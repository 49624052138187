import * as React from "react";
const SvgIconSharedDraft = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.647 35.688a3.208 3.208 0 0 0 2.353.979h6.962c.277-1.109.644-2.231 1.118-3.334H10V6.667h11.667v6.666c0 .473.16.868.48 1.187.319.32.714.48 1.186.48H30v6.813c.784-1.067 2.032-1.7 3.333-1.79v-5.315a3.297 3.297 0 0 0-.958-2.333l-8.083-8.083a3.297 3.297 0 0 0-2.333-.958H10c-.917 0-1.701.325-2.353.978a3.211 3.211 0 0 0-.98 2.355v26.666c0 .917.326 1.702.98 2.355ZM30 26.726c.78-.228 1.614-.407 2.505-.53l-.178.025-.02.003c-.817.122-1.585.291-2.307.502Z"
      fill="#4A5464"
    />
    <path
      d="M32.505 24.441v1.755C24.547 27.299 21.137 32.816 20 38.333c2.842-3.862 6.821-5.627 12.505-5.627v1.865c0 .982 1.228 1.467 1.944.772l5.218-5.065a1.076 1.076 0 0 0 0-1.555l-5.218-5.065c-.716-.695-1.944-.199-1.944.783Z"
      fill="#4A5464"
    />
  </svg>
);
export default SvgIconSharedDraft;
