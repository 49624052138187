import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 32 28",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { fill: "none" }, /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#0F66B7",
      d: "M32 5.835c0-1.75-1.432-3.166-3.2-3.166H18.667L15.467 0H8.533A3.169 3.169 0 0 0 5.35 3.167L5.333 23.5c0 1.75 1.432 3.167 3.2 3.167C22.121 27.556 28.943 28 29 28c2.038 0 3-.996 3-3V5.835Z"
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#00A4E9",
      d: "M26.667 25V9.84c0-1.75-1.432-3.173-3.2-3.173H3.2A3.175 3.175 0 0 0 .016 9.84L0 25c0 2.032 1.057 3 3 3h26.333c-2.278 0-2.666-.991-2.666-3Z"
    }
  ))
);
export {
  l as default
};
