import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm-1 17v-2h2v2h-2Zm3.17-6.83.9-.92c1.02-1.02 1.37-2.77.19-4.4-.9-1.25-2.35-2.04-3.87-1.8-1.55.24-2.8 1.36-3.23 2.83C8 8.44 8.4 9 8.98 9h.3c.39 0 .7-.28.82-.65.33-.95 1.36-1.58 2.47-1.27.7.2 1.26.81 1.39 1.53.13.7-.09 1.36-.55 1.8l-1.24 1.26A3.997 3.997 0 0 0 11 14.5v.5h2c0-.46.05-.82.13-1.14.18-.72.54-1.18 1.04-1.69Z"
    }
  )
);
export {
  l as default
};
