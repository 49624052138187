import { jsx as c } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ c("path", { d: "M12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm5-12.5 1.35-.45.4-1.35a8.564 8.564 0 0 0-1.925-2.063A7.972 7.972 0 0 0 14.35 4.35L13 5.3v1.4l4 2.8Zm-10 0 4-2.8V5.3l-1.35-.95c-.9.283-1.725.713-2.475 1.288A8.564 8.564 0 0 0 5.25 7.7l.4 1.35L7 9.5Zm-1.05 7.7 1.15-.1.75-1.35L6.4 11.4 5 10.9l-1 .75c0 1.083.15 2.07.45 2.963.3.891.8 1.754 1.5 2.587ZM12 20c.433 0 .858-.033 1.275-.1a7.735 7.735 0 0 0 1.225-.3l.7-1.5-.65-1.1h-5.1l-.65 1.1.7 1.5c.4.133.808.233 1.225.3.417.067.842.1 1.275.1Zm-2.25-5h4.5l1.4-4L12 8.45 8.4 11l1.35 4Zm8.3 2.2c.7-.833 1.2-1.696 1.5-2.587.3-.892.45-1.88.45-2.963l-1-.7-1.4.45-1.45 4.35.75 1.35 1.15.1Z" })
  }
);
export {
  t as default
};
