import { jsxs as e, jsx as l } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ l("path", { d: "M13 8v4a1 1 0 1 1-2 0V8a1 1 0 1 1 2 0ZM12 17a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" }),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m3.95 18.7 2.75.6 1.45 2.45a.929.929 0 0 0 .55.425c.233.067.467.058.7-.025l2.6-1.1 2.6 1.1c.233.083.467.092.7.025a.929.929 0 0 0 .55-.425l1.45-2.45 2.75-.6c.25-.05.45-.18.6-.388a.926.926 0 0 0 .175-.687L20.55 14.8l1.875-2.15c.167-.183.25-.4.25-.65a.934.934 0 0 0-.25-.65L20.55 9.2l.275-2.825a.926.926 0 0 0-.175-.687.943.943 0 0 0-.6-.388l-2.75-.6-1.45-2.45a.93.93 0 0 0-.55-.425 1.12 1.12 0 0 0-.7.025L12 2.95l-2.6-1.1a1.12 1.12 0 0 0-.7-.025.93.93 0 0 0-.55.425L6.7 4.7l-2.75.6c-.25.05-.45.18-.6.388a.926.926 0 0 0-.175.687L3.45 9.2l-1.875 2.15a.934.934 0 0 0-.25.65c0 .25.083.467.25.65L3.45 14.8l-.275 2.825a.926.926 0 0 0 .175.687c.15.209.35.338.6.388Zm8.05.15-2.55 1.1L8 17.55l-2.75-.6.25-2.85L3.65 12 5.5 9.85l-.25-2.8L8 6.45l1.4-2.4 2.6 1.1 2.55-1.1L16 6.45l2.75.6-.25 2.8L20.35 12l-1.85 2.1.25 2.8-2.75.65-1.4 2.4-2.6-1.1Z"
        }
      )
    ]
  }
);
export {
  t as default
};
