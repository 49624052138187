import styled from "styled-components";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";

/**
 * Popover
 * anchorPosition = <string> "left bottom", "top right", etc...
 * anchor = element to attach to
 * children = children to include
 */
const Popover = forwardRef(({ anchor, children, anchorPosition }, ref) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    const {
      bottom,
      left,
      right,
      top,
    } = anchor.current?.getBoundingClientRect();
    const { scrollY } = window;

    const [vertical, horizontal] = anchorPosition.split(" ");

    const coordLeft =
      horizontal === "left" ? `${left}px` : `calc(${right}px - 100%)`;
    const coordTop =
      vertical === "bottom"
        ? `${Math.floor(bottom + scrollY)}px`
        : `calc(${Math.floor(top + scrollY)}px - 100%)`;

    setStyle({
      transform: `translate3d(${coordLeft}, ${coordTop}, 0)`,
    });
  }, [anchor, anchorPosition]);

  return createPortal(
    <Container ref={ref} style={style}>
      {children}
    </Container>,
    document.body
  );
});

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2001;

  @media print {
    display: none;
  }
`;

Popover.propTypes = {
  anchor: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  children: PropTypes.element.isRequired,
  anchorPosition: PropTypes.oneOf([
    "top left",
    "top right",
    "bottom left",
    "bottom right",
  ]),
};

Popover.defaultProps = {
  anchorPosition: "bottom left",
};

export default Popover;
