import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { decode as decodeHtmlEntities } from "html-entities";
import styled, { keyframes, css } from "styled-components";
import {
  TinyText,
  TextEta,
  TextZeta,
  TextDelta,
  TextBeta,
  TextAlpha,
} from "@happeouikit/typography";
import { IconGroupAvatar } from "@happeouikit/icons";
import { getInitials, getNameString, getInitialsBgColor } from "./utils";
import { AvatarProps, Size, BadgeSupportedSize } from "./Avatar.types";

const baseFontStyle = css`
  color: var(--color-white100);
  user-select: none;
`;

const sizeMap = {
  xxs: {
    avatar: "18px",
    initialsFont: styled(TinyText).attrs({ as: "span" })`
      ${baseFontStyle}
    `,
  },
  xs: {
    avatar: "24px",
    initialsFont: styled(TinyText).attrs({ as: "span" })`
      ${baseFontStyle}
    `,
  },
  s: {
    avatar: "32px",
    presenceSize: "7px",
    presenceBorder: "1px",
    emojiSize: "12px",
    emojiBgSize: "16px",
    initialsFont: styled(TextEta).attrs({ as: "span" })`
      ${baseFontStyle}
    `,
  },
  m: {
    avatar: "40px",
    presenceSize: "10px",
    presenceBorder: "2px",
    emojiSize: "14px",
    emojiBgSize: "18px",
    initialsFont: styled(TextZeta).attrs({ as: "span" })`
      ${baseFontStyle}
    `,
  },
  l: {
    avatar: "48px",
    presenceSize: "12px",
    presenceBorder: "2px",
    emojiSize: "16px",
    emojiBgSize: "20px",
    initialsFont: styled(TextDelta).attrs({ as: "span" })`
      ${baseFontStyle}
    `,
  },
  xl: {
    avatar: "64px",
    presenceSize: "15px",
    presenceBorder: "3px",
    emojiSize: "20px",
    emojiBgSize: "24px",
    initialsFont: styled(TextBeta).attrs({ as: "span" })`
      ${baseFontStyle}
    `,
  },
  xxl: {
    avatar: "88px",
    presenceSize: "20px",
    presenceBorder: "3px",
    emojiSize: "24px",
    emojiBgSize: "28px",
    initialsFont: styled(TextAlpha).attrs({ as: "span" })`
      ${baseFontStyle}
    `,
  },
};

const scaleAndFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  } 
`;

const Container = styled.div`
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  vertical-align: middle;

  &.click-event {
    cursor: pointer;
  }
`;

const AvatarDiv = styled.div<{ size: Size; bgColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid transparent;
  border-radius: 50%;
  height: ${({ size }) => sizeMap[size].avatar};
  width: ${({ size }) => sizeMap[size].avatar};
  overflow: hidden;

  &:focus {
    outline: 0;
    border: 1px solid var(--color-focus-indicator);
  }

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `}

  .hover-event:hover & {
    box-shadow: var(--box-shadow-shadow-float);
    transform: translate3d(0, -1px, 0);
  }

  .click-event:active & {
    transform: scale(0.95);
  }
`;

const Image = styled.img<{ showBackground: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${({ showBackground }) =>
    showBackground &&
    css`
      background-color: var(--color-surface-darken);
    `}
`;

const Presence = styled.div<{ size: BadgeSupportedSize }>`
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  height: ${({ size }) => sizeMap[size].presenceSize};
  width: ${({ size }) => sizeMap[size].presenceSize};
  border: ${({ size }) => sizeMap[size].presenceBorder} solid
    var(--color-white100);
  font-size: ${({ size }) => sizeMap[size].presenceSize};
  line-height: ${({ size }) => sizeMap[size].presenceSize};
  animation: ${scaleAndFadeIn} var(--animation-duration-slow-sm);

  &.presence-free {
    background-color: var(--color-success-primary);
  }
  &.presence-busy {
    background-color: var(--color-alert-primary);
  }
  &.presence-outOfOffice {
    background-color: var(--color-warning-lighten50);
  }
  &.presence-unknown {
    background-color: var(--color-gray400);
  }
`;

const EmojiWrapper = styled.div<{ size: BadgeSupportedSize }>`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -${({ size }) => sizeMap[size].emojiBgSize};
  width: ${({ size }) => sizeMap[size].emojiBgSize};
  height: ${({ size }) => sizeMap[size].emojiBgSize};
  background-color: var(--color-surface);
  border-radius: 50%;
`;

const Emoji = styled.div<{ size: BadgeSupportedSize }>`
  font-size: ${({ size }) => sizeMap[size].emojiSize};
  width: ${({ size }) => sizeMap[size].emojiSize};
  height: ${({ size }) => sizeMap[size].emojiSize};
  line-height: ${({ size }) => sizeMap[size].emojiSize};
`;

const VioletGroupIcon = styled(IconGroupAvatar)`
  fill: var(--color-violet600);
`;

const Avatar = ({
  hoverEvent,
  clickEvent,
  user,
  showPresence,
  size = "m",
  emoji,
  onClick,
  tabIndex,
  ariaLabel,
}: AvatarProps) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const canShowPresence = useMemo(
    () => showPresence && size !== "xxs" && size !== "xs" && !emoji,
    [emoji, showPresence, size]
  );
  const initials = useMemo(() => getInitials(user), [user]);
  const InitialsFont = sizeMap[size].initialsFont;
  const name = useMemo(() => getNameString(user), [user]);
  const backgroundColor = useMemo(() => getInitialsBgColor(user), [user]);

  const isGroup = user?.isGroup || user?.type === "group";
  const imageAlt = name ? `${name} avatar` : "";

  const renderAvatar = useMemo(() => {
    if (isGroup) {
      return (
        <AvatarDiv size={size} onPointerDown={(e) => e.preventDefault()}>
          <VioletGroupIcon
            width={sizeMap[size].avatar}
            height={sizeMap[size].avatar}
          />
        </AvatarDiv>
      );
    }
    if (user?.thumbnailPhotoUrl && !error) {
      return (
        <AvatarDiv size={size} onPointerDown={(e) => e.preventDefault()}>
          <Image
            alt={imageAlt}
            /* thumbnailPhotoUrl seems to be stored in encoded form for at least some users */
            src={decodeHtmlEntities(user.thumbnailPhotoUrl)}
            onError={() => setError(true)}
            onLoad={() => setLoading(false)}
            showBackground={loading}
          />
        </AvatarDiv>
      );
    }
    return (
      <AvatarDiv
        size={size}
        onPointerDown={(e) => e.preventDefault()}
        bgColor={backgroundColor}
      >
        <InitialsFont bold>{initials}</InitialsFont>
      </AvatarDiv>
    );
  }, [error, imageAlt, initials, isGroup, loading, size, user]);

  const containerTabIndex = useMemo(() => {
    if (typeof tabIndex === "number") {
      return tabIndex;
    }
    return 0;
  }, [tabIndex]);

  return (
    <Container
      className={`${hoverEvent ? "hover-event" : ""} ${
        clickEvent ? "click-event" : ""
      }`}
      // @ts-ignore type declared correctly button comes from role
      onClick={clickEvent ? onClick : null}
      {...(onClick
        ? {
            tabIndex: containerTabIndex,
            role: "button",
            "aria-label": ariaLabel,
          }
        : { role: "presentation" })}
    >
      {renderAvatar}
      {canShowPresence && (
        <Presence
          size={size as BadgeSupportedSize}
          className={`presence-${user?.status || "unknown"}`}
        />
      )}
      {emoji && (
        <EmojiWrapper size={size as BadgeSupportedSize}>
          <Emoji size={size as BadgeSupportedSize}>{emoji}</Emoji>
        </EmojiWrapper>
      )}
    </Container>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(["xxs", "xs", "s", "m", "l", "xl", "xxl"]),
  clickEvent: PropTypes.bool,
  hoverEvent: PropTypes.bool,
  user: PropTypes.shape({
    thumbnailPhotoUrl: PropTypes.string,
    isGroup: PropTypes.bool,
    type: PropTypes.string,
    status: PropTypes.string,
  }),
  showPresence: PropTypes.bool,
  emoji: PropTypes.string,
  tabIndex: PropTypes.number,
  // If there is a clickEvent, then we require ariaLabel
  // eslint-disable-next-line consistent-return
  ariaLabel: (props: AvatarProps) => {
    if (props.clickEvent && !props.ariaLabel) {
      return new Error(
        "ariaLabel is required when clickEvent is true. Please provide a meaningful ariaLabel."
      );
    }
  },
};

Avatar.defaultProps = {
  size: "m",
  user: {},
  tabIndex: 0,
  clickEvent: false,
  hoverEvent: false,
  showPresence: false,
  emoji: "",
  ariaLabel: "",
};

export default Avatar;
