import { jsx as c } from "react/jsx-runtime";
const l = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M9.5 21.725c-.283.083-.52.05-.713-.1-.191-.15-.287-.367-.287-.65a.88.88 0 0 1 .113-.413c.075-.141.162-.254.262-.337L10.5 19v-5.5l-7.225 2.125c-.35.1-.65.058-.9-.125S2 15.033 2 14.65c0-.183.054-.37.163-.563a.982.982 0 0 1 .412-.412L10.5 9V3.5c0-.433.142-.792.425-1.075C11.208 2.142 11.567 2 12 2c.433 0 .792.142 1.075.425.283.283.425.642.425 1.075V9l7.925 4.675a.97.97 0 0 1 .412.425c.109.2.163.392.163.575 0 .367-.125.642-.375.825-.25.183-.55.225-.9.125L13.5 13.5V19l1.625 1.225c.1.083.188.196.262.337a.88.88 0 0 1 .113.413c0 .283-.096.5-.287.65-.192.15-.43.183-.713.1L12 21l-2.5.725Z" })
  }
);
export {
  l as default
};
