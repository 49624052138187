import { rgba } from "polished";

// Primary Colors
/** @deprecated - instead, use `var(--color-navy700)` */
export const navy = "#1A5D8D";
/** @deprecated - instead, use `var(--color-active-primary)` */
export const active = "#009DFF";
/** @deprecated - instead, use `var(--color-success-primary)` */
export const success = "#29C47F";
/** @deprecated - instead, use `var(--color-warning-primary)` */
export const warn = "#FABD24";
/** @deprecated - instead, use `var(--color-alert-primary)` */
export const alert = "#EC4B2F";
/** @deprecated - instead, use `var(--color-violet800)` */
export const violet = "#5E2590";
/** @deprecated - instead, use `var(--color-pink500)` */
export const pink = "#F62A99";
/** @deprecated - instead, use `var(--color-orange700)` */
export const brown = "#894C15";

// Brand colors
/** @deprecated */
export const brand = "#29C4A9";
/** @deprecated */
export const brandLight = "#00E5BD";

// Grayscale
/** @deprecated - instead, use `var(--color-primary-text-on-light)` or rarely `var(--color-gray900)` */
export const gray01 = "#2A3443";
/** @deprecated - instead, use `var(--color-gray800)` */
export const gray02 = "#343E49";
/** @deprecated - instead, use `var(--color-secondary-text-on-light)` or rarely `var(--color-gray700)` */
export const gray03 = "#434F59";
/** @deprecated - instead, use `var(--color-stroke-darken)` or rarely `var(--color-gray600)` */
export const gray04 = "#5A6872";
/** @deprecated - instead, use `var(--color-hint-text-on-light)` or rarely `var(--color-gray500)` */
export const gray05 = "#8997A1";
/** @deprecated - instead, use `var(--color-placeholder-text-on-light)` or rarely `var(--color-gray400)` */
export const gray06 = "#BAC6D0";
/** @deprecated - instead, use `var(--color-disabled-text-on-light)` or rarely `var(--color-gray300)` */
export const gray07 = "#D4DBE1";
/** @deprecated - instead, use `var(--color-divider)` or rarely `var(--color-gray200)` */
export const gray08 = "#E8ECF2";
/** @deprecated - instead, use `var(--color-surface-over)` or rarely `var(--color-gray100)` */
export const gray09 = "#F7F9FB";
/** @deprecated - instead, use `var(--color-gray50)` */
export const gray10 = "#FBFCFD";

// B&W
/** @deprecated - instead, use `var(--color-black100)` */
export const black = "#000000";
/** @deprecated - instead, use `var(--color-black100)` */
export const black70 = rgba(black, 0.7);
/** @deprecated - instead, use `var(--color-black70)` */
export const black50 = rgba(black, 0.5);
/** @deprecated */
export const black30 = rgba(black, 0.3);
/** @deprecated */
export const black20 = rgba(black, 0.2);
/** @deprecated - instead, use one of:  `var(--color-surface)`, `var(--color-white100)`, `var(--color-primary-text-on-dark)`  */
export const white = "#FFFFFF";
/** @deprecated - instead, use `var(--color-white70)` */
export const white70 = rgba(white, 0.7);

// Backgrounds
/** @deprecated - instead, use `var(--color-surface)` */
export const bgWhite = white;
/** @deprecated - instead, use `var(--color-surface-darken)` */
export const bgGray = gray09;

// Shadow colors
/** @deprecated */
export const shadowActive = rgba(active, 0.05);
/** @deprecated */
export const shadowGray06 = rgba(gray06, 0.5);
/** @deprecated */
export const shadowGray08 = rgba(gray08, 0.5);

// Functions
/**
 * By using the CSS color-mix() rather than the javascript mix(), we support CSS variables
 * @param color e.g. #123456, rgba(123, 123, 123, 0.5), var(--color-active-primary)
 * @param fraction a number between 0.01 and 0.99
 * @returns {`color-mix(in srgb, ${string}, black ${number})`}
 */
export const darken = (color, fraction) => `color-mix(in srgb, ${color}, black ${Math.round(fraction * 100)}%)`;
export const lighten = (color, fraction) => `color-mix(in srgb, ${color}, white ${Math.round(fraction * 100)}%)`;

/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-darken20)`). Otherwise, replace with `darken(color, 0.8)` */
export const darken80 = color => darken(color, 0.8);
/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-darken20)`). Otherwise, replace with `darken(color, 0.6)` */
export const darken60 = color => darken(color, 0.6);
/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-darken20)`). Otherwise, replace with `darken(color, 0.4)` */
export const darken40 = color => darken(color, 0.4);
/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-darken20)`). Otherwise, replace with `darken(color, 0.2)` */
export const darken20 = color => darken(color, 0.2);
/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-lighten20)`). Otherwise, replace with `lighten(color, 0.2)` */
export const lighten20 = color => lighten(color, 0.2);
/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-lighten20)`). Otherwise, replace with `lighten(color, 0.4)` */
export const lighten40 = color => lighten(color, 0.4);
/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-lighten20)`). Otherwise, replace with `lighten(color, 0.6)` */
export const lighten60 = color => lighten(color, 0.6);
/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-lighten20)`). Otherwise, replace with `lighten(color, 0.8)` */
export const lighten80 = color => lighten(color, 0.8);
/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-lighten20)`). Otherwise, replace with `lighten(color, 0.9)` */
export const lighten90 = color => lighten(color, 0.9);

/** @deprecated - replace with semantic color variables whenever possible (e.g. `var(--color-active-lighten20)`). Otherwise, replace with `lighten(color, 0.9)` */
export const toBgLight = color => lighten(color, 0.9);
