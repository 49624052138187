import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M13.1 13a.48.48 0 0 1-.35-.15.48.48 0 0 1-.15-.35v-1.075c0-.033.05-.15.15-.35l4.825-4.825 1.775 1.775-4.825 4.825c-.033.033-.15.083-.35.15H13.1ZM20 7.375l.85-.85c.1-.1.15-.221.15-.363a.493.493 0 0 0-.15-.362L19.8 4.75c-.1-.1-.22-.15-.362-.15a.494.494 0 0 0-.363.15l-.85.85L20 7.375ZM5 19a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 18c0-.283.096-.52.287-.712A.968.968 0 0 1 5 17h1v-7c0-1.417.425-2.654 1.275-3.713A5.927 5.927 0 0 1 10.5 4.2v-.7c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 12 2c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v.7c.417.1.808.246 1.175.437.367.192.708.413 1.025.663l-1.425 1.425a3.672 3.672 0 0 0-1.05-.537A3.918 3.918 0 0 0 12 6c-1.1 0-2.042.392-2.825 1.175C8.392 7.958 8 8.9 8 10v7h8v-2.8l2-2V17h1c.283 0 .52.096.712.288A.965.965 0 0 1 20 18c0 .283-.096.52-.288.712A.965.965 0 0 1 19 19H5Zm7 3c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 20h4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 22Z" })
  }
);
export {
  t as default
};
