/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Skype = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.694 8.004c0 1.476-.589 2.89-1.637 3.934a5.6 5.6 0 0 1-3.951 1.63 5.601 5.601 0 0 1-3.952-1.63 5.551 5.551 0 0 1-1.637-3.934c0-1.476.589-2.891 1.637-3.934a5.601 5.601 0 0 1 3.952-1.63c1.482 0 2.903.586 3.951 1.63a5.551 5.551 0 0 1 1.637 3.934Z"
      fill="url(#skype_svg__a)"
    />
    <path
      d="M21.483 15.994a5.545 5.545 0 0 1-1.638 3.936 5.592 5.592 0 0 1-3.953 1.63 5.603 5.603 0 0 1-3.953-1.63 5.553 5.553 0 0 1-1.637-3.936c0-1.476.589-2.892 1.637-3.936a5.603 5.603 0 0 1 6.093-1.206 5.592 5.592 0 0 1 3.025 3.012c.281.675.426 1.4.426 2.13Z"
      fill="url(#skype_svg__b)"
    />
    <path
      opacity={0.05}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.538 8.48a5.578 5.578 0 0 0 3.589 4.761 5.593 5.593 0 0 0 5.846-1.19 5.58 5.58 0 0 0-3.172-9.568A10.157 10.157 0 0 0 2.538 8.48Z"
      fill="#000"
    />
    <path
      opacity={0.05}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.468 15.594a5.58 5.58 0 0 0-3.586-4.836 5.596 5.596 0 0 0-5.9 1.226 5.583 5.583 0 0 0 3.404 9.553 10.157 10.157 0 0 0 6.082-5.943Z"
      fill="#000"
    />
    <path
      opacity={0.1}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8 9.752a5.58 5.58 0 0 0 3.951 3.679 5.592 5.592 0 0 0 5.221-1.383 5.579 5.579 0 0 0-1.9-9.254 9.506 9.506 0 0 0-7.273 6.958Z"
      fill="#000"
    />
    <path
      opacity={0.1}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.214 14.287a5.58 5.58 0 0 0-3.973-3.723 5.594 5.594 0 0 0-5.257 1.424 5.58 5.58 0 0 0 2.09 9.27 9.49 9.49 0 0 0 7.141-6.972Z"
      fill="#000"
    />
    <path
      opacity={0.1}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.005 10.28a5.579 5.579 0 0 0 4.024 3.21 5.59 5.59 0 0 0 6.368-3.677 5.573 5.573 0 0 0-2.803-6.792 9.183 9.183 0 0 0-4.938 2.437 9.166 9.166 0 0 0-2.651 4.822Z"
      fill="#000"
    />
    <path
      opacity={0.1}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.008 13.748a5.581 5.581 0 0 0-4.05-3.245 5.593 5.593 0 0 0-4.974 1.486 5.58 5.58 0 0 0-.534 7.38 5.585 5.585 0 0 0 2.085 1.672 9.183 9.183 0 0 0 4.877-2.48 9.165 9.165 0 0 0 2.596-4.813Z"
      fill="#000"
    />
    <path
      d="M20.852 12.041a8.779 8.779 0 0 1-2.592 6.232 8.856 8.856 0 0 1-6.259 2.58 8.887 8.887 0 0 1-6.259-2.58 8.778 8.778 0 0 1 0-12.463 8.886 8.886 0 0 1 12.518 0 8.78 8.78 0 0 1 2.592 6.231Z"
      fill="url(#skype_svg__c)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.512 11.735a2.777 2.777 0 0 1-.872-.919 2.538 2.538 0 0 1-.299-1.27 2.281 2.281 0 0 1 .539-1.537c.377-.43.86-.754 1.403-.937a5.425 5.425 0 0 1 1.838-.31c.41-.003.818.028 1.222.094.29.045.574.118.848.22.253.084.48.23.664.422.135.159.208.361.205.57a.822.822 0 0 1-.216.593.717.717 0 0 1-.542.225.976.976 0 0 1-.402-.09 7.302 7.302 0 0 0-.96-.35 3.484 3.484 0 0 0-.923-.115 2.11 2.11 0 0 0-1.157.295.962.962 0 0 0-.462.864.868.868 0 0 0 .24.607c.185.193.404.35.646.462.27.135.675.313 1.212.534.06.02.118.043.174.07a8.619 8.619 0 0 1 1.5.77c.374.244.69.568.924.947.23.4.346.855.334 1.316.02.564-.143 1.12-.462 1.586a2.746 2.746 0 0 1-1.292.958c-.623.222-1.281.329-1.942.316a6.124 6.124 0 0 1-2.648-.5 1.44 1.44 0 0 1-.544-.424.95.95 0 0 1-.178-.576.752.752 0 0 1 .233-.586.859.859 0 0 1 .6-.212c.245.003.486.065.702.18.27.135.481.235.636.303a3.194 3.194 0 0 0 1.254.246c.42.03.836-.082 1.185-.316a1.041 1.041 0 0 0 .4-.864c0-.235-.09-.46-.25-.631a2.66 2.66 0 0 0-.737-.535 17.92 17.92 0 0 0-1.37-.625 8.486 8.486 0 0 1-1.503-.78"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="skype_svg__a"
        x1={7.234}
        y1={2.507}
        x2={8.973}
        y2={13.501}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.012} stopColor="#00B7F0" />
        <stop offset={0.339} stopColor="#009DE5" />
        <stop offset={0.755} stopColor="#0082D9" />
        <stop offset={1} stopColor="#0078D4" />
      </linearGradient>
      <linearGradient
        id="skype_svg__b"
        x1={11.053}
        y1={18.784}
        x2={20.725}
        y2={13.194}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0078D4" />
        <stop offset={0.37} stopColor="#007AD5" />
        <stop offset={0.573} stopColor="#0082D9" />
        <stop offset={0.735} stopColor="#0090DF" />
        <stop offset={0.875} stopColor="#00A3E7" />
        <stop offset={1} stopColor="#00BCF2" />
      </linearGradient>
      <linearGradient
        id="skype_svg__c"
        x1={8.547}
        y1={6.066}
        x2={17.363}
        y2={21.353}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B7F0" />
        <stop offset={1} stopColor="#007CC1" />
      </linearGradient>
    </defs>
  </svg>
);

export default Skype;
