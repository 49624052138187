import { jsx as A } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M4 8a.968.968 0 0 1-.713-.288A.967.967 0 0 1 3 7V4a.97.97 0 0 1 .287-.713A.97.97 0 0 1 4 3h3a.97.97 0 0 1 .713.287A.97.97 0 0 1 8 4a.97.97 0 0 1-.287.713A.97.97 0 0 1 7 5H5v2c0 .283-.096.52-.287.712A.968.968 0 0 1 4 8ZM4 21a.968.968 0 0 1-.713-.288A.967.967 0 0 1 3 20v-3a.97.97 0 0 1 .287-.713A.97.97 0 0 1 4 16a.97.97 0 0 1 .713.287A.97.97 0 0 1 5 17v2h2c.283 0 .521.096.713.288A.967.967 0 0 1 8 20c0 .283-.096.52-.287.712A.968.968 0 0 1 7 21H4ZM16.288 20.712A.965.965 0 0 0 17 21h3c.283 0 .52-.096.712-.288A.965.965 0 0 0 21 20v-3a.968.968 0 0 0-.288-.713A.967.967 0 0 0 20 16a.967.967 0 0 0-.712.287A.968.968 0 0 0 19 17v2h-2a.965.965 0 0 0-.712.288A.965.965 0 0 0 16 20c0 .283.096.52.288.712ZM20 8a.965.965 0 0 1-.712-.288A.965.965 0 0 1 19 7V5h-2a.967.967 0 0 1-.712-.287A.968.968 0 0 1 16 4c0-.283.096-.521.288-.713A.967.967 0 0 1 17 3h3c.283 0 .52.096.712.287.192.192.288.43.288.713v3c0 .283-.096.52-.288.712A.965.965 0 0 1 20 8ZM12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" })
  }
);
export {
  t as default
};
