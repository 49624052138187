import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M10.146 15.146a.5.5 0 0 0 .854-.353V13h9a1 1 0 1 0 0-2h-9V9.207a.5.5 0 0 0-.854-.353l-2.792 2.792a.5.5 0 0 0 0 .708l2.792 2.792ZM3 19a1 1 0 1 0 2 0V5a1 1 0 0 0-2 0v14Z" })
  }
);
export {
  h as default
};
