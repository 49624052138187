/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Freshdesk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.477 2h-7.511v.002C6.454 2.058 2 6.512 2 12.036A9.963 9.963 0 0 0 11.977 22h.057A9.97 9.97 0 0 0 22 12.034V4.523A2.5 2.5 0 0 0 19.477 2ZM8.727 7.796a4.58 4.58 0 0 1 3.239-1.342c2.598 0 4.643 2.057 4.58 4.637v3.045a1.506 1.506 0 0 1-1.171 1.478v.113a1.83 1.83 0 0 1-1.818 1.818h-1.41a.255.255 0 0 1-.238-.238c0-.114.125-.17.239-.17h1.398a1.432 1.432 0 0 0 1.41-1.41v-.056h-1.171v-3.574h1.772v-1a3.557 3.557 0 0 0-7.102 0v1h1.75v3.574H8.909a1.559 1.559 0 0 1-1.523-1.523v-3.114a4.58 4.58 0 0 1 1.341-3.238Z"
    />
  </svg>
);

export default Freshdesk;
