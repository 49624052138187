import * as React from "react";
const SvgIconFileGeneralFile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 32"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="icon-file-general-file_svg__a"
        x1="0%"
        y1="0%"
        y2="100%"
      >
        <stop stopOpacity={0.2} offset="0%" />
        <stop stopOpacity={0} offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#00A4E9"
        d="M3 0h12l9 9v20a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
      />
      <path
        fill="#FFF"
        fillOpacity={0.4}
        d="M24 9h-6a3 3 0 0 1-3-3V0l9 9Z"
        style={{
          mixBlendMode: "lighten",
        }}
      />
      <path
        fill="url(#icon-file-general-file_svg__a)"
        d="M24 9h-6a2.991 2.991 0 0 1-2.159-.917c-.347-.36 2.373 2.28 8.159 7.917V9Z"
        style={{
          mixBlendMode: "hard-light",
        }}
      />
      <path
        fill="#FFF"
        d="M9 24h6v2H9v-2Zm-2-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4Zm10 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      />
    </g>
  </svg>
);
export default SvgIconFileGeneralFile;
