import { jsx as e } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2ZM13.5 13.5h3a1.5 1.5 0 0 0 0-3h-3v-3a1.5 1.5 0 0 0-3 0v3h-3a1.5 1.5 0 0 0 0 3h3v3a1.5 1.5 0 0 0 3 0v-3Z"
      }
    )
  }
);
export {
  v as default
};
