import { jsxs as v, jsx as c } from "react/jsx-runtime";
const Z = (h) => /* @__PURE__ */ v(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ c("path", { d: "M7.198 16.538c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48h-.48a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48ZM7.678 13.585c0 .264-.216.48-.48.48h-.48a.482.482 0 0 1-.48-.48v-.48c0-.265.216-.48.48-.48h.48c.264 0 .48.215.48.48v.48ZM7.198 11.616c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48h-.48a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48ZM7.678 8.686c0 .265-.216.48-.48.48h-.48a.482.482 0 0 1-.48-.48v-.48c0-.264.216-.48.48-.48h.48c.264 0 .48.216.48.48v.48ZM9.838 16.538c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48h-.48a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48ZM10.319 13.585c0 .264-.217.48-.48.48h-.48a.482.482 0 0 1-.481-.48v-.48c0-.265.216-.48.48-.48h.48c.264 0 .48.215.48.48v.48ZM9.838 11.616c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48h-.48a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48ZM10.319 8.686c0 .265-.217.48-.48.48h-.48a.482.482 0 0 1-.481-.48v-.48c0-.264.216-.48.48-.48h.48c.264 0 .48.216.48.48v.48ZM12.48 9.167c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.481H12a.482.482 0 0 0-.48.48v.48c0 .265.216.48.48.48h.48ZM15.361 16.298c0 .264-.216.48-.48.48h-.48a.482.482 0 0 1-.48-.48v-.48c0-.264.216-.48.48-.48h.48c.264 0 .48.216.48.48v.48ZM14.881 14.329c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48h-.48a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48ZM17.762 16.298c0 .264-.216.48-.48.48h-.48a.482.482 0 0 1-.48-.48v-.48c0-.264.216-.48.48-.48h.48c.264 0 .48.216.48.48v.48ZM17.282 14.329c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48h-.48a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48Z" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M2 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V6Zm12.16.886V9.67c0 .192-.191.48-.456.48h-1.896c-.529 0-.529.576-.529.576v7.036H5.036V6.766c0-.048.072-.529.529-.529h8.115c.145 0 .505.144.48.649Zm4.802 5.378c.024-.504-.336-.648-.48-.648h-5.234c-.457 0-.528.48-.528.528v5.619h6.242v-5.499Z"
        }
      )
    ]
  }
);
export {
  Z as default
};
