import * as React from "react";
const SvgIconFileAdobeAnimate = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 103.5 103.5"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{".icon-file-adobe-animate_svg__st0{fill:#ff401f}"}</style>
    <path
      className="icon-file-adobe-animate_svg__st0"
      d="M103.5 103.5H0V0h103.5v103.5zM5 98.5h93.5V5H5v93.5z"
    />
    <path
      d="M3.8 3.8h95.9v95.9H3.8z"
      style={{
        fill: "#260600",
      }}
    />
    <path
      className="icon-file-adobe-animate_svg__st0"
      d="M91 76.7V46.6c0-9.1-13.9-15.2-31.3-8.1v38.2H70V43.8c5.4-1.8 11.7-1 11.7 3.6v29.3H91zM39.3 21H28.1L12.7 76.1l8.6.5 4.2-15.5H42l4.1 15.5H56L39.3 21zM27.7 52.4l5.8-23.1 6.1 23.1H27.7z"
    />
  </svg>
);
export default SvgIconFileAdobeAnimate;
