import { jsx as h } from "react/jsx-runtime";
const m = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19Zm10-6v6h6v-6h-6Zm0-2h6V5h-6v6Zm-2 0V5H5v6h6Zm0 2H5v6h6v-6Z" })
  }
);
export {
  m as default
};
