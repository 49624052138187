import { jsx as c } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M8 14h8v-.55c0-.733-.367-1.325-1.1-1.775-.733-.45-1.7-.675-2.9-.675s-2.167.225-2.9.675c-.733.45-1.1 1.042-1.1 1.775V14Zm4-4c.55 0 1.021-.196 1.413-.588C13.804 9.021 14 8.55 14 8c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 12 6c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 10 8c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588ZM2 19.575V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 4 2h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 18H6l-2.3 2.3c-.317.317-.68.387-1.088.212-.408-.175-.612-.487-.612-.937Zm2-2.4L5.175 16H20V4H4v13.175Z" })
  }
);
export {
  h as default
};
