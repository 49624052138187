import * as React from "react";
const SvgIconTune = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 8c0 .55-.45 1-1 1s-1-.45-1-1V4c0-.55.45-1 1-1s1 .45 1 1v1h3c.55 0 1 .45 1 1s-.45 1-1 1h-3v1ZM4 7c-.55 0-1-.45-1-1s.45-1 1-1h9v2H4Zm0 12c-.55 0-1-.45-1-1s.45-1 1-1h5v2H4Zm9 1v-1h7c.55 0 1-.45 1-1s-.45-1-1-1h-7v-1c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1Zm-6-9v-1c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1v-1H4c-.55 0-1-.45-1-1s.45-1 1-1h3Zm14 1c0-.55-.45-1-1-1h-9v2h9c.55 0 1-.45 1-1Z"
    />
  </svg>
);
export default SvgIconTune;
