import * as React from "react";
const SvgIconCopy = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 1h-11c-1.1 0-2 .9-2 2v13c0 .55.45 1 1 1s1-.45 1-1V4c0-.55.45-1 1-1h10c.55 0 1-.45 1-1s-.45-1-1-1Zm4 4h-11c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2Zm-10 16h9c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1h-9c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1Z"
      fill="#434F59"
    />
  </svg>
);
export default SvgIconCopy;
