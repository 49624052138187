/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleMeet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m13.446 12.052 2.145 2.45 2.883 1.844.503-4.28-.503-4.183-2.939 1.619-2.09 2.55Z"
      fill="#00832D"
    />
    <path
      d="M1 15.949v3.645c0 .834.675 1.509 1.509 1.509h3.645l.755-2.756-.755-2.398-2.501-.755L1 15.95Z"
      fill="#0066DA"
    />
    <path
      d="M6.154 3 1 8.154l2.653.755 2.501-.755.742-2.366L6.154 3Z"
      fill="#E94235"
    />
    <path d="M6.154 8.154H1v7.795h5.154V8.154Z" fill="#2684FC" />
    <path
      d="m21.768 5.182-3.294 2.7v8.464l3.31 2.713a.755.755 0 0 0 1.219-.596V5.766a.755.755 0 0 0-1.235-.584Zm-8.322 6.87v3.897H6.154v5.154h10.812c.833 0 1.508-.675 1.508-1.509v-3.248l-5.028-4.294Z"
      fill="#00AC47"
    />
    <path
      d="M16.966 3H6.154v5.154h7.292v3.897l5.028-4.166V4.51C18.474 3.675 17.8 3 16.966 3Z"
      fill="#FFBA00"
    />
  </svg>
);

export default GoogleMeet;
