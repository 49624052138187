import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-ms-powerpoint_svg__a",
      x1: 5.822,
      y1: 11.568,
      x2: 20.178,
      y2: 36.432,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { offset: 0, stopColor: "#ca4c28" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.5, stopColor: "#c5401e" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#b62f14" })
  )),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M28 26 25 4h-.224A19.834 19.834 0 0 0 5 23.776V24z",
      fill: "#ed6c47"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M25.224 4H25v20l10 4 10-4v-.224A19.834 19.834 0 0 0 25.224 4z",
      fill: "#ff8f6b"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M45 24v.22A19.837 19.837 0 0 1 25.22 44h-.44A19.837 19.837 0 0 1 5 24.22V24z",
      fill: "#d35230"
    }
  ),
  /* @__PURE__ */ e.createElement("path", { fill: "none", d: "M0 0h48v48H0z" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M26 13.83v21.34a1.841 1.841 0 0 1-1.14 1.69 1.772 1.772 0 0 1-.69.14H9.72c-.28-.32-.55-.66-.8-1A19.614 19.614 0 0 1 5 24.22v-.44A19.555 19.555 0 0 1 8.22 13c.22-.34.45-.68.7-1h15.25A1.844 1.844 0 0 1 26 13.83z",
      opacity: 0.1
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M25 14.83v21.34a1.772 1.772 0 0 1-.14.69A1.841 1.841 0 0 1 23.17 38H10.63a17.1 17.1 0 0 1-.91-1c-.28-.32-.55-.66-.8-1A19.614 19.614 0 0 1 5 24.22v-.44A19.555 19.555 0 0 1 8.22 13h14.95A1.844 1.844 0 0 1 25 14.83z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M25 14.83v19.34A1.844 1.844 0 0 1 23.17 36H8.92A19.614 19.614 0 0 1 5 24.22v-.44A19.555 19.555 0 0 1 8.22 13h14.95A1.844 1.844 0 0 1 25 14.83z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M24 14.83v19.34A1.844 1.844 0 0 1 22.17 36H8.92A19.614 19.614 0 0 1 5 24.22v-.44A19.555 19.555 0 0 1 8.22 13h13.95A1.844 1.844 0 0 1 24 14.83z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 2,
      y: 13,
      width: 22,
      height: 22,
      rx: 1.833,
      fill: "url(#Icon-ms-powerpoint_svg__a)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M13.2 17.889a5.039 5.039 0 0 1 3.338.978A3.5 3.5 0 0 1 17.7 21.7a3.942 3.942 0 0 1-.573 2.117 3.829 3.829 0 0 1-1.627 1.438 5.524 5.524 0 0 1-2.447.514h-2.319v4.31H8.359v-12.19zm-2.468 6.018h2.044a2.654 2.654 0 0 0 1.807-.539 2 2 0 0 0 .611-1.577q0-2.015-2.342-2.015h-2.12z",
      fill: "#fff"
    }
  )
);
export {
  a as default
};
