import * as e from "react";
const l = (c) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...c
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12.68 6.629c.7-.24 1.22-.9 1.3-1.64.05-.47-.05-.91-.28-1.27l-1.28-2.22a.506.506 0 0 0-.87 0l-1.28 2.22c-.17.3-.27.65-.27 1.03 0 1.32 1.3 2.35 2.68 1.88Zm3.85 10.04-1-1-1.08 1.07c-1.3 1.3-3.58 1.31-4.89 0l-1.07-1.07-1.09 1.07c-.65.65-1.52 1.01-2.44 1.01-.73 0-1.4-.23-1.96-.61v3.61c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-3.61c-.75.51-1.71.75-2.74.52-.66-.14-1.25-.51-1.73-.99ZM13 9.749h5c1.66 0 3 1.34 2.99 3.01v1.42c0 1-.7 1.93-1.69 2.06-.62.08-1.22-.12-1.65-.55l-2.13-2.13-2.14 2.13c-.76.76-2.01.76-2.77 0l-2.13-2.13-2.14 2.13c-.51.52-1.3.7-2.03.46-.81-.27-1.31-1.09-1.31-1.94v-1.46c0-1.66 1.34-3 3-3h5v-1c0-.55.45-1 1-1s1 .45 1 1v1Z"
    }
  )
);
export {
  l as default
};
