import { jsx as a } from "react/jsx-runtime";
const l = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M21.45 22.05a.92.92 0 0 1-1.3 0L15 16.9V19a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-5H7.414c-.89 0-1.337-1.076-.707-1.707L8.6 10.402 1.757 3.648a.916.916 0 1 1 1.29-1.3L21.45 20.751a.92.92 0 0 1 0 1.3ZM18 6a1 1 0 1 0 0-2H7.2l2 2H18Zm-.8 8a.746.746 0 0 0 .527-1.272l-5.02-5.02a1 1 0 0 0-1.414 0L11.1 7.9l6.1 6.1Z" })
  }
);
export {
  l as default
};
