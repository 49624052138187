import { jsx as h } from "react/jsx-runtime";
const a = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M6.4 20.4a.99.99 0 0 1-.95-.087c-.3-.192-.45-.471-.45-.838V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 7 3h6v2H7v12.95l5-2.15 5 2.15V11h2v8.475c0 .367-.15.646-.45.837a.99.99 0 0 1-.95.088L12 18l-5.6 2.4ZM18 9a.968.968 0 0 1-.712-.287A.967.967 0 0 1 17 8V7h-1a.968.968 0 0 1-.713-.287A.967.967 0 0 1 15 6c0-.283.096-.52.287-.713A.968.968 0 0 1 16 5h1V4c0-.283.096-.52.288-.712A.968.968 0 0 1 18 3c.283 0 .52.096.712.288.192.191.288.429.288.712v1h1c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 20 7h-1v1c0 .283-.096.52-.288.713A.968.968 0 0 1 18 9Z" })
  }
);
export {
  a as default
};
