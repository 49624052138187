import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2Zm4.5 5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM11 7h6v2h-6V7Zm6 4h-6v2h6v-2Zm0 4h-6v2h6v-2Zm-7.5-3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
    }
  )
);
export {
  a as default
};
