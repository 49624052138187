import { jsxs as t, jsx as c } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ c("path", { d: "m5 7.648 1.515 1.186c.258.215.528.221.81.017.281-.203.375-.472.281-.808l-.563-1.976L8.733 4.7c.259-.191.33-.455.212-.79-.117-.336-.34-.503-.67-.503H6.269l-.599-1.905C5.552 1.168 5.33 1 5 1s-.552.168-.67.503l-.598 1.905H1.724c-.328 0-.552.167-.669.503-.117.335-.047.599.211.79l1.691 1.366-.563 1.976c-.094.336 0 .605.281.808.282.204.552.198.81-.017L5 7.648Z" }),
      /* @__PURE__ */ c("path", { d: "M19 5h-8a5.99 5.99 0 0 0-.341-2H19c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19v-8.341A5.99 5.99 0 0 0 5 11v8h14V5Z" }),
      /* @__PURE__ */ c("path", { d: "M7 17h10c.2 0 .35-.092.45-.275a.44.44 0 0 0-.05-.525l-2.75-3.675a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2L11.25 16 9.4 13.525a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2l-2 2.675a.44.44 0 0 0-.05.525c.1.183.25.275.45.275Z" })
    ]
  }
);
export {
  l as default
};
