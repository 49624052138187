import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12Zm3.7.7 3.59 3.59c.39.39 1.03.39 1.41 0l7.59-7.59a.996.996 0 1 0-1.41-1.41L10 14.17l-2.89-2.88A.996.996 0 1 0 5.7 12.7Z"
    }
  )
);
export {
  l as default
};
