import * as React from "react";
const SvgIconUsers = (props) => (
  <svg
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 9a2.628 2.628 0 0 0 2.625-2.625A2.628 2.628 0 0 0 6.75 3.75a2.628 2.628 0 0 0-2.625 2.625A2.628 2.628 0 0 0 6.75 9ZM1.5 12.938c0-1.748 3.495-2.626 5.25-2.626S12 11.19 12 12.938v1.312H1.5v-1.313Zm5.25-1.126c-1.343 0-2.865.503-3.495.938h6.99c-.63-.435-2.152-.938-3.495-.938Zm1.125-5.437c0-.622-.503-1.125-1.125-1.125s-1.125.503-1.125 1.125S6.128 7.5 6.75 7.5s1.125-.503 1.125-1.125Zm4.155 3.982c.87.63 1.47 1.47 1.47 2.58v1.313h3v-1.313c0-1.514-2.625-2.377-4.47-2.58Zm1.845-3.982A2.628 2.628 0 0 1 11.25 9c-.405 0-.78-.098-1.125-.262a4.08 4.08 0 0 0 .75-2.363 4.08 4.08 0 0 0-.75-2.362c.345-.165.72-.263 1.125-.263a2.628 2.628 0 0 1 2.625 2.625Z"
    />
  </svg>
);
export default SvgIconUsers;
