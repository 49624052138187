import * as React from "react";
const SvgIconLogoWebexColor = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__a)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__b)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__c)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__d)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__e)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__f)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__g)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__h)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__i)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__j)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__k)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__l)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__m)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__n)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__o)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__p)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__q)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__r)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__s)"
    />
    <path
      d="M5.282 12.346c.257.897.612 1.762 1.06 2.58.161.377.42.705.749.949l.196.095c.53.101.817-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.1-.361.072-.224.127-.453.165-.685l2.33-6.224a5.169 5.169 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.336 4.336 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.98.98 0 0 0-.297-.287.647.647 0 0 0-.717-.095l-.031.032a1.8 1.8 0 0 0-.531.817c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.684 3.433h-.015Z"
      fill="url(#icon-logo-webex-color_svg__t)"
    />
    <path
      d="m10.057 10.022 1.47-3.942a5.168 5.168 0 0 1 1.83-1.55 5.492 5.492 0 0 1 2.32-.53c1.154.009 2.273.399 3.183 1.109 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95a2.91 2.91 0 0 1-.164.875c-.135.27-.3.522-.494.753a1.91 1.91 0 0 1-.748.489c-.284.105-.584.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.695 2.695 0 0 1-.785-.594.032.032 0 0 0-.032-.032 4.335 4.335 0 0 1-.36-.653 6.622 6.622 0 0 0-.425-.817c-.096-.133-.165-.265-.26-.393a.982.982 0 0 0-.297-.28.647.647 0 0 0-.717-.096l-.031.031a1.8 1.8 0 0 0-.531.818c-.332.78-.597 1.588-.79 2.414l-.096.488-.982 3.136-2.876-5.545Z"
      fill="url(#icon-logo-webex-color_svg__u)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__v)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__w)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__x)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__y)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__z)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__A)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__B)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__C)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__D)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__E)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__F)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__G)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__H)"
    />
    <path
      d="M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.769 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.028 2.028 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.722 1.958c-.164.387-.361.76-.589 1.114-.15.234-.365.42-.62.53a.899.899 0 0 1-.292.033.531.531 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.723 6.723 0 0 1-.324-.685c-.196-.488-.53-1.698-.53-1.698l-.303-1.247-.329-1.279-.228-.849a9.754 9.754 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.647.589.273.454.42.972.425 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.069.032.105.1.203.209.292.324.173.317.326.645.456.982.265.721.494 1.634.494 1.634l.652 2.616c.227.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.45.658 4.802 4.802 0 0 0 2.352-.494 6.326 6.326 0 0 0 2.058-1.671c.34-.468.647-.96.918-1.47a17.923 17.923 0 0 0 1.401-3.789c.227-.816.404-1.645.53-2.483.07-.425.134-.817.165-1.242.07-.39.059-.791-.032-1.178-.031-.063-.031-.127-.063-.196a2.308 2.308 0 0 0-1.014-1.14 1.969 1.969 0 0 0-1.12-.335Z"
      fill="url(#icon-logo-webex-color_svg__I)"
    />
    <defs>
      <radialGradient
        id="icon-logo-webex-color_svg__b"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(74.325 -.612 13.964) scale(8.62092 6.55031)"
      >
        <stop stopColor="#227B60" />
        <stop offset={0.14} stopColor="#227B60" stopOpacity={0.81} />
        <stop offset={0.58} stopColor="#227B60" stopOpacity={0.23} />
        <stop offset={0.78} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(91.132 1.907 12.092) scale(5.82747 4.42904)"
      >
        <stop stopColor="#227B60" />
        <stop offset={0.25} stopColor="#227B60" stopOpacity={0.7} />
        <stop offset={0.71} stopColor="#227B60" stopOpacity={0.2} />
        <stop offset={0.92} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__d"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(88.612 1.707 12.244) scale(5.43314 3.85689)"
      >
        <stop stopColor="#227B60" />
        <stop offset={0.07} stopColor="#227B60" stopOpacity={0.9} />
        <stop offset={0.34} stopColor="#227B60" stopOpacity={0.51} />
        <stop offset={0.59} stopColor="#227B60" stopOpacity={0.23} />
        <stop offset={0.79} stopColor="#227B60" stopOpacity={0.06} />
        <stop offset={0.92} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__e"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(88.582 1.824 12.537) scale(4.38672 3.11641)"
      >
        <stop stopColor="#227B60" />
        <stop offset={0.36} stopColor="#227B60" stopOpacity={0.63} />
        <stop offset={0.92} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__f"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(.08553 3.49711 -2.23713 .05472 13.89 10.285)"
      >
        <stop stopColor="#20735A" />
        <stop offset={0.15} stopColor="#20745A" stopOpacity={0.93} />
        <stop offset={0.38} stopColor="#21755C" stopOpacity={0.74} />
        <stop offset={0.67} stopColor="#21785D" stopOpacity={0.43} />
        <stop offset={1} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__g"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(5.15192 2.27329 -1.70422 3.86226 14.698 11.171)"
      >
        <stop stopColor="#227A61" />
        <stop offset={0.36} stopColor="#227A61" stopOpacity={0.56} />
        <stop offset={0.72} stopColor="#227A61" stopOpacity={0.16} />
        <stop offset={0.9} stopColor="#227A61" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__h"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(35.278 3.545 21.294) scale(7.50772 6.86598)"
      >
        <stop stopColor="#3CD9C3" />
        <stop offset={0.07} stopColor="#3BD7C1" stopOpacity={0.9} />
        <stop offset={0.41} stopColor="#38CEB9" stopOpacity={0.42} />
        <stop offset={0.65} stopColor="#36C8B4" stopOpacity={0.12} />
        <stop offset={0.76} stopColor="#35C6B2" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__i"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(35.272 20024.109 -27528.465) scale(1334.03 936.155)"
      >
        <stop stopColor="#3CD9C3" />
        <stop offset={0.07} stopColor="#3BD7C1" stopOpacity={0.9} />
        <stop offset={0.41} stopColor="#38CEB9" stopOpacity={0.42} />
        <stop offset={0.65} stopColor="#36C8B4" stopOpacity={0.12} />
        <stop offset={0.76} stopColor="#35C6B2" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__j"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(100.969 2.804 12.552) scale(6.62174 8.18314)"
      >
        <stop stopColor="#237F66" stopOpacity={0.8} />
        <stop offset={0.06} stopColor="#2C8C70" stopOpacity={0.65} />
        <stop offset={0.21} stopColor="#3DA785" stopOpacity={0.37} />
        <stop offset={0.35} stopColor="#4ABA93" stopOpacity={0.17} />
        <stop offset={0.47} stopColor="#51C59C" stopOpacity={0.04} />
        <stop offset={0.56} stopColor="#54C99F" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__k"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(5.46774 -6.70801 7.24448 5.90502 12.132 12.607)"
      >
        <stop stopColor="#167C8A" />
        <stop offset={0.04} stopColor="#167C8A" stopOpacity={0.93} />
        <stop offset={0.33} stopColor="#177F8D" stopOpacity={0.43} />
        <stop offset={0.54} stopColor="#17818F" stopOpacity={0.12} />
        <stop offset={0.65} stopColor="#178290" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__l"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-31.665 29.571 -14.01) scale(8.44171 6.67052)"
      >
        <stop offset={0.05} stopColor="#177D87" />
        <stop offset={0.11} stopColor="#177E89" stopOpacity={0.8} />
        <stop offset={0.21} stopColor="#177F8B" stopOpacity={0.52} />
        <stop offset={0.32} stopColor="#17818D" stopOpacity={0.29} />
        <stop offset={0.42} stopColor="#17818F" stopOpacity={0.13} />
        <stop offset={0.51} stopColor="#178290" stopOpacity={0.03} />
        <stop offset={0.6} stopColor="#178290" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__m"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-130.544 8.077 4.334) scale(5.03066 3.9598)"
      >
        <stop offset={0.26} stopColor="#177F8A" />
        <stop offset={0.27} stopColor="#177F8A" stopOpacity={0.98} />
        <stop offset={0.38} stopColor="#17808C" stopOpacity={0.68} />
        <stop offset={0.5} stopColor="#17818D" stopOpacity={0.43} />
        <stop offset={0.62} stopColor="#17818F" stopOpacity={0.24} />
        <stop offset={0.74} stopColor="#17828F" stopOpacity={0.11} />
        <stop offset={0.86} stopColor="#178290" stopOpacity={0.03} />
        <stop offset={1} stopColor="#178290" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__n"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-173.113 5.264 7.714) scale(4.7188)"
      >
        <stop stopColor="#13656E" />
        <stop offset={0.11} stopColor="#13646E" stopOpacity={0.73} />
        <stop offset={0.27} stopColor="#12646E" stopOpacity={0.41} />
        <stop offset={0.42} stopColor="#12636E" stopOpacity={0.18} />
        <stop offset={0.55} stopColor="#12636E" stopOpacity={0.05} />
        <stop offset={0.66} stopColor="#12636E" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__o"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-119.327 8.67 5.503) scale(3.298 2.77363)"
      >
        <stop offset={0.13} stopColor="#146A73" />
        <stop offset={0.24} stopColor="#156F79" stopOpacity={0.79} />
        <stop offset={0.45} stopColor="#167783" stopOpacity={0.45} />
        <stop offset={0.65} stopColor="#167D8A" stopOpacity={0.2} />
        <stop offset={0.82} stopColor="#17818E" stopOpacity={0.05} />
        <stop offset={0.95} stopColor="#178290" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__p"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(-55.973 17.094 .186) scale(3.10418 2.45998)"
      >
        <stop stopColor="#198E9C" />
        <stop offset={0.1} stopColor="#198E9C" stopOpacity={0.87} />
        <stop offset={0.41} stopColor="#198E9C" stopOpacity={0.5} />
        <stop offset={0.67} stopColor="#198E9C" stopOpacity={0.23} />
        <stop offset={0.88} stopColor="#198E9C" stopOpacity={0.06} />
        <stop offset={1} stopColor="#198E9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__w"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-1.56597 10.25452 -6.76717 -1.03342 17.105 11.26)"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.4} stopColor="#1A3D9C" stopOpacity={0.42} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__x"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(98.69 10088.409 -5404.714) scale(1276.06 688.043)"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.4} stopColor="#1A3D9C" stopOpacity={0.42} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__y"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(-227.66097 1502.57092 -620.74929 -94.05239 5497.61 -16428.2)"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.4} stopColor="#1A3D9C" stopOpacity={0.42} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__z"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1.09223 9.00104 -6.28008 .76205 15.962 11.575)"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.16} stopColor="#1A3D9C" stopOpacity={0.69} />
        <stop offset={0.33} stopColor="#1A3D9C" stopOpacity={0.4} />
        <stop offset={0.49} stopColor="#1A3D9C" stopOpacity={0.18} />
        <stop offset={0.61} stopColor="#1A3D9C" stopOpacity={0.05} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__A"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2.30949 8.4023 -5.7141 1.5706 17.785 12.74)"
      >
        <stop stopColor="#18388F" />
        <stop offset={0.37} stopColor="#193B96" stopOpacity={0.45} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__B"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(74.643 -1.508 18.616) scale(4.04966 3.84855)"
      >
        <stop stopColor="#18388F" />
        <stop offset={0.09} stopColor="#183992" stopOpacity={0.78} />
        <stop offset={0.22} stopColor="#193B96" stopOpacity={0.5} />
        <stop offset={0.35} stopColor="#193C98" stopOpacity={0.28} />
        <stop offset={0.47} stopColor="#1A3C9A" stopOpacity={0.13} />
        <stop offset={0.59} stopColor="#1A3D9C" stopOpacity={0.03} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__C"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(74.643 -1.89 19.008) scale(4.04966 3.84855)"
      >
        <stop stopColor="#1A3D9C" stopOpacity={0.8} />
        <stop offset={0.01} stopColor="#1A3D9C" stopOpacity={0.78} />
        <stop offset={0.24} stopColor="#1A3D9C" stopOpacity={0.45} />
        <stop offset={0.44} stopColor="#1A3D9C" stopOpacity={0.2} />
        <stop offset={0.6} stopColor="#1A3D9C" stopOpacity={0.05} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__D"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(3.77347 8.90234 -5.90825 2.50435 7.317 13.258)"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.4} stopColor="#1A3D9C" stopOpacity={0.56} />
        <stop offset={0.48} stopColor="#1A3D9C" stopOpacity={0.43} />
        <stop offset={0.69} stopColor="#1A3D9C" stopOpacity={0.12} />
        <stop offset={0.79} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__E"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(76.091 -3.855 9.36) scale(7.17161 4.73479)"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.06} stopColor="#1A3D9C" stopOpacity={0.88} />
        <stop offset={0.21} stopColor="#1A3D9C" stopOpacity={0.61} />
        <stop offset={0.36} stopColor="#1A3D9C" stopOpacity={0.39} />
        <stop offset={0.51} stopColor="#1A3D9C" stopOpacity={0.22} />
        <stop offset={0.67} stopColor="#1A3D9C" stopOpacity={0.1} />
        <stop offset={0.83} stopColor="#1A3D9C" stopOpacity={0.02} />
        <stop offset={1} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__F"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(76.091 -3.855 9.36) scale(7.17161 4.73479)"
      >
        <stop stopColor="#1B3E9E" />
        <stop offset={0.06} stopColor="#1B3E9E" stopOpacity={0.87} />
        <stop offset={0.21} stopColor="#1B3E9D" stopOpacity={0.61} />
        <stop offset={0.36} stopColor="#1A3D9D" stopOpacity={0.39} />
        <stop offset={0.52} stopColor="#1A3D9C" stopOpacity={0.22} />
        <stop offset={0.67} stopColor="#1A3D9C" stopOpacity={0.1} />
        <stop offset={0.83} stopColor="#1A3D9C" stopOpacity={0.02} />
        <stop offset={1} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__G"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(76.089 -3.484 9.53) scale(5.48722 3.62251)"
      >
        <stop stopColor="#183991" />
        <stop offset={0.03} stopColor="#183992" stopOpacity={0.95} />
        <stop offset={0.18} stopColor="#193A95" stopOpacity={0.66} />
        <stop offset={0.34} stopColor="#193B97" stopOpacity={0.42} />
        <stop offset={0.5} stopColor="#1A3C99" stopOpacity={0.24} />
        <stop offset={0.66} stopColor="#1A3D9B" stopOpacity={0.1} />
        <stop offset={0.82} stopColor="#1A3D9C" stopOpacity={0.03} />
        <stop offset={1} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="icon-logo-webex-color_svg__H"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(87.129 -1.844 9.621) scale(4.33489 3.81771)"
      >
        <stop offset={0.04} stopColor="#2453D6" stopOpacity={0.6} />
        <stop offset={0.2} stopColor="#2453D6" stopOpacity={0.49} />
        <stop offset={0.52} stopColor="#2453D6" stopOpacity={0.2} />
        <stop offset={0.73} stopColor="#2453D6" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="icon-logo-webex-color_svg__a"
        x1={1.063}
        y1={15.159}
        x2={18.982}
        y2={7.916}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#1CBBF3" />
        <stop offset={0.34} stopColor="#1F99A5" />
        <stop offset={0.45} stopColor="#209CA6" />
        <stop offset={0.51} stopColor="#24A4AA" />
        <stop offset={0.57} stopColor="#2BB3B0" />
        <stop offset={0.6} stopColor="#30BDB4" />
        <stop offset={0.79} stopColor="#38BA90" />
        <stop offset={1} stopColor="#60F05A" />
      </linearGradient>
      <linearGradient
        id="icon-logo-webex-color_svg__q"
        x1={2.215}
        y1={3.634}
        x2={10.121}
        y2={10.951}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#2BF9FF" />
        <stop offset={0.27} stopColor="#29F9FF" stopOpacity={0.64} />
        <stop offset={0.4} stopColor="#27FAFF" stopOpacity={0.3} />
        <stop offset={0.49} stopColor="#26FAFF" stopOpacity={0.08} />
        <stop offset={0.54} stopColor="#26FAFF" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="icon-logo-webex-color_svg__r"
        x1={-2.264}
        y1={15.578}
        x2={6.969}
        y2={12.962}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#26FAFF" />
        <stop offset={0.06} stopColor="#26FAFF" stopOpacity={0.88} />
        <stop offset={0.24} stopColor="#26FAFF" stopOpacity={0.51} />
        <stop offset={0.39} stopColor="#26FAFF" stopOpacity={0.23} />
        <stop offset={0.5} stopColor="#26FAFF" stopOpacity={0.06} />
        <stop offset={0.55} stopColor="#26FAFF" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="icon-logo-webex-color_svg__s"
        x1={0.182}
        y1={15.265}
        x2={10.588}
        y2={11.55}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21DEFF" />
        <stop offset={0.04} stopColor="#21DEFF" stopOpacity={0.88} />
        <stop offset={0.17} stopColor="#21DEFF" stopOpacity={0.51} />
        <stop offset={0.27} stopColor="#21DEFF" stopOpacity={0.23} />
        <stop offset={0.34} stopColor="#21DEFF" stopOpacity={0.06} />
        <stop offset={0.38} stopColor="#21DEFF" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="icon-logo-webex-color_svg__t"
        x1={10.084}
        y1={9.879}
        x2={15.905}
        y2={19.372}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.62} stopColor="#21DEFF" stopOpacity={0} />
        <stop offset={0.66} stopColor="#21DEFF" stopOpacity={0.06} />
        <stop offset={0.73} stopColor="#21DEFF" stopOpacity={0.23} />
        <stop offset={0.83} stopColor="#21DEFF" stopOpacity={0.51} />
        <stop offset={0.96} stopColor="#21DEFF" stopOpacity={0.88} />
        <stop offset={1} stopColor="#21DEFF" />
      </linearGradient>
      <linearGradient
        id="icon-logo-webex-color_svg__u"
        x1={16.541}
        y1={13.439}
        x2={15.629}
        y2={8.971}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#44B044" />
        <stop offset={0.11} stopColor="#44B044" stopOpacity={0.8} />
        <stop offset={0.27} stopColor="#44B044" stopOpacity={0.52} />
        <stop offset={0.43} stopColor="#44B044" stopOpacity={0.29} />
        <stop offset={0.58} stopColor="#44B044" stopOpacity={0.13} />
        <stop offset={0.7} stopColor="#44B044" stopOpacity={0.03} />
        <stop offset={0.8} stopColor="#44B044" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="icon-logo-webex-color_svg__v"
        x1={3.78}
        y1={8.786}
        x2={23.052}
        y2={15.164}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3066FB" />
        <stop offset={0.38} stopColor="#2F64F7" />
        <stop offset={0.74} stopColor="#2857D9" />
        <stop offset={1} stopColor="#3C7EFF" />
      </linearGradient>
      <linearGradient
        id="icon-logo-webex-color_svg__I"
        x1={16.308}
        y1={0.943}
        x2={11.437}
        y2={12.973}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3774FF" />
        <stop offset={0.05} stopColor="#3774FF" stopOpacity={0.91} />
        <stop offset={0.16} stopColor="#3774FF" stopOpacity={0.66} />
        <stop offset={0.32} stopColor="#3774FF" stopOpacity={0.28} />
        <stop offset={0.43} stopColor="#3774FF" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgIconLogoWebexColor;
