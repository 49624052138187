import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { margin500 } from "@happeouikit/layout";
import { sansFamily, TinyText } from "@happeouikit/typography";
import {
  active,
  alert,
  gray04,
  gray08,
  lighten90,
  white,
} from "@happeouikit/colors";

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

const TabItem = ({
  label,
  onClickTabItem,
  isActive,
  variant,
  notification,
  icon,
  index,
  routePath,
  transparent,
  href,
  scrollToActive,
  count,
  ...rest
}) => {
  const ref = useRef();
  const onClick = (e) => {
    if (!href) {
      e.preventDefault();
    }
    onClickTabItem(index, routePath);
  };

  const handleKeyDown = (event) => {
    if (event.key.match(/enter|spacebar|\W{1}/i)) {
      onClick(event);
    } else if (event.key.match(/arrowright/i)) {
      event.preventDefault();

      const {
        current: { nextElementSibling, parentElement },
      } = ref;

      if (nextElementSibling) {
        nextElementSibling?.focus();
      } else {
        parentElement?.firstElementChild?.focus();
      }
    } else if (event.key.match(/arrowleft/i)) {
      event.preventDefault();

      const {
        current: { previousElementSibling, parentElement },
      } = ref;

      if (previousElementSibling) {
        previousElementSibling?.focus();
      } else {
        parentElement?.lastElementChild?.focus();
      }
    }
  };

  useEffect(() => {
    if (!scrollToActive) return;
    if (isActive && ref.current) {
      ref.current.scrollIntoView({ block: "nearest", inline: "nearest" });
    }
  }, [scrollToActive, isActive]);

  return (
    <Tab
      ref={ref}
      aria-current={isActive && "page"}
      role="tab"
      tabIndex="0"
      aria-selected={isActive}
      isActive={isActive}
      variant={variant}
      onKeyDown={handleKeyDown}
      onClick={onClick}
      transparent={transparent}
      data-tracker={rest["data-tracker"]}
    >
      <ConditionalWrap
        condition={href !== undefined}
        wrap={(children) => (
          <a href={href} tabIndex="0">
            {children}
          </a>
        )}
      >
        {icon}
        {label}
        {notification ? (
          <Notification variant={variant}>{notification}</Notification>
        ) : (
          ""
        )}
        {count ? ` (${count})` : ""}
      </ConditionalWrap>
    </Tab>
  );
};

const tabVariation = {
  primary: {
    background: "transparent",
    color: gray04,
    activeColor: active,
    activeBackground: "transparent",
    hoverColor: active,
    hoverBackground: "transparent",
    radius: "0",
    padding: "8px 0",
    marginBottom: "-1px",
    borderBottom: "4px solid transparent",
    activeBorder: `4px solid ${active}`,
  },
  secondary: {
    background: gray08,
    color: gray04,
    activeColor: white,
    activeBackground: active,
    hoverColor: gray04,
    hoverBackground: lighten90(active),
    radius: "100px",
    padding: "8px 16px",
    marginBottom: "0",
    borderBottom: 0,
    activeBorder: "1px solid transparent",
  },
};

const Tab = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${margin500};
  font-family: ${sansFamily};
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  fill: ${gray04};

  &:last-child {
    margin-right: 0;
  }

  &:focus-visible {
    outline: 2px solid ${active};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  svg {
    margin-right: 8px;
  }

  ${({ isActive, variant, transparent }) => css`
    color: ${tabVariation[variant].color};
    background-color: ${transparent
      ? "transparent"
      : tabVariation[variant].background};
    border-radius: ${tabVariation[variant].radius};
    padding: ${tabVariation[variant].padding};
    margin-bottom: ${tabVariation[variant].marginBottom};
    border-bottom: ${tabVariation[variant].borderBottom};
    outline: 0;

    ${isActive
      ? `
        color: ${tabVariation[variant].activeColor};
        background-color: ${tabVariation[variant].activeBackground};
        border-bottom: ${tabVariation[variant].activeBorder};
        fill: ${white};

        &:focus-visible {
          outline-offset: 2px;
        }
      `
      : `
      &:hover {
        color: ${tabVariation[variant].hoverColor};
        background-color: ${tabVariation[variant].hoverBackground};
        fill: ${gray04};
      };
    `}
  `}
`;

const Notification = styled(TinyText)`
  ${({ variant }) =>
    variant === "secondary" &&
    `
  position: absolute;
  top: -10px;
  right: 0;
  `}
  width: 20px;
  height: 20px;
  color: ${white};
  background: ${alert};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-left: 4px;
`;

export default TabItem;
