import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v5.083A6 6 0 0 0 13.083 18H5l-3 3V6Zm5 8h4c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1Zm8-4H7c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1Zm7.707 4.293a1 1 0 0 1 0 1.414l-3.5 3.5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414l1.293 1.293 2.793-2.793a1 1 0 0 1 1.414 0Z"
    }
  )
);
export {
  a as default
};
