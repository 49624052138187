import * as c from "react";
const v = (e) => /* @__PURE__ */ c.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...e
  },
  /* @__PURE__ */ c.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.5 11.5h-3c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1Zm0 7h-3c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1Zm3 0h3c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1Zm9 0h-3c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1Zm-9-7h3c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1Zm5-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1h-3c-.55 0-1-.45-1-1Z"
    }
  )
);
export {
  v as default
};
