/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const AdobeAftereffect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.188 3h11.625C19.575 3 21 4.462 21 6.27v11.46c0 1.808-1.425 3.27-3.188 3.27H6.188C4.425 21 3 19.538 3 17.73V6.27C3 4.461 4.425 3 6.188 3Z"
      fill="#00005B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.512 14.023h2.861v-.039l.631 1.846c.016.054.07.092.123.085h1.608c.077 0 .1-.039.077-.123l-2.808-7.846c-.007-.039-.046-.062-.1-.062H7.912c-.039.008-.07.046-.062.085 0 .169-.015.338-.046.5-.03.092-.054.161-.077.238l-2.477 7.1c-.023.092 0 .139.085.139h1.446c.07.007.13-.039.146-.108l.585-1.815Zm2.4-1.531H7.958c.115-.323.239-.685.339-1.077.076-.238.149-.476.221-.71l.132-.429c.108-.33.193-.661.262-.992h.015l.092.323c.025.09.05.182.078.277.061.223.13.446.207.685.039.119.075.236.112.353l.111.354c.07.231.139.454.208.662.07.2.13.392.177.554ZM14.904 13.33h2.439c.103 0 .202.008.298.016a3.208 3.208 0 0 0 .71-.008l.115-.008c.062-.004.108-.008.138-.008.054 0 .1-.046.108-.1l.01-.115.02-.208a9.06 9.06 0 0 0 .016-.492c0-.315-.046-.63-.146-.938a2.815 2.815 0 0 0-.462-.877 2.118 2.118 0 0 0-.807-.616 2.779 2.779 0 0 0-1.193-.238c-.492 0-.923.061-1.284.238a2.787 2.787 0 0 0-.931.731 3.14 3.14 0 0 0-.577 1.054c-.13.4-.192.808-.192 1.223 0 .385.054.761.161 1.123.108.37.285.708.531 1.008.262.315.592.569.97.73.4.177.892.27 1.476.27.37.007.731-.023 1.093-.093.269-.046.523-.123.769-.23a.18.18 0 0 0 .054-.054.385.385 0 0 0 .015-.092v-1.177c0-.077-.023-.093-.061-.062a2.891 2.891 0 0 1-.685.177c-.262.054-.53.077-.8.085a2.392 2.392 0 0 1-.985-.193 1.22 1.22 0 0 1-.561-.461 1.695 1.695 0 0 1-.239-.685Zm1.623-1.123h-1.623c.023-.192.077-.377.17-.554.2-.384.6-.607 1.022-.584a1.018 1.018 0 0 1 1.047.992v.077a.376.376 0 0 1-.177.061c-.093.008-.239.008-.439.008Z"
      fill="#99F"
    />
  </svg>
);

export default AdobeAftereffect;
