import { jsxs as a, jsx as h } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ h("path", { d: "m11.242 10.9-.517 2.2h2.15l.518-2.2h-2.15Z" }),
      /* @__PURE__ */ h(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3.587 20.413C3.979 20.804 4.45 21 5 21h14c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413V5c0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 19 3H5c-.55 0-1.021.196-1.413.587A1.928 1.928 0 0 0 3 5v14c0 .55.196 1.021.587 1.413Zm8.29-12.207a.9.9 0 0 0-1.753-.412L9.817 9.1H8a.9.9 0 1 0 0 1.8h1.393l-.518 2.2H7a.9.9 0 0 0 0 1.8h1.452l-.328 1.394a.9.9 0 0 0 1.752.412l.425-1.806h2.15l-.327 1.394a.9.9 0 0 0 1.752.412l.425-1.806H16a.9.9 0 0 0 0-1.8h-1.275l.517-2.2H17a.9.9 0 0 0 0-1.8h-1.334l.21-.894a.9.9 0 0 0-1.752-.412L13.817 9.1h-2.151l.21-.894Z"
        }
      )
    ]
  }
);
export {
  t as default
};
