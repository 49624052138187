import { jsx as t } from "react/jsx-runtime";
const e = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "m10.6 13.8-2.175-2.175a.89.89 0 0 0-.687-.262.976.976 0 0 0-.688.287.948.948 0 0 0-.275.7c0 .284.092.517.275.7L9.9 15.9a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l5.675-5.675a.894.894 0 0 0 .263-.688.979.979 0 0 0-.288-.687.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275L10.6 13.8Z" })
  }
);
export {
  e as default
};
