import { jsx as h } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M4 20V8h4v12H4Zm5 0v-7h4v7H9Zm7 0V4h4v16h-4Z" })
  }
);
export {
  o as default
};
