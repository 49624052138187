import { jsx as c } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M3 16c-.533 0-1-.2-1.4-.6-.4-.4-.6-.867-.6-1.4v-2a2.014 2.014 0 0 1 .15-.75l3-7.05c.15-.333.4-.617.75-.85C5.25 3.117 5.617 3 6 3h8c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v10.175c0 .267-.054.521-.162.763a2.13 2.13 0 0 1-.438.637l-5.425 5.4c-.25.233-.546.375-.887.425a1.582 1.582 0 0 1-.988-.175 1.513 1.513 0 0 1-.688-.7 1.32 1.32 0 0 1-.087-.925L8.45 16H3ZM20 3c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v9c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 20 16c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0 1 18 14V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 20 3Z" })
  }
);
export {
  o as default
};
