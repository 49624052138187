import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 32 32",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M2.668 16c0-7.36 5.973-13.333 13.333-13.333S29.335 8.64 29.335 16 23.36 29.333 16 29.333C8.641 29.333 2.668 23.36 2.668 16Zm15.213 8.893v-.773c2.32-.44 4.147-1.773 4.174-4.213 0-3.36-2.88-4.52-5.574-5.214-2.693-.693-3.56-1.426-3.56-2.546 0-1.28 1.2-2.187 3.2-2.187 1.68 0 2.52.653 2.827 1.667.133.48.533.826 1.027.826h.346c.787 0 1.347-.773 1.08-1.506-.506-1.414-1.666-2.56-3.506-3.014v-.826c0-.974-.8-1.774-1.774-1.774h-.013c-.973 0-1.773.8-1.773 1.774v.8c-2.294.493-4.147 1.986-4.147 4.28 0 2.733 2.267 4.093 5.573 4.893 2.974.707 3.56 1.747 3.56 2.853 0 .814-.586 2.12-3.2 2.12-1.946 0-2.96-.693-3.346-1.693-.187-.467-.587-.8-1.08-.8h-.32c-.8 0-1.374.813-1.067 1.547.667 1.64 2.24 2.613 4.013 2.986v.8c0 .974.8 1.774 1.774 1.774h.013c.973 0 1.773-.8 1.773-1.774Z" })
);
export {
  c as default
};
