/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Atlassian = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.723 8.945c.12-2.017.761-3.968 1.862-5.662a.498.498 0 0 1 .898 0l8.478 16.952a.545.545 0 0 1-.454.76h-6.151a.529.529 0 0 1-.47-.294l-2.981-5.914a11.652 11.652 0 0 1-1.182-5.842ZM8.139 11.146c.08.035.152.09.205.16 2.33 2.49 3.133 6.673 1.807 9.395a.51.51 0 0 1-.473.299H3.527a.528.528 0 0 1-.47-.765l4.416-8.834a.511.511 0 0 1 .666-.255Z" />
  </svg>
);

export default Atlassian;
