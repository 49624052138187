import { jsx as l } from "react/jsx-runtime";
const t = (e) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ l(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2ZM10.4 7.777a.9.9 0 0 1-.026 1.272L7.3 12l3.073 2.95a.9.9 0 1 1-1.246 1.3l-3.75-3.6A.896.896 0 0 1 5.1 12c0-.236.093-.473.277-.65l3.75-3.6a.9.9 0 0 1 1.272.027Zm3.228 1.272a.9.9 0 0 1 1.246-1.298l3.75 3.6a.895.895 0 0 1 .233.926.895.895 0 0 1-.233.372l-3.75 3.6a.9.9 0 1 1-1.246-1.298L16.7 12l-3.073-2.952Z"
      }
    )
  }
);
export {
  t as default
};
