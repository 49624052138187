import { jsx as c } from "react/jsx-runtime";
const A = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M19 8c.283 0 .52-.096.712-.287A.968.968 0 0 0 20 7V5h2c.283 0 .52-.096.712-.288A.965.965 0 0 0 23 4a.968.968 0 0 0-.288-.713A.967.967 0 0 0 22 3h-2V1a.968.968 0 0 0-.288-.713A.967.967 0 0 0 19 0a.967.967 0 0 0-.712.287A.968.968 0 0 0 18 1v2h-2a.967.967 0 0 0-.712.287A.968.968 0 0 0 15 4c0 .283.096.52.288.712A.965.965 0 0 0 16 5h2v2c0 .283.096.521.288.713A.967.967 0 0 0 19 8Zm-7 4c.55 0 1.021-.196 1.413-.588.391-.391.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 12 8c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 10 10c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm0 9.625c-.133 0-.267-.025-.4-.075a1.2 1.2 0 0 1-.35-.2C8.817 19.2 7 17.204 5.8 15.363 4.6 13.521 4 11.8 4 10.2c0-2.5.804-4.492 2.413-5.975C8.021 2.742 9.883 2 12 2c.467 0 .93.037 1.388.112a6.78 6.78 0 0 1 1.337.363A1.93 1.93 0 0 0 14 4c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588h1v1c0 .55.196 1.02.587 1.412.392.392.863.588 1.413.588.167 0 .325-.017.475-.05.15-.033.292-.083.425-.15a10.012 10.012 0 0 1 .1 1.4c0 1.6-.6 3.321-1.8 5.163-1.2 1.841-3.017 3.837-5.45 5.987a1.2 1.2 0 0 1-.35.2c-.133.05-.267.075-.4.075Z" })
  }
);
export {
  A as default
};
