import { jsx as s } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ s("path", { d: "M7 18c-1.1 0-2.042-.392-2.825-1.175C3.392 16.042 3 15.1 3 14s.392-2.042 1.175-2.825C4.958 10.392 5.9 10 7 10s2.042.392 2.825 1.175C10.608 11.958 11 12.9 11 14s-.392 2.042-1.175 2.825C9.042 17.608 8.1 18 7 18Zm4-8c-1.1 0-2.042-.392-2.825-1.175C7.392 8.042 7 7.1 7 6s.392-2.042 1.175-2.825C8.958 2.392 9.9 2 11 2s2.042.392 2.825 1.175C14.608 3.958 15 4.9 15 6s-.392 2.042-1.175 2.825C13.042 9.608 12.1 10 11 10Zm6 11c-1.1 0-2.042-.392-2.825-1.175C13.392 19.042 13 18.1 13 17s.392-2.042 1.175-2.825C14.958 13.392 15.9 13 17 13s2.042.392 2.825 1.175C20.608 14.958 21 15.9 21 17s-.392 2.042-1.175 2.825C19.042 20.608 18.1 21 17 21Z" })
  }
);
export {
  o as default
};
