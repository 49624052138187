import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v5.7a5.78 5.78 0 0 0-.962-.375A7.447 7.447 0 0 0 20 11.1V8l-7.475 4.675c-.083.05-.171.087-.263.112a.981.981 0 0 1-.262.038.981.981 0 0 1-.262-.038 1.026 1.026 0 0 1-.263-.112L4 8v10h8c0 .35.025.687.075 1.012.05.325.125.655.225.988H4Zm8-9 8-5H4l8 5Zm7 12c-1.383 0-2.562-.487-3.537-1.462C14.488 20.563 14 19.383 14 18s.488-2.563 1.463-3.538C16.438 13.487 17.617 13 19 13s2.563.487 3.538 1.462C23.513 15.437 24 16.617 24 18s-.487 2.563-1.462 3.538C21.563 22.513 20.383 23 19 23Zm-2.5-4.5h5a.48.48 0 0 0 .35-.15.48.48 0 0 0 0-.7.48.48 0 0 0-.35-.15h-5a.48.48 0 0 0-.35.15.48.48 0 0 0 0 .7c.1.1.217.15.35.15ZM4 18v-7 .075V8v.25V6.8c0-.183 0-.183 0 0V6v.8-.013V8.25 8v10Z" })
  }
);
export {
  t as default
};
