import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "m10.95 13.7-1.4-1.425c-.2-.2-.437-.3-.712-.3-.275 0-.513.1-.713.3-.2.2-.3.442-.3.725 0 .284.092.517.275.7l2.15 2.15c.2.2.433.3.7.3.267 0 .5-.1.7-.3l4.25-4.225c.2-.2.3-.437.3-.713 0-.274-.1-.512-.3-.712-.2-.2-.442-.3-.725-.3a.948.948 0 0 0-.7.275L10.95 13.7ZM12 22c-1.25 0-2.42-.237-3.512-.712a9.142 9.142 0 0 1-2.85-1.926 9.143 9.143 0 0 1-1.926-2.85A8.709 8.709 0 0 1 3 13c0-1.25.237-2.42.712-3.513a9.159 9.159 0 0 1 1.926-2.85 9.138 9.138 0 0 1 2.85-1.924A8.698 8.698 0 0 1 12 4c1.25 0 2.421.238 3.513.713a9.152 9.152 0 0 1 2.85 1.924 9.167 9.167 0 0 1 1.925 2.85A8.715 8.715 0 0 1 21 13c0 1.25-.237 2.42-.712 3.512a9.151 9.151 0 0 1-1.925 2.85 9.156 9.156 0 0 1-2.85 1.926A8.715 8.715 0 0 1 12 22ZM3.425 7.325a.89.89 0 0 1-.687.262.976.976 0 0 1-.688-.287.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7l2.875-2.875a.894.894 0 0 1 .688-.263.978.978 0 0 1 .687.288.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L3.425 7.325ZM20.55 7.3l-2.875-2.875a.891.891 0 0 1-.262-.688.975.975 0 0 1 .287-.687.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.875 2.875a.893.893 0 0 1 .263.687.979.979 0 0 1-.288.688.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM12 20c1.95 0 3.604-.68 4.962-2.038C18.321 16.604 19 14.95 19 13c0-1.95-.68-3.604-2.038-4.963C15.604 6.68 13.95 6 12 6c-1.95 0-3.604.68-4.962 2.037C5.679 9.396 5 11.05 5 13c0 1.95.68 3.604 2.038 4.962C8.396 19.321 10.05 20 12 20Z" })
  }
);
export {
  t as default
};
