import { jsx as c } from "react/jsx-runtime";
const a = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "m5.93 7.83-3.65 3.66c-.78.78-.78 2.05 0 2.83l3.66 3.66c.78.78 2.05.78 2.83 0l3.66-3.65c.78-.78.78-2.05 0-2.83L8.76 7.82c-.79-.78-2.05-.78-2.83.01Zm13.43-1.19A8.95 8.95 0 0 0 13 4v-.83c0-.89-1.08-1.34-1.71-.71L9.47 4.29a.996.996 0 0 0 0 1.41l1.83 1.83c.62.63 1.7.19 1.7-.7V6c2.02 0 4.03.86 5.45 2.61 2.05 2.52 2.05 6.27 0 8.79a6.985 6.985 0 0 1-7.74 2.21.998.998 0 0 0-1.02.26c-.5.5-.34 1.39.34 1.62a8.95 8.95 0 0 0 9.33-2.13 8.98 8.98 0 0 0 0-12.72Z" })
  }
);
export {
  a as default
};
