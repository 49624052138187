import { jsx as h } from "react/jsx-runtime";
const i = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h(
      "path",
      {
        d: "M8.25 7.625V2h7.5v5.625h-7.5Zm7.5 3.125H22V22H2V10.75h6.25v5.625h7.5V10.75Z",
        fill: "#000"
      }
    )
  }
);
export {
  i as default
};
