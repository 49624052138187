/* eslint-disable import/prefer-default-export */

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getAnchorPosition = (menuPosition: string) => {
  const split = menuPosition.split(" ");
  if (split.length === 2) {
    return menuPosition;
  }

  const pos = split.length === 1 ? split[0] : split[1];
  const translatedPos = pos === "left" ? "right" : "left";
  return `bottom ${translatedPos}`;
};
