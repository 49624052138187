import { jsx as v } from "react/jsx-runtime";
const l = (e) => /* @__PURE__ */ v(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ v(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M17.873 12.686c1.326.11 3.642-1.05 4.084-2.155-2.14 6.054-3.775 10.442-7.933 10.442H5.646C3.657 20.973 2 19.242 2 17.216v-5.745c.11-2.32 1.105-3.83 2.983-4.972.923-.56 1.956-.878 2.982-1.192 1.266-.388 2.52-.773 3.537-1.607-.441 1.326-1.03 1.989-1.915 2.946h.037l-.037.037C13.012 6.425 17.174 5.615 19.2 3c0 0 .515 1.105.515 2.946 0 1.658-.222 4.126-1.842 6.74Zm-13.848.663h5.488v2.284H4.025v-2.284Zm9.613 5.561H10.36v-2.504h5.488v.295a2.217 2.217 0 0 1-2.21 2.21Zm-3.278-5.56h5.488v2.283H10.36v-2.284ZM5.5 10.107h6.408c2.186-.036 3.435-.325 3.925-.438l.016-.004v2.873H4.062v-.994a1.438 1.438 0 0 1 1.437-1.437Zm.368 8.84a1.847 1.847 0 0 1-1.842-1.842v-.627h5.488v2.468H5.867Z"
      }
    )
  }
);
export {
  l as default
};
