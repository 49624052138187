import { jsx as A } from "react/jsx-runtime";
const c = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M12 21a5.77 5.77 0 0 1-3.025-.824A6.179 6.179 0 0 1 6.8 18H4.975a.926.926 0 0 1-.7-.288A.99.99 0 0 1 4 17c0-.284.096-.521.287-.712A.968.968 0 0 1 5 16h1.1c-.05-.384-.079-.713-.087-.988A32.934 32.934 0 0 1 6 14H4.975a.926.926 0 0 1-.7-.288A.99.99 0 0 1 4 13a.97.97 0 0 1 .287-.713A.97.97 0 0 1 5 12h1c0-.45.004-.821.013-1.113.008-.292.037-.587.087-.887H4.975a.926.926 0 0 1-.7-.288A.99.99 0 0 1 4 9a.97.97 0 0 1 .287-.713A.97.97 0 0 1 5 8h1.8c.233-.4.496-.763.788-1.088A5.077 5.077 0 0 1 8.6 6.05l-.925-.95a.971.971 0 0 1-.262-.713A.975.975 0 0 1 7.7 3.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l1.45 1.45c.383-.134.858-.2 1.425-.2.567 0 1.042.066 1.425.2l1.5-1.475a.975.975 0 0 1 .713-.263.978.978 0 0 1 .687.288.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-.95.95c.383.25.73.537 1.038.862.308.325.579.688.812 1.088h1.825c.283 0 .517.095.7.287.183.192.275.43.275.713s-.096.52-.288.712A.965.965 0 0 1 19 10h-1.1c.05.383.08.7.088.95.008.25.012.6.012 1.05h1.025c.283 0 .517.095.7.287.183.192.275.43.275.713s-.096.52-.288.712A.965.965 0 0 1 19 14h-1c0 .4-.004.737-.012 1.012-.009.275-.038.604-.088.988h1.125c.283 0 .517.096.7.288A.99.99 0 0 1 20 17c0 .283-.096.52-.288.712A.965.965 0 0 1 19 18h-1.8a6.178 6.178 0 0 1-2.175 2.175A5.77 5.77 0 0 1 12 21Zm-1-5h2.025a.926.926 0 0 0 .7-.287A.99.99 0 0 0 14 15a.97.97 0 0 0-.287-.713A.97.97 0 0 0 13 14h-2.025a.928.928 0 0 0-.7.287A.993.993 0 0 0 10 15c0 .283.096.52.288.712A.965.965 0 0 0 11 16Zm0-4h2.025a.926.926 0 0 0 .7-.287A.99.99 0 0 0 14 11a.97.97 0 0 0-.287-.713A.97.97 0 0 0 13 10h-2.025a.928.928 0 0 0-.7.287A.993.993 0 0 0 10 11c0 .283.096.52.288.712A.965.965 0 0 0 11 12Z" })
  }
);
export {
  c as default
};
