import {
  IconArchive,
  IconLogoGAnalytics,
  IconLogoGDrive,
} from "@happeouikit/icons";

export default [
  {
    name: "Item1",
    url: "/item1",
    exact: true,
    id: 1,
    dataTracker: "tracker-item1",
  },
  {
    name: "Item2",
    url: "/item2",
    icon: IconArchive,
    id: 2,
  },
  {
    name: "Item3",
    url: "/item3",
    icon: IconLogoGAnalytics,
    id: 3,
    children: [
      {
        name: "Child31",
        url: "/item3",
        exact: true,
        id: 4,
        dataTracker: "tracker-child31",
      },
      {
        name: "Child32",
        url: "/item3/child32",
        id: 5,
      },
    ],
  },
  {
    name: "Item4",
    url: "/item4",
    icon: IconLogoGDrive,
    id: 6,
    children: [
      {
        name: "Child41",
        url: "/item4/child41",
        id: 7,
      },
      {
        name: "Child42",
        url: "/item4/child42",
        id: 8,
      },
      {
        name: "Child43",
        url: "/item4/child43",
        id: 9,
        children: [
          {
            name: "Child431",
            url: "/item4/child43/child431",
            id: 10,
          },
          {
            name: "Child432",
            url: "/item4/child43/child432",
            id: 11,
          },
        ],
      },
    ],
  },
];
