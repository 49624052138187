import { jsxs as c, jsx as l } from "react/jsx-runtime";
const t = (e) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m18.36 22.07-6.649-6.64-.711-.429h-1v4H8v-4H7c-.55 0-1.02-.196-1.412-.588A1.924 1.924 0 0 1 5 13.001v-2c0-.55.196-1.021.588-1.413.046-.046.093-.09.141-.13L1.36 5.095a1.003 1.003 0 0 1-.274-.7c0-.267.1-.5.3-.7.2-.2.437-.3.712-.3.275 0 .513.1.713.3l1.344 1.343c.015.02.031.038.048.057l8.735 8.727.012.007 2.917 2.913-.003.006 3.922 3.921c.2.2.296.434.288.7-.009.267-.113.5-.313.7-.2.184-.434.28-.7.289a.918.918 0 0 1-.7-.288ZM9.278 13l-2.003-2H7v2h2.278Z"
        }
      ),
      /* @__PURE__ */ l("path", { d: "m16 14.043-2-2V9.55l-1.565.926-1.477-1.476H11l3.475-2.1c.333-.2.671-.2 1.013 0 .341.2.512.492.512.875v6.267ZM17.16 15.202l-.16-.16V8.652c.45.4.813.888 1.088 1.463.275.575.412 1.204.412 1.887s-.137 1.312-.412 1.887a4.614 4.614 0 0 1-.929 1.314Z" })
    ]
  }
);
export {
  t as default
};
