/* eslint-disable import/prefer-default-export */
import { useState, useEffect, Fragment } from "react";
import Textarea from "../src/Textarea";
import { Checkbox } from "../src";

export const TextareaExample = () => {
  const [state, setState] = useState("1\n2\n3\n4\n5\n6");

  return (
    <div>
      <p>maxHeight set to 400 and rows 4 (min height)</p>
      <Textarea
        label="Texarea with state change"
        value={state}
        onChange={(e) => setState(e.target.value)}
        autoHeight
        maxHeight={400}
        rows={4}
      />
      <p>State: {state}</p>
    </div>
  );
};

export const IndeterminateExample = () => {
  const [indeterminate, setIndeterminate] = useState(false);
  const [all, setAll] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(true);

  const handleAllChange = (newValue) => {
    setCheck1(newValue);
    setCheck2(newValue);
  };

  useEffect(() => {
    if (check1 && check2) {
      setIndeterminate(false);
      setAll(true);
    } else if (!check1 && !check2) {
      setIndeterminate(false);
      setAll(false);
    } else {
      setIndeterminate(true);
    }
  }, [check1, check2]);

  return (
    <Fragment>
      <Checkbox
        label="All"
        value="all"
        indeterminate={indeterminate}
        checked={all}
        onChange={handleAllChange}
      />
      <Checkbox
        label="check 1"
        value="check1"
        style={{ marginLeft: 30 }}
        checked={check1}
        onChange={(newValue) => setCheck1(newValue)}
      />
      <Checkbox
        label="check 2"
        value="check2"
        style={{ marginLeft: 30 }}
        checked={check2}
        onChange={(newValue) => setCheck2(newValue)}
      />
    </Fragment>
  );
};
