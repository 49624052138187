import { jsxs as t, jsx as h } from "react/jsx-runtime";
const s = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: [
      /* @__PURE__ */ h("path", { d: "M4.588 21.413C4.979 21.803 5.45 22 6 22h7.55a5.396 5.396 0 0 1-.7-.925 5.759 5.759 0 0 1-.5-1.075H6V4h7v3c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588h3v3c.35 0 .692.03 1.025.088.333.058.658.145.975.262V8.825a1.975 1.975 0 0 0-.575-1.4l-4.85-4.85a1.975 1.975 0 0 0-1.4-.575H6c-.55 0-1.02.196-1.412.587A1.926 1.926 0 0 0 4 4v16c0 .55.196 1.02.588 1.413Z" }),
      /* @__PURE__ */ h("path", { d: "m16.994 19.002-1.962-.017a.977.977 0 0 1-.69-.283.93.93 0 0 1-.3-.707c0-.26.1-.49.3-.69.201-.2.43-.3.69-.3h1.98v-1.98c0-.26.1-.49.3-.69.2-.2.43-.3.69-.3.26 0 .489.1.69.3.2.2.3.43.3.69v1.98h1.98c.259 0 .489.094.69.283.2.188.3.424.3.707 0 .259-.1.489-.3.689-.201.2-.431.3-.69.3l-1.963.018-.017 1.963c0 .259-.094.488-.283.689a.93.93 0 0 1-.707.3c-.26 0-.49-.1-.69-.3-.2-.2-.3-.43-.3-.69l-.018-1.962Z" })
    ]
  }
);
export {
  s as default
};
