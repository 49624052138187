/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleFirebase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.008 18.137 7.47 2.385a.455.455 0 0 1 .85-.145l2.546 4.749 1.014-1.932a.455.455 0 0 1 .805 0l6.87 13.08H5.008Z"
      fill="#FFA000"
    />
    <path d="m13.513 12-2.649-5.014-5.856 11.15 8.505-6.135Z" fill="#F57C00" />
    <path
      d="M19.555 18.137 17.67 6.469a.455.455 0 0 0-.77-.245L5.009 18.137l6.58 3.69c.414.23.917.23 1.33 0l6.637-3.69Z"
      fill="#FFCA28"
    />
    <path
      d="M17.67 6.47a.455.455 0 0 0-.77-.246L14.54 8.59l-1.856-3.533a.455.455 0 0 0-.805 0l-1.014 1.932L8.319 2.24a.455.455 0 0 0-.85.146l-2.461 15.75H5l.008.01.065.032L16.897 6.34a.455.455 0 0 1 .77.245l1.87 11.563.018-.011L17.67 6.469ZM5.03 18.114 7.47 2.497a.455.455 0 0 1 .85-.145L10.866 7.1l1.014-1.932a.455.455 0 0 1 .805 0l1.818 3.458-9.474 9.49Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path
      opacity={0.2}
      d="M12.918 21.716c-.413.23-.916.23-1.33 0l-6.564-3.68-.016.1 6.58 3.69c.414.23.917.23 1.33 0l6.637-3.69-.017-.104-6.62 3.684Z"
      fill="#A52714"
    />
  </svg>
);

export default GoogleFirebase;
