import { jsx as t } from "react/jsx-runtime";
const s = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ t("path", { d: "M14.36 9.265H22l-6.18 4.482-3.813 2.77 4.342-1.128-.529-1.642 2.353 7.265-6.18-4.496-6.18 4.496 2.367-7.265L2 9.25l7.64.014L12.008 2l2.352 7.265Z" })
  }
);
export {
  s as default
};
