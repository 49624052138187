import * as React from "react";
const SvgIconMyDraft = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 33.667a3.208 3.208 0 0 1-2.353-.979 3.211 3.211 0 0 1-.98-2.355V3.667c0-.917.326-1.702.98-2.355A3.208 3.208 0 0 1 4 .333h11.958a3.297 3.297 0 0 1 2.334.959l8.083 8.083a3.3 3.3 0 0 1 .958 2.333v18.625c0 .917-.326 1.702-.978 2.355a3.214 3.214 0 0 1-2.355.979H4Zm0-3.334h20V12h-6.667c-.472 0-.867-.16-1.186-.48a1.609 1.609 0 0 1-.48-1.187V3.667H4v26.666Zm0 0V3.667v26.666Z"
      fill="#4A5464"
    />
  </svg>
);
export default SvgIconMyDraft;
