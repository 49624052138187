import { jsx as l } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ l("path", { d: "M7 23c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 5 21V3c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 7 1h10c.55 0 1.02.196 1.413.587C18.803 1.98 19 2.45 19 3v4h-2V6H7v12h10v-1h2v4c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 17 23H7Zm10.45-7a.484.484 0 0 1-.337-.125.509.509 0 0 1-.163-.325l-.1-.8a2.301 2.301 0 0 1-.35-.162c-.1-.059-.2-.138-.3-.238l-.75.325a.463.463 0 0 1-.35.012.509.509 0 0 1-.275-.237l-.55-.975a.483.483 0 0 1-.063-.35.528.528 0 0 1 .188-.3l.65-.475a1.551 1.551 0 0 1-.05-.375c0-.117.017-.242.05-.375l-.625-.475a.532.532 0 0 1-.188-.313.509.509 0 0 1 .063-.362l.575-.95a.54.54 0 0 1 .275-.213.463.463 0 0 1 .35.013l.7.3c.1-.083.2-.15.3-.2.1-.05.217-.1.35-.15l.1-.8a.509.509 0 0 1 .163-.325A.483.483 0 0 1 17.45 8h1.1c.133 0 .246.042.337.125a.509.509 0 0 1 .163.325l.1.8c.133.05.25.1.35.15.1.05.2.117.3.2l.7-.3a.463.463 0 0 1 .35-.013.54.54 0 0 1 .275.213l.575.95a.509.509 0 0 1 .063.363.532.532 0 0 1-.188.312l-.625.475c.033.133.05.258.05.375 0 .117-.017.242-.05.375l.65.475c.1.083.162.183.188.3a.483.483 0 0 1-.063.35l-.55.975a.509.509 0 0 1-.275.238.463.463 0 0 1-.35-.013l-.75-.325c-.1.1-.2.18-.3.238-.1.058-.217.112-.35.162l-.1.8a.509.509 0 0 1-.163.325.484.484 0 0 1-.337.125h-1.1Zm.55-2.5c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075-.283-.283-.642-.425-1.075-.425-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Z" })
  }
);
export {
  h as default
};
