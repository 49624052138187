import { jsxs as a, jsx as l } from "react/jsx-runtime";
const d = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14.525 14.475A3.372 3.372 0 0 1 12.05 15.5c-.983 0-1.813-.342-2.488-1.025A3.394 3.394 0 0 1 8.55 12c0-.967.337-1.792 1.012-2.475S11.067 8.5 12.05 8.5c.967 0 1.792.342 2.475 1.025A3.372 3.372 0 0 1 15.55 12c0 .967-.342 1.792-1.025 2.475ZM12 13.499a1.499 1.499 0 1 0 0-2.998 1.499 1.499 0 0 0 0 2.998Z"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M10.125 22h3.75c.25 0 .467-.083.65-.25a.997.997 0 0 0 .325-.625l.3-2.325c.2-.083.4-.183.6-.3.2-.117.392-.242.575-.375l2.125.9c.233.083.471.088.713.013a.985.985 0 0 0 .562-.438l1.85-3.2a.94.94 0 0 0 .125-.7.96.96 0 0 0-.375-.6l-1.875-1.425c.033-.117.05-.23.05-.338v-.675c0-.108-.008-.22-.025-.337L21.35 9.9a.96.96 0 0 0 .375-.6.94.94 0 0 0-.125-.7l-1.85-3.225a.93.93 0 0 0-.55-.425 1.119 1.119 0 0 0-.7.025l-2.175.9a8.319 8.319 0 0 0-.562-.375 3.812 3.812 0 0 0-.613-.3l-.3-2.325a.997.997 0 0 0-.325-.625.934.934 0 0 0-.65-.25h-3.75a.934.934 0 0 0-.65.25.997.997 0 0 0-.325.625L8.85 5.2c-.2.083-.4.183-.6.3-.2.117-.392.242-.575.375l-2.175-.9a1.227 1.227 0 0 0-.713-.038.788.788 0 0 0-.537.438L2.4 8.6a.94.94 0 0 0-.125.7c.05.25.175.45.375.6l1.875 1.425a2.385 2.385 0 0 0-.025.337v.675c0 .109.008.221.025.338L2.65 14.1a.96.96 0 0 0-.375.6.94.94 0 0 0 .125.7l1.85 3.225a.93.93 0 0 0 .55.425c.233.067.467.058.7-.025l2.175-.9c.183.133.371.258.563.375.191.117.395.217.612.3l.3 2.325c.033.25.142.458.325.625.183.167.4.25.65.25Zm2.85-2H11l-.325-2.65a5.537 5.537 0 0 1-2.65-1.55L5.55 16.85l-.975-1.7 2.15-1.625c-.083-.25-.142-.5-.175-.75A5.86 5.86 0 0 1 6.5 12c0-.283.017-.55.05-.8s.092-.5.175-.75l-2.15-1.6.975-1.7 2.475 1.025c.367-.367.771-.68 1.213-.938.441-.258.92-.454 1.437-.587l.35-2.65H13l.325 2.65c.517.133.996.329 1.438.587.441.259.845.58 1.212.963l2.475-1.05.975 1.7-2.15 1.625c.083.233.142.479.175.737a6.165 6.165 0 0 1 0 1.575 3.527 3.527 0 0 1-.175.738l2.15 1.625-.975 1.7-2.475-1.025c-.367.367-.77.679-1.212.937a5.6 5.6 0 0 1-1.438.588l-.35 2.65Z"
        }
      )
    ]
  }
);
export {
  d as default
};
