import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M6.5 20c-1.517 0-2.812-.525-3.887-1.575C1.538 17.375 1 16.092 1 14.575c0-1.3.392-2.458 1.175-3.475S3.983 9.433 5.25 9.15c.417-1.533 1.25-2.775 2.5-3.725C9 4.475 10.417 4 12 4c1.95 0 3.604.679 4.962 2.037C18.321 7.396 19 9.05 19 11c1.15.133 2.104.629 2.863 1.487A4.41 4.41 0 0 1 23 15.5c0 1.25-.437 2.313-1.312 3.188S19.75 20 18.5 20h-12Zm3.85-3.425a1.1 1.1 0 0 0 .375-.063.871.871 0 0 0 .325-.212l4.225-4.225c.2-.2.3-.442.3-.725s-.1-.525-.3-.725c-.2-.2-.442-.3-.725-.3s-.525.1-.725.3l-3.5 3.5-1.4-1.4a.96.96 0 0 0-.7-.3c-.267 0-.5.1-.7.3-.2.2-.3.438-.3.713 0 .275.1.512.3.712L9.65 16.3c.1.1.208.17.325.212a1.1 1.1 0 0 0 .375.063ZM6.5 18h12c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775C19.792 13.242 19.2 13 18.5 13H17v-2c0-1.383-.487-2.563-1.462-3.538C14.563 6.487 13.383 6 12 6s-2.562.487-3.537 1.462C7.488 8.437 7 9.617 7 11h-.5c-.967 0-1.792.342-2.475 1.025A3.372 3.372 0 0 0 3 14.5c0 .967.342 1.792 1.025 2.475A3.372 3.372 0 0 0 6.5 18Z" })
  }
);
export {
  t as default
};
