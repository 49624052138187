/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Visa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.112 8.137 5.97 15.634H3.92L2.374 9.65c-.094-.368-.175-.503-.461-.659C1.446 8.74.677 8.502 0 8.355l.045-.218h3.3c.421 0 .8.28.895.765l.816 4.338 2.019-5.103h2.037Zm8.033 5.05c.008-1.979-2.736-2.088-2.718-2.972.006-.269.263-.555.823-.628.278-.036 1.044-.064 1.913.336l.34-1.59A5.194 5.194 0 0 0 15.688 8c-1.916 0-3.265 1.019-3.277 2.478-.012 1.08.963 1.682 1.698 2.04.756.368 1.01.604 1.007.932-.006.503-.603.725-1.162.734-.975.015-1.54-.264-1.991-.474l-.352 1.643c.454.208 1.29.39 2.157.398 2.038 0 3.37-1.006 3.377-2.565Zm5.061 2.447H24l-1.566-7.497H20.78a.883.883 0 0 0-.825.55l-2.91 6.947h2.036l.404-1.12h2.488l.234 1.12Zm-2.163-2.656 1.02-2.815.588 2.814h-1.608Zm-8.16-4.84-1.604 7.496H8.34l1.604-7.497h1.94Z" />
  </svg>
);

export default Visa;
