/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { active, gray01, gray04, gray06 } from "@happeouikit/colors";
import PropTypes from "prop-types";
import { BodyUI, sansFamily } from "@happeouikit/typography";
import { uuidv4 } from "./utils";

const Textarea = ({ cols, rows, label, ...props }) => {
  const id = useState(uuidv4)[0];
  const accessibility = label ? { "aria-labelledby": id } : {};
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${Math.min(
        textarea.scrollHeight,
        props.maxHeight
      )}px`;
    }
  }, [props.maxHeight]);

  const onChange = (event) => {
    // Handle auto height
    if (props.autoHeight) {
      const { target } = event;

      // First set height to inherit to allow textarea to contract
      target.style.height = "inherit";
      target.style.height = `${target.scrollHeight}px`;
    }

    // call props.onChange if exists
    if (typeof props.onChange === "function") {
      props.onChange(event);
    }
  };

  return (
    <>
      {!!label && (
        <Label id={id}>
          <BodyUI bold color={gray01}>{label}</BodyUI>
        </Label>
      )}
      <StyledTextarea
        ref={textareaRef}
        cols={cols}
        rows={rows}
        {...accessibility}
        {...props}
        style={{
          ...props.style,
          maxHeight: props.maxHeight,
        }}
        onChange={onChange}
      />
    </>
  );
};

Textarea.propTypes = {
  rows: PropTypes.number,
  cols: PropTypes.number,
  autoHeight: PropTypes.bool,
  maxHeight: PropTypes.number,
};

Textarea.defaultProps = {
  maxHeight: 9999,
};

const StyledTextarea = styled.textarea`
  font-family: ${sansFamily};
  border-radius: 4px;
  ${(props) => (props.cols ? "" : "width: 100%;")};
  ${(props) => (props.rows ? "" : "height: 100%;")};
  padding: 10px 12px;
  border: solid 1px ${gray06};
  box-sizing: border-box;
  box-shadow: none;
  font-size: 14px;

  :hover {
    border-color: ${gray04};
  }

  :active {
    outline: none;
    border-color: ${active};
  }

  :focus {
    outline: none;
    border-color: ${active};
  }
`;

const Label = styled.div`
  margin: 4px 0;
`;

export default Textarea;
