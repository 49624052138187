import { jsxs as i, jsx as t } from "react/jsx-runtime";
const l = (h) => /* @__PURE__ */ i(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M1 3.75H.75v17.1h22.5V3.75H1Z",
          stroke: "#000",
          strokeOpacity: 0.2,
          strokeWidth: 0.5
        }
      ),
      /* @__PURE__ */ t("path", { d: "M1 4h22v16.6H1V4Z", fill: "#fff" }),
      /* @__PURE__ */ t("path", { d: "M1 10.035h22v4.53H1v-4.53Z", fill: "#003580" }),
      /* @__PURE__ */ t("path", { d: "M7.033 4h4.5v16.6H7.028L7.033 4Z", fill: "#003580" })
    ]
  }
);
export {
  l as default
};
