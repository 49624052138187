import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/icons/package.json";
import { IconGrid } from "../../../../../packages/icons/docs/IconGrid";
import * as Colored from "../../../../../packages/icons/src/icons/App/Colored";
import * as Monochrome from "../../../../../packages/icons/src/icons/App/Monochrome";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  IconGrid,
  Colored,
  Monochrome,
  React
};