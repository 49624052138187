import { jsxs as d, jsx as l } from "react/jsx-runtime";
const h = (e) => /* @__PURE__ */ d(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z",
          fill: "#C84031"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M16.8 9.5H15L13.8 7h-1.2l1.2 2.5H12L10.8 7H9.6l1.2 2.5H9L7.8 7h-.6C6.537 7 6 7.56 6 8.25v7.5c0 .69.537 1.25 1.2 1.25h9.6c.663 0 1.2-.56 1.2-1.25V7h-2.4l1.2 2.5Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  h as default
};
