import { jsx as h } from "react/jsx-runtime";
const Z = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M19 9V7h2v2h-2Zm-8 0V7h2v2h-2Zm0 4v-2h2v2h-2Zm8 0v-2h2v2h-2Zm-4-8V3h2v2h-2Zm4 0V3h2v2h-2Zm-8 0V3h2v2h-2ZM7 5V3h2v2H7Zm8 8v-2h2v2h-2Zm-8 8v-2h2v2H7Zm4 0v-2h2v2h-2Zm-7 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 20V4c0-.283.096-.521.288-.713A.967.967 0 0 1 4 3a.97.97 0 0 1 .713.287A.97.97 0 0 1 5 4v16c0 .283-.096.52-.287.712A.968.968 0 0 1 4 21Zm11 0v-2h2v2h-2Zm4-4v-2h2v2h-2ZM7 13v-2h2v2H7Zm12 8v-2h2v2h-2Zm-8-4v-2h2v2h-2Z" })
  }
);
export {
  Z as default
};
