import { jsxs as i, jsx as t } from "react/jsx-runtime";
const e = (s) => /* @__PURE__ */ i(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: [
      /* @__PURE__ */ t("path", { d: "m20.785 11.784-8.01-8.008L12 3l-8.785 8.784a.737.737 0 0 0 0 1.042l5.508 5.507L12 21.611l8.785-8.785a.737.737 0 0 0 0-1.042ZM12 15.056l-2.751-2.75 2.75-2.752 2.752 2.751L12 15.056Z" }),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.5,
          d: "M12 9.554a4.633 4.633 0 0 1-.02-6.533l-6.02 6.02 3.277 3.277L12 9.554Z",
          fill: "url(#jira_svg__a)"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.5,
          d: "m14.759 12.298-2.76 2.758a4.633 4.633 0 0 1 0 6.555l6.036-6.036-3.276-3.277Z",
          fill: "url(#jira_svg__b)"
        }
      ),
      /* @__PURE__ */ i("defs", { children: [
        /* @__PURE__ */ i(
          "linearGradient",
          {
            id: "jira_svg__a",
            x1: 12,
            y1: 7.692,
            x2: 5.959,
            y2: 7.692,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ t("stop", { offset: 0.155, stopOpacity: 0.8 }),
              /* @__PURE__ */ t("stop", { offset: 1, stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ i(
          "linearGradient",
          {
            id: "jira_svg__b",
            x1: 11.999,
            y1: 16.977,
            x2: 18.035,
            y2: 16.977,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ t("stop", { offset: 0.155, stopOpacity: 0.8 }),
              /* @__PURE__ */ t("stop", { offset: 1, stopOpacity: 0 })
            ]
          }
        )
      ] })
    ]
  }
);
export {
  e as default
};
