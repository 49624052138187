import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../../../../../packages/emojis/package.json";
import { shortToUnicode, emojisToShort, removeEmojis } from "../../../../../packages/emojis/src/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  shortToUnicode,
  emojisToShort,
  removeEmojis,
  React
};