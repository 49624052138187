import { jsx as c } from "react/jsx-runtime";
const a = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M18.45 7.5 17 10c1.1 0 2.042.392 2.825 1.175C20.608 11.958 21 12.9 21 14s-.392 2.042-1.175 2.825C19.042 17.608 18.1 18 17 18s-2.042-.392-2.825-1.175C13.392 16.042 13 15.1 13 14c0-.383.046-.738.137-1.063A3.74 3.74 0 0 1 13.55 12l3.175-5.5a.973.973 0 0 1 .85-.5c.383 0 .67.167.863.5a.96.96 0 0 1 .012 1Zm-9 0L8 10c1.1 0 2.042.392 2.825 1.175C11.608 11.958 12 12.9 12 14s-.392 2.042-1.175 2.825C10.042 17.608 9.1 18 8 18s-2.042-.392-2.825-1.175C4.392 16.042 4 15.1 4 14c0-.383.046-.738.138-1.063.091-.324.229-.637.412-.937l3.175-5.5a.973.973 0 0 1 .85-.5c.383 0 .67.167.863.5a.96.96 0 0 1 .012 1Zm7.55 5c-.417 0-.77.146-1.063.438A1.446 1.446 0 0 0 15.5 14c0 .417.146.77.438 1.063.291.291.645.437 1.062.437.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 17 12.5Zm-9 0c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 6.5 14c0 .417.146.77.438 1.063.291.291.645.437 1.062.437.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 8 12.5Z" })
  }
);
export {
  a as default
};
