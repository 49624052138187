import { jsx as c } from "react/jsx-runtime";
const r = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M12 18.6a1.61 1.61 0 0 1-.488-.075 1.454 1.454 0 0 1-.437-.225c-1.35-1.05-2.7-2.37-4.05-3.963C5.675 12.747 5 11.018 5 9.15c0-1.183.213-2.22.638-3.113.425-.891.97-1.637 1.637-2.237a6.755 6.755 0 0 1 2.25-1.35A7.27 7.27 0 0 1 12 2c.817 0 1.642.15 2.475.45.833.3 1.583.75 2.25 1.35.667.6 1.212 1.346 1.637 2.237.426.892.638 1.93.638 3.113 0 1.867-.675 3.596-2.025 5.188-1.35 1.591-2.7 2.912-4.05 3.962-.133.1-.28.175-.438.225A1.61 1.61 0 0 1 12 18.6Zm0-7.6c.55 0 1.02-.196 1.412-.588C13.804 10.021 14 9.55 14 9c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 12 7c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 10 9c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588ZM6 22a.967.967 0 0 1-.713-.288A.968.968 0 0 1 5 21c0-.283.096-.52.287-.712A.967.967 0 0 1 6 20h12c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 18 22H6Z" })
  }
);
export {
  r as default
};
