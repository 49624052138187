import * as e from "react";
const m = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M19 3H5v2h14V3Zm0 18v-2H5v2h14Zm3-4H2V7h20v10Z" })
);
export {
  m as default
};
