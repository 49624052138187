import { jsx as l } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ l("path", { d: "M17.706 8.116a.998.998 0 0 0-1.411-1.41l-4.588 4.588a1 1 0 0 0 0 1.414l4.588 4.588a.998.998 0 0 0 1.411-1.41L13.83 12l3.876-3.885Zm-6 0a.998.998 0 0 0-1.411-1.41l-4.588 4.588a1 1 0 0 0 0 1.414l4.588 4.588a.998.998 0 0 0 1.411-1.41L7.83 12l3.876-3.885Z" })
  }
);
export {
  e as default
};
