import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextZeta, BodyUI } from "@happeouikit/typography";
import {
  active,
  success,
  warn,
  alert,
  gray04,
  white,
} from "@happeouikit/colors";
import { IconError, IconInfoRound, IconCheckCircle } from "@happeouikit/icons";

export const BannerStateMap = {
  info: active,
  success,
  warn,
  error: alert,
};

const IconMap = {
  info: () => <IconInfoRound />,
  success: () => <IconCheckCircle />,
  warn: () => <IconError />,
  error: () => <IconError />,
};

const Banner = ({ state, title, description, icon, action, rightAction }) => {
  return (
    <Container color={BannerStateMap[state]} data-testid="banner_uikit">
      <IconWrapper hasDescription={!!description} fill={BannerStateMap[state]}>
        {icon || IconMap[state]()}
      </IconWrapper>
      <Content>
        <StackedContent>
          {title && <TextZeta bold>{title}</TextZeta>}
          {description && (
            <BodyUI color={gray04} style={{ paddingTop: "4px" }}>
              {description}
            </BodyUI>
          )}
          {action && (
            <ActionWrapper data-test-id="banner-bottom-action-wrapper">
              {action}
            </ActionWrapper>
          )}
        </StackedContent>
        {rightAction && (
          <RightActionWrapper
            hasDescription={!!description}
            data-test-id="banner-right-action-wrapper"
          >
            {rightAction}
          </RightActionWrapper>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border-radius: 6px;
  min-width: 285px;
  padding: 16px 40px 16px 16px;
  position: relative;
  box-shadow: 0px 1px 4px rgba(186, 198, 208, 0.5),
    0px 1px 4px rgba(0, 157, 255, 0.05);
  background-color: ${white};

  :after {
    content: "";
    position: absolute;
    width: 8px;
    left: 0px;
    top: 0;
    bottom: 0;
    background: ${({ color }) => color};
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  padding-top: ${({ hasDescription }) => hasDescription && "10px"};
  padding-left: 10px;
  padding-right: 18px;
  font-size: 24px;

  path {
    fill: ${({ fill }) => fill};
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StackedContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionWrapper = styled.div`
  padding-top: 14px;
  display: flex;
`;

const RightActionWrapper = styled.div`
  padding-left: 14px;
  margin: -6px -24px -6px 0;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasDescription }) => !hasDescription && "center"};
  padding-top: ${({ hasDescription }) => hasDescription && "10px"};
`;

Banner.propTypes = {
  state: PropTypes.oneOf(Object.keys(BannerStateMap)).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.object,
  action: PropTypes.object,
  rightAction: PropTypes.object,
};

Banner.defaultProps = {
  state: "info",
};

export default Banner;
