import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import TranslationProvider from "../../../../../src/gatsby-theme-docz/TranslationProvider";
import pkg from "../../../../../packages/copy-to-clipboard-button/package.json";
import { CopyToClipboardButton } from "../../../../../packages/copy-to-clipboard-button/src/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  TranslationProvider,
  pkg,
  CopyToClipboardButton,
  React
};