import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    fill: "none",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m13.444 12 2.145 2.438 2.883 1.832.503-4.255-.503-4.16-2.939 1.61L13.444 12Z",
      fill: "#00832D"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M1 15.875V19.5c0 .829.675 1.5 1.508 1.5h3.646l.754-2.74-.754-2.385-2.502-.75-2.652.75Z",
      fill: "#0066DA"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M6.154 3 1 8.125l2.652.75 2.502-.75.741-2.353L6.154 3Z",
      fill: "#E94235"
    }
  ),
  /* @__PURE__ */ e.createElement("path", { d: "M6.154 8.125H1v7.75h5.154v-7.75Z", fill: "#2684FC" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m21.766 5.17-3.294 2.685v8.415l3.309 2.698A.755.755 0 0 0 23 18.375V5.75c0-.634-.74-.981-1.234-.58ZM13.444 12v3.875h-7.29V21h10.81c.833 0 1.508-.671 1.508-1.5v-3.23L13.444 12Z",
      fill: "#00AC47"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M16.964 3H6.154v5.125h7.29V12l5.028-4.143V4.5c0-.829-.675-1.5-1.508-1.5Z",
      fill: "#FFBA00"
    }
  )
);
export {
  l as default
};
