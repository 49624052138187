import { jsxs as t, jsx as e } from "react/jsx-runtime";
const s = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e("path", { d: "M9.2 12c0-.133.05-.25.15-.35l2.8-2.8c.15-.15.33-.187.538-.112.208.075.312.229.312.462v5.6c0 .233-.104.387-.312.462-.209.075-.388.038-.538-.112l-2.8-2.8A.48.48 0 0 1 9.2 12Z" }),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4 12a8 8 0 1 0 16 0 8 8 0 0 0-16 0Zm-2 0c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12Z"
        }
      )
    ]
  }
);
export {
  s as default
};
