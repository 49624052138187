import * as e from "react";
const t = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ e.createElement("path", { d: "M11.8 8.6a1 1 0 1 0-1.6-1.2l-3 4a1 1 0 0 0 0 1.2l3 4a1 1 0 0 0 1.6-1.2L9.25 12l2.55-3.4Zm5 0a1 1 0 1 0-1.6-1.2l-3 4a1 1 0 0 0 0 1.2l3 4a1 1 0 0 0 1.6-1.2L14.25 12l2.55-3.4Z" })
);
export {
  t as default
};
