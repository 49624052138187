/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Unsplash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.25 7.625V2h7.5v5.625h-7.5Zm7.5 3.125H22V22H2V10.75h6.25v5.625h7.5V10.75Z" />
  </svg>
);

export default Unsplash;
