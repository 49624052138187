import { jsxs as a, jsx as c } from "react/jsx-runtime";
const o = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ c("path", { d: "M10.62 13.54c.45-.87.78-1.66 1.01-2.4.41.9.93 1.64 1.53 2.15.12.11.26.22.41.32-.87.16-2.07.44-3.34.93l-.11.04c.063-.233.209-.502.365-.789l.135-.251ZM15.8 13.89c1.4 0 1.8.25 1.9.35.042.033.063.066.08.094a.534.534 0 0 0 .01.016c-.01.098-.038.11-.087.129l-.003.001h-.04c-.06.01-.12.02-.19.02-.46 0-1.17-.19-1.9-.51.09-.1.16-.1.23-.1ZM8.05 16.01c.1-.08.27-.19.48-.31-.65 1.19-1.24 1.85-1.69 2 .05-.38.5-1.04 1.21-1.69ZM11.6 8.75l-.05.04c-.23-.9-.24-1.63-.07-2.05.02-.04.05-.09.07-.12l.15.05c.17.24.19.53.09 1.1l-.03.16a4.56 4.56 0 0 0-.083.413c-.022.127-.045.263-.077.407Z" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm12.2 12.25c.44 0 .74-.11.91-.3.18-.18.27-.41.28-.66.03-.2-.02-.39-.12-.55-.29-.47-1.04-.69-2.28-.69-.41 0-.84.02-1.29.07-.32-.18-.61-.38-.87-.58-.63-.52-1.2-1.43-1.6-2.56a.797.797 0 0 0 .013-.047l.027-.093c.33-1.33.64-2.94-.02-3.6a.853.853 0 0 0-.61-.24h-.24c-.37 0-.7.39-.79.77-.37 1.33-.15 2.06.22 3.27v.01c-.25.88-.57 1.9-1.08 2.93-.34.67-.66 1.28-.96 1.8-.4.19-.7.35-.89.49-1.2.75-1.77 1.59-1.88 2.12-.04.19-.02.36.05.54l.03.05.48.31c.12.07.28.11.44.11.81 0 1.73-.95 2.97-3.07a2.542 2.542 0 0 1 .18-.07c1.03-.33 2.31-.56 4.03-.75 1 .51 2.24.74 3 .74Z"
        }
      )
    ]
  }
);
export {
  o as default
};
