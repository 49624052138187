import * as React from "react";
const SvgWikiNotActive = (props) => (
  <svg
    viewBox="0 0 102 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <g filter="url(#wiki-not-active_svg__a)">
      <rect x={4} y={3} width={94} height={100} rx={4} fill="#fff" />
      <rect
        x={90}
        y={11}
        width={84}
        height={49}
        rx={3}
        transform="rotate(90 90 11)"
        fill="#CAD0D8"
      />
      <rect x={12} y={11} width={21} height={51} rx={2} fill="#CAD0D8" />
    </g>
    <defs>
      <filter
        id="wiki-not-active_svg__a"
        x={0}
        y={0}
        width={102}
        height={108}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1380_133752"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_1380_133752"
          result="effect2_dropShadow_1380_133752"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_1380_133752"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgWikiNotActive;
