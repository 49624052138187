import * as React from "react";
const SvgIconWidgetVideo = (props) => (
  <svg
    viewBox="0 0 93 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <g filter="url(#icon-widget-video_svg__a)">
      <g clipPath="url(#icon-widget-video_svg__b)">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
        <rect
          x={-9.025}
          y={-1.447}
          width={110.469}
          height={66.366}
          rx={1.422}
          fill="#fff"
        />
        <rect
          x={6.555}
          y={5.555}
          width={79.639}
          height={42.664}
          rx={1.422}
          fill="#0075DB"
        />
        <rect
          x={6.555}
          y={5.555}
          width={79.639}
          height={42.664}
          rx={1.422}
          fill="#fff"
          fillOpacity={0.9}
        />
        <g filter="url(#icon-widget-video_svg__c)">
          <circle cx={46.375} cy={26.887} r={10.666} fill="#fff" />
        </g>
        <path
          d="M50.36 26.271a.711.711 0 0 1 0 1.232l-5.445 3.144a.711.711 0 0 1-1.066-.616v-6.288c0-.547.592-.89 1.066-.615l5.446 3.143Z"
          fill="#0075DB"
        />
      </g>
    </g>
    <defs>
      <filter
        id="icon-widget-video_svg__a"
        x={0.593}
        y={0.195}
        width={91.563}
        height={54.588}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44131"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44131"
          result="effect2_dropShadow_213_44131"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44131"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-video_svg__c"
        x={32.865}
        y={14.088}
        width={27.021}
        height={27.02}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44131"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44131"
          result="effect2_dropShadow_213_44131"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44131"
          result="shape"
        />
      </filter>
      <clipPath id="icon-widget-video_svg__b">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconWidgetVideo;
