import { jsx as a } from "react/jsx-runtime";
const t = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3 5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v5h2a2 2 0 0 1 2 2v6a3 3 0 0 1-3 3H5a2 2 0 0 1-2-2V5Zm15 14a1 1 0 0 1-1-1v-6h2v6a1 1 0 0 1-1 1ZM6 8a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Z"
      }
    )
  }
);
export {
  t as default
};
