import { jsx as c } from "react/jsx-runtime";
const a = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M7 18c-1.1 0-2.042-.392-2.825-1.175C3.392 16.042 3 15.1 3 14s.392-2.042 1.175-2.825C4.958 10.392 5.9 10 7 10s2.042.392 2.825 1.175C10.608 11.958 11 12.9 11 14s-.392 2.042-1.175 2.825C9.042 17.608 8.1 18 7 18Zm0-2c.55 0 1.02-.196 1.412-.588C8.804 15.021 9 14.55 9 14c0-.55-.196-1.021-.588-1.413A1.925 1.925 0 0 0 7 12c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 5 14c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm9.5-2c-1.533 0-2.833-.533-3.9-1.6-1.067-1.067-1.6-2.367-1.6-3.9s.533-2.833 1.6-3.9C13.667 3.533 14.967 3 16.5 3s2.833.533 3.9 1.6C21.467 5.667 22 6.967 22 8.5s-.533 2.833-1.6 3.9c-1.067 1.067-2.367 1.6-3.9 1.6Zm-2 7a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 11.5 18c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 14.5 15c.833 0 1.542.292 2.125.875S17.5 17.167 17.5 18s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 14.5 21Zm2-9c.983 0 1.813-.337 2.488-1.012S20 9.483 20 8.5c0-.983-.337-1.813-1.012-2.488S17.483 5 16.5 5c-.983 0-1.813.337-2.488 1.012S13 7.517 13 8.5c0 .983.337 1.813 1.012 2.488S15.517 12 16.5 12Zm-2 7a.968.968 0 0 0 .713-.288A.967.967 0 0 0 15.5 18a.967.967 0 0 0-.287-.712A.968.968 0 0 0 14.5 17a.965.965 0 0 0-.712.288.965.965 0 0 0-.288.712c0 .283.096.52.288.712A.965.965 0 0 0 14.5 19Z" })
  }
);
export {
  a as default
};
