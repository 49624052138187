import * as e from "react";
const t = (l) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 32 28",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement("rect", { width: 32, height: 28, fill: "#00A4E9", rx: 2 }), /* @__PURE__ */ e.createElement("g", { fill: "#FFF" }, /* @__PURE__ */ e.createElement(
    "path",
    {
      fillOpacity: 0.5,
      d: "M23.913 17.597c-.314-.13-.822-.13-1.136 0l-4.585 1.89c-.605.25-1.384.392-2.19.392-.81 0-1.589-.142-2.196-.393l-4.584-1.89c-.314-.128-.823-.128-1.137 0l-1.38.57c-.94.386-.94 1.017 0 1.41l7.59 3.13c.942.392 2.469.392 3.409 0l7.591-3.13c.94-.393.94-1.024 0-1.41l-1.382-.57Z"
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fillOpacity: 0.75,
      d: "M23.913 13.097c-.314-.13-.822-.13-1.136 0l-4.585 1.888c-.025.013-.049.02-.074.03-.593.233-1.343.363-2.116.363-.779 0-1.527-.13-2.121-.362-.025-.012-.05-.018-.075-.03l-4.584-1.89c-.314-.128-.823-.128-1.137 0l-1.38.57c-.94.392-.94 1.022 0 1.409l3.514 1.45 4.077 1.683c.47.19 1.088.292 1.707.292.612 0 1.231-.101 1.701-.292l4.078-1.682 3.513-1.451c.94-.387.94-1.017 0-1.41l-1.382-.568Z"
    }
  ), /* @__PURE__ */ e.createElement("path", { d: "m8.654 11.458.786.317.785.312 4.07 1.63c.47.185 1.09.283 1.708.283.612 0 1.231-.098 1.701-.282l4.07-1.631.787-.312.785-.317 1.949-.778c.94-.374.94-.985 0-1.366l-7.591-3.031c-.47-.19-1.089-.283-1.701-.283-.619 0-1.237.092-1.707.283l-7.59 3.031c-.941.38-.941.99 0 1.366l1.948.778Z" })))
);
export {
  t as default
};
