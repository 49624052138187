import { jsx as a } from "react/jsx-runtime";
const c = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M4.025 9a.968.968 0 0 1-.713-.288A.967.967 0 0 1 3.025 8a.97.97 0 0 1 .287-.713A.97.97 0 0 1 4.025 7h1.6l-2.4-2.4a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l2.4 2.4V4a.97.97 0 0 1 .287-.713A.97.97 0 0 1 8.025 3a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v4c0 .283-.096.52-.287.712A.968.968 0 0 1 8.025 9h-4Zm16 0h-4a.968.968 0 0 1-.713-.288.967.967 0 0 1-.287-.712V4a.97.97 0 0 1 .287-.713.97.97 0 0 1 .713-.287.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v1.6l2.4-2.4a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-2.4 2.4h1.6a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288Zm-16.8 11.8a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7l2.4-2.4h-1.6a.968.968 0 0 1-.713-.288.967.967 0 0 1-.287-.712.97.97 0 0 1 .287-.713.97.97 0 0 1 .713-.287h4a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v4c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288.968.968 0 0 1-.713-.288.967.967 0 0 1-.287-.712v-1.6l-2.4 2.4a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Zm12.8.2a.968.968 0 0 1-.713-.288.967.967 0 0 1-.287-.712v-4a.97.97 0 0 1 .287-.713.97.97 0 0 1 .713-.287h4a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288h-1.6l2.4 2.4a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.949.949 0 0 1-.7.275.949.949 0 0 1-.7-.275l-2.4-2.4V20c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288Z" })
  }
);
export {
  c as default
};
