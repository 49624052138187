import { jsxs as t, jsx as e } from "react/jsx-runtime";
const d = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4 12a8 8 0 1 0 16 0 8 8 0 0 0-16 0Zm-2 0c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12Z"
        }
      ),
      /* @__PURE__ */ e("path", { d: "M8.925 11.995c0-.283.092-.517.275-.7l3.1-3.1a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275.2.2.3.438.3.713a.932.932 0 0 1-.275.687l-2.4 2.4L13.7 14.37a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7c-.2.2-.437.3-.712.3a.934.934 0 0 1-.688-.275l-3.1-3.1a.948.948 0 0 1-.275-.7Z" })
    ]
  }
);
export {
  d as default
};
