import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/layout/package.json";
import PaddingExample from "../../../../../packages/layout/src/examples/PaddingExample";
import MarginExample from "../../../../../packages/layout/src/examples/MarginExample";
import PopoverExample from "../../../../../packages/layout/src/examples/PopoverExample";
import { margin100, margin200, margin300, margin400, margin500, margin600, margin700, margin800, margin900, margin1000 } from "../../../../../packages/layout/src/margins";
import { padding100, padding200, padding300, padding400, padding600, padding800 } from "../../../../../packages/layout/src/paddings";
import { Container, Col, Row } from "../../../../../packages/layout/src/grid";
import { Spacer } from "../../../../../packages/layout/src/Spacer";
import Popover from "../../../../../packages/layout/src/Popover";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  PaddingExample,
  MarginExample,
  PopoverExample,
  margin100,
  margin200,
  margin300,
  margin400,
  margin500,
  margin600,
  margin700,
  margin800,
  margin900,
  margin1000,
  padding100,
  padding200,
  padding300,
  padding400,
  padding600,
  padding800,
  Container,
  Col,
  Row,
  Spacer,
  Popover,
  React
};