import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 17.675c.283 0 .52-.096.713-.288a.968.968 0 0 0 .287-.712v-3.2l.9.9a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7L12.7 10.4a.96.96 0 0 0-.7-.3c-.267 0-.5.1-.7.3l-2.6 2.575a.948.948 0 0 0-.275.7c0 .283.092.517.275.7a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l.9-.9v3.2c0 .283.096.52.287.712.192.192.43.288.713.288ZM5 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19V6.525c0-.233.038-.458.112-.675.075-.217.188-.417.338-.6L4.7 3.725c.183-.233.412-.413.688-.538C5.662 3.063 5.95 3 6.25 3h11.5c.3 0 .587.063.863.188.274.124.504.304.687.537l1.25 1.525c.15.183.262.383.337.6.075.217.113.442.113.675V19c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21H5Zm.4-15h13.2l-.85-1H6.25L5.4 6Z" })
  }
);
export {
  t as default
};
