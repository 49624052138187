import { jsx as h } from "react/jsx-runtime";
const o = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M17 22v-3H7c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 5 17V7H2a.968.968 0 0 1-.712-.287A.968.968 0 0 1 1 6c0-.283.096-.52.288-.713A.968.968 0 0 1 2 5h3V2c0-.283.096-.52.287-.712A.968.968 0 0 1 6 1c.283 0 .52.096.713.288.191.191.287.429.287.712v15h15c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 22 19h-3v3c0 .283-.096.52-.288.712A.968.968 0 0 1 18 23a.968.968 0 0 1-.712-.288A.968.968 0 0 1 17 22Zm0-7V7H9V5h8c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v8h-2Z" })
  }
);
export {
  o as default
};
