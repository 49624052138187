/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftAccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.116 15.07v5.116c0 1.413 3.78 2.558 8.442 2.558 4.663 0 8.442-1.145 8.442-2.558V15.07H6.116Z"
      fill="#38404C"
    />
    <path
      d="M6.116 9.442v5.628c0 1.412 3.78 2.558 8.442 2.558 4.663 0 8.442-1.146 8.442-2.558V9.442H6.116Z"
      fill="#596578"
    />
    <path
      d="M6.116 3.814v5.628c0 1.412 3.78 2.558 8.442 2.558C19.221 12 23 10.854 23 9.442V3.814H6.116Z"
      fill="#68778D"
    />
    <path
      d="M14.558 6.372C19.22 6.372 23 5.227 23 3.814c0-1.413-3.78-2.558-8.442-2.558-4.662 0-8.442 1.145-8.442 2.558 0 1.413 3.78 2.558 8.442 2.558Z"
      fill="#95A0B1"
    />
    <path
      opacity={0.1}
      d="M12.341 5.86H6.116v12.791h6.225a.94.94 0 0 0 .938-.938V6.798a.94.94 0 0 0-.938-.938Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M11.83 6.372H6.116v12.79h5.714a.941.941 0 0 0 .937-.937V7.31a.94.94 0 0 0-.937-.938Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M11.83 6.372H6.116V18.14h5.714a.94.94 0 0 0 .937-.938V7.31a.94.94 0 0 0-.937-.938Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M11.318 6.372H6.116V18.14h5.202a.94.94 0 0 0 .938-.938V7.31a.94.94 0 0 0-.938-.938Z"
      fill="#000"
    />
    <path
      d="M1.938 6.372h9.38c.518 0 .938.42.938.938v9.38c0 .518-.42.938-.938.938h-9.38A.938.938 0 0 1 1 16.69V7.31c0-.518.42-.938.938-.938Z"
      fill="#596578"
    />
    <path
      d="M5.954 8.951h1.382l2.219 6.097H8.25l-.489-1.454H5.478l-.48 1.454H3.7l2.253-6.097Zm1.514 3.704-.71-2.207a2.895 2.895 0 0 1-.13-.498h-.026a2.817 2.817 0 0 1-.127.512l-.716 2.194 1.709-.001Z"
      fill="#fff"
    />
  </svg>
);

export default MicrosoftAccess;
