import { jsx as s } from "react/jsx-runtime";
const v = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ s("path", { d: "M12 3C7.58 3 3.02 4.79 3.02 7v10c0 2.451 4.57 4 8.98 4s8.957-1.489 8.957-4V7c0-2.21-4.537-4-8.957-4Zm6.957 14.408s-3.087 1.49-6.957 1.49c-3.87 0-6.98-1.49-6.98-1.49v-3.04c1.63.973 4.7 1.459 6.98 1.459 2.28 0 5.307-.47 6.957-1.459v3.04Zm0-5.123c-1.33.783-4.537 1.542-6.957 1.542s-5.644-.705-6.98-1.542V9.542C6.456 10.455 9.61 11 12 11c2.39 0 5.457-.515 6.957-1.458v2.743ZM12 9C8.13 9 5.02 7.5 5.02 7S8.13 5 12 5c3.87 0 6.957 1.5 6.957 2S15.87 9 12 9Z" })
  }
);
export {
  v as default
};
