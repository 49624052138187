import * as React from "react";
const SvgIconBulb = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.995 21.475v-3.81c-1.79-1.04-3-2.97-3-5.2 0-3.55 3.11-6.39 6.75-5.95 2.62.32 4.78 2.41 5.18 5.02.39 2.61-.89 4.95-2.93 6.13v3.81c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1Zm11.99-8h1.02c.55 0 .99-.44.99-.99v-.01c0-.55-.44-.99-.99-.99h-1.01c-.55 0-.99.44-.99.99v.01c-.01.55.43.99.98.99Zm-2.65-8.74a.996.996 0 0 1 1.41 0c.39.39.39 1.01.01 1.41l-.39.39a.996.996 0 0 1-1.41 0l-.01-.01a.993.993 0 0 1 0-1.4l.39-.39Zm-6.34-3.21h.01c.55 0 .99.44.99.99v.96c0 .55-.44.99-.99.99h-.01c-.55 0-.99-.44-.99-.99v-.96c0-.55.44-.99.99-.99Zm-8 10.96v-.01c0-.55-.44-.99-.99-.99h-1.01c-.55 0-.99.44-.99.99v.01c0 .55.44.99.99.99h1.01c.55 0 .99-.44.99-.99Zm1.67-7.76.39.39c.39.39.39 1.01-.01 1.41a.993.993 0 0 1-1.4 0l-.39-.39a.996.996 0 1 1 1.41-1.41Z"
    />
  </svg>
);
export default SvgIconBulb;
