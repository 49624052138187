import { jsxs as s, jsx as t } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ t("path", { d: "M7.655 12.682 4 16.337 11.663 24l7.31-7.31h-7.3s-1.342-1.332-4.018-4.008Z" }),
      /* @__PURE__ */ t("path", { d: "M12.278 16.129h6.83l-3.415-3.416-3.415 3.416Z", fill: "#95A0B1" }),
      /* @__PURE__ */ t(
        "path",
        {
          d: "m4.198 8.445 7.476 7.475 7.444-7.444L11.642 1 4.198 8.445Z",
          fill: "#596578"
        }
      )
    ]
  }
);
export {
  o as default
};
