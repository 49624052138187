import { jsx as a } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M12 17a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 16v-3h3.025a.926.926 0 0 0 .7-.288A.99.99 0 0 0 17 12a.968.968 0 0 0-.288-.713A.967.967 0 0 0 16 11h-3V7.975a.928.928 0 0 0-.287-.7A.993.993 0 0 0 12 7a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 8v3H7.975a.928.928 0 0 0-.7.287A.993.993 0 0 0 7 12c0 .283.096.52.287.712.192.192.43.288.713.288h3v3.025c0 .283.096.517.288.7A.99.99 0 0 0 12 17Zm0 5a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22Z" })
  }
);
export {
  h as default
};
