import { jsxs as s, jsx as c } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ c("path", { d: "M14.258 2.253A9.724 9.724 0 0 0 12 2a9.743 9.743 0 0 0-3.9.787 10.105 10.105 0 0 0-3.175 2.138c-.9.9-1.612 1.958-2.137 3.175A9.732 9.732 0 0 0 2 12c0 1.383.263 2.683.788 3.9a10.091 10.091 0 0 0 2.137 3.175c.9.9 1.958 1.612 3.175 2.137A9.733 9.733 0 0 0 12 22a9.733 9.733 0 0 0 3.9-.788 10.092 10.092 0 0 0 3.175-2.137c.9-.9 1.612-1.958 2.137-3.175A9.733 9.733 0 0 0 22 12c0-.533-.042-1.054-.125-1.562a11.205 11.205 0 0 0-.136-.694 6 6 0 0 1-1.997.25l.021.081c.158.617.237 1.258.237 1.925 0 2.217-.779 4.104-2.337 5.663C16.104 19.221 14.217 20 12 20s-4.104-.779-5.662-2.337C4.779 16.104 4 14.217 4 12s.78-4.104 2.338-5.663C7.896 4.779 9.783 4 12 4c.7 0 1.369.086 2.005.258a5.999 5.999 0 0 1 .253-2.005Z" }),
      /* @__PURE__ */ c("path", { d: "M9.575 10.575c-.283.283-.642.425-1.075.425-.433 0-.792-.142-1.075-.425C7.142 10.292 7 9.933 7 9.5c0-.433.142-.792.425-1.075C7.708 8.142 8.067 8 8.5 8c.433 0 .792.142 1.075.425.283.283.425.642.425 1.075 0 .433-.142.792-.425 1.075ZM15.137 16.525c-.908.65-1.954.975-3.137.975s-2.229-.325-3.137-.975A5.473 5.473 0 0 1 6.9 14h10.2a5.473 5.473 0 0 1-1.963 2.525ZM16.575 10.575c-.283.283-.642.425-1.075.425-.433 0-.792-.142-1.075-.425C14.142 10.292 14 9.933 14 9.5c0-.433.142-.792.425-1.075.283-.283.642-.425 1.075-.425.433 0 .792.142 1.075.425.283.283.425.642.425 1.075 0 .433-.142.792-.425 1.075ZM18.675 6.888l1.4-1.375 1.4 1.375a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275.93.93 0 0 0 .288-.712.977.977 0 0 0-.288-.688l-1.375-1.4 1.375-1.4a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7.93.93 0 0 0-.712-.288.977.977 0 0 0-.688.288l-1.4 1.4-1.4-1.4a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275.948.948 0 0 0-.275.7c0 .283.092.517.275.7l1.4 1.4-1.4 1.4a.948.948 0 0 0-.275.7c0 .283.092.517.275.7.2.2.438.296.713.288a.977.977 0 0 0 .687-.288Z" })
    ]
  }
);
export {
  l as default
};
