import { jsx as c } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M3 18.5v-3.726c0-.233.067-.441.2-.625a.884.884 0 0 1 .55-.35L11 12l-7.25-1.8a.883.883 0 0 1-.55-.35 1.036 1.036 0 0 1-.2-.625V5.5c0-.366.146-.646.438-.838a.964.964 0 0 1 .937-.087L17.3 10H17c-1.95 0-3.604.688-4.962 2.062C10.679 13.437 10 15.1 10 17.05l-5.6 2.375a.989.989 0 0 1-.95-.088c-.3-.191-.45-.47-.45-.837ZM17 22c-1.383 0-2.562-.488-3.537-1.463C12.488 19.562 12 18.383 12 17c0-1.383.488-2.562 1.463-3.538C14.438 12.487 15.617 12 17 12s2.563.488 3.538 1.462C21.513 14.437 22 15.616 22 17c0 1.384-.487 2.563-1.462 3.538C19.563 21.512 18.383 22 17 22Zm.5-5.2v-2.3a.48.48 0 0 0-.15-.35.48.48 0 0 0-.7 0 .48.48 0 0 0-.15.35v2.4c0 .1.017.191.05.274a.76.76 0 0 0 .175.25L18.3 19a.48.48 0 0 0 .7 0 .48.48 0 0 0 0-.7l-1.5-1.5Z" })
  }
);
export {
  l as default
};
