/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Zoom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 12c0-2.796 0-4.193.457-5.296a6 6 0 0 1 3.247-3.247C7.807 3 9.204 3 12 3c2.796 0 4.194 0 5.296.457a6 6 0 0 1 3.247 3.247C21 7.807 21 9.204 21 12c0 2.796 0 4.194-.457 5.296a6 6 0 0 1-3.247 3.247C16.194 21 14.796 21 12 21c-2.796 0-4.193 0-5.296-.457a6 6 0 0 1-3.247-3.247C3 16.194 3 14.796 3 12Z"
      fill="url(#zoom_svg__a)"
    />
    <g filter="url(#zoom_svg__b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.594 15.273h6.609v-4.5c0-.678-.592-1.228-1.322-1.228H6.273v4.5c0 .678.591 1.228 1.321 1.228Zm7.49-1.636 2.643 1.636V9.545l-2.643 1.637v2.455Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="zoom_svg__a"
        x1={12}
        y1={3}
        x2={12}
        y2={21}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#57A4FF" />
        <stop offset={1} stopColor="#3D83FC" />
      </linearGradient>
      <filter
        id="zoom_svg__b"
        x={2.273}
        y={6.545}
        width={19.455}
        height={13.727}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2449_18287"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_2449_18287"
          result="effect2_dropShadow_2449_18287"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
        <feBlend
          in2="effect2_dropShadow_2449_18287"
          result="effect3_dropShadow_2449_18287"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect3_dropShadow_2449_18287"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Zoom;
