import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 32",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "icon-file-g-sheets-file_svg__a",
      x1: "0%",
      y1: "0%",
      y2: "100%"
    },
    /* @__PURE__ */ e.createElement("stop", { stopOpacity: 0.2, offset: "0%" }),
    /* @__PURE__ */ e.createElement("stop", { stopOpacity: 0, offset: "100%" })
  )),
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#0F9D58",
      d: "M3 0h12l9 9v20a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#FFF",
      fillOpacity: 0.4,
      d: "M24 9h-6a3 3 0 0 1-3-3V0l9 9Z",
      style: {
        mixBlendMode: "lighten"
      }
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "url(#icon-file-g-sheets-file_svg__a)",
      d: "M24 9h-6a2.991 2.991 0 0 1-2.159-.917c-.347-.36 2.373 2.28 8.159 7.917V9Z",
      style: {
        mixBlendMode: "hard-light"
      }
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#F1F1F1",
      fillRule: "nonzero",
      d: "M6 15v10.875h12V15H6Zm5.25 9.375H7.5V22.5h3.75v1.875Zm0-3H7.5V19.5h3.75v1.875Zm0-3H7.5V16.5h3.75v1.875Zm5.25 6h-3.75V22.5h3.75v1.875Zm0-3h-3.75V19.5h3.75v1.875Zm0-3h-3.75V16.5h3.75v1.875Z"
    }
  ))
);
export {
  l as default
};
