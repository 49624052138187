/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Thinkific = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2H2v20h20V2ZM9 8a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm3 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
);

export default Thinkific;
