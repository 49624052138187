import { jsx as h } from "react/jsx-runtime";
const Z = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M15 21v-2h2v2h-2Zm4 0v-2h2v2h-2ZM7 21v-2h2v2H7Zm4 0v-2h2v2h-2Zm8-4v-2h2v2h-2Zm0-4v-2h2v2h-2ZM3 21V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h16v2H5v16H3ZM19 9V7h2v2h-2Z" })
  }
);
export {
  Z as default
};
