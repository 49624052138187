/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleZip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z"
      fill="#808B7F"
    />
    <path
      d="M12 9h2V7h-2V5h-2v2h2v2ZM12 11V9h-2v2h2ZM12 13v-2h2v2h-2ZM12 15v-2h-2v2h2ZM12 15v2h2v-2h-2Z"
      fill="#fff"
    />
  </svg>
);

export default GoogleZip;
