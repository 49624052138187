import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M16.3 12.3c-.2-.2-.3-.437-.3-.712 0-.275.092-.504.275-.687l1.9-1.9H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 8c0-.284.096-.521.288-.713A.967.967 0 0 1 4 7h14.175l-1.9-1.9A.934.934 0 0 1 16 4.413c0-.275.1-.512.3-.712a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l3.6 3.6c.1.1.171.208.213.325.041.116.062.241.062.375 0 .133-.02.258-.062.375a.883.883 0 0 1-.213.325l-3.6 3.6c-.2.2-.437.295-.712.287a.976.976 0 0 1-.688-.287Zm-10 8-3.6-3.6a.872.872 0 0 1-.212-.324A1.1 1.1 0 0 1 2.425 16c0-.134.021-.259.063-.375A.871.871 0 0 1 2.7 15.3l3.6-3.6a.979.979 0 0 1 .688-.288.932.932 0 0 1 .712.288c.2.183.304.412.312.687a.93.93 0 0 1-.287.713l-1.9 1.9H20c.283 0 .52.095.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 17H5.825l1.9 1.9a.99.99 0 0 1 .287.7.87.87 0 0 1-.287.7c-.183.2-.417.3-.7.3a.988.988 0 0 1-.725-.3Z" })
  }
);
export {
  t as default
};
