import { jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 18.5c.417 0 .77-.146 1.062-.438.292-.291.438-.645.438-1.062h-3c0 .417.146.77.438 1.062.291.292.645.438 1.062.438ZM8.025 16h8a.926.926 0 0 0 .7-.288A.99.99 0 0 0 17 15a.968.968 0 0 0-.288-.713A.967.967 0 0 0 16 14v-2.6a5.167 5.167 0 0 0-.787-2.788C14.688 7.771 13.95 7.233 13 7v-.5a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 5.5a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 6.5V7c-.95.233-1.687.77-2.212 1.612A5.163 5.163 0 0 0 8 11.4V14a.928.928 0 0 0-.7.287.993.993 0 0 0-.275.713c0 .283.096.52.287.712.192.192.43.288.713.288ZM12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22Zm0-2c2.217 0 4.104-.779 5.663-2.337C19.221 16.104 20 14.217 20 12s-.779-4.104-2.337-5.663C16.104 4.779 14.217 4 12 4s-4.104.779-5.662 2.337C4.779 7.896 4 9.783 4 12s.78 4.104 2.338 5.663C7.896 19.221 9.783 20 12 20Zm-2-6v-3c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12 9c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v3h-4Z" })
  }
);
export {
  h as default
};
