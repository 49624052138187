import { jsx as c } from "react/jsx-runtime";
const s = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M8.65 20H6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 18v-2.65L2.075 13.4A1.905 1.905 0 0 1 1.5 12c0-.55.192-1.017.575-1.4L4 8.65V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 6 4h2.65l1.95-1.925c.383-.383.85-.575 1.4-.575.55 0 1.017.192 1.4.575L15.35 4H18c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v2.65l1.925 1.95c.383.383.575.85.575 1.4 0 .55-.192 1.017-.575 1.4L20 15.35V18c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 20h-2.65l-1.95 1.925c-.383.383-.85.575-1.4.575-.55 0-1.017-.192-1.4-.575L8.65 20ZM12 17c1.383 0 2.563-.488 3.538-1.463C16.513 14.562 17 13.383 17 12s-.487-2.563-1.462-3.538C14.563 7.487 13.383 7 12 7v10Zm0 3.5 2.5-2.5H18v-3.5l2.5-2.5L18 9.5V6h-3.5L12 3.5 9.5 6H6v3.5L3.5 12 6 14.5V18h3.5l2.5 2.5Z" })
  }
);
export {
  s as default
};
