import { jsx as c } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M12 9a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 8V6H9a.967.967 0 0 1-.712-.287A.968.968 0 0 1 8 5c0-.283.096-.521.288-.713A.967.967 0 0 1 9 4h2V2c0-.283.096-.521.288-.713A.967.967 0 0 1 12 1a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 2v2h2a.97.97 0 0 1 .713.287A.97.97 0 0 1 16 5a.97.97 0 0 1-.287.713A.97.97 0 0 1 15 6h-2v2c0 .283-.096.52-.287.712A.968.968 0 0 1 12 9ZM7 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 5 20c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 7 18c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.021-.588 1.413A1.925 1.925 0 0 1 7 22Zm10 0c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 15 20c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 17 18c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0 1 17 22ZM7 17c-.767 0-1.346-.33-1.737-.988-.392-.658-.396-1.312-.013-1.962L6.6 11.6 3 4H2a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 3c0-.283.096-.521.288-.713A.967.967 0 0 1 2 2h1.625c.183 0 .358.05.525.15.167.1.292.242.375.425L8.55 11h7l3.575-6.475a.907.907 0 0 1 .363-.388C19.646 4.046 19.817 4 20 4c.383 0 .671.167.863.5a.96.96 0 0 1 .012 1L17.3 11.95a2.034 2.034 0 0 1-.725.775c-.3.183-.642.275-1.025.275H8.1L7 15h11c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 18 17H7Z" })
  }
);
export {
  h as default
};
