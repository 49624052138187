import { jsxs as l, jsx as h } from "react/jsx-runtime";
const e = (v) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: [
      /* @__PURE__ */ h(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5 21a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8.341A5.99 5.99 0 0 0 19 13v-2h-3.325v3h.007a6.029 6.029 0 0 0-2.007 2.233V16h-3.35v3H13c0 .701.12 1.374.341 2H5Zm5.325-7h3.35v-3h-3.35v3ZM5 14h3.325v-3H5v3Zm0 5h3.325v-3H5v3Z"
        }
      ),
      /* @__PURE__ */ h("path", { d: "m19 21.648 1.515 1.186c.258.215.528.221.81.017.282-.203.375-.472.282-.808l-.564-1.976 1.69-1.366c.259-.191.33-.455.212-.79-.117-.336-.34-.503-.67-.503h-2.007l-.599-1.905C19.552 15.168 19.33 15 19 15s-.552.168-.67.503l-.598 1.905h-2.008c-.329 0-.552.167-.669.503-.117.335-.047.599.211.79l1.691 1.366-.564 1.976c-.093.336 0 .605.282.808.282.204.552.198.81-.017L19 21.648Z" })
    ]
  }
);
export {
  e as default
};
