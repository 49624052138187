import { jsx as e } from "react/jsx-runtime";
const t = (i) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...i,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22 2H2v20h20V2ZM9 8a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm3 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      }
    )
  }
);
export {
  t as default
};
