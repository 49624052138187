import { jsx as c } from "react/jsx-runtime";
const o = (l) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ c("path", { d: "M8.281 15.832c-.183.183-.425.275-.725.275s-.541-.092-.725-.275l-3.075-3.075a.883.883 0 0 1-.213-.325 1.114 1.114 0 0 1-.062-.375c0-.134.021-.263.062-.388a.682.682 0 0 1 .213-.312l3.1-3.1c.184-.184.421-.27.712-.262.292.008.53.103.713.287.184.183.275.425.275.725s-.091.541-.275.725l-2.325 2.325 2.35 2.35c.184.183.271.42.263.712a1.01 1.01 0 0 1-.288.713Zm12.25-3.775a.941.941 0 0 1-.075.375c-.05.116-.116.225-.2.325l-3.075 3.075c-.183.183-.425.275-.725.275s-.541-.092-.725-.275c-.183-.184-.275-.425-.275-.725s.092-.542.275-.725l2.325-2.325-2.35-2.35c-.183-.184-.27-.42-.262-.712.008-.292.104-.53.287-.713.184-.184.425-.275.725-.275s.542.091.725.275l3.075 3.075a.92.92 0 0 1 .2.312c.05.125.075.254.075.388Z" })
  }
);
export {
  o as default
};
