// eslint-disable-next-line import/prefer-default-export
export const IconGrid = ({ icons }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(175px, 1fr))",
        gap: "24px",
        margin: "36px 0",
      }}
    >
      {Object.keys(icons).map((i) => {
        const IconName = icons[i];
        return (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "var(--color-surface)",
              borderRadius: "var(--radius-md)",
              boxShadow: "0 1px 3px #0000001a, 0 1px 2px #0000000f",
              border: "2px solid transparent",
              padding: "16px 0",
            }}
          >
            <IconName height="50px" width="50px" />
            <div
              style={{ fontSize: "14px", fontWeight: "bold", marginTop: "8px" }}
            >
              {i}
            </div>
          </div>
        );
      })}
    </div>
  );
};
