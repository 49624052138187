import * as e from "react";
const C = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 20 20",
    fill: "currentColor",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M2.50001 13.3333C2.05557 13.3333 1.66668 13.1667 1.33334 12.8333C1.00001 12.5 0.833344 12.1111 0.833344 11.6667V10C0.833344 9.90278 0.847232 9.79861 0.87501 9.6875C0.902788 9.57639 0.930566 9.47222 0.958343 9.375L3.45834 3.5C3.58334 3.22222 3.79168 2.98611 4.08334 2.79167C4.37501 2.59722 4.68057 2.5 5.00001 2.5H14.1667V13.3333L9.16668 18.2917C8.95834 18.5 8.71195 18.6214 8.42751 18.6558C8.14251 18.6908 7.86807 18.6389 7.60418 18.5C7.34029 18.3611 7.14584 18.1667 7.02084 17.9167C6.89584 17.6667 6.86807 17.4097 6.93751 17.1458L7.87501 13.3333H2.50001ZM12.5 12.625V4.16667H5.00001L2.50001 10V11.6667H10L8.87501 16.25L12.5 12.625ZM16.6667 2.5C17.125 2.5 17.5175 2.66306 17.8442 2.98917C18.1703 3.31583 18.3333 3.70833 18.3333 4.16667V11.6667C18.3333 12.125 18.1703 12.5172 17.8442 12.8433C17.5175 13.17 17.125 13.3333 16.6667 13.3333H14.1667V11.6667H16.6667V4.16667H14.1667V2.5H16.6667ZM12.5 4.16667V12.625V11.6667V10V4.16667Z" })
);
export {
  C as default
};
