import { useState } from "react";
import ColorPicker from "../src/ColorPicker";

// eslint-disable-next-line import/prefer-default-export
export const ColorPickerExample = () => {
  const [value, setValue] = useState("#000000");
  return (
    <div>
      <ColorPicker label="Color" value={value} onValueChanged={setValue} />
      <p>some paragraph</p>
    </div>
  );
};
