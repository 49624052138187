import * as React from "react";
const SvgIconVisible = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M12 6C8.149 6 4.561 9.755 2 12c2.575 2.257 6.149 6 10 6s7.311-3.642 10-6c-2.769-2.427-6.149-6-10-6Zm0 10c-2.255 0-4-1.745-4-4s1.745-4 4-4 4 1.745 4 4-1.745 4-4 4Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
  </svg>
);
export default SvgIconVisible;
