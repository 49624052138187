/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const DropboxInvoice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.998 5.536v12.748l5.875-2.693V2.839l-5.875 2.697Z"
      fill="#1E1919"
    />
    <path
      d="M11.998 5.536v12.748l-5.871-2.693V2.839l5.87 2.697ZM22 16.57l-.008-10.854-9.994 4.59v10.855L22 16.57Z"
      fill="#0061FE"
    />
    <path d="m2 16.57.008-10.854 9.99 4.59v10.855L2 16.57Z" fill="#1E1919" />
  </svg>
);

export default DropboxInvoice;
