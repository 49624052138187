import { ChangeEvent, useEffect, useMemo, useState } from "react";
import Checkbox from "./Checkbox";
import {
  devWarning,
  getValidChildrenArray,
  isString,
  useDevWarningOnce,
} from "./utils";
import { CheckboxGroupOption, CheckboxGroupProps } from "./Checkbox.types";
import styled from "styled-components";

const getOptions = (options: CheckboxGroupOption[]) =>
  options.map((option) =>
    isString(option) ? { label: option, value: option } : option
  );

const sortValuesByOrderInOptions = (options: CheckboxGroupOption[]) => (
  a: string,
  b: string
) => {
  const indexA = options.findIndex((opt) => opt.value === a);
  const indexB = options.findIndex((opt) => opt.value === b);
  return indexA - indexB;
};

const getOptionsFromChildren = (children: any) =>
  getValidChildrenArray(children).map(({ props: option }) => option);

const getChildrenDefaultValue = (options: any[]) =>
  options
    .filter(({ defaultChecked }) => defaultChecked)
    .map(({ value }) => value);

const optionsWarning =
  "[CheckboxGroup] Both the `children` and `options` props have been used.\n  The `options` prop will be ignored. To get rid of this warning, remove one of these props.";
const defaultValueWarning =
  "[CheckboxGroup] Both the `defaultChecked` and `defaultValue` props have been used.\n  The `defaultValue` prop will be ignored. To get rid of this warning, remove one of these props.";

const CheckboxGroup = ({
  value,
  disabled,
  name,
  children,
  className,
  options: rawOptions,
  onChange: onGroupChange,
  defaultValue: groupDefaultValue,
}: CheckboxGroupProps) => {
  useDevWarningOnce(children && rawOptions, optionsWarning);

  const options = useMemo(
    () =>
      (children
        ? getOptionsFromChildren(children as any)
        : getOptions(rawOptions || [])) as CheckboxGroupOption[],
    [children, rawOptions]
  );

  const defaultValue = useMemo(() => {
    const childrenDefaultValue = getChildrenDefaultValue(options);
    const hasChildrenDefaultValue = Boolean(childrenDefaultValue.length);

    if (hasChildrenDefaultValue && groupDefaultValue) {
      devWarning(defaultValueWarning);
    }

    return hasChildrenDefaultValue ? childrenDefaultValue : groupDefaultValue;
  }, [options, groupDefaultValue]);

  const [groupValue, setGroupValue] = useState(value || defaultValue || []);

  useEffect(() => {
    if (value) {
      setGroupValue(value);
    }
  }, [value]);

  const isChecked = (optionValue: string | any) =>
    groupValue.includes(optionValue);

  const handleChange = (
    option: CheckboxGroupOption,
    event: ChangeEvent<HTMLInputElement>,
    newVal: boolean
  ) => {
    if (option.onChange) {
      option.onChange(event, newVal);
    }

    const newValue = newVal
      ? [...groupValue, option.value]
      : groupValue.filter((val: string | any) => val !== option.value);

    const newSortedValue = newValue.sort(sortValuesByOrderInOptions(options));

    setGroupValue(newSortedValue);

    if (onGroupChange) {
      onGroupChange(newSortedValue);
    }
  };

  return (
    <CheckboxGroupContainer className={className}>
      {options.map((option) => (
        <li key={option.value}>
          <Checkbox
            label={option.label}
            background={option.background}
            name={name}
            disabled={option.disabled || disabled || false}
            checked={isChecked(option.value)}
            onChange={(event, newVal) => handleChange(option, event, newVal)}
          />
        </li>
      ))}
    </CheckboxGroupContainer>
  );
};

const CheckboxGroupContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  margin-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-start: 0;
`;

export default CheckboxGroup;
