import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M14 18.176V12c0-.284.096-.521.288-.713A.967.967 0 0 1 15 11a.97.97 0 0 1 .713.287A.97.97 0 0 1 16 12v6.175l1.875-1.875a.978.978 0 0 1 .687-.288.933.933 0 0 1 .713.288c.2.183.304.412.313.687a.933.933 0 0 1-.288.713l-3.6 3.6c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063 1.1 1.1 0 0 1-.375-.063.872.872 0 0 1-.325-.212l-3.6-3.6a.933.933 0 0 1-.275-.687c0-.276.1-.513.3-.713.2-.184.433-.28.7-.288.267-.008.5.088.7.288L14 18.176ZM8 5.826 6.125 7.7c-.217.216-.458.316-.725.3a1.012 1.012 0 0 1-.675-.3c-.2-.2-.3-.438-.3-.713 0-.275.092-.504.275-.687l3.6-3.6c.1-.1.208-.171.325-.213.117-.042.242-.062.375-.062s.258.02.375.062A.883.883 0 0 1 9.7 2.7l3.6 3.6a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7c-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3L10 5.826V12c0 .283-.096.52-.287.712A.968.968 0 0 1 9 13a.965.965 0 0 1-.712-.288A.965.965 0 0 1 8 12V5.826Z" })
  }
);
export {
  l as default
};
