import * as c from "react";
const t = (e) => /* @__PURE__ */ c.createElement(
  "svg",
  {
    viewBox: "0 0 32 32",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...e
  },
  /* @__PURE__ */ c.createElement("path", { d: "M26.668 6.388h-5.333l-4.387-4.386a1.328 1.328 0 0 0-1.88 0l-4.4 4.386H5.335a2.675 2.675 0 0 0-2.667 2.667v18.667c0 1.466 1.2 2.666 2.667 2.666h21.333c1.467 0 2.667-1.2 2.667-2.666V9.055c0-1.467-1.2-2.667-2.667-2.667Zm-1.333 21.334H6.668c-.733 0-1.333-.6-1.333-1.334v-16c0-.733.6-1.333 1.333-1.333h4.693l4.694-4.667 4.64 4.667h4.64c.733 0 1.333.6 1.333 1.333v16c0 .734-.6 1.334-1.333 1.334Zm-16-16h13.333c.733 0 1.333.6 1.333 1.333v10.667c0 .733-.6 1.333-1.333 1.333H9.335c-.734 0-1.334-.6-1.334-1.333V13.055c0-.733.6-1.333 1.334-1.333Z" })
);
export {
  t as default
};
