import * as React from "react";
const SvgIconMsTeams = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="Icon-ms-teams_svg__a"
        x1={5.822}
        y1={11.568}
        x2={20.178}
        y2={36.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5a62c3" />
        <stop offset={0.5} stopColor="#4d55bd" />
        <stop offset={1} stopColor="#3940ab" />
      </linearGradient>
    </defs>
    <path
      d="M31.993 19H43.1a1.9 1.9 0 0 1 1.9 1.9v10.117A6.983 6.983 0 0 1 38.017 38h-.033A6.983 6.983 0 0 1 31 31.017V19.993a.993.993 0 0 1 .993-.993z"
      fill="#5059c9"
    />
    <circle cx={39.5} cy={12.5} r={4.5} fill="#5059c9" />
    <circle cx={25.5} cy={10.5} r={6.5} fill="#7b83eb" />
    <path
      d="M34.167 19H15.833A1.88 1.88 0 0 0 14 20.923v11.539A11.279 11.279 0 0 0 25 44a11.279 11.279 0 0 0 11-11.538V20.923A1.88 1.88 0 0 0 34.167 19z"
      fill="#7b83eb"
    />
    <path fill="none" d="M0 0h48v48H0z" />
    <path
      d="M26 19v16.17a1.841 1.841 0 0 1-1.14 1.69 1.772 1.772 0 0 1-.69.14h-9.29c-.13-.33-.25-.66-.35-1a12.179 12.179 0 0 1-.53-3.54V20.92A1.877 1.877 0 0 1 15.83 19z"
      opacity={0.1}
    />
    <path
      d="M25 19v17.17a1.772 1.772 0 0 1-.14.69A1.841 1.841 0 0 1 23.17 38h-7.82c-.17-.33-.33-.66-.47-1s-.25-.66-.35-1a12.179 12.179 0 0 1-.53-3.54V20.92A1.877 1.877 0 0 1 15.83 19z"
      opacity={0.2}
    />
    <path
      d="M25 19v15.17A1.844 1.844 0 0 1 23.17 36h-8.64a12.179 12.179 0 0 1-.53-3.54V20.92A1.877 1.877 0 0 1 15.83 19z"
      opacity={0.2}
    />
    <path
      d="M24 19v15.17A1.844 1.844 0 0 1 22.17 36h-7.64a12.179 12.179 0 0 1-.53-3.54V20.92A1.877 1.877 0 0 1 15.83 19z"
      opacity={0.2}
    />
    <path
      d="M26 13.83v3.15c-.17.01-.33.02-.5.02s-.33-.01-.5-.02a5.489 5.489 0 0 1-1-.16A6.5 6.5 0 0 1 19.5 13a5.556 5.556 0 0 1-.32-1h4.99A1.837 1.837 0 0 1 26 13.83z"
      opacity={0.1}
    />
    <path
      d="M25 14.83v2.15a5.489 5.489 0 0 1-1-.16A6.5 6.5 0 0 1 19.5 13h3.67A1.837 1.837 0 0 1 25 14.83z"
      opacity={0.2}
    />
    <path
      d="M25 14.83v2.15a5.489 5.489 0 0 1-1-.16A6.5 6.5 0 0 1 19.5 13h3.67A1.837 1.837 0 0 1 25 14.83z"
      opacity={0.2}
    />
    <path
      d="M24 14.83v1.99A6.5 6.5 0 0 1 19.5 13h2.67A1.837 1.837 0 0 1 24 14.83z"
      opacity={0.2}
    />
    <rect
      x={2}
      y={13}
      width={22}
      height={22}
      rx={1.833}
      fill="url(#Icon-ms-teams_svg__a)"
    />
    <path
      d="M17.824 19.978h-3.665v9.98h-2.335v-9.98H8.176v-1.936h9.648z"
      fill="#fff"
    />
  </svg>
);
export default SvgIconMsTeams;
