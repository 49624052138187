/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const AdobeIllustrator = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.188 3h11.625C19.575 3 21 4.462 21 6.27v11.46c0 1.808-1.425 3.27-3.188 3.27H6.188C4.425 21 3 19.538 3 17.73V6.27C3 4.461 4.425 3 6.188 3Z"
      fill="#300"
    />
    <path
      d="M15.473 9.296c.185.177.431.277.685.27a.904.904 0 0 0 .7-.27.973.973 0 0 0 .27-.708.971.971 0 0 0-.255-.684c-.169-.177-.4-.27-.7-.27-.254 0-.5.093-.684.27a.905.905 0 0 0-.277.684 1 1 0 0 0 .261.708ZM15.296 10.32v5.922c0 .077.039.123.116.123h1.507c.07 0 .1-.038.1-.123V10.32c0-.069-.03-.107-.1-.107h-1.523c-.069 0-.1.03-.1.107Z"
      fill="#FF9A00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.143 14.412h2.861l.63 1.83c.016.054.07.093.124.085h1.608c.077 0 .1-.038.077-.123l-2.808-7.923c-.008-.039-.039-.062-.1-.062H9.543c-.039.008-.07.047-.062.085 0 .17-.015.338-.046.5-.022.065-.04.127-.057.185l-.02.069-2.477 7.13c-.023.093 0 .14.085.14h1.446c.069.007.13-.04.146-.109l.585-1.807Zm2.4-1.562H9.588c.115-.323.23-.684.338-1.077.076-.238.15-.476.222-.71l.132-.428a9.43 9.43 0 0 0 .261-.993h.016c.03.103.06.211.091.324.025.089.05.181.078.276.062.223.13.447.208.685l.111.354.112.354c.07.23.138.454.208.661.069.2.13.393.177.554Z"
      fill="#FF9A00"
    />
  </svg>
);

export default AdobeIllustrator;
