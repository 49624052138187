import { jsxs as s, jsx as l } from "react/jsx-runtime";
const d = (e) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l("path", { d: "M2.587 19.413C2.98 19.803 3.45 20 4 20h6.45a.78.78 0 0 0 .675-.363.928.928 0 0 0 .125-.812 6.827 6.827 0 0 1-.25-1.85c0-1.9.67-3.538 2.012-4.913C14.354 10.688 16.008 10 17.976 10c.483 0 .967.05 1.45.15.483.1.942.25 1.375.45a.829.829 0 0 0 .813-.025A.732.732 0 0 0 22 9.9V8c0-.55-.196-1.02-.587-1.412A1.926 1.926 0 0 0 20 6h-8l-1.425-1.425A1.975 1.975 0 0 0 9.175 4H4c-.55 0-1.02.196-1.413.588A1.926 1.926 0 0 0 2 6v12c0 .55.196 1.02.587 1.413Z" }),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14.463 20.538C15.438 21.513 16.617 22 18 22s2.563-.487 3.538-1.462C22.513 19.563 23 18.383 23 17s-.487-2.563-1.462-3.538C20.563 12.487 19.383 12 18 12s-2.562.487-3.537 1.462C13.488 14.437 13 15.617 13 17s.488 2.563 1.463 3.538ZM18.5 14.5v2.3l1.5 1.5a.48.48 0 0 1 0 .7.48.48 0 0 1-.7 0l-1.575-1.575a.762.762 0 0 1-.175-.25.735.735 0 0 1-.05-.275v-2.4c0-.133.05-.25.15-.35a.48.48 0 0 1 .7 0c.1.1.15.217.15.35Z"
        }
      )
    ]
  }
);
export {
  d as default
};
