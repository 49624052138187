import * as React from "react";
const SvgIconFavoriteBorder = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.267c1.76-2.06 5.02-2.9 7.66-1.1 1.4.96 2.28 2.58 2.34 4.28.13 3.88-3.3 6.99-8.55 11.75l-.11.1c-.76.7-1.93.7-2.69.01l-.1-.09-.06-.055C5.274 15.423 1.86 12.322 2 8.457c.06-1.71.94-3.33 2.34-4.29 2.64-1.81 5.9-.96 7.66 1.1Zm0 13.56.1-.1c4.76-4.31 7.9-7.16 7.9-10.05 0-2-1.5-3.5-3.5-3.5-1.54 0-3.04.99-3.56 2.36h-1.87c-.53-1.37-2.03-2.36-3.57-2.36-2 0-3.5 1.5-3.5 3.5 0 2.89 3.14 5.74 7.9 10.05l.1.1Z"
    />
  </svg>
);
export default SvgIconFavoriteBorder;
