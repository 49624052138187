import * as React from "react";
const SvgIconLogoGChat = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10.286C5 6.262 8.358 3 12.5 3c4.138 0 7.5 3.257 7.5 7.286 0 3.857-2.77 8.271-7.059 10.285v-3H12.5c-4.142 0-7.5-3.262-7.5-7.285Zm7.824-4.287a4.51 4.51 0 0 0-4.825 4.825c.162 2.388 2.248 4.189 4.64 4.189h1.666a.453.453 0 0 0 .451-.451.453.453 0 0 0-.451-.452h-1.657c-1.684 0-3.227-1.092-3.647-2.722-.673-2.618 1.765-5.06 4.383-4.383 1.634.416 2.726 1.96 2.726 3.643v.497c0 .356-.32.708-.677.708-.357 0-.677-.352-.677-.708v-.565c0-1.133-.803-2.153-1.923-2.31a2.26 2.26 0 0 0-.876 4.427c.83.194 1.62-.072 2.14-.6.402.55 1.205.84 1.94.546.606-.24.976-.857.976-1.508v-.492c0-2.396-1.801-4.482-4.189-4.644Zm-.325 5.854c-.75 0-1.354-.605-1.354-1.354 0-.75.605-1.354 1.354-1.354.75 0 1.354.605 1.354 1.354 0 .75-.605 1.354-1.354 1.354Z"
    />
  </svg>
);
export default SvgIconLogoGChat;
