import { jsx as c } from "react/jsx-runtime";
const a = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M5 19a.967.967 0 0 1-.712-.288A.968.968 0 0 1 4 18c0-.283.096-.52.288-.712A.967.967 0 0 1 5 17h1v-7c0-1.383.417-2.612 1.25-3.688.833-1.075 1.917-1.779 3.25-2.112v-.7c0-.417.146-.77.438-1.063A1.447 1.447 0 0 1 12 2c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v.7c1.333.333 2.417 1.037 3.25 2.112C17.583 7.388 18 8.617 18 10v7h1c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 19 19H5Zm7 3c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 10 20h4c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 12 22ZM3 10a.913.913 0 0 1-.712-.325.923.923 0 0 1-.238-.75A9.588 9.588 0 0 1 3.1 5.437a10.141 10.141 0 0 1 2.175-2.812 1.01 1.01 0 0 1 .738-.25.844.844 0 0 1 .662.375c.167.233.233.483.2.75s-.158.5-.375.7a7.866 7.866 0 0 0-1.6 2.15A8.336 8.336 0 0 0 4.075 9c-.033.283-.15.52-.35.713A1.01 1.01 0 0 1 3 10Zm18 0a1.01 1.01 0 0 1-.725-.287 1.14 1.14 0 0 1-.35-.713 8.334 8.334 0 0 0-.825-2.65 7.867 7.867 0 0 0-1.6-2.15 1.124 1.124 0 0 1-.375-.7 1.033 1.033 0 0 1 .2-.75.844.844 0 0 1 .663-.375c.274-.017.52.067.737.25.883.8 1.608 1.737 2.175 2.813a9.586 9.586 0 0 1 1.05 3.487.923.923 0 0 1-.238.75A.913.913 0 0 1 21 10Z" })
  }
);
export {
  a as default
};
