import { jsx as a } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M10 15a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 14v-4c-1.1 0-2.042-.392-2.825-1.175C5.392 8.042 5 7.1 5 6s.392-2.042 1.175-2.825C6.958 2.392 7.9 2 9 2h7c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 16 4h-1v10c0 .283-.096.52-.287.712A.968.968 0 0 1 14 15a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 14V4h-2v10c0 .283-.096.52-.287.712A.968.968 0 0 1 10 15Zm6.3 6.3a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l.9-.9H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 18c0-.283.096-.52.288-.712A.965.965 0 0 1 4 17h13.2l-.9-.9a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.6 2.6c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.883.883 0 0 1-.213.325l-2.6 2.6a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Z" })
  }
);
export {
  h as default
};
