import { useEffect, useState } from "react";
import styled from "styled-components";
import { IconButton } from "@happeouikit/buttons";
import {
  IconBrokenImage,
  IconChevronLeft,
  IconChevronRight,
} from "@happeouikit/icons";
import { Loader } from "@happeouikit/loaders";
import { gray03, gray05, gray09, white } from "@happeouikit/colors";

import { loadImage } from "./utils";

const TRANSLATE_X_CONSTANT = 100;

type ImageCarouselProps = {
  images: {
    src: string;
    alt?: string;
  }[];
  imageHeight: string;
  previousLabel?: string;
  nextLabel?: string;
};

const ImageCarousel = ({
  images = [],
  imageHeight = "448px",
  previousLabel = "Previous image",
  nextLabel = "Next image",
}: ImageCarouselProps) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [imgsLoaded, setImgsLoaded] = useState(false);
  const [processedImages, setProcessedImages] = useState<
    { id: number; src: string; alt?: string | null; error: boolean }[]
  >([]);

  useEffect(() => {
    const loadAllImages = async () => {
      const imageLoadResults = await Promise.all(
        images.map(({ src }) => loadImage(src)).map((p) => p.catch((e) => e))
      );

      setProcessedImages(
        images.map((image, index) => ({
          id: Math.random(),
          ...image,
          error: imageLoadResults[index] instanceof Error,
        }))
      );
      setImgsLoaded(true);
    };
    loadAllImages();
  }, [images]);

  const leftClick = () => {
    let newTranslateX = translateX;
    const carouselCount = images.length;
    let calculate =
      imageIndex > 0 ? (imageIndex - 1) % carouselCount : carouselCount;
    if (imageIndex > 0)
      newTranslateX =
        imageIndex === 1 ? 0 : newTranslateX - TRANSLATE_X_CONSTANT;
    else if (imageIndex <= 0) {
      newTranslateX = TRANSLATE_X_CONSTANT * (carouselCount - 1);
      calculate = carouselCount - 1;
    }

    setImageIndex(calculate);
    setTranslateX(newTranslateX);
  };

  const rightClick = () => {
    let newTranslateX = translateX;
    const carouselCount = images.length;
    let calculate = (imageIndex + 1) % carouselCount;
    if (imageIndex >= carouselCount - 1) {
      calculate = 0;
      newTranslateX = 0;
    } else {
      newTranslateX += TRANSLATE_X_CONSTANT;
    }
    setImageIndex(calculate);
    setTranslateX(newTranslateX);
  };

  return (
    <Flex>
      <ButtonContainer left>
        <ArrowButton
          aria-label={previousLabel}
          data-testid="previous-image"
          icon={IconChevronLeft}
          onClick={leftClick}
          style={{
            visibility: images.length > 1 ? "visible" : "hidden",
          }}
        />
      </ButtonContainer>
      <Carousel imageCount={images.length}>
        {!imgsLoaded ? (
          <ImageLoaderWrapper
            imageHeight={imageHeight}
            data-testid="image-carousel-loader"
          >
            <Loader />
          </ImageLoaderWrapper>
        ) : (
          processedImages.map(({ id, alt, src, error }, index) => (
            <ImageWrap
              key={`image-${id}`}
              active={index === imageIndex}
              translateX={translateX}
            >
              {error ? (
                <ImageErrorWrapper imageHeight={imageHeight}>
                  <IconBrokenImage />
                </ImageErrorWrapper>
              ) : (
                <CarouselImage
                  alt={alt || ""}
                  src={src}
                  imageHeight={imageHeight}
                  data-testid={index === imageIndex && "active-image"}
                />
              )}
            </ImageWrap>
          ))
        )}
      </Carousel>
      <ButtonContainer>
        <ArrowButton
          aria-label={nextLabel}
          data-testid="next-image"
          icon={IconChevronRight}
          onClick={rightClick}
          style={{
            visibility: images.length > 1 ? "visible" : "hidden",
          }}
        />
      </ButtonContainer>
    </Flex>
  );
};

export default ImageCarousel;

const Flex = styled.div`
  display: flex;
  width: 100%;
`;

const Carousel = styled.div<{ imageCount: number }>`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: grid;
  ${(props) => `
    grid-template-columns: repeat(${props.imageCount}, 100%);
  `}
`;

const ArrowButton = styled(IconButton)`
  svg {
    fill: ${gray03};
    width: 24px;
    height: 24px;
  }
  background: ${white};
`;

const ButtonContainer = styled.div<{ left?: boolean }>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  background-color: ${gray09};
  ${(props) =>
    props.left ? "border-radius: 6px 0 0 6px;" : "border-radius: 0 6px 6px 0;"}
`;

const ImageWrap = styled.div<{ active: boolean; translateX: number }>`
  ${({ active, translateX }) =>
    `transform: translateX(-${translateX}%) scale(${active ? 1 : 0.9});`}
  transition: all 0.7s ease;
  display: flex;
  width: 100%;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
`;

export const CarouselImage = styled.img<{
  imageHeight?: string | null | undefined;
}>`
  object-fit: cover;
  height: ${(props) => props.imageHeight || "448px"};
  width: 100%;
`;

const ImageLoaderWrapper = styled.div<{
  imageHeight?: string | null | undefined;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.imageHeight || "448px"};
`;

const ImageErrorWrapper = styled.div<{
  imageHeight?: string | null | undefined;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${gray09};
  height: ${(props) => props.imageHeight || "448px"};
  width: 100%;

  & > svg {
    fill: ${gray05};
    width: 20%;
    height: auto;
  }
`;
