import { jsx as a } from "react/jsx-runtime";
const v = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M3 18.5v-13c0-.367.146-.647.438-.839a.964.964 0 0 1 .937-.087L17.3 10H17c-.583 0-1.133.067-1.65.2a8.1 8.1 0 0 0-1.5.55L5 7v3.5l6 1.5-6 1.5V17l5.4-2.3a7.426 7.426 0 0 0-.3 1.137c-.067.376-.1.763-.1 1.163v.05l-5.6 2.375a.989.989 0 0 1-.95-.088c-.3-.191-.45-.47-.45-.837ZM17 22c-1.383 0-2.562-.488-3.537-1.463C12.488 19.562 12 18.383 12 17c0-1.383.488-2.562 1.463-3.538C14.438 12.487 15.617 12 17 12s2.563.488 3.538 1.462C21.513 14.437 22 15.616 22 17c0 1.384-.487 2.563-1.462 3.538C19.563 21.512 18.383 22 17 22Zm2-3a.48.48 0 0 0 0-.7l-1.5-1.5v-2.3a.48.48 0 0 0-.15-.35.48.48 0 0 0-.7 0 .48.48 0 0 0-.15.35v2.4c0 .1.017.191.05.274a.76.76 0 0 0 .175.25L18.3 19a.48.48 0 0 0 .7 0ZM5 14.7V7v10-2.3Z" })
  }
);
export {
  v as default
};
