import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M21.5 6.058c-.7.32-1.453.53-2.234.631a3.925 3.925 0 0 0 1.707-2.176 7.818 7.818 0 0 1-2.47.96c-.718-.786-1.726-1.243-2.87-1.243-2.134 0-3.878 1.755-3.878 3.922 0 .31.036.612.1.896a11.051 11.051 0 0 1-8.029-4.096 3.9 3.9 0 0 0-.527 1.966c0 1.362.681 2.568 1.735 3.254a3.833 3.833 0 0 1-1.771-.457v.027c0 1.902 1.344 3.492 3.124 3.849a3.81 3.81 0 0 1-1.753.064c.49 1.545 1.917 2.697 3.633 2.724a7.707 7.707 0 0 1-4.84 1.682c-.31 0-.618-.018-.927-.055a10.978 10.978 0 0 0 5.976 1.765c7.157 0 11.09-5.979 11.09-11.162 0-.174 0-.338-.01-.512A7.814 7.814 0 0 0 21.5 6.058Z"
    }
  )
);
export {
  a as default
};
