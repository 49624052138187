import { jsx as a } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "m16.3 17.3-4.6-4.6a.871.871 0 0 1-.212-.325 1.1 1.1 0 0 1-.063-.375 1.1 1.1 0 0 1 .063-.375.871.871 0 0 1 .212-.325l4.6-4.6a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L13.8 12l3.9 3.9a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM7 18a.968.968 0 0 1-.713-.288A.967.967 0 0 1 6 17V7a.97.97 0 0 1 .287-.713A.97.97 0 0 1 7 6a.97.97 0 0 1 .713.287A.97.97 0 0 1 8 7v10c0 .283-.096.52-.287.712A.968.968 0 0 1 7 18Z" })
  }
);
export {
  e as default
};
