import { jsx as v } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ v(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ v("path", { d: "M5 21a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5ZM5 9h14V5H5v4Zm5.325 5h3.35v-3h-3.35v3Zm0 5h3.35v-3h-3.35v3ZM5 14h3.325v-3H5v3Zm10.675 0H19v-3h-3.325v3ZM5 19h3.325v-3H5v3Zm10.675 0H19v-3h-3.325v3Z" })
  }
);
export {
  t as default
};
