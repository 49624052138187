import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M10 12c-1.1 0-2.042-.392-2.825-1.175C6.392 10.042 6 9.1 6 8s.392-2.042 1.175-2.825C7.958 4.392 8.9 4 10 4s2.042.392 2.825 1.175C13.608 5.958 14 6.9 14 8s-.392 2.042-1.175 2.825C12.042 11.608 11.1 12 10 12Zm-7 8a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2 19v-1.8c0-.55.142-1.067.425-1.55.283-.483.675-.85 1.175-1.1.85-.433 1.808-.8 2.875-1.1C7.542 13.15 8.717 13 10 13h.35c.1 0 .2.017.3.05-.133.3-.246.613-.338.938A7.82 7.82 0 0 0 10.1 15c-.083.6-.108 1.104-.075 1.512.033.409.125.905.275 1.488.1.35.233.696.4 1.038.167.341.35.662.55.962H3Zm14-2c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.021-.587-1.413A1.928 1.928 0 0 0 17 14c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 15 16c0 .55.196 1.021.588 1.413.391.391.862.587 1.412.587Zm-1.3 1.5c-.2-.083-.388-.17-.563-.262a4.42 4.42 0 0 1-.537-.338l-1.075.325a.478.478 0 0 1-.325-.013.552.552 0 0 1-.25-.212l-.6-1a.408.408 0 0 1-.062-.325.526.526 0 0 1 .187-.3l.825-.725a4.62 4.62 0 0 1-.05-.65c0-.2.017-.417.05-.65l-.825-.725a.526.526 0 0 1-.187-.3.408.408 0 0 1 .062-.325l.6-1c.067-.1.15-.171.25-.213a.483.483 0 0 1 .325-.012l1.075.325a4.42 4.42 0 0 1 .537-.338c.175-.091.363-.179.563-.262l.225-1.1a.595.595 0 0 1 .175-.287.432.432 0 0 1 .3-.113h1.2c.117 0 .217.038.3.113.083.075.142.17.175.287l.225 1.1c.2.083.388.175.563.275.175.1.354.225.537.375l1.05-.375a.4.4 0 0 1 .338 0c.108.05.195.125.262.225l.6 1.05c.067.1.092.208.075.325a.47.47 0 0 1-.175.3l-.85.725c.033.2.05.408.05.625a3.8 3.8 0 0 1-.05.625l.825.725c.1.083.162.183.187.3a.408.408 0 0 1-.062.325l-.6 1c-.067.1-.15.17-.25.212a.478.478 0 0 1-.325.013L19.4 18.9a4.42 4.42 0 0 1-.537.338 7.272 7.272 0 0 1-.563.262l-.225 1.1a.595.595 0 0 1-.175.287.432.432 0 0 1-.3.113h-1.2a.432.432 0 0 1-.3-.113.595.595 0 0 1-.175-.287l-.225-1.1Z" })
  }
);
export {
  s as default
};
