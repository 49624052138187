import { jsx as t } from "react/jsx-runtime";
const o = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "m11 14.4-3.9 3.9a.948.948 0 0 1-.7.274.948.948 0 0 1-.7-.275.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7l4.9-4.9-4.9-4.9a.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275L11 9.6V3.424c0-.283.087-.516.262-.7a.878.878 0 0 1 .663-.275c.167 0 .333.038.5.112.167.076.317.18.45.313L17 7c.1.1.171.209.213.325.041.117.062.242.062.375 0 .134-.02.259-.062.375A.883.883 0 0 1 17 8.4L13.4 12l3.6 3.6c.1.1.171.209.213.325.041.117.062.242.062.375 0 .134-.02.259-.062.375A.883.883 0 0 1 17 17l-4.125 4.125a1.512 1.512 0 0 1-.45.313 1.214 1.214 0 0 1-.5.112.878.878 0 0 1-.663-.275.973.973 0 0 1-.262-.7V14.4Zm2-4.8 1.9-1.9L13 5.85V9.6Zm0 8.55 1.9-1.85-1.9-1.9v3.75Z" })
  }
);
export {
  o as default
};
