import { jsx as A } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M12 18a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 17c0-.283.096-.52.288-.712A.965.965 0 0 1 12 16c.283 0 .521.096.713.288A.967.967 0 0 1 13 17c0 .283-.096.52-.287.712A.968.968 0 0 1 12 18Zm0 3a8.654 8.654 0 0 1-3.488-.712A9.2 9.2 0 0 1 5.65 18.35a9.202 9.202 0 0 1-1.938-2.862A8.655 8.655 0 0 1 3 12c0-1.3.25-2.508.75-3.625A9.085 9.085 0 0 1 5.825 5.45a.982.982 0 0 1 .75-.275c.283.017.525.125.725.325l5.4 5.4c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.948.948 0 0 1-.913.6.948.948 0 0 1-.7-.275L6.6 7.6c-.5.6-.892 1.271-1.175 2.013A6.63 6.63 0 0 0 5 12c0 1.933.683 3.583 2.05 4.95C8.417 18.317 10.067 19 12 19c1.933 0 3.583-.683 4.95-2.05C18.317 15.583 19 13.933 19 12c0-1.783-.57-3.321-1.712-4.613C16.146 6.096 14.717 5.333 13 5.1V6a.97.97 0 0 1-.287.713A.97.97 0 0 1 12 7a.967.967 0 0 1-.712-.287A.968.968 0 0 1 11 6V4a.9.9 0 0 1 .338-.713c.225-.191.487-.287.787-.287 1.2 0 2.338.237 3.413.712a9.138 9.138 0 0 1 2.825 1.938 9.316 9.316 0 0 1 1.925 2.862A8.654 8.654 0 0 1 21 12a8.654 8.654 0 0 1-.712 3.488 9.201 9.201 0 0 1-1.938 2.862 9.201 9.201 0 0 1-2.862 1.938A8.654 8.654 0 0 1 12 21Zm5-8a.965.965 0 0 1-.712-.288A.965.965 0 0 1 16 12c0-.283.096-.521.288-.713A.967.967 0 0 1 17 11c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 17 13ZM7 13a.968.968 0 0 1-.713-.288A.967.967 0 0 1 6 12a.97.97 0 0 1 .287-.713A.97.97 0 0 1 7 11a.97.97 0 0 1 .713.287A.97.97 0 0 1 8 12c0 .283-.096.52-.287.712A.968.968 0 0 1 7 13Z" })
  }
);
export {
  t as default
};
