export { default as IconChina } from "./China";
export { default as IconFinland } from "./Finland";
export { default as IconFrance } from "./France";
export { default as IconGermany } from "./Germany";
export { default as IconItaly } from "./Italy";
export { default as IconJapan } from "./Japan";
export { default as IconNetherland } from "./Netherland";
export { default as IconNorway } from "./Norway";
export { default as IconPoland } from "./Poland";
export { default as IconPortugal } from "./Portugal";
export { default as IconSpain } from "./Spain";
export { default as IconSweden } from "./Sweden";
export { default as IconUnitedKingdom } from "./UnitedKingdom";
export { default as IconUnitedStates } from "./UnitedStates";
export { default as IconWales } from "./Wales";
