import styled, { css } from "styled-components";
import { radius400 } from "../rectangles";

const Box = styled.div<{ borderRadius?: string, shadow?: string }>`
  width: 128px;
  height: 96px;
  border-radius: ${({ borderRadius }) =>
          borderRadius ? borderRadius : radius400};
  background-color: ${({ shadow }) => (shadow ? "var(--color-white100)" : "var(--color-gray600)")};
  ${({ shadow }) =>
          !!shadow &&
          css`
            box-shadow: ${shadow};
          `}
`;

const Wrapper = styled.div`
  margin: 0 32px 32px 0;
  display: inline-block;
`;

const RectangleExample = ({ borderRadius, name, shadow = "" }: { borderRadius?: string, name: string, shadow?: string }) => {
  return (
    <Wrapper style={{ width: "150px"}}>
      <Box borderRadius={borderRadius} shadow={shadow} />
      <p>{name}</p>
    </Wrapper>
  );
};

const Circle = styled(Box)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const CircleExample = ({ name, shadow = "" }: { name: string, shadow?: string }) => {
  return (
    <Wrapper>
      <Circle shadow={shadow} />
      <p>{name}</p>
    </Wrapper>
  );
};

export default RectangleExample;
