import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "m21.1 18.15-5.475-5.475c.167-.2.375-.362.625-.487s.533-.188.85-.188h2v-1c0-1.967-.683-3.625-2.05-4.975C15.683 4.675 14.033 4 12.1 4c-.733 0-1.43.104-2.088.312A6.68 6.68 0 0 0 8.2 5.2L6.75 3.8a8.45 8.45 0 0 1 2.488-1.338A8.848 8.848 0 0 1 12.1 2c1.233 0 2.396.233 3.488.7a9.018 9.018 0 0 1 2.862 1.925 9.2 9.2 0 0 1 1.938 2.862A8.715 8.715 0 0 1 21.1 11v7.15Zm-8 4.85a.965.965 0 0 1-.712-.288A.965.965 0 0 1 12.1 22c0-.283.096-.52.288-.712A.965.965 0 0 1 13.1 21h5l-1-1c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 15.1 18L5.575 8.475c-.15.4-.267.808-.35 1.225-.083.417-.125.85-.125 1.3v1h2c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413v4c0 .55-.196 1.021-.588 1.413A1.925 1.925 0 0 1 7.1 20h-2c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 3.1 18v-7c0-.75.08-1.454.238-2.113a8.323 8.323 0 0 1 .737-1.937L1.4 4.275a.914.914 0 0 1-.287-.7c.008-.267.112-.5.312-.7.2-.183.433-.28.7-.288.267-.008.5.088.7.288l18.375 18.4a.932.932 0 0 1 .275.687c0 .275-.092.513-.275.713-.2.2-.429.308-.687.325-.259.017-.455-.042-.588-.175a.664.664 0 0 1-.35.15A3.886 3.886 0 0 1 19.1 23h-6Z" })
  }
);
export {
  t as default
};
