import { jsx as A } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M8 11a.968.968 0 0 0 .713-.288A.967.967 0 0 0 9 10a.97.97 0 0 0-.287-.713A.97.97 0 0 0 8 9a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 10c0 .283.096.52.287.712.192.192.43.288.713.288Zm4 0a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 10a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 9a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 10c0 .283.096.52.288.712A.965.965 0 0 0 12 11Zm4 0c.283 0 .52-.096.712-.288A.965.965 0 0 0 17 10a.968.968 0 0 0-.288-.713A.967.967 0 0 0 16 9a.967.967 0 0 0-.712.287A.968.968 0 0 0 15 10c0 .283.096.52.288.712A.965.965 0 0 0 16 11ZM2 19.575V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 4 2h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 18H6l-2.3 2.3c-.317.317-.68.387-1.088.212-.408-.175-.612-.487-.612-.937Z" })
  }
);
export {
  t as default
};
