import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M19.25 16.402 8.4 5.552l2.55-2.525c.15-.15.317-.259.5-.325a1.6 1.6 0 0 1 .55-.1c.183 0 .367.037.55.112.183.075.35.18.5.313l4.6 4.525c.733.7 1.308 1.529 1.725 2.487.417.958.625 1.98.625 3.063a7.3 7.3 0 0 1-.2 1.725 8.386 8.386 0 0 1-.55 1.575Zm-7.25 4.6c-2.217 0-4.104-.771-5.662-2.313C4.779 17.147 4 15.285 4 13.102c0-.85.133-1.667.4-2.45a8.175 8.175 0 0 1 1.2-2.25l-3.5-3.5a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l17 17a.948.948 0 0 1 .275.7.949.949 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-2.4-2.4a8.287 8.287 0 0 1-2.2 1.112 7.933 7.933 0 0 1-2.5.388Z" })
  }
);
export {
  l as default
};
