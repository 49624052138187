/**
 * Custom event types
 * @type {{HIDE: string, SHOW: string}}
 */
export const TOAST_EVENT = {
  SHOW: "Toast.show",
  HIDE: "Toast.hide",
};

// Matches with the Banner component states
export const TOAST_STATE = {
  INFO: "info",
  SUCCESS: "success",
  WARN: "warn",
  ERROR: "error",
};

export const TOAST_POSITION = {
  RIGHT: "right",
  CENTER: "center",
  LEFT: "left",
};
