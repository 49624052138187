import { jsx as h } from "react/jsx-runtime";
const o = (A) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ h("path", { d: "M4 21a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 20c0-.283.096-.52.288-.712A.965.965 0 0 1 4 19h1V4a.97.97 0 0 1 .287-.713A.97.97 0 0 1 6 3h8a.97.97 0 0 1 .713.287A.97.97 0 0 1 15 4h3c.283 0 .52.096.712.287.192.192.288.43.288.713v14h1c.283 0 .52.096.712.288A.965.965 0 0 1 21 20c0 .283-.096.52-.288.712A.965.965 0 0 1 20 21h-2a.965.965 0 0 1-.712-.288A.965.965 0 0 1 17 20V6h-2v14c0 .283-.096.52-.287.712A.968.968 0 0 1 14 21H4ZM7 5v14V5Zm5 7a.97.97 0 0 0-.287-.713A.97.97 0 0 0 11 11a.967.967 0 0 0-.712.287A.968.968 0 0 0 10 12c0 .283.096.52.288.712A.965.965 0 0 0 11 13a.968.968 0 0 0 .713-.288A.967.967 0 0 0 12 12Zm-5 7h6V5H7v14Z" })
  }
);
export {
  o as default
};
