import { jsx as s } from "react/jsx-runtime";
const A = (c) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ s("path", { d: "M6 20a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 3 17c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 6 14c.833 0 1.542.292 2.125.875S9 16.167 9 17s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 6 20Zm12 0a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 15 17c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 18 14c.833 0 1.542.292 2.125.875S21 16.167 21 17s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 18 20Zm-6-10a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 9 7c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 12 4c.833 0 1.542.292 2.125.875S15 6.167 15 7s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 12 10Z" })
  }
);
export {
  A as default
};
