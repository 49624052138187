import styled from "styled-components";
import { shadow400 } from "@happeouikit/theme";
import { white } from "@happeouikit/colors";

const TypeaheadDropdown = ({ maxHeight, children }) => (
  <Dropdown>
    <DropdownContent maxHeight={maxHeight}>{children}</DropdownContent>
  </Dropdown>
);

const Dropdown = styled.div`
  position: relative;
  display: block;
  width: 100%;
  z-index: 1000;
`;

const DropdownContent = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  background: ${white};
  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: ${shadow400};
  border-radius: 4px;
  max-height: ${(props) => props.maxHeight};
  overflow-y: auto;
`;

export default TypeaheadDropdown;
