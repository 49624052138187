/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Intercom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.317 12.977c0 .178-.07.349-.195.474a.663.663 0 0 1-1.135-.474v-5.99c0-.178.07-.348.195-.474a.663.663 0 0 1 1.135.474v5.99Zm-.23 4.15c-.089.09-2.563 2.167-7.098 2.167s-6.993-2.067-7.097-2.155a.658.658 0 0 1-.202-.706.663.663 0 0 1 1.059-.297c.039.027 2.249 1.834 6.234 1.834 3.986 0 6.213-1.818 6.235-1.834a.672.672 0 0 1 .94.072.663.663 0 0 1-.066.925l-.006-.005ZM4.654 6.988a.678.678 0 0 1 .222-.465.663.663 0 0 1 .917.025.673.673 0 0 1 .192.44v5.98c0 .177-.07.348-.195.473a.662.662 0 0 1-.94 0 .673.673 0 0 1-.196-.474V6.987Zm3.337-1.33a.678.678 0 0 1 .222-.465.663.663 0 0 1 .917.025.673.673 0 0 1 .192.44v8.866c0 .178-.07.349-.195.474a.663.663 0 0 1-1.136-.474V5.657Zm3.354-.332c0-.178.07-.349.195-.474a.663.663 0 0 1 1.135.474v9.642c0 .177-.07.348-.195.474a.663.663 0 0 1-1.135-.474V5.325Zm3.298.332c0-.178.07-.348.195-.474a.662.662 0 0 1 .94 0 .673.673 0 0 1 .196.474v8.866c0 .178-.07.349-.195.474a.663.663 0 0 1-1.136-.474V5.657ZM19.5 2H4.5a2.468 2.468 0 0 0-1.763.724A2.506 2.506 0 0 0 2 4.494v14.961a2.523 2.523 0 0 0 .738 1.77 2.482 2.482 0 0 0 1.763.723H19.5a2.466 2.466 0 0 0 1.762-.721A2.503 2.503 0 0 0 22 19.46V4.495a2.523 2.523 0 0 0-.736-1.768A2.483 2.483 0 0 0 19.504 2"
      fill="#0057FF"
    />
  </svg>
);

export default Intercom;
