import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M9.825 6c0-.283.096-.52.288-.713A.968.968 0 0 1 10.825 5H20c.283 0 .52.096.712.287.192.192.288.43.288.713v4.5c0 .283-.096.52-.288.713A.968.968 0 0 1 20 11.5h-9.175a.968.968 0 0 1-.712-.287.968.968 0 0 1-.288-.713V6ZM4 19a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 18V6c0-.283.096-.52.288-.713A.968.968 0 0 1 4 5h3.825c.283 0 .52.096.712.287.192.192.288.43.288.713v12c0 .283-.096.52-.287.712a.967.967 0 0 1-.713.288H4Zm16-6.5c.283 0 .52.096.712.287.192.192.288.43.288.713V18c0 .283-.096.52-.288.712A.968.968 0 0 1 20 19h-3.075a.968.968 0 0 1-.713-.288.968.968 0 0 1-.287-.712v-4.5c0-.283.096-.52.287-.713a.968.968 0 0 1 .713-.287H20Zm-10.175 1c0-.283.096-.52.288-.713a.968.968 0 0 1 .712-.287h3.1c.283 0 .52.096.712.287.192.192.288.43.288.713V18c0 .283-.096.52-.288.712a.968.968 0 0 1-.712.288h-3.1a.968.968 0 0 1-.712-.288.968.968 0 0 1-.288-.712v-4.5Z" })
  }
);
export {
  t as default
};
