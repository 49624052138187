import { jsx as t } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M9.525 18.025c-.333.217-.671.23-1.013.037-.341-.191-.512-.487-.512-.887V6.825c0-.4.17-.696.512-.888.342-.191.68-.178 1.013.038l8.15 5.175c.3.2.45.484.45.85 0 .367-.15.65-.45.85l-8.15 5.175ZM10 15.35 15.25 12 10 8.65v6.7Z" })
  }
);
export {
  o as default
};
