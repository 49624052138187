import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-ms-outlook_svg__a",
      x1: 28.5,
      y1: 25,
      x2: 28.5,
      y2: 44,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { offset: 0, stopColor: "#35b8f1" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#28a8ea" })
  ), /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-ms-outlook_svg__b",
      x1: 5.822,
      y1: 11.568,
      x2: 20.178,
      y2: 36.432,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { offset: 0, stopColor: "#1784d9" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.5, stopColor: "#107ad5" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#0a63c9" })
  )),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M45 25a.96.96 0 0 0-.458-.825h-.005l-.018-.01-14.9-8.82a2.026 2.026 0 0 0-.2-.119 2 2 0 0 0-1.834 0 2.026 2.026 0 0 0-.2.119l-14.9 8.82-.018.01a.971.971 0 0 0 .023 1.663l14.9 8.82a2.241 2.241 0 0 0 .2.119 2 2 0 0 0 1.834 0 2.241 2.241 0 0 0 .2-.119l14.9-8.82A.959.959 0 0 0 45 25z",
      fill: "#0a2767"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#0364b8",
      d: "M14.222 19.111H24v8.963h-9.778zM43 10V5.9A1.858 1.858 0 0 0 41.187 4H15.813A1.858 1.858 0 0 0 14 5.9V10l15 4z"
    }
  ),
  /* @__PURE__ */ e.createElement("path", { fill: "#0078d4", d: "M14 10h10v9H14z" }),
  /* @__PURE__ */ e.createElement("path", { fill: "#28a8ea", d: "M34 10H24v9l10 9h9v-9l-9-9z" }),
  /* @__PURE__ */ e.createElement("path", { fill: "#0078d4", d: "M24 19h10v9H24z" }),
  /* @__PURE__ */ e.createElement("path", { fill: "#0364b8", d: "M24 28h10v9H24z" }),
  /* @__PURE__ */ e.createElement("path", { fill: "#14447d", d: "M14.222 28.074H24v8.148h-9.778z" }),
  /* @__PURE__ */ e.createElement("path", { fill: "#0078d4", d: "M34 28h9v9h-9z" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m44.542 25.783-.019.01-14.9 8.38c-.065.04-.131.078-.2.112a2.077 2.077 0 0 1-.808.2l-.814-.476a2.033 2.033 0 0 1-.2-.115l-15.1-8.618h-.007L12 25v16.964A2.05 2.05 0 0 0 14.063 44h28.906c.017 0 .032-.008.05-.008a2.531 2.531 0 0 0 .7-.146A2.019 2.019 0 0 0 44 43.7c.067-.038.182-.121.182-.121A2.019 2.019 0 0 0 45 41.964V25a.9.9 0 0 1-.458.783z",
      fill: "url(#Icon-ms-outlook_svg__a)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M44.2 24.933v1.04L28.62 36.7 12.49 25.283a.01.01 0 0 0-.01-.01l-1.48-.89v-.75l.61-.01 1.29.74.03.01.11.07s15.16 8.65 15.2 8.67l.58.34c.05-.02.1-.04.16-.06.03-.02 15.05-8.47 15.05-8.47z",
      fill: "#0a2767",
      opacity: 0.5
    }
  ),
  /* @__PURE__ */ e.createElement("path", { fill: "none", d: "M0 0h48v48H0z" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m44.542 25.783-.019.011-14.9 8.38c-.065.04-.131.078-.2.112a2.089 2.089 0 0 1-1.834 0 2.264 2.264 0 0 1-.2-.112l-14.9-8.38-.018-.011A.9.9 0 0 1 12 25v16.964A2.049 2.049 0 0 0 14.062 44h28.876A2.049 2.049 0 0 0 45 41.964V25a.9.9 0 0 1-.458.783z",
      fill: "#1490df"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m29.84 34.05-.223.125a2.183 2.183 0 0 1-.2.113 2.071 2.071 0 0 1-.786.208L34.3 41.2l9.889 2.383a2.038 2.038 0 0 0 .627-.783z",
      opacity: 0.1
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m30.85 33.482-1.233.693a2.183 2.183 0 0 1-.2.113 2.071 2.071 0 0 1-.786.208l2.656 7.323 12.905 1.761A2.022 2.022 0 0 0 45 41.964v-.219z",
      opacity: 0.05
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M14.09 44h28.845a2.074 2.074 0 0 0 1.235-.4L27.8 34.011a2.033 2.033 0 0 1-.2-.115l-15.1-8.618h-.007L12 25v16.906A2.092 2.092 0 0 0 14.09 44z",
      fill: "#28a8ea"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M26 13.833v21.33a1.838 1.838 0 0 1-1.15 1.7 1.723 1.723 0 0 1-.68.14H12V13h2v-1h10.17A1.837 1.837 0 0 1 26 13.833z",
      opacity: 0.1
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M25 14.833v21.33a1.629 1.629 0 0 1-.15.7A1.822 1.822 0 0 1 23.17 38H12V13h11.17a1.679 1.679 0 0 1 .83.21 1.818 1.818 0 0 1 1 1.623z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M25 14.833v19.33A1.846 1.846 0 0 1 23.17 36H12V13h11.17a1.679 1.679 0 0 1 .83.21 1.818 1.818 0 0 1 1 1.623z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M24 14.833v19.33A1.839 1.839 0 0 1 22.17 36H12V13h10.17A1.831 1.831 0 0 1 24 14.833z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 2,
      y: 13,
      width: 22,
      height: 22,
      rx: 1.833,
      fill: "url(#Icon-ms-outlook_svg__b)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M7.729 20.688a5.332 5.332 0 0 1 2.094-2.313 6.33 6.33 0 0 1 3.327-.834 5.892 5.892 0 0 1 3.079.791 5.284 5.284 0 0 1 2.037 2.21 7.133 7.133 0 0 1 .714 3.25 7.528 7.528 0 0 1-.735 3.4 5.41 5.41 0 0 1-2.1 2.287 6.126 6.126 0 0 1-3.194.812 6.02 6.02 0 0 1-3.147-.8 5.366 5.366 0 0 1-2.064-2.214 6.963 6.963 0 0 1-.722-3.211 7.733 7.733 0 0 1 .711-3.378zm2.232 5.43a3.461 3.461 0 0 0 1.178 1.522 3.077 3.077 0 0 0 1.84.554 3.223 3.223 0 0 0 1.964-.572 3.315 3.315 0 0 0 1.144-1.522 5.908 5.908 0 0 0 .365-2.123 6.463 6.463 0 0 0-.344-2.15A3.4 3.4 0 0 0 15 20.241a3.043 3.043 0 0 0-1.947-.6 3.175 3.175 0 0 0-1.883.559 3.49 3.49 0 0 0-1.2 1.535 6.1 6.1 0 0 0-.008 4.385z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ e.createElement("path", { fill: "#50d9ff", d: "M34 10h9v9h-9z" })
);
export {
  a as default
};
