import * as e from "react";
const t = (c) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...c
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m12.845 3.636 2.13 2.13c.4.4.4 1.04 0 1.42l-2.13 2.08c-.63.62-1.7.18-1.7-.71v-1.53c-2.84.48-5 2.94-5 5.91 0 2.73 1.82 5.03 4.32 5.75.41.12.68.51.68.94v.03c0 .64-.61 1.14-1.23.96a7.997 7.997 0 0 1-5.77-7.68c0-4.08 3.06-7.44 7-7.93v-.66c0-.89 1.08-1.33 1.7-.71Zm6.31 5.47c.28.51.5 1.04.66 1.59.19.62-.31 1.24-.96 1.24h-.02c-.43 0-.82-.28-.94-.7-.12-.4-.28-.79-.48-1.16a.972.972 0 0 1 .17-1.16l.01-.01c.46-.46 1.25-.37 1.56.2Zm-6.01 10.53c0 .65.62 1.14 1.24.96.55-.16 1.08-.38 1.59-.66.57-.31.66-1.1.2-1.56l-.02-.02a.972.972 0 0 0-1.16-.17c-.37.21-.76.37-1.16.49-.41.12-.69.51-.69.94v.02Zm6-2.87c-.31.57-1.1.66-1.56.2-.3-.3-.38-.78-.17-1.16.2-.38.36-.77.48-1.17.12-.42.5-.7.94-.7h.02c.65 0 1.14.62.96 1.24a8.51 8.51 0 0 1-.67 1.59Z"
    }
  )
);
export {
  t as default
};
