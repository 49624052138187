import { jsx as a } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "m19.975 17.15-6.95-6.95V9a.97.97 0 0 0-.287-.713.97.97 0 0 0-.713-.287.878.878 0 0 0-.475.125 1.347 1.347 0 0 0-.325.275l-3.35-3.35a9.384 9.384 0 0 1 2-.775A8.45 8.45 0 0 1 12.025 4c1 0 1.98.167 2.938.5a10.05 10.05 0 0 1 2.712 1.45l.725-.725a.918.918 0 0 1 .675-.275c.267 0 .5.1.7.3a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-.7.7a10.05 10.05 0 0 1 1.45 2.712c.333.959.5 1.938.5 2.938 0 .7-.087 1.396-.262 2.087a9.803 9.803 0 0 1-.788 2.063ZM12.025 22a8.647 8.647 0 0 1-3.487-.712 9.197 9.197 0 0 1-2.863-1.938 9.188 9.188 0 0 1-1.937-2.862A8.644 8.644 0 0 1 3.025 13c0-1 .154-1.962.463-2.887A8.36 8.36 0 0 1 4.825 7.6L2.1 4.875a.918.918 0 0 1-.275-.675c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275L20.55 20.525a.918.918 0 0 1 .275.675c0 .267-.1.5-.3.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-1.7-1.7a9.444 9.444 0 0 1-2.587 1.338 8.854 8.854 0 0 1-2.813.462Zm-2-19a.968.968 0 0 1-.713-.288A.967.967 0 0 1 9.025 2a.97.97 0 0 1 .287-.713.97.97 0 0 1 .713-.287h4a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288h-4Z" })
  }
);
export {
  h as default
};
