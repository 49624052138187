import { jsx as c } from "react/jsx-runtime";
const v = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M6.5 21c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 5 19.5v-1.55a4.264 4.264 0 0 1-.725-1.113A3.228 3.228 0 0 1 4 15.5V6c0-1.383.642-2.396 1.925-3.038C7.208 2.321 9.233 2 12 2c2.867 0 4.917.308 6.15.925C19.383 3.542 20 4.567 20 6v9.5c0 .483-.092.93-.275 1.337-.183.409-.425.78-.725 1.113v1.55c0 .417-.146.77-.438 1.063A1.446 1.446 0 0 1 17.5 21c-.417 0-.77-.146-1.063-.438A1.446 1.446 0 0 1 16 19.5V19H8v.5c0 .417-.146.77-.438 1.063A1.446 1.446 0 0 1 6.5 21ZM6 10h12V7H6v3Zm2.5 6c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 8.5 13c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 7 14.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437Zm7 0c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 15.5 13c-.417 0-.77.146-1.063.438A1.446 1.446 0 0 0 14 14.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437Z" })
  }
);
export {
  v as default
};
