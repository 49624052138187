import * as v from "react";
const e = (h) => /* @__PURE__ */ v.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...h
  },
  /* @__PURE__ */ v.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 5v2h8c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2ZM4 19h2v-2H4v2Zm2-4H4v-2h2v2Zm-2-4h2V9H4v2Zm2-4H4V5h2v2Zm2 12h2v-2H8v2Zm2-4H8v-2h2v2Zm-2-4h2V9H8v2Zm2-4H8V5h2v2Zm2 12h7c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-7v2h2v2h-2v2h2v2h-2v2Zm6-8h-2v2h2v-2Zm-2 4h2v2h-2v-2Z"
    }
  )
);
export {
  e as default
};
