import { jsx as A } from "react/jsx-runtime";
const a = (c) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ A("path", { d: "M4 21a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 20c0-.283.096-.52.288-.712A.965.965 0 0 1 4 19h16c.283 0 .52.096.712.288A.965.965 0 0 1 21 20c0 .283-.096.52-.288.712A.965.965 0 0 1 20 21H4Zm0-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 16c0-.283.096-.521.288-.713A.967.967 0 0 1 4 15h4a.97.97 0 0 1 .713.287A.97.97 0 0 1 9 16c0 .283-.096.52-.287.712A.968.968 0 0 1 8 17H4Zm0-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 12c0-.283.096-.521.288-.713A.967.967 0 0 1 4 11h4a.97.97 0 0 1 .713.287A.97.97 0 0 1 9 12c0 .283-.096.52-.287.712A.968.968 0 0 1 8 13H4Zm0-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 8c0-.283.096-.521.288-.713A.967.967 0 0 1 4 7h4a.97.97 0 0 1 .713.287A.97.97 0 0 1 9 8c0 .283-.096.52-.287.712A.968.968 0 0 1 8 9H4Zm0-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 4c0-.283.096-.521.288-.713A.967.967 0 0 1 4 3h16c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 5H4Zm8 12a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 16V8c0-.283.096-.521.288-.713A.967.967 0 0 1 12 7h10c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .283-.096.52-.288.712A.965.965 0 0 1 22 17H12Zm1-2h8V9h-8v6Z" })
  }
);
export {
  a as default
};
