import { jsx as e } from "react/jsx-runtime";
const o = (d) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...d,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1 11.755h11v10.756l11-10.755V1H12L1 11.755Z"
      }
    )
  }
);
export {
  o as default
};
