/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraDevelopmentTask = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z"
      fill="#4BAEE8"
    />
    <path
      d="M10.373 9.05a.9.9 0 0 0-1.246-1.3l-3.75 3.6a.897.897 0 0 0 0 1.3l3.75 3.6a.9.9 0 1 0 1.246-1.3L7.3 12l3.073-2.95ZM13.6 7.777a.9.9 0 0 0 .027 1.272L16.7 12l-3.073 2.95a.9.9 0 1 0 1.246 1.3l3.75-3.6a.895.895 0 0 0 .233-.927.895.895 0 0 0-.233-.372l-3.75-3.6a.9.9 0 0 0-1.272.026Z"
      fill="#fff"
    />
  </svg>
);

export default JiraDevelopmentTask;
