import * as c from "react";
const e = (a) => /* @__PURE__ */ c.createElement(
  "svg",
  {
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ c.createElement(
    "path",
    {
      d: "m6.4 15.6-3.333-3.333a1.279 1.279 0 0 1-.4-.933c0-.356.133-.667.4-.934L6.4 7.067c.267-.267.578-.4.933-.4.356 0 .667.133.934.4L11.6 10.4c.267.267.4.578.4.934 0 .355-.133.666-.4.933L8.267 15.6a1.28 1.28 0 0 1-.934.4 1.28 1.28 0 0 1-.933-.4Zm6.933 13.734a1.29 1.29 0 0 1-.95-.384A1.29 1.29 0 0 1 12 28v-5.333a53.546 53.546 0 0 1-3.3-.367 42.117 42.117 0 0 1-3.267-.6 1.514 1.514 0 0 1-.916-.633A1.19 1.19 0 0 1 4.333 20c.112-.378.34-.655.684-.833a1.528 1.528 0 0 1 1.083-.134c1.622.378 3.261.634 4.917.767a62.069 62.069 0 0 0 9.966 0 33.513 33.513 0 0 0 4.917-.767 1.528 1.528 0 0 1 1.083.134c.345.178.573.455.684.833.11.378.05.733-.184 1.067a1.514 1.514 0 0 1-.916.633 42.106 42.106 0 0 1-3.267.6 53.546 53.546 0 0 1-3.3.367V28c0 .378-.128.695-.383.95a1.29 1.29 0 0 1-.95.384h-5.334Zm-6-16.534L8.8 11.334 7.333 9.867l-1.466 1.466L7.333 12.8ZM16 9.334c-1.111 0-2.056-.39-2.833-1.167C12.389 7.389 12 6.445 12 5.334c0-1.112.389-2.056 1.167-2.834.777-.778 1.722-1.167 2.833-1.167 1.111 0 2.056.39 2.833 1.167C19.611 3.278 20 4.222 20 5.333c0 1.112-.389 2.056-1.167 2.834-.777.778-1.722 1.167-2.833 1.167Zm0 9.333a2.568 2.568 0 0 1-1.883-.784A2.568 2.568 0 0 1 13.333 16c0-.733.261-1.36.784-1.883A2.568 2.568 0 0 1 16 13.333c.733 0 1.361.262 1.883.784.523.522.784 1.15.784 1.883 0 .733-.261 1.361-.784 1.884a2.568 2.568 0 0 1-1.883.783Zm0-12c.378 0 .695-.128.95-.383a1.29 1.29 0 0 0 .383-.95 1.29 1.29 0 0 0-.383-.95A1.29 1.29 0 0 0 16 4a1.29 1.29 0 0 0-.95.383 1.29 1.29 0 0 0-.383.95c0 .378.127.695.383.95.256.256.572.384.95.384Zm6.367 8.667-1.534-2.667a1.35 1.35 0 0 1-.166-.667c0-.244.055-.466.166-.666l1.534-2.667c.11-.222.266-.39.466-.5.2-.111.423-.167.667-.167h3c.244 0 .467.056.667.167.2.11.355.278.466.5l1.534 2.667c.11.2.166.422.166.666 0 .245-.055.467-.166.667l-1.534 2.667a1.133 1.133 0 0 1-.466.5A1.35 1.35 0 0 1 26.5 16h-3a1.35 1.35 0 0 1-.667-.166 1.134 1.134 0 0 1-.466-.5Zm1.9-2h1.466L26.467 12l-.734-1.333h-1.466L23.533 12l.734 1.334Z",
      fill: "#434F59"
    }
  )
);
export {
  e as default
};
