import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 22.95a.967.967 0 0 1-.712-.287.968.968 0 0 1-.288-.713v-1c-2.083-.233-3.87-1.096-5.362-2.587C4.146 16.87 3.283 15.083 3.05 13h-1a.968.968 0 0 1-.713-.288A.967.967 0 0 1 1.05 12a.97.97 0 0 1 .287-.713A.97.97 0 0 1 2.05 11h1c.233-2.084 1.096-3.871 2.588-5.363C7.129 4.145 8.917 3.283 11 3.05v-1c0-.284.096-.521.288-.713A.967.967 0 0 1 12 1.05a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v1c2.083.233 3.871 1.095 5.363 2.587 1.491 1.492 2.354 3.28 2.587 5.363h1a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288h-1c-.233 2.083-1.096 3.87-2.587 5.363-1.492 1.491-3.28 2.354-5.363 2.587v1a.97.97 0 0 1-.287.713.97.97 0 0 1-.713.287ZM12 19c1.933 0 3.583-.683 4.95-2.05C18.317 15.583 19 13.933 19 12c0-1.934-.683-3.584-2.05-4.95C15.583 5.683 13.933 5 12 5c-1.933 0-3.583.683-4.95 2.05C5.683 8.416 5 10.066 5 12c0 1.933.683 3.583 2.05 4.95C8.417 18.317 10.067 19 12 19Z" })
  }
);
export {
  s as default
};
