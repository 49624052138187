import { jsx as A } from "react/jsx-runtime";
const a = (c) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ A("path", { d: "M7 17c-1.383 0-2.562-.488-3.537-1.463C2.488 14.562 2 13.383 2 12s.488-2.563 1.463-3.538C4.438 7.487 5.617 7 7 7h3a.97.97 0 0 1 .713.287A.97.97 0 0 1 11 8c0 .283-.096.52-.287.712A.968.968 0 0 1 10 9H7c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 4 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 7 15h3a.97.97 0 0 1 .713.287A.97.97 0 0 1 11 16c0 .283-.096.52-.287.712A.968.968 0 0 1 10 17H7Zm2-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 8 12c0-.283.096-.521.288-.713A.967.967 0 0 1 9 11h6a.97.97 0 0 1 .713.287A.97.97 0 0 1 16 12c0 .283-.096.52-.287.712A.968.968 0 0 1 15 13H9Zm13-1h-2c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 17 9h-3a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 8c0-.283.096-.521.288-.713A.967.967 0 0 1 14 7h3c1.383 0 2.563.487 3.538 1.462C21.513 9.437 22 10.617 22 12Zm-4 8a.965.965 0 0 1-.712-.288A.965.965 0 0 1 17 19v-2h-2a.965.965 0 0 1-.712-.288A.965.965 0 0 1 14 16c0-.283.096-.521.288-.713A.967.967 0 0 1 15 15h2v-2c0-.283.096-.521.288-.713A.967.967 0 0 1 18 12c.283 0 .52.096.712.287.192.192.288.43.288.713v2h2c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 21 17h-2v2c0 .283-.096.52-.288.712A.965.965 0 0 1 18 20Z" })
  }
);
export {
  a as default
};
