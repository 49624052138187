import { jsx as a } from "react/jsx-runtime";
const s = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M18.2 15.325 13.775 10.9c.083-.133.142-.275.175-.425.033-.15.05-.308.05-.475 0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 12 8c-.167 0-.325.017-.475.05-.15.033-.292.092-.425.175L6.775 3.9a7.73 7.73 0 0 1 2.413-1.413A8.151 8.151 0 0 1 12 2c2.117 0 3.979.742 5.587 2.225C19.196 5.708 20 7.7 20 10.2c0 .8-.15 1.62-.45 2.462-.3.842-.75 1.73-1.35 2.663Zm-6.95 6.025C8.817 19.2 7 17.208 5.8 15.375 4.6 13.542 4 11.817 4 10.2a8.733 8.733 0 0 1 .475-2.9l-3.1-3.1a.948.948 0 0 1-.275-.7c0-.283.092-.525.275-.725.2-.183.442-.275.725-.275s.525.092.725.275l18.375 18.4a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7c-.2.2-.442.3-.725.3a.908.908 0 0 1-.7-.3l-4.1-4.075c-.433.467-.892.938-1.375 1.413-.483.475-1 .954-1.55 1.437a1.2 1.2 0 0 1-.35.2c-.133.05-.267.075-.4.075s-.267-.025-.4-.075a1.2 1.2 0 0 1-.35-.2Z" })
  }
);
export {
  s as default
};
