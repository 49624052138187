import { jsx as l } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ l("path", { d: "m13.674 14.232-2.915-2.92-4.485 4.493 2.887 2.92 4.513-4.493ZM12.357 9.74l2.887 2.893 4.485-4.465-2.915-2.92-4.457 4.492Zm-2.355-.814 6.055 6.066-5.298 5.335c-.449.45-.977.674-1.584.674-.607 0-1.135-.225-1.584-.674l-.14.112c-.206.169-.43.3-.673.393-.243.094-.495.14-.757.14h-3.42c-.261 0-.443-.111-.546-.336-.103-.225-.061-.43.126-.618l2.579-2.555a2.308 2.308 0 0 1-.7-1.615 2.11 2.11 0 0 1 .644-1.614l5.298-5.308Zm0 0 5.242-5.251c.449-.45.977-.674 1.584-.674.607 0 1.135.225 1.584.674l2.915 2.892c.449.45.673.978.673 1.587 0 .608-.224 1.137-.673 1.586l-5.27 5.252-6.055-6.066Z" })
  }
);
export {
  c as default
};
