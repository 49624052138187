/**
 *
 * Default Loader component
 *
 */
import PropTypes from "prop-types";

import { Modal } from "@happeouikit/modals";
import LoadingContainer from "./LoadingContainer";

const LoadingModal = ({
  isOpen,
  title,
  description,
  state,
  progress,
  showPercentage,
}) => (
  <Modal isOpen={isOpen} footer={false} iconClose={false}>
    <LoadingContainer
      title={title}
      description={description}
      state={state}
      progress={progress}
      showPercentage={showPercentage}
    />
  </Modal>
);

LoadingModal.propTypes = {
  isOpen: PropTypes.bool,
  description: PropTypes.string,
  state: PropTypes.oneOf(["loading", "success", "error", "progress"]),
  progress: PropTypes.number,
  showPercentage: PropTypes.bool,
};

LoadingModal.defaultProps = {
  isOpen: true,
  description: "",
  state: "loading",
  progress: 0,
  showPercentage: false,
};

export default LoadingModal;
