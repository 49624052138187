import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 32 28",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement("rect", { width: 32, height: 28, fill: "#EC4B2F", rx: 2 }), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#FFF",
      d: "M28 14.4v7.99c0 .894-.668 1.61-1.5 1.61h-21c-.828 0-1.5-.719-1.5-1.61V17.6l4.5-4.8 4.5 4.8L22 8l6 6.4ZM12.5 10c-.972 0-1.799-.34-2.48-1.02C9.34 8.298 9 7.471 9 6.5s.34-1.799 1.02-2.48C10.702 3.34 11.529 3 12.5 3s1.799.34 2.48 1.02C15.66 4.702 16 5.529 16 6.5s-.34 1.799-1.02 2.48c-.681.68-1.508 1.02-2.48 1.02Z"
    }
  ))
);
export {
  l as default
};
