import { jsx as c } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M6 19v.525c0 .417-.142.767-.425 1.05-.283.283-.642.425-1.075.425-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 3 19.5V12l2.1-6c.1-.3.28-.542.538-.725C5.896 5.092 6.183 5 6.5 5h11c.317 0 .604.092.863.275.258.183.437.425.537.725l2.1 6v7.525c0 .417-.142.767-.425 1.05-.283.283-.642.425-1.075.425-.417 0-.77-.146-1.063-.438A1.446 1.446 0 0 1 18 19.5V19H6Zm-.2-9h12.4l-1.05-3H6.85L5.8 10Zm1.7 6c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 7.5 13c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 6 14.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437Zm9 0c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.446 1.446 0 0 0 16.5 13c-.417 0-.77.146-1.063.438A1.446 1.446 0 0 0 15 14.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437ZM5 17h14v-5H5v5Z" })
  }
);
export {
  v as default
};
