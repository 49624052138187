/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const AmericanExpress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 21v-2.852h-2.172l-1.118-1.236-1.123 1.236h-7.16v-5.76h-2.31l2.867-6.489h2.764l.987 2.223V5.899h3.42l.594 1.675.598-1.675H21V3H3v18h18Zm-1.806-3.574H21l-2.389-2.533L21 12.39h-1.777l-1.476 1.612-1.46-1.612H14.48l2.376 2.52-2.376 2.52h1.755l1.483-1.626 1.476 1.626v-.003Zm.423-2.53L21 16.367v-2.931l-1.383 1.46Zm-8.049 1.365v-.785h2.844V14.34h-2.844v-.785h2.912v-1.166h-4.288v5.037h4.288v-1.165h-2.912Zm8.072-4.603H21V6.621h-2.116l-1.13 3.137-1.122-3.137H14.48v5.037h1.36V8.132l1.296 3.526h1.21l1.295-3.533v3.533h-.001Zm-6.707 0h1.548l-2.224-5.037h-1.769l-2.226 5.037h1.512l.418-1.008h2.317l.425 1.008Zm-.9-2.137h-1.365l.684-1.648.684 1.648h-.003Z"
      fill="#006FCF"
    />
  </svg>
);

export default AmericanExpress;
