import * as React from "react";
const SvgIconLocalFlorist = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1 13.25a2.5 2.5 0 0 1-2.5-2.5c0-1 .59-1.85 1.43-2.25-.84-.4-1.43-1.25-1.43-2.25a2.5 2.5 0 0 1 2.5-2.5c.53 0 1.02.16 1.42.44L9.5 4a2.5 2.5 0 0 1 5 0l-.02.19a2.5 2.5 0 0 1 3.92 2.06c0 1-.59 1.85-1.43 2.25.84.4 1.43 1.25 1.43 2.25a2.5 2.5 0 0 1-2.5 2.5c-.53 0-1.02-.16-1.42-.44l.02.19a2.5 2.5 0 0 1-5 0l.02-.19c-.41.28-.89.44-1.42.44Zm12.82 1.45c-.59 4.4-4.36 7.8-8.92 7.8 0-4.56 3.4-8.33 7.8-8.92.64-.09 1.21.48 1.12 1.12ZM12 22.5c0-4.56-3.4-8.33-7.8-8.92-.64-.09-1.21.48-1.12 1.12.59 4.4 4.36 7.8 8.92 7.8ZM12 6a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5Z"
    />
  </svg>
);
export default SvgIconLocalFlorist;
