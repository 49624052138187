import { jsxs as f, jsx as t } from "react/jsx-runtime";
const i = (h) => /* @__PURE__ */ f(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M1 3.75H.75v17.1h22.5V3.75H1Z",
          stroke: "#000",
          strokeOpacity: 0.2,
          strokeWidth: 0.5
        }
      ),
      /* @__PURE__ */ t("path", { d: "M1 4h22v16.6H1V4Z", fill: "#ED2939" }),
      /* @__PURE__ */ t("path", { d: "M7.188 4h4.125v16.6H7.186V4Z", fill: "#fff" }),
      /* @__PURE__ */ t("path", { d: "M1 10.225h22v4.15H1v-4.15Z", fill: "#fff" }),
      /* @__PURE__ */ t("path", { d: "M8.219 4h2.062v16.6H8.22V4Z", fill: "#002664" }),
      /* @__PURE__ */ t("path", { d: "M1 11.262h22v2.075H1v-2.075Z", fill: "#002664" })
    ]
  }
);
export {
  i as default
};
