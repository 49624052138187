import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "m13.9 5.5-.24-1.2c-.09-.46-.5-.8-.98-.8H5.5c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1v-6h5.6l.24 1.2c.09.47.5.8.98.8h5.18c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-4.6Z" })
);
export {
  c as default
};
