import { jsx as c } from "react/jsx-runtime";
const l = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M4 24c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 22c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 4 20h16c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 24H4Zm6.6-16 5.4 5.425-4 4c-.4.4-.87.6-1.412.6-.542 0-1.013-.2-1.413-.6l-.125.1c-.183.15-.383.267-.6.35a1.865 1.865 0 0 1-.675.125h-3.05c-.233 0-.396-.1-.487-.3-.092-.2-.055-.383.112-.55l2.3-2.275c-.4-.4-.608-.88-.625-1.438A1.876 1.876 0 0 1 6.6 12l4-4ZM12 6.575 16 2.6c.4-.4.87-.6 1.413-.6.541 0 1.012.2 1.412.6l2.6 2.575c.4.4.6.87.6 1.413 0 .541-.2 1.012-.6 1.412l-4 4L12 6.575Z" })
  }
);
export {
  l as default
};
