import { jsx as c } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M17.6 12 4.45 7.25a.993.993 0 0 1-.575-.525.964.964 0 0 1-.025-.775.993.993 0 0 1 .525-.575.964.964 0 0 1 .775-.025L19.8 10.7c.333.134.617.367.85.7.233.334.35.7.35 1.1V18c0 .55-.196 1.021-.587 1.413A1.926 1.926 0 0 1 19 20H5c-.55 0-1.02-.195-1.413-.587A1.926 1.926 0 0 1 3 18v-4c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 12.001h12.6ZM11 17h6c.283 0 .52-.095.712-.287A.968.968 0 0 0 18 16a.967.967 0 0 0-.288-.712.968.968 0 0 0-.712-.287h-6a.968.968 0 0 0-.713.287A.967.967 0 0 0 10 16c0 .284.096.521.287.713.192.192.43.287.713.287Zm-4 0c.283 0 .52-.095.713-.287A.968.968 0 0 0 8 16a.967.967 0 0 0-.287-.712.967.967 0 0 0-.713-.287.967.967 0 0 0-.713.287A.967.967 0 0 0 6 16c0 .284.096.521.287.713.192.192.43.287.713.287Z" })
  }
);
export {
  a as default
};
