import { jsx as c } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M8 21c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 6 19v-2H4c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 15v-4c0-.85.292-1.563.875-2.137C3.458 8.288 4.167 8 5 8h14c.85 0 1.563.287 2.137.863.575.575.863 1.287.863 2.137v4c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 17h-2v2c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 16 21H8ZM18 7H6V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 8 3h8c.55 0 1.02.196 1.413.587C17.803 3.98 18 4.45 18 5v2Zm0 5.5c.283 0 .52-.096.712-.287A.968.968 0 0 0 19 11.5a.968.968 0 0 0-.288-.713A.968.968 0 0 0 18 10.5a.968.968 0 0 0-.712.287.968.968 0 0 0-.288.713c0 .283.096.52.288.713.191.191.429.287.712.287ZM8 19h8v-4H8v4Z" })
  }
);
export {
  v as default
};
