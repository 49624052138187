import { jsx as h } from "react/jsx-runtime";
const A = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M4 11a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 10V4c0-.283.096-.52.288-.712A.968.968 0 0 1 4 3h6c.283 0 .52.096.713.288.191.191.287.429.287.712v6c0 .283-.096.52-.287.713A.968.968 0 0 1 10 11H4Zm10 0a.968.968 0 0 1-.713-.287A.968.968 0 0 1 13 10V4c0-.283.096-.52.287-.712A.968.968 0 0 1 14 3h6c.283 0 .52.096.712.288.192.191.288.429.288.712v6c0 .283-.096.52-.288.713A.968.968 0 0 1 20 11h-6ZM4 21a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 20v-6c0-.283.096-.52.288-.713A.967.967 0 0 1 4 13h6c.283 0 .52.096.713.287.191.192.287.43.287.713v6c0 .283-.096.52-.287.712A.968.968 0 0 1 10 21H4Zm13 0a.968.968 0 0 1-.712-.288A.968.968 0 0 1 16 20v-2h-2.025a.927.927 0 0 1-.7-.288A.991.991 0 0 1 13 17c0-.283.096-.52.287-.712A.968.968 0 0 1 14 16h2v-2.025c0-.283.096-.517.288-.7A.991.991 0 0 1 17 13c.283 0 .52.096.712.287.192.192.288.43.288.713v2h2.025c.283 0 .517.096.7.288.183.191.275.429.275.712s-.096.52-.288.712A.968.968 0 0 1 20 18h-2v2.025a.927.927 0 0 1-.288.7A.991.991 0 0 1 17 21ZM15 5v4h4V5h-4ZM5 5v4h4V5H5Zm0 10v4h4v-4H5Z" })
  }
);
export {
  A as default
};
