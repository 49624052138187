import * as e from "react";
const h = (c) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...c
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M7.537 6.155c.32.31.1.85-.35.85h-1.29v10h1.29a.5.5 0 0 1 .36.85l-2.29 2.29c-.2.2-.51.2-.71 0l-2.29-2.29c-.32-.31-.1-.85.35-.85h1.29v-10h-1.29a.5.5 0 0 1-.36-.85l2.29-2.3c.2-.2.51-.2.71 0l2.29 2.3Zm13.36.85h-10c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1Zm0 10h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1Zm-10-6h10c.55 0 1 .45 1 1s-.45 1-1 1h-10c-.55 0-1-.45-1-1s.45-1 1-1Z"
    }
  )
);
export {
  h as default
};
