import * as c from "react";
const s = (e) => /* @__PURE__ */ c.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...e
  },
  /* @__PURE__ */ c.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 5h.5v2.5c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5H3c-.28 0-.5.22-.5.5s.22.5.5.5Zm17.5 2h-12c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1Zm-12 10h12c.55 0 1 .45 1 1s-.45 1-1 1h-12c-.55 0-1-.45-1-1s.45-1 1-1Zm12-6h-12c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1ZM5 16c.28 0 .5.22.5.5v3c0 .28-.22.5-.5.5H3c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1.5v-.5H4c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h.5V17H3c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h2Zm-2.5-5.5c0-.28.22-.5.5-.5h2c.28 0 .5.22.5.5v.22c0 .11-.04.23-.12.32L3.7 13H5c.28 0 .5.22.5.5s-.22.5-.5.5H3c-.28 0-.5-.22-.5-.5v-.22c0-.11.04-.23.12-.32L4.3 11H3c-.28 0-.5-.22-.5-.5Z"
    }
  )
);
export {
  s as default
};
