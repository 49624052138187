import { jsx as t } from "react/jsx-runtime";
const s = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M9 15H5.9c-.4 0-.696-.18-.888-.538-.191-.358-.17-.704.063-1.038l7.475-10.75c.167-.233.383-.395.65-.487.267-.092.542-.088.825.012.283.1.492.275.625.525s.183.517.15.8L14 10h3.875c.433 0 .738.192.913.575.174.384.12.742-.163 1.075L10.4 21.5a1.27 1.27 0 0 1-.675.425 1.133 1.133 0 0 1-.775-.075 1.31 1.31 0 0 1-.587-.537 1.232 1.232 0 0 1-.163-.788L9 15Z" })
  }
);
export {
  s as default
};
