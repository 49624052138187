/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Channels = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm6.544 15.315a1.342 1.342 0 0 0 .913 0 1.69 1.69 0 0 0 .42-.224l4.836-3.761a.651.651 0 0 0 .278-.565.697.697 0 0 0-.296-.564.715.715 0 0 0-.86 0L12 16.945 7.164 13.2a.716.716 0 0 0-.86 0 .695.695 0 0 0-.295.564.65.65 0 0 0 .278.565l4.835 3.761c.132.096.272.17.422.224Zm0-3.618a1.342 1.342 0 0 0 .913 0 1.69 1.69 0 0 0 .42-.224l4.836-3.761a.689.689 0 0 0 .215-.26.731.731 0 0 0 0-.627.69.69 0 0 0-.215-.26l-4.835-3.76a1.69 1.69 0 0 0-.42-.225 1.348 1.348 0 0 0-.914 0c-.15.054-.29.129-.422.224L6.287 9.566a.69.69 0 0 0-.215.259.73.73 0 0 0 0 .628c.047.1.119.187.215.259l4.835 3.761c.132.096.272.17.422.224Z"
    />
  </svg>
);

export default Channels;
