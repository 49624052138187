import { jsx as a } from "react/jsx-runtime";
const t = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm5 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm7.44 10H6.56a.527.527 0 0 1-.49-.294c-.109-.196-.09-.383.054-.562l2.177-2.86a.52.52 0 0 1 .435-.215.52.52 0 0 1 .435.214l2.013 2.647 2.829-3.716a.52.52 0 0 1 .435-.214.52.52 0 0 1 .435.214l2.993 3.93c.145.179.163.366.054.562a.527.527 0 0 1-.49.294Z"
      }
    )
  }
);
export {
  t as default
};
