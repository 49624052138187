import { TOAST_EVENT } from "./constants";

function createEvent(eventType, detail = {}) {
  return new CustomEvent(eventType, {
    bubbles: true,
    detail,
  });
}

function show(params) {
  const event = createEvent(TOAST_EVENT.SHOW, params);
  return window.dispatchEvent(event);
}

function hide() {
  const event = createEvent(TOAST_EVENT.HIDE);
  return window.dispatchEvent(event);
}

// eslint-disable-next-line import/prefer-default-export
export const toast = {
  default: (params) => show({ ...params }),
  message: (params) => show({ state: "info", ...params }),
  success: (params) => show({ state: "success", ...params }),
  error: (params) => show({ state: "error", ...params }),
  hide,
};

export default toast;
