import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "m19.25 16.402-1.55-1.5a5.259 5.259 0 0 0 .3-1.8c0-.784-.146-1.525-.438-2.225a5.434 5.434 0 0 0-1.312-1.875L12 4.802l-2.2 2.15-1.4-1.4 2.55-2.525c.15-.15.317-.259.5-.325a1.6 1.6 0 0 1 .55-.1c.183 0 .367.037.55.112.183.075.35.18.5.313l4.6 4.525c.733.7 1.308 1.529 1.725 2.487.417.958.625 1.98.625 3.063a7.3 7.3 0 0 1-.2 1.725 8.386 8.386 0 0 1-.55 1.575Zm-7.25 4.6c-2.217 0-4.104-.771-5.662-2.313C4.779 17.147 4 15.285 4 13.102c0-.85.133-1.667.4-2.45a8.175 8.175 0 0 1 1.2-2.25l-3.5-3.5a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l17 17a.948.948 0 0 1 .275.7.949.949 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-2.4-2.4a8.287 8.287 0 0 1-2.2 1.112 7.933 7.933 0 0 1-2.5.388Zm0-2c.6 0 1.17-.084 1.713-.25a6.565 6.565 0 0 0 1.537-.7L7 9.852a6.077 6.077 0 0 0-.762 1.6A5.767 5.767 0 0 0 6 13.102c0 1.633.583 3.025 1.75 4.175s2.583 1.725 4.25 1.725Z" })
  }
);
export {
  l as default
};
