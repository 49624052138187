/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Slack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.638 14.897a2.308 2.308 0 0 1-2.31 2.31 2.308 2.308 0 0 1-2.31-2.31 2.308 2.308 0 0 1 2.31-2.31h2.31v2.31Zm1.155 0a2.308 2.308 0 0 1 2.31-2.31 2.308 2.308 0 0 1 2.31 2.31v5.775a2.308 2.308 0 0 1-2.31 2.31 2.308 2.308 0 0 1-2.31-2.31v-5.775Z"
      fill="#E01E5A"
    />
    <path
      d="M9.103 5.62a2.308 2.308 0 0 1-2.31-2.31A2.308 2.308 0 0 1 9.103 1a2.308 2.308 0 0 1 2.31 2.31v2.31h-2.31Zm0 1.173a2.308 2.308 0 0 1 2.31 2.31 2.308 2.308 0 0 1-2.31 2.31H3.31A2.308 2.308 0 0 1 1 9.104a2.308 2.308 0 0 1 2.31-2.31h5.793Z"
      fill="#36C5F0"
    />
    <path
      d="M18.362 9.103a2.308 2.308 0 0 1 2.31-2.31 2.308 2.308 0 0 1 2.31 2.31 2.308 2.308 0 0 1-2.31 2.31h-2.31v-2.31Zm-1.155 0a2.308 2.308 0 0 1-2.31 2.31 2.308 2.308 0 0 1-2.31-2.31V3.31A2.308 2.308 0 0 1 14.896 1a2.308 2.308 0 0 1 2.31 2.31v5.793Z"
      fill="#2EB67D"
    />
    <path
      d="M14.897 18.362a2.308 2.308 0 0 1 2.31 2.31 2.308 2.308 0 0 1-2.31 2.31 2.308 2.308 0 0 1-2.31-2.31v-2.31h2.31Zm0-1.155a2.308 2.308 0 0 1-2.31-2.31 2.308 2.308 0 0 1 2.31-2.31h5.793a2.308 2.308 0 0 1 2.31 2.31 2.308 2.308 0 0 1-2.31 2.31h-5.793Z"
      fill="#ECB22E"
    />
  </svg>
);

export default Slack;
