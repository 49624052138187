import { jsxs as l, jsx as t } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M21.007 10.5c0-5.246-4.261-9.5-9.507-9.5A9.5 9.5 0 0 0 2 10.5c0 4.97 4.53 9 9.5 9V23c5.772-2.927 9.507-7.639 9.507-12.5Z",
          fill: "#0F9D58"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "m16.25 8-2.75 2.75L10.75 8l-4 4 8.572 8.572c2.886-2.279 4.839-5.084 5.466-8.034L16.25 8Z",
          fill: "url(#google-hangouts_svg__a)"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M12.25 8v4h2.25v2.25c.981 0 1.75-1.25 1.75-2.5V8h-4ZM6.75 8v4H9v2.25c.98 0 1.75-1.25 1.75-2.5V8h-4Z",
          fill: "#EEE"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.4,
          d: "M6.75 8h4v.125h-4V8Zm5.5 0h4v.125h-4V8Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M14.5 14.25v.125c.981 0 1.75-1.25 1.75-2.5v-.125c0 1.25-.769 2.5-1.75 2.5Zm0-2.25h-2.25v.125h2.25V12ZM9 14.25l.119.119c.924-.095 1.631-1.295 1.631-2.494v-.125c0 1.25-.768 2.5-1.75 2.5ZM9 12H6.75v.125H9V12Z",
          fill: "#263238"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.5 1.125c5.246 0 9.5 4.253 9.5 9.5h.004l.003-.125H21a9.5 9.5 0 0 0-19 0l.001.059c.036-5.217 4.274-9.434 9.499-9.434Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.5 19.5v-.125c-4.951 0-9.463-3.998-9.499-8.941L2 10.5c0 4.97 4.53 9 9.5 9Zm9.5-9c-.064 4.816-3.778 9.473-9.5 12.374V23c5.772-2.926 9.5-7.638 9.5-12.5Z",
          fill: "#263238"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ l(
        "linearGradient",
        {
          id: "google-hangouts_svg__a",
          x1: 11.617,
          y1: 10.117,
          x2: 18.128,
          y2: 16.628,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ t("stop", { stopColor: "#263238" }),
            /* @__PURE__ */ t("stop", { offset: 1, stopOpacity: 0 })
          ]
        }
      ) })
    ]
  }
);
export {
  h as default
};
