import * as e from "react";
const t = (l) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 32",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "icon-file-broken-file_svg__a",
      x1: "0%",
      y1: "0%",
      y2: "100%"
    },
    /* @__PURE__ */ e.createElement("stop", { stopOpacity: 0.2, offset: "0%" }),
    /* @__PURE__ */ e.createElement("stop", { stopOpacity: 0, offset: "100%" })
  )),
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#EC4B2F",
      d: "M3 0h12l9 9v20a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#FFF",
      fillOpacity: 0.4,
      d: "M24 9h-6a3 3 0 0 1-3-3V0l9 9Z",
      style: {
        mixBlendMode: "lighten"
      }
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "url(#icon-file-broken-file_svg__a)",
      d: "M24 9h-6a2.991 2.991 0 0 1-2.159-.917c-.347-.36 2.373 2.28 8.159 7.917V9Z",
      style: {
        mixBlendMode: "hard-light"
      }
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#FFF",
      d: "m5.586 16-1.414-1.414 1.414-1.414L7 14.586l1.414-1.414 1.414 1.414L8.414 16l1.414 1.414-1.414 1.414L7 17.414l-1.414 1.414-1.414-1.414L5.586 16ZM9 24h6v2H9v-2Zm6.586-8-1.414-1.414 1.414-1.414L17 14.586l1.414-1.414 1.414 1.414L18.414 16l1.414 1.414-1.414 1.414L17 17.414l-1.414 1.414-1.414-1.414L15.586 16Z"
    }
  ))
);
export {
  t as default
};
