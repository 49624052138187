import { jsxs as h, jsx as t } from "react/jsx-runtime";
const i = (l) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M1 3.75H.75v17.1h22.5V3.75H1Z",
          stroke: "#000",
          strokeOpacity: 0.2,
          strokeWidth: 0.5
        }
      ),
      /* @__PURE__ */ t("path", { d: "M1 4h22v16.6H1V4Z", fill: "#DE2910" }),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M3.64 10.142 5.125 5.66l1.485 4.482-3.96-2.739H7.6l-3.96 2.739ZM10.08 5.746l-1.527.34 1.019-1.194-.1 1.608-.848-1.424 1.457.67ZM11.623 7.712l-1.54-.282 1.4-.704-.712 1.444-.232-1.644 1.084 1.186ZM11.398 10.471l-1.291-.89 1.564-.067-1.236 1.026.454-1.596.51 1.527ZM9.456 12.274l-.85-1.323 1.469.545-1.534.466 1.031-1.296-.116 1.608Z",
          fill: "#FFDE00"
        }
      )
    ]
  }
);
export {
  i as default
};
