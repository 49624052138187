import { jsx as h } from "react/jsx-runtime";
const v = (A) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ h("path", { d: "M4 17a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 16V8c0-.283.096-.521.288-.713A.967.967 0 0 1 4 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 5 8v3h4V8c0-.283.096-.521.288-.713A.967.967 0 0 1 10 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 11 8v8c0 .283-.096.52-.287.712A.968.968 0 0 1 10 17a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 16v-3H5v3c0 .283-.096.52-.287.712A.968.968 0 0 1 4 17Zm11 0c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 13 15V9c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 15 7h5c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 9h-5v2h4c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v2c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 17h-4Zm0-4v2h4v-2h-4Z" })
  }
);
export {
  v as default
};
