import { jsxs as e, jsx as a } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M11.363 15.363a1.871 1.871 0 0 0 1.275 0 2.36 2.36 0 0 0 .587-.313l6.75-5.25c.133-.1.233-.22.3-.362a1.02 1.02 0 0 0 0-.876.963.963 0 0 0-.3-.362l-6.75-5.25a2.36 2.36 0 0 0-.587-.313 1.88 1.88 0 0 0-1.275 0c-.209.076-.405.18-.588.313L4.025 8.2a.963.963 0 0 0-.3.362 1.02 1.02 0 0 0 0 .876.963.963 0 0 0 .3.362l6.75 5.25c.183.134.38.238.588.313ZM17.75 9 12 13.45 6.25 9 12 4.55 17.75 9Z"
        }
      ),
      /* @__PURE__ */ a("path", { d: "M19.003 13.995a1 1 0 0 0-1 1v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2a1 1 0 0 0-1-1ZM13.003 17.745l-1 .79-6.769-5.256a.995.995 0 0 0-1.221 1.572l6.75 5.25a2 2 0 0 0 2.47-.011l.12-.095a5.68 5.68 0 0 1-.35-2v-.25Z" })
    ]
  }
);
export {
  t as default
};
