import { jsx as h } from "react/jsx-runtime";
const Z = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V9c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 7h2V5c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 9 3h6c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v6h2c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v6c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21h-6v-4h-2v4H5Zm0-2h2v-2H5v2Zm0-4h2v-2H5v2Zm0-4h2V9H5v2Zm4 4h2v-2H9v2Zm0-4h2V9H9v2Zm0-4h2V5H9v2Zm4 8h2v-2h-2v2Zm0-4h2V9h-2v2Zm0-4h2V5h-2v2Zm4 12h2v-2h-2v2Zm0-4h2v-2h-2v2Z" })
  }
);
export {
  Z as default
};
