import * as React from "react";
const SvgIconDragHandleVertical = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M8.5 16a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm6 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm0-6a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm0-6a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm-6 6a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm0-6a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconDragHandleVertical;
