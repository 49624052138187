import { jsx as c } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M19.1 21.9 17.15 20H6.5c-1.533 0-2.833-.534-3.9-1.6C1.533 17.333 1 16.033 1 14.5c0-1.284.396-2.425 1.188-3.425A5.284 5.284 0 0 1 5.25 9.15c.05-.134.1-.263.15-.388.05-.125.1-.262.15-.412L2.1 4.9a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l17 17a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.994.994 0 0 1-.7.288.872.872 0 0 1-.7-.288Zm2.5-3.15L8.05 5.225a7.038 7.038 0 0 1 1.863-.913A6.884 6.884 0 0 1 12 4c1.95 0 3.604.679 4.962 2.037C18.321 7.395 19 9.05 19 11c1.15.133 2.104.629 2.863 1.487A4.41 4.41 0 0 1 23 15.5a4.38 4.38 0 0 1-1.4 3.25Z" })
  }
);
export {
  o as default
};
