/* eslint-disable radix */
import React, { useState, useCallback } from "react";
import styled from "styled-components";
import moment from "moment";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Dropdown from "./Dropdown";
import { Input } from "./Input";
import messages from "./messages";
import DatePicker from "./DatePicker";

const TIME_REGEX = /^\d{4}$/g;
const DEFAULT_INPUT_STATE = "default";

const DateTimePicker = ({ label, value, onChange, inputMode }) => {
  const intl = useIntl();
  const [selectedDate, setSelectedDate] = useState(value);
  const [selectedTime, setSelectedTime] = useState(value);
  const [selectedMode, setSelectedMode] = useState(inputMode);
  const [timeInputState, setTimeInputState] = useState(DEFAULT_INPUT_STATE);
  const [timeInputErrorMessage, setTimeInputErrorMessage] = useState();
  const [timeValue, setTimeValue] = useState("");

  const validateTime = useCallback(
    ({ time, mode }) => {
      const hour = parseInt(time[0]);
      const minute = parseInt(time[1]);

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(hour) || isNaN(minute)) {
        setTimeInputState("error");
        setTimeInputErrorMessage(intl.formatMessage(messages.notNumber));
        return false;
      }
      const regex = new RegExp(
        mode === "24h"
          ? "([0-1][0-9]{1}|[2][0-3]{1}):([0-5]{1}[0-9]{1})"
          : "([0][1-9]|[1][0-2]{1}):([0-5]{1}[0-9]{1})"
      );
      const timeValue =
        hour < 10 ? `0${hour}:${time[1]}` : `${hour}:${time[1]}`;
      if (!regex.test(timeValue)) {
        setTimeInputState("error");
        setTimeInputErrorMessage(intl.formatMessage(messages.invalidTime));
        return false;
      }

      setTimeInputState(DEFAULT_INPUT_STATE);
      return true;
    },
    [intl]
  );

  const setDateTime = useCallback(
    ({ date, time, mode }) => {
      if (!time || !mode) return;
      if (!validateTime({ time, mode })) return;

      if (!date) return;

      const hour = parseInt(time[0]);
      const minute = parseInt(time[1]);

      const selectedDateTime = moment(date);

      if (mode === "am" && hour === 12) {
        selectedDateTime.hour(0);
      } else if (mode === "pm" && hour === 12) {
        selectedDateTime.hour(12);
      } else if (mode === "pm") {
        selectedDateTime.hour(hour + 12);
      } else {
        selectedDateTime.hour(hour);
      }

      selectedDateTime.minute(minute);

      if (onChange) onChange(selectedDateTime.toDate());
    },
    [onChange, validateTime]
  );

  const handleDateSelected = useCallback(
    (date) => {
      setSelectedDate(date);
      setDateTime({ date, time: selectedTime, mode: selectedMode });
    },
    [selectedMode, selectedTime, setDateTime]
  );

  const handleOnKeyPress = useCallback((event) => {
    if (event.charCode < 48 || event.charCode > 58) {
      event.preventDefault();
    }
  }, []);

  const handleTimeSelected = useCallback(
    (time) => {
      if (time.length !== 2) {
        setTimeInputState("error");
        setTimeInputErrorMessage(intl.formatMessage(messages.wrongFormat));
        return;
      }

      setSelectedTime(time);
      setDateTime({ date: selectedDate, time, mode: selectedMode });
    },
    [intl, selectedDate, selectedMode, setDateTime]
  );

  const onChangeMode = useCallback(
    ({ value }) => {
      setSelectedMode(value);
      setDateTime({ date: selectedDate, time: selectedTime, mode: value });
    },
    [selectedDate, selectedTime, setDateTime]
  );

  const handleTimeSelectedDebounced = debounce(handleTimeSelected, 500);

  const onChangeTime = useCallback(
    (event) => {
      const timeString = event.target.value;
      let newTime = timeString;
      setTimeInputState(DEFAULT_INPUT_STATE);

      if (timeString.match(TIME_REGEX)) {
        newTime = `${timeString.slice(0, 2)}:${timeString.slice(2)}`;
      }
      setTimeValue(newTime);

      handleTimeSelectedDebounced(newTime.split(":"));
    },
    [handleTimeSelectedDebounced]
  );

  return (
    <Wrapper>
      <DatePicker
        width="100%"
        label={label || intl.formatMessage(messages.dateTimePickerLabel)}
        value={selectedDate}
        onDayChange={handleDateSelected}
      />
      <Input
        placeholder={intl.formatMessage(messages.timeInputPlaceholder)}
        label={intl.formatMessage(messages.timeInputLabel)}
        onChange={onChangeTime}
        state={timeInputState}
        errorMessage={timeInputErrorMessage}
        value={timeValue}
        onKeyPress={handleOnKeyPress}
      />
      <div style={{ paddingTop: "28px" }}>
        <Dropdown
          options={[
            { value: "am", label: intl.formatMessage(messages.am) },
            { value: "pm", label: intl.formatMessage(messages.pm) },
            {
              value: "24h",
              label: intl.formatMessage(messages.twentyFourHour),
            },
          ]}
          defaultValue={{ value: "am", label: intl.formatMessage(messages.am) }}
          onChange={onChangeMode}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 180px 90px 90px;
  column-gap: 8px;
`;

DateTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  inputMode: PropTypes.oneOf(["am", "pm", "24h"]),
};

DateTimePicker.defaultProps = {
  inputMode: "am",
  label: undefined,
};

export default DateTimePicker;
