import styled from "styled-components";

const BaseLine = styled.div`
  width: 100%;
  background-color: var(--color-old__gray08__will_become__gray200);
`;

export const Line300 = styled(BaseLine)`
  height: 1px;
`;

export const Line400 = styled(BaseLine)`
  height: 2px;
`;

export const Line600 = styled(BaseLine)`
  height: 4px;
`;
