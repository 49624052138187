import { jsxs as l, jsx as t } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z",
          fill: "#EAB73E"
        }
      ),
      /* @__PURE__ */ t("path", { fill: "#fff", d: "M5 8h14v8H5z" })
    ]
  }
);
export {
  a as default
};
