import * as React from "react";
const SvgIconDrive = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="m5.798 20.68 2.869-4.95H22l-2.79 4.95H5.799ZM8.63 3.59l2.855 4.926-6.702 11.566L2 15.142 8.63 3.59ZM9.65 3h5.713l6.629 11.55h-5.648L9.65 3Z" />
  </svg>
);
export default SvgIconDrive;
