import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M6.294 8.116a.998.998 0 0 1 1.411-1.41l4.588 4.588a1 1 0 0 1 0 1.414l-4.588 4.588a.998.998 0 0 1-1.411-1.41L10.17 12 6.294 8.116Zm6 0a.997.997 0 0 1 1.411-1.41l4.588 4.588a1 1 0 0 1 0 1.414l-4.588 4.588a.998.998 0 0 1-1.411-1.41L16.17 12l-3.876-3.885Z" })
  }
);
export {
  h as default
};
