import { jsx as c } from "react/jsx-runtime";
const a = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M12 11c-2.5 0-4.625-.392-6.375-1.175S3 8.1 3 7s.875-2.042 2.625-2.825C7.375 3.392 9.5 3 12 3s4.625.392 6.375 1.175S21 5.9 21 7s-.875 2.042-2.625 2.825C16.625 10.608 14.5 11 12 11Zm0 5c-2.5 0-4.625-.392-6.375-1.175S3 13.1 3 12V9.5c0 .733.342 1.354 1.025 1.863.683.508 1.5.92 2.45 1.237.95.317 1.938.546 2.963.687 1.025.142 1.879.213 2.562.213.683 0 1.537-.071 2.562-.213a16.448 16.448 0 0 0 2.963-.687c.95-.317 1.767-.729 2.45-1.237C20.658 10.854 21 10.233 21 9.5V12c0 1.1-.875 2.042-2.625 2.825C16.625 15.608 14.5 16 12 16Zm0 5c-2.5 0-4.625-.392-6.375-1.175S3 18.1 3 17v-2.5c0 .733.342 1.354 1.025 1.863.683.508 1.5.92 2.45 1.237.95.317 1.938.546 2.963.688 1.025.141 1.879.212 2.562.212.683 0 1.537-.07 2.562-.212a16.51 16.51 0 0 0 2.963-.688c.95-.317 1.767-.729 2.45-1.237.683-.509 1.025-1.13 1.025-1.863V17c0 1.1-.875 2.042-2.625 2.825C16.625 20.608 14.5 21 12 21Z" })
  }
);
export {
  a as default
};
