import { useRef, forwardRef, useImperativeHandle } from "react";
import { IconUpload } from "@happeouikit/icons";
import { IconButton, ButtonTertiary } from "@happeouikit/buttons";
import styled from "styled-components";
import PropTypes from "prop-types";

const FilePicker = forwardRef(
  ({ onSelected = () => {}, disabled, text, ariaLabel }, ref) => {
    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
      clear: () => {
        inputRef.current.value = "";
      },
    }));

    const onClick = () => {
      inputRef.current && inputRef.current.click();
    };

    const handleChangeInput = (e) => {
      const files = e.target.files;
      if (files) {
        onSelected && onSelected(files);
      }
    };

    const getButton = () => {
      if (text)
        return (
          <ButtonTertiary
            aria-label={ariaLabel || text}
            tabIndex="0"
            text={text}
            icon={IconUpload}
            onClick={onClick}
            disabled={disabled}
          />
        );
      return (
        <IconButton
          aria-label={ariaLabel}
          icon={IconUpload}
          onClick={onClick}
          disabled={disabled}
          tabIndex="0"
        />
      );
    };

    return (
      <StyledIconButton>
        {getButton()}
        <input
          type="file"
          id="file"
          multiple
          onChange={handleChangeInput}
          ref={inputRef}
        />
      </StyledIconButton>
    );
  }
);

FilePicker.propTypes = {
  onSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

const StyledIconButton = styled.div`
  input[type="file"] {
    display: none;
  }
`;

export default FilePicker;
