/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftOnenoteAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 3h15A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3Z"
      fill="url(#microsoft-onenote-alt_svg__a)"
    />
    <path
      d="M7.93 7.125h2.034l3.995 6.464c.181.29.318.523.409.7h.026a9.83 9.83 0 0 1-.059-1.326V7.125h1.735v9.75h-1.903l-4.145-6.643a5.165 5.165 0 0 1-.37-.687h-.033c.042.484.06.97.052 1.455v5.875H7.93v-9.75Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="microsoft-onenote-alt_svg__a"
        x1={6.127}
        y1={1.828}
        x2={17.873}
        y2={22.172}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8324B3" />
        <stop offset={0.5} stopColor="#7A1BAC" />
        <stop offset={1} stopColor="#621197" />
      </linearGradient>
    </defs>
  </svg>
);

export default MicrosoftOnenoteAlt;
