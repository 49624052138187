import * as e from "react";
const t = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M2 6a1 1 0 0 1 1-1h17a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm4 3a1 1 0 0 1 1 1v1h13a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm4 6a1 1 0 0 1 1 1v1h9a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Z"
    }
  )
);
export {
  t as default
};
