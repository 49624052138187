/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleForms = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm4 6H7V7h2v2Zm8 0h-6V7h6v2Zm-8 4H7v-2h2v2Zm0 4H7v-2h2v2Zm8-4h-6v-2h6v2Zm0 4h-6v-2h6v2Z"
    />
  </svg>
);

export default GoogleForms;
