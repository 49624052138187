import { jsxs as f, jsx as h } from "react/jsx-runtime";
const l = (t) => /* @__PURE__ */ f(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ h("rect", { x: 3, y: 3, width: 18, height: 18, rx: 2, fill: "#EA8237" }),
      /* @__PURE__ */ h("path", { d: "M13 5h4v4h-4V5ZM13 10h4v4h-4v-4Z", fill: "#fff" }),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M15.326 17.222A4 4 0 0 0 16 15h-4v-4a4 4 0 1 0 3.326 6.222Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  l as default
};
