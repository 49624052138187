/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Dropbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 3.506 2 6.69l5 3.186 5-3.186-5-3.185ZM17 3.506 12 6.69l5 3.186 5-3.186-5-3.185ZM2 13.062l5 3.185 5-3.185-5-3.185-5 3.185ZM17 9.877l-5 3.185 5 3.185 5-3.185-5-3.185ZM7 17.309l5 3.185 5-3.185-5-3.185-5 3.185Z"
      fill="#0061FF"
    />
  </svg>
);

export default Dropbox;
