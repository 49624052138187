import * as React from "react";
const SvgIconFormatColorReset = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.77 3.892c1.63 1.99 5.23 6.78 5.23 9.87 0 .28-.03.56-.07.84l-8.44-8.44c.65-.91 1.27-1.7 1.73-2.27a1 1 0 0 1 1.55 0Zm-6.65 1.71 13.17 13.17c.39.39.39 1.03-.01 1.41a.996.996 0 0 1-1.41 0l-1.92-1.92c-1.05.93-2.43 1.5-3.95 1.5-3.31 0-6-2.69-6-6 0-1.21.55-2.67 1.32-4.14l-2.61-2.61a.996.996 0 1 1 1.41-1.41Z"
    />
  </svg>
);
export default SvgIconFormatColorReset;
