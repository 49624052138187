/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftPublisherAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.809 11.969h-1.613V8.627h1.67c1.232 0 1.847.544 1.847 1.63.033.475-.143.94-.48 1.275-.402.318-.91.475-1.424.437Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15c0 .83.67 1.5 1.5 1.5h15c.83 0 1.5-.67 1.5-1.5v-15c0-.83-.67-1.5-1.5-1.5Zm-4.726 4.894a3.907 3.907 0 0 0-2.632-.792H8.328v9.856h1.87v-3.484h1.825a4.217 4.217 0 0 0 1.93-.415c.53-.255.977-.66 1.282-1.165a3.26 3.26 0 0 0 .451-1.712 2.853 2.853 0 0 0-.912-2.288Z"
    />
  </svg>
);

export default MicrosoftPublisherAlt;
