import { jsx as a } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M5 22c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 20V6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 5 4h1V3a.97.97 0 0 1 .287-.713A.97.97 0 0 1 7 2a.97.97 0 0 1 .713.287A.97.97 0 0 1 8 3v1h8V3c0-.283.096-.521.288-.713A.967.967 0 0 1 17 2c.283 0 .52.096.712.287.192.192.288.43.288.713v1h1c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v6h-2v-2H5v10h7v2H5Zm17.125-5L20 14.875l.725-.725a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l.725.725a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-.725.725ZM14 22.5v-1.2a1.03 1.03 0 0 1 .3-.725l5-5 2.125 2.125-5 5a1 1 0 0 1-.725.3h-1.2a.48.48 0 0 1-.35-.15.48.48 0 0 1-.15-.35Z" })
  }
);
export {
  v as default
};
