import { jsxs as c, jsx as a } from "react/jsx-runtime";
const t = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: [
      /* @__PURE__ */ a("path", { d: "M3.587 20.413C3.98 20.803 4.45 21 5 21h14c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413v-8a.968.968 0 0 0-.288-.713A.968.968 0 0 0 20 10a.968.968 0 0 0-.712.287A.968.968 0 0 0 19 11v8H5V5h8c.283 0 .52-.096.713-.287A.967.967 0 0 0 14 4a.967.967 0 0 0-.287-.712A.968.968 0 0 0 13 3H5c-.55 0-1.02.196-1.413.587A1.926 1.926 0 0 0 3 5v14c0 .55.196 1.02.587 1.413Z" }),
      /* @__PURE__ */ a("path", { d: "M16 7h1v1c0 .283.096.52.288.713.191.191.429.287.712.287s.52-.096.712-.287A.967.967 0 0 0 19 8V7h1c.283 0 .52-.096.712-.287A.967.967 0 0 0 21 6a.967.967 0 0 0-.288-.713A.968.968 0 0 0 20 5h-1V4a.967.967 0 0 0-.288-.712A.968.968 0 0 0 18 3a.968.968 0 0 0-.712.288A.967.967 0 0 0 17 4v1h-1a.968.968 0 0 0-.713.287A.967.967 0 0 0 15 6c0 .283.096.52.287.713.192.191.43.287.713.287ZM7.588 10.412C7.979 10.804 8.45 11 9 11c.55 0 1.021-.196 1.413-.588C10.804 10.021 11 9.55 11 9c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 9 7c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 7 9c0 .55.196 1.02.588 1.412ZM7.207 17a.5.5 0 0 1-.353-.854l2.795-2.795a.5.5 0 0 1 .705-.002l1.446 1.426 1.81-2.283a.5.5 0 0 1 .782-.002l2.958 3.698a.5.5 0 0 1-.39.812H7.207Z" })
    ]
  }
);
export {
  t as default
};
