import { jsxs as t, jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ c("path", { d: "m16.69 14.97 2.54.29c1.01.12 1.77.97 1.76 1.98v1.73c0 1.13-.94 2.07-2.07 2-8.53-.53-15.36-7.35-15.89-15.89-.07-1.13.87-2.07 2-2.07h1.73c1.02 0 1.87.76 1.99 1.77l.29 2.52c.07.61-.14 1.21-.57 1.64l-1.85 1.85c1.44 2.84 3.76 5.15 6.59 6.59l1.84-1.84a1.99 1.99 0 0 1 1.64-.57ZM19.288 11.712c.208.192.445.288.712.288.283 0 .52-.1.712-.3a.82.82 0 0 0 .238-.7c-.233-2.1-1.092-3.891-2.575-5.375C16.892 4.142 15.1 3.284 13 3.05a.821.821 0 0 0-.7.237c-.2.192-.3.43-.3.713 0 .284.096.525.288.725.191.2.429.317.712.35 1.55.217 2.871.871 3.963 1.962C18.054 8.13 18.708 9.45 18.925 11c.033.284.154.521.363.712Z" }),
      /* @__PURE__ */ c("path", { d: "M15.25 11.775c.167.15.358.225.575.225.333 0 .608-.116.825-.35.217-.233.292-.491.225-.775a4.924 4.924 0 0 0-1.337-2.413 4.924 4.924 0 0 0-2.413-1.337c-.283-.066-.542.004-.775.212a1.04 1.04 0 0 0-.35.813c0 .234.075.434.225.6.15.167.35.292.6.375a2.89 2.89 0 0 1 1.288.762c.375.376.629.805.762 1.288.083.25.208.45.375.6Z" })
    ]
  }
);
export {
  s as default
};
