import { jsxs as l, jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          d: "M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z",
          fill: "#3870E0"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M12 16.95a3.371 3.371 0 0 1-2.475 1.026c-.965 0-1.79-.341-2.475-1.026a3.374 3.374 0 0 1-1.027-2.476c0-.965.343-1.79 1.027-2.474l1.485-1.485a.68.68 0 0 1 .495-.21c.19 0 .355.07.495.21.14.14.21.305.21.495s-.07.355-.21.495L8.04 12.99a2.025 2.025 0 0 0-.618 1.485c0 .577.206 1.072.618 1.485.413.412.908.618 1.485.618.578 0 1.073-.206 1.485-.618l1.485-1.485a.68.68 0 0 1 .495-.211c.19 0 .355.07.495.21.14.141.21.306.21.495 0 .19-.07.356-.21.496L12 16.95Zm-.99-2.97a.676.676 0 0 1-.495.21.676.676 0 0 1-.495-.21.678.678 0 0 1-.21-.496c0-.189.07-.354.21-.494l2.97-2.97c.14-.14.305-.21.495-.21s.355.07.495.21c.14.14.21.305.21.495s-.07.355-.21.495l-2.97 2.97Zm4.455-.495a.675.675 0 0 1-.495.21.675.675 0 0 1-.495-.21.678.678 0 0 1-.21-.495c0-.19.07-.355.21-.495l1.485-1.485c.412-.412.618-.907.618-1.485 0-.577-.206-1.072-.618-1.485a2.025 2.025 0 0 0-1.485-.618c-.578 0-1.073.206-1.485.618l-1.485 1.485a.675.675 0 0 1-.495.21.675.675 0 0 1-.495-.21.678.678 0 0 1-.21-.495c0-.19.07-.355.21-.495L12 7.05a3.375 3.375 0 0 1 2.475-1.027c.965 0 1.79.343 2.475 1.027a3.373 3.373 0 0 1 1.027 2.475c0 .965-.343 1.79-1.027 2.475l-1.485 1.485Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  t as default
};
