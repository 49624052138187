import * as React from "react";
const SvgIconFileSoundFile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 28"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={28} fill="#2AC4AF" rx={2} />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M20.394 14c0-1.671-.893-3.111-2.222-3.857v7.714A4.42 4.42 0 0 0 20.394 14ZM7 11.429v5.142h3.724l4.966 5.143V6.286l-4.966 5.143H7ZM18.172 5v1.929c2.88.951 4.966 3.767 4.966 7.071s-2.086 6.12-4.966 7.071V23C22.082 21.997 25 18.371 25 14s-2.917-7.997-6.828-9Z"
      />
    </g>
  </svg>
);
export default SvgIconFileSoundFile;
