import { jsx as a } from "react/jsx-runtime";
const i = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M15 20a1 1 0 0 0 1-1V4H8a1 1 0 0 0-1 1v10a1 1 0 1 1-2 0V5a3 3 0 0 1 3-3h11a3 3 0 0 1 3 3 1 1 0 1 1-2 0 1 1 0 0 0-2 0v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-1h11a2 2 0 0 0 2 2Z" })
  }
);
export {
  i as default
};
