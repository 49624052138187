import * as React from "react";
const SvgIconCommute = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.995 4h7c1.66 0 3 1.34 3 3v1h-2V6.5c0-.28-.22-.5-.5-.5h-8c-.28 0-.5.22-.5.5v6c0 .28.22.5.5.5h4.5v5h-2l-1.77 1.77a.7.7 0 0 1-1 0 .7.7 0 0 1 0-1l.77-.77c-1.66 0-3-1.34-3-3V7c0-1.66 1.34-3 3-3Zm1 11c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1Zm14.57-5.34c-.14-.4-.52-.66-.97-.66h-7.19c-.46 0-.83.26-.98.66l-1.42 4.11v5.24c0 .55.45.99 1 .99s1-.45 1-1v-1h8v1a1 1 0 0 0 2 .01l-.01-5.24-1.43-4.11Zm-1.32.34h-6.48c-.22 0-.41.14-.46.34l-.69 2a.5.5 0 0 0 .47.66h7.85a.5.5 0 0 0 .47-.66l-.69-2a.505.505 0 0 0-.47-.34Zm-7.25 6c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm7-1c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1Z"
    />
  </svg>
);
export default SvgIconCommute;
