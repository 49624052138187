import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18 9.5c0 .825.675 1.5 1.5 1.5s1.5-.675 1.5-1.5S20.325 8 19.5 8 18 8.675 18 9.5ZM14.5 13c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5Zm6.5 1.5c0-.825-.675-1.5-1.5-1.5s-1.5.675-1.5 1.5.675 1.5 1.5 1.5 1.5-.675 1.5-1.5ZM19.5 21c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5ZM16 19.5c0-.825-.675-1.5-1.5-1.5s-1.5.675-1.5 1.5.675 1.5 1.5 1.5 1.5-.675 1.5-1.5ZM9.5 18c.825 0 1.5.675 1.5 1.5S10.325 21 9.5 21 8 20.325 8 19.5 8.675 18 9.5 18Z"
    }
  )
);
export {
  c as default
};
