import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M3 9.575V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v6.575l-2.3-2.3a.883.883 0 0 0-.325-.213A1.115 1.115 0 0 0 18 9c-.133 0-.258.02-.375.062a.883.883 0 0 0-.325.213l-3.3 3.3-3.3-3.3a.883.883 0 0 0-.325-.213A1.115 1.115 0 0 0 10 9c-.133 0-.258.02-.375.062a.883.883 0 0 0-.325.213l-3.3 3.3-3-3ZM5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19v-6.6l2.3 2.3c.1.1.208.17.325.212.117.042.242.063.375.063s.258-.021.375-.063A.871.871 0 0 0 6.7 14.7l3.3-3.3 3.3 3.3c.1.1.208.17.325.212a1.1 1.1 0 0 0 .375.063 1.1 1.1 0 0 0 .375-.063.871.871 0 0 0 .325-.212l3.3-3.3 3 3V19c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Z" })
  }
);
export {
  t as default
};
