import { jsx as s } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ s("path", { d: "M7 18c-1.1 0-2.042-.392-2.825-1.175C3.392 16.042 3 15.1 3 14s.392-2.042 1.175-2.825C4.958 10.392 5.9 10 7 10s2.042.392 2.825 1.175C10.608 11.958 11 12.9 11 14s-.392 2.042-1.175 2.825C9.042 17.608 8.1 18 7 18Zm9.5-4c-1.533 0-2.833-.533-3.9-1.6-1.067-1.067-1.6-2.367-1.6-3.9s.533-2.833 1.6-3.9C13.667 3.533 14.967 3 16.5 3s2.833.533 3.9 1.6C21.467 5.667 22 6.967 22 8.5s-.533 2.833-1.6 3.9c-1.067 1.067-2.367 1.6-3.9 1.6Zm-2 7a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 11.5 18c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 14.5 15c.833 0 1.542.292 2.125.875S17.5 17.167 17.5 18s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 14.5 21Z" })
  }
);
export {
  h as default
};
