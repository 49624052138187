/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleFirebase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.008 18.137 7.47 2.385a.455.455 0 0 1 .85-.145l2.546 4.749 1.014-1.932a.455.455 0 0 1 .805 0l6.87 13.08H5.008Z" />
    <path d="m13.512 12-2.648-5.014-5.856 11.15 8.504-6.135Z" fill="#2B313B" />
    <path
      d="M19.555 18.137 17.67 6.469a.455.455 0 0 0-.77-.245L5.008 18.137l6.58 3.69c.414.23.917.23 1.33 0l6.637-3.69Z"
      fill="#68778D"
    />
  </svg>
);

export default GoogleFirebase;
