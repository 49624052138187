import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M15.35 20.3a.888.888 0 0 1-.925-.125c-.283-.216-.425-.516-.425-.9 0-.183.054-.345.163-.487a.953.953 0 0 1 .412-.313 6.975 6.975 0 0 0 3.213-2.55C18.596 14.76 19 13.442 19 11.975c0-1.466-.404-2.783-1.212-3.95a6.976 6.976 0 0 0-3.213-2.55.838.838 0 0 1-.425-.325.882.882 0 0 1-.15-.5c0-.366.142-.658.425-.875a.888.888 0 0 1 .925-.125 8.904 8.904 0 0 1 4.1 3.275c1.033 1.5 1.55 3.184 1.55 5.05 0 1.867-.517 3.55-1.55 5.05a8.904 8.904 0 0 1-4.1 3.275ZM4 15a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 14v-4c0-.283.096-.52.288-.713A.967.967 0 0 1 4 9h3l3.3-3.3c.317-.316.679-.387 1.087-.213.409.176.613.488.613.938v11.15c0 .45-.204.763-.613.937-.408.176-.77.105-1.087-.212L7 15H4Zm10 1V7.95a4.2 4.2 0 0 1 1.812 1.625c.459.734.688 1.542.688 2.425 0 .884-.23 1.684-.688 2.4A4.248 4.248 0 0 1 14 16Zm-4-7.15L7.85 11H5v2h2.85L10 15.15v-6.3Z" })
  }
);
export {
  t as default
};
