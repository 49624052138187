import { jsx as c } from "react/jsx-runtime";
const A = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M11 18a.965.965 0 0 1-.712-.288A.965.965 0 0 1 10 17c0-.283.096-.52.288-.712A.965.965 0 0 1 11 16h2c.283 0 .521.096.713.288A.967.967 0 0 1 14 17c0 .283-.096.52-.287.712A.968.968 0 0 1 13 18h-2ZM9 8a.967.967 0 0 1-.712-.287A.968.968 0 0 1 8 7c0-.283.096-.521.288-.713A.967.967 0 0 1 9 6h11c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 20 8H9Zm-2 5a.968.968 0 0 1-.713-.288A.967.967 0 0 1 6 12a.97.97 0 0 1 .287-.713A.97.97 0 0 1 7 11h10c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 17 13H7ZM5.136 8.875 4 7.986l-1.136.89c-.194.161-.396.165-.608.012a.515.515 0 0 1-.21-.606L2.467 6.8 1.2 5.776c-.194-.144-.247-.341-.159-.593.088-.251.256-.377.502-.377H3.05l.45-1.429C3.585 3.126 3.752 3 4 3s.414.126.502.377l.449 1.429h1.506c.246 0 .414.126.502.377.088.252.035.45-.159.593L5.532 6.8l.423 1.482c.07.252 0 .454-.211.606-.212.153-.414.15-.608-.013Z" })
  }
);
export {
  A as default
};
