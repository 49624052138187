import { jsx as c } from "react/jsx-runtime";
const s = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M12 6c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12 2c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 6Zm6 16a.965.965 0 0 1-.712-.288A.965.965 0 0 1 17 21v-4h-5c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 15V9c0-.583.187-1.063.562-1.438C10.937 7.187 11.417 7 12 7c.4 0 .746.087 1.038.262.291.175.604.455.937.838.767.933 1.467 1.608 2.1 2.025a5.17 5.17 0 0 0 2.05.8c.25.033.458.142.625.325.167.183.25.4.25.65 0 .317-.108.575-.325.775a.839.839 0 0 1-.75.225 8.12 8.12 0 0 1-2.113-.712A9.79 9.79 0 0 1 14 11.05v3.45h3c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413V21c0 .283-.096.52-.288.712A.965.965 0 0 1 18 22Zm-8 0c-1.383 0-2.562-.487-3.537-1.462C5.488 19.563 5 18.383 5 17c0-1.2.38-2.258 1.138-3.175C6.896 12.908 7.85 12.333 9 12.1v2.05a3.261 3.261 0 0 0-1.438 1.112A2.855 2.855 0 0 0 7 17c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 10 20c.65 0 1.23-.187 1.738-.562A3.261 3.261 0 0 0 12.85 18h2.05c-.233 1.15-.808 2.104-1.725 2.863A4.837 4.837 0 0 1 10 22Z" })
  }
);
export {
  s as default
};
