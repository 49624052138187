import { jsx as c } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M7 18c-1.667 0-3.083-.583-4.25-1.75C1.583 15.083 1 13.667 1 12c0-1.667.583-3.083 1.75-4.25C3.917 6.583 5.333 6 7 6h10c1.667 0 3.083.583 4.25 1.75C22.417 8.917 23 10.333 23 12c0 1.667-.583 3.083-1.75 4.25C20.083 17.417 18.667 18 17 18H7Zm0-2h10c1.1 0 2.042-.392 2.825-1.175C20.608 14.042 21 13.1 21 12s-.392-2.042-1.175-2.825C19.042 8.392 18.1 8 17 8H7c-1.1 0-2.042.392-2.825 1.175C3.392 9.958 3 10.9 3 12s.392 2.042 1.175 2.825C4.958 15.608 5.9 16 7 16Zm10-1c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 20 12c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 17 9c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 14 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 17 15Z" })
  }
);
export {
  s as default
};
