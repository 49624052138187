import { jsx as c } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M13 8.376c0-.43.238-.818.606-.987l5-2.301c.66-.304 1.394.216 1.394.987v10.549c0 .43-.238.818-.606.987l-5 2.301c-.66.304-1.394-.216-1.394-.987V8.376ZM11 8.376c0-.43-.238-.818-.606-.987l-5-2.301C4.734 4.784 4 5.304 4 6.075v10.549c0 .43.238.818.606.987l5 2.301c.66.304 1.394-.216 1.394-.987V8.376Z" })
  }
);
export {
  s as default
};
