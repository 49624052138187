import { jsxs as h, jsx as a } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ a("path", { d: "M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14Zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14Z" }),
      /* @__PURE__ */ a("path", { d: "M7.275 10.213a.926.926 0 0 0 .7.288H11a.968.968 0 0 0 .713-.288A.967.967 0 0 0 12 9.5a.993.993 0 0 0-.275-.713.927.927 0 0 0-.7-.287H8a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 9.5c0 .284.092.521.275.713Z" })
    ]
  }
);
export {
  c as default
};
