import { jsx as l } from "react/jsx-runtime";
const o = (a) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ l("path", { d: "M8.115 17.706a.997.997 0 0 1-1.41-1.412l4.588-4.587a1 1 0 0 1 1.414 0l4.588 4.587a.997.997 0 0 1-1.41 1.412L12 13.83l-3.885 3.876Zm0-6a.997.997 0 0 1-1.41-1.412l4.588-4.587a1 1 0 0 1 1.414 0l4.588 4.587a.997.997 0 0 1-1.41 1.412L12 7.83l-3.885 3.876Z" })
  }
);
export {
  o as default
};
