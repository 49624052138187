import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M12 17a.993.993 0 0 0 .713-.275.927.927 0 0 0 .287-.7V14h2a.968.968 0 0 0 .713-.288A.967.967 0 0 0 16 13a.992.992 0 0 0-.275-.713.927.927 0 0 0-.7-.287H13v-2a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 9a.99.99 0 0 0-.712.275.926.926 0 0 0-.288.7V12H9a.967.967 0 0 0-.712.287A.968.968 0 0 0 8 13c0 .283.092.52.275.712a.926.926 0 0 0 .7.288H11v2c0 .283.096.52.288.712A.965.965 0 0 0 12 17Zm0 5c-1.25 0-2.42-.237-3.512-.712a9.142 9.142 0 0 1-2.85-1.926 9.143 9.143 0 0 1-1.926-2.85A8.709 8.709 0 0 1 3 13c0-1.25.237-2.421.712-3.513a9.159 9.159 0 0 1 1.926-2.85 9.138 9.138 0 0 1 2.85-1.924A8.698 8.698 0 0 1 12 4c1.25 0 2.421.238 3.513.713a9.152 9.152 0 0 1 2.85 1.924 9.167 9.167 0 0 1 1.925 2.85A8.715 8.715 0 0 1 21 13c0 1.25-.237 2.42-.712 3.512a9.151 9.151 0 0 1-1.925 2.85 9.156 9.156 0 0 1-2.85 1.926A8.715 8.715 0 0 1 12 22ZM3.425 7.325a.918.918 0 0 1-.675.275.96.96 0 0 1-.7-.3.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l2.875-2.875A.918.918 0 0 1 5.6 2.75c.267 0 .5.1.7.3a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L3.425 7.325ZM20.55 7.3l-2.875-2.875a.918.918 0 0 1-.275-.675c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.875 2.875a.917.917 0 0 1 .275.675c0 .267-.1.5-.3.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM12 20c1.95 0 3.604-.68 4.962-2.038C18.321 16.604 19 14.95 19 13c0-1.95-.68-3.604-2.038-4.963C15.604 6.679 13.95 6 12 6c-1.95 0-3.604.679-4.962 2.037C5.679 9.396 5 11.05 5 13c0 1.95.68 3.604 2.038 4.962C8.396 19.321 10.05 20 12 20Z" })
  }
);
export {
  t as default
};
