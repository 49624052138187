/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Youtube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.54 6.405a2.755 2.755 0 0 0-1.945-1.945C18.88 4 12 4 12 4s-6.88 0-8.595.46A2.755 2.755 0 0 0 1.46 6.405C1 8.12 1 11.7 1 11.7s0 3.58.46 5.296a2.755 2.755 0 0 0 1.945 1.945C5.12 19.4 12 19.4 12 19.4s6.88 0 8.595-.46a2.755 2.755 0 0 0 1.945-1.945C23 15.28 23 11.7 23 11.7s-.002-3.58-.46-5.295Z"
      fill="red"
    />
    <path d="m9.798 15 5.715-3.3-5.715-3.3V15Z" fill="#fff" />
  </svg>
);

export default Youtube;
