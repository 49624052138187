import * as React from "react";
const SvgIconGroupAdd = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 11.5c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86 0 1.07-.34 2.04-.9 2.86.28.09.59.14.91.14Zm-11-1H5v-2c0-.55-.45-1-1-1s-1 .45-1 1v2H1c-.55 0-1 .45-1 1s.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1v-2h2c.55 0 1-.45 1-1s-.45-1-1-1Zm8.99-2c0 1.66-1.33 3-2.99 3-1.66 0-3-1.34-3-3s1.34-3 3-3 2.99 1.34 2.99 3Zm-2.99 5c-2 0-6 1-6 3v1c0 .55.45 1 1 1h10c.55 0 1-.45 1-1v-1c0-2-4-3-6-3Zm8 3c0-1.18-.55-2.11-1.38-2.84 2.01.35 4.38 1.3 4.38 2.84V18c0 .28-.22.5-.5.5h-2.55c.03-.16.05-.33.05-.5v-1.5Z"
    />
  </svg>
);
export default SvgIconGroupAdd;
