/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftOnedrive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.27 9.092V9.09l4.829 2.892 2.877-1.21a4.656 4.656 0 0 1 2.168-.37A7.188 7.188 0 0 0 6.176 8.235l.073-.002a5.722 5.722 0 0 1 3.02.858Z"
      fill="#38404C"
    />
    <path d="M9.27 9.091a5.722 5.722 0 0 0-3.02-.857l-.073.002a5.748 5.748 0 0 0-4.644 9.035l4.258-1.792 1.893-.796 4.214-1.774 2.201-.926-4.828-2.892Z" />
    <path
      d="M19.144 10.402a4.656 4.656 0 0 0-2.167.37l-2.878 1.211.834.5 2.735 1.638 1.193.715 4.08 2.443a4.672 4.672 0 0 0-3.797-6.877Z"
      fill="#596578"
    />
    <path
      d="m18.861 14.836-1.193-.715-2.735-1.638-.834-.5-2.201.926-4.214 1.774-1.893.796-4.258 1.792a5.743 5.743 0 0 0 4.717 2.463h12.578a4.67 4.67 0 0 0 4.113-2.455l-4.08-2.443Z"
      fill="#68778D"
    />
  </svg>
);

export default MicrosoftOnedrive;
