import React from "react";
import styled from "styled-components";

const Box = styled.div`
  ul {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
`;

const ScrollExampleBox = ({ children }) => {
  return <Box>{children}</Box>;
};

export default ScrollExampleBox;
