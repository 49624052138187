import { jsx as c } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M14.65 12.35a.48.48 0 0 0 0-.7l-2.8-2.8c-.15-.15-.33-.187-.538-.112-.208.075-.312.229-.312.462v5.6c0 .233.104.387.312.462.209.075.388.038.538-.112l2.8-2.8ZM22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.613-1.958-2.138-3.175A9.743 9.743 0 0 1 2 12c0-1.383.262-2.683.787-3.9a10.105 10.105 0 0 1 2.138-3.175c.9-.9 1.958-1.612 3.175-2.137A9.732 9.732 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.091 10.091 0 0 1 3.175 2.137c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12Z" })
  }
);
export {
  e as default
};
