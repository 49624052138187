import * as React from "react";
const SvgIconImageResize = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="m10.293 9 2.992-3H9V5h6v6h-1V6.7l-3 3.007V19H1V9h9.293ZM19 1v18h-2V3H1V1h18Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconImageResize;
