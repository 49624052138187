import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M10.65 2.94 6.93 9.02c-.41.66.07 1.52.85 1.52h7.43c.78 0 1.26-.86.85-1.52l-3.71-6.08a.993.993 0 0 0-1.7 0Zm6.35 9.6a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm-7.5 8.5h-6c-.55 0-1-.45-1-1v-6c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v6c0 .55-.45 1-1 1Z"
    }
  )
);
export {
  c as default
};
