/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Clickup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m4 17.366 3.075-2.356c1.634 2.132 3.37 3.115 5.302 3.115 1.922 0 3.61-.971 5.17-3.087l3.119 2.3C18.415 20.387 15.617 22 12.377 22 9.148 22 6.324 20.398 4 17.366Z"
      fill="url(#clickup_svg__a)"
    />
    <path
      d="m12.367 7.125-5.474 4.717-2.53-2.934L12.379 2l7.952 6.913-2.541 2.924-5.423-4.712Z"
      fill="url(#clickup_svg__b)"
    />
    <defs>
      <linearGradient
        id="clickup_svg__a"
        x1={4}
        y1={19.764}
        x2={20.666}
        y2={19.764}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8930FD" />
        <stop offset={1} stopColor="#49CCF9" />
      </linearGradient>
      <linearGradient
        id="clickup_svg__b"
        x1={4.363}
        y1={8.694}
        x2={20.331}
        y2={8.694}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF02F0" />
        <stop offset={1} stopColor="#FFC800" />
      </linearGradient>
    </defs>
  </svg>
);

export default Clickup;
