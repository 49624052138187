import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Zm0-1.5a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Zm-1.552-8.167L5.712 7.598l1.414-1.415L9.862 8.92l2.736-2.736 1.414 1.415-2.736 2.735 2.736 2.736-1.414 1.414-2.736-2.735-2.736 2.735-1.414-1.414 2.736-2.736Z",
      fillRule: "evenodd"
    }
  )
);
export {
  l as default
};
