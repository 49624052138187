import { jsxs as l, jsx as h } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ h(
        "path",
        {
          d: "m14.5 6.5 3.016.57L20 6.5 14.5 1l-.759 2.607L14.5 6.5Z",
          fill: "#354287"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M14.5 6.5V1h-9A1.5 1.5 0 0 0 4 2.5v19A1.5 1.5 0 0 0 5.5 23h13a1.5 1.5 0 0 0 1.5-1.5v-15h-5.5Z",
          fill: "#4758B5"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M14 16.25H7.5V12H14v4.25Zm2.5 0h-1.25V12h1.25v4.25Zm0-5.5h-9V9.5h9v1.25Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  s as default
};
