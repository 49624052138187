import { basicDesignTokens } from "../DesignTokens/designTokens";

const zIndexAsStrings = basicDesignTokens.zIndex;
type zIndexKey = keyof typeof zIndexAsStrings
const zIndexAsNumbers = Object.keys(zIndexAsStrings).reduce(
  (acc, key) => ({ ...acc, [key]: parseInt(zIndexAsStrings[key as zIndexKey], 10) }),
  {} as { [key in zIndexKey]: number },
);
const { base, navigation, dialogue, below, above } = zIndexAsNumbers;

const drawer = below + navigation;
const modal = dialogue;
const sticky = above + base;
const fab = above + sticky;
const navBackdrop = below + drawer;
const navMenu = above + navigation;
const modalBackdrop = below + modal;
const dropdown = above + modal;
const popover = above + dropdown;
const tooltip = above + popover;

/**
 * We add extra variables that aren't in the export (yet?).
 *
 * These are listed in elevation order from bottom (0) to top (~1403).
 *
 * Note that they're all strings!
 * */
const zIndexNumbers = {
  /** 0 */
  base,
  sticky,
  fab,

  navBackdrop,
  drawer,
  /** 1100 */
  navigation,
  navMenu,

  modalBackdrop,
  /** 1400 */
  modal,
  dropdown,
  popover,
  tooltip,
};
type zIndexKeyExtended = keyof typeof zIndexNumbers
const zIndexStrings = Object.keys(zIndexNumbers).reduce(
  // @ts-ignore typescript doesn't understand how Object.keys() works
  (acc, key) => ({ ...acc, [key]: zIndexNumbers[key].toString() }),
  {} as { [key in zIndexKeyExtended]: string },
);

export const zIndex = {
  ...basicDesignTokens.zIndex,
  ...zIndexStrings,
};
