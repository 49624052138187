import { jsx as s } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ s("path", { d: "M4 22c-.55 0-1.02-.195-1.412-.587A1.927 1.927 0 0 1 2 20V8a1.963 1.963 0 0 1 1.25-1.85l11.825-4.825a.936.936 0 0 1 .688.012.79.79 0 0 1 .462.488.93.93 0 0 1-.013.687.787.787 0 0 1-.487.463L8.3 6H20c.55 0 1.021.196 1.413.588C21.804 6.98 22 7.45 22 8v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 22H4Zm0-11h12v-1c0-.283.096-.52.288-.713A.967.967 0 0 1 17 9c.283 0 .52.096.712.287.192.192.288.43.288.713v1h2V8H4v3Zm4 8c.7 0 1.292-.241 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.291-.725-1.775C9.292 14.242 8.7 14 8 14s-1.292.242-1.775.725C5.742 15.21 5.5 15.8 5.5 16.5s.242 1.292.725 1.775C6.708 18.76 7.3 19 8 19Z" })
  }
);
export {
  h as default
};
