import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M4.257 5.674c0-.83.73-1.5 1.56-1.41 7.35.73 13.19 6.58 13.92 13.92.08.83-.58 1.56-1.42 1.56-.73 0-1.33-.55-1.4-1.28-.6-6.01-5.38-10.79-11.39-11.39-.72-.07-1.27-.67-1.27-1.4Zm1.59 4.3c-.84-.14-1.59.55-1.59 1.4 0 .71.53 1.28 1.23 1.4 2.92.51 5.22 2.82 5.74 5.74.12.7.69 1.23 1.4 1.23.85 0 1.54-.75 1.41-1.59a9.894 9.894 0 0 0-8.19-8.18Zm.59 5.41a2.18 2.18 0 1 0 0 4.36 2.18 2.18 0 0 0 0-4.36Z"
    }
  )
);
export {
  a as default
};
