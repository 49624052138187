import { jsx as e } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3ZM9.964 7.125H7.93v9.75h1.74V11c.008-.485-.009-.97-.05-1.455h.032c.106.238.23.468.37.687l4.145 6.643h1.903v-9.75h-1.735v5.838c-.01.443.01.885.06 1.325h-.027c-.091-.176-.228-.41-.41-.7L9.965 7.126Z"
      }
    )
  }
);
export {
  o as default
};
