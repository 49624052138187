/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleSites = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m14.5 6.5 3.016.57L20 6.5 14.5 1l-.759 2.607L14.5 6.5Z"
      fill="#2B313B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 1v5.5H20v15a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 4 21.5v-19A1.5 1.5 0 0 1 5.5 1h9Zm-7 15.25H14V12H7.5v4.25Zm7.75 0h1.25V12h-1.25v4.25Zm-7.75-5.5h9V9.5h-9v1.25Z"
    />
  </svg>
);

export default GoogleSites;
