import * as c from "react";
const t = (e) => /* @__PURE__ */ c.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...e
  },
  /* @__PURE__ */ c.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6 5c-.55 0-1 .45-1 1v2c0 .55-.45 1-1 1s-1-.45-1-1V5c0-1.1.9-2 2-2h3c.55 0 1 .45 1 1s-.45 1-1 1H6Zm6 3c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm-8 7c-.55 0-1 .45-1 1v3c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1ZM19 3h-3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1 .45 1 1v2c0 .55.45 1 1 1s1-.45 1-1V5c0-1.1-.9-2-2-2Zm-1 16c.55 0 1-.45 1-1v-2c0-.55.45-1 1-1s1 .45 1 1v3c0 1.1-.9 2-2 2h-3c-.55 0-1-.45-1-1s.45-1 1-1h2Z"
    }
  )
);
export {
  t as default
};
