import { jsx as c } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h10.1a5.085 5.085 0 0 0 0 2H4v12h16V9.9c.383-.083.742-.2 1.075-.35.333-.15.642-.333.925-.55v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 20H4ZM4 6v12V6Zm15 2a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 16 5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 19 2c.833 0 1.542.292 2.125.875S22 4.167 22 5s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 19 8Zm-7 3 3.65-2.275c.233.217.488.404.763.562.275.159.562.296.862.413l-4.75 2.975c-.167.1-.342.15-.525.15s-.358-.05-.525-.15L4 8V6l8 5Z" })
  }
);
export {
  s as default
};
