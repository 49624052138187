import { jsx as a } from "react/jsx-runtime";
const l = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M6 2a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H6Zm10.427 6.843c0-.73.598-1.328 1.328-1.328.73 0 1.328.598 1.328 1.328 0 .73-.597 1.329-1.328 1.329l-.62 4.736a.447.447 0 0 1-.42.355h-9.43a.411.411 0 0 1-.42-.354l-.62-4.738c-.73 0-1.328-.597-1.328-1.328 0-.73.597-1.328 1.328-1.328a1.332 1.332 0 0 1 .863 2.347 2.29 2.29 0 0 0 2.258 1.416 2.339 2.339 0 0 0 2.191-2.191c0-.2 0-.376-.044-.553a1.356 1.356 0 0 1-.841-1.24c0-.73.598-1.328 1.328-1.328.73 0 1.328.597 1.328 1.328 0 .575-.354 1.04-.841 1.24-.044.177-.044.354-.044.553a2.339 2.339 0 0 0 2.191 2.191 2.341 2.341 0 0 0 2.258-1.416 1.341 1.341 0 0 1-.465-1.019Zm-9.518 7.84c0-.221.199-.42.42-.42h9.341c.222 0 .421.199.421.42v.022c0 .73-.598 1.329-1.328 1.329H8.237c-.73 0-1.328-.598-1.328-1.329v-.022Z"
      }
    )
  }
);
export {
  l as default
};
