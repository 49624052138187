import { jsx as h } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M2 15.8V3c0-.283.096-.521.288-.713A.967.967 0 0 1 3 2h13c.283 0 .52.096.712.287.192.192.288.43.288.713v9c0 .283-.096.52-.288.712A.965.965 0 0 1 16 13H6l-3.15 3.15c-.15.15-.33.187-.538.112C2.104 16.187 2 16.033 2 15.8ZM7 18a.968.968 0 0 1-.713-.288A.967.967 0 0 1 6 17v-2h13V6h2c.283 0 .52.096.712.287.192.192.288.43.288.713v13.8c0 .233-.104.387-.312.462-.209.075-.388.038-.538-.112L18 18H7Z" })
  }
);
export {
  o as default
};
