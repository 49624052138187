import * as React from "react";
const SvgIconHeartLine = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M4.557 9.434a2.45 2.45 0 1 1 3.466-3.466l2.04 2.04 2.039-2.04a2.45 2.45 0 1 1 3.466 3.466l-5.506 5.505-5.505-5.505Zm4.527-4.527a3.95 3.95 0 0 0-5.588 5.588l6.566 6.566 6.566-6.566a3.95 3.95 0 1 0-5.587-5.588l-.979.979-.978-.979Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconHeartLine;
