import { jsx as a } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M18.146 15.146a.5.5 0 0 0 .854-.353V13h3a1 1 0 1 0 0-2h-3V9.207a.5.5 0 0 0-.854-.353l-2.792 2.792a.5.5 0 0 0 0 .708l2.792 2.792ZM5.854 8.854A.5.5 0 0 0 5 9.207V11H2a1 1 0 1 0 0 2h3v1.793a.5.5 0 0 0 .854.353l2.792-2.792a.5.5 0 0 0 0-.708L5.854 8.854ZM11 19a1 1 0 1 0 2 0V5a1 1 0 1 0-2 0v14Z" })
  }
);
export {
  o as default
};
