import { jsx as h } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M20 6H4v12h9v2H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v7h-2V6Zm-.825 14H16a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 19c0-.283.096-.52.288-.712A.965.965 0 0 1 16 18h3.175l-.9-.9a.914.914 0 0 1-.287-.7c.008-.267.112-.5.312-.7.2-.183.433-.28.7-.288.267-.008.5.088.7.288l2.6 2.6c.2.2.3.433.3.7 0 .267-.1.5-.3.7l-2.6 2.6a.975.975 0 0 1-.687.287.93.93 0 0 1-.713-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l.875-.9ZM4 6v12-5 .075V6Zm8 5 8-5v2l-7.475 4.675c-.167.1-.342.15-.525.15s-.358-.05-.525-.15L4 8V6l8 5Z" })
  }
);
export {
  t as default
};
