import { jsx as A } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ A("path", { d: "M20 21H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 20c0-.283.096-.52.288-.712A.965.965 0 0 1 4 19h16c.283 0 .52.096.712.288A.965.965 0 0 1 21 20c0 .283-.096.52-.288.712A.965.965 0 0 1 20 21Zm0-4h-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 16c0-.283.096-.521.288-.713A.967.967 0 0 1 16 15h4c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 17Zm0-4h-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 12c0-.283.096-.521.288-.713A.967.967 0 0 1 16 11h4c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 13Zm0-4h-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 8c0-.283.096-.521.288-.713A.967.967 0 0 1 16 7h4c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 9Zm0-4H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 4c0-.283.096-.521.288-.713A.967.967 0 0 1 4 3h16c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 5Zm-8 12H2a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 16V8c0-.283.096-.521.288-.713A.967.967 0 0 1 2 7h10a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 8v8c0 .283-.096.52-.287.712A.968.968 0 0 1 12 17Zm-1-2V9H3v6h8Z" })
  }
);
export {
  a as default
};
