import { jsx as a } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M13.143 17a.94.94 0 0 1-.84-.38.921.921 0 0 1-.06-.92l2.87-7.7a1.41 1.41 0 0 1 .58-.71 1.54 1.54 0 0 1 .87-.29c.31.01.608.11.86.29.27.164.477.414.59.71l2.9 7.72a.94.94 0 0 1-.06.9 1 1 0 0 1-1.38.2.89.89 0 0 1-.36-.45l-.62-1.77h-3.85l-.58 1.75a1 1 0 0 1-.36.46.92.92 0 0 1-.56.19Zm2.13-4.1h2.6l-1.2-3.7h-.15l-1.25 3.7ZM3.643 17a1.19 1.19 0 0 1-.87-.36 1.19 1.19 0 0 1-.36-.87 2 2 0 0 1 .12-.63c.07-.207.175-.4.31-.57l4.62-5.77h-3.95a.9.9 0 1 1 0-1.8h4.9a1.18 1.18 0 0 1 .86.36 1.21 1.21 0 0 1 .36.89 2.32 2.32 0 0 1-.11.63c-.061.2-.16.386-.29.55l-4.57 5.77h4.15a.9.9 0 1 1 0 1.8h-5.17Zm6.56-12a.47.47 0 0 1-.48-.3.47.47 0 0 1 .13-.5l1.8-1.85a1.42 1.42 0 0 1 .35-.15c.122.036.24.086.35.15l1.8 1.85a.48.48 0 0 1 .12.55.46.46 0 0 1-.47.3l-3.6-.05Zm1.8 16.8a1.45 1.45 0 0 1-.35-.15l-1.8-1.8a.47.47 0 0 1-.13-.55.47.47 0 0 1 .48-.3h3.6a.46.46 0 0 1 .47.3.48.48 0 0 1-.12.55l-1.8 1.8c-.11.063-.228.113-.35.15Z" })
  }
);
export {
  t as default
};
