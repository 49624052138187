import { jsx as c } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M7.975 18.25c-.167-.484-.32-.976-.462-1.476-.142-.5-.255-1-.338-1.5l-1.175.8v2.975l1.975-.8ZM11.65 4.873c-.8.85-1.442 1.97-1.925 3.362A13.282 13.282 0 0 0 9 12.624c0 1 .092 1.98.275 2.937.183.959.425 1.772.725 2.438h4c.3-.666.542-1.48.725-2.438.183-.958.275-1.937.275-2.937 0-1.533-.242-2.996-.725-4.388-.483-1.391-1.125-2.512-1.925-3.362a.48.48 0 0 0-.35-.15.48.48 0 0 0-.35.15ZM12 13c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 11c0-.55.196-1.02.588-1.413A1.925 1.925 0 0 1 12 9c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 13Zm4.025 5.25 1.975.8v-2.975l-1.175-.8c-.083.5-.196 1-.337 1.5-.142.5-.296.992-.463 1.475ZM12.775 2.6c1.4 1.2 2.454 2.675 3.163 4.425C16.646 8.774 17 10.766 17 13l2.1 1.4c.283.184.504.425.663.725.158.3.237.617.237.95v4.45c0 .35-.146.625-.438.825a.944.944 0 0 1-.937.1l-3.6-1.45h-6.05l-3.6 1.45a.944.944 0 0 1-.937-.1.948.948 0 0 1-.438-.825v-4.45c0-.333.08-.65.238-.95.158-.3.379-.541.662-.725L7 13c0-2.233.354-4.225 1.062-5.975A11.343 11.343 0 0 1 11.225 2.6c.117-.083.242-.15.375-.2.133-.05.267-.075.4-.075s.267.025.4.075c.133.05.258.117.375.2Z" })
  }
);
export {
  h as default
};
