import { jsxs as a, jsx as c } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ a(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    ...t,
    children: [
      /* @__PURE__ */ c("path", { d: "M17.855 1.122c.029-.163.261-.163.29 0a4.6 4.6 0 0 0 3.733 3.733c.163.029.163.261 0 .29a4.6 4.6 0 0 0-3.733 3.733c-.029.163-.261.163-.29 0a4.6 4.6 0 0 0-3.733-3.733c-.163-.029-.163-.261 0-.29a4.6 4.6 0 0 0 3.733-3.733" }),
      /* @__PURE__ */ c("path", { d: "M17.31 13.549a6.95 6.95 0 0 0 1.407-2.591 6.1 6.1 0 0 1-2.068-.11c-.183.457-.437.888-.759 1.28-1.14 1.41-1.78 3-1.88 4.63-.01.14-.11.24-.24.24h-3.56c-.13 0-.23-.1-.24-.23-.14-1.73-.77-3.33-1.81-4.57-.98-1.16-1.36-2.65-1.07-4.18.36-1.94 1.96-3.55 3.89-3.93A6 6 0 0 1 12.083 4a6 6 0 0 1 .694-1.954 7.3 7.3 0 0 0-1.887.044c-2.91.44-5.16 2.57-5.74 5.46-.47 2.33.22 4.5 1.59 6.06.94 1.07 1.26 2.49 1.26 3.92 0 .81.66 1.47 1.47 1.47h5.05c.81 0 1.47-.66 1.47-1.47v-.18c0-1.38.42-2.75 1.32-3.8M14.75 20c.14 0 .25.11.25.25 0 .97-.78 1.75-1.75 1.75h-2.5C9.78 22 9 21.22 9 20.25c0-.14.11-.25.25-.25z" })
    ]
  }
);
export {
  s as default
};
