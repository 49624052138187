import * as React from "react";
const SvgIconExitToApp = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-3c0-.55.45-1 1-1s1 .45 1 1v2c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v2c0 .55-.45 1-1 1s-1-.45-1-1V5a2 2 0 0 1 2-2Zm10.79 9.7-3.59 3.59a.996.996 0 1 1-1.41-1.41L12.67 13H4c-.55 0-1-.45-1-1s.45-1 1-1h8.67l-1.88-1.89A.996.996 0 1 1 12.2 7.7l3.59 3.59c.39.39.39 1.02 0 1.41Z"
    />
  </svg>
);
export default SvgIconExitToApp;
