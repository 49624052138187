import { jsxs as t, jsx as e } from "react/jsx-runtime";
const d = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M20 12a8 8 0 1 0-16 0 8 8 0 0 0 16 0Zm2 0c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10 10-4.477 10-10Z"
        }
      ),
      /* @__PURE__ */ e("path", { d: "M15.075 12.005a.948.948 0 0 1-.275.7l-3.1 3.1a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275c-.2-.2-.3-.438-.3-.713 0-.275.092-.504.275-.687l2.4-2.4L10.3 9.63a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7.2-.2.437-.3.712-.3.275 0 .505.092.688.275l3.1 3.1a.948.948 0 0 1 .275.7Z" })
    ]
  }
);
export {
  d as default
};
