/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Android = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.071 15.264a.92.92 0 1 1 0-1.84.92.92 0 0 1 0 1.84Zm-10.123 0a.92.92 0 1 1 0-1.84.92.92 0 0 1 0 1.84Zm10.449-5.517 1.839-3.17a.379.379 0 1 0-.66-.374l-1.859 3.209a11.223 11.223 0 0 0-4.703-1.015c-1.695 0-3.294.364-4.712 1.005L5.444 6.194a.379.379 0 1 0-.66.374l1.829 3.17C3.47 11.451 1.316 14.631 1 18.395h22c-.306-3.755-2.452-6.935-5.603-8.649"
      fill="#3DDC84"
    />
  </svg>
);

export default Android;
