import { jsxs as s, jsx as t } from "react/jsx-runtime";
const i = (h) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M1 3.75H.75v17.1h22.5V3.75H1Z",
          stroke: "#000",
          strokeOpacity: 0.2,
          strokeWidth: 0.5
        }
      ),
      /* @__PURE__ */ t("path", { d: "M1 4h22v16.6H1V4Z", fill: "#066AA7" }),
      /* @__PURE__ */ t("path", { d: "M1 10.64h22v3.32H1v-3.32Z", fill: "#FECC00" }),
      /* @__PURE__ */ t("path", { d: "M7.05 4h3.3v16.6h-3.3V4Z", fill: "#FECC00" })
    ]
  }
);
export {
  i as default
};
