import { jsx as c } from "react/jsx-runtime";
const v = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M6 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 18v-7c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 9c.55 0 1.02.196 1.412.587C7.804 9.979 8 10.45 8 11v7c0 .55-.196 1.021-.588 1.413A1.925 1.925 0 0 1 6 20Zm6 0c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 12 4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 20Zm6 0c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 16 18v-3c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 18 13c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v3c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 20Z" })
  }
);
export {
  v as default
};
