import * as React from "react";
const SvgIconGroup = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.99 8c0 1.66-1.33 3-2.99 3-1.66 0-3-1.34-3-3s1.34-3 3-3 2.99 1.34 2.99 3Zm8 0c0 1.66-1.33 3-2.99 3-1.66 0-3-1.34-3-3s1.34-3 3-3 2.99 1.34 2.99 3ZM8 13c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5Zm7.03.05c.35-.03.68-.05.97-.05 2.33 0 7 1.17 7 3.5V18c0 .55-.45 1-1 1h-5.18c.11-.31.18-.65.18-1v-1.5c0-1.47-.79-2.58-1.93-3.41a.12.12 0 0 1-.01-.011.092.092 0 0 0-.03-.029Z"
    />
  </svg>
);
export default SvgIconGroup;
