import { jsxs as t, jsx as e } from "react/jsx-runtime";
const d = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm0-2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Z"
        }
      ),
      /* @__PURE__ */ e("path", { d: "M12.005 8.925c.283 0 .517.092.7.275l3.1 3.1a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7c-.2.2-.438.3-.713.3a.932.932 0 0 1-.687-.275l-2.4-2.4L9.63 13.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275c-.2-.2-.3-.437-.3-.712 0-.275.092-.505.275-.688l3.1-3.1a.948.948 0 0 1 .7-.275Z" })
    ]
  }
);
export {
  d as default
};
