import { jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M19 23c-1.383 0-2.562-.487-3.537-1.462C14.488 20.563 14 19.383 14 18s.488-2.563 1.463-3.538C16.438 13.487 17.617 13 19 13s2.563.487 3.538 1.462C23.513 15.437 24 16.617 24 18s-.487 2.563-1.462 3.538C21.563 22.513 20.383 23 19 23Zm-2.5-4.5h5a.48.48 0 0 0 .35-.15.48.48 0 0 0 0-.7.48.48 0 0 0-.35-.15h-5a.48.48 0 0 0-.35.15.48.48 0 0 0 0 .7c.1.1.217.15.35.15ZM4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v5.7a7.311 7.311 0 0 0-1.462-.5A6.668 6.668 0 0 0 19 11c-.967 0-1.875.183-2.725.55a7.078 7.078 0 0 0-2.225 1.5 7.078 7.078 0 0 0-1.5 2.225A6.805 6.805 0 0 0 12 18c0 .35.025.687.075 1.012.05.325.125.655.225.988H4Zm8-7.175c.083 0 .17-.013.262-.038s.18-.062.263-.112L19.6 8.25a.86.86 0 0 0 .3-.3.844.844 0 0 0 .1-.425c0-.3-.133-.542-.4-.725-.267-.183-.567-.183-.9 0L12 11 5.3 6.8c-.317-.183-.613-.188-.888-.013A.83.83 0 0 0 4 7.525c0 .167.033.308.1.425a.86.86 0 0 0 .3.3l7.075 4.425c.083.05.171.087.263.112a.981.981 0 0 0 .262.038Z" })
  }
);
export {
  h as default
};
