/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Pages = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm6.25 6.032a.809.809 0 0 0-.455-.74l-3.75-1.726C6.551 6.338 6 6.728 6 7.306v7.912c0 .322.179.613.455.74l3.75 1.726c.495.228 1.045-.162 1.045-.74V9.032Zm1.5 0c0-.322.179-.613.455-.74l3.75-1.726c.495-.228 1.045.162 1.045.74v7.912a.809.809 0 0 1-.455.74l-3.75 1.726c-.495.228-1.045-.162-1.045-.74V9.032Z"
    />
  </svg>
);

export default Pages;
