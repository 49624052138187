import { jsx as c } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M6 21c-.75 0-1.492-.183-2.225-.55C3.042 20.084 2.45 19.6 2 19c.433 0 .875-.17 1.325-.512C3.775 18.146 4 17.65 4 17c0-.833.292-1.541.875-2.125A2.893 2.893 0 0 1 7 14c.833 0 1.542.292 2.125.875.583.584.875 1.292.875 2.125 0 1.1-.392 2.042-1.175 2.825C8.042 20.61 7.1 21 6 21Zm0-2c.55 0 1.02-.195 1.412-.587C7.804 18.021 8 17.55 8 17a.967.967 0 0 0-.287-.712A.968.968 0 0 0 7 16a.968.968 0 0 0-.713.288A.967.967 0 0 0 6 17c0 .384-.046.733-.137 1.05a4.754 4.754 0 0 1-.363.9.67.67 0 0 0 .25.05H6Zm5.75-4L9 12.25l8.95-8.95a.98.98 0 0 1 .688-.288.932.932 0 0 1 .712.288l1.35 1.35c.2.2.3.434.3.7 0 .267-.1.5-.3.7L11.75 15Z" })
  }
);
export {
  h as default
};
