import { jsx as t } from "react/jsx-runtime";
const e = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M12 21c-2.517 0-4.646-.87-6.388-2.613C3.871 16.647 3 14.517 3 12c0-2.3.75-4.296 2.25-5.988C6.75 4.322 8.667 3.334 11 3.05c.417-.05.742.1.975.45.233.35.225.717-.025 1.1a5.16 5.16 0 0 0-.85 2.9c0 1.5.525 2.775 1.575 3.825C13.725 12.375 15 12.9 16.5 12.9c.517 0 1.03-.075 1.538-.225a4.98 4.98 0 0 0 1.362-.625c.35-.233.708-.246 1.075-.038.367.21.525.538.475.988-.233 2.3-1.212 4.209-2.937 5.725C16.288 20.242 14.283 21 12 21Z" })
  }
);
export {
  e as default
};
