import { jsxs as v, jsx as h } from "react/jsx-runtime";
const c = (a) => /* @__PURE__ */ v(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ h("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm0-2h14V5H5v14Z" }),
      /* @__PURE__ */ h("path", { d: "M7.5 10.5h2.3a.2.2 0 0 0 .2-.2C10 9.7 9.5 9 8.6 9H7.3C6.5 9 6 9.7 6 10.4v3.3c0 .6.5 1.3 1.3 1.3h1.4c.8 0 1.3-.6 1.3-1.3v-.95a.75.75 0 0 0-1.5 0v.75h-1v-3ZM11.5 14.25a.75.75 0 0 0 1.5 0v-4.5a.75.75 0 0 0-1.5 0v4.5ZM16 10.5h.75a.75.75 0 0 0 0-1.5H15.5a1 1 0 0 0-1 1v4.25a.75.75 0 0 0 1.5 0V13h.75a.75.75 0 0 0 0-1.5H16v-1Z" })
    ]
  }
);
export {
  c as default
};
