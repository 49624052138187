import { jsx as l } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ l("path", { d: "M3 21v-4.25l4.5-4.5-3.875-3.9c-.2-.2-.346-.425-.437-.675a2.054 2.054 0 0 1 0-1.487c.091-.242.237-.463.437-.663l1.9-1.9c.2-.2.425-.346.675-.438a2.075 2.075 0 0 1 1.488 0c.241.092.462.238.662.438l3.9 3.9 4.475-4.5c.1-.1.208-.175.325-.225a.942.942 0 0 1 .375-.075 1.03 1.03 0 0 1 .725.3l2.825 2.825c.1.1.175.208.225.325.05.116.075.241.075.375a1 1 0 0 1-.3.725L16.5 11.75l3.875 3.9c.2.2.346.425.437.675a2.053 2.053 0 0 1 0 1.487 1.866 1.866 0 0 1-.437.663l-1.9 1.9c-.2.2-.421.345-.663.437a2.053 2.053 0 0 1-1.487 0 1.842 1.842 0 0 1-.675-.437l-3.9-3.9L7.25 21H3Zm5.925-10.175 1.9-1.9-1.2-1.2-1.2 1.175-1.4-1.4L8.2 6.3 6.925 5.05 5.05 6.95l3.875 3.875Zm8.125 8.125 1.9-1.9-1.275-1.25-1.175 1.175-1.425-1.4 1.2-1.2-1.225-1.2-1.9 1.9 3.9 3.875ZM5 19h1.4l9.625-9.6L14.6 7.975 5 17.6V19ZM17.425 7.975 18.85 6.55l-1.425-1.4-1.4 1.4 1.4 1.425Z" })
  }
);
export {
  t as default
};
