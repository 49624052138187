import { jsx as h } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M17.8 20c-.4 1.2-1.5 2-2.8 2H5c-1.7 0-3-1.3-3-3v-1h12.2c.4 1.2 1.5 2 2.8 2h.8ZM19 2H8C6.3 2 5 3.3 5 5v10a1 1 0 0 0 1 1h10v1c0 .6.4 1 1 1h1V5c0-.6.4-1 1-1s1 .4 1 1a1 1 0 1 0 2 0c0-1.7-1.3-3-3-3Z" })
  }
);
export {
  s as default
};
