import { jsxs as o, jsx as t } from "react/jsx-runtime";
const l = (h) => /* @__PURE__ */ o(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M11.994 2c-5.665 0-9.501 3.313-9.501 9.44v1.25c0 6.377 3.55 9.31 9.5 9.31 5.698 0 9.514-2.988 9.514-9.307V12h-9.513V2Z",
          fill: "#0061FE"
        }
      ),
      /* @__PURE__ */ t("path", { d: "M21.495 12V2h-9.5l9.5 10Z", fill: "#1E1919" })
    ]
  }
);
export {
  l as default
};
