import { jsx as e } from "react/jsx-runtime";
const a = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M19.626 2H4.377A2.378 2.378 0 0 0 2 4.376V19.61A2.38 2.38 0 0 0 4.377 22h15.249A2.377 2.377 0 0 0 22 19.611V4.376A2.378 2.378 0 0 0 19.626 2Zm-9 14.405a.795.795 0 0 1-.802.792H6.492a.792.792 0 0 1-.788-.792V6.482c0-.436.352-.79.788-.792h3.332c.437.002.79.356.792.792l.01 9.923Zm7.69-4.556a.792.792 0 0 1-.802.792h-3.332a.795.795 0 0 1-.791-.792V6.482a.795.795 0 0 1 .791-.792h3.332a.792.792 0 0 1 .788.792l.013 5.367Z"
      }
    )
  }
);
export {
  a as default
};
