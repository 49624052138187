import { jsx as a } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "m10.65 19.8-1.725-1.575a68.234 68.234 0 0 1-4.787-4.813C2.713 11.822 2 10.067 2 8.15c0-1.566.525-2.875 1.575-3.925C4.625 3.175 5.933 2.65 7.5 2.65a5.72 5.72 0 0 1 2.5.562c.783.376 1.45.888 2 1.538a5.962 5.962 0 0 1 2-1.538 5.72 5.72 0 0 1 2.5-.562c1.567 0 2.875.525 3.925 1.575C21.475 5.275 22 6.584 22 8.15c0 1.917-.708 3.675-2.125 5.275a59.863 59.863 0 0 1-4.825 4.825l-1.7 1.55a1.89 1.89 0 0 1-1.35.55 1.89 1.89 0 0 1-1.35-.55Zm.4-13.05c-.483-.683-1-1.204-1.55-1.563-.55-.358-1.217-.537-2-.537-1 0-1.833.334-2.5 1-.667.667-1 1.5-1 2.5 0 .867.308 1.788.925 2.762a19.063 19.063 0 0 0 2.213 2.838 38.261 38.261 0 0 0 2.65 2.575c.908.8 1.645 1.459 2.212 1.975.567-.516 1.304-1.175 2.213-1.975a38.57 38.57 0 0 0 2.65-2.575 19.101 19.101 0 0 0 2.212-2.838c.617-.974.925-1.895.925-2.762 0-1-.333-1.833-1-2.5-.667-.666-1.5-1-2.5-1-.783 0-1.45.18-2 .537-.55.36-1.067.88-1.55 1.563a1.138 1.138 0 0 1-.95.5 1.138 1.138 0 0 1-.95-.5Z" })
  }
);
export {
  e as default
};
