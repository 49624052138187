import { jsx as a } from "react/jsx-runtime";
const o = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M14.437 2.997a1 1 0 0 1 .835.488l5.027 8.925a1 1 0 0 1-.86 1.511H15.36L9.916 4.495a1 1 0 0 1 .89-1.5h3.63ZM2.292 16.01a1 1 0 0 1 .005-.968l5.02-8.963a1 1 0 0 1 1.74-.011l2.3 3.987-5.439 9.426a1 1 0 0 1-1.744-.021l-1.882-3.45ZM18.794 20.872a1 1 0 0 0 .841-.479l2.047-3.355a1 1 0 0 0-.854-1.52l-10.883.002-2.302 3.986a1 1 0 0 0 .879 1.5l10.272-.134Z" })
  }
);
export {
  o as default
};
