/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const DropboxReplay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.294 8.218v-.035L12 2v7.6l-3.752 2.39L12 14.365V22l-9.706-6.183V8.218Z"
      fill="#2B313B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8.218v-.035L21.706 2v7.6l-3.752 2.39 3.752 2.376V22L12 15.817V8.218Z"
      fill="#68778D"
    />
  </svg>
);

export default DropboxReplay;
