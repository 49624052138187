import { jsx as t } from "react/jsx-runtime";
const e = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M19.075 21.9 14 16.825V18c0 .55-.196 1.02-.587 1.413A1.928 1.928 0 0 1 12 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 18v-5.15L2.075 4.925a1.003 1.003 0 0 1-.275-.7c0-.267.1-.5.3-.7.2-.2.437-.3.712-.3.275 0 .513.1.713.3L20.5 20.5c.2.2.296.433.288.7-.009.266-.113.5-.313.7-.2.183-.433.279-.7.288a.918.918 0 0 1-.7-.288ZM14.8 11.975l-1.425-1.425L16.95 6H8.825l-2-2H19c.417 0 .717.183.9.55.183.366.15.716-.1 1.05l-5 6.375Z" })
  }
);
export {
  e as default
};
