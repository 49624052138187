import { jsx as c } from "react/jsx-runtime";
const e = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M9.2 11.3a.948.948 0 0 0-.275.7c0 .283.092.517.275.7l3.1 3.1a.934.934 0 0 0 .688.275c.275 0 .512-.1.712-.3a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7L11.325 12l2.4-2.4A.932.932 0 0 0 14 8.913c0-.275-.1-.513-.3-.713a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-3.1 3.1ZM2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.732 9.732 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.091 10.091 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.138 3.175A9.742 9.742 0 0 1 22 12a9.743 9.743 0 0 1-.787 3.9 10.105 10.105 0 0 1-2.138 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12Z" })
  }
);
export {
  e as default
};
