/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftExcelAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3Zm-8.7 8.986-3.155 4.889H9.93l1.876-3.427a2.16 2.16 0 0 0 .211-.442h.027c.06.158.132.31.218.456l1.822 3.413h2.271l-3.04-4.916 2.965-4.833h-2.135l-1.686 3.08c-.114.223-.224.451-.327.687h-.02a7.995 7.995 0 0 0-.3-.66l-1.578-3.109H7.91l2.89 4.862Z"
    />
  </svg>
);

export default MicrosoftExcelAlt;
