import { jsx as c } from "react/jsx-runtime";
const l = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M21.75 18.925 6.825 4H10l2 2h8c.55 0 1.021.196 1.413.588C21.804 6.98 22 7.45 22 8v10c0 .184-.02.35-.062.5-.042.15-.105.292-.188.425ZM19.775 22.6l-2.6-2.6H4c-.55 0-1.02-.195-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4v2.8L1.4 4.2a.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l18.4 18.4c.2.2.296.434.288.7-.009.267-.113.5-.313.7-.2.184-.433.28-.7.287a.914.914 0 0 1-.7-.287Z" })
  }
);
export {
  l as default
};
