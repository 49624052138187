/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleForms = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m14.5 6.5 3.016.57L20 6.5 14.5 1l-.759 2.607L14.5 6.5Z"
      fill="#2B313B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 1v5.5H20v15a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 4 21.5v-19A1.5 1.5 0 0 1 5.5 1h9Zm-7 15.125a.75.75 0 1 0 1.501-.001.75.75 0 0 0-1.501.001Zm0-3a.75.75 0 1 0 1.501-.001.75.75 0 0 0-1.501.001Zm0-3a.75.75 0 1 0 1.501-.001.75.75 0 0 0-1.501.001Zm2.75 6.625h6.25V15.5h-6.25v1.25Zm0-3h6.25V12.5h-6.25v1.25Zm0-3h6.25V9.5h-6.25v1.25Z"
    />
  </svg>
);

export default GoogleForms;
