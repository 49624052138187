import { jsx as a } from "react/jsx-runtime";
const t = (A) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ a("path", { d: "M10 3a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 2c0-.283.096-.521.288-.713A.967.967 0 0 1 10 1h4a.97.97 0 0 1 .713.287A.97.97 0 0 1 15 2c0 .283-.096.52-.287.712A.968.968 0 0 1 14 3h-4Zm2 11a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 13V9a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 8a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 9v4c0 .283.096.52.288.712A.965.965 0 0 0 12 14Zm0 8a8.654 8.654 0 0 1-3.488-.712A9.2 9.2 0 0 1 5.65 19.35a9.202 9.202 0 0 1-1.938-2.862A8.655 8.655 0 0 1 3 13c0-1.233.237-2.396.712-3.488A9.202 9.202 0 0 1 5.65 6.65a9.188 9.188 0 0 1 2.862-1.937A8.644 8.644 0 0 1 12 4a8.92 8.92 0 0 1 2.975.5c.95.333 1.842.817 2.675 1.45l.725-.725a.918.918 0 0 1 .675-.275c.267 0 .5.1.7.3a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-.7.7a9.723 9.723 0 0 1 1.45 2.675c.333.95.5 1.942.5 2.975a8.654 8.654 0 0 1-.712 3.488 9.201 9.201 0 0 1-1.938 2.862 9.201 9.201 0 0 1-2.862 1.938A8.654 8.654 0 0 1 12 22Z" })
  }
);
export {
  t as default
};
