import { jsx as a } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "m15.66 7.761-1.26 1.24a.5.5 0 0 0 .35.85h3.95a.5.5 0 0 0 .5-.5v-3.93a.5.5 0 0 0-.86-.34l-1.26 1.26a8 8 0 0 0-13 2.49 1 1 0 0 0 1.82.83 5.79 5.79 0 0 1 1.28-1.9 6 6 0 0 1 8.48 0Zm2.74 5.879a1 1 0 0 0-.9.57 6 6 0 0 1-9.76 1.92L9 14.85a.5.5 0 0 0-.35-.85H4.7a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .85.35l1.27-1.32a8 8 0 0 0 11.32 0 7.77 7.77 0 0 0 1.68-2.49 1 1 0 0 0-.92-1.4Z" })
  }
);
export {
  h as default
};
