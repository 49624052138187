/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraSuggestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z"
      fill="#FF9C23"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.737 14.318a2.624 2.624 0 0 0-.28-.393l-.009-.011-.433-.535c-.17-.206-.338-.41-.5-.621-.874-1.151-1.186-2.44-.927-3.832.389-2.085 2.232-3.603 4.382-3.612 2.184.01 4.027 1.527 4.416 3.612.26 1.393-.052 2.681-.927 3.832-.159.208-.325.412-.493.615l-.344.424c-.174.219-.333.43-.367.618a1.09 1.09 0 0 0-.018.146v1.94a1.5 1.5 0 0 1-1.5 1.5h-1.5a1.5 1.5 0 0 1-1.5-1.5v-2.183Zm3.273-.818c.125-.232.28-.438.434-.634l.363-.446.071-.087c.131-.16.262-.32.387-.485.617-.81.828-1.675.646-2.646C14.659 7.846 13.407 6.82 12 6.814c-1.43.006-2.683 1.032-2.936 2.388-.18.971.031 1.837.646 2.646.15.197.306.387.464.578l.357.441c.172.212.336.415.467.633h2.013Z"
      fill="#fff"
    />
  </svg>
);

export default JiraSuggestion;
