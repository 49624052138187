import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/alerts/package.json";
import { InfoText, ErrorMessage } from "../../../../../packages/alerts/src/index";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  InfoText,
  ErrorMessage,
  React
};