import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M21.625 18.75 11.45 8.6l-.3-2.475a.993.993 0 0 1 .238-.787.923.923 0 0 1 .737-.338H16V2c0-.283.096-.521.288-.713A.967.967 0 0 1 17 1c.283 0 .52.096.712.287.192.192.288.43.288.713v3h3.9c.3 0 .55.108.75.325a.94.94 0 0 1 .25.775l-1.275 12.65ZM2 19a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 18c0-.283.096-.52.288-.712A.965.965 0 0 1 2 17h13c.283 0 .521.096.713.288A.967.967 0 0 1 16 18c0 .283-.096.52-.287.712A.968.968 0 0 1 15 19H2Zm0 4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 22c0-.283.096-.52.288-.712A.965.965 0 0 1 2 21h13c.283 0 .521.096.713.288A.967.967 0 0 1 16 22c0 .283-.096.52-.287.712A.968.968 0 0 1 15 23H2Zm17.775-.425L12.175 15H2.1a.989.989 0 0 1-.775-.338.869.869 0 0 1-.225-.762c.317-1.65 1.2-2.88 2.65-3.688C5.2 9.404 6.783 9 8.5 9c.083 0 .17.004.262.012.092.009.188.013.288.013l-.025 2.825-7.65-7.65a.933.933 0 0 1-.288-.713c.009-.275.113-.512.313-.712.2-.2.438-.3.713-.3.275 0 .512.1.712.3l18.375 18.4c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3Z" })
  }
);
export {
  t as default
};
