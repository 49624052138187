import * as React from "react";
const SvgIconLogoSlackColor = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M7.362 14.1c0 .925-.756 1.68-1.68 1.68S4 15.036 4 14.1s.756-1.68 1.68-1.68h1.68v1.68zm.846 0c0-.925.756-1.68 1.68-1.68s1.68.756 1.68 1.68v4.208c0 .925-.756 1.68-1.68 1.68s-1.68-.756-1.68-1.68V14.1z"
      fill="#e01e5a"
    />
    <path
      d="M9.9 7.362c-.925 0-1.68-.756-1.68-1.68S8.964 4 9.9 4s1.68.756 1.68 1.68v1.68H9.9zm0 .846c.925 0 1.68.756 1.68 1.68s-.756 1.68-1.68 1.68H5.7c-.925 0-1.68-.756-1.68-1.68s.756-1.68 1.68-1.68h4.2z"
      fill="#36c5f0"
    />
    <path
      d="M16.638 9.9c0-.925.756-1.68 1.68-1.68S20 8.964 20 9.9s-.756 1.68-1.68 1.68h-1.68V9.9zm-.846 0c0 .925-.756 1.68-1.68 1.68s-1.68-.756-1.68-1.68V5.7c0-.925.756-1.68 1.68-1.68s1.68.756 1.68 1.68v4.2z"
      fill="#2eb67d"
    />
    <path
      d="M14.1 16.638c.925 0 1.68.756 1.68 1.68S15.036 20 14.1 20s-1.68-.756-1.68-1.68v-1.68h1.68zm0-.846c-.925 0-1.68-.756-1.68-1.68s.756-1.68 1.68-1.68h4.208c.925 0 1.68.756 1.68 1.68s-.756 1.68-1.68 1.68H14.1z"
      fill="#ecb22e"
    />
  </svg>
);
export default SvgIconLogoSlackColor;
