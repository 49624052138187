import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 19.55c-.183 0-.362-.025-.537-.075a2.302 2.302 0 0 1-.488-.2 8.81 8.81 0 0 0-2.15-.95A8.334 8.334 0 0 0 6.5 18c-.7 0-1.387.092-2.062.275a9.041 9.041 0 0 0-1.938.775c-.35.184-.687.175-1.012-.025A.966.966 0 0 1 1 18.15V6.1c0-.183.046-.358.138-.525A.86.86 0 0 1 1.55 5.2c.767-.4 1.567-.7 2.4-.9.833-.2 1.683-.3 2.55-.3.967 0 1.913.125 2.838.375S11.15 5 12 5.5v12.1c.85-.533 1.742-.933 2.675-1.2a10.235 10.235 0 0 1 4.588-.25c.575.1 1.154.25 1.737.45v-12c.25.084.496.17.738.262.241.092.479.205.712.338a.866.866 0 0 1 .413.375c.091.167.137.342.137.525v12.05a.966.966 0 0 1-.487.875c-.325.2-.663.209-1.013.025a9.043 9.043 0 0 0-1.938-.775A7.826 7.826 0 0 0 17.5 18c-.8 0-1.575.109-2.325.325a8.81 8.81 0 0 0-2.15.95c-.15.084-.312.15-.487.2-.175.05-.355.075-.538.075Zm2.825-5.3c-.167.15-.346.18-.537.087a.474.474 0 0 1-.288-.462V5.7c0-.033.05-.15.15-.35l4-4c.167-.166.35-.208.55-.125.2.084.3.242.3.475v8.575c0 .067-.058.192-.175.375l-4 3.6ZM10 16.625v-9.9a9.269 9.269 0 0 0-1.712-.537A8.61 8.61 0 0 0 6.5 6c-.617 0-1.217.059-1.8.175A8.722 8.722 0 0 0 3 6.7v9.925A9.814 9.814 0 0 1 6.5 16a9.814 9.814 0 0 1 3.5.625Zm0 0v-9.9 9.9Z" })
  }
);
export {
  t as default
};
