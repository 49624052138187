import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M5 11c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 3 9V5c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 5 3h4c.55 0 1.02.196 1.412.587C10.804 3.98 11 4.45 11 5v4c0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 9 11H5Zm0 10c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19v-4c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 13h4c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412v4c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 9 21H5Zm10-10c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 13 9V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 15 3h4c.55 0 1.02.196 1.413.587C20.803 3.98 21 4.45 21 5v4c0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 19 11h-4Zm0 10c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 13 19v-4c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 15 13h4c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v4c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21h-4Z" })
  }
);
export {
  t as default
};
