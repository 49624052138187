import { jsx as t } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M7.15 21.1a1.214 1.214 0 0 1-.375-.888c0-.341.125-.637.375-.887L14.475 12l-7.35-7.35a1.187 1.187 0 0 1-.35-.875c0-.35.125-.65.375-.9s.546-.375.888-.375c.341 0 .637.125.887.375l8.4 8.425c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.883.883 0 0 1-.213.325L8.9 21.125c-.233.233-.52.35-.862.35-.342 0-.638-.125-.888-.375Z" })
  }
);
export {
  a as default
};
