import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M14.9 12.05 11.95 9.1l4.6-2.15c.167-.083.313-.063.438.062s.145.271.062.438l-2.15 4.6Zm4.175 9.85L17.5 20.35c-.817.533-1.692.942-2.625 1.225A9.844 9.844 0 0 1 12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.732 9.732 0 0 1 2 12c0-.983.142-1.942.425-2.875A10.19 10.19 0 0 1 3.65 6.5L2.075 4.925a.976.976 0 0 1-.287-.688.928.928 0 0 1 .287-.712c.2-.2.438-.3.713-.3.275 0 .512.1.712.3l17 16.975a.98.98 0 0 1 .288.688.932.932 0 0 1-.288.712c-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3Zm1.275-4.4-1.45-1.45A7.924 7.924 0 0 0 20 12c0-2.217-.779-4.104-2.337-5.663C16.104 4.779 14.217 4 12 4a7.923 7.923 0 0 0-4.05 1.1L6.5 3.65a10.239 10.239 0 0 1 2.613-1.225A9.799 9.799 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.8 9.8 0 0 1-.425 2.887A10.24 10.24 0 0 1 20.35 17.5ZM12 20a7.935 7.935 0 0 0 4.05-1.1l-4-4-4.625 2.175c-.167.083-.312.062-.437-.063s-.146-.27-.063-.437L9.05 11.9 5.1 7.95A7.934 7.934 0 0 0 4 12c0 2.217.78 4.104 2.338 5.663C7.896 19.221 9.783 20 12 20Z" })
  }
);
export {
  t as default
};
