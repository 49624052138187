import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "m20.975 21.1-5.9-5.9-2.15 2.15c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063c-.133 0-.258-.02-.375-.063a.871.871 0 0 1-.325-.212l-4.25-4.25c-.183-.183-.275-.42-.275-.713 0-.291.092-.529.275-.712.183-.183.421-.275.713-.275.291 0 .529.092.712.275l3.525 3.525 1.4-1.4-9.65-9.65c-.183-.183-.275-.42-.275-.712s.092-.53.275-.713c.183-.183.421-.275.713-.275.291 0 .529.092.712.275l17 17a.918.918 0 0 1 .275.675c0 .267-.1.5-.3.7a.949.949 0 0 1-.7.275.948.948 0 0 1-.7-.275Zm-3.1-8.7-1.4-1.4L20.7 6.775a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L17.875 12.4Zm-2.85-2.85-1.4-1.4 1.45-1.45a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-1.45 1.45Zm-8.45 8.075c-.133 0-.258-.02-.375-.063a.871.871 0 0 1-.325-.212l-4.25-4.25a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l3.55 3.55 1.4 1.4-.7.7c-.1.1-.208.17-.325.212a1.099 1.099 0 0 1-.375.063Z" })
  }
);
export {
  t as default
};
