import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M10 19v-2c-1.383 0-2.563-.488-3.537-1.463C5.487 14.562 5 13.383 5 11.999c0-1.016.28-1.941.838-2.775A4.836 4.836 0 0 1 8.1 7.4a2.36 2.36 0 0 1 .888-1.375c.458-.35.979-.525 1.562-.525l-.225-.625c-.083-.266-.07-.52.038-.762a.952.952 0 0 1 .562-.513h.025a.878.878 0 0 1 .025-.737c.117-.242.317-.413.6-.513a.984.984 0 0 1 .738.038.868.868 0 0 1 .487.562.964.964 0 0 1 .775.025c.25.117.425.309.525.575l2.05 5.625c.1.267.096.521-.012.763a.952.952 0 0 1-.563.512h-.025c.1.267.092.525-.025.775a.993.993 0 0 1-.6.525.984.984 0 0 1-.738-.037.869.869 0 0 1-.487-.563.964.964 0 0 1-.775-.025.994.994 0 0 1-.525-.575l-.25-.7c-.25.234-.537.409-.863.525a2.233 2.233 0 0 1-.987.125 2.37 2.37 0 0 1-1.025-.337 3.222 3.222 0 0 1-.825-.713c-.45.267-.804.625-1.062 1.075C7.128 10.974 7 11.466 7 12c0 .834.292 1.542.875 2.125A2.893 2.893 0 0 0 10 15h7c.283 0 .52.096.712.288.192.191.288.429.288.712 0 .284-.096.521-.288.713a.968.968 0 0 1-.712.287h-4v2h5c.283 0 .52.096.712.288.192.191.288.429.288.712 0 .284-.096.521-.288.713a.968.968 0 0 1-.712.287H6a.968.968 0 0 1-.713-.287.968.968 0 0 1-.287-.713c0-.283.096-.52.287-.712A.967.967 0 0 1 6 18.999h4Zm3.65-9.45.9-.35-1.7-4.7-.95.35 1.75 4.7ZM10.5 9c.283 0 .52-.097.713-.288a.967.967 0 0 0 .287-.713.967.967 0 0 0-.287-.712.968.968 0 0 0-.713-.288.968.968 0 0 0-.713.288.968.968 0 0 0-.287.712c0 .284.096.521.287.713.192.191.43.287.713.287Z" })
  }
);
export {
  t as default
};
