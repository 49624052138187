import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M12 14a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 13v-2h2a.968.968 0 0 0 .713-.288A.967.967 0 0 0 16 10a.97.97 0 0 0-.287-.713A.97.97 0 0 0 15 9h-2V7a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 6a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 7v2H9a.967.967 0 0 0-.712.287A.968.968 0 0 0 8 10c0 .283.096.52.288.712A.965.965 0 0 0 9 11h2v2c0 .283.096.52.288.712A.965.965 0 0 0 12 14Zm0 7.625c-.133 0-.267-.025-.4-.075a1.2 1.2 0 0 1-.35-.2C8.817 19.2 7 17.204 5.8 15.363 4.6 13.521 4 11.8 4 10.2c0-2.5.804-4.492 2.413-5.975C8.021 2.742 9.883 2 12 2c2.117 0 3.979.742 5.587 2.225C19.196 5.708 20 7.7 20 10.2c0 1.6-.6 3.321-1.8 5.163-1.2 1.841-3.017 3.837-5.45 5.987a1.2 1.2 0 0 1-.35.2c-.133.05-.267.075-.4.075Z" })
  }
);
export {
  t as default
};
