import { jsx as c } from "react/jsx-runtime";
const t = (e) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ c(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm5.62 4.914c-.407.367-.696.922-.868 1.664h-.046c-.063.258-.2.47-.41.639-.212.168-.45.252-.716.252-.312 0-.582-.115-.808-.346a1.137 1.137 0 0 1-.34-.826c0-.11.008-.188.024-.235.117-.984.562-1.804 1.335-2.46.844-.75 1.954-1.125 3.329-1.125 1.46 0 2.609.382 3.445 1.148.86.719 1.289 1.586 1.289 2.602 0 .539-.156 1.062-.469 1.57-.297.476-.969 1.156-2.015 2.039-.555.453-.883.805-.985 1.055-.07.164-.117.39-.14.68a7.418 7.418 0 0 0-.047.82c0 .203-.05.353-.147.45a.595.595 0 0 1-.334.177 4.08 4.08 0 0 1-.574.029h-.393c-.035 0-.1-.004-.193-.012a.532.532 0 0 1-.193-.04c-.036-.02-.086-.048-.153-.083a.342.342 0 0 1-.14-.123 1.107 1.107 0 0 1-.082-.187.777.777 0 0 1-.041-.258l-.024-.14v-.54c0-.68.117-1.266.352-1.758.258-.469.75-1.008 1.476-1.617.735-.594 1.172-.984 1.313-1.172.219-.265.328-.586.328-.96 0-.5-.195-.923-.586-1.266-.39-.344-.914-.516-1.57-.516-.625 0-1.164.18-1.617.54Zm.316 9.727A1.666 1.666 0 0 1 12 16.148c.469 0 .871.165 1.207.493.336.328.504.726.504 1.195 0 .46-.168.861-.504 1.201-.336.34-.738.51-1.207.51-.469 0-.871-.17-1.207-.51a1.652 1.652 0 0 1-.504-1.201c0-.469.168-.867.504-1.195Z"
      }
    )
  }
);
export {
  t as default
};
