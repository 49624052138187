import { jsxs as d, jsx as l } from "react/jsx-runtime";
const o = (e) => /* @__PURE__ */ d(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3.356 2.307C3.13 2.55 3 2.922 3 3.407v17.245c0 .486.13.857.364 1.09l.06.053 9.661-9.661v-.216L3.416 2.255l-.06.052Z"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m16.3 15.364-3.222-3.222v-.226L16.3 8.694l.07.042 3.812 2.167c1.09.614 1.09 1.63 0 2.252L16.37 15.32l-.07.043Z",
          fill: "#95A0B1"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m16.37 15.321-3.292-3.292-9.722 9.722c.356.38.953.423 1.62.052l11.394-6.482Z",
          fill: "#2B313B"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M16.37 8.736 4.976 2.264c-.667-.38-1.266-.328-1.62.053l9.722 9.712 3.292-3.293Z",
          fill: "#68778D"
        }
      )
    ]
  }
);
export {
  o as default
};
