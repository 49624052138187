import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M17 7v10h-1.79c-.45 0-.67.54-.35.85l2.79 2.79c.2.2.51.2.71 0l2.79-2.79a.5.5 0 0 0-.36-.85H19V6c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1s.45 1 1 1h3ZM7 17V7h1.79c.45 0 .67-.54.35-.85L6.35 3.36c-.2-.2-.51-.2-.71 0L2.85 6.15a.5.5 0 0 0 .36.85H5v11c0 .55.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1H7Z" })
);
export {
  c as default
};
