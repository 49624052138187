/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M6 6h12v12H6z" />
    <path d="M17.79 23 23 17.79h-5.21V23Z" fill="#EA4335" />
    <path d="M23 6.21h-5.21v11.58H23V6.21Z" fill="#FBBC04" />
    <path d="M17.79 17.79H6.21V23h11.58v-5.21Z" fill="#34A853" />
    <path
      d="M1 17.79v3.473C1 22.223 1.777 23 2.737 23H6.21v-5.21H1Z"
      fill="#188038"
    />
    <path
      d="M23 6.21V2.738C23 1.777 22.223 1 21.263 1H17.79v5.21H23Z"
      fill="#1967D2"
    />
    <path
      d="M17.79 1H2.736C1.777 1 1 1.777 1 2.737v15.052h5.21V6.212h11.58V1Z"
      fill="#4285F4"
    />
    <path
      d="M8.586 15.193c-.433-.293-.733-.72-.896-1.284l1.004-.414c.091.348.25.617.478.808.226.19.5.285.822.285.329 0 .61-.1.847-.3a.957.957 0 0 0 .354-.763.946.946 0 0 0-.373-.772c-.25-.2-.562-.3-.935-.3h-.58v-.994h.52c.322 0 .592-.087.812-.26.22-.175.33-.412.33-.714a.788.788 0 0 0-.295-.644c-.197-.161-.446-.242-.748-.242-.295 0-.53.078-.704.236a1.385 1.385 0 0 0-.379.58l-.994-.414c.132-.373.373-.703.728-.988.355-.285.808-.429 1.358-.429.406 0 .772.078 1.097.236.324.158.579.376.762.654.184.28.275.592.275.94 0 .354-.085.654-.256.9-.17.246-.38.434-.63.566v.06c.322.132.601.35.808.629.21.282.316.62.316 1.013 0 .394-.1.745-.3 1.054-.2.308-.476.551-.826.728a2.605 2.605 0 0 1-1.186.266 2.453 2.453 0 0 1-1.41-.437Zm6.17-4.985-1.103.798-.552-.837 1.979-1.427h.758v6.732h-1.082v-5.266Z"
      fill="#4285F4"
    />
  </svg>
);

export default GoogleCalendar;
