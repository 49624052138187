import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22Zm-1-2.05V18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 9 16v-1l-4.8-4.8c-.05.3-.096.6-.138.9-.041.3-.062.6-.062.9 0 2.017.663 3.783 1.988 5.3S8.983 19.7 11 19.95Zm6.9-2.55A7.982 7.982 0 0 0 20 12a7.847 7.847 0 0 0-1.362-4.475A7.704 7.704 0 0 0 15 4.6V5c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 13 7h-2v2c0 .283-.096.52-.287.712A.968.968 0 0 1 10 10H8v2h6a.97.97 0 0 1 .713.287A.97.97 0 0 1 15 13v3h1c.433 0 .825.129 1.175.387.35.259.592.596.725 1.013Z" })
  }
);
export {
  t as default
};
