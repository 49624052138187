import { jsx as c } from "react/jsx-runtime";
const A = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M2.15 15a.989.989 0 0 1-.775-.338.869.869 0 0 1-.225-.762c.317-1.65 1.2-2.88 2.65-3.688C5.25 9.404 6.833 9 8.55 9s3.3.404 4.75 1.212c1.45.809 2.333 2.038 2.65 3.688a.869.869 0 0 1-.225.762.989.989 0 0 1-.775.338H2.15Zm15.9 8v-2h1.4l1.4-14H11.3l-.1-.875a.993.993 0 0 1 .238-.787.923.923 0 0 1 .737-.338h3.875V2a.97.97 0 0 1 .287-.713A.97.97 0 0 1 17.05 1a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v3h3.9c.3 0 .55.108.75.325a.94.94 0 0 1 .25.775L21.4 21.55c-.05.4-.229.742-.537 1.025-.309.283-.68.425-1.113.425h-1.7Zm0-2h1.4-1.4Zm-4.725-8c-.483-.667-1.162-1.167-2.037-1.5A7.768 7.768 0 0 0 8.5 11a7.76 7.76 0 0 0-2.787.5c-.875.333-1.555.833-2.038 1.5h9.65ZM2 19a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 18c0-.283.096-.52.288-.712A.965.965 0 0 1 2 17h13c.283 0 .521.096.713.288A.967.967 0 0 1 16 18c0 .283-.096.52-.287.712A.968.968 0 0 1 15 19H2Zm0 4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 22c0-.283.096-.52.288-.712A.965.965 0 0 1 2 21h13c.283 0 .521.096.713.288A.967.967 0 0 1 16 22c0 .283-.096.52-.287.712A.968.968 0 0 1 15 23H2Z" })
  }
);
export {
  A as default
};
