/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraConcern = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2ZM13.5 7.5V12a1.5 1.5 0 0 1-3 0V7.5a1.5 1.5 0 0 1 3 0Zm-3 9a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 10.5 16.5Z"
    />
  </svg>
);

export default JiraConcern;
