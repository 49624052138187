import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-ms-access_svg__a",
      x1: 5.822,
      y1: 11.568,
      x2: 20.178,
      y2: 36.432,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { offset: 0, stopColor: "#b72c3f" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.5, stopColor: "#b12334" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#9d1623" })
  )),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M12 30v10c0 2.761 7.387 5 16.5 5S45 42.761 45 40V30z",
      fill: "#881421"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M12 19v11c0 2.761 7.387 5 16.5 5S45 32.761 45 30V19z",
      fill: "#af2031"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M12 8v11c0 2.761 7.387 5 16.5 5S45 21.761 45 19V8z",
      fill: "#c94f60"
    }
  ),
  /* @__PURE__ */ e.createElement("path", { fill: "none", d: "M0 0h48v48H0z" }),
  /* @__PURE__ */ e.createElement("ellipse", { cx: 28.5, cy: 8, rx: 16.5, ry: 5, fill: "#e08095" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M24.167 12H12v25h12.167A1.839 1.839 0 0 0 26 35.167V13.833A1.839 1.839 0 0 0 24.167 12z",
      opacity: 0.1
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M23.167 13H12v25h11.167A1.839 1.839 0 0 0 25 36.167V14.833A1.839 1.839 0 0 0 23.167 13z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M23.167 13H12v23h11.167A1.839 1.839 0 0 0 25 34.167V14.833A1.839 1.839 0 0 0 23.167 13z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M22.167 13H12v23h10.167A1.839 1.839 0 0 0 24 34.167V14.833A1.839 1.839 0 0 0 22.167 13z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 2,
      y: 13,
      width: 22,
      height: 22,
      rx: 1.833,
      fill: "url(#Icon-ms-access_svg__a)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M11.683 18.041h2.7l4.338 11.917h-2.55l-.956-2.842h-4.463l-.939 2.842H7.279zm2.958 7.239-1.387-4.313a5.654 5.654 0 0 1-.254-.973h-.05a5.506 5.506 0 0 1-.249 1l-1.4 4.288z",
      fill: "#fff"
    }
  )
);
export {
  l as default
};
