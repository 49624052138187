import { jsx as e } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14Zm-7 10.205v-6.65C12 5.7 12.75 5 13.665 5h1.667C16.25 5 17 5.7 17 6.555c0 .856-.75 1.556-1.667 1.556h-1.667v7.66c0 1.548-1.158 2.948-2.792 3.19-2.275.334-4.209-1.486-3.825-3.617.2-1.09 1.058-2.015 2.175-2.388a3.487 3.487 0 0 1 2.775.249Z"
      }
    )
  }
);
export {
  h as default
};
