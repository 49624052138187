import { jsx as l } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ l("path", { d: "M4.975 15.925 7.5 12 4.975 8.075c-.433-.667-.458-1.346-.075-2.038C5.283 5.346 5.875 5 6.675 5H15c.333 0 .646.075.938.225.291.15.529.358.712.625l3.525 5c.233.35.35.733.35 1.15 0 .417-.117.8-.35 1.15l-3.525 5c-.183.267-.42.475-.712.625-.292.15-.605.225-.938.225H6.675c-.8 0-1.392-.346-1.775-1.038-.383-.691-.358-1.37.075-2.037ZM6.65 17H15l3.55-5L15 7H6.65l3.25 5-3.25 5Zm3.25-5L6.65 7l3.25 5-3.25 5 3.25-5Z" })
  }
);
export {
  e as default
};
