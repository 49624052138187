import { jsxs as h, jsx as l } from "react/jsx-runtime";
const d = (e) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M11.206 7.124a.9.9 0 0 1 .67 1.082l-.21.894h2.15l.308-1.306a.9.9 0 0 1 1.752.412l-.21.894H17a.9.9 0 0 1 0 1.8h-1.758l-.517 2.2H16a.9.9 0 0 1 0 1.8h-1.699l-.425 1.806a.9.9 0 0 1-1.752-.412l.328-1.394h-2.15l-.426 1.806a.9.9 0 0 1-1.752-.412l.328-1.394H7a.9.9 0 1 1 0-1.8h1.875l.518-2.2H8a.9.9 0 1 1 0-1.8h1.817l.307-1.306a.9.9 0 0 1 1.082-.67Zm-.481 5.976.517-2.2h2.151l-.518 2.2h-2.15Z"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm14-2H5V5h14v14Z"
        }
      )
    ]
  }
);
export {
  d as default
};
