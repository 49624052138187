import { jsx as c } from "react/jsx-runtime";
const s = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M7 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 5 20c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 7 18c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.021-.588 1.413A1.925 1.925 0 0 1 7 22Zm10 0c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 15 20c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 17 18c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0 1 17 22ZM6.15 6l2.4 5h7l2.75-5H6.15ZM7 17c-.75 0-1.317-.33-1.7-.988-.383-.658-.4-1.312-.05-1.962L6.6 11.6 3 4H1.975a.926.926 0 0 1-.7-.288A.99.99 0 0 1 1 3c0-.283.096-.521.288-.713A.967.967 0 0 1 2 2h1.625c.183 0 .358.05.525.15.167.1.292.242.375.425L5.2 4h14.75c.45 0 .758.167.925.5.167.333.158.683-.025 1.05l-3.55 6.4a2.034 2.034 0 0 1-.725.775c-.3.183-.642.275-1.025.275H8.1L7 15h11.025c.283 0 .517.096.7.287.183.192.275.43.275.713s-.096.52-.288.712A.965.965 0 0 1 18 17H7Zm1.55-6h7-7Z" })
  }
);
export {
  s as default
};
