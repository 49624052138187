import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "m6.125 12.149-2.325-1a.894.894 0 0 1-.587-.725.978.978 0 0 1 .287-.9l3.15-3.15c.233-.233.508-.4.825-.5.317-.1.642-.117.975-.05l1.3.275c-.883 1.05-1.587 2.004-2.112 2.863-.525.858-1.03 1.92-1.513 3.187Zm14.7-9.75c.133 0 .267.033.4.1s.25.15.35.25c.1.1.183.217.25.35.067.133.1.267.1.4.067 1.55-.262 3.158-.987 4.825a14.422 14.422 0 0 1-3.063 4.475c-.9.9-1.767 1.62-2.6 2.162-.833.542-1.817 1.055-2.95 1.538a1.829 1.829 0 0 1-.662.125.805.805 0 0 1-.588-.25L7.95 13.249a.804.804 0 0 1-.25-.588c0-.225.042-.445.125-.662.483-1.117.996-2.096 1.538-2.938.541-.841 1.262-1.712 2.162-2.612A14.424 14.424 0 0 1 16 3.386c1.667-.725 3.275-1.054 4.825-.987Zm-6.35 7.45a1.92 1.92 0 0 0 1.413.575 1.92 1.92 0 0 0 1.412-.575 1.92 1.92 0 0 0 .575-1.413 1.92 1.92 0 0 0-.575-1.412 1.92 1.92 0 0 0-1.412-.575 1.92 1.92 0 0 0-1.413.575 1.92 1.92 0 0 0-.575 1.412c0 .559.192 1.03.575 1.413Zm-2.3 8.35c1.267-.483 2.333-.987 3.2-1.512s1.825-1.23 2.875-2.113l.25 1.3c.067.333.05.663-.05.988-.1.325-.267.604-.5.837l-3.15 3.15a.951.951 0 0 1-.9.275.924.924 0 0 1-.725-.6l-1-2.325Zm-8.125-2.15a2.94 2.94 0 0 1 2.125-.888 2.852 2.852 0 0 1 2.125.863c.583.583.875 1.292.875 2.125s-.292 1.542-.875 2.125c-.417.417-1.112.775-2.087 1.075-.975.3-2.321.567-4.038.8.233-1.717.5-3.058.8-4.025.3-.967.658-1.658 1.075-2.075Z" })
  }
);
export {
  s as default
};
