import { jsx as h } from "react/jsx-runtime";
const A = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M19 19H8c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 6 17V3c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 8 1h6.175a1.978 1.978 0 0 1 1.4.575l4.85 4.85a1.978 1.978 0 0 1 .575 1.4V17c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 19ZM14 3H8v14h11V8h-4a.967.967 0 0 1-.712-.287A.968.968 0 0 1 14 7V3ZM4 23c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 21V8c0-.283.096-.521.288-.713A.967.967 0 0 1 3 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 4 8v13h10c.283 0 .521.096.713.288A.967.967 0 0 1 15 22c0 .283-.096.52-.287.712A.968.968 0 0 1 14 23H4ZM8 3v5-5 14V3Z" })
  }
);
export {
  A as default
};
