import { jsx as c } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M3.4 17c-.383 0-.663-.126-.838-.376-.175-.25-.195-.558-.062-.925L5.375 8c.1-.283.292-.52.575-.713.283-.191.575-.287.875-.287.283 0 .571.096.863.287.291.192.487.43.587.713l2.9 7.725c.133.35.113.65-.062.9s-.455.375-.838.375a.956.956 0 0 1-.537-.175.904.904 0 0 1-.363-.45L8.75 14.6H4.9l-.575 1.75a.969.969 0 0 1-.362.462A.95.95 0 0 1 3.4 17Zm2.1-4.1h2.6L6.9 9.15h-.15L5.5 12.9Zm9.425 4.1c-.333 0-.621-.122-.863-.364a1.177 1.177 0 0 1-.362-.862c0-.183.042-.396.125-.638.083-.241.183-.428.3-.562L18.75 8.8H14.8a.865.865 0 0 1-.637-.263.865.865 0 0 1-.263-.637c0-.25.088-.462.263-.638A.867.867 0 0 1 14.8 7h4.925c.333 0 .621.12.863.362.241.242.362.538.362.888 0 .184-.037.392-.112.625a1.555 1.555 0 0 1-.288.55l-4.6 5.775h4.15a.87.87 0 0 1 .638.262.87.87 0 0 1 .262.638.87.87 0 0 1-.262.638.87.87 0 0 1-.638.262h-5.175ZM10.2 5c-.233 0-.392-.1-.475-.3-.083-.2-.042-.384.125-.55l1.8-1.8c.033-.034.15-.084.35-.15.033 0 .15.05.35.15l1.8 1.8c.167.166.208.35.125.55-.083.2-.242.3-.475.3h-3.6ZM12 21.8c-.033 0-.15-.05-.35-.15l-1.8-1.8c-.167-.167-.208-.35-.125-.55.083-.2.242-.3.475-.3h3.6c.233 0 .392.1.475.3.083.2.042.383-.125.55l-1.8 1.8c-.033.033-.15.083-.35.15Z" })
  }
);
export {
  a as default
};
