import * as React from "react";
const SvgIconInsertTable = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2l.01-12c0-1.1.89-2 1.99-2Zm0 6a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1.5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V10Zm0 5.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1V17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1.5Zm9-5.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1.5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V10Zm0 5.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1V17a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1.5Z"
    />
  </svg>
);
export default SvgIconInsertTable;
