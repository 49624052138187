/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Box = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.432 14.77a1.631 1.631 0 1 1-.002-3.262 1.631 1.631 0 0 1 .002 3.263ZM12.236 14.77a1.631 1.631 0 1 1 0-3.262 1.631 1.631 0 0 1 0 3.263Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.315 22h13.37A3.315 3.315 0 0 0 22 18.685V5.315A3.315 3.315 0 0 0 18.685 2H5.315A3.315 3.315 0 0 0 2 5.315v13.37A3.315 3.315 0 0 0 5.315 22Zm6.921-11.58c-1.04 0-1.946.586-2.402 1.446a2.718 2.718 0 0 0-4.035-.901V8.674a.543.543 0 0 0-1.086 0v4.51a2.719 2.719 0 0 0 5.12 1.231 2.72 2.72 0 1 0 2.403-3.994Zm6.938 4.529-1.478-1.813 1.48-1.816a.515.515 0 0 0-.125-.752.627.627 0 0 0-.821.099l-1.273 1.56-1.272-1.56a.626.626 0 0 0-.821-.1.515.515 0 0 0-.124.753l1.477 1.816-1.477 1.813a.515.515 0 0 0 .124.752.626.626 0 0 0 .82-.1l1.273-1.558 1.27 1.559c.201.232.564.278.822.1a.515.515 0 0 0 .125-.753Z"
    />
  </svg>
);

export default Box;
