import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "m19.3 16.5-3.2-3.2a4.25 4.25 0 0 0 .3-.862c.067-.292.1-.605.1-.938 0-1.25-.437-2.313-1.312-3.188S13.25 7 12 7c-.333 0-.646.033-.938.1a4.246 4.246 0 0 0-.862.3L7.65 4.85a11.107 11.107 0 0 1 2.1-.638A11.61 11.61 0 0 1 12 4c2.383 0 4.525.629 6.425 1.887 1.9 1.258 3.325 2.896 4.275 4.913.05.083.083.187.1.312s.025.254.025.388a1.987 1.987 0 0 1-.125.7 11.5 11.5 0 0 1-1.437 2.375A10.479 10.479 0 0 1 19.3 16.5Zm-.2 5.4-3.5-3.45c-.583.183-1.17.32-1.762.413-.592.09-1.205.137-1.838.137-2.383 0-4.525-.63-6.425-1.887-1.9-1.26-3.325-2.897-4.275-4.913a.813.813 0 0 1-.1-.313 2.919 2.919 0 0 1 0-.762.796.796 0 0 1 .1-.3c.35-.75.767-1.442 1.25-2.075A13.291 13.291 0 0 1 4.15 7L2.075 4.9a.933.933 0 0 1-.275-.688c0-.275.1-.512.3-.712a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l17 17a.98.98 0 0 1 .288.688.932.932 0 0 1-.288.712.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM12 16c.183 0 .354-.009.512-.025.159-.017.33-.05.513-.1l-5.4-5.4c-.05.183-.083.354-.1.513a4.895 4.895 0 0 0-.025.512c0 1.25.437 2.312 1.312 3.188C9.687 15.562 10.75 16 12 16Zm2.65-4.15-3-3c.95-.15 1.725.116 2.325.8.6.683.825 1.416.675 2.2Z" })
  }
);
export {
  s as default
};
