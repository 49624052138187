import { jsx as t } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M7.7 16.3a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l2.9-2.9 2.925 2.925a.89.89 0 0 0 .688.262.974.974 0 0 0 .687-.287.948.948 0 0 0 .275-.7.949.949 0 0 0-.275-.7L13.4 12l2.925-2.925a.891.891 0 0 0 .262-.688.975.975 0 0 0-.287-.687.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275L12 10.6 9.075 7.675a.893.893 0 0 0-.687-.263.98.98 0 0 0-.688.288.948.948 0 0 0-.275.7c0 .283.092.516.275.7l2.9 2.9-2.925 2.925a.89.89 0 0 0-.262.687.976.976 0 0 0 .287.688Z" })
  }
);
export {
  l as default
};
