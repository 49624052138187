import { jsx as t } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 20H4ZM20 8l-7.475 4.675c-.083.05-.171.087-.263.112a.981.981 0 0 1-.262.038.981.981 0 0 1-.262-.038 1.026 1.026 0 0 1-.263-.112L4 8v10h16V8Zm-8 3 8-5H4l8 5ZM4 8v.25-1.475.025V6v.8-.013V8.25 8v10V8Z" })
  }
);
export {
  c as default
};
