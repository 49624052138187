/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Hubspot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.135 8.244v-2.62a2.018 2.018 0 0 0 1.163-1.818v-.06a2.017 2.017 0 0 0-2.017-2.018h-.06a2.017 2.017 0 0 0-2.018 2.017v.06a2.017 2.017 0 0 0 1.163 1.819v2.62A5.713 5.713 0 0 0 12.65 9.44L5.465 3.843a2.25 2.25 0 0 0 .08-.566A2.273 2.273 0 1 0 3.27 5.546c.393-.002.78-.107 1.12-.305l7.074 5.506a5.732 5.732 0 0 0 .088 6.455L9.4 19.354a1.85 1.85 0 0 0-.538-.087 1.867 1.867 0 1 0 1.869 1.868 1.842 1.842 0 0 0-.088-.538l2.129-2.13a5.74 5.74 0 1 0 4.363-10.223m-.883 8.616a2.945 2.945 0 1 1 0-5.888 2.946 2.946 0 0 1 .004 5.887"
      fill="#FF7A59"
    />
  </svg>
);

export default Hubspot;
