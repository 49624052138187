import { jsx as a } from "react/jsx-runtime";
const l = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "m10.05 13.95-6.475-2.625a.891.891 0 0 1-.475-.387c-.1-.175-.15-.354-.15-.538 0-.183.054-.362.163-.537a.95.95 0 0 1 .487-.388l4.275-1.6L3.5 3.5a.93.93 0 0 1-.287-.713c.008-.274.112-.512.312-.712.2-.2.438-.3.713-.3.275 0 .512.1.712.3l16.975 17c.2.2.3.434.3.7 0 .267-.1.5-.3.7-.2.2-.438.3-.713.3a.973.973 0 0 1-.712-.3l-4.375-4.35-1.6 4.275a.956.956 0 0 1-.388.488 1.01 1.01 0 0 1-.537.162c-.183 0-.363-.05-.538-.15a.891.891 0 0 1-.387-.475L10.05 13.95Zm7.625-1.975-5.65-5.65L18.95 3.75a.816.816 0 0 1 .575-.038 1.163 1.163 0 0 1 .763.763.817.817 0 0 1-.038.575l-2.575 6.925Z" })
  }
);
export {
  l as default
};
