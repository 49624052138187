import { jsx as h } from "react/jsx-runtime";
const v = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M16 13.5c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075-.283-.283-.642-.425-1.075-.425-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425ZM5 19V5v14Zm0 2c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v2.5h-2V5H5v14h14v-2.5h2V19c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm8-4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 11 15V9c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 13 7h7c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v6c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 17h-7Z" })
  }
);
export {
  v as default
};
