import { jsx as e } from "react/jsx-runtime";
const d = (o) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm5.233 5.138 5.204 3.557a.787.787 0 0 1 0 1.3l-5.204 3.558A.79.79 0 0 1 9 15.903V8.787a.788.788 0 0 1 1.233-.65Z"
      }
    )
  }
);
export {
  d as default
};
