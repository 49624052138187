import { jsx as e } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm2.639 8.5C6.68 10.5 6 11.172 6 12c0 .834.67 1.5 1.496 1.5h9.008C17.32 13.5 18 12.828 18 12c0-.834-.67-1.5-1.495-1.5h-9.01Z"
      }
    )
  }
);
export {
  o as default
};
