import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3.5 10.5v-4c0-.55.45-1 1-1h15c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1h-15c-.55 0-1-.45-1-1Zm16 8h-15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h15c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1Z"
    }
  )
);
export {
  c as default
};
