import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5.85l-.925-.925a.933.933 0 0 1-.275-.688c0-.275.1-.512.3-.712.2-.2.437-.3.712-.3.275 0 .513.1.713.3L20.5 20.5a.98.98 0 0 1 .288.688.932.932 0 0 1-.288.712c-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3l-.925-.9H5Zm0-2h11.15l-4.875-4.875-.9.9a.918.918 0 0 1-.675.275.96.96 0 0 1-.7-.3.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l.875-.875L5 7.85V19Zm16-.85-2-2V13c0-.283.096-.521.288-.713A.967.967 0 0 1 20 12c.283 0 .52.096.712.287.192.192.288.43.288.713v5.15Zm-6.875-6.875-1.4-1.4L17.6 5H15a.965.965 0 0 1-.712-.288A.965.965 0 0 1 14 4c0-.283.096-.521.288-.713A.967.967 0 0 1 15 3h5c.283 0 .52.096.712.287.192.192.288.43.288.713v5c0 .283-.096.52-.288.712A.965.965 0 0 1 20 10a.965.965 0 0 1-.712-.288A.965.965 0 0 1 19 9V6.4l-4.875 4.875ZM7.85 5l-2-2H11a.97.97 0 0 1 .713.287A.97.97 0 0 1 12 4c0 .283-.096.52-.287.712A.968.968 0 0 1 11 5H7.85Z" })
  }
);
export {
  t as default
};
