import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M18 9a.968.968 0 0 1-.712-.287A.967.967 0 0 1 17 8V7h-1a.968.968 0 0 1-.713-.287A.967.967 0 0 1 15 6c0-.283.096-.52.287-.713A.968.968 0 0 1 16 5h1V4c0-.283.096-.52.288-.712A.968.968 0 0 1 18 3c.283 0 .52.096.712.288.192.191.288.429.288.712v1h1c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 20 7h-1v1c0 .283-.096.52-.288.713A.968.968 0 0 1 18 9ZM7 17h10c.2 0 .35-.092.45-.275a.44.44 0 0 0-.05-.525l-2.75-3.675a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2L11.25 16 9.4 13.525a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2l-2 2.675a.44.44 0 0 0-.05.525c.1.183.25.275.45.275Zm-2 4c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19V5c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 5 3h8c.283 0 .52.096.713.288.191.191.287.429.287.712v2a2 2 0 0 0 2 2 2 2 0 0 0 2 2h2c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21H5Z" })
  }
);
export {
  t as default
};
