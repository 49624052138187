/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Link = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm4.525 14.976c.965 0 1.79-.341 2.475-1.026l1.485-1.485c.14-.14.21-.306.21-.496 0-.189-.07-.354-.21-.494a.68.68 0 0 0-.495-.211.68.68 0 0 0-.495.21L11.01 15.96a2.025 2.025 0 0 1-1.485.618 2.025 2.025 0 0 1-1.485-.618 2.025 2.025 0 0 1-.618-1.485c0-.578.206-1.073.618-1.485l1.485-1.485c.14-.14.21-.305.21-.495s-.07-.355-.21-.495a.68.68 0 0 0-.495-.21.68.68 0 0 0-.495.21L7.05 12a3.373 3.373 0 0 0-1.027 2.474c0 .966.343 1.791 1.027 2.476a3.371 3.371 0 0 0 2.475 1.026Zm.99-3.786c.19 0 .355-.07.495-.21l2.97-2.97c.14-.14.21-.305.21-.495s-.07-.355-.21-.495a.679.679 0 0 0-.495-.21c-.19 0-.355.07-.495.21l-2.97 2.97a.677.677 0 0 0-.21.494c0 .19.07.356.21.496.14.14.305.21.495.21Zm4.455-.495c.19 0 .355-.07.495-.21L16.95 12a3.374 3.374 0 0 0 1.027-2.475c0-.965-.343-1.79-1.027-2.475a3.375 3.375 0 0 0-2.475-1.027c-.965 0-1.79.343-2.475 1.027l-1.485 1.485a.677.677 0 0 0-.21.495c0 .19.07.355.21.495.14.14.305.21.495.21s.355-.07.495-.21L12.99 8.04a2.025 2.025 0 0 1 1.485-.618c.577 0 1.072.206 1.485.618.412.413.618.908.618 1.485 0 .578-.206 1.073-.618 1.485l-1.485 1.485a.677.677 0 0 0-.21.495c0 .19.07.355.21.495.14.14.305.21.495.21Z"
    />
  </svg>
);

export default Link;
