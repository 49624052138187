import { jsxs as d, jsx as e } from "react/jsx-runtime";
const i = (l) => /* @__PURE__ */ d(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z",
          fill: "#F3B137"
        }
      ),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m15.437 11.695-5.204-3.557A.789.789 0 0 0 9 8.788v7.114a.788.788 0 0 0 1.233.65l5.204-3.557a.788.788 0 0 0 0-1.3Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  i as default
};
