/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Pages = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z"
      fill="#29C4A9"
    />
    <path
      d="M10.795 8.292a.809.809 0 0 1 .455.74v7.912c0 .578-.55.968-1.045.74l-3.75-1.726a.809.809 0 0 1-.455-.74V7.306c0-.578.55-.968 1.045-.74l3.75 1.726ZM13.205 8.292a.809.809 0 0 0-.455.74v7.912c0 .578.55.968 1.045.74l3.75-1.726a.809.809 0 0 0 .455-.74V7.306c0-.578-.55-.968-1.045-.74l-3.75 1.726Z"
      fill="#fff"
    />
  </svg>
);

export default Pages;
