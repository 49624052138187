import { jsx as a } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M5.646 6.103a.5.5 0 0 1 .708 0l3.042 3.043a.5.5 0 0 1-.353.854H7v6h6.5l2 2H7a2 2 0 0 1-2-2v-6H2.957a.5.5 0 0 1-.353-.854l3.042-3.043Zm12.708 11.793a.5.5 0 0 1-.707 0l-3.043-3.043a.5.5 0 0 1 .353-.853H17V8h-6.5l-2-2H17a2 2 0 0 1 2 2v6h2.043a.5.5 0 0 1 .354.854l-3.043 3.042Z" })
  }
);
export {
  h as default
};
