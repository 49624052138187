/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftOutlookAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 3h15A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3Z"
      fill="url(#microsoft-outlook-alt_svg__a)"
    />
    <path
      d="M7.687 9.29a4.362 4.362 0 0 1 1.714-1.892 5.18 5.18 0 0 1 2.722-.682 4.82 4.82 0 0 1 2.519.647 4.323 4.323 0 0 1 1.667 1.808c.401.828.601 1.739.584 2.659a6.16 6.16 0 0 1-.602 2.782 4.427 4.427 0 0 1-1.718 1.871 5.013 5.013 0 0 1-2.613.664 4.925 4.925 0 0 1-2.575-.654 4.39 4.39 0 0 1-1.689-1.812 5.697 5.697 0 0 1-.59-2.627 6.327 6.327 0 0 1 .581-2.764Zm1.827 4.443c.197.498.53.93.963 1.245.441.308.969.467 1.506.454a2.637 2.637 0 0 0 1.607-.468c.427-.315.752-.748.936-1.246a4.834 4.834 0 0 0 .298-1.737 5.288 5.288 0 0 0-.281-1.759 2.782 2.782 0 0 0-.907-1.297 2.49 2.49 0 0 0-1.593-.491 2.598 2.598 0 0 0-1.54.457c-.44.317-.78.753-.982 1.256a4.99 4.99 0 0 0-.007 3.588v-.002Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="microsoft-outlook-alt_svg__a"
        x1={6.127}
        y1={1.828}
        x2={17.873}
        y2={22.172}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1784D9" />
        <stop offset={0.5} stopColor="#107AD5" />
        <stop offset={1} stopColor="#0A63C9" />
      </linearGradient>
    </defs>
  </svg>
);

export default MicrosoftOutlookAlt;
