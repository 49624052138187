/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraSuggestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.444 12.865c-.154.197-.309.403-.434.635h-2.013a5.555 5.555 0 0 0-.467-.633l-.357-.441c-.158-.19-.314-.381-.464-.578-.615-.81-.826-1.675-.646-2.646.253-1.356 1.506-2.382 2.935-2.388 1.408.006 2.66 1.032 2.914 2.388.181.97-.03 1.836-.647 2.646-.125.165-.256.325-.387.485l-.07.087-.364.445Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm4.6 11.925c.106.131.221.275.28.392V16.5a1.5 1.5 0 0 0 1.5 1.5h1.5a1.5 1.5 0 0 0 1.5-1.5v-1.939a1.09 1.09 0 0 1 .018-.146c.034-.188.193-.4.367-.618l.344-.425c.168-.202.334-.406.493-.615.875-1.15 1.186-2.439.927-3.83-.388-2.086-2.232-3.604-4.416-3.613-2.15.01-3.993 1.527-4.382 3.612-.259 1.392.053 2.68.927 3.831.162.212.33.416.5.621l.433.536.01.01Z"
    />
  </svg>
);

export default JiraSuggestion;
