import { jsx as c } from "react/jsx-runtime";
const t = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M6 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 2h8.175a1.978 1.978 0 0 1 1.4.575l3.85 3.85a1.978 1.978 0 0 1 .575 1.4V20c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 22H6Zm9-14h3l-4-4v3c0 .283.096.521.288.713A.967.967 0 0 0 15 8Zm-3 11c1.117 0 2.063-.392 2.838-1.175A3.873 3.873 0 0 0 16 15v-3a.97.97 0 0 0-.287-.713A.97.97 0 0 0 15 11a.967.967 0 0 0-.712.287A.968.968 0 0 0 14 12v3c0 .55-.192 1.021-.575 1.413A1.915 1.915 0 0 1 12 17c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 15V9.5c0-.15.05-.271.15-.363A.503.503 0 0 1 10.5 9c.15 0 .271.046.363.137A.493.493 0 0 1 11 9.5V14c0 .283.096.52.288.712A.965.965 0 0 0 12 15a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 14V9.5c0-.7-.242-1.292-.725-1.775C11.792 7.242 11.2 7 10.5 7s-1.292.242-1.775.725C8.242 8.208 8 8.8 8 9.5V15c0 1.1.392 2.042 1.175 2.825C9.958 18.608 10.9 19 12 19Z" })
  }
);
export {
  t as default
};
