import { jsx as a } from "react/jsx-runtime";
const o = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm10.102 9.979a.5.5 0 0 1 .78.012l2.49 3.202a.5.5 0 0 1-.394.807H6a.5.5 0 0 1-.4-.8l3.507-4.676a.5.5 0 0 1 .795-.006l2.716 3.5a.5.5 0 0 0 .78.013l1.704-2.052Z"
      }
    )
  }
);
export {
  o as default
};
