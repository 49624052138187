import { jsx as t } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ t("path", { d: "M12 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 21v-4H7.5c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 6 15.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 7.5 14H11v-4H4.5c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 3 8.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 4.5 7H11V3c0-.283.096-.521.288-.713A.967.967 0 0 1 12 2a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 3v4h6.5c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 19.5 10H13v4h3.5c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 16.5 17H13v4c0 .283-.096.52-.287.712A.968.968 0 0 1 12 22Z" })
  }
);
export {
  h as default
};
