import { jsx as t } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "m9.189 9.74 6.055 6.093-4.485 4.493c-.449.45-.977.674-1.584.674-.607 0-1.135-.225-1.584-.674l-.14.112c-.206.169-.43.3-.673.393-.243.094-.495.14-.757.14h-3.42c-.261 0-.443-.111-.546-.336-.103-.225-.061-.43.126-.618l2.579-2.555a2.308 2.308 0 0 1-.7-1.615 2.11 2.11 0 0 1 .644-1.614l4.485-4.494Zm1.57-1.601 4.485-4.465c.449-.45.977-.674 1.584-.674.607 0 1.135.225 1.584.674l2.915 2.892c.449.45.673.978.673 1.587 0 .608-.224 1.137-.673 1.586l-4.485 4.493-6.083-6.093Z" })
  }
);
export {
  c as default
};
