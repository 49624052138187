/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Gmail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.068 20.627h3.66v-8.886L.5 7.82v11.238c0 .868.703 1.568 1.568 1.568Z" />
    <path
      d="M18.273 20.627h3.659c.867 0 1.568-.703 1.568-1.568V7.82l-5.227 3.92"
      fill="#68778D"
    />
    <path
      d="M18.273 4.945v6.796L23.5 7.82V5.73c0-1.94-2.214-3.045-3.764-1.882"
      fill="#95A0B1"
    />
    <path
      d="M5.727 11.74V4.946L12 9.65l6.273-4.705v6.796L12 16.445"
      fill="#596578"
    />
    <path
      d="M.5 5.73v2.09l5.227 3.92V4.946L4.264 3.848C2.71 2.685.5 3.79.5 5.73"
      fill="#2B313B"
    />
  </svg>
);

export default Gmail;
