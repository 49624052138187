/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Okta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.047 13.384a.244.244 0 0 0-.418.171h-.006v1.6c0 .105-.093.21-.236.21h-.924c-.11 0-.242-.045-.242-.204V8.198c0-.094.077-.198.236-.198h.924c.105 0 .237.06.237.22v3.14c0 .226.275.336.429.171.432-.462 1.07-1.15 1.343-1.443l.13-.141c.017-.022.045-.044.11-.066.034-.011.066-.011.11-.011h1.133c.232 0 .298.253.193.385l-1.639 1.831c-.27.287-.297.396-.06.677l.11.11 2.067 2.118c.105.132.039.39-.187.39H11.11c-.05 0-.094 0-.121-.011a.185.185 0 0 1-.091-.058l-.008-.008-.031-.033c-.19-.2-1.185-1.25-1.811-1.887Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12.625A2.765 2.765 0 0 1 3.767 9.86a2.765 2.765 0 0 1 2.766 2.766 2.765 2.765 0 0 1-2.767 2.767A2.765 2.765 0 0 1 1 12.625Zm1.38 0a1.387 1.387 0 0 0 2.772 0 1.387 1.387 0 0 0-2.772 0Z"
    />
    <path d="m16.174 15.128-.098-.924a.22.22 0 0 0-.259-.193 1.386 1.386 0 0 1-1.595-1.293V11.51c0-.149.11-.27.258-.27h1.238a.23.23 0 0 0 .22-.236v-.874c0-.171-.11-.259-.209-.259h-1.248a.257.257 0 0 1-.264-.247V8.22A.223.223 0 0 0 13.98 8h-.918c-.116 0-.226.072-.226.214v4.505a2.764 2.764 0 0 0 3.135 2.662.225.225 0 0 0 .203-.253Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.939 12.636c0 1.051.12 1.332.901 1.332.094 0 .16.093.16.175v.985c0 .265-.53.26-.681.259h-.023c-.654-.006-1.072-.27-1.331-.666A2.764 2.764 0 0 1 16.4 12.62a2.765 2.765 0 0 1 4.13-2.403V10.1c0-.143.127-.236.242-.236h.925c.175 0 .242.148.242.236v2.535Zm-4.153-.01c0 .764.621 1.386 1.386 1.386.759 0 1.38-.622 1.386-1.386a1.387 1.387 0 0 0-2.772 0Z"
    />
  </svg>
);

export default Okta;
