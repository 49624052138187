import { jsx as t } from "react/jsx-runtime";
const a = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h11.175a1.978 1.978 0 0 1 1.4.575l2.85 2.85a1.978 1.978 0 0 1 .575 1.4V19c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm7-3c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 15 15c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 12 12c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 9 15c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 12 18Zm-5-8h7a.968.968 0 0 0 .713-.288A.967.967 0 0 0 15 9V7a.97.97 0 0 0-.287-.713A.97.97 0 0 0 14 6H7a.97.97 0 0 0-.713.287A.97.97 0 0 0 6 7v2c0 .283.096.52.287.712.192.192.43.288.713.288Z" })
  }
);
export {
  a as default
};
