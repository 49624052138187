import { jsx as c } from "react/jsx-runtime";
const r = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12.025 21a.967.967 0 0 1-.713-.288.968.968 0 0 1-.287-.712v-1.15a5.19 5.19 0 0 1-1.975-.875c-.567-.417-1.025-1-1.375-1.75a.847.847 0 0 1-.013-.738c.109-.258.305-.445.588-.562a.881.881 0 0 1 .725.012c.25.109.442.288.575.538.283.5.642.88 1.075 1.138.433.258.967.387 1.6.387.683 0 1.263-.154 1.738-.462.475-.309.712-.788.712-1.438 0-.583-.183-1.046-.55-1.387-.367-.342-1.217-.73-2.55-1.163-1.433-.45-2.417-.988-2.95-1.613-.533-.624-.8-1.387-.8-2.287 0-1.083.35-1.925 1.05-2.525.7-.6 1.417-.942 2.15-1.025V4c0-.283.096-.52.287-.712A.967.967 0 0 1 12.025 3c.283 0 .52.096.713.288.191.191.287.429.287.712v1.1c.633.1 1.183.304 1.65.613.467.308.85.687 1.15 1.137.15.217.18.458.088.725a.937.937 0 0 1-.563.575.949.949 0 0 1-.725.012 1.814 1.814 0 0 1-.7-.487 2.228 2.228 0 0 0-.763-.537c-.291-.125-.654-.188-1.087-.188-.733 0-1.292.162-1.675.487-.383.325-.575.73-.575 1.213 0 .55.25.983.75 1.3.5.317 1.367.65 2.6 1 1.15.333 2.02.863 2.612 1.588.592.724.888 1.562.888 2.512 0 1.183-.35 2.083-1.05 2.7-.7.617-1.567 1-2.6 1.15V20c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288Z" })
  }
);
export {
  r as default
};
