import { jsxs as t, jsx as e } from "react/jsx-runtime";
const i = (s) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: [
      /* @__PURE__ */ e("path", { d: "M8 11a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8Z" }),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M8.173 2.761a10 10 0 1 1 7.654 18.478A10 10 0 0 1 8.173 2.761ZM4 12c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8-8 3.59-8 8Z"
        }
      )
    ]
  }
);
export {
  i as default
};
