import { jsxs as s, jsx as o } from "react/jsx-runtime";
const p = (e) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ o(
        "path",
        {
          d: "M23 9.663c0 5.35-2.588 9.549-6.155 11.661A10.282 10.282 0 0 1 11.54 22.8c-.866 0-1.727-.114-2.563-.336h-.012c-4.217-.973-7.52-4.404-7.925-8.715-.502-5.422 3.744-10.19 9.483-10.677 2.334-.2 4.671.355 6.657 1.584.905-1.069 1.683-2.209 2.197-3.456a33.49 33.49 0 0 1 1.206 2.58 9.44 9.44 0 0 0 .905-1.429C22.546 4.948 23 7.407 23 9.663Z",
          fill: "url(#happeo_svg__a)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M21.503 2.395c5.137 12.766-3.918 22.326-12.528 20.072C2.501 11.69 17.597 9.999 21.503 2.395Z",
          fill: "url(#happeo_svg__b)"
        }
      ),
      /* @__PURE__ */ s("defs", { children: [
        /* @__PURE__ */ s(
          "linearGradient",
          {
            id: "happeo_svg__a",
            x1: 0.962,
            y1: 11.627,
            x2: 22.957,
            y2: 11.627,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#00F4E0" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#00E5BD" })
            ]
          }
        ),
        /* @__PURE__ */ s(
          "linearGradient",
          {
            id: "happeo_svg__b",
            x1: 15.032,
            y1: 22.514,
            x2: 15.032,
            y2: 2.112,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#00DECA" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#00BA9A" })
            ]
          }
        )
      ] })
    ]
  }
);
export {
  p as default
};
