import { jsx as a } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M13.35 22c-.4 0-.687-.129-.862-.387-.175-.259-.196-.58-.063-.963l3.65-9.675c.1-.267.288-.496.563-.688.275-.191.562-.287.862-.287.283 0 .567.096.85.287.283.192.475.421.575.688l3.65 9.675c.133.383.113.704-.062.963-.175.258-.471.387-.888.387a.795.795 0 0 1-.5-.175 1.006 1.006 0 0 1-.325-.425l-.85-2.45H15.1l-.875 2.45a.947.947 0 0 1-.35.425.901.901 0 0 1-.525.175Zm2.35-4.8h3.6l-1.75-4.95h-.1L15.7 17.2ZM7.15 8.55c.267.483.55.929.85 1.337.3.409.65.83 1.05 1.263.733-.8 1.342-1.62 1.825-2.462.483-.842.892-1.738 1.225-2.688H2a.967.967 0 0 1-.712-.287A.968.968 0 0 1 1 5c0-.283.096-.521.288-.713A.967.967 0 0 1 2 4h6V3c0-.283.096-.521.288-.713A.967.967 0 0 1 9 2a.97.97 0 0 1 .713.287A.97.97 0 0 1 10 3v1h6c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 16 6h-1.9a18.153 18.153 0 0 1-1.425 3.45 16.134 16.134 0 0 1-2.225 3.15l2.4 2.45-.75 2.05L9 14l-4.3 4.3a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l4.35-4.35a23.252 23.252 0 0 1-1.25-1.563 12.516 12.516 0 0 1-1.025-1.662c-.183-.35-.192-.658-.025-.925s.458-.4.875-.4c.167 0 .342.054.525.162.183.109.317.238.4.388Z" })
  }
);
export {
  h as default
};
