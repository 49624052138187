import { jsxs as o, jsx as t } from "react/jsx-runtime";
const e = (s) => /* @__PURE__ */ o(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M4.5 3h15A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3Z",
          fill: "url(#microsoft-excel-alt_svg__a)"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "m7.645 16.875 3.155-4.889-2.89-4.861h2.325l1.577 3.109c.146.295.245.514.3.66h.02c.104-.237.213-.465.327-.687l1.687-3.08h2.134l-2.964 4.832 3.04 4.916h-2.272l-1.822-3.413a2.865 2.865 0 0 1-.218-.456h-.027a2.16 2.16 0 0 1-.21.442L9.93 16.875H7.645Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ o(
        "linearGradient",
        {
          id: "microsoft-excel-alt_svg__a",
          x1: 6.127,
          y1: 1.828,
          x2: 17.873,
          y2: 22.172,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ t("stop", { stopColor: "#18884F" }),
            /* @__PURE__ */ t("stop", { offset: 0.5, stopColor: "#117E43" }),
            /* @__PURE__ */ t("stop", { offset: 1, stopColor: "#0B6631" })
          ]
        }
      ) })
    ]
  }
);
export {
  e as default
};
