/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleMaps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.43 17.538a22.36 22.36 0 0 1 1.795 2.678c.457.876.646 1.468.986 2.528.199.58.388.756.785.756.435 0 .634-.296.786-.756.318-.995.563-1.751.95-2.472 1.498-2.846 3.938-4.868 5.312-7.64 0 0 .904-1.692.904-4.062 0-2.21-.891-3.745-.891-3.745L8.435 17.557l-.006-.018Z"
      fill="#596578"
    />
    <path
      d="M4.826 12.333c.857 1.966 2.489 3.689 3.603 5.205l5.905-7.058s-.834 1.1-2.34 1.1A3.04 3.04 0 0 1 8.95 8.53c0-1.172.693-1.985.693-1.985-4.346.652-4.104 1.713-4.829 5.785l.013.004Z"
      fill="#95A0B1"
    />
    <path
      d="M14.409.869c1.968.638 3.64 1.985 4.642 3.95l-4.717 5.674s.693-.817.693-1.985c0-1.74-1.456-3.052-3.027-3.052-1.491 0-2.34 1.088-2.34 1.088.363-.831 4.104-5.392 4.754-5.673L14.41.869Z"
      fill="#68778D"
    />
    <path d="M5.904 3.365C7.077 1.957 9.134.5 11.976.5c1.372 0 2.414.367 2.414.367L9.655 6.54c-.32-.174-3.436-2.621-3.751-3.183v.008Z" />
    <path
      d="M4.827 12.333s-.775-1.55-.775-3.782c0-2.116.821-3.97 1.857-5.168L9.66 6.566l-4.828 5.767h-.005Z"
      fill="#2B313B"
    />
  </svg>
);

export default GoogleMaps;
