import * as React from "react";
const SvgIconPagesDuotone = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M13 7.842a1 1 0 0 1 .584-.91l5-2.285a1 1 0 0 1 1.416.91v10.6a1 1 0 0 1-.584.91l-5 2.286a1 1 0 0 1-1.416-.91v-10.6Z"
      fill="#8997A1"
    />
    <path
      d="M11 7.842a1 1 0 0 0-.584-.91l-5-2.285A1 1 0 0 0 4 5.557v10.6a1 1 0 0 0 .584.91l5 2.286a1 1 0 0 0 1.416-.91v-10.6Z"
      fill="#434F59"
    />
  </svg>
);
export default SvgIconPagesDuotone;
