import { jsx as t } from "react/jsx-runtime";
const r = (o) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ t("path", { d: "M12 21.5c-2.217 0-4.104-.767-5.662-2.3C4.779 17.667 4 15.8 4 13.6c0-1.05.204-2.054.612-3.012A7.784 7.784 0 0 1 6.35 8.05L12 2.5l5.65 5.55c.75.733 1.33 1.58 1.738 2.537.408.959.612 1.963.612 3.013 0 2.2-.78 4.067-2.337 5.6-1.559 1.533-3.446 2.3-5.663 2.3Z" })
  }
);
export {
  r as default
};
