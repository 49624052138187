import { defineMessages } from "react-intl";

export default defineMessages({
  typeaheadNoResults: {
    id: "typeahead.noResults",
    defaultMessage: "No results found",
  },
  typeaheadNoResultsDetails: {
    id: "typeahead.noResultsDetails",
    // eslint-disable-next-line quotes
    defaultMessage: 'We found no results for "{query}"',
  },
  typeaheadServerError: {
    id: "typeahead.serverError",
    defaultMessage: "Server Error",
  },
  typeaheadServerErrorDetails: {
    id: "typeahead.serverErrorDetails",
    defaultMessage:
      "Oh no, there seems to be an issue with our servers. Please try again later",
  },
  typeaheadSearching: {
    id: "typeahead.searching",
    defaultMessage: "Searching...",
  },
  typeaheadSearchingDetails: {
    id: "typeahead.searchingDetails",
    // eslint-disable-next-line quotes
    defaultMessage: 'Please wait as we try to find "{query}"',
  },
});
