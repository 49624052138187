import { jsxs as t, jsx as c } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ c("path", { d: "M4.588 21.413C4.979 21.804 5.45 22 6 22h4.177c.167-.665.386-1.338.671-2H6V4h7v4c0 .283.096.52.288.712A.965.965 0 0 0 14 9h4v4.088c.47-.64 1.22-1.02 2-1.074V8.825a1.978 1.978 0 0 0-.575-1.4l-4.85-4.85a1.978 1.978 0 0 0-1.4-.575H6c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 4 4v16c0 .55.196 1.021.588 1.413ZM18 16.036a10.47 10.47 0 0 1 1.503-.319l-.107.015-.012.002c-.49.073-.95.175-1.384.302Z" }),
      /* @__PURE__ */ c("path", { d: "M19.503 14.665v1.052C14.728 16.38 12.683 19.69 12 23c1.705-2.317 4.093-3.377 7.503-3.377v1.12c0 .589.737.88 1.167.463l3.13-3.039a.646.646 0 0 0 0-.934l-3.13-3.038c-.43-.417-1.167-.12-1.167.47Z" })
    ]
  }
);
export {
  a as default
};
