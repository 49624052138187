import * as React from "react";
const SvgIconCircleBlank = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.668 10.001c0-4.6 3.733-8.333 8.333-8.333s8.334 3.733 8.334 8.333S14.6 18.335 10 18.335A8.336 8.336 0 0 1 1.668 10Zm1.667 0A6.665 6.665 0 0 0 10 16.668a6.665 6.665 0 0 0 6.667-6.667A6.665 6.665 0 0 0 10 3.335 6.665 6.665 0 0 0 3.335 10Z"
    />
  </svg>
);
export default SvgIconCircleBlank;
