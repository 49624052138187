import { jsxs as l, jsx as a } from "react/jsx-runtime";
const s = (h) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          d: "M4.5 3h15A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3Z",
          fill: "#0078D4"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M11.804 11.176c.105.217.189.443.251.675h.024c.056-.228.138-.449.243-.66l2.275-4.285a1 1 0 1 1 1.751.968l-3.148 5.48a1 1 0 0 0-.133.498v2.773a1 1 0 0 1-1 1h-.204a1 1 0 0 1-1-1v-2.74a1 1 0 0 0-.127-.488l-3.09-5.534a1 1 0 0 1 .873-1.488h.197a1 1 0 0 1 .888.541l2.2 4.26Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  s as default
};
