import { jsx as a } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M6 8v3c0 .283.096.521.287.713A.96.96 0 0 0 7 12a.99.99 0 0 0 .95-.666c.033-.104.05-.215.05-.334V8h3c.283 0 .52-.096.713-.287A.968.968 0 0 0 12 7a.968.968 0 0 0-.287-.713A.968.968 0 0 0 11 6H8V3a.96.96 0 0 0-.287-.713A.977.977 0 0 0 7 2c-.156 0-.298.03-.426.088a.96.96 0 0 0-.287.2A.968.968 0 0 0 6 3v3H3a.968.968 0 0 0-.713.287A.968.968 0 0 0 2 7c0 .176.037.334.111.477a.966.966 0 0 0 .176.236C2.48 7.904 2.717 8 3 8h3ZM16.422 17.836 14.3 19.957c-.2.201-.3.436-.3.707 0 .271.1.508.3.707.2.201.437.3.708.3a1 1 0 0 0 .256-.03.862.862 0 0 0 .212-.088c.085-.047.164-.108.239-.182l2.121-2.121 2.121 2.121c.2.201.436.3.707.3.271 0 .507-.099.708-.3.2-.2.3-.435.3-.707 0-.271-.1-.506-.3-.707l-2.122-2.121 2.122-2.121c.2-.201.3-.436.3-.707 0-.272-.1-.508-.3-.707a.965.965 0 0 0-.707-.301c-.272 0-.507.1-.708.3l-2.12 2.122-2.122-2.121a.964.964 0 0 0-.707-.301c-.271 0-.507.1-.707.3-.2.2-.301.436-.301.708 0 .271.1.506.3.707l2.122 2.12ZM20 7a2 2 0 1 1-3.999.001A2 2 0 0 1 20 7ZM7 20a2 2 0 1 0 .001-3.999A2 2 0 0 0 7 20Z" })
  }
);
export {
  h as default
};
