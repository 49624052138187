import { jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.2 8h-2.4c-.873 0-1.408.002-1.808.034a2.563 2.563 0 0 0-.367.05.603.603 0 0 0-.079.025 1 1 0 0 0-.443.453.603.603 0 0 0-.018.063 2.563 2.563 0 0 0-.05.367C8.001 9.392 8 9.927 8 10.8v2.4c0 .873.002 1.408.034 1.808.015.186.035.299.05.367a.597.597 0 0 0 .025.079 1 1 0 0 0 .437.437s.005.003.016.006c.01.004.03.01.063.018.068.016.181.036.367.05.4.033.935.035 1.808.035h2.4c.873 0 1.408-.002 1.808-.034.186-.015.299-.035.367-.05a.59.59 0 0 0 .079-.025 1 1 0 0 0 .443-.453.59.59 0 0 0 .018-.063c.016-.069.036-.181.05-.367.033-.4.035-.935.035-1.808v-2.4c0-.873-.002-1.408-.034-1.808a2.576 2.576 0 0 0-.05-.367.597.597 0 0 0-.019-.063l-.006-.016a1 1 0 0 0-.453-.443.597.597 0 0 0-.063-.018 2.564 2.564 0 0 0-.367-.05C14.607 8.001 14.072 8 13.2 8Zm-6.873-.362C6 8.28 6 9.12 6 10.8v2.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C8.28 18 9.12 18 10.8 18h2.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C18 15.72 18 14.88 18 13.2v-2.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C15.72 6 14.88 6 13.2 6h-2.4c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311Z"
      }
    )
  }
);
export {
  h as default
};
