import { jsxs as e, jsx as l } from "react/jsx-runtime";
const i = (a) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z",
          fill: "#904EE2"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "m11.874 14.666 3-4.5a.75.75 0 0 0-1.248-.833l-2.493 3.74-.854-.854a.75.75 0 0 0-1.06 1.06l1.5 1.5c.01.01.023.018.036.024.01.005.018.01.027.017.008.007.015.016.022.024.009.01.018.022.03.03a.07.07 0 0 0 .025.008c.008.001.017.003.025.007.114.065.235.11.364.11V15h.002c.11 0 .216-.031.316-.078a.324.324 0 0 0 .058-.037l.03-.021.044-.027c.03-.016.058-.032.081-.057a.172.172 0 0 0 .031-.044l.019-.03a.103.103 0 0 1 .021-.018c.009-.006.017-.013.024-.022Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M12 4.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM6 12a6 6 0 1 1 12 0 6 6 0 0 1-12 0Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  i as default
};
