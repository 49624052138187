import * as e from "react";
const o = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 9.265c0-3.87 3.13-7 7-7s7 3.13 7 7c0 4.17-4.42 9.92-6.23 12.11-.4.48-1.13.48-1.53 0C9.42 19.185 5 13.435 5 9.265Zm4.5 0a2.5 2.5 0 0 0 5 0 2.5 2.5 0 0 0-5 0Z"
    }
  )
);
export {
  o as default
};
