import { jsx as a } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3Zm-7.445 8.85a3.784 3.784 0 0 0-.251-.674l-2.2-4.26a1 1 0 0 0-.888-.541h-.197a1 1 0 0 0-.873 1.488l3.09 5.534a1 1 0 0 1 .127.487v2.74a1.001 1.001 0 0 0 1 1.001h.204a1 1 0 0 0 1-1v-2.773a1 1 0 0 1 .133-.499l3.148-5.48a1.001 1.001 0 1 0-1.75-.967l-2.276 4.286c-.105.21-.187.431-.243.659h-.024Z"
      }
    )
  }
);
export {
  h as default
};
