import { jsxs as c, jsx as e } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ e("path", { d: "M15 13a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" }),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4 21c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 19V7c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 5h3.15L8.4 3.65c.183-.217.404-.38.662-.488A2.09 2.09 0 0 1 9.875 3h4.25c.283 0 .554.054.813.162.258.109.479.271.662.488L16.85 5H20c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 21H4Zm13-8a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
        }
      )
    ]
  }
);
export {
  h as default
};
