import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M16.3 13.276a.949.949 0 0 1-.275-.7c0-.284.092-.517.275-.7L18.175 10H12a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 9c0-.284.096-.521.288-.713A.967.967 0 0 1 12 8h6.175L16.3 6.126c-.183-.184-.275-.421-.275-.713s.092-.529.275-.712a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l3.6 3.6c.1.1.171.208.213.325.041.116.062.241.062.375 0 .133-.02.258-.062.375a.883.883 0 0 1-.213.325l-3.6 3.6a.907.907 0 0 1-.7.262.99.99 0 0 1-.7-.287ZM6.3 19.3l-3.6-3.6a.872.872 0 0 1-.212-.325A1.1 1.1 0 0 1 2.425 15c0-.134.021-.259.063-.375A.871.871 0 0 1 2.7 14.3l3.6-3.6a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275.183.183.275.42.275.712s-.092.53-.275.713L5.825 14H12a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 15c0 .283-.096.52-.287.712A.968.968 0 0 1 12 16H5.825L7.7 17.876a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.99.99 0 0 1-.7.287.907.907 0 0 1-.7-.262Z" })
  }
);
export {
  l as default
};
