import { jsx as a } from "react/jsx-runtime";
const e = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M12.7 9.2a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-3.1 3.1a.933.933 0 0 0-.275.688c0 .275.1.512.3.712a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275L12 11.325l2.4 2.4a.932.932 0 0 0 .687.275c.275 0 .513-.1.713-.3a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7l-3.1-3.1ZM12 2c1.383 0 2.683.263 3.9.788a10.091 10.091 0 0 1 3.175 2.137c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.138A9.743 9.743 0 0 1 12 22a9.742 9.742 0 0 1-3.9-.787 10.105 10.105 0 0 1-3.175-2.138c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.732 9.732 0 0 1 12 2Z" })
  }
);
export {
  e as default
};
