import { jsx as a } from "react/jsx-runtime";
const o = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "m18.434 20.301 2.61-2.6c.09-.08.162-.179.21-.29.046-.131.067-.27.06-.41 0-.129-.02-.257-.06-.38a.892.892 0 0 0-.21-.32l-2.58-2.58a1 1 0 0 0-.71-.3.94.94 0 0 0-.71.3 1 1 0 0 0-.28.72.9.9 0 0 0 .28.68l.87.88a7.72 7.72 0 0 1-4-.83 10.994 10.994 0 0 1-2-1.72l-.71-.74a6.8 6.8 0 0 0-.84-.71 6.8 6.8 0 0 0 .84-.71l.71-.74a11.485 11.485 0 0 1 2-1.73 7.84 7.84 0 0 1 4-.82l-.87.87a.941.941 0 0 0-.28.69 1 1 0 0 0 .28.71.94.94 0 0 0 .71.3 1 1 0 0 0 .71-.3l2.58-2.57c.09-.08.162-.179.21-.29.046-.131.067-.27.06-.41 0-.129-.02-.257-.06-.38a.892.892 0 0 0-.21-.32l-2.61-2.6a1 1 0 0 0-.68-.29.9.9 0 0 0-.71.29.93.93 0 0 0-.28.7.93.93 0 0 0 .28.7l.87.9c-2.38 0-4.076.367-5.09 1.1-.915.6-1.757 1.305-2.51 2.1a9.181 9.181 0 0 1-1.41 1.3 3.87 3.87 0 0 1-2.22.5h-3a.94.94 0 0 0-.71.29 1 1 0 0 0-.29.71 1 1 0 0 0 1 1h3a3.87 3.87 0 0 1 2.22.5 9.18 9.18 0 0 1 1.41 1.3 14.118 14.118 0 0 0 2.46 2.1c1.007.667 2.704 1.034 5.09 1.1l-.87.9a1 1 0 0 0 .71 1.69 1.001 1.001 0 0 0 .73-.29Z" })
  }
);
export {
  o as default
};
