import { jsx as c } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "m9.05 18.95-4-4c-.05.633-.063 1.292-.037 1.975.024.683.087 1.292.187 1.825.383.117.962.192 1.737.225.776.033 1.48.025 2.113-.025Zm2.4-.4a11.02 11.02 0 0 0 2.65-.925 8.052 8.052 0 0 0 2.05-1.475 8.024 8.024 0 0 0 1.45-2.012c.4-.775.717-1.655.95-2.638L12.5 5.45c-.95.233-1.808.554-2.575.962A8.477 8.477 0 0 0 7.9 7.9a8.386 8.386 0 0 0-1.488 2.037c-.408.776-.729 1.646-.962 2.613l6 6ZM9.2 14.8a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l4.225-4.225A.918.918 0 0 1 14.1 8.9c.267 0 .5.1.7.3a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-4.225 4.225a.918.918 0 0 1-.675.275.96.96 0 0 1-.7-.3Zm9.75-5.7c.067-.65.088-1.325.063-2.025A9.993 9.993 0 0 0 18.8 5.25c-.383-.133-.963-.217-1.738-.25a14.39 14.39 0 0 0-2.112.05l4 4.05ZM7.75 21c-.95 0-1.817-.07-2.6-.212-.783-.142-1.267-.305-1.45-.488-.183-.2-.346-.7-.488-1.5C3.071 18 3 17.117 3 16.15c0-1.983.3-3.82.9-5.512.6-1.692 1.45-3.088 2.55-4.188 1.1-1.1 2.508-1.95 4.225-2.55 1.717-.6 3.575-.9 5.575-.9.967 0 1.837.07 2.613.212.774.142 1.254.305 1.437.488.183.2.346.7.488 1.5.141.8.212 1.7.212 2.7 0 1.95-.3 3.77-.9 5.463-.6 1.691-1.45 3.087-2.55 4.187-1.083 1.083-2.483 1.93-4.2 2.537-1.717.609-3.583.913-5.6.913Z" })
  }
);
export {
  l as default
};
