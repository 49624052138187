import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 88 88",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M0 0h88v88H0V0Z", fill: "#D4DBE1" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M58 34c0 7.732-6.268 14-14 14s-14-6.268-14-14 6.268-14 14-14 14 6.268 14 14ZM12 88V68.747a4 4 0 0 1 2.564-3.733l20.663-7.947A16 16 0 0 1 40.971 56h6.058a16 16 0 0 1 5.744 1.066l20.663 7.948A4 4 0 0 1 76 68.747V88H12Z",
      fill: "#8997A1"
    }
  )
);
export {
  a as default
};
