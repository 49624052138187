import { jsx as a } from "react/jsx-runtime";
const l = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M20.125 15 18 12.875l.725-.725a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l.725.725a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-.725.725ZM12 21v-2.125l5.3-5.3 2.125 2.125-5.3 5.3H12Zm-8-5a1 1 0 1 1 0-2h5a1 1 0 1 1 0 2H4Zm0-4a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2H4Zm0-4a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2H4Z" })
  }
);
export {
  l as default
};
