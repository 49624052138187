import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import TranslationProvider from "../../../../../src/gatsby-theme-docz/TranslationProvider";
import pkg from "../../../../../packages/file-picker/package.json";
import { FilePicker, Dropzone } from "../../../../../packages/file-picker/src/index";
import ExampleInnerDropzone from "../../../../../packages/file-picker/src/examples/ExampleInnerDropzone";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  TranslationProvider,
  pkg,
  FilePicker,
  Dropzone,
  ExampleInnerDropzone,
  React
};