import { jsx as t } from "react/jsx-runtime";
const A = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M20 19a.968.968 0 0 1-.712-.287A.968.968 0 0 1 19 18v-3c0-.833-.292-1.541-.875-2.125A2.893 2.893 0 0 0 16 12H6.8l2.925 2.925A.918.918 0 0 1 10 15.6c0 .267-.1.5-.3.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-4.6-4.6a.877.877 0 0 1-.213-.325A1.107 1.107 0 0 1 3.425 11c0-.133.02-.258.062-.375A.877.877 0 0 1 3.7 10.3l4.625-4.625A.918.918 0 0 1 9 5.4c.267 0 .5.1.7.3a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L6.8 10H16c1.383 0 2.563.488 3.538 1.463C20.512 12.438 21 13.617 21 15v3c0 .284-.096.521-.288.713A.968.968 0 0 1 20 19Z" })
  }
);
export {
  A as default
};
