import { PackageMetadata, BackgroundBox } from '@happeo/docs-tools';
import pkg from "../../../../../packages/theme/package.json";
import { DebugHappeoThemeProvider } from "../../../../../packages/theme/src/Theme/HappeoThemeProvider";
import { ColorsExample, AnimationsExample } from "../../../../../packages/theme/src/examples/DesignSystemExamples";
import RectangleExample, { CircleExample } from "../../../../../packages/theme/src/old/examples/RectangleExample";
import { Line300, Line400, Line600 } from "../../../../../packages/theme/src/old/Lines";
import { shadow100, shadow200, shadow300, shadow400, shadow500 } from "../../../../../packages/theme/src/old/shadows";
import * as React from 'react';
export default {
  PackageMetadata,
  BackgroundBox,
  pkg,
  DebugHappeoThemeProvider,
  ColorsExample,
  AnimationsExample,
  RectangleExample,
  CircleExample,
  Line300,
  Line400,
  Line600,
  shadow100,
  shadow200,
  shadow300,
  shadow400,
  shadow500,
  React
};