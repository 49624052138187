/**
 *
 * Default Loader component
 *
 */
import { useMemo } from "react";
import styled, { keyframes, css } from "styled-components";
import { lighten, active, success, alert, gray08 } from "@happeouikit/colors";
import { IconCheck, IconDeleteCircle } from "@happeouikit/icons";
import PropTypes from "prop-types";
import { TextEta } from "@happeouikit/typography";

const DEFAULT_SIZE = 64;
const DEFAULT_BORDER_SIZE = 4;
const DEFAULT_ICON_SIZE = 40;

const Circle = ({
  sizePx,
  weightPx,
  circumference,
  spinnerProps,
  progress,
}) => {
  const offset = useMemo(() => {
    if (progress === 100) {
      return circumference - (50 / 100) * circumference;
    }
    // Always have at least 5% done so users can see that there's a progress bar
    // and not just a gray circle
    return circumference - (Math.max(5, progress) / 100) * circumference;
  }, [progress, circumference]);

  return (
    <svg
      style={{ position: "absolute", left: 0, right: 0 }}
      width={sizePx}
      height={sizePx}
      xmlns="http://www.w3.org/2000/svg"
    >
      <CircleBackground
        cx={sizePx / 2}
        cy={sizePx / 2}
        r={sizePx / 2 - weightPx}
        circumference={circumference}
        {...spinnerProps}
      />
      <CircleLoader
        cx={sizePx / 2}
        cy={sizePx / 2}
        r={sizePx / 2 - weightPx}
        style={{
          strokeDashoffset: offset,
        }}
        circumference={circumference}
        {...spinnerProps}
      />
    </svg>
  );
};

const Spinner = ({
  state = "loading",
  sizePx = DEFAULT_SIZE,
  weightPx = DEFAULT_BORDER_SIZE,
  progress = 100,
  showPercentage,
}) => {
  const spinnerProps = {
    sizePx,
    weightPx,
    progress,
  };

  const radius = useMemo(() => sizePx / 2 - weightPx, [sizePx, weightPx]);
  const circumference = useMemo(() => radius * 2 * Math.PI, [radius]);

  return (
    <AnimationContainer sizePx={sizePx}>
      {(state === "loading" || state === "progress") && (
        <SpinnerContainer>
          <SpinInner
            sizePx={sizePx}
            spin={state === "loading" || progress === 100}
          >
            <Circle
              sizePx={sizePx}
              weightPx={weightPx}
              circumference={circumference}
              spinnerProps={spinnerProps}
              progress={state === "loading" ? 50 : progress}
            />
          </SpinInner>
          {showPercentage && state === "progress" && (
            <TextContainer>
              <TextEta>{Math.floor(Math.max(5, progress))}%</TextEta>
            </TextContainer>
          )}
        </SpinnerContainer>
      )}

      {state === "success" && (
        <SpinnerContainer>
          <SuccessCheck {...spinnerProps}>
            <IconCheck fill={success} />
          </SuccessCheck>
        </SpinnerContainer>
      )}
      {state === "error" && (
        <SpinnerContainer>
          <ErrorCheck {...spinnerProps}>
            <IconDeleteCircle fill={alert} />
          </ErrorCheck>
        </SpinnerContainer>
      )}
    </AnimationContainer>
  );
};

Spinner.propTypes = {
  state: PropTypes.oneOf(["loading", "progress", "success", "error"]),
  sizePx: PropTypes.number,
  weightPx: PropTypes.number,
  progress: PropTypes.number,
  showPercentage: PropTypes.bool,
};

Spinner.defaultProps = {
  state: "loading",
  sizePx: 64,
  weightPx: 4,
  progress: 0,
  showPercentage: false,
};

const sizePx = ({ sizePx }) => sizePx;
const weightPx = ({ weightPx }) => weightPx;
const iconSizePx = ({ sizePx, weightPx }) =>
  sizePx + weightPx <= 16
    ? // When icon is too small we do not add padding to icon
      sizePx + 2 * weightPx
    : sizePx || DEFAULT_ICON_SIZE;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const CircleBase = styled.circle`
  position: absolute;
  fill: none;
  stroke-width: ${weightPx}px;
  stroke-dasharray: ${({ circumference }) =>
    `${circumference} ${circumference}`};
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

const CircleBackground = styled(CircleBase)`
  stroke: ${gray08};
`;
const CircleLoader = styled(CircleBase)`
  stroke: ${active};
  transition: stroke-dashoffset 100ms linear;
`;

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(120deg);
  }
  40% {
    transform: rotate(240deg);
  }
  60% {
    transform: rotate(360deg);
  }
  80% {
    transform: rotate(490deg);
  }
  90% {
    transform: rotate(600deg);
  }
  100% {
    transform: rotate(720deg);
  }
`;
const scaleAndFadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const AnimationContainer = styled.div`
  height: ${sizePx}px;
  width: 100%;
  position: relative;
`;
const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const SpinInner = styled.div`
  width: ${sizePx}px;
  height: ${sizePx}px;
  position: relative;
  ${({ spin }) =>
    spin &&
    css`
      animation: ${spinnerAnimation} 2s infinite linear;
    `}
`;
const SuccessCheck = styled.div`
  border-radius: 50%;
  width: ${sizePx}px;
  height: ${sizePx}px;
  background-color: ${lighten(success, 0.9)};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${scaleAndFadeInAnimation} 180ms linear;
  svg {
    width: ${iconSizePx}px;
    height: ${iconSizePx}px;
  }
`;
const ErrorCheck = styled.div`
  border-radius: 50%;
  width: ${sizePx}px;
  height: ${sizePx}px;
  background-color: ${lighten(alert, 0.9)};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${scaleAndFadeInAnimation} 180ms linear;
  svg {
    width: ${iconSizePx}px;
    height: ${iconSizePx}px;
  }
`;

export default Spinner;
