import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20.791 5.714c0 .83-.67 1.5-1.5 1.5h-4.29l-1.45 3.37-2.09-2.09.55-1.28h-1.83l-3-3h12.11c.83 0 1.5.67 1.5 1.5Zm-15.88-.94 13.32 13.32c.39.39.39 1.02-.01 1.4a.996.996 0 0 1-1.41 0l-4.95-4.95-1.21 2.83c-.22.51-.72.84-1.27.84-.99 0-1.66-1.01-1.27-1.93l1.65-3.84-6.26-6.26a.996.996 0 1 1 1.41-1.41Z"
    }
  )
);
export {
  l as default
};
