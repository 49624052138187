import { jsx as s } from "react/jsx-runtime";
const a = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ s("path", { d: "M6.55 16.5 8 14c-1.1 0-2.042-.392-2.825-1.175C4.392 12.042 4 11.1 4 10s.392-2.042 1.175-2.825C5.958 6.392 6.9 6 8 6s2.042.392 2.825 1.175C11.608 7.958 12 8.9 12 10c0 .383-.046.738-.137 1.063a3.74 3.74 0 0 1-.413.937l-3.175 5.5a.973.973 0 0 1-.85.5.934.934 0 0 1-.862-.5.96.96 0 0 1-.013-1Zm9 0L17 14c-1.1 0-2.042-.392-2.825-1.175C13.392 12.042 13 11.1 13 10s.392-2.042 1.175-2.825C14.958 6.392 15.9 6 17 6s2.042.392 2.825 1.175C20.608 7.958 21 8.9 21 10c0 .383-.046.738-.137 1.063a3.74 3.74 0 0 1-.413.937l-3.175 5.5a.973.973 0 0 1-.85.5.934.934 0 0 1-.863-.5.96.96 0 0 1-.012-1Z" })
  }
);
export {
  a as default
};
