import * as e from "react";
const c = (v) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 32 32",
    xmlns: "http://www.w3.org/2000/svg",
    ...v
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M27.953 14.436c.047.185.047.371.047.65 0 3.25-1.7 13.096-11.858 12.771H8.677C6.126 27.857 4 25.814 4 23.259v-7.244c.142-2.926 1.417-4.83 3.827-6.27C9.01 9.04 10.336 8.64 11.65 8.242c1.624-.49 3.233-.974 4.538-2.026-.567 1.671-1.323 2.507-2.457 3.715h.048l-.048.046c4.394-.325 9.733-1.346 12.331-4.644 0 0 .661 1.393.661 3.716 0 2.09-.897 5.154-2.976 8.452v.046c1.7.14 3.638-1.718 4.205-3.111ZM6.598 18.383h7.04v2.88h-7.04v-2.88Zm8.126 7.013h4.205c1.56 0 2.835-1.254 2.835-2.787v-.371h-7.04v3.158Zm0-7.013h7.04v2.88h-7.04v-2.88Zm1.985-4.087h-8.22c-.993 0-1.843.79-1.843 1.812v1.253h15.118V13.74l-.022.005c-.63.143-2.231.506-5.033.552ZM8.96 25.442c-1.323 0-2.363-1.068-2.363-2.322v-.79h7.04v3.112H8.96Z"
    }
  )
);
export {
  c as default
};
