import { jsx as a } from "react/jsx-runtime";
const d = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.587 21.413C3.979 21.804 4.45 22 5 22h14c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413V6c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 19 4h-1V3a.968.968 0 0 0-.288-.713A.967.967 0 0 0 17 2a.99.99 0 0 0-.712.275.926.926 0 0 0-.288.7V4H8V3a.97.97 0 0 0-.287-.713A.97.97 0 0 0 7 2a.993.993 0 0 0-.713.275.928.928 0 0 0-.287.7V4H5c-.55 0-1.021.196-1.413.588A1.925 1.925 0 0 0 3 6v14c0 .55.196 1.021.587 1.413ZM19 19a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3Zm0-7a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2h14v2Zm0-4H5V6h14v2Z"
      }
    )
  }
);
export {
  d as default
};
