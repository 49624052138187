import { jsx as c } from "react/jsx-runtime";
const A = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M4.8 11.7 2.3 9.2a.96.96 0 0 1-.3-.7c0-.267.1-.5.3-.7l2.5-2.5c.2-.2.433-.3.7-.3.267 0 .5.1.7.3l2.5 2.5c.2.2.3.433.3.7 0 .267-.1.5-.3.7l-2.5 2.5c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3ZM10 22a.967.967 0 0 1-.713-.288A.968.968 0 0 1 9 21v-4a40.12 40.12 0 0 1-2.475-.275 31.587 31.587 0 0 1-2.45-.45 1.136 1.136 0 0 1-.688-.475.893.893 0 0 1-.137-.8.988.988 0 0 1 .513-.625c.258-.133.529-.167.812-.1 1.217.283 2.446.475 3.687.575a46.539 46.539 0 0 0 7.476 0c1.241-.1 2.47-.292 3.687-.575.283-.067.554-.033.813.1.258.133.429.342.512.625a.893.893 0 0 1-.137.8c-.175.25-.405.408-.688.475-.817.183-1.633.333-2.45.45-.817.117-1.642.208-2.475.275v4c0 .283-.096.52-.287.712A.968.968 0 0 1 14 22h-4ZM5.5 9.6l1.1-1.1-1.1-1.1-1.1 1.1 1.1 1.1ZM12 7a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 9 4c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 12 1c.833 0 1.542.292 2.125.875S15 3.167 15 4s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 12 7Zm0 7c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 10 12c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 12 10c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 12 14Zm0-9c.283 0 .52-.096.713-.287A.967.967 0 0 0 13 4a.967.967 0 0 0-.287-.712A.968.968 0 0 0 12 3a.968.968 0 0 0-.713.288A.967.967 0 0 0 11 4c0 .283.096.52.287.713.192.191.43.287.713.287Zm4.775 6.5-1.15-2A1.012 1.012 0 0 1 15.5 9c0-.183.042-.35.125-.5l1.15-2a.85.85 0 0 1 .35-.375c.15-.083.317-.125.5-.125h2.25c.183 0 .35.042.5.125a.85.85 0 0 1 .35.375l1.15 2c.083.15.125.317.125.5s-.042.35-.125.5l-1.15 2a.85.85 0 0 1-.35.375c-.15.083-.317.125-.5.125h-2.25c-.183 0-.35-.042-.5-.125a.85.85 0 0 1-.35-.375ZM18.2 10h1.1l.55-1-.55-1h-1.1l-.55 1 .55 1Z" })
  }
);
export {
  A as default
};
