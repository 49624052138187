import { jsx as h } from "react/jsx-runtime";
const t = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M12 3a9 9 0 0 0-9 9v7c0 1.1.9 2 2 2h2a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H5v-1c0-3.87 3.13-7 7-7s7 3.13 7 7v1h-2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h2c1.1 0 2-.9 2-2v-7a9 9 0 0 0-9-9ZM7 15v4H5v-4h2Zm12 4h-2v-4h2v4Z" })
  }
);
export {
  t as default
};
