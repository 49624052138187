import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M12 17a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 16v-3.2l.9.9c.1.1.208.17.325.212a1.1 1.1 0 0 0 .375.063 1.1 1.1 0 0 0 .375-.063.948.948 0 0 0 .6-.912.948.948 0 0 0-.275-.7l-2.6-2.6a.96.96 0 0 0-.7-.3c-.267 0-.5.1-.7.3l-2.6 2.6a.948.948 0 0 0-.275.7c0 .283.092.517.275.7a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l.9-.9V16c0 .283.096.52.288.712A.965.965 0 0 0 12 17Zm-8 3c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h5.175a1.978 1.978 0 0 1 1.4.575L12 6h8c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 20H4ZM4 6v12h16V8h-8.825l-2-2H4Zm0 0v12V6Z" })
  }
);
export {
  l as default
};
