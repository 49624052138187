import { jsx as t } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ t("path", { d: "M6 16a.965.965 0 0 1-.712-.288A.965.965 0 0 1 5 15c0-.283.096-.52.288-.712A.965.965 0 0 1 6 14h6c.283 0 .52.096.712.288A.965.965 0 0 1 13 15c0 .283-.096.52-.288.712A.965.965 0 0 1 12 16H6Zm0-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 5 11c0-.283.096-.521.288-.713A.967.967 0 0 1 6 10h12c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 18 12H6Zm-4 8a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 19V6c0-.283.096-.521.288-.713A.967.967 0 0 1 2 5h20c.283 0 .52.096.712.287.192.192.288.43.288.713v13c0 .283-.096.52-.288.712A.965.965 0 0 1 22 20H2Zm1-2h18V7H3v11Z" })
  }
);
export {
  h as default
};
