import { jsx as h } from "react/jsx-runtime";
const a = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9.035 19.979a.94.94 0 0 1-.037 0h-3A1.003 1.003 0 0 1 5 19v-7h-.002v-.02h-1.7a.5.5 0 0 1-.449-.716.577.577 0 0 1 .126-.139l8.35-7.525a.923.923 0 0 1 .674-.275c.154 0 .308.035.448.106.082.043.158.1.228.17l8.35 7.524c.05.041.09.084.12.13a.5.5 0 0 1-.447.725h-1.7v7c0 .55-.45 1-1 1H14a1 1 0 0 1-1-1v-5h-2v5a1 1 0 0 1-1 1h-.965ZM9 17.998v-4.02a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v4.02h2v-7.8l-5-4.5-5 4.5V18h2Z"
      }
    )
  }
);
export {
  a as default
};
