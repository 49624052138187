import { jsx as t } from "react/jsx-runtime";
const A = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M3 4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2 3c0-.283.096-.521.288-.713A.967.967 0 0 1 3 2h18c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 21 4H3Zm5.5 9.5c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 7 12c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 8.5 10.5h7c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062a1.444 1.444 0 0 1-1.062.438h-7ZM3 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2 21c0-.283.096-.52.288-.712A.965.965 0 0 1 3 20h18c.283 0 .52.096.712.288A.965.965 0 0 1 22 21c0 .283-.096.52-.288.712A.965.965 0 0 1 21 22H3Z" })
  }
);
export {
  A as default
};
