import * as React from "react";
const SvgIconUserAdd = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M19 14a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 13v-2h-2a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 10c0-.283.096-.521.288-.713A.967.967 0 0 1 16 9h2V7c0-.283.096-.521.288-.713A.967.967 0 0 1 19 6c.283 0 .52.096.712.287.192.192.288.43.288.713v2h2c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 22 11h-2v2c0 .283-.096.52-.288.712A.965.965 0 0 1 19 14ZM9 12c-1.1 0-2.042-.392-2.825-1.175C5.392 10.042 5 9.1 5 8s.392-2.042 1.175-2.825C6.958 4.392 7.9 4 9 4s2.042.392 2.825 1.175C12.608 5.958 13 6.9 13 8s-.392 2.042-1.175 2.825C11.042 11.608 10.1 12 9 12Zm-7 8a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 19v-1.8c0-.567.146-1.088.438-1.563A2.914 2.914 0 0 1 2.6 14.55a14.866 14.866 0 0 1 3.15-1.163A13.776 13.776 0 0 1 9 13c1.1 0 2.183.129 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.475.438.996.438 1.563V19c0 .283-.096.52-.288.712A.965.965 0 0 1 16 20H2Z"
      fill="#4A5464"
    />
  </svg>
);
export default SvgIconUserAdd;
