export { default as IconAdobeAcrobatFileColored } from "./AdobeAcrobat";
export { default as IconAdobeAftereffectFileColored } from "./AdobeAftereffect";
export { default as IconAdobeAnimateFileColored } from "./AdobeAnimate";
export { default as IconAdobeIllustratorFileColored } from "./AdobeIllustrator";
export { default as IconAdobeIndesignFileColored } from "./AdobeIndesign";
export { default as IconAdobePhotoshopFileColored } from "./AdobePhotoshop";
export { default as IconAdobePremiereFileColored } from "./AdobePremiere";
export { default as IconChannelsFileColored } from "./Channels";
export { default as IconDropboxAppcenterFileColored } from "./DropboxAppcenter";
export { default as IconDropboxBackupFileColored } from "./DropboxBackup";
export { default as IconDropboxCaptureFileColored } from "./DropboxCapture";
export { default as IconDropboxDocsendFileColored } from "./DropboxDocsend";
export { default as IconDropboxInvoiceFileColored } from "./DropboxInvoice";
export { default as IconDropboxPaperFileColored } from "./DropboxPaper";
export { default as IconDropboxPasswordsFileColored } from "./DropboxPasswords";
export { default as IconDropboxReplayFileColored } from "./DropboxReplay";
export { default as IconDropboxSignFileColored } from "./DropboxSign";
export { default as IconDropboxTransferFileColored } from "./DropboxTransfer";
export { default as IconFileFileColored } from "./File";
export { default as IconFolderMultipleFileColored } from "./FolderMultiple";
export { default as IconFolderFileColored } from "./Folder";
export { default as IconGoogleAcrobatFileColored } from "./GoogleAcrobat";
export { default as IconGoogleAudioFileColored } from "./GoogleAudio";
export { default as IconGoogleCodeFileColored } from "./GoogleCode";
export { default as IconGoogleDocsFileColored } from "./GoogleDocs";
export { default as IconGoogleDocumentsTextFileColored } from "./GoogleDocumentsText";
export { default as IconGoogleDrawingsFileColored } from "./GoogleDrawings";
export { default as IconGoogleFontFileColored } from "./GoogleFont";
export { default as IconGoogleFormsFileColored } from "./GoogleForms";
export { default as IconGoogleImageFileColored } from "./GoogleImage";
export { default as IconGoogleJamboardFileColored } from "./GoogleJamboard";
export { default as IconGoogleMapsFileColored } from "./GoogleMaps";
export { default as IconGoogleMusicFileColored } from "./GoogleMusic";
export { default as IconGooglePdfFileColored } from "./GooglePdf";
export { default as IconGoogleSheetsFileColored } from "./GoogleSheets";
export { default as IconGoogleSitesFileColored } from "./GoogleSites";
export { default as IconGoogleSlidesFileColored } from "./GoogleSlides";
export { default as IconGoogleVideoAltFileColored } from "./GoogleVideoAlt";
export { default as IconGoogleVideoFileColored } from "./GoogleVideo";
export { default as IconGoogleZipFileColored } from "./GoogleZip";
export { default as IconImageFileColored } from "./Image";
export { default as IconJiraAccessRequestFileColored } from "./JiraAccessRequest";
export { default as IconJiraBugAltFileColored } from "./JiraBugAlt";
export { default as IconJiraBugFileColored } from "./JiraBug";
export { default as IconJiraConcernFileColored } from "./JiraConcern";
export { default as IconJiraDefectFileColored } from "./JiraDefect";
export { default as IconJiraDesignTaskFileColored } from "./JiraDesignTask";
export { default as IconJiraDevelopmentTaskFileColored } from "./JiraDevelopmentTask";
export { default as IconJiraDocumentationFileColored } from "./JiraDocumentation";
export { default as IconJiraEpicFileColored } from "./JiraEpic";
export { default as IconJiraImprovementFileColored } from "./JiraImprovement";
export { default as IconJiraNewFeatureFileColored } from "./JiraNewFeature";
export { default as IconJiraQuestionFileColored } from "./JiraQuestion";
export { default as IconJiraRemoveFeatureFileColored } from "./JiraRemoveFeature";
export { default as IconJiraRequirementFileColored } from "./JiraRequirement";
export { default as IconJiraSalesRequestFileColored } from "./JiraSalesRequest";
export { default as IconJiraStoryFileColored } from "./JiraStory";
export { default as IconJiraSubTaskFileColored } from "./JiraSubTask";
export { default as IconJiraSuggestionFileColored } from "./JiraSuggestion";
export { default as IconJiraTaskFileColored } from "./JiraTask";
export { default as IconLinkFileColored } from "./Link";
export { default as IconMicrosoftAccessAltFileColored } from "./MicrosoftAccessAlt";
export { default as IconMicrosoftAccessFileColored } from "./MicrosoftAccess";
export { default as IconMicrosoftExcelAltFileColored } from "./MicrosoftExcelAlt";
export { default as IconMicrosoftExcelFileColored } from "./MicrosoftExcel";
export { default as IconMicrosoftExchangeAltFileColored } from "./MicrosoftExchangeAlt";
export { default as IconMicrosoftExchangeFileColored } from "./MicrosoftExchange";
export { default as IconMicrosoftFolderFileColored } from "./MicrosoftFolder";
export { default as IconMicrosoftForms1FileColored } from "./MicrosoftForms1";
export { default as IconMicrosoftFormsFileColored } from "./MicrosoftForms";
export { default as IconMicrosoftLibraryFileColored } from "./MicrosoftLibrary";
export { default as IconMicrosoftOnenoteAltFileColored } from "./MicrosoftOnenoteAlt";
export { default as IconMicrosoftOnenoteFileColored } from "./MicrosoftOnenote";
export { default as IconMicrosoftOutlookAltFileColored } from "./MicrosoftOutlookAlt";
export { default as IconMicrosoftOutlookFileColored } from "./MicrosoftOutlook";
export { default as IconMicrosoftPowerpointAltFileColored } from "./MicrosoftPowerpointAlt";
export { default as IconMicrosoftPowerpointFileColored } from "./MicrosoftPowerpoint";
export { default as IconMicrosoftPublisherAltFileColored } from "./MicrosoftPublisherAlt";
export { default as IconMicrosoftPublisherFileColored } from "./MicrosoftPublisher";
export { default as IconMicrosoftWordAltFileColored } from "./MicrosoftWordAlt";
export { default as IconMicrosoftWordFileColored } from "./MicrosoftWord";
export { default as IconMicrosoftYammerAltFileColored } from "./MicrosoftYammerAlt";
export { default as IconMicrosoftYammerFileColored } from "./MicrosoftYammer";
export { default as IconPagesFileColored } from "./Pages";
export { default as IconSalesforceAccountFileColored } from "./SalesforceAccount";
export { default as IconSalesforceContactsFileColored } from "./SalesforceContacts";
export { default as IconSalesforceLeadFileColored } from "./SalesforceLead";
export { default as IconSalesforceOpportunityFileColored } from "./SalesforceOpportunity";
export { default as IconSoundFileColored } from "./Sound";
