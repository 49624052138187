import { jsx as c } from "react/jsx-runtime";
const h = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M18.92 13c-.5 0-.91.37-.98.86a6.006 6.006 0 0 1-7.42 4.96c-2.25-.54-3.91-2.27-4.39-4.53A6.014 6.014 0 0 1 12 7v2.79c0 .45.54.67.85.35l3.79-3.79c.2-.2.2-.51 0-.71l-3.79-3.79a.5.5 0 0 0-.85.36V5c-4.94 0-8.84 4.48-7.84 9.6.6 3.11 2.9 5.5 5.99 6.19 4.83 1.08 9.15-2.2 9.77-6.67.09-.59-.4-1.12-1-1.12Zm-8.02 3v-4.27h-.09l-1.77.63v.69l1.01-.31V16h.85Zm3.42-4.22c-.18-.07-.37-.1-.59-.1-.22 0-.41.03-.59.1s-.33.18-.45.33c-.12.15-.23.34-.29.57-.06.23-.1.5-.1.82v.74c0 .32.04.6.11.82.07.22.17.42.3.57.13.15.28.26.46.33s.37.1.59.1c.22 0 .41-.03.59-.1s.33-.18.45-.33c.12-.15.22-.34.29-.57.07-.23.1-.5.1-.82v-.74c0-.32-.04-.6-.11-.82-.07-.22-.17-.42-.3-.57-.13-.15-.29-.26-.46-.33Zm.01 2.57c0 .19-.01.35-.04.48s-.06.24-.11.32c-.05.08-.11.14-.19.17s-.16.05-.25.05-.18-.02-.25-.05a.436.436 0 0 1-.19-.17c-.05-.08-.09-.19-.12-.32s-.04-.29-.04-.48v-.97c0-.19.01-.35.04-.48s.06-.23.12-.31c.06-.08.11-.14.19-.17s.16-.05.25-.05.18.02.25.05c.07.03.14.09.19.17.05.08.09.18.12.31s.04.29.04.48v.97h-.01Z" })
  }
);
export {
  h as default
};
