import { jsxs as e, jsx as a } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a("path", { d: "M6 8a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM6 12a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM6 16a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Z" }),
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3 19a2 2 0 0 0 2 2h13a3 3 0 0 0 3-3v-6a2 2 0 0 0-2-2h-2V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14ZM15 5H5v14h10V5Zm3 14a1 1 0 0 1-1-1v-6h2v6a1 1 0 0 1-1 1Z"
        }
      )
    ]
  }
);
export {
  v as default
};
