import { jsx as c } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M3 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2 21c0-.283.096-.52.288-.712A.965.965 0 0 1 3 20h18c.283 0 .52.096.712.288A.965.965 0 0 1 22 21c0 .283-.096.52-.288.712A.965.965 0 0 1 21 22H3Zm5.5-4c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 7 16.5v-13c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 8.5 2c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v13c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 8.5 18Zm7 0c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 14 16.5v-7c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 15.5 8c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v7c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 15.5 18Z" })
  }
);
export {
  o as default
};
