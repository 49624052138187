import * as React from "react";
const SvgIconFacebook = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M20.007 3H3.993A.993.993 0 0 0 3 3.993v16.014c0 .548.445.993.993.993h8.621v-6.97H10.27v-2.717h2.345V9.309c0-2.324 1.42-3.59 3.494-3.59.994 0 1.848.074 2.096.107v2.43h-1.438c-1.128 0-1.346.536-1.346 1.322v1.735h2.69l-.35 2.716h-2.34V21h4.587a.993.993 0 0 0 .993-.993V3.993A.993.993 0 0 0 20.007 3" />
  </svg>
);
export default SvgIconFacebook;
