/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Channels = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z"
      fill="#0075DB"
    />
    <path
      d="M12 18.395c-.155 0-.307-.026-.456-.08a1.685 1.685 0 0 1-.422-.224L6.287 14.33a.65.65 0 0 1-.278-.565.695.695 0 0 1 .295-.564.716.716 0 0 1 .86 0L12 16.945l4.836-3.744a.716.716 0 0 1 .86 0c.19.144.29.332.295.564a.651.651 0 0 1-.278.565l-4.835 3.761a1.69 1.69 0 0 1-.42.224c-.15.054-.303.08-.458.08Zm0-3.617c-.155 0-.307-.027-.456-.08a1.685 1.685 0 0 1-.422-.225l-4.835-3.761a.689.689 0 0 1-.215-.26.73.73 0 0 1 0-.627.69.69 0 0 1 .215-.26l4.835-3.76c.132-.096.272-.17.422-.225a1.342 1.342 0 0 1 .913 0c.15.054.29.129.42.224l4.836 3.762a.69.69 0 0 1 .215.259.731.731 0 0 1 0 .628.689.689 0 0 1-.215.259l-4.835 3.761a1.69 1.69 0 0 1-.42.224c-.15.054-.303.08-.458.08Z"
      fill="#fff"
    />
  </svg>
);

export default Channels;
