import { jsxs as t, jsx as l } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "m2.663 18.846.97 1.676c.202.353.492.63.832.832l3.465-5.998H1c0 .39.1.781.302 1.134l1.361 2.357Z",
          fill: "#2B313B"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M12 8.3 8.535 2.302c-.34.202-.63.48-.832.832l-6.4 11.088A2.283 2.283 0 0 0 1 15.356h6.93L12 8.3Z",
          fill: "#95A0B1"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M19.535 21.354c.34-.202.63-.479.831-.832l.404-.693 1.928-3.339c.201-.353.302-.743.302-1.134h-6.93l1.474 2.898 1.99 3.1Z",
          fill: "#38404C"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "m12 8.3 3.465-5.998A2.215 2.215 0 0 0 14.331 2H9.669c-.403 0-.794.113-1.134.302L12 8.3Z",
          fill: "#68778D"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M16.07 15.356H7.93l-3.465 5.998c.34.201.73.302 1.134.302h12.802c.403 0 .794-.113 1.134-.302l-3.465-5.998Z",
          fill: "#596578"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "m19.497 8.678-3.2-5.544a2.288 2.288 0 0 0-.832-.832L12 8.3l4.07 7.056h6.917c0-.39-.1-.781-.302-1.134l-3.188-5.544Z",
          fill: "#95A0B1"
        }
      )
    ]
  }
);
export {
  c as default
};
