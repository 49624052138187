import * as e from "react";
const l = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm3.464 12.536a2 2 0 0 1 0-2.829L9.172 12a1 1 0 1 0-1.415-1.414l-.707.707a4 4 0 1 0 5.657 5.657l.707-.707A1 1 0 0 0 12 14.828l-.707.708a2 2 0 0 1-2.829 0Zm1.415-2.829a1 1 0 1 0 1.414 1.414l2.828-2.828a1 1 0 0 0-1.414-1.414L9.88 12.707Zm2.828-4.243a2 2 0 1 1 2.829 2.829l-.708.707a1 1 0 0 0 1.415 1.414l.707-.707a4 4 0 0 0-5.657-5.657l-.707.707A1 1 0 0 0 12 9.172l.707-.708Z"
    }
  )
);
export {
  l as default
};
