import * as React from "react";
const SvgIconViewList = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 9h2c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1Zm2 5h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1Zm0 5h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1Zm13-5h-10c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1Zm-10 5h10c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1h-10c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1Zm-1-11V6c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-10c-.55 0-1-.45-1-1Z"
    />
  </svg>
);
export default SvgIconViewList;
