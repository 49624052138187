import * as React from "react";
const SvgIconMsOnenote = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="Icon-ms-onenote_svg__a"
        x1={5.822}
        y1={11.568}
        x2={20.178}
        y2={36.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#8324b3" />
        <stop offset={0.5} stopColor="#7a1bac" />
        <stop offset={1} stopColor="#621197" />
      </linearGradient>
    </defs>
    <path
      d="M35 44H13.831A1.831 1.831 0 0 1 12 42.169V5.831A1.831 1.831 0 0 1 13.831 4h29.338A1.831 1.831 0 0 1 45 5.831V14z"
      fill="#ca64ea"
    />
    <path fill="#ae4bd5" d="m45 24-5 3-5-3V14h10v10z" />
    <path fill="#9332bf" d="m45 34-5 3-5-3V24h10v10z" />
    <path
      d="M35 34h10v8.125A1.875 1.875 0 0 1 43.125 44H35V34z"
      fill="#7719aa"
    />
    <path fill="none" d="M0 0h48v48H0z" />
    <path
      d="M24.167 12H12v25h12.167A1.839 1.839 0 0 0 26 35.167V13.833A1.839 1.839 0 0 0 24.167 12z"
      opacity={0.2}
    />
    <path
      d="M23.167 13H12v25h11.167A1.839 1.839 0 0 0 25 36.167V14.833A1.839 1.839 0 0 0 23.167 13z"
      opacity={0.2}
    />
    <path
      d="M23.167 13H12v23h11.167A1.839 1.839 0 0 0 25 34.167V14.833A1.839 1.839 0 0 0 23.167 13z"
      opacity={0.5}
    />
    <path
      d="M22.167 13H12v23h10.167A1.839 1.839 0 0 0 24 34.167V14.833A1.839 1.839 0 0 0 22.167 13z"
      opacity={0.5}
    />
    <rect
      x={2}
      y={13}
      width={22}
      height={22}
      rx={1.833}
      fill="url(#Icon-ms-onenote_svg__a)"
    />
    <path
      d="M8.026 18.042h2.485l4.883 7.9q.333.531.5.855h.032a12.032 12.032 0 0 1-.072-1.62v-7.135h2.12v11.916h-2.326l-5.066-8.119A6.313 6.313 0 0 1 10.13 21h-.04a17.54 17.54 0 0 1 .063 1.778v7.18H8.026z"
      fill="#fff"
    />
  </svg>
);
export default SvgIconMsOnenote;
