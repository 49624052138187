import { jsx as t } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M18.45 15.6 17 14.15 18.55 12 15 7H9.85l-2-2H15a1.95 1.95 0 0 1 1.65.85l3.525 5c.233.35.35.733.35 1.15 0 .416-.117.8-.35 1.15L18.45 15.6ZM5 19c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 17V7c0-.184.02-.35.062-.5.042-.15.105-.3.188-.45L2.1 4.9a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l17 17a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-3.15-3.15c-.15.083-.3.146-.45.188-.15.04-.317.062-.5.062H5Zm9.2-2L5 7.8V17h9.2Z" })
  }
);
export {
  l as default
};
