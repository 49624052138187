import { jsx as a } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M15.885 6.293a.998.998 0 0 1 1.41 1.412l-4.588 4.587a1 1 0 0 1-1.414 0L6.705 7.705a.998.998 0 0 1 1.41-1.412L12 10.17l3.885-3.876Zm0 6a.998.998 0 0 1 1.41 1.412l-4.588 4.587a1 1 0 0 1-1.414 0l-4.588-4.587a.998.998 0 0 1 1.41-1.412L12 16.17l3.885-3.877Z" })
  }
);
export {
  t as default
};
