import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/banners/package.json";
import { Banner } from "../../../../../packages/banners/src/index";
import { BodyUI } from "@happeouikit/typography";
import { ButtonPrimary, ButtonSecondary, IconButton } from "@happeouikit/buttons";
import { active } from "@happeouikit/colors";
import { IconBulb, IconClose } from "@happeouikit/icons";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  Banner,
  BodyUI,
  ButtonPrimary,
  ButtonSecondary,
  IconButton,
  active,
  IconBulb,
  IconClose,
  React
};