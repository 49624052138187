/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Discord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.3 5.3c-1.4-.6-2.8-1-4.3-1.3-.2.4-.4.7-.6 1.1-1.6-.2-3.2-.2-4.8 0-.2-.4-.4-.7-.6-1.1-1.5.3-3 .7-4.4 1.3-2.8 4.1-3.5 8.1-3.1 12C3.1 18.5 5 19.4 6.9 20c.4-.6.8-1.2 1.1-1.8-.6-.2-1.2-.5-1.8-.9.2-.1.3-.2.4-.3 3.4 1.6 7.3 1.6 10.7 0 .1.1.3.2.4.3l-1.8.9c.3.6.7 1.3 1.1 1.8 1.9-.6 3.7-1.5 5.3-2.7.6-4.5-.6-8.5-3-12ZM8.5 14.9c-1 0-1.9-.9-1.9-2.1 0-1.2.8-2.1 1.9-2.1 1.1 0 1.9 1 1.9 2.1s-.8 2.1-1.9 2.1Zm7 0c-1 0-1.9-.9-1.9-2.1 0-1.2.8-2.1 1.9-2.1 1.1 0 1.9 1 1.9 2.1s-.8 2.1-1.9 2.1Z"
      fill="#5865F2"
    />
  </svg>
);

export default Discord;
