import { jsx as h } from "react/jsx-runtime";
const m = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M11 9V7h2v2h-2Zm0 4v-2h2v2h-2Zm4 0v-2h2v2h-2ZM5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm0-2h14V5H5v14Zm6-2v-2h2v2h-2Zm-4-4v-2h2v2H7Z" })
  }
);
export {
  m as default
};
