import { jsx as t } from "react/jsx-runtime";
const e = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M9.425 14c-.45 0-.762-.204-.937-.613-.175-.408-.105-.77.212-1.087l2.6-2.6c.1-.1.208-.175.325-.225A.942.942 0 0 1 12 9.4c.133 0 .258.025.375.075.117.05.225.125.325.225l2.6 2.6c.317.317.387.68.212 1.087-.175.41-.487.613-.937.613h-5.15Z" })
  }
);
export {
  e as default
};
