import * as e from "react";
const h = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M1 19h18v-2H1v2ZM1 1v2h18V1H1Zm3 14h3l2-4V5H3v6h3l-2 4Zm8 0h3l2-4V5h-6v6h3l-2 4Z",
      fillRule: "evenodd"
    }
  )
);
export {
  h as default
};
