import { jsx as a } from "react/jsx-runtime";
const c = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "m3.95 18.7 2.75.6 1.45 2.45a.929.929 0 0 0 .55.425c.233.067.467.058.7-.025l2.6-1.1 2.6 1.1c.233.083.467.092.7.025a.929.929 0 0 0 .55-.425l1.45-2.45 2.75-.6c.25-.05.45-.18.6-.388a.926.926 0 0 0 .175-.687L20.55 14.8l1.875-2.15c.167-.183.25-.4.25-.65a.934.934 0 0 0-.25-.65L20.55 9.2l.275-2.825a.926.926 0 0 0-.175-.687.943.943 0 0 0-.6-.388l-2.75-.6-1.45-2.45a.93.93 0 0 0-.55-.425 1.12 1.12 0 0 0-.7.025L12 2.95l-2.6-1.1a1.12 1.12 0 0 0-.7-.025.93.93 0 0 0-.55.425L6.7 4.7l-2.75.6c-.25.05-.45.18-.6.388a.926.926 0 0 0-.175.687L3.45 9.2l-1.875 2.15a.934.934 0 0 0-.25.65c0 .25.083.467.25.65L3.45 14.8l-.275 2.825a.926.926 0 0 0 .175.687c.15.209.35.338.6.388ZM13 12V8a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0Zm-2 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z"
      }
    )
  }
);
export {
  c as default
};
