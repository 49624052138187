import { jsxs as t, jsx as e } from "react/jsx-runtime";
const i = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM8.173 2.761a10 10 0 1 1 7.654 18.478A10 10 0 0 1 8.173 2.761Z"
        }
      ),
      /* @__PURE__ */ e("path", { d: "M12 5a7 7 0 1 1-4.95 2.05L12 12V5Z" })
    ]
  }
);
export {
  i as default
};
