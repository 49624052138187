import { jsx as a } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M6.3 17.3a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l3.9-3.9-3.9-3.9a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l4.6 4.6c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.883.883 0 0 1-.213.325l-4.6 4.6a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM17 18a.965.965 0 0 1-.712-.288A.965.965 0 0 1 16 17V7c0-.283.096-.521.288-.713A.967.967 0 0 1 17 6c.283 0 .52.096.712.287.192.192.288.43.288.713v10c0 .283-.096.52-.288.712A.965.965 0 0 1 17 18Z" })
  }
);
export {
  s as default
};
