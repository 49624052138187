import { jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M19.1 21.9 16.15 19H5a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 18c0-.283.096-.52.287-.712A.968.968 0 0 1 5 17h1v-7a5.934 5.934 0 0 1 .1-1.1l-4-4a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l17 17a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3ZM12 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 20h4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 22Zm6-6.85-9.8-9.8c.333-.25.692-.48 1.075-.688A4.836 4.836 0 0 1 10.5 4.2v-.7c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 12 2c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v.7a5.927 5.927 0 0 1 3.225 2.087C17.575 7.346 18 8.583 18 10v5.15Z" })
  }
);
export {
  h as default
};
