import { jsxs as t, jsx as l } from "react/jsx-runtime";
const c = (e) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z",
          fill: "#63BA3C"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m12.347 11.28-.078-.032-.258-.104c-.344-.136-.7-.277-1-.496-.368-.268-.55-.637-.5-1.011.051-.383.334-.708.775-.891.259-.108.55-.16.891-.16.175 0 .364.012.613.04.292.034.6.11.939.235.083.03.17.046.26.046.214 0 .409-.088.532-.24a.563.563 0 0 0 .112-.505.615.615 0 0 0-.352-.399 3.978 3.978 0 0 0-.93-.275c-.186-.029-.37-.05-.565-.073l-.22-.025v-.886c0-.271-.24-.491-.537-.491a.56.56 0 0 0-.38.144.468.468 0 0 0-.156.348c0 .276.002.547.003.82 0 .073.001.115-.008.125-.011.01-.061.02-.125.032a3.426 3.426 0 0 0-1.344.556 2.226 2.226 0 0 0-.963 1.78 2.044 2.044 0 0 0 .855 1.685c.553.407 1.184.667 1.728.868.4.147.858.328 1.246.616.508.376.705.847.586 1.4-.11.505-.456.607-.98.762l-.099.03a2.824 2.824 0 0 1-.808.114c-.344 0-.713-.056-1.127-.173a10.007 10.007 0 0 1-.488-.152.772.772 0 0 0-.247-.04c-.314 0-.588.184-.68.459l-.01.033c-.11.324.075.674.42.797.34.12.714.219 1.027.268.2.032.4.052.61.073l.245.026v.91c0 .286.254.519.566.519a.59.59 0 0 0 .402-.153.493.493 0 0 0 .165-.367l-.002-.929a6.63 6.63 0 0 0 .642-.205c1.088-.371 1.716-1.042 1.866-1.995.164-1.04-.266-1.885-1.243-2.442-.438-.25-.918-.449-1.383-.641Z",
          fill: "#fff",
          stroke: "#fff",
          strokeWidth: 0.4
        }
      )
    ]
  }
);
export {
  c as default
};
