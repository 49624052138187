import { jsx as c } from "react/jsx-runtime";
const A = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M18 6.5c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0 1 16 4.5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 18 2.5c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 18 6.5ZM10 22c-1.383 0-2.562-.487-3.537-1.462C5.488 19.563 5 18.383 5 17s.488-2.563 1.463-3.538C7.438 12.487 8.617 12 10 12v2c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 7 17c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 10 20c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 13 17h2c0 1.383-.487 2.563-1.462 3.538C12.563 21.513 11.383 22 10 22Zm9-1a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 20v-4h-5c-.733 0-1.3-.313-1.7-.938-.4-.625-.45-1.279-.15-1.962L13 9h-2.275l-.3.75a.886.886 0 0 1-.45.487.903.903 0 0 1-.675.063 1.03 1.03 0 0 1-.663-.525.928.928 0 0 1-.037-.825l.3-.75c.15-.383.396-.68.737-.888A2.103 2.103 0 0 1 10.75 7h5.2c.75 0 1.321.304 1.713.912.391.609.437 1.255.137 1.938l-1.65 3.65H18c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413V20c0 .283-.096.52-.288.712A.965.965 0 0 1 19 21Z" })
  }
);
export {
  A as default
};
