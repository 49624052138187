import { jsxs as s, jsx as t } from "react/jsx-runtime";
const e = (h) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M1 3.75H.75v17.1h22.5V3.75H1Z",
          stroke: "#000",
          strokeOpacity: 0.2,
          strokeWidth: 0.5
        }
      ),
      /* @__PURE__ */ t("path", { d: "M1 15.067h22V20.6H1v-5.533Z", fill: "#FFCE00" }),
      /* @__PURE__ */ t("path", { d: "M1 4h22v5.533H1V4Z", fill: "#000" }),
      /* @__PURE__ */ t("path", { d: "M1 9.533h22v5.534H1V9.533Z", fill: "#D00" })
    ]
  }
);
export {
  e as default
};
