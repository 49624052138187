import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M15.146 13.854a.5.5 0 0 0-.353-.854H13V4a1 1 0 1 0-2 0v9H9.207a.5.5 0 0 0-.353.854l2.792 2.792a.5.5 0 0 0 .708 0l2.792-2.792ZM5 19a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H5Z" })
  }
);
export {
  h as default
};
