import { jsx as t } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M6 10.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C8.28 6 9.12 6 10.8 6h2.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C18 8.28 18 9.12 18 10.8v2.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C15.72 18 14.88 18 13.2 18h-2.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C6 15.72 6 14.88 6 13.2v-2.4Z" })
  }
);
export {
  c as default
};
