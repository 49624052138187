import { jsx as t } from "react/jsx-runtime";
const e = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M4.4 19.424a.989.989 0 0 1-.95-.088c-.3-.191-.45-.47-.45-.837v-3.725c0-.233.067-.441.2-.625a.884.884 0 0 1 .55-.35L11 12l-7.25-1.8a.883.883 0 0 1-.55-.35 1.036 1.036 0 0 1-.2-.625V5.5c0-.366.15-.646.45-.838a.99.99 0 0 1 .95-.087l15.4 6.5c.417.184.625.492.625.925 0 .434-.208.742-.625.925l-15.4 6.5Z" })
  }
);
export {
  e as default
};
