import { jsxs as e, jsx as l } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ l("rect", { x: 2, y: 2, width: 20, height: 20, rx: 4, fill: "#F88962" }),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M14.057 7.198a2.057 2.057 0 1 1-4.113 0 2.057 2.057 0 0 1 4.113 0ZM18.398 10.626c.457 0 .64.571.251.822l-3.336 2.148a.48.48 0 0 0-.183.503l1.257 4.181c.114.457-.48.777-.8.434l-3.244-3.427a.458.458 0 0 0-.686 0l-3.244 3.427c-.343.343-.937.023-.8-.434L8.87 14.1c.068-.183-.023-.389-.183-.503L5.35 11.448c-.389-.251-.206-.822.251-.822h12.796Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  c as default
};
