import * as React from "react";
const SvgIconInfo = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M11.5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM9 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7c0 .55-.45 1-1 1s-1-.45-1-1v-6h-1a1 1 0 0 1-1-1Z" />
  </svg>
);
export default SvgIconInfo;
