import * as React from "react";

const SvgIconThumbUp = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M5.83332 17.4999V6.66661L10.8333 1.70827C11.0417 1.49994 11.288 1.37827 11.5725 1.34327C11.8575 1.30883 12.1319 1.36105 12.3958 1.49994C12.6597 1.63883 12.8542 1.83327 12.9792 2.08327C13.1042 2.33327 13.1319 2.59022 13.0625 2.85411L12.125 6.66661H17.5C17.9444 6.66661 18.3333 6.83328 18.6667 7.16661C19 7.49994 19.1667 7.88883 19.1667 8.33327V9.99994C19.1667 10.0972 19.1528 10.2013 19.125 10.3124C19.0972 10.4236 19.0694 10.5277 19.0417 10.6249L16.5417 16.4999C16.4167 16.7777 16.2083 17.0138 15.9167 17.2083C15.625 17.4027 15.3194 17.4999 15 17.4999H5.83332ZM7.49999 7.37494V15.8333H15L17.5 9.99994V8.33327H9.99999L11.125 3.74994L7.49999 7.37494ZM3.33332 17.4999C2.87499 17.4999 2.48277 17.3369 2.15666 17.0108C1.82999 16.6841 1.66666 16.2916 1.66666 15.8333V8.33327C1.66666 7.87494 1.82999 7.48244 2.15666 7.15577C2.48277 6.82966 2.87499 6.66661 3.33332 6.66661H5.83332V8.33327H3.33332V15.8333H5.83332V17.4999H3.33332ZM7.49999 15.8333V7.37494V8.33327V9.99994V15.8333Z" />
  </svg>
);
export default SvgIconThumbUp;
