export const margin100 = "2px";
export const margin200 = "4px";
export const margin300 = "8px";
export const margin400 = "16px";
export const margin500 = "24px";
export const margin600 = "32px";
export const margin700 = "40px";
export const margin800 = "48px";
export const margin900 = "56px";
export const margin1000 = "64px";
