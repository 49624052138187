import { jsx as e } from "react/jsx-runtime";
const c = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V6Zm10 3.255a2.057 2.057 0 1 0 0-4.113 2.057 2.057 0 0 0 0 4.113Zm6.65 2.193c.388-.251.205-.822-.252-.822H5.602c-.457 0-.64.571-.251.822l3.336 2.148c.16.114.251.32.183.503L7.613 18.28c-.137.457.457.777.8.434l3.244-3.427a.458.458 0 0 1 .686 0l3.244 3.427c.32.343.914.023.8-.434L15.13 14.1a.48.48 0 0 1 .183-.503l3.336-2.148Z"
      }
    )
  }
);
export {
  c as default
};
