import { jsx as e } from "react/jsx-runtime";
const i = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm11.525 9.629.003.006-2.937 5.873c-.009.024-.02.045-.033.067l-.013.026-.02.039-.005-.002a.74.74 0 0 1-.627.361.75.75 0 0 1-.75-.75V13.5H8.25a.75.75 0 0 1-.75-.75c0-.145.053-.274.125-.39l-.003-.004 2.922-5.826a.747.747 0 0 1 .099-.195A.736.736 0 0 1 11.25 6a.75.75 0 0 1 .75.75v3.75h3.75a.75.75 0 0 1 .75.75c0 .142-.049.267-.118.379Z"
      }
    )
  }
);
export {
  i as default
};
