import { jsx as e } from "react/jsx-runtime";
const o = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2ZM9 6h6a1.5 1.5 0 0 1 1.5 1.5v9.75a.75.75 0 0 1-.75.75.734.734 0 0 1-.615-.346l-.006.001-2.889-3.345a.301.301 0 0 0-.48 0l-2.889 3.345-.006-.002A.734.734 0 0 1 8.25 18a.75.75 0 0 1-.75-.75V7.5A1.5 1.5 0 0 1 9 6Z"
      }
    )
  }
);
export {
  o as default
};
