import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22ZM8.5 12c.417 0 .763-.133 1.038-.4s.429-.6.462-1a.573.573 0 0 0 .413.088.489.489 0 0 0 .337-.238.442.442 0 0 0 .062-.388.587.587 0 0 0-.262-.312l-2.625-1.5c-.133-.083-.258-.104-.375-.062a.594.594 0 0 0-.3.262.561.561 0 0 0-.062.375c.025.117.104.217.237.3l.225.125c-.2.133-.358.308-.475.525A1.504 1.504 0 0 0 7 10.5c0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm7 0c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.267-.058-.508-.175-.725a1.442 1.442 0 0 0-.475-.525l.225-.125a.53.53 0 0 0 .25-.313.479.479 0 0 0-.075-.387.446.446 0 0 0-.287-.25.48.48 0 0 0-.388.075l-2.625 1.5a.53.53 0 0 0-.25.312.537.537 0 0 0 .05.388.49.49 0 0 0 .338.238A.571.571 0 0 0 14 10.6c.033.4.188.733.463 1s.62.4 1.037.4Zm-7.75 5h8.5c.233 0 .396-.08.488-.238.091-.158.087-.337-.013-.537a6.479 6.479 0 0 0-1.95-2.325c-.817-.6-1.742-.9-2.775-.9s-1.958.3-2.775.9a6.48 6.48 0 0 0-1.95 2.325c-.1.2-.104.379-.012.537.091.159.254.238.487.238Z" })
  }
);
export {
  s as default
};
