import { jsxs as t, jsx as a } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          d: "M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z",
          fill: "#29C4A9"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M10.795 8.292a.809.809 0 0 1 .455.74v7.912c0 .578-.55.968-1.045.74l-3.75-1.726a.809.809 0 0 1-.455-.74V7.306c0-.578.55-.968 1.045-.74l3.75 1.726ZM13.205 8.292a.809.809 0 0 0-.455.74v7.912c0 .578.55.968 1.045.74l3.75-1.726a.809.809 0 0 0 .455-.74V7.306c0-.578-.55-.968-1.045-.74l-3.75 1.726Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  h as default
};
