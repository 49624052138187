import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18 10.79c0 3.05-2.18 5.77-5.21 6.16A6.006 6.006 0 0 1 6 11V4.25a1.25 1.25 0 0 1 2.5 0V11c0 2.17 1.98 3.89 4.23 3.42 1.64-.33 2.77-1.85 2.77-3.52V4.25a1.25 1.25 0 0 1 2.5 0v6.54ZM6 21c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1H6Z"
    }
  )
);
export {
  a as default
};
