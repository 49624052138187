import * as React from "react";
const SvgIconLink = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 15h3c.55 0 1 .45 1 1s-.45 1-1 1H7c-2.76 0-5-2.24-5-5s2.24-5 5-5h3c.55 0 1 .45 1 1s-.45 1-1 1H7c-1.65 0-3 1.35-3 3s1.35 3 3 3Zm10-8h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c1.65 0 3 1.35 3 3s-1.35 3-3 3h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c2.76 0 5-2.24 5-5s-2.24-5-5-5Zm-9 5c0 .55.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1H9c-.55 0-1 .45-1 1Z"
    />
  </svg>
);
export default SvgIconLink;
