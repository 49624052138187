import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c2.433 0 4.562.762 6.387 2.287 1.825 1.525 2.963 3.438 3.413 5.738h-2.05a8.051 8.051 0 0 0-1.712-3.263A7.786 7.786 0 0 0 15 4.6V5c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 13 7h-2v2c0 .283-.096.52-.287.712A.968.968 0 0 1 10 10H8v2h2v3H9l-4.8-4.8c-.05.3-.096.6-.138.9-.041.3-.062.6-.062.9 0 2.183.767 4.058 2.3 5.625C7.833 19.192 9.733 19.983 12 20v2Zm8.4-1.2-2.5-2.5c-.35.2-.725.367-1.125.5s-.825.2-1.275.2c-1.25 0-2.313-.437-3.188-1.312S11 15.75 11 14.5c0-1.25.437-2.313 1.312-3.188S14.25 10 15.5 10c1.25 0 2.313.437 3.188 1.312S20 13.25 20 14.5c0 .45-.067.875-.2 1.275-.133.4-.3.775-.5 1.125l2.5 2.5a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM15.5 17c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775C16.792 12.242 16.2 12 15.5 12s-1.292.242-1.775.725C13.242 13.208 13 13.8 13 14.5s.242 1.292.725 1.775c.483.483 1.075.725 1.775.725Z" })
  }
);
export {
  t as default
};
