import { jsx as c } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M11.3 22.3 9 20H5c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 18V4c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 2h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 20h-4l-2.3 2.3a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Zm.65-5.5c.35 0 .646-.12.888-.362a1.21 1.21 0 0 0 .362-.888c0-.35-.12-.646-.362-.888a1.21 1.21 0 0 0-.888-.362c-.35 0-.646.12-.888.362a1.21 1.21 0 0 0-.362.888c0 .35.12.646.362.888.242.241.538.362.888.362Zm1-4.7c.05-.267.138-.5.263-.7.125-.2.37-.483.737-.85.617-.617 1.033-1.117 1.25-1.5.217-.383.325-.817.325-1.3 0-.85-.312-1.554-.937-2.113-.625-.558-1.413-.837-2.363-.837-.717 0-1.354.146-1.913.437-.558.292-.987.68-1.287 1.163a.896.896 0 0 0-.125.737.757.757 0 0 0 .45.538c.233.1.463.104.688.013.225-.092.404-.246.537-.463.167-.233.392-.412.675-.537.283-.125.575-.188.875-.188.467 0 .842.125 1.125.375.283.25.425.567.425.95 0 .317-.096.633-.287.95-.192.317-.488.65-.888 1-.433.383-.767.775-1 1.175-.233.4-.35.775-.35 1.125 0 .233.088.433.263.6.175.167.387.25.637.25a.797.797 0 0 0 .587-.237c.159-.159.263-.355.313-.588Z" })
  }
);
export {
  h as default
};
