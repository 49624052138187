import * as e from "react";
const r = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 130.746 595.279 580.398",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "radialGradient",
    {
      id: "icon-file-adobe-premiere_svg__a",
      cx: -183.69,
      cy: 328.972,
      r: 0.76,
      gradientTransform: "matrix(545.6736 0 0 528.3113 100439.305 -173525.125)",
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { offset: 0, stopColor: "#4b2d4f", stopOpacity: 0.98 }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#180024" })
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M24.803 155.549h545.674v530.792H24.803V155.549z",
      fill: "url(#icon-file-adobe-premiere_svg__a)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M24.803 155.549h545.674v530.792H24.803V155.549zM0 711.145h595.28V130.746H0v580.399zm357.168-335.342c0-2.977 0-10.169-1.24-24.06 0-1.984.248-2.48 1.984-3.224 15.13-6.201 50.599-17.114 90.78-17.114 1.984 0 2.976.496 2.976 2.48v35.965c0 1.984-.744 2.48-2.728 2.48-15.627-.744-38.197 1.24-46.879 4.96v156.262c0 1.983-.744 2.976-2.977 2.976h-38.94c-1.984 0-2.977-.744-2.977-2.976V375.803zm-158.741 22.571c5.704.496 10.169.496 20.091.496 29.02 0 56.304-10.169 56.304-49.606 0-31.5-19.347-47.375-52.584-47.375-9.921 0-19.346.496-23.811.744v95.741zm-43.902-131.706c0-1.736.496-2.977 2.48-2.977 15.874-.744 39.189-1.24 63.745-1.24 68.705 0 98.717 37.701 98.717 86.068 0 63-45.638 90.036-101.693 90.036-9.426 0-12.65-.496-19.347-.496v95.245c0 1.984-.744 2.976-2.977 2.976h-38.197c-1.984 0-2.977-.744-2.977-2.976V266.668h.249z",
      fill: "#e579ff"
    }
  )
);
export {
  r as default
};
