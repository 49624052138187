import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M12 15c.55 0 1.021-.196 1.413-.588.391-.391.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 12 11c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 10 13c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm5.6 5-3.575-3.575a3.553 3.553 0 0 1-.937.425c-.342.1-.705.15-1.088.15-1.1 0-2.042-.392-2.825-1.175C8.392 15.042 8 14.1 8 13s.392-2.042 1.175-2.825C9.958 9.392 10.9 9 12 9s2.042.392 2.825 1.175C15.608 10.958 16 11.9 16 13a3.595 3.595 0 0 1-.575 2L18 17.575v-8.85L14.05 4H6v16h11.6ZM6 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 2h8.075c.3 0 .583.063.85.188s.492.304.675.537l3.925 4.725c.133.183.246.383.338.6.091.217.137.442.137.675V20c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 22H6Z" })
  }
);
export {
  t as default
};
