/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const SalesforceOpportunity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={2} y={2} width={20} height={20} rx={4} fill="#FCB95B" />
    <path
      d="M16.67 16.263H7.33c-.222 0-.421.199-.421.42v.022c0 .73.597 1.329 1.328 1.329h7.526c.73 0 1.328-.598 1.328-1.329v-.022c0-.221-.2-.42-.42-.42ZM17.755 7.515a1.332 1.332 0 0 0-.863 2.347 2.341 2.341 0 0 1-2.258 1.416 2.339 2.339 0 0 1-2.191-2.191c0-.2 0-.376.044-.553.487-.2.841-.665.841-1.24 0-.73-.598-1.328-1.328-1.328-.73 0-1.328.597-1.328 1.328 0 .553.354 1.04.84 1.24.045.177.045.354.045.553a2.339 2.339 0 0 1-2.191 2.191 2.29 2.29 0 0 1-2.258-1.416c.288-.244.465-.62.465-1.019 0-.73-.598-1.328-1.328-1.328-.73 0-1.329.598-1.329 1.328 0 .73.598 1.329 1.329 1.329l.62 4.736c.022.2.199.355.42.355h9.43c.199 0 .376-.155.42-.354l.62-4.738c.73 0 1.328-.597 1.328-1.328 0-.73-.598-1.328-1.328-1.328Z"
      fill="#fff"
    />
  </svg>
);

export default SalesforceOpportunity;
