/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftExchange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.604 2.4h-4.883a1.395 1.395 0 0 0-.987.409L4.209 14.334a1.395 1.395 0 0 0-.409.987v4.883A1.396 1.396 0 0 0 5.196 21.6h4.883a1.395 1.395 0 0 0 .987-.41L22.591 9.667c.262-.262.41-.617.409-.987V3.796A1.394 1.394 0 0 0 21.604 2.4Z"
      fill="#68778D"
    />
    <path
      d="M21.604 21.6H16.72c-.37 0-.725-.147-.987-.409L13.4 18.857v-4.361A2.496 2.496 0 0 1 15.896 12h4.361l2.334 2.334c.262.262.409.617.409.987v4.883a1.396 1.396 0 0 1-1.396 1.396Z"
      fill="#596578"
    />
    <path
      d="M5.196 2.4h4.883c.37 0 .725.147.987.409L13.4 5.143v4.36A2.496 2.496 0 0 1 10.904 12H6.543L4.209 9.666a1.396 1.396 0 0 1-.409-.987V3.796A1.396 1.396 0 0 1 5.196 2.4Z"
      fill="#95A0B1"
    />
    <path
      opacity={0.2}
      d="M12.6 7.332v10.536a.688.688 0 0 1-.016.16.925.925 0 0 1-.916.772H3.8V6.4h7.868a.93.93 0 0 1 .932.932Z"
      fill="#000"
    />
    <path
      opacity={0.1}
      d="M13 7.332v9.736a1.345 1.345 0 0 1-1.332 1.332H3.8V6h7.868A1.337 1.337 0 0 1 13 7.332Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M12.6 7.332v9.736a.928.928 0 0 1-.932.932H3.8V6.4h7.868a.93.93 0 0 1 .932.932Z"
      fill="#000"
    />
    <path
      opacity={0.1}
      d="M12.2 7.332v9.736a.928.928 0 0 1-.932.932H3.8V6.4h7.468a.93.93 0 0 1 .932.932Z"
      fill="#000"
    />
    <path
      d="M11.267 6.4H1.933A.933.933 0 0 0 1 7.333v9.334c0 .515.418.933.933.933h9.334a.933.933 0 0 0 .933-.933V7.333a.933.933 0 0 0-.933-.933Z"
      fill="#596578"
    />
    <path
      d="M8.434 9.952H5.815v1.532h2.458v.981H5.815v1.591h2.759v.977H4.626V8.967h3.808v.985Z"
      fill="#fff"
    />
  </svg>
);

export default MicrosoftExchange;
