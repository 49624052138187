import * as React from "react";
const SvgIconWidgetBreadcrumb = (props) => (
  <svg
    viewBox="0 0 93 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <g filter="url(#icon-widget-breadcrumb_svg__a)">
      <g clipPath="url(#icon-widget-breadcrumb_svg__b)">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
        <rect
          x={-9.025}
          y={-1.447}
          width={110.469}
          height={66.366}
          rx={1.422}
          fill="#fff"
        />
        <rect
          x={6.125}
          y={5.676}
          width={79.639}
          height={42.664}
          rx={1.422}
          fill="#0075DB"
        />
        <rect
          x={6.125}
          y={5.676}
          width={79.639}
          height={42.664}
          rx={1.422}
          fill="#fff"
          fillOpacity={0.9}
        />
        <g filter="url(#icon-widget-breadcrumb_svg__c)">
          <path
            d="M13.625 19.676a1 1 0 0 1 1-1H45.81a1 1 0 0 1 .91.583l3.213 7a1 1 0 0 1 0 .834l-3.214 7a1 1 0 0 1-.909.583H14.625a1 1 0 0 1-1-1v-14Z"
            fill="#fff"
          />
        </g>
        <g filter="url(#icon-widget-breadcrumb_svg__d)">
          <path
            d="M49.793 20.1a1 1 0 0 1 .905-1.424h9.703a1 1 0 0 1 .889.543l3.6 7a1 1 0 0 1 0 .915l-3.6 7a1 1 0 0 1-.89.542h-9.702a1 1 0 0 1-.905-1.424l2.883-6.151a1 1 0 0 0 0-.85l-2.883-6.15Z"
            fill="#fff"
          />
        </g>
        <g filter="url(#icon-widget-breadcrumb_svg__e)">
          <path
            d="M64.793 20.1a1 1 0 0 1 .905-1.424h9.703a1 1 0 0 1 .889.543l3.6 7a1 1 0 0 1 0 .915l-3.6 7a1 1 0 0 1-.89.542h-9.702a1 1 0 0 1-.905-1.424l2.883-6.151a1 1 0 0 0 0-.85l-2.883-6.15Z"
            fill="#fff"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="icon-widget-breadcrumb_svg__a"
        x={0.593}
        y={0.195}
        width={91.563}
        height={54.588}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44071"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44071"
          result="effect2_dropShadow_213_44071"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44071"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-breadcrumb_svg__c"
        x={10.781}
        y={16.543}
        width={42.088}
        height={21.689}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44071"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44071"
          result="effect2_dropShadow_213_44071"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44071"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-breadcrumb_svg__d"
        x={46.852}
        y={16.543}
        width={20.992}
        height={21.689}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44071"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44071"
          result="effect2_dropShadow_213_44071"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44071"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-breadcrumb_svg__e"
        x={61.852}
        y={16.543}
        width={20.992}
        height={21.689}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44071"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44071"
          result="effect2_dropShadow_213_44071"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44071"
          result="shape"
        />
      </filter>
      <clipPath id="icon-widget-breadcrumb_svg__b">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconWidgetBreadcrumb;
