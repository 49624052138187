/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const AdobeIllustrator = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.589 12.85h1.954a10.894 10.894 0 0 0-.177-.554c-.07-.207-.139-.43-.208-.661a37.89 37.89 0 0 1-.112-.354 36.96 36.96 0 0 0-.111-.354 16.507 16.507 0 0 1-.208-.684l-.078-.277-.091-.323h-.015c-.07.33-.154.661-.262.992l-.132.428a65.25 65.25 0 0 1-.222.71c-.108.393-.223.754-.338 1.077Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.813 3H6.188C4.425 3 3 4.462 3 6.27v11.46C3 19.539 4.425 21 6.188 21h11.625C19.575 21 21 19.538 21 17.73V6.27C21 4.461 19.575 3 17.812 3Zm-2.34 6.296c.185.177.431.277.685.27a.904.904 0 0 0 .7-.27.973.973 0 0 0 .27-.707.97.97 0 0 0-.255-.685c-.169-.177-.4-.27-.7-.27-.254 0-.5.093-.684.27a.905.905 0 0 0-.277.685 1 1 0 0 0 .261.707Zm-.177 1.023v5.923c0 .077.039.123.116.123h1.507c.07 0 .1-.038.1-.122v-5.924c0-.069-.03-.107-.1-.107h-1.523c-.069 0-.1.03-.1.107Zm-3.292 4.093H9.142l-.584 1.807a.135.135 0 0 1-.146.108H6.966c-.085 0-.108-.046-.085-.138l2.477-7.131.02-.07c.017-.057.035-.118.057-.184.03-.161.046-.33.046-.5-.008-.038.023-.077.061-.085h1.993c.061 0 .092.024.1.062l2.808 7.923c.023.085 0 .123-.077.123h-1.608c-.054.008-.108-.03-.123-.084l-.631-1.831Z"
    />
  </svg>
);

export default AdobeIllustrator;
