import { jsx as h } from "react/jsx-runtime";
const v = (e) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ h(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.5 3h15A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3Zm6.239 5.71h4.208V7.124H8.829v9.75h6.344v-1.57h-4.433v-2.557h3.95v-1.577h-3.95V8.709Z"
      }
    )
  }
);
export {
  v as default
};
