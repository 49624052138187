import { jsx as e } from "react/jsx-runtime";
const a = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm6.569 4.114a1.497 1.497 0 0 0-.487.325l-4.5 4.5a1.5 1.5 0 0 0 2.122 2.122l1.939-1.94V16.5a1.5 1.5 0 0 0 3 0v-5.379l1.94 1.94a1.5 1.5 0 0 0 2.12-2.122l-4.5-4.5a1.5 1.5 0 0 0-1.634-.325Z"
      }
    )
  }
);
export {
  a as default
};
