import { jsx as e } from "react/jsx-runtime";
const c = (A) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M1.288 19.712A.965.965 0 0 0 2 20h20c.283 0 .52-.096.712-.288A.965.965 0 0 0 23 19V6a.968.968 0 0 0-.288-.713A.967.967 0 0 0 22 5H2a.967.967 0 0 0-.712.287A.968.968 0 0 0 1 6v13c0 .283.096.52.288.712ZM6 12a.965.965 0 0 1-.712-.288A.965.965 0 0 1 5 11c0-.283.096-.521.288-.713A.967.967 0 0 1 6 10h12c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 18 12H6Zm0 4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 5 15c0-.283.096-.52.288-.712A.965.965 0 0 1 6 14h6c.283 0 .52.096.712.288A.965.965 0 0 1 13 15c0 .283-.096.52-.288.712A.965.965 0 0 1 12 16H6Z"
      }
    )
  }
);
export {
  c as default
};
