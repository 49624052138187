/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleChrome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16.325 14.5 12 22a10 10 0 0 0 10-10 9.88 9.88 0 0 0-.738-3.75h-5.987A4.959 4.959 0 0 1 17 12c0 .912-.262 1.75-.688 2.5h.013Z" />
    <path d="M3.337 7A9.965 9.965 0 0 0 2 12c0 5.05 3.75 9.212 8.613 9.887l3-5.175A4.973 4.973 0 0 1 12 17c-1.875 0-3.45-1.013-4.312-2.5h-.013L3.337 7Z" />
    <path d="M14.652 14.652a3.75 3.75 0 1 0-5.304-5.304 3.75 3.75 0 0 0 5.304 5.304Z" />
    <path d="M20.65 7A9.976 9.976 0 0 0 12 2C8.8 2 5.95 3.512 4.125 5.85L7.1 11.012A5.002 5.002 0 0 1 12 7h8.65Z" />
  </svg>
);

export default GoogleChrome;
