import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.225 11.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4h-5.25c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h5.78c2.07 0 3.96-1.69 3.97-3.77.01-1.53-.85-2.84-2.15-3.44Zm-5.6-4.29h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3Zm0 9h3.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-3.5v3Z"
    }
  )
);
export {
  c as default
};
