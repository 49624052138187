import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "m19.1 21.874-3.025-3c-.1.084-.212.154-.337.212a12.18 12.18 0 0 1-.363.163.983.983 0 0 1-.925-.012c-.3-.158-.45-.48-.45-.963a.89.89 0 0 1 .163-.513c.108-.158.254-.278.437-.362l-8-8a7.84 7.84 0 0 0-.438 1.288 5.836 5.836 0 0 0 .263 3.549c.283.709.725 1.363 1.325 1.963l.25.25V15c0-.283.096-.52.288-.713A.967.967 0 0 1 9 14a.97.97 0 0 1 .713.287A.97.97 0 0 1 10 15v4c0 .284-.096.52-.287.712A.968.968 0 0 1 9 20H5a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 19c0-.283.096-.52.287-.712A.968.968 0 0 1 5 18h1.75l-.4-.35c-.817-.816-1.413-1.704-1.788-2.662a8.08 8.08 0 0 1-.275-5.101 8.023 8.023 0 0 1 .838-1.962L2.1 4.9a.948.948 0 0 1-.275-.7c0-.283.1-.525.3-.725a.948.948 0 0 1 .7-.275c.283 0 .525.092.725.275l16.975 17a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7c-.2.2-.442.3-.725.3a.908.908 0 0 1-.7-.3Zm-.175-5.85-1.5-1.5a6.5 6.5 0 0 0 .425-1.25 5.873 5.873 0 0 0-.275-3.513C17.292 9.053 16.85 8.4 16.25 7.8L16 7.55V9c0 .284-.096.52-.287.712A.968.968 0 0 1 15 10a.965.965 0 0 1-.712-.288A.965.965 0 0 1 14 9V5c0-.283.096-.52.288-.713A.967.967 0 0 1 15 4h4c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 19 6h-1.75l.4.35c.817.817 1.413 1.704 1.788 2.662a8.009 8.009 0 0 1 .274 5.075 9.518 9.518 0 0 1-.787 1.938Zm-9.45-9.45-1.5-1.5a7.44 7.44 0 0 1 .312-.175c.125-.066.238-.116.338-.15.333-.15.646-.137.937.037.292.176.438.488.438.938a.882.882 0 0 1-.137.488 1.17 1.17 0 0 1-.388.362Z" })
  }
);
export {
  t as default
};
