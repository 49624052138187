/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const DropboxBackup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C5.61 2 2 5.61 2 12c0 6.39 3.61 10 10 10v-5.815c-2.684 0-4.185-1.533-4.185-4.185 0-2.684 1.533-4.185 4.185-4.185 2.684 0 4.185 1.533 4.185 4.185H22c0-6.39-3.61-10-10-10Z"
      fill="#0061FE"
    />
    <path
      d="M12 7.815V12H7.816c0 2.684 1.534 4.185 4.186 4.185 2.683 0 4.185-1.533 4.185-4.185 0-2.684-1.502-4.185-4.185-4.185Z"
      fill="#1E1919"
    />
  </svg>
);

export default DropboxBackup;
