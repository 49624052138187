import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M6 21a.968.968 0 0 1-.713-.288.968.968 0 0 1-.287-.713c0-.283.096-.52.287-.712A.967.967 0 0 1 6 18.999h4v-2c-1.383 0-2.563-.487-3.537-1.462C5.487 14.562 5 13.383 5 11.999c0-.95.242-1.825.725-2.625a4.707 4.707 0 0 1 2-1.825c-.067.367-.054.725.038 1.075.091.35.237.684.437 1-.383.267-.68.609-.887 1.025A2.976 2.976 0 0 0 7 12c0 .834.292 1.542.875 2.125A2.893 2.893 0 0 0 10 15h7c.283 0 .52.096.712.288.192.191.288.429.288.712 0 .284-.096.521-.288.713a.968.968 0 0 1-.712.287h-4v2h5c.283 0 .52.096.712.288.192.191.288.429.288.712 0 .284-.096.521-.288.713a.968.968 0 0 1-.712.287H6Zm7.7-9.85h-.025a.968.968 0 0 1-.763-.013.953.953 0 0 1-.512-.563l-.15-.4a2.79 2.79 0 0 0 .775-.962c.183-.375.275-.78.275-1.213 0-.783-.275-1.446-.825-1.987-.55-.542-1.225-.813-2.025-.813l-.125-.325c-.083-.266-.07-.52.038-.762a.952.952 0 0 1 .562-.513h.025a.878.878 0 0 1 .025-.737c.117-.242.317-.413.6-.513a.984.984 0 0 1 .738.038.868.868 0 0 1 .487.562.964.964 0 0 1 .775.025c.25.117.425.309.525.575l2.05 5.625c.1.267.096.521-.012.763a.952.952 0 0 1-.563.512h-.025c.1.267.092.525-.025.775a.993.993 0 0 1-.6.525.984.984 0 0 1-.738-.037.869.869 0 0 1-.487-.563ZM10.5 9.8c-.5 0-.925-.176-1.275-.526A1.736 1.736 0 0 1 8.7 8c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525.5 0 .925.175 1.275.525.35.35.525.775.525 1.275 0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525Z" })
  }
);
export {
  t as default
};
