/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleDocs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z"
      fill="#5383EC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 9H7V7h10v2ZM17 13H7v-2h10v2ZM14 17H7v-2h7v2Z"
      fill="#fff"
    />
  </svg>
);

export default GoogleDocs;
