import { jsx as h } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ h("path", { d: "M19 21a1 1 0 0 1-1-1v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 1 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 0 1-1 1Zm-13.375.5c-.35 0-.675-.092-.975-.275a2.154 2.154 0 0 1-.725-.725l-2.35-4.1a1.88 1.88 0 0 1-.275-1c0-.367.092-.7.275-1l6.35-10.9c.183-.333.43-.583.738-.75.308-.167.637-.25.987-.25h4.7c.35 0 .68.083.988.25.308.167.554.417.737.75l4.475 7.675c-.25-.083-.5-.138-.75-.163a8.154 8.154 0 0 0-.8-.037h-.425c-.133 0-.275.017-.425.05L14.35 4.5h-4.7L3.3 15.4l2.35 4.1h7.9c.183.383.396.742.638 1.075.241.333.529.642.862.925H5.625ZM7.25 17l-.725-1.275L11.1 7.75h1.8l2.525 4.4c-.3.233-.562.471-.787.713a6.337 6.337 0 0 0-.638.812L12 10.2 9.25 15h4.1a4.501 4.501 0 0 0-.275.988c-.05.325-.075.662-.075 1.012H7.25Z" })
  }
);
export {
  l as default
};
