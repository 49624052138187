import * as React from "react";
const SvgIconEmojiPlus = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M10 18.333a8.11 8.11 0 0 1-3.25-.656 8.41 8.41 0 0 1-2.646-1.781 8.41 8.41 0 0 1-1.78-2.646A8.11 8.11 0 0 1 1.666 10a8.11 8.11 0 0 1 .656-3.25 8.41 8.41 0 0 1 1.781-2.646A8.421 8.421 0 0 1 6.75 2.322 8.12 8.12 0 0 1 10 1.667c.625 0 1.233.065 1.823.197a7.98 7.98 0 0 1 1.677.573 2.265 2.265 0 0 0-.125.438 2.555 2.555 0 0 0 .02 1.01c.043.174.098.337.168.49a7.276 7.276 0 0 0-1.667-.76A6.301 6.301 0 0 0 10 3.332c-1.847 0-3.42.65-4.718 1.948C3.982 6.58 3.333 8.153 3.333 10c0 1.847.65 3.42 1.949 4.72 1.298 1.297 2.87 1.947 4.718 1.947 1.847 0 3.42-.65 4.72-1.948 1.298-1.299 1.947-2.872 1.947-4.72 0-.555-.066-1.09-.198-1.603-.132-.514-.316-1-.552-1.459a2.452 2.452 0 0 0 2.02.52c.126.404.223.817.292 1.241.07.424.104.857.104 1.302a8.11 8.11 0 0 1-.656 3.25 8.41 8.41 0 0 1-1.781 2.646 8.409 8.409 0 0 1-2.646 1.78 8.11 8.11 0 0 1-3.25.657Zm2.917-9.166c.36 0 .66-.118.896-.355.236-.236.354-.534.354-.895 0-.362-.118-.66-.354-.896a1.214 1.214 0 0 0-.896-.354c-.361 0-.66.118-.896.354a1.214 1.214 0 0 0-.354.896c0 .36.118.66.354.895.236.237.535.355.896.355Zm-5.834 0c.362 0 .66-.118.896-.355.236-.236.354-.534.354-.895 0-.362-.118-.66-.354-.896a1.214 1.214 0 0 0-.896-.354c-.36 0-.66.118-.895.354a1.214 1.214 0 0 0-.355.896c0 .36.118.66.355.895.236.237.534.355.895.355ZM10 14.583c.986 0 1.858-.27 2.614-.812a4.561 4.561 0 0 0 1.636-2.104h-8.5a4.561 4.561 0 0 0 1.636 2.104c.757.541 1.628.812 2.614.812Zm6.667-10.416h-.834a.804.804 0 0 1-.593-.24.804.804 0 0 1-.24-.594c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24h.834v-.832c0-.237.08-.435.24-.595.16-.159.357-.239.593-.239s.434.08.593.24c.16.16.24.357.24.594V2.5h.834c.236 0 .434.08.593.24.16.16.24.357.24.593s-.08.434-.24.594a.804.804 0 0 1-.593.24h-.834V5c0 .236-.08.434-.24.594a.806.806 0 0 1-.593.24.806.806 0 0 1-.593-.24.807.807 0 0 1-.24-.594v-.833Z"
      fill="#4A5464"
    />
  </svg>
);
export default SvgIconEmojiPlus;
