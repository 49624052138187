import { jsx as e } from "react/jsx-runtime";
const d = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm1 8.74 3.74 3.74 1.42-1.41-2.33-2.33 2.33-2.33L9.74 8 6 11.74ZM14.42 8 13 9.41l2.33 2.33L13 14.07l1.42 1.41 3.74-3.74L14.42 8Z"
      }
    )
  }
);
export {
  d as default
};
