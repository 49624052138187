import * as React from "react";
const SvgIconFileAdobeAfterEffects = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 130.746 595.279 580.398"
    width="1em"
    height="1em"
    {...props}
  >
    <radialGradient
      id="icon-file-adobe-after-effects_svg__a"
      cx={-183.69}
      cy={328.972}
      r={0.76}
      gradientTransform="matrix(545.6736 0 0 528.3113 100439.305 -173525.125)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#442f55" stopOpacity={0.98} />
      <stop offset={1} stopColor="#12002c" />
    </radialGradient>
    <path
      d="M24.803 155.549h545.674v530.792H24.803V155.549z"
      fill="url(#icon-file-adobe-after-effects_svg__a)"
    />
    <path
      d="M24.803 155.549h545.674v530.792H24.803V155.549zM0 711.145h595.28V130.746H0v580.399zm421.16-300.617c12.649 0 17.114 0 18.354-.496 0-1.736.496-2.977.496-4.217 0-13.394-6.449-38.197-32.244-38.197-23.812 0-33.98 20.834-36.461 42.91h49.855zm-50.35 33.98c.496 33.485 16.37 56.056 54.071 56.056 14.634 0 27.283-1.983 40.43-7.688 1.736-.744 2.977-.496 2.977 1.736v31.004c0 2.48-.744 3.721-2.48 4.961-13.146 6.448-29.517 9.425-49.855 9.425-65.48 0-90.036-48.366-90.036-102.189 0-58.536 30.261-106.406 83.588-106.406 54.071 0 72.922 45.39 72.922 82.347 0 11.906-.744 21.579-1.984 26.292-.496 1.984-1.24 2.729-3.225 3.225-4.961.744-19.595 1.735-41.422 1.735H370.81v-.498zm-132.945-25.299c-6.945-27.532-23.812-87.556-30.013-116.576h-.496c-5.208 29.02-18.851 78.13-29.02 116.576h59.529zm-69.699 40.182-19.594 74.162c-.496 1.983-1.24 2.728-3.721 2.728H108.39c-2.48 0-2.977-.744-2.48-3.72l70.441-246.298c1.24-4.464 1.984-7.937 2.48-20.338 0-1.736.744-2.48 1.984-2.48h52.087c1.736 0 2.48.496 2.977 2.48l78.874 267.132c.496 1.983 0 3.224-1.984 3.224h-40.925c-1.984 0-3.225-.744-3.721-2.479l-20.339-74.41h-79.618z"
      fill="#d8a1ff"
    />
  </svg>
);
export default SvgIconFileAdobeAfterEffects;
