import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../../../../../packages/color-picker/package.json";
import { ColorPicker } from "../../../../../packages/color-picker/src/index";
import { ColorPickerExample } from "../../../../../packages/color-picker/docs/example";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  ColorPicker,
  ColorPickerExample,
  React
};