import * as React from "react";
const SvgIconFileMsExcel = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2Zm9.2 14h2L13 12l3.2-5h-2L12 10.8 9.8 7h-2l3.2 5-3.2 5h2l2.2-3.8 2.2 3.8Z"
    />
  </svg>
);
export default SvgIconFileMsExcel;
