import { jsx as a } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M11 19a1 1 0 1 0 2 0V5a1 1 0 1 0-2 0v14ZM4.793 13v1.794a.5.5 0 0 1-.854.353l-2.793-2.793a.5.5 0 0 1 0-.707L3.94 8.854a.5.5 0 0 1 .854.354v1.793h3a1 1 0 1 1 0 2h-3ZM19.207 11V9.209a.5.5 0 0 1 .854-.354l2.793 2.793a.5.5 0 0 1 0 .707l-2.793 2.793a.5.5 0 0 1-.854-.353V13h-3a1 1 0 1 1 0-2h3Z" })
  }
);
export {
  o as default
};
