import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M4 19c-1.1 0-2.042-.392-2.825-1.175C.392 17.042 0 16.1 0 15c0-.95.304-1.792.912-2.525A3.909 3.909 0 0 1 3.25 11.1l-.7-.6H.75A.728.728 0 0 1 0 9.75.728.728 0 0 1 .75 9h3.2c.183 0 .362.025.537.075.175.05.338.117.488.2L7 10.5l3.65-1.375a5.37 5.37 0 0 1 .35-.088c.117-.025.242-.037.375-.037h2.775L12.6 7H11a.968.968 0 0 1-.713-.287A.967.967 0 0 1 10 6c0-.283.096-.52.287-.713A.968.968 0 0 1 11 5h1.575c.317 0 .613.07.888.213.275.141.504.329.687.562l1.5 1.925 2.625-1.325a.467.467 0 0 1 .488.025c.158.1.237.242.237.425V8.5a.48.48 0 0 1-.15.35.48.48 0 0 1-.35.15h-1.8l1.75 2.3A4.197 4.197 0 0 1 20 11c1.1 0 2.042.392 2.825 1.175C23.608 12.958 24 13.9 24 15s-.392 2.042-1.175 2.825C22.042 18.608 21.1 19 20 19s-2.042-.392-2.825-1.175C16.392 17.042 16 16.1 16 15c0-.45.08-.887.238-1.313.158-.425.379-.82.662-1.187l-.5-.6-2.8 4.2a2.008 2.008 0 0 1-1.675.9H10.75a2.07 2.07 0 0 1-.7-.125 1.84 1.84 0 0 1-.625-.375L8 15.25a3.98 3.98 0 0 1-1.275 2.663A3.832 3.832 0 0 1 4 19Zm0-2c.55 0 1.02-.196 1.412-.587C5.804 16.02 6 15.55 6 15s-.196-1.02-.588-1.412A1.926 1.926 0 0 0 4 13c-.55 0-1.02.196-1.413.588A1.926 1.926 0 0 0 2 15c0 .55.196 1.02.587 1.413C2.98 16.803 3.45 17 4 17Zm16 0c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.02-.587-1.412A1.926 1.926 0 0 0 20 13c-.55 0-1.02.196-1.413.588A1.926 1.926 0 0 0 18 15c0 .55.196 1.02.587 1.413.392.391.863.587 1.413.587Z" })
  }
);
export {
  s as default
};
