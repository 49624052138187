import { active, gray01, gray03, lighten90 } from "@happeouikit/colors";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import {
  padding200,
  padding300,
  margin100,
  margin200,
  margin300,
} from "@happeouikit/layout";
import { radius500, animationCurve } from "@happeouikit/theme";
import { IconButton } from "@happeouikit/buttons";
import { IconArrowDropDown } from "@happeouikit/icons";
import React from "react";
import styled from "styled-components";

const ExpandableList = ({ items, handleClick }) => {
  const generateRow = (rowData, open, setOpen) => {
    const handleExpand = () => {
      const set = new Set(open);
      set.add(rowData.id);
      setOpen(set);
    };

    const handleCollapse = () => {
      const set = new Set(open);
      set.delete(rowData.id);
      setOpen(set);
    };

    return (
      <div>
        <ItemWrapper isActive={open.has(rowData.id)}>
          <ButtonWrapper
            shouldRotate={open.has(rowData.id)}
            onClick={() => handleClick && handleClick(rowData)}
          >
            {rowData.icon && (
              <CustomIconWrapper isExpanded={open.has(rowData.id)}>
                {rowData.icon}
              </CustomIconWrapper>
            )}
            {!rowData.element ? (
              <StyledLink
                isExpanded={open.has(rowData.id)}
                href={rowData.href}
                hasSubs={rowData.subs && rowData.subs.length > 0}
              >
                {rowData.label}
              </StyledLink>
            ) : (
              <CustomElementWrapper>{rowData.element}</CustomElementWrapper>
            )}

            {rowData.subs && rowData.subs.length > 0 && (
              <StyledIconButton
                isExpanded={open.has(rowData.id)}
                onClick={!open.has(rowData.id) ? handleExpand : handleCollapse}
                icon={IconArrowDropDown}
                isActionIcon
                hasCustomIcon={rowData.icon}
              />
            )}
          </ButtonWrapper>
        </ItemWrapper>
        {rowData.subs && (
          <CSSTransition
            in={rowData.subs.length > 0 && open.has(rowData.id)}
            classNames="expandable-list"
            timeout={200}
            unmountOnExit
          >
            <ListWrapper>
              {rowData.subs.map((child) => {
                if (child.subs && child.subs.length > 0) {
                  return (
                    <li key={child.id}>{generateRow(child, open, setOpen)}</li>
                  );
                }
                return (
                  <li key={child.id}>
                    {child.element ? (
                      <CustomElementWrapper>
                        {child.element}
                      </CustomElementWrapper>
                    ) : (
                      generateRow(child, open, setOpen)
                    )}
                  </li>
                );
              })}
            </ListWrapper>
          </CSSTransition>
        )}
      </div>
    );
  };

  const [open, setOpen] = React.useState(new Set());
  return items.map((item) => (
    <div key={item.id}>{generateRow(item, open, setOpen)}</div>
  ));
};

const ListWrapper = styled.ul`
  padding-left: ${padding300};
  margin: 0;
  list-style-type: none;
  &.expandable-list-enter {
    transform: scaleY(0);
    overflow: hidden;
  }
  &.expandable-list-enter-active,
  &.expandable-list-enter-done {
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 200ms ${animationCurve};
  }
  &.expandable-list-exit {
    transform-origin: top;
    transform: scaleY(1);
  }
  &.expandable-list-exit-active {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 200ms ${animationCurve};
  }
`;

const ItemWrapper = styled.div`
  button {
    color: ${({ isActive }) => (isActive ? active : gray01)};
    width: 100%;
    &:hover {
      color: ${active};
    }
    display: flex;
    & > div:first-child {
      width: 100%;
      text-align: left;
      p {
        width: 100%;
      }
      svg {
        fill: ${({ isActive }) => (isActive ? active : gray03)};
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: ${margin200};
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    margin: ${margin200} 0px;

    svg {
      transition: transform 400ms ${animationCurve};
      transform: ${({ shouldRotate }) =>
        shouldRotate ? "rotate(0)" : "rotate(-90deg)"};
      fill: ${({ shouldRotate }) => (shouldRotate ? active : gray03)};
    }
  }
`;

const StyledLink = styled.a`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${gray01};
  width: 100%;
  margin-left: ${margin100};
  padding: ${padding200};
  ${({ hasSubs }) =>
    hasSubs &&
    `
      padding-left: 36px;
    `}
  text-decoration: none;
  background: ${({ isExpanded }) =>
    isExpanded ? lighten90(active) : "inherit"};

  border-radius: ${radius500};
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isExpanded }) =>
    isExpanded &&
    `
    color: ${active};
    font-weight: 500;
  `}
  &:hover {
    color: ${active};
    background: ${lighten90(active)};
  }

  ${ButtonWrapper}:hover & {
    color: ${active};
    background: ${lighten90(active)};
  }
`;

const StyledIconButton = styled(IconButton)`
  transition: opacity 400ms ${animationCurve};
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 0;
  ${({ hasCustomIcon }) => (hasCustomIcon ? "right: 0" : "left: 0")};

  &:hover {
    & > svg {
      fill: ${active};
    }
  }

  ${ButtonWrapper}:hover & {
    & > svg {
      fill: ${active};
    }
  }
`;

const CustomIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 400ms ${animationCurve};
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  pointer-events: none;

  & > svg {
    fill: ${({ isExpanded }) => (isExpanded ? active : gray03)};
    height: 20px;
    width: 20px;
    margin-left: ${margin300};
  }

  ${ButtonWrapper}:hover & {
    & > svg {
      fill: ${active};
    }
  }
`;

const CustomElementWrapper = styled.div`
  padding-left: 36px;
`;

const itemShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string,
  href: PropTypes.string,
});
itemShape.subs = PropTypes.arrayOf(PropTypes.shape(itemShape));

ExpandableList.propTypes = {
  items: PropTypes.arrayOf(itemShape),
  handleClick: PropTypes.func,
};

ExpandableList.defaultProps = {
  items: [],
};

export default ExpandableList;
