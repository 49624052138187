import { jsx as e } from "react/jsx-runtime";
const t = (i) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...i,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.173 2.761a10 10 0 1 1 7.654 18.478A10 10 0 0 1 8.173 2.761ZM8 11a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8Z"
      }
    )
  }
);
export {
  t as default
};
