import { jsx as l } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ l("path", { d: "m17.71 9.12-2.83-2.83c-.2-.19-.45-.29-.71-.29-.26 0-.51.1-.71.29L2.29 17.46a.996.996 0 0 0 0 1.41l2.83 2.83c.2.2.45.3.71.3.26 0 .51-.1.71-.29l11.17-11.17c.39-.39.39-1.03 0-1.42Zm-3.54-.7 1.41 1.41L14.41 11 13 9.59l1.17-1.17ZM5.83 19.59l-1.41-1.41L11.59 11 13 12.41l-7.17 7.18ZM20.94 4.94 20.45 6c-.17.39-.73.39-.9 0l-.49-1.05L18 4.46a.5.5 0 0 1 0-.91l1.05-.49.49-1.06a.5.5 0 0 1 .91 0l.49 1.05 1.06.49a.5.5 0 0 1 0 .91l-1.06.49ZM9.44 4.94 8.95 6c-.17.39-.73.39-.9 0l-.49-1.05-1.06-.49a.5.5 0 0 1 0-.91l1.06-.49L8.05 2c.17-.39.73-.39.9 0l.49 1.06 1.06.48a.5.5 0 0 1 0 .91l-1.06.49ZM19.06 14.56l.49-1.06c.17-.39.73-.39.9-.01l.49 1.06 1.06.49a.5.5 0 0 1 0 .91l-1.05.5-.49 1.06a.5.5 0 0 1-.91 0l-.49-1.06-1.06-.49a.5.5 0 0 1 0-.91l1.06-.49Z" })
  }
);
export {
  t as default
};
