import { jsxs as a, jsx as l } from "react/jsx-runtime";
const d = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M15.57 21a5.587 5.587 0 0 0 5.606-5.551V8.55A5.587 5.587 0 0 0 15.57 3H8.605A5.585 5.585 0 0 0 3 8.551v6.898A5.585 5.585 0 0 0 8.605 21h6.965Z",
          fill: "#FFFFFE"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M13.078 9.494v2.494a.511.511 0 0 0 .515.51h1.678a.508.508 0 0 0 .51-.51v-1.664a.507.507 0 0 0-.51-.51H14.1v-.326c.006-.64.529-1.154 1.17-1.152a.51.51 0 1 0 0-1.02 2.18 2.18 0 0 0-2.193 2.172v.006Zm-4.683 0v2.494a.509.509 0 0 0 .513.51h1.677a.513.513 0 0 0 .515-.51v-1.664a.51.51 0 0 0-.515-.51H9.423v-.326a1.163 1.163 0 0 1 1.162-1.152.51.51 0 1 0 0-1.02 2.18 2.18 0 0 0-2.19 2.172v.006Zm.616 5.336a.248.248 0 0 0-.208.37c.045.077 1.133 1.914 3.3 1.914 2.166 0 3.235-1.838 3.285-1.915a.251.251 0 0 0 0-.247.243.243 0 0 0-.212-.122H9.01Z",
          fill: "#0A0B09"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M8.605 3A5.585 5.585 0 0 0 3 8.551v6.898A5.585 5.585 0 0 0 8.605 21h4.725l-3.113-2.22H8.605a3.351 3.351 0 0 1-3.367-3.331V8.55A3.363 3.363 0 0 1 8.605 5.22h6.965a3.363 3.363 0 0 1 3.366 3.332v6.898a3.363 3.363 0 0 1-3.366 3.331h-1.323V21h1.323a5.587 5.587 0 0 0 5.606-5.551V8.55A5.587 5.587 0 0 0 15.57 3H8.605Z",
          fill: "#FF5551"
        }
      )
    ]
  }
);
export {
  d as default
};
