import { jsxs as l, jsx as t } from "react/jsx-runtime";
const o = (a) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M6.116 15.07v5.116c0 1.413 3.78 2.558 8.442 2.558 4.663 0 8.442-1.145 8.442-2.558V15.07H6.116Z",
          fill: "#881421"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M6.116 9.442v5.628c0 1.412 3.78 2.558 8.442 2.558 4.663 0 8.442-1.146 8.442-2.558V9.442H6.116Z",
          fill: "#AF2031"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M6.116 3.814v5.628c0 1.412 3.78 2.558 8.442 2.558C19.221 12 23 10.854 23 9.442V3.814H6.116Z",
          fill: "#C94F60"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M14.558 6.372C19.22 6.372 23 5.227 23 3.814c0-1.413-3.78-2.558-8.442-2.558-4.662 0-8.442 1.145-8.442 2.558 0 1.413 3.78 2.558 8.442 2.558Z",
          fill: "#E08095"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.1,
          d: "M12.341 5.86H6.116v12.791h6.225a.94.94 0 0 0 .938-.938V6.798a.94.94 0 0 0-.938-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.83 6.372H6.116v12.79h5.714a.941.941 0 0 0 .937-.937V7.31a.94.94 0 0 0-.937-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.83 6.372H6.116V18.14h5.714a.94.94 0 0 0 .937-.938V7.31a.94.94 0 0 0-.937-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.318 6.372H6.116V18.14h5.202a.94.94 0 0 0 .938-.938V7.31a.94.94 0 0 0-.938-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M1.938 6.372h9.38c.518 0 .938.42.938.938v9.38c0 .518-.42.938-.938.938h-9.38A.938.938 0 0 1 1 16.69V7.31c0-.518.42-.938.938-.938Z",
          fill: "url(#microsoft-access_svg__a)"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M5.954 8.951h1.382l2.219 6.097H8.25l-.489-1.454H5.478l-.48 1.454H3.7l2.253-6.097Zm1.514 3.704-.71-2.207a2.895 2.895 0 0 1-.13-.498h-.026a2.817 2.817 0 0 1-.127.512l-.716 2.194 1.709-.001Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ l(
        "linearGradient",
        {
          id: "microsoft-access_svg__a",
          x1: 2.955,
          y1: 5.639,
          x2: 10.3,
          y2: 18.361,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ t("stop", { stopColor: "#B72C3F" }),
            /* @__PURE__ */ t("stop", { offset: 0.5, stopColor: "#B12334" }),
            /* @__PURE__ */ t("stop", { offset: 1, stopColor: "#9D1623" })
          ]
        }
      ) })
    ]
  }
);
export {
  o as default
};
