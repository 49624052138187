import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 18 12",
    xmlns: "http://www.w3.org/2000/svg",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m2.488 10.804 4.417-4.417 2.708 2.708a.827.827 0 0 0 1.209-.033l5.975-6.725a.837.837 0 0 0-.034-1.142.834.834 0 0 0-1.208.034L10.23 7.212 7.488 4.47a.83.83 0 0 0-1.175 0L1.238 9.554a.83.83 0 0 0 0 1.175l.075.075a.83.83 0 0 0 1.175 0Z",
      fill: "#434F59"
    }
  )
);
export {
  a as default
};
