/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleDocs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m14.5 6.5 3.016.57L20 6.5 14.5 1l-.759 2.607L14.5 6.5Z"
      fill="#2B313B"
    />
    <path d="M14.5 6.5V1h-9A1.5 1.5 0 0 0 4 2.5v19A1.5 1.5 0 0 0 5.5 23h13a1.5 1.5 0 0 0 1.5-1.5v-15h-5.5ZM14 16.75H7.5V15.5H14v1.25Zm2.5-3h-9V12.5h9v1.25Zm0-3h-9V9.5h9v1.25Z" />
  </svg>
);

export default GoogleDocs;
