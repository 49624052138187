import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M12 9.476a1.03 1.03 0 0 1-.7-.275L8.2 6.1a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275L12 7.1l2.4-2.4a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-3.1 3.1c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063ZM8.2 19.3a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7l3.1-3.1c.1-.1.208-.17.325-.212a1.1 1.1 0 0 1 .375-.063c.133 0 .263.02.388.063a.674.674 0 0 1 .312.212l3.125 3.125a.89.89 0 0 1 .262.687.976.976 0 0 1-.287.688.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275L12 16.9l-2.4 2.4a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Z" })
  }
);
export {
  t as default
};
