import { useState } from "react";
import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../../../../../packages/navigation/package.json";
import { Tabs, SideNavigation } from "../../../../../packages/navigation/src/index";
import { BrowserRouter } from "react-router-dom";
import { IconStar } from "@happeouikit/icons";
import menuItems from "../../../../../packages/navigation/docs/menuItems.js";
import ScrollExampleBox from "../../../../../packages/navigation/src/examples/ScrollExampleBox";
import * as React from 'react';
export default {
  useState,
  Playground,
  Props,
  PackageMetadata,
  pkg,
  Tabs,
  SideNavigation,
  BrowserRouter,
  IconStar,
  menuItems,
  ScrollExampleBox,
  React
};