import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/toast/package.json";
import { ToastContainer, toast } from "../../../../../packages/toast/src";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  ToastContainer,
  toast,
  React
};