/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const AdobeAcrobat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.37 3h11.635c1.768 0 3.182 1.467 3.182 3.3v11.4c0 1.833-1.414 3.3-3.182 3.3H6.37c-1.767 0-3.182-1.467-3.182-3.3V6.3C3.187 4.467 4.602 3 6.37 3Z"
      fill="#FA0F00"
    />
    <path
      d="M17.74 13.4c-.867-.9-3.234-.533-3.8-.467-.834-.8-1.4-1.766-1.6-2.1.3-.9.5-1.8.533-2.766 0-.834-.334-1.734-1.267-1.734-.333 0-.633.2-.8.467-.4.7-.233 2.1.4 3.533-.367 1.034-.7 2.034-1.633 3.8-.967.4-3 1.334-3.167 2.334-.067.3.033.6.267.833.233.2.533.3.833.3 1.233 0 2.433-1.7 3.267-3.133.7-.234 1.8-.567 2.9-.767 1.3 1.133 2.433 1.3 3.033 1.3.8 0 1.1-.333 1.2-.633.167-.334.067-.7-.167-.967Zm-.834.567c-.033.233-.333.466-.867.333a4.57 4.57 0 0 1-1.7-.867c.434-.066 1.4-.166 2.1-.033.267.067.534.233.467.567ZM11.339 7.1c.067-.1.167-.167.267-.167.3 0 .367.367.367.667-.034.7-.167 1.4-.4 2.067-.5-1.334-.4-2.267-.234-2.567Zm-.066 6.467c.266-.534.633-1.467.766-1.867.3.5.8 1.1 1.067 1.367 0 .033-1.033.233-1.833.5ZM9.306 14.9c-.767 1.267-1.567 2.067-2 2.067a.473.473 0 0 1-.2-.067c-.1-.067-.133-.167-.1-.3.1-.467.967-1.1 2.3-1.7Z"
      fill="#fff"
    />
  </svg>
);

export default AdobeAcrobat;
