/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Productboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m9.222 12.292 6.222 6.125H3l6.222-6.125Z" fill="#FF2638" />
    <path d="m3 6.167 6.222 6.125 6.222-6.125H3Z" fill="#FFC600" />
    <path
      d="m9.223 12.292 6.222 6.125 6.222-6.125-6.222-6.125-6.222 6.125Z"
      fill="#0079F2"
    />
  </svg>
);

export default Productboard;
