import { jsx as h } from "react/jsx-runtime";
const a = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M2.65 19c-.45 0-.783-.15-1-.45-.217-.3-.242-.667-.075-1.1l4.25-11.3a1.87 1.87 0 0 1 .688-.812A1.751 1.751 0 0 1 7.525 5c.333 0 .658.113.975.338.317.225.542.495.675.812L13.4 17.425c.167.433.146.804-.062 1.113-.209.308-.555.462-1.038.462a.97.97 0 0 1-.55-.188.949.949 0 0 1-.375-.462l-1.05-2.925h-5.65l-1.025 2.9a.998.998 0 0 1-.387.475c-.192.133-.396.2-.613.2Zm2.75-5.6h4.2L7.55 7.6h-.1L5.4 13.4ZM19 16a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 15v-2h-2a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 12c0-.283.096-.521.288-.713A.967.967 0 0 1 16 11h2V9c0-.283.096-.521.288-.713A.967.967 0 0 1 19 8c.283 0 .52.096.712.287.192.192.288.43.288.713v2h2c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 22 13h-2v2c0 .283-.096.52-.288.712A.965.965 0 0 1 19 16Z" })
  }
);
export {
  a as default
};
