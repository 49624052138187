import * as React from "react";
const SvgIconStarBorder = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.809 8.875 4.84.42c.88.07 1.23 1.17.56 1.75l-3.67 3.18 1.1 4.73c.2.86-.73 1.54-1.49 1.08l-4.15-2.51-4.15 2.5c-.76.46-1.69-.22-1.49-1.08l1.1-4.72-3.67-3.18c-.67-.58-.31-1.68.57-1.75l4.83-.41 1.89-4.46c.34-.81 1.5-.81 1.84 0l1.89 4.45Zm-6.57 9.05 3.76-2.27 3.77 2.28-1-4.28 3.32-2.88-4.38-.38-1.71-4.04-1.7 4.03-4.38.38 3.32 2.88-1 4.28Z"
    />
  </svg>
);
export default SvgIconStarBorder;
