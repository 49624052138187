import { jsx as h } from "react/jsx-runtime";
const A = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M10 16a.968.968 0 0 0 .713-.288A.967.967 0 0 0 11 15v-2h2a.968.968 0 0 0 .713-.288A.967.967 0 0 0 14 12a.97.97 0 0 0-.287-.713A.97.97 0 0 0 13 11h-2V9a.97.97 0 0 0-.287-.713A.97.97 0 0 0 10 8a.967.967 0 0 0-.712.287A.968.968 0 0 0 9 9v2H7a.97.97 0 0 0-.713.287A.97.97 0 0 0 6 12c0 .283.096.52.287.712.192.192.43.288.713.288h2v2c0 .283.096.52.288.712A.965.965 0 0 0 10 16Zm-6 4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h12c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v4.5l3.15-3.15c.15-.15.33-.188.538-.113.208.075.312.23.312.463v8.6c0 .233-.104.387-.312.462-.209.075-.388.038-.538-.112L18 13.5V18c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 16 20H4Zm0-2h12V6H4v12Zm0 0V6v12Z" })
  }
);
export {
  A as default
};
