import * as React from "react";
const SvgLogoGoogleFonts = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M3 26.447 15.89 6h8.002v2.845L12.779 26.447" fill="#FBBC04" />
    <path d="M23.892 26.447H15.89V6h8v20.447Z" fill="#1A73E8" />
    <path
      d="M29.67 20.668a5.778 5.778 0 0 1-5.78 5.779V14.89a5.778 5.778 0 0 1 5.78 5.778Z"
      fill="#34A853"
    />
    <path
      d="M23.892 26.447a5.778 5.778 0 1 1 0-11.557v11.557Z"
      fill="#0D652D"
    />
    <path
      d="M28.336 10.445a4.445 4.445 0 0 1-4.445 4.445V6a4.445 4.445 0 0 1 4.445 4.445Z"
      fill="#1A73E8"
    />
    <path d="M23.89 14.89a4.445 4.445 0 1 1 0-8.89v8.89Z" fill="#174EA6" />
    <path d="M3.445 10a4 4 0 1 1 8.002.001 4 4 0 0 1-8.002 0Z" fill="#EA4335" />
  </svg>
);
export default SvgLogoGoogleFonts;
