/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GooglePdf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5Z"
      fill="#D53F40"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 9a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 0 1.5 0V13H8a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H6c-.045 0-.09.003-.134.009A.755.755 0 0 0 5.75 9Zm.75 1.5h1v1h-1v-1ZM10 9.25a.25.25 0 0 1 .25-.25h2.25a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-2.25a.25.25 0 0 1-.25-.25v-5.5Zm2.5 1.25h-1v3h1v-3Z"
      fill="#fff"
    />
    <path
      d="M15.75 9a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 0 1.5 0V13h.75a.75.75 0 0 0 0-1.5h-.75v-1h1.75a.75.75 0 0 0 0-1.5h-2.5Z"
      fill="#fff"
    />
  </svg>
);

export default GooglePdf;
