import { jsxs as a, jsx as h } from "react/jsx-runtime";
const d = (v) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: [
      /* @__PURE__ */ h("path", { d: "M6.5 10.5h1v1h-1v-1ZM12.5 10.5h-1v3h1v-3Z" }),
      /* @__PURE__ */ h(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14ZM5.75 9a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 0 1.5 0V13H8a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H6c-.045 0-.09.003-.134.009A.755.755 0 0 0 5.75 9Zm4.25.25a.25.25 0 0 1 .25-.25h2.25a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-2.25a.25.25 0 0 1-.25-.25v-5.5Zm5 .5a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5H16.5v1h.75a.75.75 0 0 1 0 1.5h-.75v1.25a.75.75 0 0 1-1.5 0v-4.5Z"
        }
      )
    ]
  }
);
export {
  d as default
};
