import { jsxs as e, jsx as t } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M10.89 2.089c1.12-.17 2.21-.08 3.21.23 2.69.82 4.64 3.28 4.87 6.09.16 1.97-.49 3.78-1.66 5.14-.9 1.05-1.32 2.42-1.32 3.8v.18c0 .81-.66 1.47-1.47 1.47H9.47c-.81 0-1.47-.66-1.47-1.47 0-1.43-.32-2.85-1.26-3.92-1.37-1.56-2.06-3.73-1.59-6.06.58-2.89 2.83-5.02 5.74-5.46Zm3.12 14.67c.1-1.63.74-3.22 1.88-4.63A4.89 4.89 0 0 0 17 8.999c0-2.76-2.24-5-5-5-.34 0-.68.03-1.02.09-1.93.38-3.53 1.99-3.89 3.93-.29 1.53.09 3.02 1.07 4.18 1.04 1.24 1.67 2.84 1.81 4.57.01.13.11.23.24.23h3.56c.13 0 .23-.1.24-.24Z"
        }
      ),
      /* @__PURE__ */ t("path", { d: "M9.25 20h5.5c.14 0 .25.11.25.25 0 .97-.78 1.75-1.75 1.75h-2.5C9.78 22 9 21.22 9 20.25c0-.14.11-.25.25-.25Z" })
    ]
  }
);
export {
  l as default
};
