import * as e from "react";
const t = (l) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 32",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "icon-file-g-slides-file_svg__c",
      x1: "0%",
      y1: "0%",
      y2: "100%"
    },
    /* @__PURE__ */ e.createElement("stop", { stopOpacity: 0.2, offset: "0%" }),
    /* @__PURE__ */ e.createElement("stop", { stopOpacity: 0, offset: "100%" })
  ), /* @__PURE__ */ e.createElement(
    "filter",
    {
      id: "icon-file-g-slides-file_svg__a",
      width: "133.3%",
      height: "133.3%",
      x: "-16.7%",
      y: "-16.7%",
      filterUnits: "objectBoundingBox"
    },
    /* @__PURE__ */ e.createElement("feOffset", { in: "SourceAlpha", result: "shadowOffsetOuter1" }),
    /* @__PURE__ */ e.createElement(
      "feGaussianBlur",
      {
        stdDeviation: 0.5,
        in: "shadowOffsetOuter1",
        result: "shadowBlurOuter1"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feComposite",
      {
        in: "shadowBlurOuter1",
        in2: "SourceAlpha",
        operator: "out",
        result: "shadowBlurOuter1"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0",
        in: "shadowBlurOuter1"
      }
    )
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      id: "icon-file-g-slides-file_svg__b",
      d: "M24 9h-6a3 3 0 0 1-3-3V0l9 9Z"
    }
  )),
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#F5BA15",
      d: "M3 0h12l9 9v20a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
    }
  ), /* @__PURE__ */ e.createElement(
    "use",
    {
      fill: "#000",
      filter: "url(#icon-file-g-slides-file_svg__a)",
      xlinkHref: "#icon-file-g-slides-file_svg__b"
    }
  ), /* @__PURE__ */ e.createElement(
    "use",
    {
      fill: "#FFF",
      fillOpacity: 0.4,
      style: {
        mixBlendMode: "lighten"
      },
      xlinkHref: "#icon-file-g-slides-file_svg__b"
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "url(#icon-file-g-slides-file_svg__c)",
      d: "M24 9h-6a2.991 2.991 0 0 1-2.159-.917c-.347-.36 2.373 2.28 8.159 7.917V9Z",
      style: {
        mixBlendMode: "hard-light"
      }
    }
  ), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#F2F2F2",
      d: "M7 14h10a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V15a1 1 0 0 1 1-1Zm.5 3.5v5h9v-5h-9Z"
    }
  ))
);
export {
  t as default
};
