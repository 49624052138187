import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M20 20a.965.965 0 0 1-.712-.288A.965.965 0 0 1 19 19V3H7a.968.968 0 0 1-.713-.288A.967.967 0 0 1 6 2a.97.97 0 0 1 .287-.713A.97.97 0 0 1 7 1h12c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v16c0 .283-.096.52-.288.712A.965.965 0 0 1 20 20ZM4.4 22.4a.99.99 0 0 1-.95-.088c-.3-.191-.45-.47-.45-.837V7c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 5 5h10c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v14.475c0 .367-.15.646-.45.837a.99.99 0 0 1-.95.088L10 20l-5.6 2.4Z" })
  }
);
export {
  h as default
};
