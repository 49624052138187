import * as React from "react";
const SvgIconTag = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m21.42 12.41-9 9c-.36.36-.87.59-1.42.59-.55 0-1.05-.22-1.41-.59l-7-7C2.22 14.05 2 13.55 2 13c0-.55.22-1.05.58-1.41l9-9c.37-.37.87-.59 1.42-.59h7c1.1 0 2 .9 2 2v7c0 .55-.22 1.05-.58 1.41ZM18.5 4c-.83 0-1.5.67-1.5 1.5S17.67 7 18.5 7 20 6.33 20 5.5 19.33 4 18.5 4Z"
    />
  </svg>
);
export default SvgIconTag;
