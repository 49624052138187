import { jsxs as s, jsx as h } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ h(
        "path",
        {
          d: "M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z",
          fill: "#479B5F"
        }
      ),
      /* @__PURE__ */ h("path", { d: "M9 9V5h2v4h8v2h-8v8H9v-8H5V9h4Z", fill: "#fff" })
    ]
  }
);
export {
  e as default
};
