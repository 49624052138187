import { jsx as c } from "react/jsx-runtime";
const t = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M15 21c-1.967 0-3.717-.558-5.25-1.675S7.133 16.767 6.5 15H4a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 14c0-.283.096-.52.288-.713A.967.967 0 0 1 4 13h2.05c-.05-.4-.07-.77-.063-1.113.009-.341.03-.637.063-.887H4a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 10c0-.283.096-.52.288-.713A.968.968 0 0 1 4 9h2.5c.633-1.767 1.717-3.208 3.25-4.325S13.033 3 15 3c.967 0 1.887.142 2.762.425A8.928 8.928 0 0 1 20.2 4.65a.844.844 0 0 1 .375.662A.821.821 0 0 1 20.3 6c-.2.2-.442.304-.725.313A1.328 1.328 0 0 1 18.8 6.1a7.092 7.092 0 0 0-1.788-.813A6.994 6.994 0 0 0 15 5c-1.417 0-2.683.37-3.8 1.112A7.09 7.09 0 0 0 8.675 9H14c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 14 11H8.075c-.067.45-.092.846-.075 1.188.017.341.042.612.075.812H14c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 14 15H8.675a7.091 7.091 0 0 0 2.525 2.887C12.317 18.63 13.583 19 15 19c.7 0 1.37-.1 2.012-.3a7.43 7.43 0 0 0 1.788-.825 1.26 1.26 0 0 1 .775-.2c.283.017.525.125.725.325a.82.82 0 0 1 .275.688.844.844 0 0 1-.375.662 8.928 8.928 0 0 1-2.438 1.225A8.912 8.912 0 0 1 15 21Z" })
  }
);
export {
  t as default
};
