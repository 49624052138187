import { jsx as a } from "react/jsx-runtime";
const c = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M12 20.525c-.217 0-.429-.037-.637-.112a2.355 2.355 0 0 1-.588-.313l-6.75-5.25a.906.906 0 0 1-.387-.788.97.97 0 0 1 .412-.787.999.999 0 0 1 1.2 0L12 18.5l6.75-5.225a.999.999 0 0 1 1.2 0c.267.2.404.463.413.787a.909.909 0 0 1-.388.788l-6.75 5.25a2.36 2.36 0 0 1-.587.313 1.88 1.88 0 0 1-.638.112Zm0-5.05c-.217 0-.429-.037-.637-.112a2.355 2.355 0 0 1-.588-.313L4.025 9.8a.963.963 0 0 1-.3-.362 1.02 1.02 0 0 1 0-.876.963.963 0 0 1 .3-.362l6.75-5.25c.183-.133.38-.237.588-.313a1.872 1.872 0 0 1 1.275 0c.208.076.404.18.587.313l6.75 5.25c.133.1.233.221.3.362a1.02 1.02 0 0 1 0 .876.963.963 0 0 1-.3.362l-6.75 5.25a2.36 2.36 0 0 1-.587.313 1.88 1.88 0 0 1-.638.112Z" })
  }
);
export {
  c as default
};
