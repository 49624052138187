import { useState, createElement } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IconArrowDropDown } from "@happeouikit/icons";
import { NavLink, useLocation } from "react-router-dom";
import { active, gray04, lighten90 } from "@happeouikit/colors";
import { margin300, padding200 } from "@happeouikit/layout";
import { animationCurve, animationFast } from "@happeouikit/theme";
import { sansFamily } from "@happeouikit/typography";

const SideNavigation = ({ menuItems, withUrlParameters }) => {
  const { pathname } = window.location;
  const setMenuPaths = (menuItems, state) => {
    if (menuItems && state) {
      menuItems.forEach(({ url, children, id }) => {
        // eslint-disable-next-line no-param-reassign
        state[id] = pathname.includes(url);

        if (children) {
          setMenuPaths(children, state);
        }
      });
    }

    return state;
  };

  const { search } = useLocation();
  const parameters = withUrlParameters ? search : "";

  const [collapsedState, setCollapsedState] = useState(
    setMenuPaths(menuItems, {})
  );

  const handleClick = (item) => {
    setCollapsedState((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const renderIcon = (icon) =>
    icon &&
    createElement(icon, {
      style: {
        width: "20px",
        height: "20px",
        marginRight: margin300,
      },
    });

  const returnMenu = (menuList, firstLevel) =>
    menuList.map((menuItem) => {
      const { icon, name, url, exact, children, id, dataTracker } = menuItem;
      // we want to keep dropdowns expanded if you click on another one but not active
      const isSelected = collapsedState[id] && pathname.includes(url);

      if (!menuItem.children) {
        return (
          <MenuItem
            key={name}
            $isSelected={isSelected}
            onClick={() => handleClick(id)}
          >
            <MenuLink
              to={url + parameters}
              end={exact}
              $firstLevel={firstLevel}
              data-tracker={dataTracker}
            >
              <DropdownPlaceholder />
              {icon && renderIcon(icon)}
              {name}
            </MenuLink>
          </MenuItem>
        );
      }

      return (
        <MenuItem key={name}>
          <MenuLinkWithDropdown
            to={children[0].url + parameters}
            end={exact}
            onClick={() => handleClick(id)}
            $isSelected={isSelected}
            data-tracker={dataTracker}
          >
            <DropdownIcon
              data-testid="dropdownIcon"
              $isCollapsed={collapsedState[id]}
            />
            {icon && renderIcon(icon)}
            {name}
          </MenuLinkWithDropdown>
          {collapsedState[id] && (
            <Menu nestedItems>{returnMenu(children)}</Menu>
          )}
        </MenuItem>
      );
    });

  return <Menu>{returnMenu(menuItems, true)}</Menu>;
};

const Menu = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin: 0;
  transition: transform ${animationFast} ${animationCurve};
  transform-origin: top;

  a {
    padding-left: ${({ nestedItems }) => nestedItems && "36px"};
  }
`;

const MenuItem = styled.li`
  display: flex;
  flex-wrap: wrap;
`;

const MenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-family: ${sansFamily};
  width: 100%;
  padding: ${padding200};
  margin-bottom: ${margin300};
  border-radius: 6px;
  color: ${gray04};

  svg {
    fill: ${gray04};
  }

  &.active {
    color: ${active};
    background-color: ${lighten90(active)};
    font-weight: ${({ $firstLevel }) => !$firstLevel && "500"};

    svg {
      fill: ${active};
    }
  }
`;

const MenuLinkWithDropdown = styled(MenuLink)`
  &.active {
    background-color: transparent;
    font-weight: 400;
  }

  ${({ $isSelected }) =>
    $isSelected
      ? `
    color: ${active};
    svg {fill: ${active}}
  `
      : `
    color: ${gray04};
    svg {fill:${gray04}}
  `}
`;

const DropdownPlaceholder = styled.span`
  height: 24px;
  width: 24px;
`;

const DropdownIcon = styled(IconArrowDropDown)`
  transition: transform ${animationFast} ${animationCurve};
  transform: rotate(-90deg);
  height: 24px;
  width: 24px;
  fill: ${({ $isCollapsed }) => ($isCollapsed ? active : gray04)};
  ${({ $isCollapsed }) => $isCollapsed && "transform: rotate(0);"}
`;

SideNavigation.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      component: PropTypes.elementType,
      exact: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      dataTracker: PropTypes.string,
    })
  ),
  withUrlParameters: PropTypes.bool,
};

SideNavigation.defaultProps = {
  menuItems: [],
  withUrlParameters: false,
};

export default SideNavigation;
