import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 9.21V11H2c-.55 0-1 .45-1 1s.45 1 1 1h3v1.79c0 .45.54.67.85.36l2.79-2.79c.2-.2.2-.51 0-.71L5.85 8.86a.495.495 0 0 0-.85.35Zm14 5.58V13h3c.55 0 1-.45 1-1s-.45-1-1-1h-3V9.21c0-.45-.54-.67-.85-.35l-2.79 2.79c-.2.2-.2.51 0 .71l2.79 2.79a.5.5 0 0 0 .85-.36ZM13 19c0 .55-.45 1-1 1s-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v14Z"
    }
  )
);
export {
  c as default
};
