import * as e from "react";
const v = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M6 13h12v-2H6v2ZM3 6v2h18V6H3Zm7 12h4v-2h-4v2Z",
      fillRule: "evenodd"
    }
  )
);
export {
  v as default
};
