import { jsx as A } from "react/jsx-runtime";
const c = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M12 21a5.925 5.925 0 0 1-3.012-.8A5.835 5.835 0 0 1 6.8 18H4.975a.926.926 0 0 1-.7-.287A.99.99 0 0 1 4 17c0-.284.096-.521.287-.712A.968.968 0 0 1 5 16h1.1a8.035 8.035 0 0 1-.087-1A38.458 38.458 0 0 1 6 14H4.975a.926.926 0 0 1-.7-.288A.99.99 0 0 1 4 13a.97.97 0 0 1 .287-.713A.97.97 0 0 1 5 12h1c0-.334.004-.667.013-1 .008-.334.037-.667.087-1H4.975a.926.926 0 0 1-.7-.288A.99.99 0 0 1 4 9a.97.97 0 0 1 .287-.713A.97.97 0 0 1 5 8h1.8c.233-.384.496-.742.788-1.075A4.865 4.865 0 0 1 8.6 6.05l-.925-.95a.971.971 0 0 1-.262-.713A.975.975 0 0 1 7.7 3.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l1.45 1.45a4.63 4.63 0 0 1 2.85 0l1.5-1.475a.975.975 0 0 1 .713-.263.978.978 0 0 1 .687.288.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-.95.95c.383.25.73.537 1.038.862.308.325.579.688.812 1.088h1.825c.283 0 .517.095.7.287.183.192.275.43.275.713s-.096.52-.288.712A.965.965 0 0 1 19 10h-1.1c.05.333.08.666.088 1 .008.333.012.666.012 1h1.025c.283 0 .517.095.7.287.183.192.275.43.275.713s-.096.52-.288.712A.965.965 0 0 1 19 14h-1c0 .333-.004.666-.012 1a8.173 8.173 0 0 1-.088 1h1.125c.283 0 .517.096.7.288A.99.99 0 0 1 20 17c0 .283-.096.52-.288.712A.965.965 0 0 1 19 18h-1.8a5.837 5.837 0 0 1-2.187 2.2A5.93 5.93 0 0 1 12 21Zm0-2c1.1 0 2.042-.391 2.825-1.174C15.608 17.042 16 16.1 16 15v-4c0-1.1-.392-2.042-1.175-2.825C14.042 7.392 13.1 7 12 7s-2.042.391-2.825 1.175C8.392 8.959 8 9.9 8 11v4c0 1.1.392 2.041 1.175 2.825C9.958 18.609 10.9 19 12 19Zm-1-3h2.025a.926.926 0 0 0 .7-.287A.99.99 0 0 0 14 15a.97.97 0 0 0-.287-.713A.97.97 0 0 0 13 14h-2.025a.928.928 0 0 0-.7.287A.993.993 0 0 0 10 15c0 .283.096.52.288.712A.965.965 0 0 0 11 16Zm0-4h2.025a.926.926 0 0 0 .7-.287A.99.99 0 0 0 14 11a.97.97 0 0 0-.287-.713A.97.97 0 0 0 13 10h-2.025a.928.928 0 0 0-.7.287A.993.993 0 0 0 10 11c0 .283.096.52.288.712A.965.965 0 0 0 11 12Z" })
  }
);
export {
  c as default
};
