import { jsx as e } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm-3.061-2.609c.97.402 2.01.609 3.061.609V4a8 8 0 0 0-3.061 15.391Z"
      }
    )
  }
);
export {
  t as default
};
