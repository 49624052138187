import { Loader } from "@happeouikit/loaders";
import { useIntl } from "react-intl";
import messages from "./messages";
import TypeaheadMessage, { ICON_SIZE } from "./TypeaheadMessage";

const FixedSizeLoader = () => (
  <Loader containerWidth={ICON_SIZE} containerHeight={ICON_SIZE} />
);

const TypeaheadLoading = ({ query }) => {
  const intl = useIntl();
  return (
    <TypeaheadMessage
      icon={FixedSizeLoader}
      title={intl.formatMessage(messages.typeaheadSearching)}
      details={intl.formatMessage(messages.typeaheadSearchingDetails, {
        query,
      })}
    />
  );
};

export default TypeaheadLoading;
