import { jsx as h } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M8 19.001v-4H7c-.55 0-1.02-.196-1.412-.588A1.924 1.924 0 0 1 5 13.001v-2c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 7 9.001h4l3.475-2.1c.333-.2.671-.2 1.013 0 .341.2.512.492.512.875v8.45c0 .383-.17.675-.512.875-.342.2-.68.2-1.013 0L11 15.001h-1v4H8Zm9-3.65v-6.7c.45.4.813.888 1.088 1.463.275.575.412 1.204.412 1.887s-.137 1.312-.412 1.887A4.571 4.571 0 0 1 17 15.351Z" })
  }
);
export {
  c as default
};
