import { jsxs as a, jsx as l } from "react/jsx-runtime";
const i = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M15.293 8.293a1 1 0 0 0 0 1.414L17.586 12l-2.293 2.293a1 1 0 0 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 0 0-1.414 0Z"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.5,
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M10.293 8.293a1 1 0 0 0 0 1.414L12.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 0 0-1.414 0Z"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.3,
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5.293 8.293a1 1 0 0 0 0 1.414L7.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 0 0-1.414 0Z"
        }
      )
    ]
  }
);
export {
  i as default
};
