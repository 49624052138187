import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../../../../../packages/image-cropper/package.json";
import { ImageCropper } from "../../../../../packages/image-cropper/src/index";
import ImageCropperExample from "../../../../../packages/image-cropper/docs/ImageCropperExample";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  ImageCropper,
  ImageCropperExample,
  React
};