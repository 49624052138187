import { jsx as t } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M7.184 19c-.45 0-.784-.15-1-.45-.217-.3-.242-.667-.075-1.1l4.25-11.3a1.87 1.87 0 0 1 .688-.812A1.751 1.751 0 0 1 12.059 5c.333 0 .658.113.975.338.316.225.541.495.675.812l4.225 11.275c.166.433.146.804-.062 1.113-.209.308-.555.462-1.038.462a.97.97 0 0 1-.55-.188.949.949 0 0 1-.375-.462l-1.05-2.925h-5.65l-1.025 2.9a.998.998 0 0 1-.387.475c-.192.133-.397.2-.613.2Zm2.75-5.6h4.2l-2.05-5.8h-.1l-2.05 5.8Z" })
  }
);
export {
  a as default
};
