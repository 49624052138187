import { jsx as c } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "m7.725 20.524-2.35.925a.944.944 0 0 1-.937-.1.948.948 0 0 1-.438-.825v-4.45c0-.333.08-.65.238-.95.158-.3.379-.541.662-.725l1.1-.725c.117 1.35.296 2.517.538 3.5.241.984.637 2.1 1.187 3.35Zm3.5-17.3c.1-.1.221-.17.363-.213.141-.041.279-.062.412-.062.133 0 .271.02.413.062a.842.842 0 0 1 .362.213c1.15 1.05 2.058 2.42 2.725 4.112a14.43 14.43 0 0 1 1 5.338c0 1.284-.108 2.409-.325 3.375-.217.967-.55 2.025-1 3.175-.083.217-.208.4-.375.55a.866.866 0 0 1-.6.225H9.8a.866.866 0 0 1-.6-.225 1.408 1.408 0 0 1-.375-.55c-.45-1.15-.783-2.212-1-3.187-.217-.975-.325-2.096-.325-3.363 0-1.866.333-3.646 1-5.338.667-1.691 1.575-3.062 2.725-4.112ZM12 13c.55 0 1.021-.196 1.413-.588.391-.391.587-.862.587-1.412 0-.55-.196-1.02-.587-1.413A1.928 1.928 0 0 0 12 9c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 10 11c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm4.275 7.525c.55-1.25.946-2.366 1.187-3.35.242-.983.421-2.15.538-3.5l1.1.725c.283.184.504.425.663.725.158.3.237.617.237.95v4.45c0 .35-.146.625-.438.825a.944.944 0 0 1-.937.1l-2.35-.925Z" })
  }
);
export {
  o as default
};
