import { jsx as c } from "react/jsx-runtime";
const a = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M1 18a.967.967 0 0 1-.713-.288A.968.968 0 0 1 0 17v-.575c0-.717.367-1.3 1.1-1.75C1.833 14.225 2.8 14 4 14c.217 0 .425.004.625.012.2.009.392.03.575.063-.233.35-.408.717-.525 1.1a4.098 4.098 0 0 0-.175 1.2V18H1Zm6 0a.967.967 0 0 1-.713-.288A.968.968 0 0 1 6 17v-.625c0-.533.146-1.02.438-1.463.291-.441.704-.829 1.237-1.162.533-.333 1.17-.583 1.913-.75.741-.167 1.545-.25 2.412-.25.883 0 1.696.083 2.438.25.741.167 1.379.417 1.912.75.533.333.942.72 1.225 1.162.283.442.425.93.425 1.463V17c0 .283-.096.52-.288.712A.968.968 0 0 1 17 18H7Zm12.5 0v-1.625c0-.433-.054-.842-.163-1.225a3.913 3.913 0 0 0-.487-1.075c.183-.033.37-.054.563-.063.191-.008.387-.012.587-.012 1.2 0 2.167.22 2.9.662.733.442 1.1 1.03 1.1 1.763V17c0 .283-.096.52-.288.712A.968.968 0 0 1 23 18h-3.5ZM4 13c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 2 11c0-.567.196-1.042.587-1.425A1.947 1.947 0 0 1 4 9c.567 0 1.042.192 1.425.575.383.383.575.858.575 1.425 0 .55-.192 1.02-.575 1.412A1.914 1.914 0 0 1 4 13Zm16 0c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 18 11c0-.567.196-1.042.587-1.425A1.947 1.947 0 0 1 20 9c.567 0 1.042.192 1.425.575.383.383.575.858.575 1.425 0 .55-.192 1.02-.575 1.412A1.914 1.914 0 0 1 20 13Zm-8-1a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 9 9c0-.85.292-1.563.875-2.138S11.167 6 12 6c.85 0 1.563.287 2.137.862C14.712 7.438 15 8.15 15 9c0 .833-.287 1.542-.863 2.125-.575.583-1.287.875-2.137.875Z" })
  }
);
export {
  a as default
};
