import { basicDesignTokens } from "../DesignTokens/designTokens";

// we add extra variable names that aren't in the export - numbers and basic sizes
export const breakpoint = {
  mobileSmLower: basicDesignTokens.breakpoint["mobile-sm-lower"],
  xs: basicDesignTokens.breakpoint["mobile-sm-lower"],
  "0": basicDesignTokens.breakpoint["mobile-sm-lower"],
  mobileSmUpper: basicDesignTokens.breakpoint["mobile-sm-upper"],
  mobileLgLower: basicDesignTokens.breakpoint["mobile-lg-lower"],
  sm: basicDesignTokens.breakpoint["mobile-lg-lower"],
  "1": basicDesignTokens.breakpoint["mobile-lg-lower"],
  mobileLgUpper: basicDesignTokens.breakpoint["mobile-lg-upper"],
  tabletLower: basicDesignTokens.breakpoint["tablet-lower"],
  md: basicDesignTokens.breakpoint["tablet-lower"],
  "2": basicDesignTokens.breakpoint["tablet-lower"],
  tabletUpper: basicDesignTokens.breakpoint["tablet-upper"],
  laptopSmLower: basicDesignTokens.breakpoint["laptop-sm-lower"],
  lg: basicDesignTokens.breakpoint["laptop-sm-lower"],
  "3": basicDesignTokens.breakpoint["laptop-sm-lower"],
  laptopSmUpper: basicDesignTokens.breakpoint["laptop-sm-upper"],
  laptopLgLower: basicDesignTokens.breakpoint["laptop-lg-lower"],
  xl: basicDesignTokens.breakpoint["laptop-lg-lower"],
  "4": basicDesignTokens.breakpoint["laptop-lg-lower"],
  laptopLgUpper: basicDesignTokens.breakpoint["laptop-lg-upper"],
  desktopLower: basicDesignTokens.breakpoint["desktop-lower"],
  xxl: basicDesignTokens.breakpoint["desktop-lower"],
  "5": basicDesignTokens.breakpoint["desktop-lower"],
  desktopUpper: basicDesignTokens.breakpoint["desktop-upper"],
} as const;
