import { jsx as c } from "react/jsx-runtime";
const s = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "m8.85 6-2-2H20c.283 0 .52.095.712.287.192.192.288.43.288.713s-.096.52-.288.712A.968.968 0 0 1 20 6H8.85ZM22 19l-2-2v-7h-4v3.15l-2-2V9c0-.284.096-.521.287-.713A.968.968 0 0 1 15 8h6c.283 0 .52.095.712.287.192.192.288.43.288.713v10Zm-7 1a.968.968 0 0 1-.713-.288A.968.968 0 0 1 14 19v-2.2l-8-8V17h4.5c.417 0 .77.145 1.063.437.291.292.437.646.437 1.063 0 .416-.146.77-.438 1.062A1.446 1.446 0 0 1 10.5 20h-7c-.417 0-.77-.146-1.062-.438A1.447 1.447 0 0 1 2 18.5c0-.417.146-.771.438-1.063A1.446 1.446 0 0 1 3.5 17H4V6.8L2.1 4.9a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l17 17a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275L17.2 20H15Z" })
  }
);
export {
  s as default
};
