import { jsx as a } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M8.854 18.146a.5.5 0 0 0 .353.854H11v3a1 1 0 1 0 2 0v-3h1.793a.5.5 0 0 0 .353-.854l-2.792-2.792a.5.5 0 0 0-.708 0l-2.792 2.792Zm6.292-12.292A.5.5 0 0 0 14.793 5H13V2a1 1 0 1 0-2 0v3H9.207a.5.5 0 0 0-.353.854l2.792 2.792a.5.5 0 0 0 .708 0l2.792-2.792ZM5 11a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H5Z" })
  }
);
export {
  e as default
};
