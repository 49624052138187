/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Google = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.766 12.248c0-.725-.064-1.412-.174-2.081H12.234V14.3h5.93c-.265 1.357-1.045 2.502-2.2 3.281v2.75h3.539c2.072-1.915 3.263-4.739 3.263-8.085Z"
      fill="#4285F4"
    />
    <path
      d="M12.234 23c2.97 0 5.454-.99 7.269-2.668l-3.538-2.75c-.99.66-2.246 1.064-3.731 1.064-2.87 0-5.299-1.934-6.17-4.547H2.417v2.833C4.222 20.525 7.935 23 12.234 23Z"
      fill="#34A853"
    />
    <path
      d="M6.064 14.1a6.383 6.383 0 0 1-.348-2.1c0-.733.128-1.44.348-2.1V7.069H2.416a10.872 10.872 0 0 0 0 9.864l3.648-2.833Z"
      fill="#FBBC05"
    />
    <path
      d="M12.234 5.354c1.622 0 3.07.56 4.216 1.65l3.135-3.135C17.688 2.091 15.204 1 12.234 1c-4.3 0-8.012 2.475-9.818 6.068l3.649 2.833c.87-2.613 3.3-4.547 6.169-4.547Z"
      fill="#EA4335"
    />
  </svg>
);

export default Google;
