import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { getValidChildrenArray, renderChildrenWithSeparators } from "./utils";

const Breadcrumbs = ({ children, Component, separator }) => {
  const allValidChildren = getValidChildrenArray(children);

  return (
    <Component>
      {renderChildrenWithSeparators(allValidChildren, separator)}
    </Component>
  );
};

const DefaultBreadcrumbsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

Breadcrumbs.propTypes  = {
  children: PropTypes.node,
  Component: PropTypes.elementType,
  separator: PropTypes.node,
};

Breadcrumbs.defaultProps = {
  children: [],
  Component: DefaultBreadcrumbsWrapper,
  separator: "/",
};

export default Breadcrumbs;
