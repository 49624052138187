/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Klarna = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.166 2H7.834C4.612 2 2 4.599 2 7.804v8.392C2 19.401 4.612 22 7.834 22h8.332C19.388 22 22 19.401 22 16.196V7.804C22 4.599 19.388 2 16.166 2Z"
      fill="#FFB3C7"
    />
    <path
      d="M6 6.06h2.635v11.713H6V6.059ZM15.347 6.06h-2.63a6.725 6.725 0 0 1-2.72 5.427l-1.041.78 4.037 5.506h3.319l-3.715-5.066a9.317 9.317 0 0 0 2.75-6.648ZM18.01 14.97a1.521 1.521 0 1 0 .935 2.896 1.521 1.521 0 0 0-.934-2.896Z"
      fill="#000"
    />
  </svg>
);

export default Klarna;
