/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z"
      fill="#F79232"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.61 8.578c.171-.742.46-1.297.867-1.664.453-.36.992-.539 1.617-.539.656 0 1.18.172 1.57.516.39.343.586.765.586 1.265 0 .375-.11.696-.328.961-.14.188-.578.578-1.313 1.172-.726.61-1.218 1.149-1.476 1.617-.235.492-.352 1.078-.352 1.758v.54l.024.14c0 .094.013.18.04.258.028.078.055.14.083.187a.342.342 0 0 0 .14.123l.153.082c.035.02.1.033.193.041.094.008.158.012.193.012H12c.258 0 .45-.01.574-.03a.595.595 0 0 0 .334-.175c.098-.098.147-.248.147-.451 0-.266.015-.54.047-.82.023-.29.07-.516.14-.68.102-.25.43-.602.985-1.055 1.046-.883 1.718-1.563 2.015-2.04.313-.507.469-1.03.469-1.57 0-1.015-.43-1.882-1.29-2.601-.835-.766-1.983-1.148-3.444-1.148-1.375 0-2.485.375-3.329 1.125-.773.656-1.218 1.476-1.335 2.46a.822.822 0 0 0-.024.235c0 .32.113.596.34.826.226.23.496.346.809.346.265 0 .503-.084.714-.252.211-.168.348-.381.41-.639h.047Zm2.39 7.57c-.469 0-.871.165-1.207.493a1.608 1.608 0 0 0-.504 1.195c0 .46.168.861.504 1.201.336.34.738.51 1.207.51.469 0 .871-.17 1.207-.51.336-.34.504-.74.504-1.201 0-.469-.168-.867-.504-1.195A1.666 1.666 0 0 0 12 16.148Z"
      fill="#fff"
    />
  </svg>
);

export default JiraQuestion;
