import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  gutterWidthXSmall,
  gutterWidth,
  gutterWidthMedium,
  gutterWidthLarge,
  gutterWidthXLarge,
} from "./gutters";
import media from "../mediaQueries";

const StyledContainer = styled.div`
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  ${media.min.xs`
    width: 100%;
    padding-right: ${gutterWidthXSmall}px;
    padding-left: ${gutterWidthXSmall}px;
  `}
  ${media.min.sm`
    padding-right: ${gutterWidth}px;
    padding-left: ${gutterWidth}px;
  `}
  ${media.min.md`
    padding-right: ${gutterWidthMedium}px;
    padding-left: ${gutterWidthMedium}px;
  `}
  ${media.min.lg`
    max-width: 1440px;
    padding-right: ${gutterWidthLarge}px;
    padding-left: ${gutterWidthLarge}px;
  `}

  ${(props) =>
    props.fluid &&
    `
      &&{
        width: 100%;
        max-width: initial;
        padding-right: ${gutterWidth}px;
        padding-left: ${gutterWidth}px;
      }
  `}
`;

const Container = ({ children, ...rest }) => {
  return <StyledContainer {...rest}>{children}</StyledContainer>;
};

Container.propTypes = {
  fluid: PropTypes.bool,
};

export default Container;
