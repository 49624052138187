import { jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.033.142-2.013.425-2.938A9.83 9.83 0 0 1 3.65 6.475L1.375 4.2c-.2-.2-.3-.438-.3-.713 0-.275.1-.512.3-.712.2-.2.437-.3.712-.3.275 0 .513.1.713.3l18.4 18.4c.2.2.296.433.288.7-.009.267-.113.5-.313.7-.2.2-.438.3-.713.3a.973.973 0 0 1-.712-.3l-2.225-2.225a9.83 9.83 0 0 1-2.587 1.225A9.982 9.982 0 0 1 12 22Zm-1-2.05V18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 9 16v-1l-4.8-4.8c-.05.3-.096.6-.138.9-.041.3-.062.6-.062.9 0 2.017.663 3.783 1.988 5.3S8.983 19.7 11 19.95Zm9.35-2.475-1.45-1.45a8.74 8.74 0 0 0 .812-1.938c.192-.675.288-1.37.288-2.087a7.847 7.847 0 0 0-1.362-4.475A7.704 7.704 0 0 0 15 4.6V5c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 13 7h-2v1.125L6.525 3.65c.8-.517 1.658-.92 2.575-1.212A9.497 9.497 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.497 9.497 0 0 1-.438 2.9 10.692 10.692 0 0 1-1.212 2.575Z" })
  }
);
export {
  h as default
};
