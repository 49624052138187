import { jsx as t } from "react/jsx-runtime";
const h = (s) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ t("path", { d: "M20.3 20.3 18 18H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 16V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 4 2h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v15.575c0 .45-.204.762-.612.937-.409.175-.771.105-1.088-.212Z" })
  }
);
export {
  h as default
};
