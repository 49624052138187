import { jsxs as l, jsx as h } from "react/jsx-runtime";
const i = (a) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ h(
        "path",
        {
          d: "M12.333 12a3.667 3.667 0 1 1 7.334 0 3.667 3.667 0 0 1-7.334 0Z",
          fill: "#1ABCFE"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M5 19.333a3.667 3.667 0 0 1 3.667-3.666h3.666v3.666a3.667 3.667 0 1 1-7.333 0Z",
          fill: "#0ACF83"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M12.333 1v7.333H16A3.667 3.667 0 1 0 16 1h-3.667Z",
          fill: "#FF7262"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M5 4.667a3.667 3.667 0 0 0 3.667 3.666h3.666V1H8.667A3.667 3.667 0 0 0 5 4.667Z",
          fill: "#F24E1E"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M5 12a3.667 3.667 0 0 0 3.667 3.666h3.666V8.333H8.667A3.667 3.667 0 0 0 5 12Z",
          fill: "#A259FF"
        }
      )
    ]
  }
);
export {
  i as default
};
