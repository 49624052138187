import { jsx as h } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ h("path", { d: "M6 22c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 20V4c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 6 2h7.175a1.975 1.975 0 0 1 1.4.575l4.85 4.85a1.975 1.975 0 0 1 .575 1.4V20c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 22H6Zm7-14c0 .283.096.52.287.713.192.191.43.287.713.287h4l-5-5v4Zm-2 7v2c0 .283.096.52.287.712.192.192.43.288.713.288s.52-.096.713-.288A.968.968 0 0 0 13 17v-2h2c.283 0 .52-.096.713-.287A.968.968 0 0 0 16 14a.968.968 0 0 0-.287-.713A.968.968 0 0 0 15 13h-2v-2a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 10a.968.968 0 0 0-.713.287A.968.968 0 0 0 11 11v2H9a.967.967 0 0 0-.713.287A.968.968 0 0 0 8 14c0 .283.096.52.287.713.192.191.43.287.713.287h2Z" })
  }
);
export {
  t as default
};
