import { jsx as e } from "react/jsx-runtime";
const l = (o) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm11 6.5a3.98 3.98 0 0 1-.775 2.366c-.295.487-.647.97-1.01 1.465C13.15 14.787 12 16.356 12 18.5c0-2.144-1.149-3.713-2.215-5.17-.363-.495-.715-.977-1.01-1.464A4 4 0 1 1 16 9.5Z"
      }
    )
  }
);
export {
  l as default
};
