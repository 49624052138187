import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M4.805 10.463v2.651c0 .26-.097.486-.291.68a1.019 1.019 0 0 1-.699.31.974.974 0 0 1-.716-.291.973.973 0 0 1-.291-.716V8.04c0-.142.026-.268.08-.38.052-.112.126-.215.22-.31.094-.094.198-.168.31-.22a.877.877 0 0 1 .38-.08h5.09c.284 0 .516.097.699.291.183.195.274.434.274.716a1.055 1.055 0 0 1-.292.699.916.916 0 0 1-.698.291H6.22l4.367 4.367c.2.2.3.436.3.707 0 .27-.1.507-.3.707-.2.2-.436.3-.707.3a.968.968 0 0 1-.707-.3l-4.367-4.366Zm12.976 4.49-4.367-4.367c-.2-.2-.3-.436-.3-.707 0-.27.1-.507.3-.707.2-.2.436-.3.707-.3.271 0 .507.1.707.3l4.367 4.367v-2.652c0-.26.097-.486.291-.68a1.02 1.02 0 0 1 .699-.31c.283 0 .521.097.716.292a.973.973 0 0 1 .291.716v5.055a.877.877 0 0 1-.08.38 1.105 1.105 0 0 1-.22.31 1.107 1.107 0 0 1-.31.22.876.876 0 0 1-.38.08h-5.09a.916.916 0 0 1-.699-.291 1.006 1.006 0 0 1-.274-.716c.012-.271.109-.504.292-.699a.916.916 0 0 1 .698-.291h2.652Z" })
  }
);
export {
  t as default
};
