import { jsx as c } from "react/jsx-runtime";
const o = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M15.5 11c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C16.292 8.142 15.933 8 15.5 8c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm-7 0c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C9.292 8.142 8.933 8 8.5 8c-.433 0-.792.142-1.075.425C7.142 8.708 7 9.067 7 9.5c0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm3.5 6.5c.95 0 1.817-.212 2.6-.637.783-.425 1.392-.963 1.825-1.613.2-.3.23-.583.088-.85a.703.703 0 0 0-.663-.4.7.7 0 0 0-.35.1.8.8 0 0 0-.275.25c-.367.5-.825.9-1.375 1.2A3.8 3.8 0 0 1 12 16a3.8 3.8 0 0 1-1.85-.45c-.55-.3-1.008-.7-1.375-1.2a.774.774 0 0 0-.287-.25.772.772 0 0 0-.363-.1c-.283 0-.496.129-.637.387-.142.259-.121.53.062.813.45.683 1.067 1.238 1.85 1.663.783.425 1.65.637 2.6.637Zm0 4.5a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22Z" })
  }
);
export {
  o as default
};
