import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M13.854 8.854a.5.5 0 0 0-.854.353V11H4a1 1 0 1 0 0 2h9v1.793a.5.5 0 0 0 .854.353l2.792-2.792a.5.5 0 0 0 0-.708l-2.792-2.792ZM19 19a1 1 0 1 0 2 0V5a1 1 0 1 0-2 0v14Z" })
  }
);
export {
  h as default
};
