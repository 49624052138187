import { jsx as l } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ l("path", { d: "M3 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 1 18c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 3 16h.263a.64.64 0 0 1 .237.05l4.55-4.55a.641.641 0 0 1-.05-.238V11c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 10 9c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413 0 .033-.017.2-.05.5l2.55 2.55a.642.642 0 0 1 .238-.05h.524c.075 0 .155.017.238.05l3.55-3.55a.642.642 0 0 1-.05-.238V10c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 21 8c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 21 12h-.262a.642.642 0 0 1-.238-.05l-3.55 3.55c.033.083.05.163.05.238V16c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 15 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 13 16v-.262c0-.075.017-.155.05-.238l-2.55-2.55a.642.642 0 0 1-.238.05H10c-.033 0-.2-.017-.5-.05L4.95 17.5c.033.083.05.163.05.238V18c0 .55-.196 1.021-.588 1.413A1.925 1.925 0 0 1 3 20ZM4 9.975l-.625-1.35L2.025 8l1.35-.625L4 6.025l.625 1.35L5.975 8l-1.35.625L4 9.975ZM15 9l-.95-2.05L12 6l2.05-.95L15 3l.95 2.05L18 6l-2.05.95L15 9Z" })
  }
);
export {
  h as default
};
