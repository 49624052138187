import * as c from "react";
const a = (e) => /* @__PURE__ */ c.createElement(
  "svg",
  {
    width: "1em",
    height: "1em",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...e
  },
  /* @__PURE__ */ c.createElement(
    "path",
    {
      d: "m19.65 21.4-1.5-1.5c-.85.65-1.792 1.163-2.825 1.538A9.525 9.525 0 0 1 12.05 22a9.676 9.676 0 0 1-3.875-.788 10.144 10.144 0 0 1-3.187-2.15 10.142 10.142 0 0 1-2.15-3.187A9.676 9.676 0 0 1 2.05 12c0-1.15.188-2.246.563-3.287A10.484 10.484 0 0 1 4.15 5.875L2.65 4.4a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l17 17a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.949.949 0 0 1-.7-.275Zm1.125-4.525L19.3 15.4a9.575 9.575 0 0 0 .55-1.65c.133-.567.2-1.15.2-1.75 0-.4-.025-.787-.075-1.162A4.838 4.838 0 0 0 19.7 9.75c-.35.083-.7.146-1.05.188-.35.041-.717.062-1.1.062-.933 0-1.82-.12-2.663-.363A9.968 9.968 0 0 1 12.5 8.6L7.15 3.25c.733-.4 1.508-.708 2.325-.925A10.001 10.001 0 0 1 12.05 2c1.383 0 2.68.263 3.887.788a10.29 10.29 0 0 1 3.176 2.137 9.993 9.993 0 0 1 2.15 3.175 9.738 9.738 0 0 1 .787 3.9c0 .883-.104 1.73-.313 2.537a9.29 9.29 0 0 1-.962 2.338ZM9.05 14.25c-.35 0-.646-.12-.887-.363A1.207 1.207 0 0 1 7.8 13c0-.35.12-.646.363-.887.241-.242.537-.363.887-.363s.646.12.888.363c.241.241.362.537.362.887s-.12.646-.362.887a1.207 1.207 0 0 1-.888.363Zm3 5.75c.883 0 1.717-.13 2.5-.387A7.53 7.53 0 0 0 16.7 18.5L7.725 9.475c-.5.533-1.062 1-1.687 1.4-.625.4-1.288.725-1.988.975V12c0 2.217.78 4.104 2.338 5.663C7.946 19.22 9.833 20 12.05 20Z",
      fill: "#4A5464"
    }
  )
);
export {
  a as default
};
