import { jsx as h } from "react/jsx-runtime";
const t = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M8 6V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 10 2h4c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v2h4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v11c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 21H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 19V8c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 6h4Zm2 0h4V4h-4v2Zm10 9h-5v1c0 .283-.096.52-.287.712A.968.968 0 0 1 14 17h-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 16v-1H4v4h16v-4Zm-9 0h2v-2h-2v2Zm-7-2h5v-1c0-.283.096-.521.288-.713A.967.967 0 0 1 10 11h4a.97.97 0 0 1 .713.287A.97.97 0 0 1 15 12v1h5V8H4v5Z" })
  }
);
export {
  t as default
};
