import { jsx as a } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ a("path", { d: "M3.287 7.712c.192.192.43.288.713.288a.968.968 0 0 0 .713-.288A.967.967 0 0 0 5 7V5h2a.97.97 0 0 0 .713-.287A.97.97 0 0 0 8 4a.97.97 0 0 0-.287-.713A.97.97 0 0 0 7 3H4a.97.97 0 0 0-.713.287A.97.97 0 0 0 3 4v3c0 .283.096.52.287.712ZM3.287 20.712c.192.192.43.288.713.288h3a.968.968 0 0 0 .713-.288A.967.967 0 0 0 8 20a.967.967 0 0 0-.287-.712A.968.968 0 0 0 7 19H5v-2a.97.97 0 0 0-.287-.713A.97.97 0 0 0 4 16a.97.97 0 0 0-.713.287A.97.97 0 0 0 3 17v3c0 .283.096.52.287.712ZM16.288 20.712A.965.965 0 0 0 17 21h3c.283 0 .52-.096.712-.288A.965.965 0 0 0 21 20v-3a.968.968 0 0 0-.288-.713A.967.967 0 0 0 20 16a.967.967 0 0 0-.712.287A.968.968 0 0 0 19 17v2h-2a.965.965 0 0 0-.712.288A.965.965 0 0 0 16 20c0 .283.096.52.288.712ZM19.288 7.712A.965.965 0 0 0 20 8c.283 0 .52-.096.712-.288A.965.965 0 0 0 21 7V4a.968.968 0 0 0-.288-.713A.967.967 0 0 0 20 3h-3a.967.967 0 0 0-.712.287A.968.968 0 0 0 16 4c0 .283.096.521.288.713A.967.967 0 0 0 17 5h2v2c0 .283.096.52.288.712ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" })
  }
);
export {
  h as default
};
