import { jsx as a } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M20.205 3.796a.997.997 0 0 0-1.41 0L15 7.59V5a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1h5a1 1 0 1 0 0-2h-2.59l3.795-3.795a.997.997 0 0 0 0-1.41ZM5 13a1 1 0 1 0 0 2h2.59l-3.795 3.795a.997.997 0 0 0 1.41 1.41L9 16.41V19a1 1 0 1 0 2 0v-5a1 1 0 0 0-1-1H5Z" })
  }
);
export {
  o as default
};
