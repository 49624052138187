import { jsx as h } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "m10.825 8-2-2H20c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 20 8h-9.175Zm5 5-2-2H17c.283 0 .52.096.712.287.192.192.288.43.288.713 0 .284-.096.52-.288.712A.965.965 0 0 1 17 13h-1.175ZM13 18h-2a.965.965 0 0 1-.712-.288A.965.965 0 0 1 10 17c0-.283.096-.52.288-.712A.965.965 0 0 1 11 16h2c.283 0 .521.096.713.288A.967.967 0 0 1 14 17c0 .284-.096.52-.287.712A.968.968 0 0 1 13 18Zm6.075 3.9L10.15 13H7a.968.968 0 0 1-.713-.288A.967.967 0 0 1 6 12a.97.97 0 0 1 .287-.713A.97.97 0 0 1 7 11h1.15L2.075 4.925c-.2-.2-.3-.437-.3-.713 0-.274.1-.512.3-.712.2-.2.438-.3.713-.3.275 0 .512.1.712.3l17 17c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3ZM5.15 6v2H4a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 7c0-.283.096-.52.288-.713A.967.967 0 0 1 4 6h1.15Z" })
  }
);
export {
  c as default
};
