/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Typeform = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.165 2.022H19.5V22h-2.335V2.022ZM4.5 6.572h2.926V2h3.877v4.572h3.504v2.507h-3.504v8.142c0 .354.017.659.051.914.034.256.113.472.238.65.125.176.311.309.562.397.249.089.589.133 1.02.133.272 0 .544-.005.816-.015.272-.01.544-.044.817-.103v2.596c-.431.04-.85.078-1.259.118-.418.04-.838.06-1.258.059-1.021 0-1.843-.084-2.466-.25-.625-.168-1.111-.414-1.463-.738a2.438 2.438 0 0 1-.715-1.225 7.975 7.975 0 0 1-.22-1.68V9.078H4.5V6.572Z" />
  </svg>
);

export default Typeform;
