import { jsx as t } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M13.25 3a9.003 9.003 0 0 0-9.26 9H2.2c-.45 0-.67.54-.35.85l2.79 2.8c.2.2.51.2.71 0l2.79-2.8c.32-.31.09-.85-.35-.85h-1.8c0-3.9 3.18-7.05 7.1-7 3.72.05 6.85 3.18 6.9 6.9.05 3.91-3.1 7.1-7 7.1-1.61 0-3.1-.55-4.28-1.48a.994.994 0 0 0-1.32.08c-.42.43-.39 1.13.08 1.5a8.931 8.931 0 0 0 5.52 1.9c5.05 0 9.14-4.17 9-9.26-.13-4.69-4.05-8.61-8.74-8.74Z" })
  }
);
export {
  h as default
};
