/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleKeep = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m14.5 6.5 3.016.57L20 6.5 14.5 1l-.759 2.607L14.5 6.5Z"
      fill="#F29900"
    />
    <path
      d="M14.5 6.5V1h-9A1.5 1.5 0 0 0 4 2.5v19A1.5 1.5 0 0 0 5.5 23h13a1.5 1.5 0 0 0 1.5-1.5v-15h-5.5Z"
      fill="#FBBC04"
    />
    <path
      d="M13.75 17h-3.5v-1.25h3.5V17Zm-.019-2.5H10.27a3.25 3.25 0 1 1 3.463 0Z"
      fill="#fff"
    />
  </svg>
);

export default GoogleKeep;
