import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M2.05 10.976c.083-.85.258-1.654.525-2.413a8.806 8.806 0 0 1 1.1-2.137 8.16 8.16 0 0 1 1.462 2.087c.392.792.646 1.613.763 2.463H2.05Zm16.05 0c.117-.85.367-1.667.75-2.45a7.69 7.69 0 0 1 1.475-2.075c.467.65.833 1.358 1.1 2.125.267.766.442 1.566.525 2.4H18.1ZM3.675 17.55a8.357 8.357 0 0 1-1.1-2.113 10.265 10.265 0 0 1-.525-2.412H5.9a7.825 7.825 0 0 1-.763 2.45 8.24 8.24 0 0 1-1.462 2.075Zm16.65 0a7.691 7.691 0 0 1-1.475-2.075 8.026 8.026 0 0 1-.75-2.45h3.85a10.439 10.439 0 0 1-.525 2.4 8.298 8.298 0 0 1-1.1 2.125Zm-12.4-6.575A9.619 9.619 0 0 0 6.95 7.65 10.476 10.476 0 0 0 4.925 4.9c.8-.8 1.712-1.442 2.737-1.925a10.454 10.454 0 0 1 3.313-.925v8.925h-3.05Zm5.1 0V2.05c1.183.133 2.287.441 3.312.925A9.683 9.683 0 0 1 19.075 4.9a9.58 9.58 0 0 0-2.037 2.737 9.913 9.913 0 0 0-.963 3.338h-3.05Zm-2.05 10.975a10.155 10.155 0 0 1-3.325-.938 9.96 9.96 0 0 1-2.725-1.937c.85-.8 1.53-1.709 2.037-2.725a9.789 9.789 0 0 0 .963-3.325h3.05v8.925Zm2.05 0v-8.925h3.05c.133 1.2.454 2.312.963 3.337a9.58 9.58 0 0 0 2.037 2.738c-.8.8-1.712 1.441-2.738 1.925-1.024.483-2.129.791-3.312.925Z" })
  }
);
export {
  t as default
};
