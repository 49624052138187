import * as e from "react";
const o = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 10.286C5 6.262 8.358 3 12.5 3c4.138 0 7.5 3.257 7.5 7.286 0 3.857-2.77 8.271-7.059 10.285v-3H12.5c-4.142 0-7.5-3.262-7.5-7.285Zm7.059.428-.882 1.715H9.853l.882-1.715H9.412V8.143h2.647v2.571Zm3.53 0-.883 1.715h-1.324l.883-1.715H12.94V8.143h2.647v2.571Z"
    }
  )
);
export {
  o as default
};
