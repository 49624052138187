import * as React from "react";
const SvgIconCreate = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.709 5.631c.39.39.39 1.02 0 1.41l-1.83 1.83-3.75-3.75 1.83-1.83a.996.996 0 0 1 1.41 0l2.34 2.34Zm-17.71 14.87v-3.04c0-.14.05-.26.15-.36l10.91-10.91 3.75 3.75-10.92 10.91a.47.47 0 0 1-.35.15h-3.04c-.28 0-.5-.22-.5-.5Z"
    />
  </svg>
);
export default SvgIconCreate;
