import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M12 10a.968.968 0 0 0-.713.287A.968.968 0 0 0 11 11v3.2l-.9-.9a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275.948.948 0 0 0-.275.7c0 .283.092.517.275.7l2.6 2.6c.2.2.433.3.7.3.267 0 .5-.1.7-.3l2.6-2.6a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-.9.9V11a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 10ZM5 8v11h14V8H5Zm0 13c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19V6.525c0-.233.038-.458.112-.675.075-.217.188-.417.338-.6L4.7 3.725c.183-.233.412-.413.688-.538C5.662 3.063 5.95 3 6.25 3h11.5c.3 0 .587.063.863.188.274.124.504.304.687.537l1.25 1.525c.15.183.262.383.337.6.075.217.113.442.113.675V19c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21H5Zm.4-15h13.2l-.85-1H6.25L5.4 6Z" })
  }
);
export {
  t as default
};
