import * as l from "react";
const h = (e) => /* @__PURE__ */ l.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...e
  },
  /* @__PURE__ */ l.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm4.722 14H8.23l.261-1.667H7l.268-1.666H8.76l.522-3.334H7.79l.261-1.666h1.492L9.81 7h1.492l-.269 1.667h2.983L14.286 7h1.491l-.268 1.667H17l-.261 1.666h-1.491l-.522 3.334h1.491l-.268 1.666h-1.492L14.197 17h-1.492l.26-1.667H9.984L9.722 17Zm4.034-6.667h-2.983l-.522 3.334h2.983l.522-3.334Z",
      fill: "#8997A1"
    }
  )
);
export {
  h as default
};
