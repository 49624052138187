import { jsx as a } from "react/jsx-runtime";
const s = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "m19.6 16.8-1.45-1.45c.283-.5.496-1.034.638-1.6.141-.567.212-1.15.212-1.75 0-1.934-.683-3.584-2.05-4.95C15.583 5.683 13.933 5 12 5c-.6 0-1.183.07-1.75.213-.567.141-1.1.353-1.6.637L7.2 4.4c.583-.35 1.188-.642 1.813-.875A8.543 8.543 0 0 1 11 3.05v-1c0-.284.096-.521.288-.713A.967.967 0 0 1 12 1.05a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v1c2.083.233 3.871 1.095 5.363 2.587 1.491 1.492 2.354 3.28 2.587 5.363h1a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288h-1a8.544 8.544 0 0 1-.475 1.987c-.233.625-.525 1.23-.875 1.813ZM12 22.95a.967.967 0 0 1-.712-.287.968.968 0 0 1-.288-.713v-1c-2.083-.233-3.87-1.096-5.362-2.587C4.146 16.87 3.283 15.083 3.05 13h-1a.968.968 0 0 1-.713-.288A.967.967 0 0 1 1.05 12a.97.97 0 0 1 .287-.713A.97.97 0 0 1 2.05 11h1a8.56 8.56 0 0 1 .475-1.988c.233-.625.525-1.229.875-1.812L2.1 4.9a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l16.975 16.975c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3L16.8 19.6c-.583.35-1.187.641-1.812.875A8.56 8.56 0 0 1 13 20.95v1a.97.97 0 0 1-.287.713.97.97 0 0 1-.713.287ZM12 19c.6 0 1.183-.07 1.75-.212a6.37 6.37 0 0 0 1.6-.638l-9.5-9.5a6.343 6.343 0 0 0-.637 1.6A7.172 7.172 0 0 0 5 12c0 1.933.683 3.583 2.05 4.95C8.417 18.317 10.067 19 12 19Z" })
  }
);
export {
  s as default
};
