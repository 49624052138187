import * as React from "react";
const SvgIconMsCalendar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M1 16h8v7H2a1 1 0 0 1-1-1v-6Z" fill="#064a8c" />
    <path
      d="M23 6H1V2.143A1.1 1.1 0 0 1 2.048 1h19.9A1.1 1.1 0 0 1 23 2.143ZM1 10h8v7H1z"
      fill="#0364b8"
    />
    <path fill="#0364b8" d="M8 16h9v7H8z" />
    <path d="M16 16h7v6a1 1 0 0 1-1 1h-6v-7Z" fill="#0078d4" />
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill="#0078d4" d="M1 5h8v6H1z" />
    <path fill="#0078d4" d="M8 10h9v7H8z" />
    <path fill="#28a8ea" d="M8 5h8v6H8z" />
    <path fill="#50d9ff" d="M16 5h7v7h-7z" />
    <path fill="#28a8ea" d="M16 11h7v6h-7z" />
  </svg>
);
export default SvgIconMsCalendar;
