/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Klarna = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.834 2h8.332C19.388 2 22 4.599 22 7.804v8.392C22 19.401 19.388 22 16.166 22H7.834C4.612 22 2 19.401 2 16.196V7.804C2 4.599 4.612 2 7.834 2ZM6 6.06h2.635v11.713H6V6.059Zm9.347 0h-2.63a6.725 6.725 0 0 1-2.72 5.427l-1.041.78 4.037 5.506h3.319l-3.715-5.066a9.317 9.317 0 0 0 2.75-6.648Zm2.286 9.093a1.521 1.521 0 1 1 1.69 2.53 1.521 1.521 0 0 1-1.69-2.53Z"
    />
  </svg>
);

export default Klarna;
