import { jsx as A } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M6 17a.968.968 0 0 1-.713-.288A.967.967 0 0 1 5 16v-5.025c0-.283.096-.517.287-.7A.993.993 0 0 1 6 10a.97.97 0 0 1 .713.287A.97.97 0 0 1 7 11v5.025a.928.928 0 0 1-.287.7A.993.993 0 0 1 6 17Zm6 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 16v-5.025c0-.283.096-.517.288-.7A.99.99 0 0 1 12 10a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 11v5.025a.928.928 0 0 1-.287.7A.993.993 0 0 1 12 17Zm-9.025 4a.926.926 0 0 1-.7-.288A.99.99 0 0 1 2 20c0-.283.096-.52.288-.712A.965.965 0 0 1 3 19h18.025c.283 0 .517.096.7.288A.99.99 0 0 1 22 20c0 .283-.096.52-.288.712A.965.965 0 0 1 21 21H2.975ZM18 17a.965.965 0 0 1-.712-.288A.965.965 0 0 1 17 16v-5.025c0-.283.096-.517.288-.7A.99.99 0 0 1 18 10c.283 0 .52.096.712.287.192.192.288.43.288.713v5.025a.926.926 0 0 1-.288.7A.99.99 0 0 1 18 17ZM12.9 1.45l8.425 4.2c.217.117.383.279.5.487.117.209.175.438.175.688 0 .333-.12.612-.362.837-.242.225-.53.338-.863.338H3.25c-.333 0-.625-.113-.875-.338A1.08 1.08 0 0 1 2 6.825c0-.233.054-.458.163-.675a.984.984 0 0 1 .512-.475L11.1 1.45c.283-.133.583-.2.9-.2.317 0 .617.067.9.2Z" })
  }
);
export {
  t as default
};
