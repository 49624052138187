/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Finland = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 3.75H.75v17.1h22.5V3.75H1Z"
      stroke="#000"
      strokeOpacity={0.2}
      strokeWidth={0.5}
    />
    <path d="M1 4h22v16.6H1V4Z" fill="#fff" />
    <path d="M1 10.035h22v4.53H1v-4.53Z" fill="#003580" />
    <path d="M7.033 4h4.5v16.6H7.028L7.033 4Z" fill="#003580" />
  </svg>
);

export default Finland;
