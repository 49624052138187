import { jsx as e } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.1 21.212A9.733 9.733 0 0 0 12 22a9.733 9.733 0 0 0 3.9-.788 10.092 10.092 0 0 0 3.175-2.137c.9-.9 1.612-1.958 2.137-3.175A9.733 9.733 0 0 0 22 12a9.733 9.733 0 0 0-.788-3.9 10.092 10.092 0 0 0-2.137-3.175c-.9-.9-1.958-1.613-3.175-2.138A9.743 9.743 0 0 0 12 2a9.743 9.743 0 0 0-3.9.787 10.105 10.105 0 0 0-3.175 2.138c-.9.9-1.612 1.958-2.137 3.175A9.732 9.732 0 0 0 2 12c0 1.383.263 2.683.788 3.9a10.091 10.091 0 0 0 2.137 3.175c.9.9 1.958 1.612 3.175 2.137Zm4.25-11.062a.48.48 0 0 0-.7 0l-2.8 2.8c-.15.15-.187.33-.112.538.075.208.229.312.462.312h5.6c.233 0 .387-.104.462-.312.075-.209.038-.388-.112-.538l-2.8-2.8Z"
      }
    )
  }
);
export {
  t as default
};
