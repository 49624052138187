import { jsx as a } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.032 15.671a3.473 3.473 0 0 1 0-4.941L5.77 7.023a3.548 3.548 0 0 1 4.29-.535c.419.247.415.812.071 1.153-.344.341-.906.312-1.373.176a1.772 1.772 0 0 0-1.742.442l-3.738 3.706a1.737 1.737 0 0 0 0 2.47 1.773 1.773 0 0 0 2.492 0l7.476-7.412a3.546 3.546 0 0 1 4.984 0l3.738 3.707a3.473 3.473 0 0 1 0 4.941 3.545 3.545 0 0 1-4.984 0l-1.246-1.235-1.557 1.544a3.548 3.548 0 0 1-4.29.535c-.42-.246-.416-.812-.071-1.153.344-.34.905-.312 1.372-.176.598.173 1.27.026 1.742-.441l1.603-1.589a1.71 1.71 0 0 1 2.402 0l1.29 1.28a1.773 1.773 0 0 0 2.493 0 1.737 1.737 0 0 0 0-2.471l-3.738-3.706a1.773 1.773 0 0 0-2.492 0L7.016 15.67a3.546 3.546 0 0 1-4.984 0Z"
      }
    )
  }
);
export {
  t as default
};
