import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "m10.05 13.95-6.4-2.6a1.18 1.18 0 0 1-.462-.375A.931.931 0 0 1 3 10.4c0-.216.058-.408.175-.575.117-.166.275-.291.475-.375l15.3-5.675c.2-.083.392-.1.575-.05.183.05.342.142.475.275.133.134.225.292.275.475a.869.869 0 0 1-.05.575l-5.675 15.3a.952.952 0 0 1-.387.487 1.01 1.01 0 0 1-.538.163 1.07 1.07 0 0 1-.55-.163 1.062 1.062 0 0 1-.425-.487l-2.6-6.4Z" })
  }
);
export {
  h as default
};
