/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftWordAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3ZM9.212 15.107a8.32 8.32 0 0 0-.058-.605c-.03-.23-.06-.417-.091-.557l-1.421-6.82h-1.89l2.304 9.748h2.206l1.461-6.438c.032-.15.074-.381.126-.694.053-.312.088-.548.106-.707h.027a15.187 15.187 0 0 0 .259 1.382l1.554 6.459h2.17l2.292-9.753H16.4l-1.305 6.744c-.083.405-.14.815-.17 1.228h-.027a9.94 9.94 0 0 0-.204-1.265l-1.636-6.704h-2.04l-1.577 6.806c-.034.16-.07.354-.112.585a6.24 6.24 0 0 0-.082.591h-.035Z"
    />
  </svg>
);

export default MicrosoftWordAlt;
