/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const File = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.588 21.413C4.979 21.804 5.45 22 6 22h12c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413V8.825a1.978 1.978 0 0 0-.575-1.4l-4.85-4.85a1.978 1.978 0 0 0-1.4-.575H6c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 4 4v16c0 .55.196 1.021.588 1.413ZM13 8a1 1 0 0 0 1 1h4l-5-5v4Z"
    />
  </svg>
);

export default File;
