import { jsx as a } from "react/jsx-runtime";
const A = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M12 12a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 11V8a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 7a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 8v3c0 .284.096.521.288.712A.965.965 0 0 0 12 12Zm0 4a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 15a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 14a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 15c0 .284.096.521.288.712A.965.965 0 0 0 12 16Zm0 5.925h-.25a.497.497 0 0 1-.225-.05c-2.183-.683-3.983-2.037-5.4-4.063C4.708 15.788 4 13.55 4 11.1V6.375c0-.416.121-.791.363-1.125.241-.333.554-.575.937-.725l6-2.25a2.07 2.07 0 0 1 .7-.125c.233 0 .467.042.7.125l6 2.25c.383.15.696.392.938.725.241.334.362.709.362 1.125V11.1c0 2.45-.708 4.688-2.125 6.712-1.417 2.026-3.217 3.38-5.4 4.063-.083.034-.242.05-.475.05Z" })
  }
);
export {
  A as default
};
