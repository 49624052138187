import { jsx as c } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h4.2c.233-.6.6-1.083 1.1-1.45.5-.367 1.067-.55 1.7-.55s1.2.183 1.7.55c.5.367.867.85 1.1 1.45H19c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm7-16.75a.728.728 0 0 0 .75-.75.731.731 0 0 0-.212-.538A.731.731 0 0 0 12 2.75a.728.728 0 0 0-.75.75.726.726 0 0 0 .75.75Zm-7 13.6c.9-.883 1.946-1.58 3.138-2.088C9.329 15.254 10.617 15 12 15s2.671.254 3.863.762A10.09 10.09 0 0 1 19 17.85V5H5v12.85ZM12 13a3.372 3.372 0 0 1-2.475-1.025A3.372 3.372 0 0 1 8.5 9.5c0-.967.342-1.792 1.025-2.475A3.372 3.372 0 0 1 12 6c.967 0 1.792.342 2.475 1.025A3.372 3.372 0 0 1 15.5 9.5c0 .967-.342 1.792-1.025 2.475A3.372 3.372 0 0 1 12 13Z" })
  }
);
export {
  o as default
};
