import * as e from "react";
const a = (h) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...h
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M5.357 15.571H1.43V17h3.928v-1.429Zm3.929-1.428H6.429a.72.72 0 0 0-.715.714v2.857c0 .39.324.715.715.715h2.857a.72.72 0 0 0 .714-.715v-2.857a.72.72 0 0 0-.714-.714Zm1.785-4.286H1.43v1.429h9.642V9.857ZM3.93 4.143h-2.5V5.57h2.5V4.143ZM18.57 15.57h-8.214V17h8.214v-1.429ZM7.857 2.714H5a.72.72 0 0 0-.714.715v2.857c0 .39.323.714.714.714h2.857a.72.72 0 0 0 .714-.714V3.429a.72.72 0 0 0-.714-.715ZM15 8.43h-2.857a.72.72 0 0 0-.714.714V12c0 .39.323.714.714.714H15a.72.72 0 0 0 .714-.714V9.143A.72.72 0 0 0 15 8.429Zm3.571 1.428h-2.5v1.429h2.5V9.857Zm0-5.714H8.93V5.57h9.642V4.143Z",
      fillRule: "evenodd"
    }
  )
);
export {
  a as default
};
