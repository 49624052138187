import { basicDesignTokens } from "../DesignTokens/designTokens";
import { duration, easing } from "./animation";
import { breakpoint } from "./breakpoint";
import { colorPrimitives } from "./colorPrimitives";
import { oldColors } from "./oldColors";
import {
  fontWeight,
  fontSize,
  fontVariant,
  fontVariantShorthands,
} from "./fonts";
import { zIndex } from "./zIndex";
import { boxShadowsLight, boxShadowsDark } from "../Colors/shadows";
import { getSemanticColors } from "./getSemanticColors";

const themeWithoutSemanticColors = {
  animation: {
    duration,
    easing,
  },
  blur: basicDesignTokens.blur,
  breakpoint,
  color: {
    ...colorPrimitives.palette,
    ...colorPrimitives.neutral,
    ...oldColors,
  },
  font: {
    size: fontSize,
    variant: fontVariant,
    weight: fontWeight,
    ...fontVariantShorthands,
  },
  radii: basicDesignTokens.radius, // backwards compatibility, TODO remove after updating radii to radius in react-ng-bridge
  radius: basicDesignTokens.radius,
  opacity: basicDesignTokens.opacity.opacity, // it's exported with an extra layer for some reason
  space: basicDesignTokens.space,
  transition: {
    button: `all ${duration.fastSm} ${easing.easeInOut}`,
  },
  zIndex,
} as const;

export type ThemeWithoutSemanticColors = typeof themeWithoutSemanticColors;

export const themeLightWithOnlySemantic = {
  color: {
    ...getSemanticColors(basicDesignTokens.dynamicColorLight.semantic),
  },
  boxShadow: boxShadowsLight,
} as const;
export type ThemeWithOnlySemanticColors = typeof themeLightWithOnlySemantic;
export const themeDarkWithOnlySemantic: ThemeWithOnlySemanticColors = {
  color: {
    ...getSemanticColors(basicDesignTokens.dynamicColorDark.semantic),
  },
  boxShadow: boxShadowsDark,
} as const;

type ThemeName = "light" | "dark";
export type Theme = ThemeWithOnlySemanticColors &
  ThemeWithoutSemanticColors & { name: ThemeName };

export const themeLight: Theme = {
  name: "light",
  ...themeWithoutSemanticColors,
  ...themeLightWithOnlySemantic,
  color: {
    ...themeWithoutSemanticColors.color,
    ...themeLightWithOnlySemantic.color,
  },
} as const;

export const themeDark: Theme = {
  name: "dark",
  ...themeWithoutSemanticColors,
  ...themeDarkWithOnlySemantic,
  color: {
    ...themeWithoutSemanticColors.color,
    ...themeDarkWithOnlySemantic.color,
  },
} as const;
