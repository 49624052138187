import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 17c-1.383 0-2.562-.488-3.537-1.463C7.488 14.562 7 13.383 7 12s.488-2.563 1.463-3.538C9.438 7.487 10.617 7 12 7s2.563.487 3.538 1.462C16.513 9.437 17 10.617 17 12s-.487 2.562-1.462 3.537C14.563 16.512 13.383 17 12 17ZM2 13a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 12c0-.283.096-.521.288-.713A.967.967 0 0 1 2 11h2a.97.97 0 0 1 .713.287A.97.97 0 0 1 5 12c0 .283-.096.52-.287.712A.968.968 0 0 1 4 13H2Zm18 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 19 12c0-.283.096-.521.288-.713A.967.967 0 0 1 20 11h2c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 22 13h-2Zm-8-8a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 4V2c0-.283.096-.521.288-.713A.967.967 0 0 1 12 1a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 2v2c0 .283-.096.52-.287.712A.968.968 0 0 1 12 5Zm0 18a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 22v-2c0-.283.096-.52.288-.712A.965.965 0 0 1 12 19c.283 0 .521.096.713.288A.967.967 0 0 1 13 20v2c0 .283-.096.52-.287.712A.968.968 0 0 1 12 23ZM5.65 7.05 4.575 6a.872.872 0 0 1-.288-.7c.009-.283.105-.525.288-.725.2-.2.442-.3.725-.3s.517.1.7.3L7.05 5.65c.183.2.275.433.275.7 0 .267-.092.5-.275.7a.854.854 0 0 1-.687.287 1.068 1.068 0 0 1-.713-.287ZM18 19.425l-1.05-1.075a1.018 1.018 0 0 1-.275-.712c0-.275.092-.505.275-.688a.855.855 0 0 1 .688-.287c.275.008.512.104.712.287L19.425 18c.2.183.296.417.288.7a1.087 1.087 0 0 1-.288.725c-.2.2-.442.3-.725.3a.908.908 0 0 1-.7-.3ZM16.95 7.05a.855.855 0 0 1-.287-.688c.008-.275.104-.512.287-.712L18 4.575a.872.872 0 0 1 .7-.288c.283.009.525.105.725.288.2.2.3.442.3.725s-.1.517-.3.7L18.35 7.05c-.2.183-.433.275-.7.275-.267 0-.5-.092-.7-.275ZM4.575 19.425c-.2-.2-.3-.442-.3-.725s.1-.517.3-.7l1.075-1.05c.2-.183.438-.275.713-.275.275 0 .504.092.687.275.2.183.296.413.288.688a1.07 1.07 0 0 1-.288.712L6 19.425a.869.869 0 0 1-.7.287 1.083 1.083 0 0 1-.725-.287Z" })
  }
);
export {
  s as default
};
