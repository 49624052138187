import { jsx as h } from "react/jsx-runtime";
const t = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M2 13V8c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 6h4V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 10 2h4c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v2h4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v5h-7v-1a.97.97 0 0 0-.287-.713A.97.97 0 0 0 14 11h-4a.967.967 0 0 0-.712.287A.968.968 0 0 0 9 12v1H2Zm8-7h4V4h-4v2Zm1 9v-2h2v2h-2Zm-7 6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 19v-4h7v1c0 .283.096.52.288.712A.965.965 0 0 0 10 17h4a.968.968 0 0 0 .713-.288A.967.967 0 0 0 15 16v-1h7v4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 21H4Z" })
  }
);
export {
  t as default
};
