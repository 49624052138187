import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M7.1 11.349c.233-.467.475-.917.725-1.35.25-.434.525-.867.825-1.3l-1.4-.275-2.1 2.1 1.95.825Zm12.05-6.875c-1.167.033-2.412.375-3.737 1.025a13.486 13.486 0 0 0-3.613 2.6c-.7.7-1.325 1.45-1.875 2.25s-.958 1.55-1.225 2.25l2.85 2.825c.7-.267 1.45-.675 2.25-1.225s1.55-1.175 2.25-1.875a13.562 13.562 0 0 0 2.6-3.6c.65-1.317.992-2.559 1.025-3.725a.572.572 0 0 0-.037-.2.491.491 0 0 0-.288-.288.573.573 0 0 0-.2-.037Zm-5.5 6a1.92 1.92 0 0 1-.575-1.413c0-.558.192-1.029.575-1.412.383-.384.858-.575 1.425-.575.567 0 1.042.191 1.425.575.383.383.575.854.575 1.412a1.92 1.92 0 0 1-.575 1.413c-.383.383-.858.575-1.425.575-.567 0-1.042-.192-1.425-.575Zm-.85 6.55.825 1.975 2.1-2.1-.275-1.4c-.433.3-.867.57-1.3.812-.433.242-.883.48-1.35.713Zm8.775-13.35c.133 1.833-.167 3.62-.9 5.362s-1.892 3.404-3.475 4.988l.5 2.475c.067.333.05.658-.05.975-.1.316-.267.591-.5.825L14 21.449a.978.978 0 0 1-.9.287.893.893 0 0 1-.725-.587l-1.525-3.575-4.275-4.275L3 11.774a.924.924 0 0 1-.6-.725.951.951 0 0 1 .275-.9l3.15-3.15c.233-.234.513-.4.838-.5.325-.1.654-.117.987-.05l2.475.5c1.583-1.584 3.246-2.742 4.988-3.475 1.741-.734 3.529-1.034 5.362-.9.133.016.267.054.4.112a1.138 1.138 0 0 1 .587.588c.059.133.096.266.113.4Zm-17.65 12.3c.583-.584 1.296-.88 2.137-.888.842-.008 1.555.28 2.138.863s.87 1.295.862 2.137c-.008.842-.304 1.554-.887 2.138-.417.416-1.112.775-2.087 1.075-.975.3-2.321.566-4.038.8.233-1.717.5-3.063.8-4.038.3-.975.658-1.67 1.075-2.087Zm1.425 1.4c-.167.166-.333.47-.5.913a6.454 6.454 0 0 0-.35 1.337c.45-.067.896-.18 1.338-.337.441-.159.745-.322.912-.488.2-.2.308-.442.325-.725a.907.907 0 0 0-.275-.725.947.947 0 0 0-.725-.288 1.035 1.035 0 0 0-.725.313Z" })
  }
);
export {
  t as default
};
