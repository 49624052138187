import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { fillRule: "evenodd" }, /* @__PURE__ */ e.createElement("path", { d: "m16.73 13.79.168 1.703c.11 1.103-.368 2.105-1.298 2.72L11.39 21l-.359-4.626c-.985-.031-1.839-.375-2.482-1.014-.64-.634-.986-1.475-1.022-2.445L3 12.627l2.68-4.2c.611-.96 1.649-1.46 2.788-1.344l1.652.166c.178-.197.367-.394.565-.59 3.399-3.374 8.755-3.99 10.175-3.52.473 1.41-.147 6.726-3.546 10.1-.194.193-.39.376-.584.551Zm-2.5-6.89a1.984 1.984 0 0 0 0 2.82 2.02 2.02 0 0 0 2.842 0 1.984 1.984 0 0 0 0-2.82 2.02 2.02 0 0 0-2.841 0Z" }), /* @__PURE__ */ e.createElement(
    "path",
    {
      fillOpacity: 0.75,
      d: "m4.593 15.232 1.686-1.668a4.22 4.22 0 0 0 1.217 2.448 4.293 4.293 0 0 0 2.472 1.204l-1.385 1.371-1.435 1.42v-1.825l-2.767.912.922-2.739H3.46l1.134-1.123Z"
    }
  ))
);
export {
  l as default
};
