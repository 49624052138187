import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../../../../../packages/image-grid/package.json";
import { ImageGrid } from "../../../../../packages/image-grid/src/index";
import { ImageGridExample, ImageGridSmallExample, ImageGridTargetHeightExample, ImageGridBrokenExample } from "../../../../../packages/image-grid/src/Examples";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  ImageGrid,
  ImageGridExample,
  ImageGridSmallExample,
  ImageGridTargetHeightExample,
  ImageGridBrokenExample,
  React
};