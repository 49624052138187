import React from "react";
import styled from "styled-components";
import { TextZeta } from "@happeouikit/typography";

const ExampleInnerDropzone = ({
  clickUpload,
  isDragging,
  isDragActive,
  disabled,
}) => {
  return (
    <Container>
      <TextZeta>Test by dragging on to this element</TextZeta>
      {isDragging && <TextZeta>Dragging</TextZeta>}
      {isDragActive && <TextZeta>Drag active</TextZeta>}
      {disabled && <TextZeta>Disabled</TextZeta>}

      <button onClick={clickUpload} type="button">
        Clickedi click to upload
      </button>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
`;

export default ExampleInnerDropzone;
