import { useRef, useState } from "react";
import { hooks } from "@happeo/react-core";
import Popover from "../Popover";

const PopoverExample = ({ text, anchorPosition }) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchor = useRef();
  const popover = useRef();

  hooks.useOnClickOutside(popover, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const [v, h] = anchorPosition.split(" ");

  const top = v === "top" ? { top: -5 } : { bottom: -5 };
  const left = h === "left" ? { left: -5 } : { right: -5 };

  return (
    <div>
      <button
        type="button"
        onClick={() => setIsOpen((prevValue) => !prevValue)}
        ref={anchor}
        style={{ position: "relative" }}
      >
        {text}
        <span
          style={{
            position: "absolute",
            width: "5px",
            height: "5px",
            backgroundColor: "red",
            ...top,
            ...left,
          }}
        />
      </button>
      {isOpen && (
        <Popover anchor={anchor} ref={popover} anchorPosition={anchorPosition}>
          <div
            style={{
              backgroundColor: "#FFF",
              padding: "16px",
              border: "1px solid #eee",
            }}
          >
            <p style={{ margin: 0 }}>I am in a popover lol</p>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default PopoverExample;
