import { jsx as c } from "react/jsx-runtime";
const o = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M13 17.05a11.01 11.01 0 0 1 2.213-.788 10.276 10.276 0 0 1 4.05-.112c.575.1 1.154.25 1.737.45V6.7a8.668 8.668 0 0 0-1.712-.525 9.226 9.226 0 0 0-4.113.125c-.767.2-1.492.5-2.175.9v9.85Zm-1 2.425c-.233 0-.454-.029-.662-.087a2.814 2.814 0 0 1-.588-.238 8.165 8.165 0 0 0-2.05-.862A8.465 8.465 0 0 0 6.5 18c-.7 0-1.387.092-2.062.275a9.047 9.047 0 0 0-1.938.775c-.35.183-.687.175-1.012-.025A.966.966 0 0 1 1 18.15V6.1c0-.183.046-.358.138-.525A.86.86 0 0 1 1.55 5.2c.783-.383 1.588-.68 2.413-.888C4.788 4.104 5.633 4 6.5 4c.967 0 1.913.125 2.838.375S11.15 5 12 5.5A10.78 10.78 0 0 1 17.5 4c.867 0 1.713.104 2.538.312a12.39 12.39 0 0 1 2.412.888.866.866 0 0 1 .413.375c.091.167.137.342.137.525v12.05a.966.966 0 0 1-.487.875c-.325.2-.663.208-1.013.025a9.048 9.048 0 0 0-1.938-.775A7.83 7.83 0 0 0 17.5 18c-.75 0-1.483.096-2.2.288-.717.191-1.4.479-2.05.862-.183.1-.379.18-.587.238-.209.058-.43.087-.663.087Z" })
  }
);
export {
  o as default
};
