import { jsx as e } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.588 21.413C4.979 21.804 5.45 22 6 22h12c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413V8.825a1.978 1.978 0 0 0-.575-1.4l-4.85-4.85a1.978 1.978 0 0 0-1.4-.575H6c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 4 4v16c0 .55.196 1.021.588 1.413ZM13 9h5l-5-5v5Zm3 5H8v-2h8v2Zm0 4H8v-2h8v2Z"
      }
    )
  }
);
export {
  t as default
};
