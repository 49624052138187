import { jsxs as s, jsx as o } from "react/jsx-runtime";
const r = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ o(
        "path",
        {
          d: "M3.642 8.224S2.056 9.8 2.622 13.406c.565 3.604 3.58 5.53 5.264 6.25a25.326 25.326 0 0 1-2.518 2.842s3.16.138 5.754-2.027c3.812.33 7.38-1.247 9.385-5.152 2.006-3.905.916-8.877-2.757-11.684-3.673-2.807-7.52-2.039-7.52-2.039s4.156-.408 5.963 2.105c1.557 2.16 1.598 4.612-.058 7.126-1.743 2.65-4.209 3.574-7.124 3.76-3.177.204-7.304-2.339-5.369-6.363Z",
          fill: "url(#jabber_svg__a)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M17.08 7.924s.63-4.433-3.83-5.884c-2.373-.774-5.212-.096-6.856 1.097C4.68 4.38 3.87 6.274 3.986 6.598c.14.396 1.388-1.163 3.83-1.775 2.53-.63 6.541-.108 9.264 3.1Z",
          fill: "url(#jabber_svg__b)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M10.86 14.09s.413-1.608.046-3.323c-.735-3.383-3.241-4.715-5.9-3.605 3.766-2.1 8.885 1.14 9.083 5.506-1.008 1.02-3.23 1.421-3.23 1.421Z",
          fill: "url(#jabber_svg__c)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M4.184 12.458s-1.13-.797-.868-2.915c.448-3.616 6.78-3.868 7.49 1.536-1.264-.948-5.153-1.422-6.622 1.38Z",
          fill: "url(#jabber_svg__d)"
        }
      ),
      /* @__PURE__ */ s("defs", { children: [
        /* @__PURE__ */ s(
          "linearGradient",
          {
            id: "jabber_svg__a",
            x1: 12.002,
            y1: 22.498,
            x2: 12.002,
            y2: 1.506,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#0D3095" }),
              /* @__PURE__ */ o("stop", { offset: 0.115, stopColor: "#0F3C9D" }),
              /* @__PURE__ */ o("stop", { offset: 0.319, stopColor: "#135AB4" }),
              /* @__PURE__ */ o("stop", { offset: 0.589, stopColor: "#1A8CD8" }),
              /* @__PURE__ */ o("stop", { offset: 0.685, stopColor: "#1D9FE6" })
            ]
          }
        ),
        /* @__PURE__ */ s(
          "linearGradient",
          {
            id: "jabber_svg__b",
            x1: 3.97,
            y1: 4.818,
            x2: 17.108,
            y2: 4.818,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1D9FE6" }),
              /* @__PURE__ */ o("stop", { offset: 0.518, stopColor: "#1B93D4" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#198BC9" })
            ]
          }
        ),
        /* @__PURE__ */ s(
          "linearGradient",
          {
            id: "jabber_svg__c",
            x1: 4.594,
            y1: 8.253,
            x2: 14.371,
            y2: 11.901,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { offset: 0.315, stopColor: "#1D9FE6" }),
              /* @__PURE__ */ o("stop", { offset: 0.373, stopColor: "#1C9AE2" }),
              /* @__PURE__ */ o("stop", { offset: 0.774, stopColor: "#187BCC" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#1670C4" })
            ]
          }
        ),
        /* @__PURE__ */ s(
          "linearGradient",
          {
            id: "jabber_svg__d",
            x1: 7.036,
            y1: 12.455,
            x2: 7.036,
            y2: 6.908,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1670C4" }),
              /* @__PURE__ */ o("stop", { offset: 0.276, stopColor: "#187BCC" }),
              /* @__PURE__ */ o("stop", { offset: 0.767, stopColor: "#1C9AE2" }),
              /* @__PURE__ */ o("stop", { offset: 0.837, stopColor: "#1D9FE6" })
            ]
          }
        )
      ] })
    ]
  }
);
export {
  r as default
};
