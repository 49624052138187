import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-ms-sharepoint_svg__a",
      x1: 5.822,
      y1: 11.568,
      x2: 20.178,
      y2: 36.432,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { offset: 0, stopColor: "#058f92" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.5, stopColor: "#038489" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#026d71" })
  )),
  /* @__PURE__ */ e.createElement("circle", { cx: 24, cy: 15, r: 12, fill: "#036c70" }),
  /* @__PURE__ */ e.createElement("circle", { cx: 34, cy: 26, r: 11, fill: "#1a9ba1" }),
  /* @__PURE__ */ e.createElement("circle", { cx: 25.5, cy: 36.5, r: 8.5, fill: "#37c6d0" }),
  /* @__PURE__ */ e.createElement("path", { fill: "none", d: "M0 0h48v48H0z" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M26 13.83v21.34a1.841 1.841 0 0 1-1.14 1.69 1.772 1.772 0 0 1-.69.14h-7.16c-.01-.17-.01-.33-.01-.5a4.18 4.18 0 0 1 .02-.5 8.473 8.473 0 0 1 5.09-7.29v-1.86A11.986 11.986 0 0 1 12.17 13a8.455 8.455 0 0 1 .21-1h11.79A1.837 1.837 0 0 1 26 13.83z",
      opacity: 0.1
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M23.17 13h-11a11.987 11.987 0 0 0 10.4 13.915c-3.1 1.47-5.359 5.645-5.551 9.085a4.18 4.18 0 0 0-.02.5c0 .17 0 .33.01.5a6.673 6.673 0 0 0 .13 1h6.03a1.841 1.841 0 0 0 1.69-1.14 1.772 1.772 0 0 0 .14-.69V14.83A1.837 1.837 0 0 0 23.17 13z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M23.17 13h-11a11.988 11.988 0 0 0 10.208 13.891c-3 1.576-5.17 5.741-5.358 9.109h6.15A1.844 1.844 0 0 0 25 34.17V14.83A1.837 1.837 0 0 0 23.17 13z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M22.17 13h-10a11.988 11.988 0 0 0 8.87 13.632A16.522 16.522 0 0 0 17.02 36h5.15A1.837 1.837 0 0 0 24 34.17V14.83A1.831 1.831 0 0 0 22.17 13z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 2,
      y: 13,
      width: 22,
      height: 22,
      rx: 1.833,
      fill: "url(#Icon-ms-sharepoint_svg__a)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M10.187 23.776a3.384 3.384 0 0 1-1.049-1.109 3.018 3.018 0 0 1-.365-1.517 2.919 2.919 0 0 1 .679-1.965 4.011 4.011 0 0 1 1.806-1.178 7.629 7.629 0 0 1 2.467-.382 9.392 9.392 0 0 1 3.25.455v2.3a5.073 5.073 0 0 0-1.47-.6 7.159 7.159 0 0 0-1.719-.206 3.719 3.719 0 0 0-1.767.374 1.159 1.159 0 0 0-.7 1.062 1.076 1.076 0 0 0 .3.752 2.69 2.69 0 0 0 .8.572q.5.249 1.5.662a1.519 1.519 0 0 1 .214.086 12.341 12.341 0 0 1 1.892.933 3.387 3.387 0 0 1 1.118 1.126 3.264 3.264 0 0 1 .4 1.7 3.162 3.162 0 0 1-.636 2.046 3.557 3.557 0 0 1-1.7 1.144 7.71 7.71 0 0 1-2.424.352 12.824 12.824 0 0 1-2.2-.18 7.534 7.534 0 0 1-1.783-.524v-2.425a5.746 5.746 0 0 0 1.8.86 7.042 7.042 0 0 0 1.986.318 3.42 3.42 0 0 0 1.8-.382A1.208 1.208 0 0 0 15 26.971a1.15 1.15 0 0 0-.34-.826 3.425 3.425 0 0 0-.937-.653q-.6-.3-1.767-.791a10.044 10.044 0 0 1-1.769-.925z",
      fill: "#fff"
    }
  )
);
export {
  a as default
};
