import { jsxs as o, jsx as l } from "react/jsx-runtime";
const c = (e) => /* @__PURE__ */ o(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z",
          fill: "#CA5040"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M15.225 11.866a4 4 0 1 0-6.45 0c.295.487.647.97 1.01 1.465C10.85 14.787 12 16.356 12 18.5c0-2.144 1.149-3.713 2.215-5.17.363-.495.715-.977 1.01-1.464Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  c as default
};
