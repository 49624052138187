import { jsxs as t, jsx as a } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          d: "M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z",
          fill: "#904EE2"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M6.75 8a.656.656 0 1 0 0 1.313h10.5a.656.656 0 0 0 0-1.313H6.75ZM6.094 11.656c0-.362.294-.656.656-.656h10.5a.656.656 0 0 1 0 1.313H6.75a.656.656 0 0 1-.656-.657ZM6.094 14.656c0-.362.294-.656.656-.656h6a.656.656 0 1 1 0 1.313h-6a.656.656 0 0 1-.656-.657Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  o as default
};
