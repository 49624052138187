/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Microsoft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 2h9.524v9.524H2V2Z" fill="#F35325" />
    <path d="M12.476 2H22v9.524h-9.524V2Z" fill="#81BC06" />
    <path d="M2 12.476h9.524V22H2v-9.524Z" fill="#05A6F0" />
    <path d="M12.476 12.476H22V22h-9.524v-9.524Z" fill="#FFBA08" />
  </svg>
);

export default Microsoft;
