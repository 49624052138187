import { jsx as a } from "react/jsx-runtime";
const e = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M16 14a1 1 0 0 1-1 1h-5a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1Zm3-4a1 1 0 0 1-1 1h-8a1 1 0 1 1 0-2h8a1 1 0 0 1 1 1Zm0-4a1 1 0 0 1-1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1ZM2 5a1 1 0 0 1 1 1v15h15a1 1 0 1 1 0 2H3a2 2 0 0 1-2-2V6a1 1 0 0 1 1-1Zm19-4a2 2 0 0 1 2 2v14c0 1.11-.89 2-2 2H7a2 2 0 0 1-2-2V3c0-1.11.89-2 2-2h14ZM7 3v14h14V3H7Z" })
  }
);
export {
  e as default
};
