import { jsxs as d, jsx as e } from "react/jsx-runtime";
const i = (l) => /* @__PURE__ */ d(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          d: "M1 3.75H.75v17.1h22.5V3.75H1Z",
          stroke: "#000",
          strokeOpacity: 0.2,
          strokeWidth: 0.5
        }
      ),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M1 4h22v16.6H1V4Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M1 4h7.332v16.6H1V4Z",
          fill: "#009246"
        }
      ),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M15.668 4H23v16.6h-7.332V4Z",
          fill: "#CE2B37"
        }
      )
    ]
  }
);
export {
  i as default
};
