import * as React from "react";
const SvgIconAccessTime = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2ZM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Zm-.28-13h.06c.4 0 .72.32.72.72v4.54l3.87 2.3c.35.2.46.65.25.99-.2.34-.64.44-.98.24l-4.15-2.49a.99.99 0 0 1-.49-.86V7.72c0-.4.32-.72.72-.72Z"
    />
  </svg>
);
export default SvgIconAccessTime;
