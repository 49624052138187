import { jsx as t } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M9.35 18H4c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 16V4c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 4 2h16c.55 0 1.02.196 1.413.587C21.803 2.98 22 3.45 22 4v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 18h-5.35l-1.825 2.75c-.2.3-.475.45-.825.45a.941.941 0 0 1-.825-.45L9.35 18ZM4 16h16V4H4v12Z" })
  }
);
export {
  c as default
};
