import { jsx as c } from "react/jsx-runtime";
const A = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M21 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 20 21V3c0-.283.096-.521.288-.713A.967.967 0 0 1 21 2c.283 0 .52.096.712.287.192.192.288.43.288.713v18c0 .283-.096.52-.288.712A.965.965 0 0 1 21 22ZM3.5 10c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 2 8.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 3.5 7h13c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 16.5 10h-13Zm6 7c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 8 15.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 9.5 14h7c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 16.5 17h-7Z" })
  }
);
export {
  A as default
};
