import { jsxs as l, jsx as a } from "react/jsx-runtime";
const i = (h) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          d: "M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z",
          fill: "#4BAEE8"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14.25 9.75V7.2a1.95 1.95 0 0 0-1.95-1.95H7.2A1.95 1.95 0 0 0 5.25 7.2v5.1a1.95 1.95 0 0 0 1.95 1.95h2.55v2.55a1.95 1.95 0 0 0 1.95 1.95h5.1a1.95 1.95 0 0 0 1.95-1.95v-5.1a1.95 1.95 0 0 0-1.95-1.95h-2.55Zm-7.05-3a.45.45 0 0 0-.45.45v5.1c0 .248.201.45.45.45h2.55V11.7a1.95 1.95 0 0 1 1.95-1.95h1.05V7.2a.45.45 0 0 0-.45-.45H7.2Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  i as default
};
