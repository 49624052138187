import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-ms-project_svg__a",
      x1: 5.822,
      y1: 11.568,
      x2: 20.178,
      y2: 36.432,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { offset: 0, stopColor: "#18884f" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.5, stopColor: "#117e43" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#0b6631" })
  )),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M43.17 44H18.83A1.83 1.83 0 0 1 17 42.17V31l10-5 10.917 5h5.253A1.83 1.83 0 0 1 45 32.83v9.34A1.83 1.83 0 0 1 43.17 44Z",
      fill: "#185c37"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m32 17-11 7-13-7V5.83A1.83 1.83 0 0 1 9.83 4h20.34A1.83 1.83 0 0 1 32 5.83Z",
      fill: "#33c481"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M8 17h28.1a1.9 1.9 0 0 1 1.9 1.9V31H9.9A1.9 1.9 0 0 1 8 29.1V17Z",
      fill: "#21a366"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M24.17 37a1.772 1.772 0 0 0 .69-.14A1.841 1.841 0 0 0 26 35.17V13.83A1.844 1.844 0 0 0 24.17 12H8v17.1A1.9 1.9 0 0 0 9.9 31H17v6Z",
      opacity: 0.1
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M17 31v7h6.17a1.841 1.841 0 0 0 1.69-1.14 1.772 1.772 0 0 0 .14-.69V14.83A1.844 1.844 0 0 0 23.17 13H8v16.1A1.9 1.9 0 0 0 9.9 31Z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M23.17 36A1.844 1.844 0 0 0 25 34.17V14.83A1.844 1.844 0 0 0 23.17 13H8v16.1A1.9 1.9 0 0 0 9.9 31H17v5Z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M22.17 36A1.844 1.844 0 0 0 24 34.17V14.83A1.844 1.844 0 0 0 22.17 13H8v16.1A1.9 1.9 0 0 0 9.9 31H17v5Z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 2,
      y: 13,
      width: 22,
      height: 22,
      rx: 1.833,
      fill: "url(#Icon-ms-project_svg__a)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M13.2 17.889a5.039 5.039 0 0 1 3.338.978A3.5 3.5 0 0 1 17.7 21.7a3.942 3.942 0 0 1-.573 2.117 3.829 3.829 0 0 1-1.627 1.438 5.524 5.524 0 0 1-2.447.514h-2.319v4.31H8.359v-12.19Zm-2.468 6.018h2.044a2.654 2.654 0 0 0 1.807-.539 2 2 0 0 0 .611-1.577q0-2.015-2.342-2.015h-2.12Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ e.createElement("path", { fill: "none", d: "M0 0h48v48H0z" })
);
export {
  a as default
};
