import { jsx as c } from "react/jsx-runtime";
const t = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M7 17c-1.383 0-2.562-.488-3.537-1.463C2.488 14.562 2 13.383 2 12s.488-2.563 1.463-3.538C4.438 7.487 5.617 7 7 7h3a.97.97 0 0 1 .713.287A.97.97 0 0 1 11 8c0 .283-.096.52-.287.712A.968.968 0 0 1 10 9H7c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 4 12c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 7 15h3a.97.97 0 0 1 .713.287A.97.97 0 0 1 11 16c0 .283-.096.52-.287.712A.968.968 0 0 1 10 17H7Zm2-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 8 12c0-.283.096-.521.288-.713A.967.967 0 0 1 9 11h6a.97.97 0 0 1 .713.287A.97.97 0 0 1 16 12c0 .283-.096.52-.287.712A.968.968 0 0 1 15 13H9Zm13-1h-2c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 17 9h-3a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 8c0-.283.096-.521.288-.713A.967.967 0 0 1 14 7h3c1.383 0 2.563.487 3.538 1.462C21.513 9.437 22 10.617 22 12ZM15.872 19.12c0 .272.1.508.3.708.2.2.436.3.707.3.27 0 .506-.1.707-.3L19 18.414l1.414 1.414c.2.2.436.3.707.3.271 0 .507-.1.707-.3.2-.2.3-.436.3-.708 0-.27-.1-.506-.3-.706L20.414 17l1.414-1.415c.2-.2.3-.436.3-.707 0-.271-.1-.507-.3-.707-.2-.2-.435-.3-.706-.3-.271 0-.507.1-.708.3L19 15.585l-1.414-1.414c-.2-.2-.436-.3-.707-.3-.271 0-.507.1-.707.3-.2.2-.3.436-.3.707 0 .271.1.507.3.707L17.586 17l-1.414 1.414c-.2.2-.3.436-.3.707Z" })
  }
);
export {
  t as default
};
