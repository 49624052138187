import { jsx as c } from "react/jsx-runtime";
const t = (v) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ c("path", { d: "M4 22a.967.967 0 0 1-.712-.288.967.967 0 0 1-.288-.713v-5c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 13.999v-4c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 7 7.999h4V6.55c-.3-.2-.542-.441-.725-.725C10.092 5.541 10 5.2 10 4.8c0-.25.05-.496.15-.737.1-.242.25-.463.45-.663l1.05-1.05c.033-.033.15-.083.35-.15.033 0 .15.05.35.15L13.4 3.4c.2.2.35.421.45.663.1.241.15.487.15.737 0 .4-.092.742-.275 1.025A2.503 2.503 0 0 1 13 6.55V8h4c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v4c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v5c0 .284-.096.521-.288.713a.968.968 0 0 1-.712.287H4Zm3-8h10v-4H7v4Zm-2 6h14v-4H5v4Z" })
  }
);
export {
  t as default
};
