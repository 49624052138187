import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M11.25 22a7.56 7.56 0 0 1-1.662-.189 11.15 11.15 0 0 1-1.713-.537c.2-2.016.783-3.9 1.75-5.65A17.174 17.174 0 0 1 13.35 11a15.776 15.776 0 0 0-4.762 3.7 13.062 13.062 0 0 0-2.813 5.25 1.743 1.743 0 0 1-.187-.162L5.4 19.6a8.206 8.206 0 0 1-1.787-2.625A7.692 7.692 0 0 1 3 13.95c0-1.133.225-2.216.675-3.25A8.858 8.858 0 0 1 5.55 7.95C6.9 6.6 8.65 5.72 10.8 5.311c2.15-.408 5.167-.445 9.05-.112.3 4.017.25 7.063-.15 9.137-.4 2.076-1.267 3.78-2.6 5.113a8.898 8.898 0 0 1-2.737 1.887A7.682 7.682 0 0 1 11.25 22Z" })
  }
);
export {
  h as default
};
