import { jsx as t } from "react/jsx-runtime";
const e = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "m10.65 19.8-1.725-1.575a68.234 68.234 0 0 1-4.787-4.813C2.713 11.822 2 10.067 2 8.15c0-1.566.525-2.875 1.575-3.925C4.625 3.175 5.933 2.65 7.5 2.65a5.72 5.72 0 0 1 2.5.562c.783.376 1.45.888 2 1.538a5.962 5.962 0 0 1 2-1.538 5.72 5.72 0 0 1 2.5-.562c1.567 0 2.875.525 3.925 1.575C21.475 5.275 22 6.584 22 8.15c0 1.917-.708 3.675-2.125 5.275a59.863 59.863 0 0 1-4.825 4.825l-1.7 1.55c-.383.35-.833.525-1.35.525-.517 0-.967-.175-1.35-.525Z" })
  }
);
export {
  e as default
};
