import { jsx as h } from "react/jsx-runtime";
const o = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M12 20.5a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 19.5a.967.967 0 0 0-.287-.712A.968.968 0 0 0 12 18.5a.965.965 0 0 0-.712.288.965.965 0 0 0-.288.712c0 .283.096.52.288.712A.965.965 0 0 0 12 20.5ZM7 16h10V6H7v10Zm0 7c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 5 21V3c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 7 1h10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v18c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 17 23H7ZM7 4h10V3H7v1Zm0-1v1-1Zm0 15v3h10v-3H7Zm0 3v-3 3Z" })
  }
);
export {
  o as default
};
