import { jsx as h } from "react/jsx-runtime";
const A = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M13 23a.965.965 0 0 1-.712-.288A.965.965 0 0 1 12 22c0-.283.096-.52.288-.712A.965.965 0 0 1 13 21h6v-1h-2c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 15 18v-4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 17 12h2v-1c0-1.933-.683-3.583-2.05-4.95C15.583 4.683 13.933 4 12 4c-1.933 0-3.583.683-4.95 2.05C5.683 7.417 5 9.067 5 11v1h2c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413v4c0 .55-.196 1.021-.588 1.413A1.925 1.925 0 0 1 7 20H5c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 18v-7c0-1.233.237-2.396.712-3.488A9.202 9.202 0 0 1 5.65 4.65a9.202 9.202 0 0 1 2.862-1.938A8.655 8.655 0 0 1 12 2c1.233 0 2.396.237 3.488.712A9.202 9.202 0 0 1 18.35 4.65a9.2 9.2 0 0 1 1.938 2.862A8.654 8.654 0 0 1 21 11v10c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 23h-6Zm-8-5h2v-4H5v4Zm12 0h2v-4h-2v4ZM5 18h2-2Zm12 0h2-2Z" })
  }
);
export {
  A as default
};
