import { jsx as h } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ h("path", { d: "M15 19.586a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-5H7.414c-.89 0-1.337-1.077-.707-1.707l4.586-4.586a1 1 0 0 1 1.414 0l4.586 4.586c.63.63.184 1.707-.707 1.707H15v5Zm-3-9.17-2.17 2.569H11v5.6h2v-5.6h1.17L12 10.415ZM19 5a1 1 0 0 1-1 1H6a1 1 0 1 1 0-2h12a1 1 0 0 1 1 1Z" })
  }
);
export {
  s as default
};
