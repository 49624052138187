import * as React from "react";
const SvgIconLogoGChatColor = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M5.993 12.867v-7.15H2.735C1.775 5.717 1 6.465 1 7.385v14.78c0 .744.936 1.113 1.482.59l3.584-3.437h10.206c.96 0 1.735-.744 1.735-1.663V14.53H7.732c-.959 0-1.74-.744-1.74-1.664Z"
      fill="#00AC47"
    />
    <path
      d="M21.265 1H7.732c-.959 0-1.735.744-1.735 1.663v3.054h10.275c.96 0 1.735.744 1.735 1.663v7.146h3.258c.96 0 1.735-.743 1.735-1.663v-10.2C23 1.743 22.224 1 21.265 1Z"
      fill="#5BB974"
    />
    <path
      d="M16.272 5.717H5.992v7.146c0 .92.776 1.663 1.736 1.663h10.275V7.385c.004-.92-.771-1.668-1.73-1.668Z"
      fill="#00832D"
    />
  </svg>
);
export default SvgIconLogoGChatColor;
