import { jsx as c } from "react/jsx-runtime";
const a = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "m17.35 17.175 3.55-3.55c.2-.2.433-.296.7-.287.267.008.5.112.7.312.183.2.28.433.287.7a.916.916 0 0 1-.287.7l-4.25 4.25c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3l-2.125-2.15a.977.977 0 0 1-.287-.688.93.93 0 0 1 .287-.712.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l1.425 1.425ZM6 18l-2.3 2.3c-.317.317-.68.387-1.088.212-.408-.175-.612-.487-.612-.937V4c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 4 2h16c.55 0 1.02.196 1.413.587C21.803 2.98 22 3.45 22 4v6c0 .283-.096.52-.288.713A.968.968 0 0 1 21 11a.968.968 0 0 1-.712-.287A.968.968 0 0 1 20 10V4H4v13.125L5.15 16H11c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 11 18H6Z" })
  }
);
export {
  a as default
};
