import * as React from "react";
const SvgIconFileAodocs = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="943.9 285.3 149.7 220.9"
    style={{
      enableBackground: "new 943.9 285.3 700.7 220.9",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="m530 58.5-35.1 35.1 73.6 73.6L638.7 97h-70.1S555.7 84.2 530 58.5z"
      style={{
        fill: "#ff2153",
      }}
      transform="translate(449 339)"
    />
    <path
      style={{
        fill: "#f5c600",
      }}
      d="M574.4 91.6H640l-32.8-32.8z"
      transform="translate(449 339)"
    />
    <path
      style={{
        fill: "#0da0ff",
      }}
      d="m496.8 17.8 71.8 71.8 71.5-71.5-71.8-71.8z"
      transform="translate(449 339)"
    />
  </svg>
);
export default SvgIconFileAodocs;
