import { jsx as c } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M7.275 10.475a.74.74 0 0 0 .512.213.737.737 0 0 0 .538-.188l.575-.55.525.525a.72.72 0 0 0 .525.225.72.72 0 0 0 .525-.225.678.678 0 0 0 .213-.525.822.822 0 0 0-.213-.525l-.875-.9c-.1-.1-.208-.175-.325-.225a.942.942 0 0 0-.375-.075 1.03 1.03 0 0 0-.725.3l-.9.9a.72.72 0 0 0-.225.525c0 .2.075.375.225.525Zm6.25 0a.72.72 0 0 0 .525.225.72.72 0 0 0 .525-.225l.525-.525.575.55c.15.133.325.2.525.2a.72.72 0 0 0 .525-.225.72.72 0 0 0 .225-.525.72.72 0 0 0-.225-.525l-.9-.9a1 1 0 0 0-.725-.3.942.942 0 0 0-.375.075c-.117.05-.225.125-.325.225l-.9.9a.766.766 0 0 0-.2.525c0 .2.075.375.225.525ZM12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22Zm0-2c2.217 0 4.104-.779 5.663-2.337C19.221 16.104 20 14.217 20 12s-.779-4.104-2.337-5.663C16.104 4.779 14.217 4 12 4s-4.104.779-5.662 2.337C4.779 7.896 4 9.783 4 12s.78 4.104 2.338 5.663C7.896 19.221 9.783 20 12 20Zm0-2.5c1.033 0 1.958-.25 2.775-.75a5.671 5.671 0 0 0 1.95-1.975c.117-.2.125-.379.025-.537-.1-.159-.267-.238-.5-.238h-8.5c-.233 0-.4.08-.5.238-.1.158-.092.337.025.537a5.672 5.672 0 0 0 1.95 1.975c.817.5 1.742.75 2.775.75Z" })
  }
);
export {
  l as default
};
