import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M5 19a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 18c0-.283.096-.52.287-.712A.968.968 0 0 1 5 17h1v-7c0-.55.071-1.092.213-1.625A6.086 6.086 0 0 1 6.85 6.85l1.5 1.5a4.174 4.174 0 0 0-.262.812A4.01 4.01 0 0 0 8 10v7h6.2L2.1 4.9a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l17 17a.98.98 0 0 1 .288.688.932.932 0 0 1-.288.712.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275L16.15 19H5Zm13-3.85-2-2V10c0-1.1-.392-2.042-1.175-2.825C14.042 6.392 13.1 6 12 6c-.45 0-.87.071-1.262.213A4.148 4.148 0 0 0 9.65 6.8L8.2 5.35c.333-.25.692-.48 1.075-.688A4.836 4.836 0 0 1 10.5 4.2v-.7c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 12 2c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v.7a5.927 5.927 0 0 1 3.225 2.087C17.575 7.346 18 8.583 18 10v5.15ZM12 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 20h4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 22Z" })
  }
);
export {
  t as default
};
