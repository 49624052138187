import { jsx as c } from "react/jsx-runtime";
const m = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M4 9c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 6 7c.55 0 1.021.196 1.413.588C7.804 7.979 8 8.45 8 9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 6 11c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 4 9Zm0 6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 6 13c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 6 17c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 4 15Zm6-6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 12 7c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 11c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 9Zm0 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 12 13c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 17c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 15Zm6-6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 18 7c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.021-.588 1.413A1.925 1.925 0 0 1 18 11c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 16 9Zm0 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 18 13c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.021-.588 1.413A1.925 1.925 0 0 1 18 17c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 16 15Z" })
  }
);
export {
  m as default
};
