import { jsxs as i, jsx as e } from "react/jsx-runtime";
const d = (l) => /* @__PURE__ */ i(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          d: "M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z",
          fill: "#F79232"
        }
      ),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M13.5 12V7.5a1.5 1.5 0 0 0-3 0V12a1.5 1.5 0 0 0 3 0ZM12 15a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 12 15Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  d as default
};
