/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleAcrobat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z"
      fill="#DA3737"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.63 11.14c-.23.74-.56 1.53-1.01 2.4-.2.38-.42.74-.5 1.04l.11-.04c1.27-.49 2.47-.77 3.34-.93-.15-.1-.29-.21-.41-.32-.6-.51-1.12-1.25-1.53-2.15Zm6.48 3.81c-.17.19-.47.3-.91.3-.76 0-2-.23-3-.74-1.72.19-3 .42-4.03.75-.05.02-.11.04-.18.07-1.24 2.12-2.16 3.07-2.97 3.07-.16 0-.32-.04-.44-.11l-.48-.31-.03-.05a.908.908 0 0 1-.05-.54c.11-.53.68-1.37 1.88-2.12.19-.14.49-.3.89-.49.3-.52.62-1.13.96-1.8.51-1.03.83-2.05 1.08-2.93v-.01c-.37-1.21-.59-1.94-.22-3.27.09-.38.42-.77.79-.77h.24c.23 0 .45.08.61.24.66.66.35 2.27.02 3.6-.02.06-.03.11-.04.14.4 1.13.97 2.04 1.6 2.56.26.2.55.4.87.58.45-.05.88-.07 1.29-.07 1.24 0 1.99.22 2.28.69.1.16.15.35.12.55-.01.25-.1.48-.28.66Zm-.41-.71c-.1-.1-.5-.35-1.9-.35-.07 0-.14 0-.23.1.73.32 1.44.51 1.9.51.07 0 .13-.01.19-.02h.04c.05-.02.08-.03.09-.13-.02-.03-.04-.07-.09-.11ZM8.53 15.7c-.21.12-.38.23-.48.31-.71.65-1.16 1.31-1.21 1.69.45-.15 1.04-.81 1.69-2Zm3.02-6.91.05-.04c.07-.32.1-.6.16-.82l.03-.16c.1-.57.08-.86-.09-1.1l-.15-.05c-.02.03-.05.08-.07.12-.17.42-.16 1.15.07 2.05Z"
      fill="#fff"
    />
  </svg>
);

export default GoogleAcrobat;
