// note:  I'm doing a weird [a, b][0] thing here just to make my IDE display the old and new colors visually side by side for sanity.
// Source for old-to-new color mapping:
// https://docs.google.com/spreadsheets/d/1LjX4yY80kYhajoMyQc_JoQKd2XBdQ3Y5u_E5adiqIiQ/edit#gid=0
// Source for new colors (Design System 3, DS3):
// https://www.figma.com/file/mGKUf76F62y0V6oXgOeyol/DS-3.0---Colors-and-Styles
export const oldColors = {
  old__navy__will_become__navy700: ["#1A5D8D", "#3242A9"][0],
  old__active__will_become__activePrimary: ["#009DFF", "#0075DB"][0],
  old__success__will_become__successPrimary: ["#29C47F", "#16884f"][0],
  old__warn__will_become__warningPrimary: ["#FABD24", "#c25700"][0],
  old__alert__will_become__alertPrimary: ["#EC4B2F", "#da3939"][0],
  old__violet__will_become__violet800: ["#5E2590", "#5d248e"][0],
  old__pink__will_become__pink500: ["#F62A99", "#d7097b"][0],
  old__brown__will_become__orange700: ["#894C15", "#984501"][0],
  old__brand__will_be_removed: ["#29C4A9", "#FF00AAFF"][0], // will no longer be used!
  old__brandLight__will_be_removed: ["#00E5BD", "#FF00AAFF"][0], // will no longer be used!
  old__gray01__will_become__gray900: ["#2A3443", "#2B313B"][0], // common semantic: --color-primary-text-on-light
  old__gray02__will_become__gray800: ["#343E49", "#38404C"][0],
  old__gray03__will_become__gray700: ["#434F59", "#4A5464"][0], // common semantic: --color-secondary-text-on-light
  old__gray04__will_become__gray600: ["#5A6872", "#596578"][0], // common semantic: --color-stroke-darken
  old__gray05__will_become__gray500: ["#8997A1", "#68778D"][0], // common semantic: --color-hint-text-on-light
  old__gray06__will_become__gray400: ["#BAC6D0", "#95A0B1"][0], // common semantic: --color-placeholder-text-on-light
  old__gray07__will_become__gray300: ["#D4DBE1", "#CAD0D8"][0], // common semantic: --color-disabled-text-on-light
  old__gray08__will_become__gray200: ["#E8ECF2", "#EAEDF0"][0], // common semantic: --color-divider
  old__gray09__will_become__gray100: ["#F7F9FB", "#F3F5F7"][0], // common semantic: --color-surface-over
  old__gray10__will_become__gray50: ["#FBFCFD", "#FCFCFD"][0],
  old__black__will_become__black100: ["#000000", "#000000"][0],
  old__black70__will_become__black100: ["rgba(0, 0, 0, 0.7)", "#000000"][0],
  old__black50__will_become__black70: [
    "rgba(0, 0, 0, 0.5)",
    "rgba(0, 0, 0, 0.7)",
  ][0],
  // old black30 will stay black30
  // old black20 will stay black20
  old__white__will_become__white100: ["#FFFFFF", "#FFFFFF"][0],
  // old white70 will stay white70
  old__bgWhite__will_become__colorSurface: ["#FFFFFF", "#FFFFFF"][0],
  old__bgGray__will_become__colorSurfaceDarken: ["#F7F9FB", "#F3F5F7"][0],
  old__darken20active__will_become__activeDarken20: ["#007dcc", "#005eaf"][0],
  old__darken10active__will_become__activeDarken10: ["#008de5", "#0069c5"][0],
  old__lighten50active__will_become__activeLighten50: ["#7fceff", "#80baed"][0],
  old__lighten80active__will_become__activeLighten80: ["#ccebff", "#cce3f8"][0],
  old__lighten90active__will_become__activeLighten90: ["#e5f5ff", "#e6f1fb"][0],
  old__darken20alert__will_become__alertDarken20: ["#bc3c25", "#ae2e2e"][0],
  old__darken10alert__will_become__alertDarken10: ["#d4432a", "#c43333"][0],
  old__lighten50alert__will_become__alertLighten50: ["#f5a597", "#ed9c9c"][0],
  old__lighten80alert__will_become__alertLighten80: ["#fbdbd5", "#f8d7d7"][0],
  old__lighten90alert__will_become__alertLighten90: ["#fdedea", "#fbebeb"][0],
  old__darken20warn__will_become__warningDarken20: ["#c8971c", "#9b4600"][0],
  old__darken10warn__will_become__warningDarken10: ["#e1aa20", "#af4e00"][0],
  old__lighten50warn__will_become__warningLighten50: ["#fcde91", "#e1ab80"][0],
  old__lighten80warn__will_become__warningLighten80: ["#fef1d3", "#f3ddcc"][0],
  old__lighten90warn__will_become__warningLighten90: ["#fef8e9", "#f9eee6"][0],
  old__darken20success__will_become__successDarken20: ["#209c65", "#126d3f"][0],
  old__darken10success__will_become__successDarken10: ["#24b072", "#147a47"][0],
  old__lighten50success__will_become__successLighten50: [
    "#94e1bf",
    "#8bc4a7",
  ][0],
  old__lighten80success__will_become__successLighten80: [
    "#d4f3e5",
    "#d0e7dc",
  ][0],
  old__lighten90success__will_become__successLighten90: [
    "#e9f9f2",
    "#e8f3ed",
  ][0],
} as const;
