import { jsx as h } from "react/jsx-runtime";
const a = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M17.8 20c-.4 1.2-1.5 2-2.8 2H5c-1.7 0-3-1.3-3-3v-1h12.2c.4 1.2 1.5 2 2.8 2h.8ZM19 2c1.7 0 3 1.3 3 3a1 1 0 1 1-2 0c0-.6-.4-1-1-1s-1 .4-1 1v13h-1c-.6 0-1-.4-1-1v-1H6a1 1 0 0 1-1-1V5c0-1.7 1.3-3 3-3h11ZM9 6a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2H9Z" })
  }
);
export {
  a as default
};
