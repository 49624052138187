import styled from "styled-components";
import { TextEpsilon, BodyUI } from "@happeouikit/typography";
import { gray07, gray04 } from "@happeouikit/colors";

export const ICON_SIZE = "48px";
const IconStyle = {
  width: ICON_SIZE,
  height: ICON_SIZE,
  fill: gray07,
};

const TypeaheadMessage = ({ icon, title, details }) => {
  const Icon = icon;
  return (
    <Flexbox>
      <Icon style={IconStyle} />
      <Title>{title}</Title>
      <Details>{details}</Details>
    </Flexbox>
  );
};

const Title = styled(TextEpsilon)`
  text-align: center;
  margin-bottom: 8px;
`;

const Details = styled(BodyUI)`
  text-align: center;
  color: ${gray04};
`;

const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

export default TypeaheadMessage;
