import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M16.175 7.35a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l1.4-1.4 1.4 1.4a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7l-1.4-1.4 1.4-1.4a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-1.4 1.4-1.4-1.4a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275.948.948 0 0 0-.275.7c0 .283.092.516.275.7l1.4 1.4-1.4 1.4a.948.948 0 0 0-.275.7c0 .283.092.516.275.7ZM12 12c.55 0 1.021-.196 1.413-.588.391-.392.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 12 8c-.55 0-1.02.195-1.412.587A1.927 1.927 0 0 0 10 10c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm0 9.625c-.133 0-.267-.025-.4-.075a1.2 1.2 0 0 1-.35-.2C8.817 19.2 7 17.204 5.8 15.363 4.6 13.52 4 11.8 4 10.2c0-2.5.804-4.492 2.413-5.975C8.021 2.74 9.883 2 12 2a10.427 10.427 0 0 1 1 .05v2.025a3.447 3.447 0 0 0-.488-.063A10.165 10.165 0 0 0 12 4c-1.683 0-3.104.579-4.263 1.737C6.579 6.895 6 8.383 6 10.2c0 1.183.492 2.537 1.475 4.062.983 1.525 2.492 3.22 4.525 5.088 2.033-1.867 3.542-3.563 4.525-5.088C17.508 12.737 18 11.382 18 10.2V10h2v.2c0 1.6-.6 3.32-1.8 5.163-1.2 1.84-3.017 3.837-5.45 5.987a1.2 1.2 0 0 1-.35.2c-.133.05-.267.075-.4.075Z" })
  }
);
export {
  l as default
};
