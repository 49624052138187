import { jsxs as a, jsx as t } from "react/jsx-runtime";
const i = (s) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: [
      /* @__PURE__ */ t("path", { d: "M22 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z" }),
      /* @__PURE__ */ t("path", { d: "M10 20c.462 0 .5-.618.074-.8A10.003 10.003 0 0 1 4.221 7.9c.111-.52-.485-.904-.79-.468A8 8 0 0 0 10 20Z" })
    ]
  }
);
export {
  i as default
};
