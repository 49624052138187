import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";

import { IconUpload } from "@happeouikit/icons";
import { animationCurve } from "@happeouikit/theme";
import { active, gray08, lighten, white } from "@happeouikit/colors";
import { TextEta, TextZeta } from "@happeouikit/typography";
import messages from "./messages";

const DropzoneDefaultInner = ({
  onClickUpload,
  isDragActive,
  text,
  disabled,
}) => {
  const intl = useIntl();
  return (
    <Container
      type="button"
      onClick={onClickUpload}
      aria-label={intl.formatMessage(messages.uploadFile)}
      isDisabled={disabled}
      disabled={disabled}
      isDragActive={isDragActive}
    >
      <IconContainer className="dropzone-icon-container">
        <IconUpload />
      </IconContainer>
      <TextContainer className="dropzone-text-container">
        <TextZeta style={{ marginBottom: "4px" }}>
          {text || intl.formatMessage(messages.uploadFile)}
        </TextZeta>

        <TextEta>
          <FormattedMessage
            id="dropzone.dragDropText"
            defaultMessage="Drag and drop here or <btn>browse files</btn>"
            values={{
              btn: (chunks) => (
                <span className="dropzone-text-link" style={{ color: active }}>
                  {chunks}
                </span>
              ),
            }}
          />
        </TextEta>
      </TextContainer>
    </Container>
  );
};

const Container = styled.button`
  padding: 48px;
  background-color: transparent;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ isDragActive, disabled }) =>
    !isDragActive || disabled
      ? ""
      : `
        .dropzone-icon-container {
            background-color: ${white};
            transform: translate3d(0, 34px, 0);
        }
        .dropzone-text-container {
            opacity: 0;
        }
    `}

  ${({ isDisabled }) =>
    !isDisabled
      ? `
        cursor: pointer;
        &:hover,
        &:focus,
        &:active {
            .dropzone-text-link {
                text-decoration: underline;
            }
            .dropzone-icon-container {
                background-color: ${lighten(active, 0.9)};
                svg {
                    transform: translate3d(0, -2px, 0);
                }
            }
        }
      `
      : `
    opacity: 0.6;
  `}
`;
const IconContainer = styled.div`
  border-radius: 50%;
  background-color: ${gray08};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  transition: all 0.2s ${animationCurve};
  svg {
    transition: transform 0.2s ${animationCurve};
    width: 24px;
    height: 24px;
  }
`;
const TextContainer = styled.div`
  margin-top: 8px;
  transition: opacity 0.2s ${animationCurve};
`;

export default DropzoneDefaultInner;
