import {
  SRCSET_SIZES,
  ROOT,
  HAPPEO_IMG_ROOT,
  HAPPEO_IMG_SIZES,
  SRCSET_IMG_SIZES,
  MISSING_IMAGE_ALT_TEXT,
} from "./constants";

export const generateHappeoImageSrcSet = (image) => {
  const { happeoid, alt } = image;

  return {
    ...image,
    src: `${ROOT}${HAPPEO_IMG_ROOT}${happeoid}`,
    srcSet: HAPPEO_IMG_SIZES.map(
      (size) =>
        `${ROOT}${HAPPEO_IMG_ROOT}${happeoid}/${size} ${SRCSET_IMG_SIZES[size]}`
    ),
    preloadsrc: `${ROOT}${HAPPEO_IMG_ROOT}${happeoid}/sm`,
    sizes: SRCSET_SIZES,
    alt: alt || MISSING_IMAGE_ALT_TEXT,
  };
};

export const processImage = async (image) => {
  return new Promise((resolve) => {
    const imgObj = {
      ...image,
      alt: image.alt || "",
    };
    const preloadImage = new Image();

    preloadImage.src = image.src;
    preloadImage.onerror = (error) => {
      resolve({
        ...imgObj,
        error,
        width: 2,
        height: 1,
      });
    };

    preloadImage.onload = () => {
      resolve({
        ...imgObj,
        width: preloadImage.width,
        height: preloadImage.height,
      });
    };
  });
};
