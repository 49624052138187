import { IconError } from "@happeouikit/icons";
import { useIntl } from "react-intl";
import messages from "./messages";
import TypeaheadMessage from "./TypeaheadMessage";

const TypeaheadError = () => {
  const intl = useIntl();
  return (
    <TypeaheadMessage
      icon={IconError}
      title={intl.formatMessage(messages.typeaheadServerError)}
      details={intl.formatMessage(messages.typeaheadServerErrorDetails)}
    />
  );
};

export default TypeaheadError;
