import { jsxs as c, jsx as h } from "react/jsx-runtime";
const e = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ h("path", { d: "M19 6h-1a.967.967 0 0 1-.712-.287A.968.968 0 0 1 17 5c0-.283.096-.521.288-.713A.967.967 0 0 1 18 4h1V3c0-.283.096-.521.288-.713A.967.967 0 0 1 20 2c.283 0 .52.096.712.287.192.192.288.43.288.713v1h1c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 22 6h-1v1a.968.968 0 0 1-.288.713A.967.967 0 0 1 20 8a.967.967 0 0 1-.712-.287A.968.968 0 0 1 19 7V6ZM14 14a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" }),
      /* @__PURE__ */ h(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 1 20V8c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 3 6h3.15L7.4 4.65A2.01 2.01 0 0 1 8.875 4H13a.97.97 0 0 1 .713.287A.97.97 0 0 1 14 5v2c0 .283.096.521.288.713A.967.967 0 0 0 15 8h2v2c0 .283.096.52.288.712A.965.965 0 0 0 18 11h2c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 22H3Zm13-8a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
        }
      )
    ]
  }
);
export {
  e as default
};
