/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraDefect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm14.539 10.303-.01.008a.74.74 0 0 1 .16.448.757.757 0 0 1-.754.759.742.742 0 0 1-.595-.313l-.01.008-1.53-2.052-1.718 3.454-.008-.003a.75.75 0 0 1-.667.423.753.753 0 0 1-.699-.478h-.001l-2.2-5.527-2.411 8.49-.014-.004a.745.745 0 0 1-.712.554.75.75 0 0 1-.67-.423l-.007.003-3.018-6.07.007-.003a.747.747 0 0 1-.086-.336c0-.42.338-.759.755-.759.294 0 .543.175.667.422l.008-.003 2.15 4.325L10.52 6.48l.012.005a.745.745 0 0 1 .714-.554c.317 0 .588.197.698.478l.003-.002 2.393 6.019 1.513-3.041.008.003a.748.748 0 0 1 .668-.422c.246 0 .456.126.593.311l.01-.008 2.265 3.035Z"
    />
  </svg>
);

export default JiraDefect;
