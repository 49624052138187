import styled, { keyframes } from "styled-components";

export const ButtonLoader = () => (
  <Loader>
    <Dot />
    <Dot />
    <Dot />
  </Loader>
);

const pulse = keyframes`
0% {
  opacity: 0.24;
  transform: scale(0.625);
}

100% {
  opacity: 1;
  transform: scale(1.375);
}
`;

const Dot = styled.div`
  animation: ${pulse} 0.8s ease-in-out 0s infinite alternate;

  background-color: currentColor;
  border-radius: var(--radii-circle);
  &:nth-child(2) {
    animation-delay: 0.24s;
  }

  &:nth-child(3) {
    animation-delay: 0.48s;
  }
`;

const Loader = styled.div`
  height: 24px !important;
  width: 24px !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  ${Dot} {
    width: 3px;
    height: 3px;
  }
`;
