import { jsx as c } from "react/jsx-runtime";
const a = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm-2.5-8.5c.967 0 1.792-.342 2.475-1.025A3.372 3.372 0 0 0 13 10c0-.967-.342-1.792-1.025-2.475A3.372 3.372 0 0 0 9.5 6.5c-.967 0-1.792.342-2.475 1.025A3.372 3.372 0 0 0 6 10c0 .967.342 1.792 1.025 2.475A3.372 3.372 0 0 0 9.5 13.5Zm7 1c.7 0 1.292-.242 1.775-.725C18.758 13.292 19 12.7 19 12s-.242-1.292-.725-1.775C17.792 9.742 17.2 9.5 16.5 9.5s-1.292.242-1.775.725C14.242 10.708 14 11.3 14 12s.242 1.292.725 1.775c.483.483 1.075.725 1.775.725ZM12 20c1.417 0 2.708-.333 3.875-1a8.133 8.133 0 0 0 2.825-2.65c-.35-.1-.708-.183-1.075-.25A6.274 6.274 0 0 0 16.5 16c-.883 0-1.95.258-3.2.775-1.25.517-2.25 1.533-3 3.05.283.067.567.113.85.137.283.026.567.038.85.038Zm-3.175-.65c.583-1.2 1.246-2.092 1.988-2.675.741-.583 1.304-.975 1.687-1.175-.483-.15-.97-.27-1.463-.363A8.373 8.373 0 0 0 9.5 15c-.75 0-1.492.092-2.225.275a10.82 10.82 0 0 0-2.125.775 8.83 8.83 0 0 0 1.587 1.938 7.454 7.454 0 0 0 2.088 1.362Z" })
  }
);
export {
  a as default
};
