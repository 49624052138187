/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleFont = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 20.867 11.633 4h6.6v2.347l-9.166 14.52" fill="#95A0B1" />
    <path d="M18.233 20.867h-6.6V4h6.6v16.867Z" fill="#596578" />
    <path
      d="M23 16.1a4.767 4.767 0 0 1-4.767 4.767v-9.534A4.767 4.767 0 0 1 23 16.1Z"
      fill="#68778D"
    />
    <path d="M18.233 20.867a4.767 4.767 0 1 1 0-9.534v9.534Z" fill="#38404C" />
    <path
      d="M21.9 7.667a3.667 3.667 0 0 1-3.667 3.666V4A3.667 3.667 0 0 1 21.9 7.667Z"
      fill="#596578"
    />
    <path d="M18.233 11.333a3.667 3.667 0 1 1 0-7.333v7.333Z" fill="#38404C" />
    <path
      d="M1.367 7.3a3.3 3.3 0 1 1 6.6 0 3.3 3.3 0 0 1-6.6 0Z"
      fill="#596578"
    />
  </svg>
);

export default GoogleFont;
