import { jsxs as a, jsx as l } from "react/jsx-runtime";
const t = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4.588 21.413C4.979 21.804 5.45 22 6 22h4.177c.43-1.717 1.215-3.488 2.554-4.974 1.209-1.34 2.807-2.378 4.83-2.945.256-1.236 1.315-1.99 2.439-2.067V8.825a1.978 1.978 0 0 0-.575-1.4l-4.85-4.85a1.978 1.978 0 0 0-1.4-.575H6c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 4 4v16c0 .55.196 1.021.588 1.413ZM18 9h-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 8V4l5 5Z"
        }
      ),
      /* @__PURE__ */ l("path", { d: "M12.512 21.22a9.236 9.236 0 0 1 1.303-2.372c1.184-1.54 2.965-2.725 5.57-3.114l.011-.002.08-.01.027-.005v-1.052c0-.59.737-.887 1.167-.47l3.13 3.038a.646.646 0 0 1 0 .934l-3.13 3.039c-.43.417-1.167.126-1.167-.464v-1.119c-3.41 0-5.798 1.06-7.503 3.377a11.868 11.868 0 0 1 .512-1.78Z" })
    ]
  }
);
export {
  t as default
};
