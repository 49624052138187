import { jsxs as s, jsx as a } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ a("path", { d: "M11.392 17.328c.035.031.072.06.109.09a4.9 4.9 0 0 1 .055.046 6.798 6.798 0 1 1 0-11.33l-.055.044-.11.091a7.316 7.316 0 0 0 0 11.051v.008ZM16.196 5.004a6.77 6.77 0 0 0-3.764 1.133l.056.045.109.09a7.318 7.318 0 0 1 0 11.052c-.035.031-.072.061-.11.09l-.055.046a6.798 6.798 0 1 0 3.764-12.456Z" }),
      /* @__PURE__ */ a("path", { d: "M11.738 6.673c.083-.074.17-.143.256-.213a6.798 6.798 0 0 1 .257 10.471c-.083.074-.17.143-.257.213-.086-.07-.173-.14-.256-.213a6.798 6.798 0 0 1 0-10.258Z" })
    ]
  }
);
export {
  h as default
};
