import { jsx as t } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M12 20c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 10.5 18.5V7h-4c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 5 5.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 6.5 4h11c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 17.5 7h-4v11.5c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 12 20Z" })
  }
);
export {
  o as default
};
