/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraSubTask = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm9.393 5.2v2.55h2.55a1.95 1.95 0 0 1 1.95 1.95v5.1a1.95 1.95 0 0 1-1.95 1.95h-5.1a1.95 1.95 0 0 1-1.95-1.95v-2.55H7.2a1.95 1.95 0 0 1-1.95-1.95V7.2A1.95 1.95 0 0 1 7.2 5.25h5.1a1.95 1.95 0 0 1 1.95 1.95Zm-7.5 0a.45.45 0 0 1 .45-.45h5.1a.45.45 0 0 1 .45.45v2.55H11.7a1.95 1.95 0 0 0-1.95 1.95v1.05H7.2a.45.45 0 0 1-.45-.45V7.2Z"
    />
  </svg>
);

export default JiraSubTask;
