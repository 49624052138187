import { jsx as A } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ A("path", { d: "M8 18H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 17c0-.283.096-.52.288-.712A.965.965 0 0 1 4 16h4c.283 0 .521.096.713.288A.967.967 0 0 1 9 17c0 .283-.096.52-.287.712A.968.968 0 0 1 8 18ZM20 8H4a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 7c0-.283.096-.521.288-.713A.967.967 0 0 1 4 6h16c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 20 8Zm-6 5H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 12c0-.283.096-.521.288-.713A.967.967 0 0 1 4 11h10a.97.97 0 0 1 .713.287A.97.97 0 0 1 15 12c0 .283-.096.52-.287.712A.968.968 0 0 1 14 13Z" })
  }
);
export {
  h as default
};
