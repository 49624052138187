import * as e from "react";
const t = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M6.724 15.553A1 1 0 0 0 7.618 17h.764a1 1 0 0 0 .894-.553l1.09-2.18A6.001 6.001 0 0 0 11 11.584V9a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1l-1.276 2.553Zm8 0A1 1 0 0 0 15.618 17h.764a1 1 0 0 0 .894-.553l1.09-2.18A6.001 6.001 0 0 0 19 11.584V9a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h1l-1.276 2.553"
    }
  )
);
export {
  t as default
};
