import { jsxs as i, jsx as l } from "react/jsx-runtime";
const a = (e) => /* @__PURE__ */ i(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z",
          fill: "#904EE2"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m16.385 11.635-.003-.006a.718.718 0 0 0 .118-.38.75.75 0 0 0-.75-.75H12V6.75a.75.75 0 0 0-.75-.75.736.736 0 0 0-.607.335.747.747 0 0 0-.099.195l-2.922 5.826.003.005a.733.733 0 0 0-.125.39c0 .415.336.75.75.75H12v3.75c0 .415.336.75.75.75a.74.74 0 0 0 .627-.362l.005.002.02-.04c.016-.031.034-.06.046-.093l2.937-5.872Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  a as default
};
