import { jsx as h } from "react/jsx-runtime";
const o = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.198 2h7.428v.967h.967v4.176H16.89v.967h2.813v.879h-3.692v1.846h1.89v1.934h-.967v-.967h-.923v3.253h-.923v1.363h-.923v.923h-.923v3.736h.923V22h-1.89v-2.813h-.923v-.88h-.923v.88h-.924v.967h-.923v.923h.923V22h-1.89v-3.692h-.923v-.923H5.77v-.924h-.923v-.966H3.88v-.88H3V8.99h.88v1.846h1.01v.967h.923v.923h1.802v-.923h.923v-.923H9.99v-.923h1.363v-.967h.923V2.967h.923V2Zm1.934 1.363H14.12v1.01h1.01v-1.01Z"
      }
    )
  }
);
export {
  o as default
};
