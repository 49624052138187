import { jsx as c } from "react/jsx-runtime";
const h = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M12 15c.55 0 1.021-.196 1.413-.588.391-.391.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 12 11c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 10 13c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm-6 7c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 2h8.075c.283 0 .563.063.838.188s.504.304.687.537l3.925 4.725c.15.183.267.383.35.6.083.217.125.442.125.675V19.55L15.45 15c.183-.283.321-.592.413-.925.091-.333.137-.692.137-1.075 0-1.1-.392-2.042-1.175-2.825C14.042 9.392 13.1 9 12 9s-2.042.392-2.825 1.175C8.392 10.958 8 11.9 8 13s.392 2.042 1.175 2.825C9.958 16.608 10.9 17 12 17c.383 0 .742-.046 1.075-.137.333-.092.642-.23.925-.413l5.2 5.15a2.245 2.245 0 0 1-.55.3c-.183.067-.4.1-.65.1H6Z" })
  }
);
export {
  h as default
};
