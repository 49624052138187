import { jsxs as t, jsx as o } from "react/jsx-runtime";
const p = (s) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: [
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__a)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__b)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__c)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__d)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__e)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__f)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__g)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__h)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__i)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__j)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__k)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__l)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__m)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__n)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__o)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__p)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__q)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__r)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__s)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "url(#webex_svg__t)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "m10.057 10.022 1.47-3.942a5.169 5.169 0 0 1 1.83-1.55 5.492 5.492 0 0 1 2.32-.53c1.154.009 2.273.399 3.183 1.109 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.753a1.91 1.91 0 0 1-.748.489c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.944-.228 2.697 2.697 0 0 1-.786-.594.032.032 0 0 0-.032-.032 4.335 4.335 0 0 1-.36-.653 6.622 6.622 0 0 0-.425-.817c-.095-.133-.164-.265-.26-.393a.982.982 0 0 0-.297-.28.647.647 0 0 0-.716-.096l-.032.031a1.8 1.8 0 0 0-.53.818c-.333.78-.598 1.588-.791 2.414l-.096.488-.982 3.136-2.876-5.545Z",
          fill: "url(#webex_svg__u)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__v)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__w)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__x)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__y)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__z)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__A)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__B)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__C)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__D)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__E)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__F)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__G)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__H)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "url(#webex_svg__I)"
        }
      ),
      /* @__PURE__ */ t("defs", { children: [
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__b",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(74.325 -.612 13.964) scale(8.62092 6.55031)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#227B60" }),
              /* @__PURE__ */ o("stop", { offset: 0.14, stopColor: "#227B60", stopOpacity: 0.81 }),
              /* @__PURE__ */ o("stop", { offset: 0.58, stopColor: "#227B60", stopOpacity: 0.23 }),
              /* @__PURE__ */ o("stop", { offset: 0.78, stopColor: "#227B60", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__c",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(91.132 1.907 12.093) scale(5.82747 4.42904)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#227B60" }),
              /* @__PURE__ */ o("stop", { offset: 0.25, stopColor: "#227B60", stopOpacity: 0.7 }),
              /* @__PURE__ */ o("stop", { offset: 0.71, stopColor: "#227B60", stopOpacity: 0.2 }),
              /* @__PURE__ */ o("stop", { offset: 0.92, stopColor: "#227B60", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__d",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(88.612 1.707 12.244) scale(5.43314 3.85689)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#227B60" }),
              /* @__PURE__ */ o("stop", { offset: 0.07, stopColor: "#227B60", stopOpacity: 0.9 }),
              /* @__PURE__ */ o("stop", { offset: 0.34, stopColor: "#227B60", stopOpacity: 0.51 }),
              /* @__PURE__ */ o("stop", { offset: 0.59, stopColor: "#227B60", stopOpacity: 0.23 }),
              /* @__PURE__ */ o("stop", { offset: 0.79, stopColor: "#227B60", stopOpacity: 0.06 }),
              /* @__PURE__ */ o("stop", { offset: 0.92, stopColor: "#227B60", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__e",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(88.582 1.824 12.537) scale(4.38672 3.11641)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#227B60" }),
              /* @__PURE__ */ o("stop", { offset: 0.36, stopColor: "#227B60", stopOpacity: 0.63 }),
              /* @__PURE__ */ o("stop", { offset: 0.92, stopColor: "#227B60", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__f",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "matrix(.08553 3.49711 -2.23713 .05472 13.89 10.285)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#20735A" }),
              /* @__PURE__ */ o("stop", { offset: 0.15, stopColor: "#20745A", stopOpacity: 0.93 }),
              /* @__PURE__ */ o("stop", { offset: 0.38, stopColor: "#21755C", stopOpacity: 0.74 }),
              /* @__PURE__ */ o("stop", { offset: 0.67, stopColor: "#21785D", stopOpacity: 0.43 }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#227B60", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__g",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "matrix(5.15192 2.27329 -1.70422 3.86226 14.698 11.171)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#227A61" }),
              /* @__PURE__ */ o("stop", { offset: 0.36, stopColor: "#227A61", stopOpacity: 0.56 }),
              /* @__PURE__ */ o("stop", { offset: 0.72, stopColor: "#227A61", stopOpacity: 0.16 }),
              /* @__PURE__ */ o("stop", { offset: 0.9, stopColor: "#227A61", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__h",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(35.278 3.545 21.294) scale(7.50772 6.86598)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#3CD9C3" }),
              /* @__PURE__ */ o("stop", { offset: 0.07, stopColor: "#3BD7C1", stopOpacity: 0.9 }),
              /* @__PURE__ */ o("stop", { offset: 0.41, stopColor: "#38CEB9", stopOpacity: 0.42 }),
              /* @__PURE__ */ o("stop", { offset: 0.65, stopColor: "#36C8B4", stopOpacity: 0.12 }),
              /* @__PURE__ */ o("stop", { offset: 0.76, stopColor: "#35C6B2", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__i",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(35.272 20024.109 -27528.465) scale(1334.03 936.155)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#3CD9C3" }),
              /* @__PURE__ */ o("stop", { offset: 0.07, stopColor: "#3BD7C1", stopOpacity: 0.9 }),
              /* @__PURE__ */ o("stop", { offset: 0.41, stopColor: "#38CEB9", stopOpacity: 0.42 }),
              /* @__PURE__ */ o("stop", { offset: 0.65, stopColor: "#36C8B4", stopOpacity: 0.12 }),
              /* @__PURE__ */ o("stop", { offset: 0.76, stopColor: "#35C6B2", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__j",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(100.969 2.804 12.552) scale(6.62174 8.18314)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#237F66", stopOpacity: 0.8 }),
              /* @__PURE__ */ o("stop", { offset: 0.06, stopColor: "#2C8C70", stopOpacity: 0.65 }),
              /* @__PURE__ */ o("stop", { offset: 0.21, stopColor: "#3DA785", stopOpacity: 0.37 }),
              /* @__PURE__ */ o("stop", { offset: 0.35, stopColor: "#4ABA93", stopOpacity: 0.17 }),
              /* @__PURE__ */ o("stop", { offset: 0.47, stopColor: "#51C59C", stopOpacity: 0.04 }),
              /* @__PURE__ */ o("stop", { offset: 0.56, stopColor: "#54C99F", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__k",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "matrix(5.46774 -6.70801 7.24448 5.90502 12.132 12.607)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#167C8A" }),
              /* @__PURE__ */ o("stop", { offset: 0.04, stopColor: "#167C8A", stopOpacity: 0.93 }),
              /* @__PURE__ */ o("stop", { offset: 0.33, stopColor: "#177F8D", stopOpacity: 0.43 }),
              /* @__PURE__ */ o("stop", { offset: 0.54, stopColor: "#17818F", stopOpacity: 0.12 }),
              /* @__PURE__ */ o("stop", { offset: 0.65, stopColor: "#178290", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__l",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(-31.665 29.571 -14.01) scale(8.44171 6.67052)",
            children: [
              /* @__PURE__ */ o("stop", { offset: 0.05, stopColor: "#177D87" }),
              /* @__PURE__ */ o("stop", { offset: 0.11, stopColor: "#177E89", stopOpacity: 0.8 }),
              /* @__PURE__ */ o("stop", { offset: 0.21, stopColor: "#177F8B", stopOpacity: 0.52 }),
              /* @__PURE__ */ o("stop", { offset: 0.32, stopColor: "#17818D", stopOpacity: 0.29 }),
              /* @__PURE__ */ o("stop", { offset: 0.42, stopColor: "#17818F", stopOpacity: 0.13 }),
              /* @__PURE__ */ o("stop", { offset: 0.51, stopColor: "#178290", stopOpacity: 0.03 }),
              /* @__PURE__ */ o("stop", { offset: 0.6, stopColor: "#178290", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__m",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(-130.544 8.077 4.334) scale(5.03066 3.9598)",
            children: [
              /* @__PURE__ */ o("stop", { offset: 0.26, stopColor: "#177F8A" }),
              /* @__PURE__ */ o("stop", { offset: 0.27, stopColor: "#177F8A", stopOpacity: 0.98 }),
              /* @__PURE__ */ o("stop", { offset: 0.38, stopColor: "#17808C", stopOpacity: 0.68 }),
              /* @__PURE__ */ o("stop", { offset: 0.5, stopColor: "#17818D", stopOpacity: 0.43 }),
              /* @__PURE__ */ o("stop", { offset: 0.62, stopColor: "#17818F", stopOpacity: 0.24 }),
              /* @__PURE__ */ o("stop", { offset: 0.74, stopColor: "#17828F", stopOpacity: 0.11 }),
              /* @__PURE__ */ o("stop", { offset: 0.86, stopColor: "#178290", stopOpacity: 0.03 }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#178290", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__n",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(-173.113 5.264 7.714) scale(4.7188)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#13656E" }),
              /* @__PURE__ */ o("stop", { offset: 0.11, stopColor: "#13646E", stopOpacity: 0.73 }),
              /* @__PURE__ */ o("stop", { offset: 0.27, stopColor: "#12646E", stopOpacity: 0.41 }),
              /* @__PURE__ */ o("stop", { offset: 0.42, stopColor: "#12636E", stopOpacity: 0.18 }),
              /* @__PURE__ */ o("stop", { offset: 0.55, stopColor: "#12636E", stopOpacity: 0.05 }),
              /* @__PURE__ */ o("stop", { offset: 0.66, stopColor: "#12636E", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__o",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(-119.327 8.67 5.503) scale(3.298 2.77363)",
            children: [
              /* @__PURE__ */ o("stop", { offset: 0.13, stopColor: "#146A73" }),
              /* @__PURE__ */ o("stop", { offset: 0.24, stopColor: "#156F79", stopOpacity: 0.79 }),
              /* @__PURE__ */ o("stop", { offset: 0.45, stopColor: "#167783", stopOpacity: 0.45 }),
              /* @__PURE__ */ o("stop", { offset: 0.65, stopColor: "#167D8A", stopOpacity: 0.2 }),
              /* @__PURE__ */ o("stop", { offset: 0.82, stopColor: "#17818E", stopOpacity: 0.05 }),
              /* @__PURE__ */ o("stop", { offset: 0.95, stopColor: "#178290", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__p",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(-55.973 17.094 .186) scale(3.10418 2.45998)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#198E9C" }),
              /* @__PURE__ */ o("stop", { offset: 0.1, stopColor: "#198E9C", stopOpacity: 0.87 }),
              /* @__PURE__ */ o("stop", { offset: 0.41, stopColor: "#198E9C", stopOpacity: 0.5 }),
              /* @__PURE__ */ o("stop", { offset: 0.67, stopColor: "#198E9C", stopOpacity: 0.23 }),
              /* @__PURE__ */ o("stop", { offset: 0.88, stopColor: "#198E9C", stopOpacity: 0.06 }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#198E9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__w",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "matrix(-1.56597 10.25452 -6.76717 -1.03342 17.105 11.26)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1A3D9C" }),
              /* @__PURE__ */ o("stop", { offset: 0.4, stopColor: "#1A3D9C", stopOpacity: 0.42 }),
              /* @__PURE__ */ o("stop", { offset: 0.7, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__x",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(98.69 10088.409 -5404.714) scale(1276.06 688.043)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1A3D9C" }),
              /* @__PURE__ */ o("stop", { offset: 0.4, stopColor: "#1A3D9C", stopOpacity: 0.42 }),
              /* @__PURE__ */ o("stop", { offset: 0.7, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__y",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "matrix(-227.66097 1502.57092 -620.74929 -94.05239 5497.61 -16428.2)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1A3D9C" }),
              /* @__PURE__ */ o("stop", { offset: 0.4, stopColor: "#1A3D9C", stopOpacity: 0.42 }),
              /* @__PURE__ */ o("stop", { offset: 0.7, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__z",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "matrix(1.09223 9.00104 -6.28008 .76205 15.962 11.575)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1A3D9C" }),
              /* @__PURE__ */ o("stop", { offset: 0.16, stopColor: "#1A3D9C", stopOpacity: 0.69 }),
              /* @__PURE__ */ o("stop", { offset: 0.33, stopColor: "#1A3D9C", stopOpacity: 0.4 }),
              /* @__PURE__ */ o("stop", { offset: 0.49, stopColor: "#1A3D9C", stopOpacity: 0.18 }),
              /* @__PURE__ */ o("stop", { offset: 0.61, stopColor: "#1A3D9C", stopOpacity: 0.05 }),
              /* @__PURE__ */ o("stop", { offset: 0.7, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__A",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "matrix(2.30949 8.4023 -5.7141 1.5706 17.785 12.74)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#18388F" }),
              /* @__PURE__ */ o("stop", { offset: 0.37, stopColor: "#193B96", stopOpacity: 0.45 }),
              /* @__PURE__ */ o("stop", { offset: 0.7, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__B",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(74.643 -1.508 18.616) scale(4.04966 3.84855)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#18388F" }),
              /* @__PURE__ */ o("stop", { offset: 0.09, stopColor: "#183992", stopOpacity: 0.78 }),
              /* @__PURE__ */ o("stop", { offset: 0.22, stopColor: "#193B96", stopOpacity: 0.5 }),
              /* @__PURE__ */ o("stop", { offset: 0.35, stopColor: "#193C98", stopOpacity: 0.28 }),
              /* @__PURE__ */ o("stop", { offset: 0.47, stopColor: "#1A3C9A", stopOpacity: 0.13 }),
              /* @__PURE__ */ o("stop", { offset: 0.59, stopColor: "#1A3D9C", stopOpacity: 0.03 }),
              /* @__PURE__ */ o("stop", { offset: 0.7, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__C",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(74.643 -1.89 19.008) scale(4.04966 3.84855)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1A3D9C", stopOpacity: 0.8 }),
              /* @__PURE__ */ o("stop", { offset: 0.01, stopColor: "#1A3D9C", stopOpacity: 0.78 }),
              /* @__PURE__ */ o("stop", { offset: 0.24, stopColor: "#1A3D9C", stopOpacity: 0.45 }),
              /* @__PURE__ */ o("stop", { offset: 0.44, stopColor: "#1A3D9C", stopOpacity: 0.2 }),
              /* @__PURE__ */ o("stop", { offset: 0.6, stopColor: "#1A3D9C", stopOpacity: 0.05 }),
              /* @__PURE__ */ o("stop", { offset: 0.7, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__D",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "matrix(3.77347 8.90234 -5.90825 2.50435 7.317 13.258)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1A3D9C" }),
              /* @__PURE__ */ o("stop", { offset: 0.4, stopColor: "#1A3D9C", stopOpacity: 0.56 }),
              /* @__PURE__ */ o("stop", { offset: 0.48, stopColor: "#1A3D9C", stopOpacity: 0.43 }),
              /* @__PURE__ */ o("stop", { offset: 0.69, stopColor: "#1A3D9C", stopOpacity: 0.12 }),
              /* @__PURE__ */ o("stop", { offset: 0.79, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__E",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(76.091 -3.855 9.36) scale(7.17161 4.73479)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1A3D9C" }),
              /* @__PURE__ */ o("stop", { offset: 0.06, stopColor: "#1A3D9C", stopOpacity: 0.88 }),
              /* @__PURE__ */ o("stop", { offset: 0.21, stopColor: "#1A3D9C", stopOpacity: 0.61 }),
              /* @__PURE__ */ o("stop", { offset: 0.36, stopColor: "#1A3D9C", stopOpacity: 0.39 }),
              /* @__PURE__ */ o("stop", { offset: 0.51, stopColor: "#1A3D9C", stopOpacity: 0.22 }),
              /* @__PURE__ */ o("stop", { offset: 0.67, stopColor: "#1A3D9C", stopOpacity: 0.1 }),
              /* @__PURE__ */ o("stop", { offset: 0.83, stopColor: "#1A3D9C", stopOpacity: 0.02 }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__F",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(76.091 -3.855 9.36) scale(7.17161 4.73479)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#1B3E9E" }),
              /* @__PURE__ */ o("stop", { offset: 0.06, stopColor: "#1B3E9E", stopOpacity: 0.87 }),
              /* @__PURE__ */ o("stop", { offset: 0.21, stopColor: "#1B3E9D", stopOpacity: 0.61 }),
              /* @__PURE__ */ o("stop", { offset: 0.36, stopColor: "#1A3D9D", stopOpacity: 0.39 }),
              /* @__PURE__ */ o("stop", { offset: 0.52, stopColor: "#1A3D9C", stopOpacity: 0.22 }),
              /* @__PURE__ */ o("stop", { offset: 0.67, stopColor: "#1A3D9C", stopOpacity: 0.1 }),
              /* @__PURE__ */ o("stop", { offset: 0.83, stopColor: "#1A3D9C", stopOpacity: 0.02 }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__G",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(76.089 -3.484 9.53) scale(5.48722 3.62251)",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#183991" }),
              /* @__PURE__ */ o("stop", { offset: 0.03, stopColor: "#183992", stopOpacity: 0.95 }),
              /* @__PURE__ */ o("stop", { offset: 0.18, stopColor: "#193A95", stopOpacity: 0.66 }),
              /* @__PURE__ */ o("stop", { offset: 0.34, stopColor: "#193B97", stopOpacity: 0.42 }),
              /* @__PURE__ */ o("stop", { offset: 0.5, stopColor: "#1A3C99", stopOpacity: 0.24 }),
              /* @__PURE__ */ o("stop", { offset: 0.66, stopColor: "#1A3D9B", stopOpacity: 0.1 }),
              /* @__PURE__ */ o("stop", { offset: 0.82, stopColor: "#1A3D9C", stopOpacity: 0.03 }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#1A3D9C", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "radialGradient",
          {
            id: "webex_svg__H",
            cx: 0,
            cy: 0,
            r: 1,
            gradientUnits: "userSpaceOnUse",
            gradientTransform: "rotate(87.129 -1.844 9.621) scale(4.33489 3.81771)",
            children: [
              /* @__PURE__ */ o("stop", { offset: 0.04, stopColor: "#2453D6", stopOpacity: 0.6 }),
              /* @__PURE__ */ o("stop", { offset: 0.2, stopColor: "#2453D6", stopOpacity: 0.49 }),
              /* @__PURE__ */ o("stop", { offset: 0.52, stopColor: "#2453D6", stopOpacity: 0.2 }),
              /* @__PURE__ */ o("stop", { offset: 0.73, stopColor: "#2453D6", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "linearGradient",
          {
            id: "webex_svg__a",
            x1: 1.063,
            y1: 15.159,
            x2: 18.982,
            y2: 7.916,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { offset: 0.13, stopColor: "#1CBBF3" }),
              /* @__PURE__ */ o("stop", { offset: 0.34, stopColor: "#1F99A5" }),
              /* @__PURE__ */ o("stop", { offset: 0.45, stopColor: "#209CA6" }),
              /* @__PURE__ */ o("stop", { offset: 0.51, stopColor: "#24A4AA" }),
              /* @__PURE__ */ o("stop", { offset: 0.57, stopColor: "#2BB3B0" }),
              /* @__PURE__ */ o("stop", { offset: 0.6, stopColor: "#30BDB4" }),
              /* @__PURE__ */ o("stop", { offset: 0.79, stopColor: "#38BA90" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#60F05A" })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "linearGradient",
          {
            id: "webex_svg__q",
            x1: 2.215,
            y1: 3.634,
            x2: 10.121,
            y2: 10.951,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { offset: 0.13, stopColor: "#2BF9FF" }),
              /* @__PURE__ */ o("stop", { offset: 0.27, stopColor: "#29F9FF", stopOpacity: 0.64 }),
              /* @__PURE__ */ o("stop", { offset: 0.4, stopColor: "#27FAFF", stopOpacity: 0.3 }),
              /* @__PURE__ */ o("stop", { offset: 0.49, stopColor: "#26FAFF", stopOpacity: 0.08 }),
              /* @__PURE__ */ o("stop", { offset: 0.54, stopColor: "#26FAFF", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "linearGradient",
          {
            id: "webex_svg__r",
            x1: -2.264,
            y1: 15.578,
            x2: 6.969,
            y2: 12.962,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#26FAFF" }),
              /* @__PURE__ */ o("stop", { offset: 0.06, stopColor: "#26FAFF", stopOpacity: 0.88 }),
              /* @__PURE__ */ o("stop", { offset: 0.24, stopColor: "#26FAFF", stopOpacity: 0.51 }),
              /* @__PURE__ */ o("stop", { offset: 0.39, stopColor: "#26FAFF", stopOpacity: 0.23 }),
              /* @__PURE__ */ o("stop", { offset: 0.5, stopColor: "#26FAFF", stopOpacity: 0.06 }),
              /* @__PURE__ */ o("stop", { offset: 0.55, stopColor: "#26FAFF", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "linearGradient",
          {
            id: "webex_svg__s",
            x1: 0.182,
            y1: 15.265,
            x2: 10.588,
            y2: 11.55,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#21DEFF" }),
              /* @__PURE__ */ o("stop", { offset: 0.04, stopColor: "#21DEFF", stopOpacity: 0.88 }),
              /* @__PURE__ */ o("stop", { offset: 0.17, stopColor: "#21DEFF", stopOpacity: 0.51 }),
              /* @__PURE__ */ o("stop", { offset: 0.27, stopColor: "#21DEFF", stopOpacity: 0.23 }),
              /* @__PURE__ */ o("stop", { offset: 0.34, stopColor: "#21DEFF", stopOpacity: 0.06 }),
              /* @__PURE__ */ o("stop", { offset: 0.38, stopColor: "#21DEFF", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "linearGradient",
          {
            id: "webex_svg__t",
            x1: 10.084,
            y1: 9.879,
            x2: 15.905,
            y2: 19.372,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { offset: 0.62, stopColor: "#21DEFF", stopOpacity: 0 }),
              /* @__PURE__ */ o("stop", { offset: 0.66, stopColor: "#21DEFF", stopOpacity: 0.06 }),
              /* @__PURE__ */ o("stop", { offset: 0.73, stopColor: "#21DEFF", stopOpacity: 0.23 }),
              /* @__PURE__ */ o("stop", { offset: 0.83, stopColor: "#21DEFF", stopOpacity: 0.51 }),
              /* @__PURE__ */ o("stop", { offset: 0.96, stopColor: "#21DEFF", stopOpacity: 0.88 }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#21DEFF" })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "linearGradient",
          {
            id: "webex_svg__u",
            x1: 16.542,
            y1: 13.439,
            x2: 15.629,
            y2: 8.971,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#44B044" }),
              /* @__PURE__ */ o("stop", { offset: 0.11, stopColor: "#44B044", stopOpacity: 0.8 }),
              /* @__PURE__ */ o("stop", { offset: 0.27, stopColor: "#44B044", stopOpacity: 0.52 }),
              /* @__PURE__ */ o("stop", { offset: 0.43, stopColor: "#44B044", stopOpacity: 0.29 }),
              /* @__PURE__ */ o("stop", { offset: 0.58, stopColor: "#44B044", stopOpacity: 0.13 }),
              /* @__PURE__ */ o("stop", { offset: 0.7, stopColor: "#44B044", stopOpacity: 0.03 }),
              /* @__PURE__ */ o("stop", { offset: 0.8, stopColor: "#44B044", stopOpacity: 0 })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "linearGradient",
          {
            id: "webex_svg__v",
            x1: 3.78,
            y1: 8.786,
            x2: 23.052,
            y2: 15.164,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#3066FB" }),
              /* @__PURE__ */ o("stop", { offset: 0.38, stopColor: "#2F64F7" }),
              /* @__PURE__ */ o("stop", { offset: 0.74, stopColor: "#2857D9" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#3C7EFF" })
            ]
          }
        ),
        /* @__PURE__ */ t(
          "linearGradient",
          {
            id: "webex_svg__I",
            x1: 16.308,
            y1: 0.943,
            x2: 11.437,
            y2: 12.973,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#3774FF" }),
              /* @__PURE__ */ o("stop", { offset: 0.05, stopColor: "#3774FF", stopOpacity: 0.91 }),
              /* @__PURE__ */ o("stop", { offset: 0.16, stopColor: "#3774FF", stopOpacity: 0.66 }),
              /* @__PURE__ */ o("stop", { offset: 0.32, stopColor: "#3774FF", stopOpacity: 0.28 }),
              /* @__PURE__ */ o("stop", { offset: 0.43, stopColor: "#3774FF", stopOpacity: 0 })
            ]
          }
        )
      ] })
    ]
  }
);
export {
  p as default
};
