import * as React from "react";
const SvgIconUnsplash = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M8.625 8.063V3h6.75v5.063h-6.75Zm6.75 2.812H21V21H3V10.875h5.625v5.063h6.75v-5.063Z"
      fill="#000"
    />
  </svg>
);
export default SvgIconUnsplash;
