import { jsxs as h, jsx as a } from "react/jsx-runtime";
const i = (l) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          d: "M8.94 7.68a.286.286 0 0 1 .005-.396l5.864-5.872a.574.574 0 0 1 .826.016 15.552 15.552 0 0 1 3.612 6.562L15.697 12l-5.277-1.653A6.365 6.365 0 0 0 8.94 7.68Z",
          fill: "#95A0B1"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M22.056 7.849a.57.57 0 0 0-.705-.42l-10.932 2.92a6.449 6.449 0 0 1 .002 3.323l3.646 4.542 5.19-2.167 2.091.562a.572.572 0 0 0 .706-.42 18.47 18.47 0 0 0 .002-8.34Z",
          fill: "#596578"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M10.42 13.672a6.367 6.367 0 0 1-1.463 2.664.287.287 0 0 0 .004.4l5.86 5.853a.574.574 0 0 0 .826-.016 15.502 15.502 0 0 0 3.61-6.526l-8.836-2.375Z",
          fill: "#38404C"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.1,
          d: "M13.375 17.958V6.73a.92.92 0 0 0-.917-.917H10.4L8.945 7.27a.286.286 0 0 0-.004.396 6.365 6.365 0 0 1 1.479 2.668h.002-.003a6.45 6.45 0 0 1-.283 4.155 6.379 6.379 0 0 1-1.18 1.833.287.287 0 0 0 .005.401l2.156 2.153h1.341a.92.92 0 0 0 .917-.917Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.2,
          d: "M12.688 18.646V7.416a.92.92 0 0 0-.917-.916H9.714l-.769.77a.286.286 0 0 0-.004.396 6.365 6.365 0 0 1 1.479 2.667h.002-.003a6.45 6.45 0 0 1-.283 4.155 6.379 6.379 0 0 1-1.18 1.833.287.287 0 0 0 .005.401l2.844 2.84a.92.92 0 0 0 .883-.916Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.2,
          d: "M12.688 17.27V7.418a.92.92 0 0 0-.917-.917H9.714l-.769.77a.286.286 0 0 0-.004.396 6.365 6.365 0 0 1 1.479 2.667h.002-.003a6.45 6.45 0 0 1-.283 4.155 6.379 6.379 0 0 1-1.18 1.833.287.287 0 0 0 .005.401l1.467 1.465h1.343a.92.92 0 0 0 .917-.916Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.2,
          d: "M12 17.27V7.418a.92.92 0 0 0-.917-.917h-1.37l-.768.77a.286.286 0 0 0-.004.396 6.365 6.365 0 0 1 1.479 2.667h.002-.003a6.45 6.45 0 0 1-.283 4.155 6.379 6.379 0 0 1-1.18 1.833.287.287 0 0 0 .005.401l1.467 1.465h.655a.92.92 0 0 0 .917-.916Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M1.917 6.5h9.166a.917.917 0 0 1 .917.917v9.166a.917.917 0 0 1-.917.917H1.917A.917.917 0 0 1 1 16.583V7.417a.917.917 0 0 1 .917-.917Z",
          fill: "#596578"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M6.38 11.497c.064.132.116.27.154.412h.014c.035-.14.084-.274.149-.403l1.39-2.619a.611.611 0 1 1 1.07.591l-1.924 3.349a.611.611 0 0 0-.081.305v1.694a.611.611 0 0 1-.611.611h-.125a.612.612 0 0 1-.611-.61v-1.675a.611.611 0 0 0-.078-.298L3.84 9.472a.611.611 0 0 1 .534-.91h.12a.611.611 0 0 1 .543.331l1.344 2.604Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  i as default
};
