export const mobileSmallLower = 0;
export const mobileSmallUpper = 359;

export const mobileLower = 360;
export const mobileUpper = 416;

export const tabletLower = 417;
export const tabletUpper = 1024;

export const laptopSmallLower = 1025;
export const laptopSmallUpper = 1280;

export const laptopLower = 1281;
export const laptopUpper = 1440;

export const desktopLower = 1441;
export const desktopUpper = 1920;
