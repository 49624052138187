import { jsxs as h, jsx as a } from "react/jsx-runtime";
const d = (e) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ a("path", { d: "M3 16a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1ZM3 20a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z" }),
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3 12V4c0-.283.096-.521.288-.713A.966.966 0 0 1 4 3h16c.283 0 .52.096.712.287.192.192.288.43.288.713v8a.967.967 0 0 1-.288.713.954.954 0 0 1-.361.228A1.02 1.02 0 0 1 20 13H4a.967.967 0 0 1-.712-.287A.967.967 0 0 1 3 12Zm16-7H5v6h14V5Z"
        }
      )
    ]
  }
);
export {
  d as default
};
