import { jsx as c } from "react/jsx-runtime";
const A = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M19 22H5c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 20V8c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 5 6h2c0-1.383.488-2.563 1.463-3.538C9.438 1.487 10.617 1 12 1s2.563.487 3.538 1.462C16.513 3.437 17 4.617 17 6h2c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 22ZM9 6h6c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 12 3c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 9 6Zm3 8c1.233 0 2.35-.408 3.35-1.225 1-.817 1.492-1.733 1.475-2.75 0-.283-.092-.525-.275-.725a.908.908 0 0 0-.7-.3.96.96 0 0 0-.625.225c-.183.15-.317.375-.4.675a2.779 2.779 0 0 1-1.075 1.525A2.935 2.935 0 0 1 12 12c-.633 0-1.22-.192-1.762-.575A2.64 2.64 0 0 1 9.175 9.9c-.083-.317-.208-.546-.375-.688A.897.897 0 0 0 8.2 9c-.283 0-.52.1-.712.3-.192.2-.288.442-.288.725 0 1.017.492 1.933 1.475 2.75C9.658 13.592 10.767 14 12 14Z" })
  }
);
export {
  A as default
};
