/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Thinkific = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#010046" d="M2 2h20v20H2z" />
    <path
      d="M8 9a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM11 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      fill="#fff"
    />
  </svg>
);

export default Thinkific;
