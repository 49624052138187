import { jsx as A } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M4 17a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 16V8c0-.283.096-.521.288-.713A.967.967 0 0 1 4 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 5 8v3h4V8c0-.283.096-.521.288-.713A.967.967 0 0 1 10 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 11 8v8c0 .283-.096.52-.287.712A.968.968 0 0 1 10 17a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 16v-3H5v3c0 .283-.096.52-.287.712A.968.968 0 0 1 4 17Zm15 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 16v-2h-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 13V8c0-.283.096-.521.288-.713A.967.967 0 0 1 14 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 15 8v4h3V8c0-.283.096-.521.288-.713A.967.967 0 0 1 19 7c.283 0 .52.096.712.287.192.192.288.43.288.713v4h1c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 21 14h-1v2c0 .283-.096.52-.288.712A.965.965 0 0 1 19 17Z" })
  }
);
export {
  h as default
};
