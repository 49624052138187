import { jsx as A } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M5 19a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 18c0-.283.096-.52.287-.712A.968.968 0 0 1 5 17h8c.283 0 .521.096.713.288A.967.967 0 0 1 14 18c0 .283-.096.52-.287.712A.968.968 0 0 1 13 19H5Zm0-8a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 10a.97.97 0 0 1 .287-.713A.97.97 0 0 1 5 9h14c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 19 11H5Zm0 4a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 14a.97.97 0 0 1 .287-.713A.97.97 0 0 1 5 13h14c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 19 15H5Zm0-8a.97.97 0 0 1-.713-.287A.97.97 0 0 1 4 6a.97.97 0 0 1 .287-.713A.97.97 0 0 1 5 5h14c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 19 7H5Z" })
  }
);
export {
  t as default
};
