import * as React from "react";
const SvgIconUser = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M8.754 11.132c-.308-.004-.475-.194-.538-.452a27.061 27.061 0 0 1-.4-1.86c-.04-.235.01-.497.324-.55a6.725 6.725 0 0 1-.3-2.645c.134-1.653 1.014-2.748 2.516-3.306 1.39-.515 2.763-.415 4.045.364 1.192.725 1.779 1.844 1.786 3.235.005.77-.14 1.541-.222 2.357.232.062.26.339.21.61-.112.602-.248 1.2-.392 1.795-.064.26-.22.454-.515.45-.053.397-.094.779-.157 1.157-.076.45-.249.85-.563 1.198-.136.151-.222.399-.233.608-.018.38.036.766.06 1.149.025.377.225.611.579.7.815.202 1.63.408 2.45.59 2.074.463 3.528 2.178 3.595 4.262.002.063 0 .127 0 .206H3c.029-1.303.446-2.413 1.38-3.302.783-.744 1.76-1.03 2.776-1.234.57-.115 1.136-.255 1.7-.399.54-.138.81-.504.813-1.074.001-.153-.003-.309.018-.46.06-.432-.012-.792-.319-1.144-.492-.563-.49-1.315-.592-2.018-.01-.065-.012-.13-.022-.237Z" />
  </svg>
);
export default SvgIconUser;
