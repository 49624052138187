import * as React from "react";
const SvgIconSms = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H6l-4 4V4c0-1.1.9-2 2-2Zm3 9h2V9H7v2Zm6 0h-2V9h2v2Zm2 0h2V9h-2v2Z"
    />
  </svg>
);
export default SvgIconSms;
