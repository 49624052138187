import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M16.35 7.35a4.35 4.35 0 1 1-8.7 0 4.35 4.35 0 0 1 8.7 0Zm-10 5.437a4.35 4.35 0 1 0 0 8.7 4.35 4.35 0 0 0 0-8.7Zm11.3 0a4.35 4.35 0 1 0 0 8.7 4.35 4.35 0 0 0 0-8.7Z" })
  }
);
export {
  h as default
};
