import { jsx as h } from "react/jsx-runtime";
const A = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M23 18.5c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 21.5 20h-3c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 17 18.5v-1h-2v1c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 13.5 20h-3c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 9 18.5v-1H7v1c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 5.5 20h-3c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 1 18.5v-4c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 2.5 13h3c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v1h2v-1c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 10.5 13h.5v-3h-.5c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 9 8.5v-4c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 10.5 3h3c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v4c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 13.5 10H13v3h.5c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v1h2v-1c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 18.5 13h3c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v4Zm-2-.5v-3h-2v3h2Zm-8 0v-3h-2v3h2Zm0-10V5h-2v3h2ZM5 18v-3H3v3h2Z" })
  }
);
export {
  A as default
};
