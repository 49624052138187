/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleHangouts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 1c5.246 0 9.507 4.254 9.507 9.5 0 4.861-3.735 9.573-9.507 12.5v-3.5c-4.97 0-9.5-4.03-9.5-9A9.5 9.5 0 0 1 11.5 1ZM6.75 8v4H9v2.25c.98 0 1.75-1.25 1.75-2.5V8h-4Zm5.5 0v4h2.25v2.25c.981 0 1.75-1.25 1.75-2.5V8h-4Z"
    />
  </svg>
);

export default GoogleHangouts;
