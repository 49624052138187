import { jsx as c } from "react/jsx-runtime";
const e = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.613-1.958-2.138-3.175A9.738 9.738 0 0 1 2 12c0-1.017.142-1.988.425-2.912A9.828 9.828 0 0 1 3.65 6.5L2.075 4.925a.93.93 0 0 1-.288-.712A1.02 1.02 0 0 1 2.1 3.5c.2-.2.437-.3.712-.3.275 0 .513.1.713.3l16.975 17c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.438.3-.712.3a.973.973 0 0 1-.713-.3L17.5 20.35c-.8.533-1.662.942-2.588 1.225A9.89 9.89 0 0 1 12 22Zm-1.7-2.2c.433-.85.95-1.53 1.55-2.038a7.608 7.608 0 0 1 1.875-1.187L5.1 7.95c-.35.6-.62 1.237-.813 1.913A7.79 7.79 0 0 0 4 12c0 .75.096 1.467.287 2.15.192.683.48 1.317.863 1.9a10.82 10.82 0 0 1 2.125-.775A9.142 9.142 0 0 1 9.5 15c.533 0 1.046.046 1.537.137.492.092.98.213 1.463.363-.383.2-.746.433-1.088.7-.341.267-.662.55-.962.85-.2-.033-.37-.05-.513-.05H9.5c-.533 0-1.063.058-1.588.175A6.924 6.924 0 0 0 6.4 17.7c.533.533 1.13.98 1.787 1.338a7.448 7.448 0 0 0 2.113.762Zm10.05-2.3-1.45-1.45c.35-.583.62-1.217.813-1.9.191-.683.287-1.4.287-2.15 0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4a7.79 7.79 0 0 0-2.138.287A8.255 8.255 0 0 0 7.95 5.1L6.5 3.65a9.829 9.829 0 0 1 2.587-1.225A9.89 9.89 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.89 9.89 0 0 1-.425 2.912A9.829 9.829 0 0 1 20.35 17.5ZM13 10.15 9.35 6.5c1.033-.033 1.908.313 2.625 1.037.717.725 1.058 1.596 1.025 2.613ZM9.5 13.5a3.372 3.372 0 0 1-2.475-1.025A3.372 3.372 0 0 1 6 10c0-.55.12-1.054.362-1.512A4.08 4.08 0 0 1 7.3 7.3l4.9 4.9c-.333.383-.73.696-1.188.938A3.192 3.192 0 0 1 9.5 13.5Zm7.75.9-3.15-3.15c.167-.517.467-.938.9-1.262a2.432 2.432 0 0 1 1.5-.488c.7 0 1.292.242 1.775.725.483.483.725 1.075.725 1.775 0 .567-.163 1.067-.488 1.5a2.502 2.502 0 0 1-1.262.9Z" })
  }
);
export {
  e as default
};
