import { jsx as c } from "react/jsx-runtime";
const l = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M8.65 20H6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 18v-2.65l-1.9-1.925c-.4-.383-.6-.858-.6-1.425 0-.567.2-1.042.6-1.425L4 8.65V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 6 4h2.65l1.925-1.9c.383-.4.858-.6 1.425-.6.567 0 1.042.2 1.425.6L15.35 4H18c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v2.65l1.9 1.925c.4.383.6.858.6 1.425 0 .567-.2 1.042-.6 1.425L20 15.35V18c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 20h-2.65l-1.925 1.9a1.84 1.84 0 0 1-1.412.588 2.054 2.054 0 0 1-1.438-.588L8.65 20Zm3.35.5 2.5-2.5H18v-3.5l2.5-2.5L18 9.5V6h-3.5L12 3.5 9.5 6H6v3.5L3.5 12 6 14.5V18h3.5l2.5 2.5Zm.225-3.5c1.417 0 2.617-.488 3.6-1.463.983-.975 1.475-2.154 1.475-3.537s-.492-2.558-1.475-3.525c-.983-.967-2.183-1.45-3.6-1.45-.217 0-.413.012-.588.037a6.65 6.65 0 0 0-.487.088.485.485 0 0 0-.237.162.381.381 0 0 0-.088.238c0 .033.075.167.225.4.683.45 1.2 1.037 1.55 1.762A5.2 5.2 0 0 1 13.125 12c0 .85-.183 1.633-.55 2.35a4.423 4.423 0 0 1-1.55 1.725c-.05.033-.125.158-.225.375 0 .1.033.187.1.262.067.075.15.13.25.163a4.57 4.57 0 0 0 1.075.125Z" })
  }
);
export {
  l as default
};
