import * as e from "react";
const t = (c) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...c
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12 2.21V4c4.42 0 8 3.58 8 8 0 1.04-.2 2.04-.57 2.95-.27.67-1.13.85-1.64.34-.27-.27-.38-.68-.23-1.04.29-.69.44-1.46.44-2.25 0-3.31-2.69-6-6-6v1.79c0 .45-.54.67-.86.36L8.35 5.36c-.2-.2-.2-.51 0-.71l2.8-2.79c.31-.32.85-.1.85.35ZM6 12c0 3.31 2.69 6 6 6v-1.79a.5.5 0 0 1 .85-.36l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.31.32-.85.1-.85-.35V20c-4.42 0-8-3.58-8-8 0-1.04.2-2.04.57-2.95.27-.67 1.13-.85 1.64-.34.27.27.38.68.23 1.04C6.15 10.44 6 11.21 6 12Z"
    }
  )
);
export {
  t as default
};
