import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "m18 19.425-.9.9a.933.933 0 0 1-.688.275.973.973 0 0 1-.712-.3.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7l.875-.9-.875-.875a.933.933 0 0 1-.275-.687c0-.275.092-.513.275-.713.2-.2.438-.3.713-.3.274 0 .512.1.712.3L18 16.6l.875-.9c.183-.2.413-.3.688-.3.274 0 .512.1.712.3.2.2.3.438.3.713 0 .275-.1.512-.3.712L19.4 18l.9.9c.2.2.296.434.287.7-.008.267-.112.5-.312.7-.2.184-.433.28-.7.288a.916.916 0 0 1-.7-.288L18 19.425ZM6 19c.283 0 .52-.095.713-.287A.968.968 0 0 0 7 18a.968.968 0 0 0-.287-.712A.967.967 0 0 0 6 17a.967.967 0 0 0-.713.288A.968.968 0 0 0 5 18c0 .284.096.521.287.713.192.192.43.287.713.287Zm0 2a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 3 18c0-.833.292-1.541.875-2.125A2.893 2.893 0 0 1 6 15c.617 0 1.18.171 1.688.513.508.342.879.804 1.112 1.387a2.96 2.96 0 0 0 1.587-1.075A2.922 2.922 0 0 0 11 14v-4c0-1.383.488-2.562 1.463-3.537C13.438 5.488 14.617 5 16 5h1.15l-.875-.875a.93.93 0 0 1-.287-.712A1.02 1.02 0 0 1 16.3 2.7c.2-.2.438-.3.712-.3.276 0 .513.1.713.3L20.3 5.3c.2.2.3.434.3.7 0 .267-.1.5-.3.7l-2.6 2.6a.977.977 0 0 1-.688.288.93.93 0 0 1-.712-.288.908.908 0 0 1-.3-.7c0-.283.092-.525.275-.725L17.15 7H16c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 13 10v4a4.823 4.823 0 0 1-1.175 3.213 4.934 4.934 0 0 1-2.975 1.712 2.935 2.935 0 0 1-1.087 1.5A2.917 2.917 0 0 1 6 21ZM6 7.425l-.9.9a.933.933 0 0 1-.688.275.973.973 0 0 1-.712-.3.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7l.875-.9-.875-.875a.933.933 0 0 1-.275-.687c0-.275.092-.513.275-.713.2-.2.438-.3.712-.3.276 0 .513.1.713.3L6 4.6l.875-.9c.183-.2.412-.3.688-.3.275 0 .512.1.712.3.2.2.3.438.3.713 0 .275-.1.512-.3.712L7.4 6l.9.9c.2.2.296.434.287.7-.008.267-.112.5-.312.7-.2.184-.433.28-.7.288a.916.916 0 0 1-.7-.288L6 7.425Z" })
  }
);
export {
  t as default
};
