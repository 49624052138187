import * as React from "react";
const SvgIconMsWord = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="Icon-ms-word_svg__a"
        x1={5.822}
        y1={11.568}
        x2={20.178}
        y2={36.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#2368c4" />
        <stop offset={0.5} stopColor="#1a5dbe" />
        <stop offset={1} stopColor="#1146ac" />
      </linearGradient>
    </defs>
    <path
      d="M43.167 4H13.833A1.833 1.833 0 0 0 12 5.833V14l17 5 16-5V5.833A1.833 1.833 0 0 0 43.167 4z"
      fill="#41a5ee"
    />
    <path fill="#2b7cd3" d="M45 14H12v10l17 3 16-3V14z" />
    <path fill="#185abd" d="M12 24v10l16 2 17-2V24H12z" />
    <path
      d="M13.833 44h29.334A1.833 1.833 0 0 0 45 42.167V34H12v8.167A1.833 1.833 0 0 0 13.833 44z"
      fill="#103f91"
    />
    <path fill="none" d="M0 0h48v48H0z" />
    <path
      d="M24.167 12H12v25h12.167A1.839 1.839 0 0 0 26 35.167V13.833A1.839 1.839 0 0 0 24.167 12z"
      opacity={0.1}
    />
    <path
      d="M23.167 13H12v25h11.167A1.839 1.839 0 0 0 25 36.167V14.833A1.839 1.839 0 0 0 23.167 13z"
      opacity={0.2}
    />
    <path
      d="M23.167 13H12v23h11.167A1.839 1.839 0 0 0 25 34.167V14.833A1.839 1.839 0 0 0 23.167 13z"
      opacity={0.2}
    />
    <path
      d="M22.167 13H12v23h10.167A1.839 1.839 0 0 0 24 34.167V14.833A1.839 1.839 0 0 0 22.167 13z"
      opacity={0.2}
    />
    <rect
      x={2}
      y={13}
      width={22}
      height={22}
      rx={1.833}
      fill="url(#Icon-ms-word_svg__a)"
    />
    <path
      d="M9.522 27.058c.036.283.06.529.071.74h.042q.024-.3.1-.723t.137-.715l1.928-8.318h2.493l2 8.193a12.133 12.133 0 0 1 .249 1.546h.033a11.853 11.853 0 0 1 .208-1.5l1.595-8.243h2.269l-2.802 11.92h-2.651l-1.9-7.894c-.055-.227-.118-.524-.187-.889s-.112-.632-.129-.8h-.033q-.033.291-.129.864t-.154.848l-1.786 7.869H8.18L5.363 18.042h2.31l1.737 8.335c.039.171.076.399.112.681z"
      fill="#fff"
    />
  </svg>
);
export default SvgIconMsWord;
