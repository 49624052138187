import { jsx as l } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ l("path", { d: "M21.375 8.625 20 8l1.375-.625L22 6l.625 1.375L24 8l-1.375.625L22 10l-.625-1.375ZM18.05 3.95 16 3l2.05-.95L19 0l.95 2.05L22 3l-2.05.95L19 6l-.95-2.05ZM9 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 7 20h4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 9 22Zm-3-3a.968.968 0 0 1-.713-.288A.967.967 0 0 1 5 18c0-.283.096-.52.287-.712A.968.968 0 0 1 6 17h6c.283 0 .521.096.713.288A.967.967 0 0 1 13 18c0 .283-.096.52-.287.712A.968.968 0 0 1 12 19H6Zm-.75-3a7.658 7.658 0 0 1-2.737-2.75A7.275 7.275 0 0 1 1.5 9.5c0-2.083.73-3.854 2.188-5.312C5.146 2.729 6.917 2 9 2s3.854.73 5.312 2.188C15.771 5.646 16.5 7.417 16.5 9.5c0 1.35-.337 2.6-1.012 3.75A7.666 7.666 0 0 1 12.75 16h-7.5Z" })
  }
);
export {
  c as default
};
