import { jsxs as v, jsx as a } from "react/jsx-runtime";
const s = (h) => /* @__PURE__ */ v(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a("path", { d: "M3 8.551A5.585 5.585 0 0 1 8.605 3h6.965a5.587 5.587 0 0 1 5.606 5.551v6.898A5.587 5.587 0 0 1 15.57 21h-1.323v-2.22h1.323a3.363 3.363 0 0 0 3.366-3.331V8.55A3.363 3.363 0 0 0 15.57 5.22H8.605a3.363 3.363 0 0 0-3.367 3.332v6.898a3.351 3.351 0 0 0 3.367 3.331h1.612L13.33 21H8.605A5.585 5.585 0 0 1 3 15.449V8.55Z" }),
      /* @__PURE__ */ a("path", { d: "M13.078 11.988v-2.5a2.172 2.172 0 0 1 2.193-2.172.51.51 0 1 1 0 1.02 1.165 1.165 0 0 0-1.17 1.152v.326h1.17a.508.508 0 0 1 .51.51v1.664a.508.508 0 0 1-.51.51h-1.678a.513.513 0 0 1-.515-.51ZM8.395 11.988v-2.5a2.18 2.18 0 0 1 2.19-2.172.51.51 0 1 1 0 1.02 1.163 1.163 0 0 0-1.163 1.152v.326h1.163a.511.511 0 0 1 .515.51v1.664a.511.511 0 0 1-.515.51H8.908a.51.51 0 0 1-.513-.51ZM8.802 14.956a.248.248 0 0 1 .209-.126h6.165c.087 0 .168.046.211.122a.25.25 0 0 1-.007.258c-.105.172-1.175 1.904-3.278 1.904-2.167 0-3.254-1.838-3.3-1.915a.247.247 0 0 1 0-.243Z" })
    ]
  }
);
export {
  s as default
};
