/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleMusic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5Z"
      fill="#F16C00"
    />
    <path
      d="M12 6.555v6.65a3.487 3.487 0 0 0-2.776-.249c-1.117.373-1.975 1.299-2.175 2.388-.384 2.13 1.55 3.95 3.825 3.616 1.634-.24 2.792-1.64 2.792-3.189v-7.66h1.667c.917 0 1.667-.7 1.667-1.556C17 5.7 16.25 5 15.333 5h-1.667C12.75 5 12 5.7 12 6.555Z"
      fill="#fff"
    />
  </svg>
);

export default GoogleMusic;
