import { keyframes } from "styled-components";
import { basicDesignTokens } from "../DesignTokens/designTokens";

export const duration = {
  /*
   * These are used for small utility-focused transitions.
   * the default would be fast-md: 200ms"
   */
  fastXs: basicDesignTokens.duration["fast-xs"],
  fastSm: basicDesignTokens.duration["fast-sm"],
  fastMd: basicDesignTokens.duration["fast-md"], // <-- prefer this
  fastLg: basicDesignTokens.duration["fast-lg"],
  fastXl: basicDesignTokens.duration["fast-xl"],
  /*
   * These are used for transitions that traverse a medium area of the screen. like opening a modal or dropDown menu
   * the default would be normal-md: 400ms
   */
  normalXs: basicDesignTokens.duration["normal-xs"],
  normalSm: basicDesignTokens.duration["normal-sm"],
  normalMd: basicDesignTokens.duration["normal-md"], // <-- prefer this
  normalLg: basicDesignTokens.duration["normal-lg"],
  normalXl: basicDesignTokens.duration["normal-xl"],
  /*
   * They're used for large expressive transitions.
   * like transition from a card view to expanded page view from that card or updating content of a chart.
   * the default would be slow-sm: 600ms
   */
  slowXxs: basicDesignTokens.duration["slow-xxs"],
  slowXs: basicDesignTokens.duration["slow-xs"],
  slowSm: basicDesignTokens.duration["slow-sm"], // <-- prefer this
  slowMd: basicDesignTokens.duration["slow-md"],
  slowLg: basicDesignTokens.duration["slow-lg"],
  slowXl: basicDesignTokens.duration["slow-xl"],
  slowXxl: basicDesignTokens.duration["slow-xxl"],
} as const;

export const easing = {
  /** This set is used for simple, small, or utility-focused transitions. */
  easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
  /** Objects enter the screen at full velocity from off-screen and slowly decelerate to a resting point. */
  easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
  /** Objects leave the screen at full velocity. They do not decelerate when off-screen. */
  easeIn: "cubic-bezier(0.4, 0, 1, 1)",
  /** The sharp curve is used by objects that may return to the screen at any time. */
  sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
  linear: "linear",
} as const;

/** @deprecated - instead, use one of: `var(--animation-duration-fast-lg)`, `var(--animation-duration-fast-xl)` */
export const animationFast = "260ms";
/** @deprecated - instead, use `var(--animation-duration-slow-sm)` */
export const animationSlow = "600ms";
/** @deprecated - instead, use `var(--animation-easing-ease-out)` */
export const animationCurve = "cubic-bezier(0.215, 0.61, 0.355, 1)";
/** @deprecated - use animationKeyframes.dropFade instead */
export const dropFade = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
`;
/** @deprecated - use animationKeyframes.fadeInAndTranslateUp instead */
export const fadeInAndTranslateUp = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
/** @deprecated - use animationKeyframes.fadeOutAndTranslateDown instead */
export const fadeOutAndTranslateDown = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
`;

/** @deprecated - use animationKeyframes.fadeInOut instead */
export const fadeInOut = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;
/** @deprecated - use animationKeyframes.fadeIn instead */
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
/** @deprecated - use animationKeyframes.fadeOut instead */
export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
/** @deprecated - use animationKeyframes.scaleAndFadeIn instead */
export const scaleAndFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
/** @deprecated - use animationKeyframes.scaleAndFadeOut instead */
export const scaleAndFadeOut = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
`;
/** @deprecated - use animationKeyframes.bounce instead */
export const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-20%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
`;
/** @deprecated - use animationKeyframes.translateUp instead */
export const translateUp = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
`;
/** @deprecated - use animationKeyframes.translateDown instead */
export const translateDown = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
`;
/** @deprecated - use animationKeyframes.pulseHighlight instead */
export const pulseHighlight = keyframes`
  0% {
    background-color: transparent
  }
  50% {
    background-color: rgba(0, 157, 255, 0.1)
  }
  100% {
    background-color: transparent
  }
`;

export const animationKeyframes = {
  dropFade,
  fadeInAndTranslateUp,
  fadeOutAndTranslateDown,
  fadeInOut,
  fadeIn,
  fadeOut,
  scaleAndFadeIn,
  scaleAndFadeOut,
  bounce,
  translateUp,
  translateDown,
  pulseHighlight,
}