import { jsxs as t, jsx as s } from "react/jsx-runtime";
const e = (o) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: [
      /* @__PURE__ */ s(
        "path",
        {
          d: "M4.5 3h15A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3Z",
          fill: "url(#microsoft-access-alt_svg__a)"
        }
      ),
      /* @__PURE__ */ s(
        "path",
        {
          d: "M10.923 7.125h2.209l3.549 9.75h-2.086l-.783-2.325h-3.651l-.768 2.325H7.319l3.604-9.75Zm2.42 5.922-1.135-3.529A4.616 4.616 0 0 1 12 8.723h-.04c-.043.279-.111.553-.205.819l-1.145 3.508 2.733-.002Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ s("defs", { children: /* @__PURE__ */ t(
        "linearGradient",
        {
          id: "microsoft-access-alt_svg__a",
          x1: 6.127,
          y1: 1.828,
          x2: 17.873,
          y2: 22.172,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ s("stop", { stopColor: "#B72C3F" }),
            /* @__PURE__ */ s("stop", { offset: 0.5, stopColor: "#B12334" }),
            /* @__PURE__ */ s("stop", { offset: 1, stopColor: "#9D1623" })
          ]
        }
      ) })
    ]
  }
);
export {
  e as default
};
