import { jsx as v } from "react/jsx-runtime";
const s = (c) => /* @__PURE__ */ v(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ v("path", { d: "M4 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 20V8a1.963 1.963 0 0 1 1.25-1.85l11.825-4.825c.233-.1.463-.1.688 0 .225.1.379.267.462.5a.898.898 0 0 1-.013.675.878.878 0 0 1-.487.475L8.3 6H20c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 22H4Zm0-9v7h16v-7H4Zm0-2h12v-1c0-.283.096-.521.288-.713A.967.967 0 0 1 17 9c.283 0 .52.096.712.287.192.192.288.43.288.713v1h2V8H4v3Zm4 8c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775C9.292 14.242 8.7 14 8 14s-1.292.242-1.775.725C5.742 15.208 5.5 15.8 5.5 16.5s.242 1.292.725 1.775C6.708 18.758 7.3 19 8 19Zm-4-6v7-7Z" })
  }
);
export {
  s as default
};
