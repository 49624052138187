import { jsx as a } from "react/jsx-runtime";
const o = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M17.75 14.95 16.3 13.5c.15-.25.28-.508.388-.775.108-.267.187-.542.237-.825.05-.25.171-.463.363-.638a.923.923 0 0 1 .637-.262c.317 0 .575.108.775.325.2.217.275.467.225.75a6.437 6.437 0 0 1-.425 1.512 7.5 7.5 0 0 1-.75 1.363Zm-2.95-3-1.8-1.8V5a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 4a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 5v3.15l-2-2V5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 12 2c.833 0 1.542.292 2.125.875S15 4.167 15 5v6c0 .183-.02.35-.062.5-.042.15-.088.3-.138.45Zm4.3 9.95-17-17a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l17 17a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM11 20v-2.075c-1.55-.217-2.867-.867-3.95-1.95a6.856 6.856 0 0 1-1.975-3.9.839.839 0 0 1 .225-.75c.2-.217.467-.325.8-.325.233 0 .442.087.625.262.183.175.3.388.35.638.217 1.167.783 2.142 1.7 2.925C9.692 15.608 10.767 16 12 16a4.931 4.931 0 0 0 3-1l1.425 1.425a7.884 7.884 0 0 1-1.587.975 6.223 6.223 0 0 1-1.838.525V20c0 .283-.096.52-.287.712A.968.968 0 0 1 12 21a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 20Z" })
  }
);
export {
  o as default
};
