import { jsx as e } from "react/jsx-runtime";
const d = (o) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11 3a1 1 0 0 1 1-1A10 10 0 1 1 2 12a1 1 0 1 1 2 0 8 8 0 1 0 8-8 1 1 0 0 1-1-1Z"
      }
    )
  }
);
export {
  d as default
};
