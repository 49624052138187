import { jsxs as a, jsx as e } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        }
      ),
      /* @__PURE__ */ e("path", { d: "M11.995 15.075a.948.948 0 0 1-.7-.275l-3.1-3.1a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7.2-.2.438-.3.713-.3.275 0 .504.092.687.275l2.4 2.4L14.37 10.3a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275.2.2.3.437.3.712a.934.934 0 0 1-.275.688l-3.1 3.1a.948.948 0 0 1-.7.275Z" })
    ]
  }
);
export {
  t as default
};
