import * as e from "react";
const v = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M5.903 13.5H4.755c-.694 0-1.255-.56-1.255-1.25v-6.5c0-.69.561-1.25 1.256-1.25h9.988c.694 0 1.256.56 1.256 1.25v6.5c0 .69-.558 1.25-1.244 1.25H8.779L6.6 15.617 5.903 13.5Zm.022 4.864L9.388 15h5.368a2.748 2.748 0 0 0 2.744-2.75v-6.5C17.5 4.23 16.266 3 14.744 3H4.756A2.752 2.752 0 0 0 2 5.75v6.5C2 13.77 3.234 15 4.755 15h.062l1.108 3.364Zm-.862-7.114h9.375v-1.5H5.062v1.5Zm0-3h9.375v-1.5H5.062v1.5Z",
      fillRule: "evenodd"
    }
  )
);
export {
  v as default
};
