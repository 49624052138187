import * as e from "react";
const l = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm11 4a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0V7Zm-6 2a1 1 0 0 0-2 0v6a1 1 0 1 0 2 0V9Zm3 1a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0v-4Zm-6 1a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2Zm12 0a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2Z",
      fill: "#8997A1"
    }
  )
);
export {
  l as default
};
