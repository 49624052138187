import * as a from "react";
const t = (e) => /* @__PURE__ */ a.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...e
  },
  /* @__PURE__ */ a.createElement("path", { d: "M4 1a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8.828a2 2 0 0 0-.586-1.414L9.586 1.586A2 2 0 0 0 8.172 1H4Zm8.586 8H9a1 1 0 0 1-1-1V4.414c0-.89 1.077-1.337 1.707-.707l3.586 3.586c.63.63.184 1.707-.707 1.707ZM19 9a1 1 0 0 1 1 1v9a4 4 0 0 1-4 4H6a1 1 0 1 1 0-2h10a2 2 0 0 0 2-2v-9a1 1 0 0 1 1-1Z" })
);
export {
  t as default
};
