import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M6.5 20c-1.517 0-2.812-.525-3.887-1.575C1.538 17.375 1 16.092 1 14.575c0-1.3.392-2.458 1.175-3.475S3.983 9.433 5.25 9.15c.417-1.533 1.25-2.775 2.5-3.725C9 4.475 10.417 4 12 4c1.95 0 3.604.679 4.962 2.037C18.321 7.396 19 9.05 19 11c1.15.133 2.104.629 2.863 1.487A4.41 4.41 0 0 1 23 15.5c0 1.25-.437 2.313-1.312 3.188S19.75 20 18.5 20H13v-7.15l.9.875a.934.934 0 0 0 .688.275c.275 0 .512-.1.712-.3a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7l-2.6-2.6a.96.96 0 0 0-.7-.3c-.267 0-.5.1-.7.3l-2.6 2.6a.975.975 0 0 0-.287.687.93.93 0 0 0 .287.713c.183.183.412.28.687.288a.975.975 0 0 0 .713-.263l.9-.875V20H6.5Z" })
  }
);
export {
  t as default
};
