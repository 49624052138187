import { jsxs as o, jsx as t } from "react/jsx-runtime";
const i = (l) => /* @__PURE__ */ o(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M23 12v9.296c0 .517-.42.936-.936.936H7.052a.937.937 0 0 1-.936-.936V12l8.698-1.023L23 12Z",
          fill: "#036C70"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M7.052 1.767h15.012c.516 0 .936.42.936.937V12H6.116V2.704c0-.517.42-.937.936-.937Z",
          fill: "#37C6D0"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M15.326 14.558h5.116v5.116h-5.116v-5.116ZM17.884 9.954a3.07 3.07 0 1 0 0-6.14 3.07 3.07 0 0 0 0 6.14Z",
          fill: "#1A9BA1"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M17.884 8.418a1.535 1.535 0 1 0 0-3.07 1.535 1.535 0 0 0 0 3.07Z",
          fill: "#036C70"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.1,
          d: "M12.343 5.86H6.116v12.791h6.227c.517 0 .936-.42.936-.936V6.797a.937.937 0 0 0-.936-.937Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.831 6.372H6.116v12.79h5.715c.517 0 .936-.419.936-.935V7.308a.937.937 0 0 0-.936-.936Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.831 6.372H6.116V18.14h5.715c.517 0 .936-.42.936-.937V7.308a.937.937 0 0 0-.936-.936Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.32 6.372H6.115V18.14h5.204c.516 0 .936-.42.936-.937V7.308a.937.937 0 0 0-.937-.936Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M1.936 6.372h9.378c.517 0 .937.42.937.936v9.378c0 .517-.42.937-.937.937H1.936A.93.93 0 0 1 1 16.692V7.308c0-.516.42-.936.936-.936Z",
          fill: "url(#microsoft-forms-1_svg__a)"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M8.526 9.943h-2.64v1.663h2.46v.988h-2.46v2.455H4.694V8.951H8.53l-.005.992Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ o(
        "linearGradient",
        {
          id: "microsoft-forms-1_svg__a",
          x1: 2.955,
          y1: 5.639,
          x2: 10.3,
          y2: 18.361,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ t("stop", { stopColor: "#058F92" }),
            /* @__PURE__ */ t("stop", { offset: 0.5, stopColor: "#038489" }),
            /* @__PURE__ */ t("stop", { offset: 1, stopColor: "#026D71" })
          ]
        }
      ) })
    ]
  }
);
export {
  i as default
};
