import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.25 7A7.25 7.25 0 0 0 7 14.25V18a1 1 0 1 1-2 0v-3.75A9.25 9.25 0 0 1 14.25 5H18a1 1 0 1 1 0 2h-3.75Z"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14.25 7A7.25 7.25 0 0 0 7 14.25V18a1 1 0 1 1-2 0v-3.75A9.25 9.25 0 0 1 14.25 5H18a1 1 0 1 1 0 2h-3.75Z"
    }
  )
);
export {
  l as default
};
