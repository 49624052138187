import { jsx as s } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ s("path", { d: "m7.525 15.9-3.4 3.4a.93.93 0 0 1-.713.288.977.977 0 0 1-.687-.287c-.2-.2-.3-.438-.3-.713 0-.275.092-.504.275-.687l3.4-3.4c.517-.517.87-1.163 1.062-1.938.192-.775.288-1.962.288-3.562 0-.967.217-1.917.65-2.85.433-.933 1.05-1.8 1.85-2.6 1.517-1.517 3.192-2.375 5.025-2.575 1.833-.2 3.342.308 4.525 1.525 1.2 1.2 1.7 2.717 1.5 4.55-.2 1.833-1.05 3.5-2.55 5-.8.8-1.667 1.417-2.6 1.85a6.703 6.703 0 0 1-2.85.65c-1.617 0-2.8.092-3.55.275A4.018 4.018 0 0 0 7.525 15.9Zm2.775-4.25c.783.768 1.842 1.05 3.175.85 1.333-.2 2.525-.824 3.575-1.874 1.067-1.067 1.704-2.263 1.912-3.588.209-1.325-.079-2.362-.862-3.112-.8-.8-1.846-1.1-3.138-.9-1.291.2-2.479.833-3.562 1.9-1.05 1.05-1.688 2.237-1.913 3.562-.225 1.325.046 2.38.813 3.163ZM18 23c-1.1 0-2.042-.39-2.825-1.174C14.392 21.043 14 20.1 14 19s.392-2.042 1.175-2.825C15.958 15.393 16.9 15 18 15s2.042.392 2.825 1.175C21.608 16.959 22 17.9 22 19s-.392 2.042-1.175 2.825C20.042 22.609 19.1 23 18 23Z" })
  }
);
export {
  e as default
};
