import { jsxs as a, jsx as h } from "react/jsx-runtime";
const t = (v) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: [
      /* @__PURE__ */ h(
        "path",
        {
          d: "M11.966 2h7.511A2.5 2.5 0 0 1 22 4.523v7.511A9.969 9.969 0 0 1 12.034 22h-.057a9.97 9.97 0 0 1-9.218-6.148A9.963 9.963 0 0 1 2 12.036c0-5.523 4.454-9.978 9.966-10.034V2Z",
          fill: "#25C16F"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M11.966 6.454a4.58 4.58 0 0 0-4.58 4.58v3.114a1.559 1.559 0 0 0 1.523 1.523h1.296v-3.574h-1.75v-1a3.557 3.557 0 0 1 7.102 0v1h-1.772v3.574h1.17v.056a1.432 1.432 0 0 1-1.409 1.41h-1.398c-.114 0-.239.056-.239.17.007.123.11.233.239.238h1.409a1.83 1.83 0 0 0 1.818-1.818v-.113a1.506 1.506 0 0 0 1.17-1.478v-3.045c.064-2.58-1.981-4.637-4.58-4.637Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  t as default
};
