import { useCallback, useEffect, useRef } from "react";
import { ChromePicker } from "react-color";

const BasicColorPicker = ({
  color,
  handleClose,
  handleChange,
  disableAlpha,
}) => {
  const pickerEl = useRef(null);

  const handleClick = useCallback(
    (e) => {
      if (pickerEl.current && !pickerEl.current.contains(e.target)) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  return (
    <div style={{ display: "flex", position: "absolute", zIndex: 1 }}>
      <div ref={pickerEl}>
        <ChromePicker
          onChange={handleChange}
          color={color}
          disableAlpha={disableAlpha}
        />
      </div>
    </div>
  );
};

export default BasicColorPicker;
