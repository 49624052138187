import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/icons/package.json";
import * as LegacyIcons from "../../../../../packages/icons/src/index-legacy";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  LegacyIcons,
  React
};