import { jsxs as h, jsx as s } from "react/jsx-runtime";
const v = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ s(
        "path",
        {
          d: "M8.45 12c0 2.072 1.078 3.318 2.988 3.522v5.277H2v-5.25c2.261 0 3.55-1.284 3.55-3.549C5.55 9.74 4.26 8.45 2 8.45V3.2h9.438v5.282C9.528 8.678 8.45 9.932 8.45 12Z",
          fill: "#1E1919"
        }
      ),
      /* @__PURE__ */ s(
        "path",
        {
          d: "M18.45 12c0 2.265 1.289 3.55 3.55 3.55v5.25h-9.438v-5.282c1.91-.2 2.988-1.45 2.988-3.522 0-2.068-1.078-3.318-2.988-3.518V3.201H22v5.25c-2.261 0-3.55 1.288-3.55 3.55Z",
          fill: "#0061FE"
        }
      )
    ]
  }
);
export {
  v as default
};
