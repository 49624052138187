import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 125 43",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { filter: "url(#icon-widget-files_svg__a)" }, /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 5,
      y: 4,
      width: 33.472,
      height: 33.472,
      rx: 16.736,
      fill: "#fff"
    }
  )),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m14.502 26.04.751 1.3c.156.273.381.487.645.644l2.684-4.648h-5.37c0 .303.079.606.235.88l1.055 1.825Z",
      fill: "#0066DA"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m21.736 17.87-2.685-4.648a1.773 1.773 0 0 0-.644.645l-4.96 8.59a1.77 1.77 0 0 0-.234.88h5.37l3.153-5.468Z",
      fill: "#00AC47"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M27.573 27.984c.264-.157.488-.371.645-.645l.312-.537 1.494-2.587c.156-.273.234-.576.234-.878h-5.37l1.143 2.245 1.542 2.402Z",
      fill: "#EA4335"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m21.736 17.87 2.684-4.648a1.716 1.716 0 0 0-.878-.234h-3.613c-.312 0-.615.088-.878.234l2.685 4.647Z",
      fill: "#00832D"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M24.889 23.337h-6.307l-2.684 4.647c.263.156.566.234.878.234h9.92c.312 0 .614-.088.878-.234l-2.685-4.648Z",
      fill: "#2684FC"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m27.545 18.162-2.48-4.295a1.773 1.773 0 0 0-.644-.645l-2.685 4.647 3.153 5.468h5.36a1.77 1.77 0 0 0-.234-.88l-2.47-4.295Z",
      fill: "#FFBA00"
    }
  ),
  /* @__PURE__ */ e.createElement("g", { filter: "url(#icon-widget-files_svg__b)" }, /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 86.368,
      y: 4,
      width: 33.472,
      height: 33.472,
      rx: 16.736,
      fill: "#fff"
    }
  )),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M103.302 21.727a4.757 4.757 0 1 0 0-9.514 4.757 4.757 0 0 0 0 9.514Z",
      fill: "#036C70"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M107.266 25.691a4.36 4.36 0 1 0 .001-8.72 4.36 4.36 0 0 0-.001 8.72Z",
      fill: "#1A9BA1"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M103.897 28.863a3.369 3.369 0 1 0 0-6.739 3.369 3.369 0 0 0 0 6.739Z",
      fill: "#37C6D0"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      opacity: 0.1,
      d: "M104.094 16.506v8.46a.729.729 0 0 1-.725.725h-2.838c-.004-.067-.004-.13-.004-.198a1.458 1.458 0 0 1 .008-.198 3.358 3.358 0 0 1 2.017-2.89v-.738a4.751 4.751 0 0 1-3.94-5.49c.02-.133.048-.266.083-.396h4.674c.4.001.724.325.725.725Z",
      fill: "#000"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      opacity: 0.2,
      d: "M102.973 16.177h-4.36a4.752 4.752 0 0 0 4.122 5.516c-1.229.583-2.125 2.238-2.201 3.602a3.276 3.276 0 0 0-.004.396c.008.133.025.266.052.397h2.39a.73.73 0 0 0 .726-.726v-8.46a.728.728 0 0 0-.725-.725Z",
      fill: "#000"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      opacity: 0.2,
      d: "M102.973 16.177h-4.36a4.752 4.752 0 0 0 4.046 5.507c-1.19.625-2.05 2.276-2.124 3.61h2.438a.73.73 0 0 0 .725-.725v-7.666a.727.727 0 0 0-.725-.726Z",
      fill: "#000"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      opacity: 0.2,
      d: "M102.576 16.177h-3.964a4.752 4.752 0 0 0 3.516 5.404 6.548 6.548 0 0 0-1.593 3.714h2.041a.729.729 0 0 0 .726-.726v-7.666a.727.727 0 0 0-.726-.726Z",
      fill: "#000"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M95.308 16.177h7.268c.401 0 .726.326.726.727v7.268a.726.726 0 0 1-.726.726h-7.268a.727.727 0 0 1-.727-.726v-7.268c0-.401.325-.727.727-.727Z",
      fill: "url(#icon-widget-files_svg__c)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M97.827 20.449a1.341 1.341 0 0 1-.416-.44c-.1-.184-.15-.391-.145-.601a1.157 1.157 0 0 1 .27-.779c.19-.218.439-.38.715-.467.316-.104.646-.155.978-.151.437-.016.873.045 1.289.18v.912a2.025 2.025 0 0 0-.583-.238 2.84 2.84 0 0 0-.682-.082 1.474 1.474 0 0 0-.7.148.46.46 0 0 0-.278.421c0 .112.042.218.12.299.09.094.198.17.317.226a10.08 10.08 0 0 0 .679.297c.26.102.511.225.75.37.181.111.333.264.443.446.113.206.168.44.159.674.013.291-.076.578-.252.811-.176.214-.41.372-.674.454-.311.097-.635.144-.961.14a5.087 5.087 0 0 1-.872-.072c-.244-.04-.481-.11-.707-.208v-.961c.216.154.457.27.713.34.256.08.52.123.788.127.247.015.494-.037.713-.152a.479.479 0 0 0 .244-.428.456.456 0 0 0-.135-.327 1.357 1.357 0 0 0-.371-.259c-.16-.079-.393-.184-.701-.313a3.985 3.985 0 0 1-.701-.367Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ e.createElement("g", { filter: "url(#icon-widget-files_svg__d)" }, /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 45.684,
      y: 4,
      width: 33.472,
      height: 33.472,
      rx: 16.736,
      fill: "#fff"
    }
  )),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m60.305 18.483 3.741 2.24 2.23-.938a3.608 3.608 0 0 1 1.679-.287 5.57 5.57 0 0 0-10.047-1.678l.057-.002a4.433 4.433 0 0 1 2.34.665Z",
      fill: "#0364B8"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M60.306 18.482a4.433 4.433 0 0 0-2.341-.664l-.057.002a4.454 4.454 0 0 0-3.598 7l3.3-1.388 1.466-.617 3.265-1.375 1.705-.717-3.74-2.24Z",
      fill: "#0078D4"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M67.955 19.498a3.605 3.605 0 0 0-1.68.287l-2.229.938.647.387 2.119 1.27.924.553 3.161 1.894a3.62 3.62 0 0 0-2.942-5.329Z",
      fill: "#1490DF"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m67.736 22.933-.924-.554-2.12-1.269-.646-.387-1.705.718-3.265 1.374-1.467.617-3.299 1.388a4.45 4.45 0 0 0 3.655 1.909h9.745a3.62 3.62 0 0 0 3.187-1.902l-3.16-1.894Z",
      fill: "#28A8EA"
    }
  ),
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "filter",
    {
      id: "icon-widget-files_svg__a",
      x: 0.704,
      y: 0.778,
      width: 42.064,
      height: 42.063,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB"
    },
    /* @__PURE__ */ e.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 1.074 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 2.148 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "BackgroundImageFix",
        result: "effect1_dropShadow_213_44056"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 1.074 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 2.148 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "effect1_dropShadow_213_44056",
        result: "effect2_dropShadow_213_44056"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in: "SourceGraphic",
        in2: "effect2_dropShadow_213_44056",
        result: "shape"
      }
    )
  ), /* @__PURE__ */ e.createElement(
    "filter",
    {
      id: "icon-widget-files_svg__b",
      x: 82.072,
      y: 0.778,
      width: 42.064,
      height: 42.063,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB"
    },
    /* @__PURE__ */ e.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 1.074 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 2.148 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "BackgroundImageFix",
        result: "effect1_dropShadow_213_44056"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 1.074 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 2.148 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "effect1_dropShadow_213_44056",
        result: "effect2_dropShadow_213_44056"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in: "SourceGraphic",
        in2: "effect2_dropShadow_213_44056",
        result: "shape"
      }
    )
  ), /* @__PURE__ */ e.createElement(
    "filter",
    {
      id: "icon-widget-files_svg__d",
      x: 41.388,
      y: 0.778,
      width: 42.064,
      height: 42.063,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB"
    },
    /* @__PURE__ */ e.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 1.074 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 2.148 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "BackgroundImageFix",
        result: "effect1_dropShadow_213_44056"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 1.074 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 2.148 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "effect1_dropShadow_213_44056",
        result: "effect2_dropShadow_213_44056"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in: "SourceGraphic",
        in2: "effect2_dropShadow_213_44056",
        result: "shape"
      }
    )
  ), /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "icon-widget-files_svg__c",
      x1: 96.096,
      y1: 15.609,
      x2: 101.787,
      y2: 25.466,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { stopColor: "#058F92" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.5, stopColor: "#038489" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#026D71" })
  ))
);
export {
  l as default
};
