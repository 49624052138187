import { jsx as l } from "react/jsx-runtime";
const a = (t) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ l("path", { d: "M14.35 20.775 9 18.9l-4.65 1.8a.903.903 0 0 1-.925-.113A.986.986 0 0 1 3 19.75v-14c0-.216.063-.408.188-.575.125-.166.295-.291.512-.375l4.65-1.575A2.55 2.55 0 0 1 9 3.112c.217-.008.433.03.65.113L15 5.1l4.65-1.8a.904.904 0 0 1 .925.112.989.989 0 0 1 .425.838v14a.931.931 0 0 1-.188.575 1.127 1.127 0 0 1-.512.375l-4.65 1.575a2.522 2.522 0 0 1-.65.112 1.618 1.618 0 0 1-.65-.112ZM14 18.55V6.85l-4-1.4v11.7l4 1.4Zm2 0 3-1V5.7l-3 1.15v11.7ZM5 18.3l3-1.15V5.45l-3 1V18.3ZM16 6.85v11.7-11.7Zm-8-1.4v11.7-11.7Z" })
  }
);
export {
  a as default
};
