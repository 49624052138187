import { jsxs as a, jsx as t } from "react/jsx-runtime";
const s = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ t("path", { d: "M14.975 6.454h-5.95v10.69h5.95V6.454Z", fill: "#FF5F00" }),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M9.403 11.799A6.787 6.787 0 0 1 12 6.454a6.8 6.8 0 1 0 0 10.69 6.787 6.787 0 0 1-2.597-5.345Z",
          fill: "#EB001B"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M23 11.799a6.8 6.8 0 0 1-11 5.345 6.8 6.8 0 0 0 0-10.69 6.799 6.799 0 0 1 11 5.345Z",
          fill: "#F79E1B"
        }
      )
    ]
  }
);
export {
  s as default
};
