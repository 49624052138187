import * as React from "react";
const SvgIconPublish = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1H6Zm3 8H7.41c-.89 0-1.33-1.08-.7-1.71L11.3 7.7a.996.996 0 0 1 1.41 0l4.59 4.59c.63.63.18 1.71-.71 1.71H15v5c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1v-5Z"
    />
  </svg>
);
export default SvgIconPublish;
