import { jsx as a } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M11.025 21.399a.482.482 0 0 1-.547.484c-2.325-.342-4.285-1.395-5.878-3.158-1.717-1.9-2.575-4.142-2.575-6.725 0-2.584.858-4.825 2.575-6.725 1.593-1.764 3.553-2.816 5.878-3.159a.482.482 0 0 1 .547.485v2.012c0 .25-.186.46-.431.507-1.542.297-2.831 1.049-3.869 2.255-1.133 1.316-1.7 2.858-1.7 4.625 0 1.766.567 3.308 1.7 4.625 1.038 1.206 2.327 1.957 3.869 2.254a.524.524 0 0 1 .431.508v2.012Zm2.547.484a.482.482 0 0 1-.547-.485V19.39c0-.252.188-.462.435-.509A6.545 6.545 0 0 0 17 17a6.979 6.979 0 0 0 1.9-3.576.523.523 0 0 1 .506-.424h2.018c.295 0 .527.255.484.547-.318 2.148-1.25 3.995-2.795 5.54-1.546 1.545-3.392 2.477-5.54 2.796ZM19.406 11a.523.523 0 0 1-.506-.425A6.979 6.979 0 0 0 17 7a6.545 6.545 0 0 0-3.54-1.882.524.524 0 0 1-.435-.508V2.6c0-.295.255-.527.547-.484 2.149.318 3.995 1.25 5.541 2.795 1.545 1.545 2.477 3.392 2.795 5.54a.482.482 0 0 1-.484.548h-2.018Z" })
  }
);
export {
  c as default
};
