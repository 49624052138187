import styled from "styled-components";
// @ts-ignore
import { Ul } from "@happeouikit/list";

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Container = styled(Ul)<{ menuWidth?: string }>`
  display: block;
  margin-top: var(--space-sm);
  padding: var(--space-sm) 0px;
  background: var(--color-surface);
  min-width: ${({ menuWidth }) => menuWidth || "200px"};
  box-shadow: var(--box-shadow-shadow-float);
  border-radius: var(--radius-lg);
  z-index: 150;
`;

export const ActionRow = styled.li<any>`
  cursor: pointer;
  padding: var(--space-sm) var(--space-md);
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${({ selected }) =>
    selected ? "var(--color-active-lighten80)" : undefined};

  :focus {
    background-color: var(--color-active-lighten90);
    border: 1px solid var(--color-focus-indicator);
    outline: none;
  }

  :hover {
    background-color: var(--color-active-lighten90);
    p {
      color: var(--color-focus-indicator);
    }
    div > svg {
      fill: var(--color-focus-indicator);
    }
  }
`;
