import { jsxs as h, jsx as c } from "react/jsx-runtime";
const l = (e) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ c("rect", { x: 2, y: 2, width: 20, height: 20, rx: 4, fill: "#A094ED" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M6.333 7.16h11.334c.779 0 1.416.637 1.416 1.416v6.848a1.42 1.42 0 0 1-1.416 1.416H6.333a1.42 1.42 0 0 1-1.416-1.416V8.576c0-.779.637-1.416 1.416-1.416Zm5.337 8.027c.449 0 .803-.495.756-.968-.024-.708-.756-1.18-1.535-1.51-.543-.213-.614-.426-.614-.662 0-.236.142-.448.33-.614.331-.283.497-.708.497-1.18 0-.898-.543-1.653-1.488-1.653-.944 0-1.487.755-1.487 1.653 0 .472.189.897.495 1.18.19.166.331.378.331.614s-.07.449-.614.685c-.779.354-1.511.78-1.535 1.487 0 .473.355.968.803.968h4.061Zm5.524-1.652c0 .26-.212.472-.472.472h-2.125a.474.474 0 0 1-.473-.472v-.709c0-.26.213-.472.473-.472h2.125c.26 0 .472.213.472.472v.709Zm-.472-2.125c.26 0 .473-.213.473-.473v-.708a.474.474 0 0 0-.473-.472h-3.541a.474.474 0 0 0-.472.472v.708c0 .26.212.473.472.473h3.541Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  l as default
};
