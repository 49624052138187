import { jsx as h } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M12.14 16H3a1 1 0 1 0 0 2h9.14c.45 1.72 2 3 3.86 3 1.86 0 3.41-1.28 3.86-3H21a1 1 0 1 0 0-2h-1.14c-.45-1.72-2-3-3.86-3-1.86 0-3.41 1.28-3.86 3ZM14 17c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2ZM3 6a1 1 0 0 0 0 2h1.14c.45 1.72 2 3 3.86 3 1.86 0 3.41-1.28 3.86-3H21a1 1 0 1 0 0-2h-9.14c-.45-1.72-2-3-3.86-3-1.86 0-3.41 1.28-3.86 3H3Zm3 1c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2Z" })
  }
);
export {
  s as default
};
