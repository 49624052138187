import { jsxs as h, jsx as t } from "react/jsx-runtime";
const e = (a) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ t("path", { d: "M14.642 6.449a8 8 0 0 0-9.3 11.987 1 1 0 1 1-1.664 1.109A10 10 0 0 1 15.302 4.56a1 1 0 0 1-.66 1.888ZM20.046 9.844a1 1 0 0 1 1.292.576 10 10 0 0 1-1.02 9.13 1 1 0 1 1-1.664-1.11 8 8 0 0 0 .816-7.305 1 1 0 0 1 .576-1.291Z" }),
      /* @__PURE__ */ t("path", { d: "M10.45 15.501c.4.4.917.596 1.55.588.633-.009 1.1-.238 1.4-.688l4.225-6.325c.15-.233.13-.446-.063-.637-.191-.192-.404-.213-.637-.063L10.6 12.601c-.45.3-.687.758-.712 1.375-.025.617.162 1.125.562 1.525Z" })
    ]
  }
);
export {
  e as default
};
