import { jsxs as e, jsx as c } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ c("path", { d: "M7.958 12.492h1.954a10.938 10.938 0 0 0-.177-.554c-.07-.208-.138-.43-.208-.662l-.111-.353c-.037-.118-.073-.235-.112-.354a16.596 16.596 0 0 1-.207-.685c-.028-.095-.053-.187-.078-.276l-.092-.324h-.015c-.07.33-.154.662-.262.992l-.132.428c-.072.235-.145.473-.221.71-.1.393-.224.755-.339 1.078ZM14.904 12.207h1.623c.2 0 .346 0 .439-.008a.376.376 0 0 0 .177-.061v-.077a1.018 1.018 0 0 0-1.046-.992c-.424-.023-.824.2-1.024.584a1.595 1.595 0 0 0-.169.554Z" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M17.813 3H6.188C4.425 3 3 4.462 3 6.27v11.46C3 19.539 4.425 21 6.188 21h11.625C19.575 21 21 19.538 21 17.73V6.27C21 4.461 19.575 3 17.812 3Zm-7.44 11.023H7.512l-.585 1.815a.135.135 0 0 1-.146.108H5.335c-.085 0-.108-.047-.085-.139l2.477-7.1c.023-.077.046-.146.077-.238.031-.162.046-.331.046-.5-.007-.039.023-.077.062-.085h1.992c.054 0 .093.023.1.062l2.808 7.846c.023.084 0 .123-.077.123h-1.608c-.054.008-.107-.031-.123-.085l-.63-1.846v.039Zm6.97-.693h-2.439c.031.239.108.477.239.685.138.207.33.369.561.461.308.131.646.2.985.193.27-.008.538-.031.8-.085.238-.03.47-.092.684-.177.039-.03.062-.015.062.062v1.177c0 .03-.008.061-.015.092a.18.18 0 0 1-.054.054c-.246.107-.5.184-.77.23-.361.07-.723.1-1.092.093-.584 0-1.077-.093-1.477-.27a2.59 2.59 0 0 1-.969-.73 2.822 2.822 0 0 1-.53-1.008 3.926 3.926 0 0 1-.162-1.123c0-.415.061-.823.192-1.223a3.14 3.14 0 0 1 .577-1.054c.254-.308.57-.554.93-.73.362-.178.793-.24 1.285-.24.408-.007.816.07 1.193.24.315.138.592.345.807.615.2.261.362.553.462.877.1.307.146.623.146.938 0 .185-.008.346-.015.492-.009.081-.015.15-.02.208l-.011.115c-.008.054-.054.1-.108.1-.03 0-.076.004-.138.008l-.116.008a3.21 3.21 0 0 1-.71.008c-.095-.008-.194-.016-.297-.016Z"
        }
      )
    ]
  }
);
export {
  h as default
};
