import { jsxs as t, jsx as a } from "react/jsx-runtime";
const l = (i) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...i,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          d: "M16.345 9.442h5.683c.537 0 .972.435.972.972v5.176c0 1.973-1.6 3.573-3.573 3.573h-.017a3.573 3.573 0 0 1-3.573-3.572V9.95c0-.28.228-.508.508-.508ZM20.186 8.419a2.302 2.302 0 1 0 0-4.605 2.302 2.302 0 0 0 0 4.605Z",
          fill: "#5059C9"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M13.023 8.419a3.326 3.326 0 1 0 0-6.651 3.326 3.326 0 0 0 0 6.65ZM17.458 9.442h-9.38a.962.962 0 0 0-.938.984v5.903a5.77 5.77 0 0 0 5.628 5.904 5.77 5.77 0 0 0 5.627-5.904v-5.903a.962.962 0 0 0-.937-.984Z",
          fill: "#7B83EB"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.1,
          d: "M13.28 9.442v8.273a.942.942 0 0 1-.937.936H7.59a6.232 6.232 0 0 1-.45-2.323v-5.904a.96.96 0 0 1 .936-.982h5.203Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.2,
          d: "M12.768 9.442v8.785a.942.942 0 0 1-.936.936H7.83a6.618 6.618 0 0 1-.24-.512 6.232 6.232 0 0 1-.45-2.323v-5.904a.96.96 0 0 1 .936-.982h4.692Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.2,
          d: "M12.768 9.442v7.761a.943.943 0 0 1-.937.937h-4.42a6.232 6.232 0 0 1-.271-1.812v-5.904a.96.96 0 0 1 .936-.982h4.692Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.2,
          d: "M12.256 9.442v7.761a.944.944 0 0 1-.936.937H7.41a6.232 6.232 0 0 1-.27-1.812v-5.904a.96.96 0 0 1 .936-.982h4.18Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.1,
          d: "M13.28 6.797v1.612c-.088.005-.17.01-.257.01s-.168-.005-.255-.01a2.81 2.81 0 0 1-.512-.082 3.326 3.326 0 0 1-2.302-1.955 2.844 2.844 0 0 1-.164-.511h2.553a.94.94 0 0 1 .936.936Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.2,
          d: "M12.768 7.308v1.1a2.81 2.81 0 0 1-.512-.082 3.326 3.326 0 0 1-2.302-1.954h1.877a.94.94 0 0 1 .937.936Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.2,
          d: "M12.768 7.308v1.1a2.81 2.81 0 0 1-.512-.082 3.326 3.326 0 0 1-2.302-1.954h1.877a.94.94 0 0 1 .937.936Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          opacity: 0.2,
          d: "M12.256 7.308v1.018a3.326 3.326 0 0 1-2.302-1.954h1.366a.94.94 0 0 1 .936.936Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M1.938 6.372h9.38c.518 0 .938.42.938.938v9.38c0 .518-.42.938-.938.938h-9.38A.938.938 0 0 1 1 16.69V7.31c0-.518.42-.938.938-.938Z",
          fill: "url(#microsoft-teams_svg__a)"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M9.096 9.942H7.221v5.107H6.026V9.942H4.16v-.99h4.936v.99Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ a("defs", { children: /* @__PURE__ */ t(
        "linearGradient",
        {
          id: "microsoft-teams_svg__a",
          x1: 2.955,
          y1: 5.639,
          x2: 10.3,
          y2: 18.361,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ a("stop", { stopColor: "#5A62C3" }),
            /* @__PURE__ */ a("stop", { offset: 0.5, stopColor: "#4D55BD" }),
            /* @__PURE__ */ a("stop", { offset: 1, stopColor: "#3940AB" })
          ]
        }
      ) })
    ]
  }
);
export {
  l as default
};
