import { RGBA, HEX, RGB } from "./types";

export default class Color {
  public r: number;

  public g: number;

  public b: number;

  public a: number;

  constructor();

  constructor(colorStr?: HEX | RGB | RGBA);

  constructor(r?: string | number, g?: number, b?: number);

  constructor(r?: string | number, g?: number, b?: number, a?: number) {
    this.r = 0;
    this.g = 0;
    this.b = 0;
    this.a = 0;
    if (typeof r === "string") {
      const rTrimmed = r.trim();
      if (rTrimmed.indexOf("#") === 0) {
        let colorHex = rTrimmed as HEX;
        if (colorHex.length === 4) {
          colorHex = `#${colorHex
            .substring(1, 4)
            .split("")
            .map((val) => val.padStart(2, val))
            .join("")}`;
        }
        this.r = parseInt(colorHex.substring(1, 3), 16);
        this.g = parseInt(colorHex.substring(3, 5), 16);
        this.b = parseInt(colorHex.substring(5, 7), 16);

        this.a = 1;
      } else if (r.indexOf("rgb") === 0) {
        const [red, green, blue, alpha] = (r as RGBA)
          .substring(r.indexOf("(") + 1, r.lastIndexOf(")"))
          .split(/,\s*/);
        this.r = parseInt(red, 10);
        this.g = parseInt(green, 10);
        this.b = parseInt(blue, 10);
        this.a = alpha ? parseFloat(alpha) : 1;
      }
    } else if (
      r !== undefined &&
      g !== undefined &&
      b !== undefined &&
      r >= 0 &&
      g >= 0 &&
      b >= 0
    ) {
      this.r = r;
      this.g = g;
      this.b = b;
      this.a = a || 1;
    } else {
      throw new Error("Invalid color");
    }
  }

  toHex(): HEX {
    return `#${this.r.toString(16).padStart(2, "0")}${this.g
      .toString(16)
      .padStart(2, "0")}${this.b.toString(16).padStart(2, "0")}`;
  }

  toRgb(): RGB {
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  }

  toRgba(): RGBA {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
  }

  toArray(): [number, number, number, number] {
    return [this.r, this.g, this.b, this.a];
  }
}
