import { jsx as s } from "react/jsx-runtime";
const a = (c) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ s("path", { d: "M8.225 8.325C8.042 8.142 7.95 7.9 7.95 7.6s.092-.541.275-.725L11.3 3.8c.1-.1.208-.17.325-.213.117-.04.242-.062.375-.062s.263.021.388.062a.681.681 0 0 1 .312.213l3.1 3.1c.183.184.27.421.262.712-.008.292-.104.53-.287.713-.183.184-.425.275-.725.275s-.542-.091-.725-.275L12 6 9.65 8.35c-.183.184-.42.271-.712.263a1.01 1.01 0 0 1-.713-.288ZM12 20.575a.942.942 0 0 1-.375-.075 1.315 1.315 0 0 1-.325-.2l-3.075-3.075c-.183-.183-.275-.425-.275-.725s.092-.541.275-.725c.183-.183.425-.275.725-.275s.542.092.725.275L12 18.1l2.35-2.35c.183-.183.42-.27.712-.262.292.008.53.104.713.287.183.184.275.425.275.725s-.092.542-.275.725L12.7 20.3a.917.917 0 0 1-.312.2c-.125.05-.255.075-.388.075Z" })
  }
);
export {
  a as default
};
