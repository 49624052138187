import { jsx as c } from "react/jsx-runtime";
const s = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M9 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 7 18c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 9 16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0 1 9 20Zm6 0c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 13 18c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 15 16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0 1 15 20Zm-6-6c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 7 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 9 10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 9 14Zm6 0c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 13 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 15 10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 15 14ZM9 8c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 7 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 9 4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 9 8Zm6 0c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 13 6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 15 4c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 15 8Z" })
  }
);
export {
  s as default
};
