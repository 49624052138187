import * as React from "react";
const SvgIconWidgetChannelFeed = (props) => (
  <svg
    viewBox="0 0 93 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <g filter="url(#icon-widget-channel-feed_svg__a)">
      <g clipPath="url(#icon-widget-channel-feed_svg__b)">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
        <rect
          x={-9.025}
          y={-1.447}
          width={110.469}
          height={66.366}
          rx={1.422}
          fill="#fff"
        />
        <rect
          x={6.125}
          y={5.451}
          width={54}
          height={43}
          rx={1.422}
          fill="#0075DB"
        />
        <rect
          x={6.125}
          y={5.451}
          width={54}
          height={43}
          rx={1.422}
          fill="#fff"
          fillOpacity={0.9}
        />
        <rect
          x={63.125}
          y={5.451}
          width={23}
          height={9.328}
          rx={1.422}
          fill="#F3F5F7"
        />
        <rect
          x={63.125}
          y={15.984}
          width={23}
          height={10.364}
          rx={1.422}
          fill="#F3F5F7"
        />
        <rect
          x={63.125}
          y={27.553}
          width={23}
          height={10.364}
          rx={1.422}
          fill="#F3F5F7"
        />
        <rect
          x={63.125}
          y={39.123}
          width={23}
          height={9.328}
          rx={1.422}
          fill="#F3F5F7"
        />
        <g filter="url(#icon-widget-channel-feed_svg__c)">
          <rect x={9.625} y={32.225} width={32} height={5} rx={1} fill="#fff" />
        </g>
        <g filter="url(#icon-widget-channel-feed_svg__d)">
          <rect x={9.625} y={39.225} width={20} height={5} rx={1} fill="#fff" />
        </g>
        <g filter="url(#icon-widget-channel-feed_svg__e)">
          <rect x={9.625} y={8.225} width={11} height={11} rx={3} fill="#fff" />
        </g>
        <path
          d="M12.833 11.943a.51.51 0 0 1 .51-.51h3.564a.51.51 0 0 1 .51.51v3.565a.51.51 0 0 1-.51.51h-3.565a.51.51 0 0 1-.509-.51v-3.565Z"
          fill="#0075DB"
        />
        <path
          d="M15.124 15.354a.341.341 0 0 1-.116-.02.431.431 0 0 1-.107-.058l-1.231-.957a.165.165 0 0 1-.07-.144.177.177 0 0 1 .074-.144.182.182 0 0 1 .22 0l1.23.954 1.232-.954a.182.182 0 0 1 .219 0 .177.177 0 0 1 .075.144.166.166 0 0 1-.07.144l-1.232.957a.432.432 0 0 1-.107.058.343.343 0 0 1-.117.02Zm0-.921a.34.34 0 0 1-.116-.02.425.425 0 0 1-.107-.058l-1.231-.958a.176.176 0 0 1-.055-.066.187.187 0 0 1 0-.16.176.176 0 0 1 .055-.065l1.231-.958a.431.431 0 0 1 .107-.057.341.341 0 0 1 .233 0 .432.432 0 0 1 .107.057l1.231.958c.025.018.043.04.055.066a.185.185 0 0 1 0 .16.176.176 0 0 1-.055.065l-1.231.958a.425.425 0 0 1-.107.057.34.34 0 0 1-.117.02Z"
          fill="#fff"
        />
      </g>
    </g>
    <defs>
      <filter
        id="icon-widget-channel-feed_svg__a"
        x={0.593}
        y={0.195}
        width={91.563}
        height={54.588}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44026"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44026"
          result="effect2_dropShadow_213_44026"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44026"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-channel-feed_svg__c"
        x={6.781}
        y={30.092}
        width={37.688}
        height={10.688}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44026"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44026"
          result="effect2_dropShadow_213_44026"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44026"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-channel-feed_svg__d"
        x={6.781}
        y={37.092}
        width={25.689}
        height={10.688}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44026"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44026"
          result="effect2_dropShadow_213_44026"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44026"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-channel-feed_svg__e"
        x={6.781}
        y={6.092}
        width={16.689}
        height={16.689}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44026"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44026"
          result="effect2_dropShadow_213_44026"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44026"
          result="shape"
        />
      </filter>
      <clipPath id="icon-widget-channel-feed_svg__b">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconWidgetChannelFeed;
