import { jsx as c } from "react/jsx-runtime";
const A = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M11 21c-1.25 0-2.42-.233-3.512-.7a9.017 9.017 0 0 1-2.863-1.925A9.017 9.017 0 0 1 2.7 15.512 8.837 8.837 0 0 1 2 12c0-2.517.871-4.646 2.613-6.388C6.354 3.871 8.483 3 11 3a7.529 7.529 0 0 1 1.225.1c.217.033.4.146.55.337.15.192.225.405.225.638 0 .333-.112.587-.337.762-.225.175-.513.246-.863.213A6.759 6.759 0 0 0 11 5c-1.967 0-3.625.675-4.975 2.025C4.675 8.375 4 10.033 4 12c0 1.967.675 3.625 2.025 4.975C7.375 18.325 9.033 19 11 19c1.95 0 3.596-.667 4.938-2 1.341-1.333 2.029-2.975 2.062-4.925h.013H18c0-.283.096-.533.288-.75A.912.912 0 0 1 19 11c.267 0 .5.096.7.287.2.192.3.413.3.663V12c0 2.517-.871 4.646-2.613 6.387C15.646 20.129 13.517 21 11 21Zm8-12a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 8V6h-2a.967.967 0 0 1-.712-.287A.968.968 0 0 1 15 5c0-.283.096-.521.288-.713A.967.967 0 0 1 16 4h2V2c0-.283.096-.521.288-.713A.967.967 0 0 1 19 1c.283 0 .52.096.712.287.192.192.288.43.288.713v2h2c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 22 6h-2v2c0 .283-.096.52-.288.712A.965.965 0 0 1 19 9Zm-5.9 6.5-2.8-2.8a1.03 1.03 0 0 1-.3-.725V8c0-.283.096-.521.288-.713A.967.967 0 0 1 11 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 12 8v3.6l2.5 2.5a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Z" })
  }
);
export {
  A as default
};
