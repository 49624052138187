/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Norway = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 3.75H.75v17.1h22.5V3.75H1Z"
      stroke="#000"
      strokeOpacity={0.2}
      strokeWidth={0.5}
    />
    <path d="M1 4h22v16.6H1V4Z" fill="#ED2939" />
    <path d="M7.188 4h4.125v16.6H7.186V4Z" fill="#fff" />
    <path d="M1 10.225h22v4.15H1v-4.15Z" fill="#fff" />
    <path d="M8.219 4h2.062v16.6H8.22V4Z" fill="#002664" />
    <path d="M1 11.262h22v2.075H1v-2.075Z" fill="#002664" />
  </svg>
);

export default Norway;
