import { jsx as e } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.875 21.125A2.893 2.893 0 0 0 5 22h14c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 22 19V5c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 19 2H5c-.833 0-1.542.292-2.125.875A2.893 2.893 0 0 0 2 5v14c0 .833.292 1.542.875 2.125ZM8 10c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 6 8c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 8 6c.55 0 1.021.196 1.413.588C9.804 6.979 10 7.45 10 8c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 8 10Zm9.35 7.188a.5.5 0 0 1-.39.812H7.207a.5.5 0 0 1-.353-.854l2.795-2.795a.5.5 0 0 1 .705-.002l1.446 1.426 1.81-2.283a.5.5 0 0 1 .782-.002l2.958 3.698Z"
      }
    )
  }
);
export {
  t as default
};
