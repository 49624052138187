import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { TOAST_EVENT, TOAST_POSITION } from "./constants";
import ToastBanner from "./ToastBanner";

/**
 *
 * Toast container to be used at the root of the app
 *
 */

const ToastContainer = ({ position, delay }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState();
  const nextToastProps = useRef();

  useEffect(() => {
    let delayTimer;
    /* 
      if showToast is false there's nothing to do. delayTimer 
      is already cleared in previous return value if needed.
    */
    if (showToast) {
      const newToastProps = nextToastProps.current;
      delayTimer = setTimeout(
        () => {
          setShowToast(false);
        },
        typeof newToastProps.delay === "number" ? newToastProps.delay : delay
      );
      setToastProps(newToastProps);
    }

    return () => {
      // Clear timeout on every showToast value change
      clearTimeout(delayTimer);
    };
  }, [showToast, delay]);

  useEffect(() => {
    let transitionTimer;

    const showHandler = (event) => {
      if (event.detail && typeof event.detail === "object") {
        const nextProps = { ...event.detail };

        // Backwards compability with undo and undoText detail keys
        if (typeof nextProps.undo === "function" && nextProps.undoText) {
          nextProps.actionHandler = nextProps.undo;
          nextProps.actionLabel = nextProps.undoText;
          delete nextProps.undo;
          delete nextProps.undoText;
        }

        // Backwards compability with message detail key
        if (!nextProps.title && nextProps.message) {
          nextProps.title = nextProps.message;
          delete nextProps.message;
        }

        nextToastProps.current = nextProps;
        clearTimeout(transitionTimer);
        transitionTimer = setTimeout(() => {
          setShowToast(true);
        }, 300);
      }
      setShowToast(false);
    };

    const hideHandler = () => {
      clearTimeout(transitionTimer);
      nextToastProps.current = undefined;
      setShowToast(false);
    };

    window.addEventListener(TOAST_EVENT.SHOW, showHandler);
    window.addEventListener(TOAST_EVENT.HIDE, hideHandler);
    return () => {
      window.removeEventListener(TOAST_EVENT.SHOW, showHandler);
      window.removeEventListener(TOAST_EVENT.HIDE, hideHandler);
      clearTimeout(transitionTimer);
    };
  }, []);

  return (
    <ToastBanner
      position={position}
      closeHandler={() => setShowToast(false)}
      {...toastProps}
      show={!!showToast}
    />
  );
};

ToastContainer.propTypes = {
  delay: PropTypes.number,
  position: PropTypes.oneOf(Object.values(TOAST_POSITION)),
};

ToastContainer.defaultProps = {
  delay: 5000,
  position: TOAST_POSITION.LEFT,
};

export default ToastContainer;
