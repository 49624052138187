import { jsx as c } from "react/jsx-runtime";
const t = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M7 18c-1.1 0-2.042-.392-2.825-1.175C3.392 16.042 3 15.1 3 14s.392-2.042 1.175-2.825C4.958 10.392 5.9 10 7 10s2.042.392 2.825 1.175C10.608 11.958 11 12.9 11 14s-.392 2.042-1.175 2.825C9.042 17.608 8.1 18 7 18Zm0-2c.55 0 1.02-.196 1.412-.588C8.804 15.021 9 14.55 9 14c0-.55-.196-1.021-.588-1.413A1.925 1.925 0 0 0 7 12c-.55 0-1.02.196-1.412.587A1.927 1.927 0 0 0 5 14c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm4-8c.55 0 1.021-.196 1.413-.588C12.804 7.021 13 6.55 13 6c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 11 4c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 9 6c0 .55.196 1.02.588 1.412C9.979 7.804 10.45 8 11 8Zm0 2c-1.1 0-2.042-.392-2.825-1.175C7.392 8.042 7 7.1 7 6s.392-2.042 1.175-2.825C8.958 2.392 9.9 2 11 2s2.042.392 2.825 1.175C14.608 3.958 15 4.9 15 6s-.392 2.042-1.175 2.825C13.042 9.608 12.1 10 11 10Zm6 9c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.02-.587-1.412A1.927 1.927 0 0 0 17 15c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 15 17c0 .55.196 1.021.588 1.413.391.391.862.587 1.412.587Zm0 2c-1.1 0-2.042-.392-2.825-1.175C13.392 19.042 13 18.1 13 17s.392-2.042 1.175-2.825C14.958 13.392 15.9 13 17 13s2.042.392 2.825 1.175C20.608 14.958 21 15.9 21 17s-.392 2.042-1.175 2.825C19.042 20.608 18.1 21 17 21Z" })
  }
);
export {
  t as default
};
