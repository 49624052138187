import { jsxs as t, jsx as h } from "react/jsx-runtime";
const a = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ h(
        "path",
        {
          d: "m14.5 6.5 3.016.57L20 6.5 14.5 1l-.759 2.607L14.5 6.5Z",
          fill: "#56368A"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M14.5 6.5V1h-9A1.5 1.5 0 0 0 4 2.5v19A1.5 1.5 0 0 0 5.5 23h13a1.5 1.5 0 0 0 1.5-1.5v-15h-5.5Z",
          fill: "#7248B9"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M8.25 16.875a.75.75 0 1 1 .001-1.501.75.75 0 0 1-.001 1.501Zm0-3a.75.75 0 1 1 .001-1.501.75.75 0 0 1-.001 1.501Zm0-3a.75.75 0 1 1 .001-1.501.75.75 0 0 1-.001 1.501Zm8.25 5.875h-6.25V15.5h6.25v1.25Zm0-3h-6.25V12.5h6.25v1.25Zm0-3h-6.25V9.5h6.25v1.25Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  a as default
};
