import { jsx as c } from "react/jsx-runtime";
const A = (C) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...C,
    children: /* @__PURE__ */ c("path", { d: "M15.5 5.5c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 13.5 3.5c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 15.5 1.5c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 15.5 5.5ZM12 19a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 18v-4l-3.2-2.8c-.233-.2-.392-.408-.475-.625A2.159 2.159 0 0 1 7.2 9.8c0-.3.046-.554.138-.763.091-.208.245-.42.462-.637l2.8-2.8c.217-.217.446-.371.688-.463A2.28 2.28 0 0 1 12.1 5c.3 0 .571.046.813.137.241.092.47.246.687.463l1.9 1.9c.367.367.77.675 1.212.925.442.25.938.417 1.488.5.267.05.483.167.65.35.167.183.25.4.25.65 0 .317-.108.571-.325.763a.924.924 0 0 1-.75.237 6.953 6.953 0 0 1-2.213-.7A7.3 7.3 0 0 1 14 8.9l-.8-.8-2.4 2.4 1.65 1.725c.183.183.321.396.413.638.091.241.137.487.137.737V18c0 .283-.096.52-.287.712A.968.968 0 0 1 12 19Zm-7 3c-1.417 0-2.604-.48-3.562-1.438C.479 19.604 0 18.417 0 17c0-1.417.48-2.604 1.438-3.562C2.396 12.479 3.583 12 5 12c1.417 0 2.604.48 3.562 1.438C9.521 14.396 10 15.583 10 17c0 1.417-.48 2.604-1.438 3.562C7.604 21.521 6.417 22 5 22Zm0-1.5c.95 0 1.771-.346 2.463-1.038C8.154 18.771 8.5 17.95 8.5 17c0-.95-.346-1.771-1.037-2.463C6.771 13.846 5.95 13.5 5 13.5c-.95 0-1.77.346-2.462 1.037C1.846 15.229 1.5 16.05 1.5 17c0 .95.346 1.77 1.038 2.462C3.229 20.154 4.05 20.5 5 20.5ZM19 22c-1.417 0-2.604-.48-3.562-1.438C14.479 19.604 14 18.417 14 17c0-1.417.48-2.604 1.438-3.562C16.396 12.479 17.583 12 19 12c1.417 0 2.604.48 3.562 1.438C23.521 14.396 24 15.583 24 17c0 1.417-.48 2.604-1.438 3.562C21.604 21.521 20.417 22 19 22Zm0-1.5c.95 0 1.77-.346 2.462-1.038.692-.691 1.038-1.512 1.038-2.462 0-.95-.346-1.771-1.038-2.463C20.771 13.846 19.95 13.5 19 13.5c-.95 0-1.77.346-2.462 1.037-.692.692-1.038 1.513-1.038 2.463 0 .95.346 1.77 1.038 2.462.691.692 1.512 1.038 2.462 1.038Z" })
  }
);
export {
  A as default
};
