import { jsx as a } from "react/jsx-runtime";
const s = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M21 7a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2h16a1 1 0 0 1 1 1Zm-7.75 10.306c.18-.612-.236-1.306-.874-1.306H11a1 1 0 1 0 0 2h1.387c.405 0 .748-.305.863-.694ZM18 12a1 1 0 0 0-1-1H7a1 1 0 1 0 0 2h10a1 1 0 0 0 1-1Zm1 3a1 1 0 0 0-1 1v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2a1 1 0 0 0-1-1Z" })
  }
);
export {
  s as default
};
