import { jsx as c } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M8.275 11c-.383 0-.671-.17-.863-.511a.947.947 0 0 1 .013-1.013L11.15 3.4a.95.95 0 0 1 .85-.475.95.95 0 0 1 .85.475l3.725 6.075c.2.333.204.67.012 1.013-.191.341-.479.512-.862.512h-7.45ZM17.5 22c-1.25 0-2.313-.437-3.188-1.311C13.437 19.813 13 18.75 13 17.5c0-1.25.437-2.313 1.312-3.188S16.25 13 17.5 13c1.25 0 2.313.437 3.188 1.312S22 16.25 22 17.5c0 1.25-.437 2.312-1.312 3.188C19.813 21.563 18.75 22 17.5 22ZM4 21.5a.965.965 0 0 1-.712-.287A.965.965 0 0 1 3 20.5v-6c0-.284.096-.521.288-.713A.967.967 0 0 1 4 13.5h6a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v6c0 .283-.096.52-.287.712A.968.968 0 0 1 10 21.5H4Z" })
  }
);
export {
  a as default
};
