import * as React from "react";
const SvgIconFileVideoColor = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Zm5.5 4.16v5.58a1 1 0 0 0 1.512.86l4.96-2.954a1 1 0 0 0-.044-1.743l-4.96-2.626a1 1 0 0 0-1.468.884Z"
      fill="#8997A1"
    />
  </svg>
);
export default SvgIconFileVideoColor;
