import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M4 18h17v-6H4v6zM4 5v6h17V5H4z" }),
  /* @__PURE__ */ e.createElement("path", { d: "M0 0h24v24H0z", fill: "none" })
);
export {
  a as default
};
