/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const FolderMultiple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 17c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 5 15V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 7 2h4.175a1.978 1.978 0 0 1 1.4.575L14 4h7c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 21 17H7Z"
      fill="#FBBC04"
    />
    <path
      d="M1.588 20.413C1.979 20.804 2.45 21 3 21h16c.283 0 .52-.096.712-.288A.965.965 0 0 0 20 20a.965.965 0 0 0-.288-.712A.965.965 0 0 0 19 19H3V7a.97.97 0 0 0-.287-.713A.97.97 0 0 0 2 6a.967.967 0 0 0-.712.287A.968.968 0 0 0 1 7v12c0 .55.196 1.021.588 1.413Z"
      fill="#D39E03"
    />
  </svg>
);

export default FolderMultiple;
