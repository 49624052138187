import React from "react";
import styled from "styled-components";
import ImageGrid from "./ImageGrid";

export const ImageGridExample = () => {
  const images = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      alt: "This is alt yo",
    },
    {
      src: "https://source.unsplash.com/collection/190727/1600x900",
      alt: "Image about stuff",
    },
    {
      src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
      alt: "More alt stuff",
    },
    {
      src: "https://source.unsplash.com/random/400x400",
      alt: "There should always be alt",
    },
    {
      src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
      alt: "A wide image",
    },
  ];

  return (
    <ImageGrid
      images={images}
      direction="row"
      columns={2}
      maxImages={5}
      onClickImage={({ image, index, event }) => {
        console.log(image, index, event);
      }}
    />
  );
};

export const ImageGridSmallExample = () => {
  const images = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      alt: "This is alt yo",
    },
    {
      src: "https://source.unsplash.com/random/500x500",
      alt: "Image about stuff",
    },
    {
      src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
      alt: "More alt stuff",
    },
    {
      src: "https://source.unsplash.com/random/600x600",
      alt: "There should always be alt",
    },
    {
      src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
      alt: "A wide image",
    },
  ];

  return (
    <SmallContainer>
      <ImageGrid
        images={images}
        direction="row"
        columns={2}
        maxImages={3}
        onClickImage={({ image, index, event }) => {
          console.log(image, index, event);
        }}
      />
    </SmallContainer>
  );
};

export const ImageGridTargetHeightExample = () => {
  const images = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      alt: "This is alt yo",
    },
    {
      src: "https://source.unsplash.com/random/300x300",
      alt: "Image about stuff",
    },
    {
      src: "https://source.unsplash.com/random/600x500",
      alt: "More alt stuff",
    },
    {
      src: "https://source.unsplash.com/random/400x400",
      alt: "There should always be alt",
    },
    {
      src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
      alt: "A wide image",
    },
  ];

  return (
    <ImageGrid
      images={images}
      maxImages={3}
      maxImageHeight="208px"
      targetRowHeight={1}
      onClickImage={({ image, index, event }) => {
        console.log(image, index, event);
      }}
    />
  );
};

const SmallContainer = styled.div`
  width: 400px;
`;

export const ImageGridBrokenExample = () => {
  const images = [
    {
      src: "ERROR-THIS-IS-NOT-A-SRC!",
    },
    {
      src: "ERROR-THIS-IS-NOT-A-SRC!",
    },
    {
      src: "ERROR-THIS-IS-NOT-A-SRC!",
    },
  ];

  return (
    <ImageGrid
      images={images}
      direction="row"
      columns={2}
      maxImages={5}
      onClickImage={({ image, index, event }) => {
        console.log(image, index, event);
      }}
    />
  );
};
