import * as c from "react";
const e = (a) => /* @__PURE__ */ c.createElement(
  "svg",
  {
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ c.createElement(
    "path",
    {
      d: "M13 10.286c0 1.652-1.348 3-3 3s-3-1.348-3-3c0-1.653 1.348-3 3-3s3 1.347 3 3Zm6-1.278c0-.199-.14-.386-.34-.422l-2.144-.328a6.455 6.455 0 0 0-.48-1.148c.398-.55.831-1.066 1.23-1.606a.442.442 0 0 0 .011-.527c-.492-.691-1.312-1.418-1.933-1.992a.462.462 0 0 0-.293-.117.4.4 0 0 0-.281.105l-1.665 1.254a6.88 6.88 0 0 0-1.054-.433l-.328-2.157c-.024-.199-.211-.351-.422-.351H8.699a.43.43 0 0 0-.422.328c-.187.703-.257 1.465-.34 2.18-.363.117-.726.269-1.066.445L5.254 2.985a.521.521 0 0 0-.305-.117c-.398 0-1.98 1.71-2.261 2.097a.454.454 0 0 0-.106.27c0 .105.047.2.117.281.434.528.856 1.055 1.254 1.617a6.2 6.2 0 0 0-.457 1.078l-2.18.329C1.141 8.575 1 8.786 1 8.96v2.602c0 .2.14.387.34.422l2.144.316c.118.399.282.785.48 1.16-.398.551-.831 1.067-1.23 1.606a.456.456 0 0 0-.012.54c.493.679 1.313 1.405 1.934 1.968a.42.42 0 0 0 .293.129.45.45 0 0 0 .293-.106l1.653-1.254c.34.176.69.317 1.054.434l.328 2.156c.024.2.211.352.422.352h2.602a.43.43 0 0 0 .422-.328c.187-.704.257-1.465.34-2.18.363-.117.726-.27 1.066-.445l1.617 1.265c.094.059.2.106.305.106.398 0 1.98-1.723 2.261-2.098a.391.391 0 0 0 .106-.27.462.462 0 0 0-.117-.292 24.713 24.713 0 0 1-1.254-1.618 6.64 6.64 0 0 0 .457-1.066l2.168-.328c.187-.035.328-.246.328-.422V9.008Z",
      fillRule: "evenodd"
    }
  )
);
export {
  e as default
};
