import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2 11.843c0 2.48.908 4.752 2.409 6.501.03.044.066.085.107.123a9.98 9.98 0 0 0 10.371 2.95 10.046 10.046 0 0 0 6.712-6.77c.261-.89.401-1.83.401-2.804a9.955 9.955 0 0 0-2.376-6.463.798.798 0 0 0-.124-.144A9.98 9.98 0 0 0 12 1.843c-4.514 0-8.34 3.008-9.579 7.124a.825.825 0 0 0-.017.06l-.002.006A9.968 9.968 0 0 0 2 11.843Zm13.553 7.597a8.433 8.433 0 0 0 4.27-4.57c-1.645-.805-3.18-1.177-4.599-1.24.33 1.935.44 3.873.33 5.81Zm-.654-7.417c1.768.017 3.57.437 5.363 1.27.082-.471.125-.956.125-1.45a8.341 8.341 0 0 0-1.484-4.76A12.11 12.11 0 0 1 14.344 10c.212.674.397 1.349.555 2.024Zm-2.102-1.567c.175.555.33 1.111.467 1.668-2.92.389-5.705 1.903-8.154 4.497a8.342 8.342 0 0 1-1.497-4.778c0-.56.055-1.108.16-1.637 1.885.437 3.664.653 5.324.653a16.33 16.33 0 0 0 3.7-.403Zm1.018-1.992a10.539 10.539 0 0 0 4.043-2.619 8.36 8.36 0 0 0-6.42-2.37 33.47 33.47 0 0 1 2.377 4.989ZM9.739 3.765a32.337 32.337 0 0 1 2.521 5.148c-2.322.508-5.013.427-8.019-.256a8.423 8.423 0 0 1 5.498-4.892Zm3.859 9.93c.378 2.107.478 4.216.297 6.319a8.388 8.388 0 0 1-7.732-2.154c1.623-1.748 4.153-3.76 7.435-4.165Z"
      }
    )
  }
);
export {
  l as default
};
