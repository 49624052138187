import * as React from "react";
const SvgIconFilePeople = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 28"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={28} fill="#EC4B2F" rx={2} />
      <g fill="#FFF">
        <path
          fillOpacity={0.75}
          fillRule="nonzero"
          d="M25.085 19.467a.45.45 0 0 0 .179.028c1.49-.002 2.981-.001 4.472-.002.089 0 .178-.011.264-.017.019-1.2-.866-2.246-2.126-2.541-.304-.071-.613-.123-.913-.208-.798-.225-.99-.594-.743-1.4.704-.066 1.428-.071 2.042-.505-.594-1.345-.598-1.381-.57-2.916.01-.55.03-1.102-.005-1.65-.106-1.714-1.157-2.73-2.817-2.755-1.854-.029-2.92.919-3.06 2.761-.055.724-.04 1.454-.059 2.182-.017.667-.059 1.33-.36 1.945-.227.463-.224.533.269.684.511.158 1.044.25 1.578.372.098.994.035 1.003-.759 1.214-.16.043-.327.064-.488.107-.13.035-.27.067-.376.142a.396.396 0 0 0-.059.05c1.507.403 2.748 1.31 3.531 2.51Zm-14.631-2.462c-.037-.058-.077-.11-.129-.131a6.74 6.74 0 0 0-.917-.293c-.654-.165-.665-.171-.704-.851-.02-.36.022-.358.365-.417.517-.09 1.025-.233 1.532-.372.176-.048.25-.177.132-.36-.408-.638-.499-1.354-.502-2.09-.003-.623.013-1.247-.004-1.87-.047-1.835-.938-2.893-2.653-3.077-1.491-.16-2.857.41-3.183 2.178-.126.685-.087 1.404-.094 2.109-.009.954.076 1.92-.483 2.778-.099.152-.056.284.117.347.291.106.581.228.883.29.32.067.653.07.987.102.01.135.025.26.028.383.015.625-.092.785-.694.965-.328.099-.665.166-1 .238-1.26.27-2.19 1.369-2.132 2.56h.3c1.46 0 2.918-.007 4.377.006a.61.61 0 0 0 .23-.036c.255-.394.566-.76.934-1.097.75-.688 1.56-1.084 2.61-1.362Z"
        />
        <path d="M13.115 13.67c-.274-.003-.422-.173-.479-.404a24.35 24.35 0 0 1-.355-1.665c-.035-.21.009-.443.288-.491a6.054 6.054 0 0 1-.267-2.366c.119-1.48.902-2.46 2.237-2.958 1.235-.462 2.455-.372 3.596.325 1.06.648 1.58 1.65 1.587 2.894.004.69-.124 1.38-.197 2.11.205.055.23.302.186.545-.1.539-.22 1.074-.349 1.607-.056.233-.195.406-.457.403-.047.354-.083.696-.14 1.034-.067.403-.22.76-.5 1.072a.94.94 0 0 0-.207.544c-.017.34.032.685.054 1.028.022.338.2.547.514.626.724.18 1.448.365 2.177.529 1.844.414 3.136 1.948 3.196 3.812.002.057 0 .114 0 .185H8c.026-1.166.396-2.16 1.227-2.955.696-.665 1.564-.921 2.467-1.104.507-.102 1.01-.227 1.51-.356.482-.124.72-.452.724-.961 0-.138-.002-.277.016-.412.053-.386-.01-.708-.284-1.023-.437-.505-.434-1.177-.526-1.806-.008-.058-.01-.117-.02-.213Z" />
      </g>
    </g>
  </svg>
);
export default SvgIconFilePeople;
