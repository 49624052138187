import * as React from "react";
const SvgIconAddImage = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M20 4.004h2.02c.54 0 .98.44.98.98v.04c0 .542-.44.982-.98.982H20V8.02c0 .54-.44.98-.98.98h-.03a.978.978 0 0 1-.99-.98V6.006h-2.01c-.55 0-.99-.44-.99-.991v-.03c.01-.54.45-.981.99-.981H18V1.98c0-.54.44-.981.99-.981h.03c.54 0 .98.44.98.981v2.023ZM19 11c.34 0 .675-.028 1-.083V18c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h9.083A6 6 0 0 0 19 11Zm-9 4.51-2.1-2.53a.493.493 0 0 0-.78.02l-2.49 3.2c-.26.33-.03.81.39.81h11.99a.5.5 0 0 0 .4-.8l-3.51-4.68c-.2-.27-.6-.27-.8-.01L10 15.51Z" />
  </svg>
);
export default SvgIconAddImage;
