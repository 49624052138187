import * as React from "react";
const SvgIconFileMsWord = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2Zm9 14h1.5l2.4-10h-1.7l-1.53 7.5L12.7 7h-1.4l-1.96 7.5L7.8 7H6.1l2.4 10H10l2-7.5 2 7.5Z"
    />
  </svg>
);
export default SvgIconFileMsWord;
