import React, { useEffect } from "react";

export const toCamelCase = (str) => {
  return str.replace(/(_|-|,|\.)(.)/g, (match) => match[1].toUpperCase());
};

const HASHTAG_MAX_LENGTH = 64;

export const sanitiseHashtag = (hashtag) => {
  if (typeof hashtag !== "string" || hashtag.length === 0) {
    return "";
  }
  const regex = new RegExp(
    /[^\p{Alphabetic}\p{Mark}\p{Decimal_Number}\p{Connector_Punctuation}\p{Join_Control}]/u
  );

  const withoutInitialHashSign = hashtag.replace(/^#/, "");
  const withoutConsecutiveUnderscores = withoutInitialHashSign.replace(
    /_+/g,
    "_"
  );
  const match = withoutConsecutiveUnderscores.match(regex);
  const firstDisallowedCharIndex = match
    ? match.index
    : withoutConsecutiveUnderscores.length;

  const finalStringWithoutHashSign = withoutConsecutiveUnderscores
    .substring(0, Math.min(firstDisallowedCharIndex, HASHTAG_MAX_LENGTH))
    .toLocaleLowerCase();
  if (finalStringWithoutHashSign.length === 0) {
    return "";
  }
  return "#" + finalStringWithoutHashSign;
};

export const isString = (value) => typeof value === "string";

export const getValidChildrenArray = (children) =>
  React.Children.toArray(children).filter(React.isValidElement);

export const devWarning = (message) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.warn(`HappeoUiKit/form-elements: ${message}`);
  }
};

export const useDevWarningOnce = (shouldWarn, message) => {
  useEffect(() => {
    if (shouldWarn) {
      devWarning(message);
    }
    // eslint-disable-next-line
  }, []);
};

export const uuidv4 = () => {
  // Always return starting with non-number
  return "ixxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
