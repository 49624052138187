import { jsx as t } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M8 8v8-8Zm0 10c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 6 16V8c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 8 6h8c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v8c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 16 18H8Zm0-2h8V8H8v8Z" })
  }
);
export {
  c as default
};
