import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M15 9h3l-5-5v3c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm3 10.425L16.6 20.8a.977.977 0 0 1-.688.287.93.93 0 0 1-.712-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l1.4-1.4-1.4-1.4a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l1.4 1.4 1.4-1.4a.977.977 0 0 1 .688-.287.93.93 0 0 1 .712.287.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L19.425 18l1.375 1.4c.183.183.28.413.287.688a.93.93 0 0 1-.287.712.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275L18 19.425ZM6 22c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 20V4c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 6 2h7.175a1.975 1.975 0 0 1 1.4.575l4.85 4.85a1.975 1.975 0 0 1 .575 1.4v2.725c0 .2-.08.358-.238.475a.613.613 0 0 1-.537.1 5.801 5.801 0 0 0-2.925.1 5.91 5.91 0 0 0-2.55 1.525 6.083 6.083 0 0 0-1.275 1.887A5.775 5.775 0 0 0 12 17.976c0 .483.058.967.175 1.45.117.483.292.942.525 1.375.15.283.158.554.025.813A.66.66 0 0 1 12.1 22H6Z" })
  }
);
export {
  t as default
};
