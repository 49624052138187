/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraAccessRequest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z"
      fill="#F79232"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 10.5V9.014a1.499 1.499 0 1 0-3 0V10.5h3Zm-4.5 0h-.014A1.5 1.5 0 0 0 7.5 12v4.5c0 .834.665 1.5 1.486 1.5h6.028a1.5 1.5 0 0 0 1.486-1.5V12c0-.834-.665-1.5-1.486-1.5H15V9.009A2.993 2.993 0 0 0 12 6c-1.668 0-3 1.347-3 3.009V10.5Z"
      fill="#fff"
    />
  </svg>
);

export default JiraAccessRequest;
