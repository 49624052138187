import React from "react";
import styled from "styled-components";

export const DocsButtonWrapper = styled.div<{ icon: boolean }>`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  ${({ icon }) =>
    !icon &&
    `button {
    width: 130px;
  }`}
`;
