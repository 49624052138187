import { jsx as e } from "react/jsx-runtime";
const o = (c) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3.44 19.173c.326.538.93.827 1.558.827h14.004c.629 0 1.232-.29 1.558-.827A9.954 9.954 0 0 0 22 14c0-5.523-4.477-10-10-10S2 8.477 2 14c0 1.893.526 3.663 1.44 5.173ZM13.55 15.5c-.4.4-.917.596-1.55.588-.633-.009-1.1-.238-1.4-.688L6.375 9.076c-.15-.233-.13-.446.062-.637.192-.192.405-.213.638-.063l6.325 4.225c.45.3.688.758.713 1.375.024.617-.163 1.125-.563 1.525Z"
      }
    )
  }
);
export {
  o as default
};
