import { jsx as e } from "react/jsx-runtime";
const c = (o) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M21.007 10.5c0-5.246-4.261-9.5-9.507-9.5A9.5 9.5 0 0 0 2 10.5c0 4.97 4.53 9 9.5 9V23c5.772-2.927 9.507-7.639 9.507-12.5Zm-4.2-3.156L14.58 9.567V8.15c0-.598-.49-1.087-1.088-1.087H7.865c-.599 0-1.088.49-1.088 1.087v4.533c0 .598.49 1.088 1.088 1.088h5.627c.598 0 1.088-.49 1.088-1.088v-1.431l2.228 2.228V7.344Z"
      }
    )
  }
);
export {
  c as default
};
