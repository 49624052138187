import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M19.852 18.985H4.126c-.61 0-1.124-.473-1.124-1.067V7.128c0-.58.498-1.067 1.124-1.067h15.726c.61 0 1.124.472 1.124 1.067v10.79c0 .594-.498 1.067-1.124 1.067Z",
      fill: "#E3E3E3"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      opacity: 0.1,
      d: "m5.249 18.985 6.724-5.136.048-.275-6.933-4.74-.016 9.922.177.229Z",
      fill: "#231F20"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M4.126 18.985c-.626 0-1.124-.473-1.124-1.067V7.113c0-.595.498-.702 1.124-.702.626 0 1.123.122 1.123.702v11.872H4.126Z",
      fill: "#D54B3D"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M4.126 6.564c.802 0 .963.229.963.549v11.72h-.963c-.53 0-.963-.412-.963-.915V7.113c0-.336.16-.55.963-.55Zm0-.152c-.626 0-1.124.121-1.124.7v10.806c0 .594.498 1.067 1.124 1.067h1.123V7.113c0-.595-.497-.702-1.123-.702Z",
      fill: "#D72B27"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M19.852 18.985H18.73V7.082c0-.594.497-.67 1.123-.67s1.124.076 1.124.67v10.851c0 .58-.498 1.052-1.124 1.052Z",
      fill: "#D54B3D"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M19.852 6.564c.722 0 .963.137.963.518v10.851c0 .503-.433.915-.963.915h-.963V7.082c0-.396.241-.518.963-.518Zm0-.152c-.626 0-1.123.076-1.123.67V19h1.123c.626 0 1.124-.472 1.124-1.067V7.083c0-.595-.498-.671-1.124-.671Z",
      fill: "#D72B27"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M15.038 18.985 3.096 7.555l.628.243 8.313 5.685 8.939-6.225v10.675c0 .58-.498 1.052-1.124 1.052h-4.814Z",
      fill: "url(#Icon-logo-gmail_svg__a)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m11.973 13.85-8.49-5.853c-.497-.35-.641-1.021-.272-1.494.37-.472 1.091-.579 1.605-.228l7.173 4.953 7.221-5.014a1.155 1.155 0 0 1 1.573.244c.37.472.257 1.143-.257 1.493l-8.553 5.898Z",
      fill: "#D54B3D"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M19.852 6.152c.305 0 .594.138.787.381a.905.905 0 0 1-.209 1.28l-8.457 5.853L3.58 7.875c-.433-.305-.546-.884-.24-1.28a1.03 1.03 0 0 1 .802-.382.96.96 0 0 1 .577.183l7.158 4.938.096.061.096-.06 7.206-5.015c.176-.107.369-.168.577-.168Zm0-.152c-.224 0-.465.061-.658.198l-7.221 5.014L4.8 6.26a1.16 1.16 0 0 0-.674-.198c-.353 0-.706.152-.931.442-.353.472-.209 1.143.289 1.493l8.489 5.868 8.537-5.913a1.05 1.05 0 0 0 .257-1.494A1.118 1.118 0 0 0 19.852 6Z",
      fill: "#D72B27"
    }
  ),
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-logo-gmail_svg__a",
      x1: 3.097,
      y1: 13.121,
      x2: 20.981,
      y2: 13.121,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { stopOpacity: 0.1 }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopOpacity: 0.2 })
  ))
);
export {
  l as default
};
