import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M2.725 17.75A1.03 1.03 0 0 1 2.4 17c0-.283.108-.533.325-.75l6.05-6.05c.2-.2.433-.3.7-.3.267 0 .5.1.7.3l3.3 3.3 6.4-7.225a.89.89 0 0 1 .713-.325c.291 0 .537.1.737.3a.95.95 0 0 1 .288.662.943.943 0 0 1-.263.688l-7.175 8.1a.928.928 0 0 1-.712.338.963.963 0 0 1-.738-.288l-3.25-3.25-5.25 5.25a1.03 1.03 0 0 1-.75.325 1.03 1.03 0 0 1-.75-.325Z" })
  }
);
export {
  h as default
};
