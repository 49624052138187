import { jsxs as t, jsx as e } from "react/jsx-runtime";
const d = (s) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: [
      /* @__PURE__ */ e("path", { d: "M13.5 9.014V10.5h-3V9.014a1.499 1.499 0 1 1 3 0Z" }),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm4.13 8.5H9V9.009A2.997 2.997 0 0 1 12 6c1.657 0 3 1.33 3 3.009V10.5h.014c.82 0 1.486.666 1.486 1.5v4.5a1.5 1.5 0 0 1-1.486 1.5H8.986A1.49 1.49 0 0 1 7.5 16.5V12a1.5 1.5 0 0 1 1.486-1.5Z"
        }
      )
    ]
  }
);
export {
  d as default
};
