import { jsx as c } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M6.5 20c-1.533 0-2.833-.533-3.9-1.6C1.533 17.333 1 16.033 1 14.5c0-1.217.446-2.271 1.338-3.163C3.229 10.446 4.283 10 5.5 10c1.067 0 2.013.35 2.838 1.05.825.7 1.345 1.558 1.562 2.575.05.25.171.458.363.625.191.167.404.25.637.25.333 0 .6-.113.8-.338a.869.869 0 0 0 .225-.762c-.233-1.433-.858-2.625-1.875-3.575C9.033 8.875 7.767 8.267 6.25 8c.3-1.233.979-2.208 2.037-2.925C9.346 4.358 10.583 4 12 4c1.967 0 3.625.675 4.975 2.025C18.325 7.375 19 9.033 19 11c1.05 0 1.98.467 2.788 1.4.808.933 1.212 1.967 1.212 3.1 0 1.25-.437 2.313-1.312 3.188S19.75 20 18.5 20h-12Z" })
  }
);
export {
  o as default
};
