import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm1-8.425 3.3-3.3c.1-.1.208-.171.325-.213C9.742 9.021 9.867 9 10 9s.258.02.375.062a.883.883 0 0 1 .325.213l3.3 3.3 3.3-3.3c.1-.1.208-.171.325-.213.117-.041.242-.062.375-.062s.258.02.375.062a.883.883 0 0 1 .325.213l.3.3V5H5v6.575l1 1ZM5 19h14v-6.6l-1-1-3.3 3.3a.917.917 0 0 1-.312.2c-.125.05-.255.075-.388.075a.942.942 0 0 1-.375-.075 1.315 1.315 0 0 1-.325-.2L10 11.4l-3.3 3.3c-.1.1-.208.17-.325.212a1.099 1.099 0 0 1-.375.063c-.133 0-.258-.021-.375-.063A.871.871 0 0 1 5.3 14.7l-.3-.3V19Zm0 0v-6.6 2V5v14Z" })
  }
);
export {
  t as default
};
