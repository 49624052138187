import * as React from "react";
const SvgIconAddFile = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2a2 2 0 0 0-2 2v9.083A6 6 0 0 1 11.917 20H17a2 2 0 0 0 2-2V9.828a2 2 0 0 0-.586-1.414l-5.828-5.828A2 2 0 0 0 11.172 2H7Zm8.586 8H12a1 1 0 0 1-1-1V5.414c0-.89 1.077-1.337 1.707-.707l3.586 3.586c.63.63.184 1.707-.707 1.707ZM7 18.004h2.02c.54 0 .98.44.98.98v.04c0 .542-.44.982-.98.982H7v2.013c0 .54-.44.98-.98.98h-.03a.978.978 0 0 1-.99-.98v-2.013H2.99c-.55 0-.99-.44-.99-.991v-.03c.01-.54.45-.981.99-.981H5V15.98c0-.54.44-.981.99-.981h.03c.54 0 .98.44.98.981v2.023Z"
    />
  </svg>
);
export default SvgIconAddFile;
