/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftPublisherAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 3h15c.83 0 1.5.67 1.5 1.5v15c0 .83-.67 1.5-1.5 1.5h-15c-.83 0-1.5-.67-1.5-1.5v-15C3 3.673 3.67 3 4.5 3Z"
      fill="url(#microsoft-publisher-alt_svg__a)"
    />
    <path
      d="M12.141 7.102a3.907 3.907 0 0 1 2.633.792c.634.585.97 1.426.912 2.288a3.26 3.26 0 0 1-.45 1.712c-.306.506-.754.91-1.284 1.165a4.217 4.217 0 0 1-1.929.415H10.2v3.484H8.328V7.102h3.813Zm-1.945 4.866h1.613a2.043 2.043 0 0 0 1.423-.437c.338-.335.514-.8.481-1.274 0-1.086-.615-1.63-1.846-1.63h-1.67v3.341Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="microsoft-publisher-alt_svg__a"
        x1={6.127}
        y1={1.829}
        x2={17.872}
        y2={22.172}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#058F92" />
        <stop offset={0.5} stopColor="#038489" />
        <stop offset={1} stopColor="#026D71" />
      </linearGradient>
    </defs>
  </svg>
);

export default MicrosoftPublisherAlt;
