import { jsx as a } from "react/jsx-runtime";
const c = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "m3.7 18.434 2.6 2.61c.08.09.179.161.29.21.131.045.27.066.41.06.129 0 .257-.02.38-.06a.89.89 0 0 0 .32-.21l2.58-2.58a1 1 0 0 0 .3-.71.94.94 0 0 0-.3-.71.998.998 0 0 0-.72-.28.9.9 0 0 0-.68.28l-.88.87a7.72 7.72 0 0 1 .83-4 11.004 11.004 0 0 1 1.72-2l.74-.71a6.8 6.8 0 0 0 .71-.84c.213.298.45.58.71.84l.74.71a11.486 11.486 0 0 1 1.73 2 7.84 7.84 0 0 1 .82 4l-.87-.87a.938.938 0 0 0-.69-.28 1 1 0 0 0-.71.28.94.94 0 0 0-.3.71 1.001 1.001 0 0 0 .3.71l2.57 2.58c.08.09.179.161.29.21a1.1 1.1 0 0 0 .41.06c.129 0 .257-.02.38-.06a.889.889 0 0 0 .32-.21l2.6-2.61a1 1 0 0 0 .29-.68.898.898 0 0 0-.29-.71.93.93 0 0 0-.7-.28.93.93 0 0 0-.7.28l-.9.87c0-2.38-.367-4.077-1.1-5.09a14.118 14.118 0 0 0-2.1-2.51 9.182 9.182 0 0 1-1.3-1.41 3.871 3.871 0 0 1-.5-2.22v-3a.94.94 0 0 0-.29-.71.999.999 0 0 0-.71-.29 1 1 0 0 0-1 1v3a3.87 3.87 0 0 1-.5 2.22 9.178 9.178 0 0 1-1.3 1.41 14.124 14.124 0 0 0-2.1 2.46c-.667 1.006-1.034 2.703-1.1 5.09l-.9-.87a1 1 0 0 0-1.69.71 1 1 0 0 0 .29.73Z" })
  }
);
export {
  c as default
};
