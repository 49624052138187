/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const DropboxCapture = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.5 14.5h5v-10L2 12l2.5 2.5Z" fill="#2B313B" />
    <path d="M19.5 9.5 12 2 9.5 4.5v5h10Z" fill="#68778D" />
    <path d="M14.5 19.5 22 12l-2.5-2.5h-5v10Z" fill="#2B313B" />
    <path d="M4.5 14.5 12 22l2.5-2.5v-5h-10Z" fill="#68778D" />
  </svg>
);

export default DropboxCapture;
