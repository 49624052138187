export const loadImage = (src: string) => {
  return new Promise<string>((resolve, reject) => {
    const loadImg = new Image();
    loadImg.src = src;
    loadImg.onload = () => resolve(src);
    loadImg.onerror = () => {
      reject(new Error("Error on loading image."));
    };
  });
};
