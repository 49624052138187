import { jsx as c } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M19 13a.965.965 0 0 1-.712-.287A.965.965 0 0 1 18 12c0-.283.096-.521.288-.713A.967.967 0 0 1 19 11h2c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 21 13h-2Zm-.6 6.4-1.6-1.2a.913.913 0 0 1-.4-.65 1.032 1.032 0 0 1 .2-.75.913.913 0 0 1 .65-.4c.267-.032.517.034.75.2l1.6 1.2a.913.913 0 0 1 .4.65c.033.268-.033.518-.2.75a.913.913 0 0 1-.65.4 1.032 1.032 0 0 1-.75-.2Zm-.4-12a1.032 1.032 0 0 1-.75.2.913.913 0 0 1-.65-.4 1.033 1.033 0 0 1-.2-.75.913.913 0 0 1 .4-.65l1.6-1.2c.233-.166.483-.232.75-.2a.913.913 0 0 1 .65.4c.167.234.233.484.2.75a.913.913 0 0 1-.4.65L18 7.4ZM5 19v-4H4c-.55 0-1.02-.195-1.412-.587A1.923 1.923 0 0 1 2 13v-2c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 4 9h4l3.475-2.1c.333-.2.671-.2 1.013 0 .341.2.512.492.512.875v8.45c0 .383-.17.675-.512.875-.342.2-.68.2-1.013 0L8 15H7v4H5Zm9-3.65v-6.7c.45.4.813.889 1.088 1.464.275.575.412 1.204.412 1.887s-.137 1.312-.412 1.887A4.571 4.571 0 0 1 14 15.35Zm-3-.9v-4.9L8.55 11H4v2h4.55L11 14.45Z" })
  }
);
export {
  v as default
};
