import { jsx as a } from "react/jsx-runtime";
const A = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M7.207 18a.5.5 0 0 1-.353-.854l2.795-2.795a.5.5 0 0 1 .705-.002l1.446 1.426 1.81-2.283a.5.5 0 0 1 .782-.002l2.958 3.698a.5.5 0 0 1-.39.812H7.207ZM8 10c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 6 8c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 8 6c.55 0 1.021.196 1.413.588C9.804 6.979 10 7.45 10 8c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 8 10ZM5 22a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 2 19V5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 5 2h14c.833 0 1.542.292 2.125.875S22 4.167 22 5v14c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 19 22H5Zm0-2h14c.283 0 .52-.096.712-.288A.965.965 0 0 0 20 19V5a.968.968 0 0 0-.288-.713A.967.967 0 0 0 19 4H5a.97.97 0 0 0-.713.287A.97.97 0 0 0 4 5v14c0 .283.096.52.287.712.192.192.43.288.713.288Z" })
  }
);
export {
  A as default
};
