import { jsx as a } from "react/jsx-runtime";
const o = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M9.481 12.006a1.03 1.03 0 0 1-.275.7l-3.1 3.1a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7l2.4-2.4-2.4-2.4a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.284 0 .517.091.7.275l3.1 3.1c.1.1.17.208.212.325.042.116.063.241.063.375Zm9.825 3.8a.949.949 0 0 1-.7.275.948.948 0 0 1-.7-.275l-3.1-3.1a.872.872 0 0 1-.212-.325 1.1 1.1 0 0 1-.063-.375c0-.134.021-.263.063-.388a.674.674 0 0 1 .212-.312l3.125-3.125a.89.89 0 0 1 .687-.262.976.976 0 0 1 .688.287.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-2.4 2.4 2.4 2.4a.948.948 0 0 1 .275.7.949.949 0 0 1-.275.7Z" })
  }
);
export {
  o as default
};
