import { jsx as c } from "react/jsx-runtime";
const t = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M12 6c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12 2c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 6Zm-2 16a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 21V9H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 8c0-.283.096-.521.288-.713A.967.967 0 0 1 4 7h16c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 9h-5v12c0 .283-.096.52-.287.712A.968.968 0 0 1 14 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 21v-5h-2v5c0 .283-.096.52-.287.712A.968.968 0 0 1 10 22Z" })
  }
);
export {
  t as default
};
