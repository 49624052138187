import { jsx as c } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M12.5 11.95a5.686 5.686 0 0 0 1.113-1.825A5.961 5.961 0 0 0 14 8c0-.733-.13-1.442-.387-2.125A5.686 5.686 0 0 0 12.5 4.05c1 .133 1.833.575 2.5 1.325S16 7 16 8s-.333 1.875-1 2.625a3.938 3.938 0 0 1-2.5 1.325ZM17.45 20c.183-.3.32-.62.413-.962.091-.342.137-.688.137-1.038v-1c0-.6-.133-1.17-.4-1.713a5.111 5.111 0 0 0-1.05-1.437c.85.3 1.637.688 2.363 1.162C19.637 15.488 20 16.15 20 17v1c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 18 20h-.55ZM20 11h-1a.968.968 0 0 1-.712-.287A.968.968 0 0 1 18 10c0-.283.096-.52.288-.713A.968.968 0 0 1 19 9h1V8c0-.283.096-.52.288-.713A.968.968 0 0 1 21 7c.283 0 .52.096.712.287.192.192.288.43.288.713v1h1c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 23 11h-1v1c0 .283-.096.52-.288.713A.968.968 0 0 1 21 13a.968.968 0 0 1-.712-.287A.968.968 0 0 1 20 12v-1ZM8 12c-1.1 0-2.042-.392-2.825-1.175C4.392 10.042 4 9.1 4 8s.392-2.042 1.175-2.825C5.958 4.392 6.9 4 8 4s2.042.392 2.825 1.175C11.608 5.958 12 6.9 12 8s-.392 2.042-1.175 2.825C10.042 11.608 9.1 12 8 12Zm-8 6v-.8c0-.567.146-1.087.438-1.563.291-.475.679-.837 1.162-1.087a14.843 14.843 0 0 1 3.15-1.163A13.76 13.76 0 0 1 8 13c1.1 0 2.183.13 3.25.387 1.067.259 2.117.646 3.15 1.163.483.25.87.612 1.162 1.087.292.476.438.996.438 1.563v.8c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 14 20H2c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 0 18Zm8-8c.55 0 1.02-.196 1.412-.588C9.804 9.021 10 8.55 10 8c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 8 6c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 6 8c0 .55.196 1.02.588 1.412C6.979 9.804 7.45 10 8 10Zm-6 8h12v-.8a.973.973 0 0 0-.5-.85c-.9-.45-1.808-.787-2.725-1.012a11.6 11.6 0 0 0-5.55 0c-.917.225-1.825.562-2.725 1.012a.973.973 0 0 0-.5.85v.8Z" })
  }
);
export {
  h as default
};
