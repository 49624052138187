import { jsx as c } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M6.5 20c-1.517 0-2.812-.525-3.887-1.575C1.538 17.375 1 16.09 1 14.575c0-1.3.392-2.459 1.175-3.475.783-1.017 1.808-1.667 3.075-1.95.283-1.2.992-2.342 2.125-3.425C8.508 4.64 9.717 4.1 11 4.1c.55 0 1.021.195 1.413.587.391.392.587.863.587 1.413v6.05l.9-.875a.934.934 0 0 1 .688-.275c.275 0 .512.1.712.3a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-2.6 2.6c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063 1.1 1.1 0 0 1-.375-.063.871.871 0 0 1-.325-.212l-2.6-2.6a.976.976 0 0 1-.287-.688.928.928 0 0 1 .287-.712.979.979 0 0 1 .687-.288.975.975 0 0 1 .713.263l.9.875V6.1c-1.267.233-2.25.845-2.95 1.837C7.35 8.929 7 9.95 7 11h-.5c-.967 0-1.792.341-2.475 1.025A3.372 3.372 0 0 0 3 14.5c0 .966.342 1.791 1.025 2.475A3.372 3.372 0 0 0 6.5 18h12c.7 0 1.292-.242 1.775-.725.483-.484.725-1.075.725-1.775s-.242-1.292-.725-1.775C19.792 13.24 19.2 13 18.5 13H17v-2c0-.8-.183-1.546-.55-2.238A5.616 5.616 0 0 0 15 7V4.675a6.826 6.826 0 0 1 2.925 2.588A6.892 6.892 0 0 1 19 11c1.15.133 2.104.629 2.863 1.487A4.41 4.41 0 0 1 23 15.5c0 1.25-.437 2.312-1.312 3.188C20.813 19.562 19.75 20 18.5 20h-12Z" })
  }
);
export {
  l as default
};
