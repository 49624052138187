import { jsx as t } from "react/jsx-runtime";
const o = (e) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ t("path", { d: "M8 15a.968.968 0 0 1-.713-.287A.967.967 0 0 1 7 14v-4a.97.97 0 0 1 .287-.713A.97.97 0 0 1 8 9h3l3.3-3.3c.317-.317.679-.388 1.087-.213.409.175.613.488.613.938v11.15c0 .45-.204.762-.613.937-.408.175-.77.104-1.087-.212L11 15H8Z" })
  }
);
export {
  o as default
};
