/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftLibrary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12 6-1.697-1.697A2.4 2.4 0 0 0 8.606 3.6H2.4a1.2 1.2 0 0 0-1.2 1.2v14.4a1.2 1.2 0 0 0 1.2 1.2h19.2a1.2 1.2 0 0 0 1.2-1.2v-12A1.2 1.2 0 0 0 21.6 6H12Z"
      fill="#FFB900"
    />
    <path
      d="m12 6-1.697 1.697a2.4 2.4 0 0 1-1.697.703H1.2v10.8a1.2 1.2 0 0 0 1.2 1.2h19.2a1.2 1.2 0 0 0 1.2-1.2v-12A1.2 1.2 0 0 0 21.6 6H12Z"
      fill="#FFD75E"
    />
    <path
      d="m12 6-1.697 1.697a2.4 2.4 0 0 1-1.697.703H1.2v10.8a1.2 1.2 0 0 0 1.2 1.2h19.2a1.2 1.2 0 0 0 1.2-1.2v-12A1.2 1.2 0 0 0 21.6 6H12Z"
      fill="url(#microsoft-library_svg__a)"
    />
    <path
      d="M2.4 19.8a1.79 1.79 0 0 1-1.168-.44A1.185 1.185 0 0 0 2.4 20.4h19.2c.606 0 1.086-.456 1.168-1.04-.315.27-.72.44-1.168.44H2.4Z"
      fill="#E67628"
    />
    <path
      opacity={0.4}
      d="M10.603 8.297 12.9 6H12l-1.697 1.697a2.4 2.4 0 0 1-1.697.703H1.2V9h7.706a2.4 2.4 0 0 0 1.697-.703Z"
      fill="#fff"
    />
    <path
      d="M19.312 14a2 2 0 0 0-2-2H6.688a2 2 0 0 0-2 2v7h3.694v-5.453h7.292V21h3.638v-7Z"
      fill="#5FB2E1"
    />
    <defs>
      <linearGradient
        id="microsoft-library_svg__a"
        x1={12}
        y1={6}
        x2={12}
        y2={20.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0} />
        <stop offset={0.999} stopColor="#FFD75E" stopOpacity={0.3} />
      </linearGradient>
    </defs>
  </svg>
);

export default MicrosoftLibrary;
