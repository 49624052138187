import { jsx as l } from "react/jsx-runtime";
const h = (e) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ l(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "m19.088 19.488-.245.148-1.375-1.67.245-.147a4.72 4.72 0 0 0 2.112-3.928c0-2.554-2.111-4.665-4.714-4.665a4.715 4.715 0 0 0-4.714 4.714c0 2.553 2.062 4.566 4.616 4.763h.147l1.326 1.571.442.491h-.393l-8.25-.049C4.701 20.471 2 17.524 2 13.94V3.235h2.112L4.16 13.94c0 2.406 1.767 4.42 4.174 4.665h1.767a6.833 6.833 0 0 1-1.817-4.665c0-3.83 2.947-6.826 6.777-6.826 7.155 0 9.257 8.326 4.027 12.374Z",
        fill: "#03D0C8"
      }
    )
  }
);
export {
  h as default
};
