import * as React from "react";
const SvgIconMsOffice = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="Icon-ms-office_svg__a"
        x1={32.625}
        y1={1.738}
        x2={32.625}
        y2={41.186}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ffb900" />
        <stop offset={0.166} stopColor="#ef8400" />
        <stop offset={0.313} stopColor="#e25c01" />
        <stop offset={0.429} stopColor="#db4401" />
        <stop offset={0.5} stopColor="#d83b01" />
      </linearGradient>
      <linearGradient
        id="Icon-ms-office_svg__b"
        x1={25.676}
        y1={3.393}
        x2={4.558}
        y2={33.553}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#800600" />
        <stop offset={0.6} stopColor="#c72127" />
        <stop offset={0.728} stopColor="#c13959" />
        <stop offset={0.847} stopColor="#bc4b81" />
        <stop offset={0.942} stopColor="#b95799" />
        <stop offset={1} stopColor="#b85ba2" />
      </linearGradient>
      <linearGradient
        id="Icon-ms-office_svg__c"
        x1={14.968}
        y1={40.063}
        x2={40.993}
        y2={40.063}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f32b44" />
        <stop offset={0.6} stopColor="#a4070a" />
      </linearGradient>
      <linearGradient
        id="Icon-ms-office_svg__d"
        x1={26.108}
        y1={2.776}
        x2={21.636}
        y2={9.163}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopOpacity={0.4} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="Icon-ms-office_svg__e"
        x1={32.675}
        y1={40.639}
        x2={21.002}
        y2={39.618}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopOpacity={0.4} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
    </defs>
    <path
      d="M25.251 4.01 28 11.25V36l-2.684 7.992 11.879-3.3A3.831 3.831 0 0 0 40 37V11a3.831 3.831 0 0 0-2.81-3.692Z"
      fill="url(#Icon-ms-office_svg__a)"
    />
    <path
      d="m14.519 33.816-3.537 1.934A2.015 2.015 0 0 1 8 33.982V14.84a3.824 3.824 0 0 1 1.921-3.317l12.462-7.151a3.761 3.761 0 0 1 1.867-.5A3.746 3.746 0 0 1 28 7.625v3.625l-10.128 3.692A2.848 2.848 0 0 0 16 17.618v13.7a2.849 2.849 0 0 1-1.481 2.498Z"
      fill="url(#Icon-ms-office_svg__b)"
    />
    <path
      d="M28 36v4.457a3.662 3.662 0 0 1-3.668 3.668 3.664 3.664 0 0 1-1.792-.468l-7.811-4.377a1.752 1.752 0 0 1 .857-3.28Z"
      fill="url(#Icon-ms-office_svg__c)"
    />
    <path
      d="m14.519 33.816-3.537 1.934A2.015 2.015 0 0 1 8 33.982V14.84a3.824 3.824 0 0 1 1.921-3.317l12.462-7.151a3.761 3.761 0 0 1 1.867-.5A3.746 3.746 0 0 1 28 7.625v3.625l-10.128 3.692A2.848 2.848 0 0 0 16 17.618v13.7a2.849 2.849 0 0 1-1.481 2.498Z"
      fill="url(#Icon-ms-office_svg__d)"
    />
    <path
      d="M28 36v4.457a3.662 3.662 0 0 1-3.668 3.668 3.664 3.664 0 0 1-1.792-.468l-7.811-4.377a1.752 1.752 0 0 1 .857-3.28Z"
      fill="url(#Icon-ms-office_svg__e)"
    />
    <path fill="none" d="M0 0h48v48H0z" />
  </svg>
);
export default SvgIconMsOffice;
