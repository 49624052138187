import React from "react";
import ReactIs from "react-is";
import styled from "styled-components";

const validationErrorMessage = [
  "HappeoUIKit: The Breadcrumbs component doesn't accept a Fragment as a child.",
  "Consider providing an array instead.",
].join("\n");

export const getValidChildrenArray = (children) => React.Children.toArray(children)
  .filter((child) => {
    if (process.env.NODE_ENV !== "production") {
      if (ReactIs.isFragment(child)) {
        console.error(validationErrorMessage);
      }
    }

    return React.isValidElement(child);
  });

export const renderChildrenWithSeparators = (
  childrenArray,
  separator,
) => childrenArray.reduce((nodes, node, index) => {
  const isFirstNode = index === 0;

  if (!isFirstNode) {
    nodes.push(
      // eslint-disable-next-line react/no-array-index-key
      <SeparatorWrapper key={`separator-${index}`}>
        {separator}
      </SeparatorWrapper>
    );
  }

  nodes.push(
    // eslint-disable-next-line react/no-array-index-key
    <NodeWrapper key={`node-${index}`}>
      {node}
    </NodeWrapper>
  );

  return nodes;
}, []);


const SeparatorWrapper = styled.li`
  display: flex;
  align-content: center;
  user-select: none;
  margin: 0 8px;
`;

const NodeWrapper = styled.li`
  display: flex;
  align-content: center;
`;
