import * as React from "react";

const SvgIconQuestion = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.025 16C11.625 16 11.2877 15.8583 11.013 15.575C10.7377 15.2917 10.6167 14.9583 10.65 14.575C10.7 13.875 10.8667 13.2667 11.15 12.75C11.4333 12.2333 11.8833 11.7 12.5 11.15C13.1833 10.55 13.704 10.029 14.062 9.587C14.4207 9.14567 14.6 8.64167 14.6 8.075C14.6 7.39167 14.371 6.825 13.913 6.375C13.4543 5.925 12.8167 5.7 12 5.7C11.4667 5.7 11.0127 5.81233 10.638 6.037C10.2627 6.26233 9.95835 6.54167 9.72501 6.875C9.49168 7.20833 9.20434 7.43333 8.86301 7.55C8.52101 7.66667 8.19168 7.65833 7.87501 7.525C7.49168 7.35833 7.22934 7.08333 7.08801 6.7C6.94601 6.31667 6.97501 5.95833 7.17501 5.625C7.65834 4.875 8.30834 4.25 9.12501 3.75C9.94168 3.25 10.9 3 12 3C13.75 3 15.096 3.48767 16.038 4.463C16.9793 5.43767 17.45 6.60833 17.45 7.975C17.45 8.80833 17.271 9.521 16.913 10.113C16.5543 10.7043 15.9917 11.375 15.225 12.125C14.6583 12.6583 14.2583 13.0917 14.025 13.425C13.7917 13.7583 13.6333 14.1417 13.55 14.575C13.4833 14.9917 13.3083 15.3333 13.025 15.6C12.7417 15.8667 12.4083 16 12.025 16ZM12 22C11.45 22 10.9793 21.8043 10.588 21.413C10.196 21.021 10 20.55 10 20C10 19.45 10.196 18.979 10.588 18.587C10.9793 18.1957 11.45 18 12 18C12.55 18 13.021 18.1957 13.413 18.587C13.8043 18.979 14 19.45 14 20C14 20.55 13.8043 21.021 13.413 21.413C13.021 21.8043 12.55 22 12 22Z"
    />
  </svg>
);

export default SvgIconQuestion;
