import { jsx as h } from "react/jsx-runtime";
const a = (e) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ h(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Zm10.5 0h-3.25v3.25h3.25V5ZM8.25 5v3.25H5V5h3.25Zm0 10.5H5V19h3.25v-3.5Zm7.25 0H19V19h-3.5v-3.5Zm-2 0V19h-3.25v-3.5h3.25ZM19 5v3.25h-3.5V5H19Z"
      }
    )
  }
);
export {
  a as default
};
