/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Awesometable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.957 10.53c.074-.207.037.148 0 0-.442 1.106-2.758 2.267-4.084 2.156 1.62-2.614 1.842-5.082 1.842-6.74C19.715 4.105 19.2 3 19.2 3c-2.026 2.615-6.188 3.425-9.613 3.683l.037-.037h-.037c.884-.957 1.474-1.62 1.915-2.946-1.841 1.51-4.456 1.547-6.519 2.799C3.105 7.64 2.11 9.15 2 11.47v5.745c0 2.026 1.657 3.757 3.646 3.757h8.378c4.158 0 5.793-4.388 7.933-10.442Z"
      fill="#FFC107"
    />
    <path
      d="M20.534 14.384a14.452 14.452 0 0 0-.303-.337l-1.444-1.443c-.33.072-.643.103-.914.08.14-.225.269-.45.389-.672l-2.414-2.348-1.068 1.437L4.1 12.574l1.583 1.584-1.584 1.51 1.584 1.584-1.068 1.14 2.333 2.579h7.076c3.208 0 4.915-2.612 6.51-6.587Z"
      fill="#FFA000"
    />
    <path
      d="M9.513 13.347H4.025v2.284h5.488v-2.284Zm.847 5.562h3.278a2.217 2.217 0 0 0 2.21-2.21v-.295H10.36v2.505Zm5.488-5.562H10.36v2.284h5.488v-2.284Zm-3.94-3.24h-6.41a1.438 1.438 0 0 0-1.436 1.436v.994h11.786V9.664c-.48.11-1.732.406-3.94.442Zm-7.883 6.997c0 .995.81 1.841 1.842 1.841h3.646v-2.468H4.025v.627Z"
      fill="#fff"
    />
  </svg>
);

export default Awesometable;
