import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { radius500, animationCurve } from "@happeouikit/theme";
import { active, gray08, lighten80, lighten90 } from "@happeouikit/colors";
import DropzoneDefaultInner from "./DropzoneDefaultInner";

const Dropzone = forwardRef(
  ({ onSelected = () => {}, disabled, children, text, inputProps }, ref) => {
    const [isDragging, setIsDragging] = useState(false);
    const [isDragActive, setIsDragActive] = useState(false);

    const inputRef = useRef();
    const elemRef = useRef();

    useImperativeHandle(ref, () => ({
      clear: () => {
        inputRef.current.value = "";
      },
    }));

    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      setIsDragging(false);
      setIsDragActive(false);
    };

    const handleDragOverInside = (e) => {
      e.preventDefault();
      setIsDragActive(true);
    };

    useEffect(() => {
      document.addEventListener("dragover", handleDragOver);
      document.addEventListener("dragleave", handleDragLeave);
      document.addEventListener("drop", handleDragLeave);

      return () => {
        document.removeEventListener("dragover", handleDragOver);
        document.removeEventListener("dragleave", handleDragLeave);
        document.removeEventListener("drop", handleDragLeave);
      };
    }, []);

    const clickUpload = () => {
      if (!disabled && inputRef.current) {
        inputRef.current.click();
      }
    };

    const handleDrop = (e) => {
      if (
        e.dataTransfer.files === undefined ||
        e.dataTransfer.files.length === 0
      ) {
        return;
      }
      e.preventDefault();
      onSelected(e.dataTransfer.files);
    };

    const handleChangeInput = (e) => {
      const { files } = e.target;
      if (files && onSelected) {
        onSelected(files);
      }
    };

    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          clickUpload,
          isDragging,
          isDragActive,
          disabled,
        });
      }
      return child;
    });

    return (
      <DropBox
        ref={elemRef}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOverInside}
        onDrop={handleDrop}
      >
        <input
          type="file"
          disabled={disabled}
          onChange={handleChangeInput}
          ref={inputRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
        />
        <BoxInput
          dragging={isDragging}
          dragActive={isDragActive}
          isDisabled={disabled}
        >
          {children ? (
            childrenWithProps
          ) : (
            <DropzoneDefaultInner
              onClickUpload={clickUpload}
              text={text}
              disabled={disabled}
              isDragActive={isDragActive}
            />
          )}
        </BoxInput>
      </DropBox>
    );
  }
);

Dropzone.propTypes = {
  onSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
};

Dropzone.defaultProps = {
  disabled: false,
  inputProps: { multiple: true },
  text: null,
};

const DropBox = styled.form`
  width: 100%;
  min-height: 200px;
  position: relative;
  display: flex;

  input[type="file"] {
    display: none;
  }
`;

const BoxInput = styled.div`
  position: relative;
  border: 2px dashed ${gray08};
  border-radius: ${radius500};
  opacity: 1;
  transition: all 0.2s ${animationCurve};
  flex: 1;

  ${({ dragging, isDisabled }) =>
    dragging &&
    !isDisabled &&
    `
      border-color: ${active};
      background-color: ${lighten90(active)};
    `};

  ${({ dragActive, isDisabled }) =>
    dragActive &&
    !isDisabled &&
    `
      border: 2px solid ${active};
      background-color: ${lighten80(active)};
    `};
`;

export default Dropzone;
