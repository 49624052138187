import { jsx as A } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ A("path", { d: "M4 9a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 8V4c0-.283.096-.521.288-.713A.967.967 0 0 1 4 3h4a.97.97 0 0 1 .713.287A.97.97 0 0 1 9 4c0 .283-.096.52-.287.712A.968.968 0 0 1 8 5H6.4l2.425 2.425A.918.918 0 0 1 9.1 8.1c0 .267-.1.5-.3.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275L5 6.4V8c0 .283-.096.52-.287.712A.968.968 0 0 1 4 9Zm11.2-.2a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7L17.6 5H16a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 4c0-.283.096-.521.288-.713A.967.967 0 0 1 16 3h4c.283 0 .52.096.712.287.192.192.288.43.288.713v4c0 .283-.096.52-.288.712A.965.965 0 0 1 20 9a.965.965 0 0 1-.712-.288A.965.965 0 0 1 19 8V6.4l-2.425 2.425a.918.918 0 0 1-.675.275.96.96 0 0 1-.7-.3ZM16 21a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 20c0-.283.096-.52.288-.712A.965.965 0 0 1 16 19h1.6l-2.425-2.425a.918.918 0 0 1-.275-.675c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.4 2.4V16c0-.283.096-.521.288-.713A.967.967 0 0 1 20 15c.283 0 .52.096.712.287.192.192.288.43.288.713v4c0 .283-.096.52-.288.712A.965.965 0 0 1 20 21h-4ZM4 21a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 20v-4c0-.283.096-.521.288-.713A.967.967 0 0 1 4 15a.97.97 0 0 1 .713.287A.97.97 0 0 1 5 16v1.6l2.425-2.425A.918.918 0 0 1 8.1 14.9c.267 0 .5.1.7.3a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L6.4 19H8c.283 0 .521.096.713.288A.967.967 0 0 1 9 20c0 .283-.096.52-.287.712A.968.968 0 0 1 8 21H4Z" })
  }
);
export {
  h as default
};
