import { jsx as A } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ A("path", { d: "M12 6c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12 2c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 6Zm8 2.25c-.8.183-1.625.337-2.475.462-.85.125-1.692.221-2.525.288v12.025a.928.928 0 0 1-.287.7A.993.993 0 0 1 14 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 21v-5h-2v5.025a.928.928 0 0 1-.287.7A.993.993 0 0 1 10 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 21V9a38.184 38.184 0 0 1-2.525-.288A31.622 31.622 0 0 1 4 8.25a1.005 1.005 0 0 1-.65-.463.93.93 0 0 1-.1-.787.963.963 0 0 1 .463-.638c.241-.141.504-.179.787-.112 1.2.267 2.438.458 3.713.575C9.488 6.942 10.75 7 12 7s2.512-.058 3.787-.175A29.482 29.482 0 0 0 19.5 6.25a1 1 0 0 1 .775.112c.233.142.392.355.475.638.067.283.03.546-.112.787A.963.963 0 0 1 20 8.25Z" })
  }
);
export {
  c as default
};
