import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-ms-forms_svg__a",
      x1: 5.822,
      y1: 11.568,
      x2: 20.178,
      y2: 36.432,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { offset: 0, stopColor: "#058f92" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.5, stopColor: "#038489" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#026d71" })
  )),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M45 24v18.169A1.831 1.831 0 0 1 43.169 44H13.831A1.831 1.831 0 0 1 12 42.169V24l17-2Z",
      fill: "#036c70"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M13.831 4h29.338A1.831 1.831 0 0 1 45 5.831V24H12V5.831A1.831 1.831 0 0 1 13.831 4Z",
      fill: "#37c6d0"
    }
  ),
  /* @__PURE__ */ e.createElement("path", { fill: "#1a9ba1", d: "M30 29h10v10H30z" }),
  /* @__PURE__ */ e.createElement("circle", { cx: 35, cy: 14, r: 6, fill: "#1a9ba1" }),
  /* @__PURE__ */ e.createElement("circle", { cx: 35, cy: 14, r: 3, fill: "#036c70" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M24.167 12H12v25h12.167A1.839 1.839 0 0 0 26 35.167V13.833A1.839 1.839 0 0 0 24.167 12Z",
      opacity: 0.1
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M23.167 13H12v25h11.167A1.839 1.839 0 0 0 25 36.167V14.833A1.839 1.839 0 0 0 23.167 13Z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M23.167 13H12v23h11.167A1.839 1.839 0 0 0 25 34.167V14.833A1.839 1.839 0 0 0 23.167 13Z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M22.167 13H12v23h10.167A1.839 1.839 0 0 0 24 34.167V14.833A1.839 1.839 0 0 0 22.167 13Z",
      opacity: 0.2
    }
  ),
  /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 2,
      y: 13,
      width: 22,
      height: 22,
      rx: 1.833,
      fill: "url(#Icon-ms-forms_svg__a)"
    }
  ),
  /* @__PURE__ */ e.createElement("path", { fill: "none", d: "M0 0h48v48H0z" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M16.713 19.98h-5.16v3.249h4.811v1.928h-4.811v4.8H9.218V18.043h7.5Z",
      fill: "#fff"
    }
  )
);
export {
  l as default
};
