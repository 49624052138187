import * as React from "react";
const SvgIconStream = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M4 18h17v-6H4v6zM4 5v6h17V5H4z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
export default SvgIconStream;
