/** @deprecated - instead, try using this (not exact replacement): `var(--box-shadow-shadow100)` */
export const shadow100 = `0 8px 14px 0 rgba(232,236,242,0.5), 0 8px 14px 0 rgba(0,157,255,0.05)`;
/** @deprecated - instead, use one of: `var(--box-shadow-shadow100)`, `var(--box-shadow-shadow200)` */
export const shadow200 = `0 1px 4px 0 rgba(186,198,208,0.5), 0 1px 4px 0 rgba(0,157,255,0.05)`;
/** @deprecated - instead, use one of: `var(--box-shadow-shadow200)`, `var(--box-shadow-shadow300)` */
export const shadow300 = `0 2px 8px 0 rgba(186,198,208,0.5), 0 2px 8px 0 rgba(0,157,255,0.05)`;
/** @deprecated - instead, use one of: `var(--box-shadow-shadow300)`, `var(--box-shadow-shadow400)` */
export const shadow400 = `0 4px 16px 0 rgba(186,198,208,0.5), 0 4px 16px 0 rgba(0,157,255,0.05)`;
/** @deprecated - instead, use one of: `var(--box-shadow-shadow400)`, `var(--box-shadow-shadow500)` */
export const shadow500 = `0 12px 32px 0 rgba(186,198,208,0.5), 0 12px 32px 0 rgba(0,157,255,0.05)`;
/** @deprecated */
export const shadowDark400 = `0 4px 16px 0 rgba(0, 0, 0, 0.5)`;
