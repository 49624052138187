/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const AdobeIndesign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.188 3h11.625C19.575 3 21 4.462 21 6.27v11.46c0 1.808-1.425 3.27-3.188 3.27H6.188C4.425 21 3 19.538 3 17.73V6.27C3 4.461 4.425 3 6.188 3Z"
      fill="#49021F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.17 16.104a3.824 3.824 0 0 0 1.653.346c.4 0 .8-.038 1.185-.107.43-.077.854-.2 1.254-.37.053-.023.076-.069.076-.123l-.003-.062c-.007-.116-.014-.247-.027-.384l-.002-.046a9.293 9.293 0 0 1-.014-.415V7.604c-.007-.03-.038-.061-.077-.054h-1.561c-.054 0-.077.031-.077.085v2.43l-.119-.006c-.043-.003-.082-.004-.12-.009-.069-.007-.122-.007-.161-.007-.761 0-1.408.146-1.946.446a3.16 3.16 0 0 0-1.646 2.815c0 .7.138 1.285.423 1.754.277.454.677.823 1.161 1.046Zm2.407-4.577v3.385a.654.654 0 0 1-.277.038c-.1.008-.208.016-.323.016-.215 0-.43-.031-.639-.1a1.33 1.33 0 0 1-.522-.308 1.503 1.503 0 0 1-.347-.546 2.145 2.145 0 0 1-.13-.792c0-.4.084-.731.253-.977.17-.247.393-.439.654-.57.27-.138.57-.2.87-.2a1.94 1.94 0 0 1 .461.054Z"
      fill="#F36"
    />
    <path
      d="M9.377 16.235V8.312c0-.008 0-.015-.008-.023a.087.087 0 0 0-.1-.077h-1.5c-.069 0-.107.03-.107.1v7.923c0 .085.03.123.1.123h1.507c.07 0 .108-.038.108-.123Z"
      fill="#F36"
    />
  </svg>
);

export default AdobeIndesign;
