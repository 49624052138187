/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftForms = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.502 3H4.498C3.67 3 3 3.671 3 4.498V19.51c0 .827.671 1.498 1.498 1.49h15.004c.827 0 1.498-.671 1.498-1.498V4.498C21 3.67 20.329 3 19.502 3Zm-8.685 5.713h4.224l.008-1.588H8.91v9.758h1.907v-3.93h3.937v-1.58h-3.937v-2.66Z"
    />
  </svg>
);

export default MicrosoftForms;
