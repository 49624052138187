import { jsx as A } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ A("path", { d: "M4 17a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 16V8c0-.283.096-.521.288-.713A.967.967 0 0 1 4 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 5 8v3h4V8c0-.283.096-.521.288-.713A.967.967 0 0 1 10 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 11 8v8c0 .283-.096.52-.287.712A.968.968 0 0 1 10 17a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 16v-3H5v3c0 .283-.096.52-.287.712A.968.968 0 0 1 4 17Zm10 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 16v-3c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 15 11h4V9h-5a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 8c0-.283.096-.521.288-.713A.967.967 0 0 1 14 7h5c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v2c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 19 13h-4v2h5c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 17h-6Z" })
  }
);
export {
  c as default
};
