import * as React from "react";
const SvgIconPagesColor = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm5.795 5.669a.75.75 0 0 1 .455.689v7.368a.75.75 0 0 1-1.045.69l-3.75-1.608a.75.75 0 0 1-.455-.69V7.752a.75.75 0 0 1 1.045-.69l3.75 1.608Zm2.41 0a.75.75 0 0 0-.455.689v7.368a.75.75 0 0 0 1.045.69l3.75-1.608a.75.75 0 0 0 .455-.69V7.752a.75.75 0 0 0-1.045-.69l-3.75 1.608Z"
      fill="#29C4A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.205 8.669a.75.75 0 0 0-.455.689v7.368a.75.75 0 0 0 1.045.69l3.75-1.608a.75.75 0 0 0 .455-.69V7.752a.75.75 0 0 0-1.045-.69l-3.75 1.608Z"
      fill="#29C47F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.205 8.669a.75.75 0 0 0-.455.689v7.368a.75.75 0 0 0 1.045.69l3.75-1.608a.75.75 0 0 0 .455-.69V7.752a.75.75 0 0 0-1.045-.69l-3.75 1.608Z"
      fill="#fff"
      fillOpacity={0.8}
    />
  </svg>
);
export default SvgIconPagesColor;
