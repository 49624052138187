import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M12 22c-1.383 0-2.563-.488-3.537-1.462C7.488 19.562 7 18.383 7 17V8c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 5 6V4c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 7 2h10c.55 0 1.02.196 1.413.587C18.803 2.98 19 3.45 19 4v2c0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 17 8v9c0 1.383-.488 2.563-1.463 3.538C14.563 21.512 13.383 22 12 22Zm0-2c.65 0 1.233-.188 1.75-.563A2.91 2.91 0 0 0 14.825 18H13a.968.968 0 0 1-.713-.288A.968.968 0 0 1 12 17c0-.283.096-.52.287-.712A.968.968 0 0 1 13 16h2v-1h-2a.968.968 0 0 1-.713-.287A.968.968 0 0 1 12 14c0-.283.096-.52.287-.713A.968.968 0 0 1 13 13h2v-1h-2a.968.968 0 0 1-.713-.287A.968.968 0 0 1 12 11c0-.283.096-.52.287-.713A.968.968 0 0 1 13 10h2V8H9v9c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 12 20Z" })
  }
);
export {
  t as default
};
