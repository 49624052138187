import { jsx as h } from "react/jsx-runtime";
const s = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M8.675 20h-2.65c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4.025 18v-2.65L2.1 13.4a1.905 1.905 0 0 1-.575-1.4c0-.55.192-1.017.575-1.4l1.925-1.95V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 6.025 4h2.65l1.95-1.925c.383-.383.85-.575 1.4-.575.55 0 1.017.192 1.4.575L15.375 4h2.65c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v2.65l1.925 1.95c.383.383.575.85.575 1.4 0 .55-.192 1.017-.575 1.4l-1.925 1.95V18c0 .55-.196 1.021-.587 1.413a1.928 1.928 0 0 1-1.413.587h-2.65l-1.95 1.925c-.383.383-.85.575-1.4.575-.55 0-1.017-.192-1.4-.575L8.675 20Zm3.35-3c-1.383 0-2.562-.488-3.537-1.463-.975-.975-1.463-2.154-1.463-3.537s.488-2.563 1.463-3.538C9.463 7.487 10.642 7 12.025 7s2.563.487 3.538 1.462c.975.975 1.462 2.155 1.462 3.538s-.487 2.562-1.462 3.537c-.975.975-2.155 1.463-3.538 1.463Zm0 3.5 2.5-2.5h3.5v-3.5l2.5-2.5-2.5-2.5V6h-3.5l-2.5-2.5-2.5 2.5h-3.5v3.5l-2.5 2.5 2.5 2.5V18h3.5l2.5 2.5Z" })
  }
);
export {
  s as default
};
