import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      d: "M2.065 13.148C2.022 12.771 2 12.388 2 12 2 6.477 6.477 2 12 2c4.908 0 8.99 3.536 9.838 8.2.107.584.162 1.185.162 1.8 0 5.523-4.477 10-10 10-5.135 0-9.365-3.87-9.935-8.852ZM5.636 12A6.364 6.364 0 0 1 16.19 7.21a7.91 7.91 0 0 1-8.98 8.98A6.34 6.34 0 0 1 5.637 12Z",
      fill: "#6EBF49"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M7.654 4.833c.695.725.67 1.876-.055 2.57A6.34 6.34 0 0 0 5.636 12 6.34 6.34 0 0 0 7.6 16.596a1.818 1.818 0 1 1-2.516 2.626A9.976 9.976 0 0 1 2 12a9.976 9.976 0 0 1 3.083-7.222 1.818 1.818 0 0 1 2.57.055Z",
      fill: "#FF3D67"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M10.182 3.818C10.182 2.814 10.996 2 12 2c5.523 0 10 4.477 10 10a1.818 1.818 0 1 1-3.636 0A6.364 6.364 0 0 0 12 5.636a1.818 1.818 0 0 1-1.818-1.818Z",
      fill: "#FBAB18"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M20.182 10.182c1.004 0 1.818.814 1.818 1.818 0 5.523-4.477 10-10 10a1.818 1.818 0 0 1 0-3.636A6.364 6.364 0 0 0 18.364 12c0-1.004.814-1.818 1.818-1.818Z",
      fill: "#005073"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M4.833 16.346a1.818 1.818 0 0 1 2.57.055A6.34 6.34 0 0 0 12 18.364 1.818 1.818 0 1 1 12 22a9.976 9.976 0 0 1-7.222-3.083 1.818 1.818 0 0 1 .055-2.571Z",
      fill: "#00BCEA"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M12 5.636c-1.757 0-3.345.71-4.499 1.863a1.818 1.818 0 1 1-2.57-2.571A9.974 9.974 0 0 1 12 2a1.818 1.818 0 1 1 0 3.636Z",
      fill: "#D540D8"
    }
  )
);
export {
  a as default
};
