import { jsx as e } from "react/jsx-runtime";
const d = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.5 2a4.252 4.252 0 0 1-.972 3.046 3.577 3.577 0 0 1-2.825 1.335 4.046 4.046 0 0 1 .998-2.933A4.326 4.326 0 0 1 15.5 2Zm3.467 6.481a4.318 4.318 0 0 0-2.056 3.623 4.172 4.172 0 0 0 2.547 3.839 9.565 9.565 0 0 1-1.325 2.698c-.78 1.167-1.598 2.308-2.897 2.329-.618.014-1.035-.164-1.469-.349-.453-.193-.925-.394-1.664-.394-.784 0-1.277.208-1.753.408-.411.173-.81.34-1.37.364-1.237.046-2.182-1.246-2.991-2.402-1.616-2.362-2.875-6.656-1.188-9.577a4.638 4.638 0 0 1 3.903-2.38c.701-.015 1.375.255 1.965.492.451.182.854.344 1.183.344.29 0 .682-.156 1.138-.337.719-.285 1.598-.634 2.495-.54a4.42 4.42 0 0 1 3.482 1.882Z"
      }
    )
  }
);
export {
  d as default
};
