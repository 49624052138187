import * as React from "react";
const SvgIconPhoto = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M1 4.006v11.988A3.002 3.002 0 0 0 4 19h12a3 3 0 0 0 3-3.006V4.006A3.002 3.002 0 0 0 16 1H4a3 3 0 0 0-3 3.006ZM17 11v4.994A1 1 0 0 1 16 17H4c-.552 0-1-.45-1-1.006V13l3-3 2 2 5-5 4 4ZM7.5 8a2.41 2.41 0 0 1-1.77-.73A2.41 2.41 0 0 1 5 5.5c0-.694.243-1.285.73-1.77A2.41 2.41 0 0 1 7.5 3a2.41 2.41 0 0 1 1.77.73A2.41 2.41 0 0 1 10 5.5a2.41 2.41 0 0 1-.73 1.77A2.41 2.41 0 0 1 7.5 8Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconPhoto;
