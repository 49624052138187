/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftPowerpoint = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.302 13.023 12.768 1.768h-.115C7.072 1.784 2.55 6.304 2.535 11.885V12l11.767 1.023Z"
      fill="#68778D"
    />
    <path
      d="M12.882 1.768h-.114V12l5.116 2.047L23 12v-.114c-.016-5.582-4.537-10.102-10.118-10.118Z"
      fill="#95A0B1"
    />
    <path d="M23 12v.113c-.016 5.582-4.538 10.104-10.12 10.12h-.225c-5.583-.017-10.104-4.538-10.12-10.12V12H23Z" />
    <path
      opacity={0.1}
      d="M13.28 6.797v10.918a.942.942 0 0 1-.937.936H4.95a10.035 10.035 0 0 1-2.415-6.539v-.224c-.003-1.96.57-3.878 1.647-5.516.113-.174.23-.348.359-.511h7.802c.515.003.932.42.936.936Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M12.768 7.308v10.919a.942.942 0 0 1-.936.936H5.414a8.746 8.746 0 0 1-.465-.512 10.035 10.035 0 0 1-2.415-6.539v-.225c-.003-1.96.57-3.878 1.647-5.515h7.65c.515.004.932.42.935.936Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M12.768 7.308v9.895a.943.943 0 0 1-.937.937h-7.29a10.035 10.035 0 0 1-2.006-6.027v-.226c-.003-1.96.57-3.878 1.647-5.515h7.65c.515.004.932.42.935.936Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M12.256 7.308v9.895a.944.944 0 0 1-.936.937H4.54a10.035 10.035 0 0 1-2.005-6.027v-.226c-.003-1.96.57-3.878 1.647-5.515h7.138c.515.004.932.42.936.936Z"
      fill="#000"
    />
    <path
      d="M1.938 6.372h9.38c.518 0 .938.42.938.938v9.38c0 .518-.42.938-.938.938h-9.38A.938.938 0 0 1 1 16.69V7.31c0-.518.42-.938.938-.938Z"
      fill="#596578"
    />
    <path
      d="M6.73 8.874a2.578 2.578 0 0 1 1.708.5c.41.366.63.9.595 1.45.006.38-.095.756-.294 1.082-.2.32-.49.576-.832.736-.392.182-.82.272-1.252.263H5.469v2.205H4.253V8.874H6.73Zm-1.262 3.079h1.045c.332.024.66-.074.925-.276.218-.21.333-.505.312-.807 0-.687-.399-1.031-1.198-1.031H5.468v2.114Z"
      fill="#fff"
    />
  </svg>
);

export default MicrosoftPowerpoint;
