/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Tripaction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M23 7H12v11h11V7Z" fill="#F04923" />
    <path
      d="M6.5 18c3.032 0 5.5-2.465 5.5-5.5S9.532 7 6.5 7A5.505 5.505 0 0 0 1 12.5C1 15.535 3.456 18 6.5 18Z"
      fill="#0092FE"
    />
  </svg>
);

export default Tripaction;
