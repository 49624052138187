import { jsxs as e, jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ c("path", { d: "M8.123 9.377v2.585l.023.001c.099.007.191.014.277.014h.407c.3 0 .6-.046.885-.139.246-.069.462-.215.63-.407.162-.192.24-.454.24-.792a1.266 1.266 0 0 0-.178-.685c-.13-.2-.315-.354-.538-.439a2.257 2.257 0 0 0-.908-.153c-.2 0-.377 0-.523.007-.154-.007-.261 0-.315.008Z" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M17.813 3H6.188C4.425 3 3 4.462 3 6.27v11.46C3 19.539 4.425 21 6.188 21h11.625C19.575 21 21 19.538 21 17.73V6.27C21 4.461 19.575 3 17.812 3ZM6.445 15.892V7.977c0-.054.023-.085.077-.085.13 0 .254 0 .43-.007.096-.004.193-.006.293-.008.094-.002.191-.004.292-.008.108-.004.22-.006.335-.008.108-.001.22-.003.334-.007.239-.008.47-.008.7-.008.631 0 1.154.077 1.585.239.385.13.738.346 1.03.63.247.247.44.547.562.877.116.323.177.654.177 1 0 .662-.154 1.208-.461 1.639-.308.43-.739.754-1.239.938-.523.193-1.1.262-1.73.262-.185 0-.308 0-.385-.008-.077-.008-.185-.008-.33-.008v2.47c.007.053-.032.1-.086.107H6.538c-.061 0-.092-.03-.092-.1Zm10.615-4.4a2.724 2.724 0 0 0-.738-.261c-.285-.062-.57-.1-.862-.1a1.547 1.547 0 0 0-.461.054.395.395 0 0 0-.239.153.409.409 0 0 0-.061.208c0 .07.03.139.077.2.069.085.161.154.261.208.177.092.362.177.546.254.416.138.816.33 1.185.561.254.162.461.377.608.639.123.246.184.515.177.792.007.362-.1.723-.3 1.023a1.928 1.928 0 0 1-.862.685c-.377.161-.838.246-1.392.246-.354 0-.7-.03-1.046-.1a3.105 3.105 0 0 1-.785-.246c-.054-.031-.092-.085-.085-.146v-1.339c0-.023.008-.054.031-.07.023-.014.046-.007.07.009.3.176.615.3.953.377.292.076.6.115.908.115.292 0 .5-.039.638-.108a.357.357 0 0 0 .208-.323c0-.108-.062-.208-.185-.308s-.377-.215-.754-.361a4.711 4.711 0 0 1-1.092-.554 1.993 1.993 0 0 1-.584-.654 1.65 1.65 0 0 1-.177-.784c0-.331.092-.647.261-.931.192-.308.477-.554.808-.708.361-.184.815-.27 1.361-.27.316 0 .639.024.954.07.231.03.454.092.662.177.03.008.061.039.077.07.007.03.015.06.015.092v1.253c0 .031-.015.062-.038.077-.07.016-.108.016-.139 0Z"
        }
      )
    ]
  }
);
export {
  h as default
};
