import * as React from "react";
const SvgIconCheckBox = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm4.3 13.29c.39.39 1.02.39 1.41 0l7.58-7.59a.996.996 0 1 0-1.41-1.41L10 14.17l-2.88-2.88a.996.996 0 1 0-1.41 1.41l3.59 3.59Z"
    />
  </svg>
);
export default SvgIconCheckBox;
