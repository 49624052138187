import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "m19.775 22.577-4.2-4.175H17c0 .433-.158.808-.475 1.125a1.538 1.538 0 0 1-1.125.475H3c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 1 18.002v-.8c0-.567.146-1.088.438-1.563.291-.475.679-.837 1.162-1.087a14.843 14.843 0 0 1 3.15-1.163 13.755 13.755 0 0 1 3.863-.375c.208.008.412.02.612.038l-1.05-1.05H9c-1.1 0-2.042-.392-2.825-1.175C5.392 10.043 5 9.102 5 8.002v-.175L1.375 4.202c-.2-.2-.3-.438-.3-.713 0-.275.1-.512.3-.712.2-.2.438-.3.712-.3.275 0 .513.1.713.3l18.4 18.4c.2.2.3.433.3.7 0 .266-.1.5-.3.7-.2.2-.438.3-.712.3a.973.973 0 0 1-.713-.3Zm-3.125-9.425a12.507 12.507 0 0 1 4.5 1.4c.6.333 1.058.704 1.375 1.112.317.408.475.854.475 1.338v3h-.125l-4-4a4.532 4.532 0 0 0-.788-1.563 5.386 5.386 0 0 0-1.437-1.287ZM9 15.002a11.6 11.6 0 0 0-2.775.337c-.917.225-1.825.563-2.725 1.013a.973.973 0 0 0-.5.85v.8h12v-.175l-2.175-2.175c-.633-.217-1.27-.38-1.912-.488A11.464 11.464 0 0 0 9 15.002Zm5.05-3.825c.317-.467.554-.967.712-1.5A5.856 5.856 0 0 0 15 8.002c0-.7-.12-1.375-.363-2.025A5.948 5.948 0 0 0 13.6 4.202a3.04 3.04 0 0 1 .7-.163c.233-.025.467-.037.7-.037 1.1 0 2.042.391 2.825 1.175C18.608 5.96 19 6.902 19 8.002s-.413 2.041-1.238 2.825c-.825.783-1.787 1.175-2.887 1.175l-.825-.825Zm-1.45-1.45-1.6-1.6v-.125c0-.55-.196-1.021-.588-1.413A1.926 1.926 0 0 0 9 6.002h-.125l-1.6-1.6A3.771 3.771 0 0 1 9 4.002c1.1 0 2.042.391 2.825 1.175C12.608 5.96 13 6.902 13 8.002a3.774 3.774 0 0 1-.4 1.725Z" })
  }
);
export {
  t as default
};
