import { jsx as t } from "react/jsx-runtime";
const o = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M9.3 20.275 4.7 15.7a.883.883 0 0 1-.213-.325A1.115 1.115 0 0 1 4.425 15c0-.133.02-.258.062-.375A.883.883 0 0 1 4.7 14.3l4.625-4.625a.994.994 0 0 1 .7-.288.872.872 0 0 1 .7.288c.2.183.3.417.3.7s-.1.525-.3.725l-2.9 2.9H17V5c0-.283.096-.521.288-.713A.967.967 0 0 1 18 4c.283 0 .52.096.712.287.192.192.288.43.288.713v10c0 .283-.096.52-.288.712A.965.965 0 0 1 18 16H7.825l2.9 2.875a.975.975 0 0 1 .287.687.93.93 0 0 1-.287.713c-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3Z" })
  }
);
export {
  o as default
};
