import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M13.775 10.9 11.1 8.225c.133-.083.275-.142.425-.175.15-.033.308-.05.475-.05.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413 0 .167-.017.325-.05.475-.033.15-.092.292-.175.425ZM11.25 21.35C8.817 19.2 7 17.208 5.8 15.375 4.6 13.542 4 11.817 4 10.2a8.733 8.733 0 0 1 .475-2.9l-3.1-3.1a.948.948 0 0 1-.275-.7c0-.283.092-.525.275-.725.2-.183.442-.275.725-.275s.525.092.725.275l18.375 18.4a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7c-.2.2-.442.3-.725.3a.908.908 0 0 1-.7-.3l-4.1-4.075c-.433.467-.892.938-1.375 1.413-.483.475-1 .954-1.55 1.437a1.2 1.2 0 0 1-.35.2c-.133.05-.267.075-.4.075s-.267-.025-.4-.075a1.2 1.2 0 0 1-.35-.2Zm6.95-6.025c.6-.933 1.05-1.821 1.35-2.663.3-.841.45-1.662.45-2.462 0-2.5-.804-4.492-2.413-5.975C15.979 2.742 14.117 2 12 2c-.983 0-1.92.162-2.812.487A7.73 7.73 0 0 0 6.775 3.9L8.2 5.325a5.533 5.533 0 0 1 1.738-.988A6.193 6.193 0 0 1 12 4c1.683 0 3.104.579 4.262 1.737C17.421 6.896 18 8.383 18 10.2c0 .533-.1 1.104-.3 1.712-.2.609-.517 1.263-.95 1.963l1.45 1.45ZM12 19.35c.433-.383.838-.763 1.213-1.138.375-.375.729-.745 1.062-1.112L6.1 8.925A8.01 8.01 0 0 0 6 10.2c0 1.183.492 2.537 1.475 4.062.983 1.525 2.492 3.221 4.525 5.088Z" })
  }
);
export {
  s as default
};
