import { jsx as a } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M12 2a1 1 0 0 0-1 1v1.07A8.002 8.002 0 0 0 4.07 11H3a1 1 0 1 0 0 2h1.07A8.002 8.002 0 0 0 11 19.93V21a1 1 0 1 0 2 0v-1.07A8.002 8.002 0 0 0 19.93 13H21a1 1 0 1 0 0-2h-1.07A8.002 8.002 0 0 0 13 4.07V3a1 1 0 0 0-1-1Zm-1 4.08V7a1 1 0 1 0 2 0v-.91c2.5.41 4.5 2.41 4.92 4.91H17a1 1 0 1 0 0 2h.91c-.41 2.5-2.41 4.5-4.91 4.92V17a1 1 0 1 0-2 0v.91C8.5 17.5 6.5 15.5 6.08 13H7a1 1 0 1 0 0-2h-.91C6.5 8.5 8.5 6.5 11 6.08ZM12 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" })
  }
);
export {
  v as default
};
