import { jsx as t } from "react/jsx-runtime";
const h = (o) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ t("path", { d: "M19.365 20.085a.902.902 0 0 1-1.275 1.275l-1.31-1.31L12 18l-5.606 2.403A1 1 0 0 1 5 19.483V8.27L2.64 5.91a.902.902 0 0 1 1.275-1.275l15.45 15.45ZM19 17.16V5a2 2 0 0 0-2-2H7c-.59 0-1.11.27-1.5.68L19 17.16Z" })
  }
);
export {
  h as default
};
