import { jsx as c } from "react/jsx-runtime";
const e = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.613-1.958-2.138-3.175A9.738 9.738 0 0 1 2 12c0-1.017.142-1.988.425-2.912A9.828 9.828 0 0 1 3.65 6.5L2.075 4.925a.93.93 0 0 1-.288-.712A1.02 1.02 0 0 1 2.1 3.5c.2-.2.437-.3.712-.3.275 0 .513.1.713.3l16.975 17c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.438.3-.712.3a.973.973 0 0 1-.713-.3L17.5 20.35c-.8.533-1.662.942-2.588 1.225A9.89 9.89 0 0 1 12 22Zm-.075-2.025c.75 0 1.467-.096 2.15-.288A7.62 7.62 0 0 0 16 18.85l-2.275-2.275a8.034 8.034 0 0 0-1.862 1.188c-.609.508-1.13 1.187-1.563 2.037.267.05.533.092.8.125s.542.05.825.05Zm-3.1-.625c.583-1.2 1.246-2.092 1.988-2.675.741-.583 1.304-.975 1.687-1.175-.483-.15-.97-.27-1.463-.363A8.373 8.373 0 0 0 9.5 15c-.75 0-1.492.092-2.225.275a10.82 10.82 0 0 0-2.125.775 8.83 8.83 0 0 0 1.587 1.938 7.454 7.454 0 0 0 2.088 1.362ZM20.35 17.5l-3.1-3.1a2.502 2.502 0 0 0 1.262-.9c.325-.433.488-.933.488-1.5 0-.7-.242-1.292-.725-1.775C17.792 9.742 17.2 9.5 16.5 9.5c-.567 0-1.067.162-1.5.488a2.502 2.502 0 0 0-.9 1.262l-1.1-1.1c.033-1.017-.308-1.888-1.025-2.613-.717-.724-1.592-1.07-2.625-1.037L6.5 3.65a9.829 9.829 0 0 1 2.587-1.225A9.89 9.89 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.89 9.89 0 0 1-.425 2.912A9.829 9.829 0 0 1 20.35 17.5Zm-10.85-4a3.291 3.291 0 0 0 1-.15L6.15 9A3.29 3.29 0 0 0 6 10c0 .967.342 1.792 1.025 2.475A3.372 3.372 0 0 0 9.5 13.5Z" })
  }
);
export {
  e as default
};
