import React from "react";
import styled from "styled-components";
import { IconBrokenImage } from "@happeouikit/icons";
import { darken, active, white, gray05, gray09 } from "@happeouikit/colors";
import { TextMega } from "@happeouikit/typography";

const ImageGridPhoto = ({
  index,
  left,
  top,
  photo,
  margin,
  direction,
  onClick,
  maxImages,
  totalImages,
  maxImageHeight,
}) => {
  const styles = {
    margin,
    left,
    top,
    height: photo.height,
    width: maxImageHeight ? "auto" : photo.width,
    position: direction === "column" && "absolute",
  };
  const showMoreImages = index === maxImages - 1 && totalImages - maxImages > 0;

  return (
    <ImageContainer
      style={styles}
      maxImageHeight={maxImageHeight}
      onClick={(e) => onClick(e, { index, photo })}
    >
      {photo.error ? (
        <ImageErrorWrapper>
          <IconBrokenImage />
        </ImageErrorWrapper>
      ) : (
        <img
          alt={photo.alt || photo.title || "Image"}
          height={photo.height}
          width={photo.width}
          src={photo.src}
        />
      )}
      {showMoreImages && (
        <>
          <MaxImagesWrapper />
          <ImageCenterOverlay>
            <TextMega bold color={white}>
              +{totalImages - maxImages}
            </TextMega>
          </ImageCenterOverlay>
        </>
      )}
    </ImageContainer>
  );
};

const ImageContainer = styled.a`
  display: block;
  position: relative;

  ${({ maxImageHeight }) =>
    maxImageHeight &&
    `
    max-height: ${maxImageHeight};
    width: auto;

    img, a {
      max-height: ${maxImageHeight};
      width: auto;
    }
  `}
`;

const ImageErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${gray09};
  height: 100%;

  & > svg {
    fill: ${gray05};
  }
`;

const ImageCenterOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    fill: ${gray05};
  }
`;

const MaxImagesWrapper = styled.div`
  opacity: 0.4;
  background-color: ${darken(active, 0.9)};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

export default ImageGridPhoto;
