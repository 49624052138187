import { jsx as a } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M20.566 5.81a1 1 0 0 0-1.173-1.62l-7.216 5.226L8.4 5.64a1 1 0 0 0-1.306-.094L3.4 8.306A1 1 0 1 0 4.6 9.908l2.999-2.242 3.769 3.767a1 1 0 0 0 1.293.103l7.906-5.726ZM5.27 11.917a1 1 0 0 1 1.286.087l4.916 4.755a1 1 0 0 0 1.32.062l3.202-2.568a1 1 0 0 1 .626-.22h3.36a1 1 0 0 1 1 1v4.67a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V14.09a1 1 0 0 1 .408-.806l1.861-1.366Z" })
  }
);
export {
  h as default
};
