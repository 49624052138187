import { jsx as A } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M8 17h5a.968.968 0 0 0 .713-.288A.967.967 0 0 0 14 16a.97.97 0 0 0-.287-.713A.97.97 0 0 0 13 15H8a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 16c0 .283.096.52.287.712.192.192.43.288.713.288Zm0-4h8c.283 0 .52-.096.712-.288A.965.965 0 0 0 17 12a.968.968 0 0 0-.288-.713A.967.967 0 0 0 16 11H8a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 12c0 .283.096.52.287.712.192.192.43.288.713.288Zm0-4h8c.283 0 .52-.096.712-.288A.965.965 0 0 0 17 8a.968.968 0 0 0-.288-.713A.967.967 0 0 0 16 7H8a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 8c0 .283.096.52.287.712.192.192.43.288.713.288ZM5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Z" })
  }
);
export {
  h as default
};
