import * as e from "react";
const r = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 93 55",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { filter: "url(#icon-widget-text_svg__a)" }, /* @__PURE__ */ e.createElement("g", { clipPath: "url(#icon-widget-text_svg__b)" }, /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 3,
      y: 2,
      width: 86.75,
      height: 49.775,
      rx: 3.555,
      fill: "#fff"
    }
  ), /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: -9.025,
      y: -1.447,
      width: 110.469,
      height: 66.366,
      rx: 1.422,
      fill: "#fff"
    }
  ), /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 7.125,
      y: 5.225,
      width: 79,
      height: 43,
      rx: 1.422,
      fill: "#0075DB"
    }
  ), /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 7.125,
      y: 5.225,
      width: 79,
      height: 43,
      rx: 1.422,
      fill: "#fff",
      fillOpacity: 0.9
    }
  ), /* @__PURE__ */ e.createElement("g", { filter: "url(#icon-widget-text_svg__c)" }, /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 13.125,
      y: 34.225,
      width: 35,
      height: 8,
      rx: 2,
      fill: "#fff"
    }
  )), /* @__PURE__ */ e.createElement("g", { filter: "url(#icon-widget-text_svg__d)" }, /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 13.125,
      y: 23.225,
      width: 53,
      height: 8,
      rx: 2,
      fill: "#fff"
    }
  )), /* @__PURE__ */ e.createElement("g", { filter: "url(#icon-widget-text_svg__e)" }, /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 13.125,
      y: 12.225,
      width: 66,
      height: 8,
      rx: 2,
      fill: "#fff"
    }
  )))),
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "filter",
    {
      id: "icon-widget-text_svg__a",
      x: 0.593,
      y: 0.195,
      width: 91.563,
      height: 54.588,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB"
    },
    /* @__PURE__ */ e.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 0.602 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 1.203 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "BackgroundImageFix",
        result: "effect1_dropShadow_213_44139"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 0.602 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 1.203 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "effect1_dropShadow_213_44139",
        result: "effect2_dropShadow_213_44139"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in: "SourceGraphic",
        in2: "effect2_dropShadow_213_44139",
        result: "shape"
      }
    )
  ), /* @__PURE__ */ e.createElement(
    "filter",
    {
      id: "icon-widget-text_svg__c",
      x: 10.281,
      y: 32.092,
      width: 40.688,
      height: 13.688,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB"
    },
    /* @__PURE__ */ e.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 0.711 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 1.422 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "BackgroundImageFix",
        result: "effect1_dropShadow_213_44139"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 0.711 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 1.422 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "effect1_dropShadow_213_44139",
        result: "effect2_dropShadow_213_44139"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in: "SourceGraphic",
        in2: "effect2_dropShadow_213_44139",
        result: "shape"
      }
    )
  ), /* @__PURE__ */ e.createElement(
    "filter",
    {
      id: "icon-widget-text_svg__d",
      x: 10.281,
      y: 21.092,
      width: 58.688,
      height: 13.688,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB"
    },
    /* @__PURE__ */ e.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 0.711 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 1.422 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "BackgroundImageFix",
        result: "effect1_dropShadow_213_44139"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 0.711 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 1.422 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "effect1_dropShadow_213_44139",
        result: "effect2_dropShadow_213_44139"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in: "SourceGraphic",
        in2: "effect2_dropShadow_213_44139",
        result: "shape"
      }
    )
  ), /* @__PURE__ */ e.createElement(
    "filter",
    {
      id: "icon-widget-text_svg__e",
      x: 10.281,
      y: 10.092,
      width: 71.689,
      height: 13.688,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB"
    },
    /* @__PURE__ */ e.createElement("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 0.711 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 1.422 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "BackgroundImageFix",
        result: "effect1_dropShadow_213_44139"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feColorMatrix",
      {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }
    ),
    /* @__PURE__ */ e.createElement("feOffset", { dy: 0.711 }),
    /* @__PURE__ */ e.createElement("feGaussianBlur", { stdDeviation: 1.422 }),
    /* @__PURE__ */ e.createElement("feColorMatrix", { values: "0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" }),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in2: "effect1_dropShadow_213_44139",
        result: "effect2_dropShadow_213_44139"
      }
    ),
    /* @__PURE__ */ e.createElement(
      "feBlend",
      {
        in: "SourceGraphic",
        in2: "effect2_dropShadow_213_44139",
        result: "shape"
      }
    )
  ), /* @__PURE__ */ e.createElement("clipPath", { id: "icon-widget-text_svg__b" }, /* @__PURE__ */ e.createElement(
    "rect",
    {
      x: 3,
      y: 2,
      width: 86.75,
      height: 49.775,
      rx: 3.555,
      fill: "#fff"
    }
  )))
);
export {
  r as default
};
