/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftExchangeAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3Z"
      fill="#0078D4"
    />
    <path
      d="M14.948 8.71h-4.21v2.46h3.951v1.578h-3.95v2.557h4.433v1.57H8.828v-9.75h6.12v1.584Z"
      fill="#fff"
    />
  </svg>
);

export default MicrosoftExchangeAlt;
