import { jsx as t } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "m9.875 16.307-3.6-3.6a.871.871 0 0 1-.212-.325A1.098 1.098 0 0 1 6 12.007a1.1 1.1 0 0 1 .063-.375.872.872 0 0 1 .212-.325l3.6-3.6a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275.183.183.275.42.275.712s-.092.53-.275.713L9.4 11.007H16a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288H9.4l1.875 1.875a.949.949 0 0 1 .275.7.948.948 0 0 1-.275.7.99.99 0 0 1-.7.287.906.906 0 0 1-.7-.262Z" })
  }
);
export {
  s as default
};
