/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const AdobeAnimate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.187 3h11.626C19.575 3 21 4.462 21 6.269V17.73C21 19.538 19.575 21 17.813 21H6.187C4.425 21 3 19.538 3 17.731V6.27C3 4.462 4.425 3 6.187 3Z"
      fill="#00005B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.378 14.105h2.851v-.021l.634 1.829a.115.115 0 0 0 .122.086h1.606c.079 0 .1-.036.079-.122L9.869 8.029c-.007-.044-.036-.065-.1-.065H7.766c-.044.007-.072.05-.065.086 0 .166-.015.339-.043.497l-.013.035c-.029.08-.048.133-.067.203l-2.484 7.099c-.021.093 0 .137.087.137H6.65a.133.133 0 0 0 .144-.108l.583-1.808Zm2.397-1.533h-1.95c.114-.324.23-.684.33-1.073.072-.224.142-.45.21-.674l.143-.463c.108-.332.187-.663.26-.994h.014c.032.1.062.208.094.321.024.09.05.181.079.276.064.224.13.447.208.684.04.12.076.236.112.353.036.117.072.234.112.353l.063.206c.048.158.095.31.145.457.072.201.137.396.18.554Z"
      fill="#99F"
    />
    <path
      d="M13.541 11.334v4.572c-.007.007-.007.014 0 .043a.097.097 0 0 0 .108.086h1.462c.072 0 .1-.029.1-.108V11.68c.137-.122.288-.223.461-.295.18-.08.382-.108.583-.108a.9.9 0 0 1 .526.137c.137.086.245.23.317.381.093.21.137.44.144.67v3.477c.007.065.057.101.122.094l1.447-.014c.072 0 .101-.03.101-.108v-3.636c0-.504-.05-.915-.173-1.231a1.834 1.834 0 0 0-1.166-1.138 2.217 2.217 0 0 0-.72-.115c-.317 0-.626.05-.929.15a2.57 2.57 0 0 0-.813.476 1.771 1.771 0 0 0-.094-.425c-.014-.043-.036-.072-.065-.086a.336.336 0 0 0-.122-.022H13.59a.13.13 0 0 0-.079.022.084.084 0 0 0-.014.08c.007.085.014.237.021.388.004.079.006.16.008.245.002.078.003.159.007.245.007.165.007.33.007.468Z"
      fill="#99F"
    />
  </svg>
);

export default AdobeAnimate;
