import { jsx as l } from "react/jsx-runtime";
const a = (e) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ l(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.418 5.092A1 1 0 0 0 4 6v10.696a1 1 0 0 0 .58.907l6.995 3.238a.997.997 0 0 0 .85 0l6.995-3.238a1 1 0 0 0 .58-.907V6a1 1 0 0 0-1.418-.908L12 8.124 5.418 5.092ZM13 9.865v8.506l5-2.314V7.562l-5 2.303Zm-2 0L6 7.562v8.495l5 2.314V9.865Z"
      }
    )
  }
);
export {
  a as default
};
