import * as React from "react";
const SvgIconFormatColorText = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="m4.074 17.58 4.56-11.65C8.853 5.37 9.402 5 10 5c.6 0 1.138.37 1.367.93l4.56 11.65a1.042 1.042 0 1 1-1.946.74L13.113 16H6.878l-.858 2.32c-.16.41-.55.68-.978.68-.739 0-1.237-.74-.968-1.42ZM10 7.67 7.626 14h4.749L10 7.67Zm5 1.785C15 7.762 17.5 5 17.5 5S20 7.762 20 9.455c0 1.4-1.125 2.545-2.5 2.545S15 10.854 15 9.455Z" />
  </svg>
);
export default SvgIconFormatColorText;
