import { jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v7.8a5.581 5.581 0 0 0-1.438-.6A6.129 6.129 0 0 0 19 13c-1.667 0-3.083.583-4.25 1.75C13.583 15.917 13 17.333 13 19v1H4Zm8-9L5.3 6.8c-.317-.183-.613-.188-.888-.013A.83.83 0 0 0 4 7.525c0 .167.033.308.1.425a.86.86 0 0 0 .3.3l7.075 4.425c.167.1.342.15.525.15s.358-.05.525-.15L19.6 8.25a.86.86 0 0 0 .3-.3.844.844 0 0 0 .1-.425c0-.3-.133-.542-.4-.725-.267-.183-.567-.183-.9 0L12 11Zm7.175 9H16a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 19c0-.283.096-.52.288-.712A.965.965 0 0 1 16 18h3.175l-.9-.9a.914.914 0 0 1-.287-.7c.008-.267.112-.5.312-.7.2-.183.433-.28.7-.288.267-.008.5.088.7.288l2.6 2.6c.2.2.3.433.3.7 0 .267-.1.5-.3.7l-2.6 2.6a.975.975 0 0 1-.687.287.93.93 0 0 1-.713-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l.875-.9Z" })
  }
);
export {
  h as default
};
