import styled, { css, Keyframes } from "styled-components";
import { themeDark, themeLight } from "../Theme/theme";
import { animationKeyframes } from "../Theme/animation";
import { fontVariant } from "../Theme/fonts";
import { toCssVariables } from "../Theme/cssUtils";

export const ColorsExample = () => {
  const cssVariables = toCssVariables(themeLight);
  return (
    <FlexColumnWithGap style={{ alignItems: "flex-start" }}>
      <FlexWrapWithGap>
        <Box color={"#0075db"} />
        <Box color={"#80baed"} />
        <Box color={"#005eaf"} />
        <Box color={"#ffffff"} />
        <Box color={"#f3f5f7"} />
        <Box color={"#f3f5f7"} />
        <div style={{ font: "16px/1.5 IBM Plex Sans" }}> (hardcoded styles)</div>
      </FlexWrapWithGap>
      <FlexWrapWithGap>
        <Box color="var(--color-active-primary)" />
        <Box color="var(--color-active-lighten50)" />
        <Box color="var(--color-active-darken20)" />
        <Box color="var(--color-surface)" />
        <Box color="var(--color-surface-over)" />
        <Box color="var(--color-surface-darken)" />
        <div style={{ font: "var(--font-body-lg)" }}> (css variables)</div>
      </FlexWrapWithGap>
      <FlexWrapWithGap>
        <Box color={themeLight.color.activePrimary} />
        <Box color={themeLight.color.activeLighten50} />
        <Box color={themeLight.color.activeDarken20} />
        <Box color={themeLight.color.surface} />
        <Box color={themeLight.color.surfaceOver} />
        <Box color={themeLight.color.surfaceDarken} />
        <div style={{ ...themeLight.font.variant.bodyLg }}> (object chaining - light mode)</div>
      </FlexWrapWithGap>
      <FlexWrapWithGap>
        <Box color={themeDark.color.activePrimary} />
        <Box color={themeDark.color.activeLighten50} />
        <Box color={themeDark.color.activeDarken20} />
        <Box color={themeDark.color.surface} />
        <Box color={themeDark.color.surfaceOver} />
        <Box color={themeDark.color.surfaceDarken} />
        <div style={{ ...themeDark.font.variant.bodyLg }}> (object chaining - dark mode)</div>
      </FlexWrapWithGap>
      {"Old colors:"}
      <FlexWrapWithGap>
        {Object.keys(cssVariables).filter((colorKey) => colorKey.includes("__will_become__"))
          .map((colorKey) => {
            // "convert" old to new
            const colorStr = "var(" + colorKey + ")";
            return <Box key={colorKey} color={colorStr} />;
          })}
      </FlexWrapWithGap>
      {"New colors (Design System 3):"}
      <FlexWrapWithGap>
        {Object.keys(cssVariables).filter((colorKey) => colorKey.includes("__will_become__"))
          .map((colorKey) => {
            // "convert" old to new
            const newColorKey = "--color-" + colorKey.slice(colorKey.indexOf("__will_become__") + "__will_become__".length);
            const colorStr = "var(" + newColorKey + ")";
            return <Box key={colorKey} color={colorStr} />;
          })}
      </FlexWrapWithGap>
    </FlexColumnWithGap>
  );
};

export const AnimationsExample = () => {
  const defaultKeyframes = animationKeyframes.dropFade;
  const defaultDuration = themeLight.animation.duration.slowMd;
  const defaultEasing = themeLight.animation.easing.easeIn;
  return (
    <FlexColumnWithGap>
      <div style={{ ...fontVariant.codeLg }}>
        {"(default props here:  keyframes = dropFade, duration = slowMd, easing = easeIn)"}
      </div>
      <div style={{ ...fontVariant.titleSm }}>{"Keyframes"}</div>
      <FlexWrapWithGap>
        {Object.entries(animationKeyframes).map(([keyframesKey, keyframes]) => (
          <LabeledAnimatingElement
            key={keyframesKey}
            label={keyframesKey}
            keyframes={keyframes}
            duration={defaultDuration}
            easing={defaultEasing}
          />
        ))}
      </FlexWrapWithGap>
      <div style={{ ...fontVariant.titleSm }}>{"Duration"}</div>
      <FlexWrapWithGap>
        {Object.entries(themeLight.animation.duration).map(([durationKey, duration]) => (
          <LabeledAnimatingElement
            key={durationKey}
            label={durationKey}
            keyframes={defaultKeyframes}
            duration={duration}
            easing={defaultEasing}
          />
        ))}
      </FlexWrapWithGap>
      <div style={{ ...fontVariant.titleSm }}>{"Easing"}</div>
      <FlexWrapWithGap>
        {Object.entries(themeLight.animation.easing).map(([easingKey, easing]) => (
          <LabeledAnimatingElement
            key={easingKey}
            label={easingKey}
            keyframes={defaultKeyframes}
            duration={defaultDuration}
            easing={easing}
          />
        ))}
      </FlexWrapWithGap>
    </FlexColumnWithGap>
  );
};

const LabeledAnimatingElement = ({ label, keyframes, duration, easing }: { label: string, keyframes: Keyframes, duration: string, easing: string }) => {
  return (
    <FlexColumnWithGap>
      <div>{label}</div>
      <AnimatingElement keyframes={keyframes} duration={duration} easing={easing} />
    </FlexColumnWithGap>
  );
};

const FlexColumnWithGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const FlexWrapWithGap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Box = styled.div<{ color: string }>`
  width: 30px;
  height: 30px;
  background-color: ${({ color }) => color};
  border: 1px solid var(--color-stroke);
`;

const AnimatingElement = styled.div<{ keyframes: Keyframes, duration: string, easing: string }>`
  margin: var(--space-md);
  width: var(--space-xxl);
  height: var(--space-xxl);
  border-radius: var(--radius-md);
  border: 1px solid var(--color-stroke);
  background-color: green;
  animation: ${({ keyframes, duration, easing }) =>
          css`${keyframes} ${duration} ${easing} infinite`};
`;