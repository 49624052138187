/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Sound = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z"
      fill="#0075DB"
    />
    <path
      d="M8.889 16.2c.428 0 .778-.315.778-.7v-7c0-.385-.35-.7-.778-.7-.428 0-.778.315-.778.7v7c0 .385.35.7.778.7ZM12 18c.428 0 .778-.315.778-.7V6.7c0-.385-.35-.7-.778-.7-.428 0-.778.315-.778.7v10.6c0 .385.35.7.778.7Zm-6.222-4.6c.428 0 .778-.315.778-.7v-1.4c0-.385-.35-.7-.778-.7-.428 0-.778.315-.778.7v1.4c0 .385.35.7.778.7Zm9.333 2.8c.428 0 .778-.315.778-.7v-7c0-.385-.35-.7-.778-.7-.428 0-.778.315-.778.7v7c0 .385.35.7.778.7Zm2.333-4.9v1.4c0 .385.35.7.778.7.428 0 .778-.315.778-.7v-1.4c0-.385-.35-.7-.778-.7-.428 0-.778.315-.778.7Z"
      fill="#fff"
    />
  </svg>
);

export default Sound;
