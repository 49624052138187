import { jsx as t } from "react/jsx-runtime";
const h = (o) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ t("path", { d: "M12 15.95a1.1 1.1 0 0 1-.375-.063.871.871 0 0 1-.325-.212L6.675 11.05a.89.89 0 0 1-.262-.688.975.975 0 0 1 .287-.687.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l3.9 3.9 3.925-3.925a.894.894 0 0 1 .688-.263.978.978 0 0 1 .687.288.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-4.6 4.6c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063Z" })
  }
);
export {
  h as default
};
