import * as React from "react";
const SvgIconReply = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M9.796 7.797v-1.59c0-.89-1.08-1.34-1.71-.71l-4.59 4.59a.996.996 0 0 0 0 1.41l4.59 4.59c.63.63 1.71.19 1.71-.7v-1.69c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11Z" />
  </svg>
);
export default SvgIconReply;
