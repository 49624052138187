import * as React from "react";
const SvgIconMsVisio = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="Icon-ms-visio_svg__a"
        x1={5.822}
        y1={11.568}
        x2={20.178}
        y2={36.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#2368c4" />
        <stop offset={0.5} stopColor="#1a5dbe" />
        <stop offset={1} stopColor="#1146ac" />
      </linearGradient>
    </defs>
    <rect x={23} y={13} width={21} height={20} rx={1.83} fill="#2b7cd3" />
    <path fill="none" d="M0 0h48v48H0z" />
    <path
      d="M34 13a1.793 1.793 0 0 1-.54 1.29L26 21.75l-.71.71a3.467 3.467 0 0 1-.29.25 1.877 1.877 0 0 1-1 .29 1.793 1.793 0 0 1-1.29-.54l-8.17-8.17A1.813 1.813 0 0 1 14.3 12a2.172 2.172 0 0 1 .24-.29l8.17-8.17a1.811 1.811 0 0 1 2.58 0l8.17 8.17A1.793 1.793 0 0 1 34 13z"
      fill="#41a5ee"
    />
    <path
      d="M34 33a10 10 0 0 1-18.66 5 7.178 7.178 0 0 1-.5-1 8.581 8.581 0 0 1-.38-1A10 10 0 0 1 24 23c.34 0 .67.02 1 .05a8.022 8.022 0 0 1 1 .15 10.017 10.017 0 0 1 8 9.8z"
      fill="#103f91"
    />
    <path
      d="M26 13.83v21.34a1.841 1.841 0 0 1-1.14 1.69 1.772 1.772 0 0 1-.69.14h-9.33a8.581 8.581 0 0 1-.38-1A10 10 0 0 1 24 23a1.793 1.793 0 0 1-1.29-.54l-8.17-8.17A1.813 1.813 0 0 1 14.3 12h9.87a1.83 1.83 0 0 1 1.62 1 1.679 1.679 0 0 1 .21.83z"
      opacity={0.1}
    />
    <path
      d="M25 14.83v21.34a1.772 1.772 0 0 1-.14.69A1.841 1.841 0 0 1 23.17 38h-7.83a7.178 7.178 0 0 1-.5-1 8.581 8.581 0 0 1-.38-1A10 10 0 0 1 24 23a1.793 1.793 0 0 1-1.29-.54l-8.17-8.17A1.793 1.793 0 0 1 14 13h9.17a1.78 1.78 0 0 1 1.33.59 1.8 1.8 0 0 1 .5 1.24z"
      opacity={0.2}
    />
    <path
      d="M25 14.83v19.34A1.844 1.844 0 0 1 23.17 36h-8.71A10 10 0 0 1 24 23a1.793 1.793 0 0 1-1.29-.54l-8.17-8.17A1.793 1.793 0 0 1 14 13h9.17a1.78 1.78 0 0 1 1.33.59 1.8 1.8 0 0 1 .5 1.24z"
      opacity={0.2}
    />
    <path
      d="M24 14.83V23a1.793 1.793 0 0 1-1.29-.54l-8.17-8.17A1.793 1.793 0 0 1 14 13h8.17A1.837 1.837 0 0 1 24 14.83z"
      opacity={0.2}
    />
    <path
      d="M24 23v11.17A1.844 1.844 0 0 1 22.17 36h-7.71A10 10 0 0 1 24 23z"
      opacity={0.2}
    />
    <rect
      x={2}
      y={13}
      width={22}
      height={22}
      rx={1.833}
      fill="url(#Icon-ms-visio_svg__a)"
    />
    <path
      d="M12.4 25.928q.505 1.488.6 1.878h.041q.091-.39.649-2.011l2.667-7.754h2.452l-4.525 11.917h-2.626L7.2 18.041h2.522z"
      fill="#fff"
    />
  </svg>
);
export default SvgIconMsVisio;
