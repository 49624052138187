import { jsx as c } from "react/jsx-runtime";
const a = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M11.5 22c-1.533 0-2.833-.533-3.9-1.6C6.533 19.333 6 18.033 6 16.5V6c0-1.1.392-2.042 1.175-2.825C7.958 2.392 8.9 2 10 2s2.042.392 2.825 1.175C13.608 3.958 14 4.9 14 6v9.5c0 .7-.242 1.292-.725 1.775-.483.483-1.075.725-1.775.725s-1.292-.242-1.775-.725C9.242 16.792 9 16.2 9 15.5V6.75A.726.726 0 0 1 9.75 6a.728.728 0 0 1 .75.75v8.75c0 .283.096.52.288.712a.965.965 0 0 0 .712.288.968.968 0 0 0 .713-.288.967.967 0 0 0 .287-.712V6c0-.7-.242-1.292-.725-1.775C11.292 3.742 10.7 3.5 10 3.5s-1.292.242-1.775.725C7.742 4.708 7.5 5.3 7.5 6v10.5c0 1.1.392 2.042 1.175 2.825.783.783 1.725 1.175 2.825 1.175s2.042-.392 2.825-1.175c.783-.783 1.175-1.725 1.175-2.825V6.75a.726.726 0 0 1 .75-.75.728.728 0 0 1 .75.75v9.75c0 1.533-.533 2.833-1.6 3.9-1.067 1.067-2.367 1.6-3.9 1.6Z" })
  }
);
export {
  a as default
};
