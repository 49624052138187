/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleForms = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z"
      fill="#63439E"
    />
    <path
      d="M7 9h2V7H7v2ZM11 9h6V7h-6v2ZM9 13H7v-2h2v2ZM7 17h2v-2H7v2ZM17 13h-6v-2h6v2ZM11 17h6v-2h-6v2Z"
      fill="#fff"
    />
  </svg>
);

export default GoogleForms;
