import * as e from "react";
const v = (h) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...h
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15 3h-2v2h2V3Zm8 8h-2v2h2v-2Zm0 4h-2v2h2v-2Zm0 4h-2v2c1 0 2-1 2-2ZM21 7h2v2h-2V7Zm0-2V3c1 0 2 1 2 2h-2ZM1 7h2v2H1V7Zm18-4h-2v2h2V3Zm-2 16h2v2h-2v-2ZM1 5c0-1 1-2 2-2v2H1Zm8-2h2v2H9V3ZM7 3H5v2h2V3Zm-6 8v8c0 1.1.9 2 2 2h12v-8c0-1.1-.9-2-2-2H1Zm4.12 5.28-1.49 1.91c-.26.33-.02.81.39.82H12a.5.5 0 0 0 .4-.8l-2.22-2.96a.492.492 0 0 0-.79-.01l-2.1 2.7-1.39-1.67a.5.5 0 0 0-.78.01Z"
    }
  )
);
export {
  v as default
};
