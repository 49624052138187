import { useState, useEffect } from "react";
import { Playground, Props } from 'docz';
import { PackageMetadata, BackgroundBox } from '@happeo/docs-tools';
import pkg from "../../../../../packages/loaders/package.json";
import { Spinner, Loader, LoadingContainer, LoadingModal } from "../../../../../packages/loaders/src/index";
import * as React from 'react';
export default {
  useState,
  useEffect,
  Playground,
  Props,
  PackageMetadata,
  BackgroundBox,
  pkg,
  Spinner,
  Loader,
  LoadingContainer,
  LoadingModal,
  React
};