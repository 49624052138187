import { jsxs as h, jsx as c } from "react/jsx-runtime";
const A = (a) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14.188 17.188C13.313 18.063 12.25 18.5 11 18.5c-1.25 0-2.313-.437-3.188-1.312S6.5 15.25 6.5 14c0-1.25.437-2.313 1.312-3.188S9.75 9.5 11 9.5c1.25 0 2.313.437 3.188 1.312S15.5 12.75 15.5 14c0 1.25-.437 2.313-1.312 3.188ZM11 16.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        }
      ),
      /* @__PURE__ */ c("path", { d: "M1.588 21.413C1.979 21.804 2.45 22 3 22h16c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413v-8c0-.133-.025-.263-.075-.388a.967.967 0 0 0-.212-.325.967.967 0 0 0-.325-.212A1.038 1.038 0 0 0 20 11h-1v9H3V8h11V5a.97.97 0 0 0-.287-.713A.97.97 0 0 0 13 4H8.875a2.01 2.01 0 0 0-1.475.65L6.15 6H3c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 1 8v12c0 .55.196 1.021.588 1.413ZM18 6h1v1c0 .283.096.521.288.713A.967.967 0 0 0 20 8c.283 0 .52-.096.712-.287A.968.968 0 0 0 21 7V6h1c.283 0 .52-.096.712-.287A.968.968 0 0 0 23 5a.968.968 0 0 0-.288-.713A.967.967 0 0 0 22 4h-1V3a.968.968 0 0 0-.288-.713A.967.967 0 0 0 20 2a.967.967 0 0 0-.712.287A.968.968 0 0 0 19 3v1h-1a.967.967 0 0 0-.712.287A.968.968 0 0 0 17 5c0 .283.096.521.288.713A.967.967 0 0 0 18 6Z" })
    ]
  }
);
export {
  A as default
};
