import { jsxs as s, jsx as t } from "react/jsx-runtime";
const i = (l) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M22 9.265h-7.64L12.006 2 9.641 9.265 2 9.251l6.18 4.496-2.366 7.265 6.179-4.496 6.18 4.496-2.352-7.265L22 9.265Z",
          fill: "#00B67A"
        }
      ),
      /* @__PURE__ */ t("path", { d: "m16.35 15.389-.53-1.642-3.813 2.77 4.342-1.128Z", fill: "#005128" })
    ]
  }
);
export {
  i as default
};
