import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M8.01 2.014V1c0-.55.44-1 .99-1h6l5 5v10c0 .55-.45 1-1 1h-5V8L8.01 2.014ZM15 5V1l4 4h-4ZM1 4c-.55 0-.99.45-.99 1L0 19c0 .55.44 1 1 1h10c.55 0 1-.45 1-1V9L7 4H1Zm6 5V5l4 4H7Z",
      fillRule: "evenodd"
    }
  )
);
export {
  l as default
};
