import { jsx as a } from "react/jsx-runtime";
const A = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M19 8.3a.547.547 0 0 1-.262-.076A.386.386 0 0 1 18.55 8l-.8-1.75-1.75-.8a.386.386 0 0 1-.225-.188A.547.547 0 0 1 15.7 5c0-.083.025-.17.075-.263A.387.387 0 0 1 16 4.55l1.75-.8.8-1.75a.386.386 0 0 1 .188-.225A.547.547 0 0 1 19 1.7c.083 0 .171.025.263.075.091.05.154.125.187.225l.8 1.75 1.75.8c.1.034.175.096.225.187.05.092.075.18.075.263 0 .084-.025.17-.075.262A.386.386 0 0 1 22 5.45l-1.75.8-.8 1.75a.387.387 0 0 1-.187.225A.552.552 0 0 1 19 8.3Zm0 14a.547.547 0 0 1-.262-.076.386.386 0 0 1-.188-.225l-.8-1.75-1.75-.8a.386.386 0 0 1-.225-.188A.546.546 0 0 1 15.7 19c0-.083.025-.17.075-.262A.386.386 0 0 1 16 18.55l1.75-.8.8-1.75a.386.386 0 0 1 .188-.225A.547.547 0 0 1 19 15.7c.083 0 .171.025.263.075.091.05.154.125.187.225l.8 1.75 1.75.8c.1.034.175.096.225.188.05.092.075.179.075.262 0 .084-.025.17-.075.262a.386.386 0 0 1-.225.188l-1.75.8-.8 1.75a.387.387 0 0 1-.187.225.552.552 0 0 1-.263.075ZM9 18.573c-.183 0-.358-.05-.525-.15A.932.932 0 0 1 8.1 18l-1.6-3.5L3 12.9a.932.932 0 0 1-.425-.375c-.1-.166-.15-.341-.15-.525 0-.183.05-.358.15-.525A.933.933 0 0 1 3 11.1l3.5-1.6L8.1 6a.932.932 0 0 1 .375-.425c.167-.1.342-.15.525-.15s.358.05.525.15c.167.1.292.242.375.425l1.6 3.5 3.5 1.6a.933.933 0 0 1 .425.375c.1.167.15.342.15.525 0 .184-.05.359-.15.525A.932.932 0 0 1 15 12.9l-3.5 1.6L9.9 18a.932.932 0 0 1-.375.425c-.167.1-.342.15-.525.15Z" })
  }
);
export {
  A as default
};
