import { jsx as c } from "react/jsx-runtime";
const e = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M17 15c-.7 0-1.292-.242-1.775-.725-.483-.483-.725-1.075-.725-1.775s.242-1.292.725-1.775C15.708 10.242 16.3 10 17 10s1.292.242 1.775.725c.483.483.725 1.075.725 1.775s-.242 1.292-.725 1.775C18.292 14.758 17.7 15 17 15Zm-4 5a.968.968 0 0 1-.713-.288A.968.968 0 0 1 12 19v-.4c0-.4.104-.77.313-1.113a1.76 1.76 0 0 1 .887-.737c.6-.25 1.22-.438 1.863-.563a10.11 10.11 0 0 1 3.874 0 9.604 9.604 0 0 1 1.863.563c.383.15.68.396.887.738.209.341.313.712.313 1.112v.4c0 .283-.096.52-.288.712A.968.968 0 0 1 21 20h-8Zm-3-8c-1.1 0-2.042-.392-2.825-1.175C6.392 10.042 6 9.1 6 8s.392-2.042 1.175-2.825C7.958 4.392 8.9 4 10 4s2.042.392 2.825 1.175C13.608 5.958 14 6.9 14 8s-.392 2.042-1.175 2.825C12.042 11.608 11.1 12 10 12Zm-8 5.2c0-.567.142-1.087.425-1.563A2.794 2.794 0 0 1 3.6 14.55c1-.5 2.038-.883 3.113-1.15 1.074-.267 2.17-.4 3.287-.4.583 0 1.167.05 1.75.15.583.1 1.167.217 1.75.35l-1.7 1.7c-.417.417-.82.842-1.213 1.275A2.201 2.201 0 0 0 10 18v.975c0 .2.037.387.113.563.075.174.195.329.362.462H4c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18v-.8Z" })
  }
);
export {
  e as default
};
