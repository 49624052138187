import { jsx as c } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ c("path", { d: "M4 21c-.55 0-1.02-.196-1.412-.588A1.927 1.927 0 0 1 2 19V8.15c0-.35.083-.683.25-1 .167-.316.4-.566.7-.75l8.025-4.8c.167-.1.333-.17.5-.213.167-.041.342-.062.525-.062s.358.02.525.062c.167.042.333.113.5.213l8.025 4.8c.3.184.533.434.7.75.167.317.25.65.25 1V19c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 21H4Zm8-8.526c.083 0 .167-.008.25-.025a.784.784 0 0 0 .25-.1L19.8 8l-7.3-4.35a.786.786 0 0 0-.25-.1 1.272 1.272 0 0 0-.5 0 .786.786 0 0 0-.25.1L4.2 8l7.3 4.35c.083.05.167.084.25.1.083.017.167.025.25.025ZM4 19h16v-8.8l-6.975 4.175c-.167.1-.333.175-.5.225-.167.05-.342.075-.525.075s-.358-.025-.525-.075a2.205 2.205 0 0 1-.5-.225L4 10.2V19Zm0 0h16H4Z" })
  }
);
export {
  h as default
};
