import { jsxs as t, jsx as o } from "react/jsx-runtime";
const r = (s) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: [
      /* @__PURE__ */ o(
        "path",
        {
          d: "M4.5 3h15A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3Z",
          fill: "url(#microsoft-word-alt_svg__a)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M9.154 14.502c.03.232.05.433.058.605h.035c.013-.163.04-.36.082-.591.041-.23.078-.426.112-.585l1.577-6.806h2.04l1.636 6.704c.095.416.163.839.204 1.265h.027c.03-.413.087-.823.17-1.228L16.4 7.122h1.857l-2.293 9.753h-2.169l-1.554-6.459a15.064 15.064 0 0 1-.26-1.382h-.026c-.018.159-.053.395-.106.707-.052.313-.094.544-.126.694l-1.46 6.438H8.055L5.751 7.125h1.89l1.422 6.82c.032.14.062.326.091.557Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ o("defs", { children: /* @__PURE__ */ t(
        "linearGradient",
        {
          id: "microsoft-word-alt_svg__a",
          x1: 6.127,
          y1: 1.828,
          x2: 17.873,
          y2: 22.172,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ o("stop", { stopColor: "#2368C4" }),
            /* @__PURE__ */ o("stop", { offset: 0.5, stopColor: "#1A5DBE" }),
            /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#1146AC" })
          ]
        }
      ) })
    ]
  }
);
export {
  r as default
};
