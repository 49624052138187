import { jsxs as a, jsx as l } from "react/jsx-runtime";
const i = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z",
          fill: "#E5493A"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m19.385 12.311.01-.008-2.263-3.035-.01.008a.737.737 0 0 0-.594-.311.748.748 0 0 0-.668.422l-.008-.003-1.513 3.04-2.393-6.018-.003.002a.749.749 0 0 0-.698-.478.745.745 0 0 0-.714.554l-.012-.005-2.486 8.747-2.15-4.325-.008.003a.748.748 0 0 0-.667-.422.756.756 0 0 0-.755.76c0 .12.035.231.086.335l-.007.003 3.018 6.07.008-.003a.75.75 0 0 0 .669.423.745.745 0 0 0 .712-.554l.014.003 2.412-8.489 2.199 5.527h.001c.112.28.38.478.7.478a.75.75 0 0 0 .666-.423l.008.003 1.717-3.454 1.53 2.052.012-.008a.742.742 0 0 0 .594.313c.417 0 .755-.34.755-.76a.74.74 0 0 0-.162-.447Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  i as default
};
