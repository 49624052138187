import { jsx as c } from "react/jsx-runtime";
const A = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M12 16a.965.965 0 0 1-.712-.287A.965.965 0 0 1 11 15V4.826l-.9.9A.932.932 0 0 1 9.413 6a.974.974 0 0 1-.713-.3 1.02 1.02 0 0 1-.275-.713c0-.275.092-.504.275-.687l2.6-2.6a1.03 1.03 0 0 1 1.088-.2c.125.05.229.116.312.2l2.6 2.6c.2.2.3.441.3.725 0 .283-.1.516-.3.7-.2.183-.437.275-.712.275a.933.933 0 0 1-.688-.275l-.9-.9V15c0 .283-.096.52-.287.712A.968.968 0 0 1 12 16Zm-6 7c-.55 0-1.02-.195-1.412-.586A1.927 1.927 0 0 1 4 21V10c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 8h2a.97.97 0 0 1 .713.287A.97.97 0 0 1 9 9c0 .283-.096.52-.287.712A.968.968 0 0 1 8 10H6v11h12V10h-2a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 9c0-.284.096-.521.288-.713A.967.967 0 0 1 16 8h2c.55 0 1.021.195 1.413.587.391.392.587.863.587 1.413v11c0 .55-.196 1.02-.587 1.413A1.928 1.928 0 0 1 18 23H6Z" })
  }
);
export {
  A as default
};
