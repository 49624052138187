import { jsxs as e, jsx as a } from "react/jsx-runtime";
const v = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5 21h9.075a3.456 3.456 0 0 1-.063-.488 10.165 10.165 0 0 1 0-1.024c.009-.159.03-.321.063-.488H5v-9h14v4.075c.167-.033.33-.054.488-.063a10.165 10.165 0 0 1 1.025 0c.158.009.32.03.487.063V6c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 19 4h-1V3a1 1 0 1 0-2 0v1H8V3a1 1 0 0 0-2 0v1H5c-.55 0-1.021.196-1.413.588A1.925 1.925 0 0 0 3 6v13c0 .55.196 1.021.587 1.413.392.391.863.587 1.413.587ZM19 6H5v2h14V6Z"
        }
      ),
      /* @__PURE__ */ a("path", { d: "M17.172 21.414 18.586 20l-1.414-1.414a1 1 0 1 1 1.414-1.414L20 18.586l1.414-1.414a1 1 0 1 1 1.414 1.414L21.414 20l1.414 1.414a1 1 0 1 1-1.414 1.414L20 21.414l-1.414 1.414a1 1 0 1 1-1.414-1.414Z" })
    ]
  }
);
export {
  v as default
};
