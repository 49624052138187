import { jsxs as d, jsx as e } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ d(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ e("path", { d: "M8.39 12a3.61 3.61 0 1 1 7.219 0 3.61 3.61 0 0 1-7.218 0Z" }),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2ZM12 6.586a5.414 5.414 0 1 0 0 10.828 5.414 5.414 0 0 0 0-10.828Z"
        }
      )
    ]
  }
);
export {
  h as default
};
