import { useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { TextEta } from "@happeouikit/typography";
import { gray06, white, alert } from "@happeouikit/colors";
import { Input } from "@happeouikit/form-elements";
import BasicColorPicker from "./BasicColorPicker";

const ColorPicker = ({
  label,
  value,
  onValueChanged,
  disableAlpha,
  disabled,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const containerRef = useRef(null);

  return (
    <div ref={containerRef}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInputColor
        value={value.toUpperCase()}
        onChange={(e) => onValueChanged(e.target.value)}
        onClick={() => setShowColorPicker(true)}
        icon={() => <ColorDisplay color={value} />}
        disabled={disabled}
      />
      {showColorPicker &&
        ReactDOM.createPortal(
          <BasicColorPicker
            disableAlpha={disableAlpha}
            color={value}
            show
            handleClose={() => setShowColorPicker(false)}
            handleChange={(color) => onValueChanged(color.hex)}
          />,
          containerRef.current
        )}
    </div>
  );
};

const StyledLabel = styled(TextEta)`
  margin-bottom: 8px;
  font-weight: 500;
`;

const StyledInputColor = styled(Input)`
  padding-left: 10px;
`;

const ColorDisplay = styled.span`
  border: 1px solid ${({ color }) => color || gray06};
  background-color: ${({ color }) => color || white};
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: 12px;

  ${({ color }) =>
    !color &&
    `
  :after {
    content: "/";
    color: ${alert};
    position: relative;
    left: 5px;
    bottom: 4px;
    font-size: 22px;
    width: 12px;
    transform: rotate(17deg);
    display: block;
  }
`}
`;

ColorPicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  disableAlpha: PropTypes.bool,
  onValueChanged: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
  label: undefined,
  value: "#000000",
  disableAlpha: true,
};

export default ColorPicker;
