import * as e from "react";
const t = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm7.254 8.25A2.242 2.242 0 0 0 14.5 9c0-1.245-1-2.25-2.246-2.25a2.249 2.249 0 1 0 0 4.5ZM7 14.79v1.71c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-1.71a.75.75 0 0 0-.513-.71l-3.756-1.253a1.5 1.5 0 0 0-.474-.077h-1.014a1.5 1.5 0 0 0-.474.077l-3.756 1.252a.75.75 0 0 0-.513.712Z"
    }
  )
);
export {
  t as default
};
