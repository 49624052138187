import { jsxs as a, jsx as t } from "react/jsx-runtime";
const i = (o) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "m12 6-1.697-1.697A2.4 2.4 0 0 0 8.606 3.6H2.4a1.2 1.2 0 0 0-1.2 1.2v14.4a1.2 1.2 0 0 0 1.2 1.2h19.2a1.2 1.2 0 0 0 1.2-1.2v-12A1.2 1.2 0 0 0 21.6 6H12Z",
          fill: "#FFB900"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "m12 6-1.697 1.697a2.4 2.4 0 0 1-1.697.703H1.2v10.8a1.2 1.2 0 0 0 1.2 1.2h19.2a1.2 1.2 0 0 0 1.2-1.2v-12A1.2 1.2 0 0 0 21.6 6H12Z",
          fill: "#FFD75E"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "m12 6-1.697 1.697a2.4 2.4 0 0 1-1.697.703H1.2v10.8a1.2 1.2 0 0 0 1.2 1.2h19.2a1.2 1.2 0 0 0 1.2-1.2v-12A1.2 1.2 0 0 0 21.6 6H12Z",
          fill: "url(#microsoft-folder_svg__a)"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M2.4 19.8a1.79 1.79 0 0 1-1.168-.44A1.185 1.185 0 0 0 2.4 20.4h19.2c.606 0 1.086-.456 1.168-1.04-.315.27-.72.44-1.168.44H2.4Z",
          fill: "#E67628"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.4,
          d: "M10.603 8.297 12.9 6H12l-1.697 1.697a2.4 2.4 0 0 1-1.697.703H1.2V9h7.706a2.4 2.4 0 0 0 1.697-.703Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ a(
        "linearGradient",
        {
          id: "microsoft-folder_svg__a",
          x1: 12,
          y1: 6,
          x2: 12,
          y2: 20.4,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ t("stop", { stopColor: "#fff", stopOpacity: 0 }),
            /* @__PURE__ */ t("stop", { offset: 0.999, stopColor: "#FFD75E", stopOpacity: 0.3 })
          ]
        }
      ) })
    ]
  }
);
export {
  i as default
};
