import { jsx as c } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5.85l-.925-.925c-.2-.2-.3-.438-.3-.713 0-.275.1-.512.3-.712.2-.2.438-.3.713-.3.275 0 .512.1.712.3l17 17c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3l-.925-.9H5Zm0-2h11.15L5 7.85V19Zm16-.85-2-2V5h-2v1c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 15 8h-4.15l-5-5h3.325c.183-.583.542-1.063 1.075-1.438A2.98 2.98 0 0 1 12 1c.667 0 1.263.187 1.788.562A2.75 2.75 0 0 1 14.85 3H19c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v13.15ZM12 5a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 4a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 3a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 4c0 .283.096.52.288.712A.965.965 0 0 0 12 5Z" })
  }
);
export {
  a as default
};
