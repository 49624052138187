import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 32 28",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement("rect", { width: 32, height: 28, fill: "#29C4AF", rx: 2 }), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#FFF",
      fillRule: "nonzero",
      d: "M16 5v10.55a5.266 5.266 0 0 0-2.333-.55C11.088 15 9 16.79 9 19s2.088 4 4.667 4c2.578 0 4.666-1.79 4.666-4V9H23V5h-7Z"
    }
  ))
);
export {
  l as default
};
