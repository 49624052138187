import { ChangeEvent, useCallback, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { BodyUI } from "@happeouikit/typography";
import { uuidv4 } from "./utils";
import { RadioButtonProps } from "./RadioButton.types";

const RadioButton = ({
  label,
  subtitle,
  description,
  background,
  id,
  onChange,
  "data-testid": dataTestId,
  ...props
}: RadioButtonProps) => {
  const [assignedId] = useState(() => id || `radio_${uuidv4()}`);

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      if (props.disabled) {
        return;
      }
      event.preventDefault();

      // Create a synthetic event
      const syntheticEvent = {
        ...event,
        target: {
          ...event.target,
          checked: true,
          id: assignedId,
        },
      };

      if (typeof onChange === "function") {
        onChange(
          (syntheticEvent as unknown) as ChangeEvent<HTMLInputElement>,
          true
        );
      }
    },
    [assignedId, onChange, props.disabled]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!event.key.match(/enter|spacebar|\W{1}/i)) {
        return;
      }
      event.preventDefault();
      onClick((event as unknown) as React.MouseEvent);
    },
    [onClick]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.disabled) {
        return;
      }

      if (typeof onChange === "function") {
        onChange(event, event.target.checked);
      }
    },
    [onChange, props.disabled]
  );

  return (
    <RadioButtonWrapper background={background}>
      <input
        type="radio"
        aria-describedby={description ? `${assignedId}_description` : undefined}
        onChange={onInputChange}
        {...props}
        id={assignedId}
        onKeyDown={onKeyDown}
        data-testid={dataTestId}
      />
      <StyledRadioButton
        onClick={onClick}
        onKeyDown={onKeyDown}
        background={background}
      />
      {label && (
        <TextContainer>
          <LabelRow>
            {label && (
              <Label
                bold
                htmlFor={assignedId}
                data-tip={props["data-tip"]}
                data-tip-disable={props["data-tip-disable"]}
                onClick={onClick}
              >
                {label}
              </Label>
            )}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </LabelRow>
          {description && (
            <Description id={`${assignedId}_description`}>
              {description}
            </Description>
          )}
        </TextContainer>
      )}
    </RadioButtonWrapper>
  );
};

RadioButton.propTypes = {
  label: PropTypes.node,
  subtitle: PropTypes.node,
  description: PropTypes.node,
};

RadioButton.defaultProps = {
  label: null,
};

const StyledRadioButton = styled.span<{ background?: string }>`
  box-sizing: content-box;
  position: relative;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 2px solid ${({ background }) => background || "var(--color-stroke)"};
  border-radius: var(--radius-circle);
  flex-shrink: 0;
  cursor: pointer;
  input:focus + & {
    :before {
      content: "";
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      border-radius: var(--radius-circle, 50%);
      border: 1px solid var(--color-focus-indicator);
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xxs, 2px);
  margin-left: var(--space-sm);
`;

const LabelRow = styled.div`
  display: flex;
  gap: var(--space-sm, 8px);
`;

const Subtitle = styled(BodyUI)`
  color: var(--color-secondary-text-on-light, #2b313b);
  font-size: 12px;
`;

const Description = styled(BodyUI)`
  color: var(--color-primary-text-on-light, #2b313b);
`;

const Label = styled(BodyUI).attrs({
  as: "label",
})`
  cursor: pointer;
  color: var(--color-primary-text-on-light, #2b313b);
`;

const RadioButtonWrapper = styled.div<{ background?: string }>`
  display: flex;
  align-items: flex-start;
  position: relative;

  user-select: none;

  input {
    z-index: -1;
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
  }

  :hover {
    input:not(:disabled) + ${StyledRadioButton} {
      border-color: ${({ background }) =>
        background || "var(--color-stroke-darken)"};
    }
    input:checked:not(:disabled) + ${StyledRadioButton} {
      border-color: ${({ background }) =>
        background || "var(--color-active-primary)"};
    }
  }

  input:checked + ${StyledRadioButton} {
    border-color: ${({ background }) =>
      background || "var(--color-active-primary)"};
    :after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      border-radius: var(--radius-circle);
      background-color: ${({ background }) =>
        background || "var(--color-active-primary)"};
    }
  }

  input:disabled + ${StyledRadioButton} {
    cursor: not-allowed;
    border-color: var(--color-disabled-text-on-light);
    :after {
      background-color: var(--color-disabled-text-on-light);
    }
  }

  input:disabled ~ div {
    p,
    label {
      color: var(--color-disabled-text-on-light);
      cursor: default;
    }
  }
`;

export default RadioButton;
