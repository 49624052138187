import { jsx as t } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ t("path", { d: "M3 18.5v-13c0-.367.15-.647.45-.839a.99.99 0 0 1 .95-.087l15.4 6.5c.417.184.625.492.625.925 0 .434-.208.742-.625.925l-15.4 6.5a.989.989 0 0 1-.95-.088c-.3-.191-.45-.47-.45-.837ZM5 17l11.85-5L5 7v3.5l6 1.5-6 1.5V17Zm0-5V7v10-5Z" })
  }
);
export {
  h as default
};
