import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M18 18h-6v-5.79c-.66.61-1.53.99-2.5.99a3.7 3.7 0 1 1 3.7-3.7c0 .97-.38 1.84-.99 2.5H18v6Zm1-15H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z" })
);
export {
  a as default
};
