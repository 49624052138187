import { jsxs as h, jsx as c } from "react/jsx-runtime";
const e = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: [
      /* @__PURE__ */ c("rect", { x: 2, y: 2, width: 20, height: 20, rx: 4, fill: "#7F8DE1" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14.16 9.67V6.887c.025-.505-.335-.649-.48-.649H5.565c-.457 0-.529.48-.529.529v10.997h6.243v-7.036s0-.576.529-.576h1.896c.265 0 .457-.288.457-.48Zm-6.482 6.388c0 .264-.216.48-.48.48h-.48a.482.482 0 0 1-.48-.48v-.48c0-.264.216-.48.48-.48h.48c.264 0 .48.216.48.48v.48Zm-.48-1.993c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48h-.48a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48Zm.48-2.93c0 .265-.216.48-.48.48h-.48a.482.482 0 0 1-.48-.48v-.48c0-.264.216-.48.48-.48h.48c.264 0 .48.216.48.48v.48Zm-.48-1.968c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.481h-.48a.482.482 0 0 0-.48.48v.48c0 .265.216.48.48.48h.48Zm3.12 6.89c0 .265-.216.481-.48.481h-.48a.482.482 0 0 1-.48-.48v-.48c0-.264.216-.48.48-.48h.48c.264 0 .48.216.48.48v.48Zm-.48-1.992c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48h-.48a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48Zm.48-2.93c0 .265-.216.48-.48.48h-.48a.482.482 0 0 1-.48-.48v-.48c0-.264.216-.48.48-.48h.48c.264 0 .48.216.48.48v.48Zm-.48-1.968c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.481h-.48a.482.482 0 0 0-.48.48v.48c0 .265.216.48.48.48h.48Zm3.122-.48c0 .264-.216.48-.48.48H12a.482.482 0 0 1-.48-.48v-.48c0-.265.216-.481.48-.481h.48c.264 0 .48.216.48.48v.48Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M18.482 11.616c.144 0 .504.144.48.648v5.499H12.72v-5.619c0-.048.071-.528.528-.528h5.234Zm-3.6 5.162c.263 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48H14.4a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48Zm.48-2.93c0 .265-.217.481-.48.481H14.4a.482.482 0 0 1-.48-.48v-.48c0-.265.216-.48.48-.48h.48c.264 0 .48.215.48.48v.48Zm1.92 2.93c.264 0 .48-.216.48-.48v-.48a.482.482 0 0 0-.48-.48h-.48a.482.482 0 0 0-.48.48v.48c0 .264.216.48.48.48h.48Zm.48-2.93c0 .265-.216.481-.48.481h-.48a.482.482 0 0 1-.48-.48v-.48c0-.265.216-.48.48-.48h.48c.264 0 .48.215.48.48v.48Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  e as default
};
