import * as React from "react";
const SvgIconChannelsBox = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm12.996 7.691-5.07 3.945a1.498 1.498 0 0 1-1.845.008l-5.07-3.945a.755.755 0 0 1 0-1.185l5.07-3.953a1.508 1.508 0 0 1 1.845 0l5.07 3.945c.383.3.383.885 0 1.185Zm-6.465 6.21c.27.21.653.21.923 0l4.612-3.592a.743.743 0 0 1 .915 0l.008.007c.382.3.382.878 0 1.178l-5.07 3.945c-.548.42-1.305.42-1.845 0l-5.07-3.945a.748.748 0 0 1 0-1.178.743.743 0 0 1 .915 0l4.612 3.585Z"
    />
  </svg>
);
export default SvgIconChannelsBox;
