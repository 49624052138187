import { jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M11 11h2.1l6.2-6.2-2.1-2.1L11 8.9V11Zm9-6.9.7-.7c.2-.2.3-.433.3-.7 0-.267-.1-.5-.3-.7l-.7-.7a.96.96 0 0 0-.7-.3c-.267 0-.5.1-.7.3l-.7.7L20 4.1Zm-8 17.525c-.133 0-.267-.025-.4-.075a1.2 1.2 0 0 1-.35-.2C8.817 19.2 7 17.204 5.8 15.363 4.6 13.521 4 11.8 4 10.2c0-2.5.804-4.492 2.413-5.975C8.021 2.742 9.883 2 12 2c.45 0 .896.037 1.338.112.441.075.87.18 1.287.313L13 4.075a3.447 3.447 0 0 0-.488-.063A10.165 10.165 0 0 0 12 4c-1.683 0-3.104.579-4.263 1.737C6.579 6.896 6 8.383 6 10.2c0 1.183.492 2.537 1.475 4.062.983 1.525 2.492 3.221 4.525 5.088 2.033-1.867 3.542-3.563 4.525-5.088C17.508 12.737 18 11.383 18 10.2a6.122 6.122 0 0 0-.1-1.15l1.65-1.65c.15.433.263.883.338 1.35.075.467.112.95.112 1.45 0 1.6-.6 3.321-1.8 5.163-1.2 1.841-3.017 3.837-5.45 5.987a1.2 1.2 0 0 1-.35.2c-.133.05-.267.075-.4.075Z" })
  }
);
export {
  h as default
};
