import { jsx as t } from "react/jsx-runtime";
const c = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "m14.125 7.694 3.6 3.6c.1.1.17.209.212.325a1.1 1.1 0 0 1 .063.375c0 .134-.021.259-.063.375a.871.871 0 0 1-.212.325l-3.6 3.6a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275c-.183-.183-.275-.42-.275-.712s.092-.53.275-.713l1.875-1.875H8a.97.97 0 0 1-.713-.287.97.97 0 0 1-.287-.713c0-.283.096-.52.287-.712A.968.968 0 0 1 8 10.994h6.6L12.725 9.12a.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7a.99.99 0 0 1 .7-.287.906.906 0 0 1 .7.262Z" })
  }
);
export {
  c as default
};
