import { PureComponent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IconButton, ButtonSecondary } from "@happeouikit/buttons";
import { IconFilter, type IconAI as Icon } from "@happeouikit/icons";
// @ts-ignore
import { Checkbox } from "@happeouikit/form-elements";
import { Container, ActionRow } from "./styles";
import { Popover } from "@happeouikit/layout";
import { getAnchorPosition, uuidv4 } from "./utils";

interface Action {
  name: string;
  callback: () => void;
  type: string;
}

interface FilterMenuProps {
  actions: Action[];
  menuPosition?: string;
  initialChecked?: boolean;
  icon?: JSX.Element | Element | React.ComponentType<any> | typeof Icon;
  text?: string | object;
  selected?: string[];
  dropdownId?: string;
}

/**
 *
 * FilterMenu
 * Menu with checkbox actions
 *
 */
class FilterMenu extends PureComponent<FilterMenuProps> {
  static propTypes = {
    // TODO: add format of the aciton
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        callback: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
    menuPosition: PropTypes.string,
    initialChecked: PropTypes.bool,
    icon: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    selected: PropTypes.array,
    dropdownId: PropTypes.string,
    ariaLabel: (props: any, propName: string) => {
      if (!props[propName]) {
        return new Error(
          "Provide an ariaLabel prop to the ActionsMenu component. Ensure that is describes what this button does."
        );
      }
      return null;
    },
  };

  static defaultProps = {
    menuPosition: "left",
    initialChecked: false,
    icon: IconFilter,
    selected: [],
  };

  constructor(props: FilterMenuProps) {
    super(props);

    this.state = {
      open: false,
      values: props.selected,
      dropdownId: props.dropdownId || uuidv4(),
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const { initialChecked, actions } = this.props;
    // If initialChecked is true, add all the values to the state thus marking
    // them as checked in checkboxes
    if (initialChecked) {
      const values = actions.map((val) => val.type);
      this.setState({ values });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e: any) => {
    if (
      // @ts-ignore
      this.wrapperRef &&
      // @ts-ignore
      !this.wrapperRef.contains(e.target) &&
      // @ts-ignore
      !this.popoverRef?.contains(e.target)
    ) {
      this.setState({ open: false });
    }
  };

  toggleMenu = () => {
    // @ts-ignore
    if (!this.state.open) {
      this.setState({
        open: true,
      });
      setTimeout(() => {
        // @ts-ignore
        this.popoverRef.querySelector("span[tabindex='0']").focus();
      }, 1);
    } else {
      this.setState({
        open: false,
      });
      // @ts-ignore
      this.wrapperRef.querySelector("button").focus();
    }
  };

  /**
   * Set checked value on the state and pass them to callback
   * @param value
   * @param {Function} cb - callback function
   */
  changeValue = (value: any, cb: any) => {
    const { values } = this.state as any;
    let vals;
    if (values.includes(value)) {
      vals = values.filter((v: any) => v !== value);
    } else {
      vals = [...values, value];
    }

    this.setState({ values: vals });
    cb(vals);
  };

  onKeyDown = (event: React.KeyboardEvent) => {
    // Close on esc
    if (event.key === "Escape") {
      this.toggleMenu();
    }
  };

  render() {
    const { open, values, dropdownId, ariaLabel } = this.state as any;
    const { actions, menuPosition, text } = this.props;
    const Icon = this.props.icon;
    const numSelected = values.length;
    // Backwards compatibility when using just left right position
    const anchorPosition = getAnchorPosition(menuPosition as string);

    return (
      <Wrapper
        // @ts-ignore
        ref={(node) => (this.wrapperRef = node)}
        isActive={numSelected}
        // @ts-ignore
        onKeyDown={(event) => this.onKeyDown(event)}
      >
        {text ? (
          <ButtonSecondary
            // @ts-ignore
            icon={() => <Icon width="20px" height="20px" />}
            onClick={this.toggleMenu}
            text={text}
            aria-label={ariaLabel || text}
            aria-expanded={open}
            aria-controls={dropdownId}
          />
        ) : (
          <IconButton
            tabIndex="0"
            // @ts-ignore
            icon={() => <Icon width="20px" height="20px" />}
            onClick={this.toggleMenu}
            aria-label={ariaLabel}
            aria-expanded={open}
            aria-controls={dropdownId}
          />
        )}
        {numSelected ? <Indicator>{numSelected}</Indicator> : null}
        {open && (
          <Popover
            // @ts-ignore
            anchor={{ current: this.wrapperRef }}
            anchorPosition={anchorPosition}
            // @ts-ignore
            ref={(node) => (this.popoverRef = node)}
          >
            <Container tabindex={0} id={dropdownId}>
              {actions.map((action, i) => (
                <ActionRow key={i} data-testid="filter-menu-item">
                  <Checkbox
                    checked={values.includes(action.type)}
                    // @ts-ignore
                    onChange={() =>
                      this.changeValue(action.type, action.callback)
                    }
                    label={action.name}
                  />
                </ActionRow>
              ))}
            </Container>
          </Popover>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div<any>`
  position: relative;
  display: inline-block;

  button {
    ${({ isActive }) => {
      if (isActive) {
        return `
        background: var(--color-active-lighten90);
      `;
      }
    }}
  }
`;

const Indicator = styled.span`
  font-family: var(--default-font-family);
  background: var(--color-active-primary);
  color: var(--color-surface);
  position: absolute;
  right: -7px;
  border-radius: 50px;
  font-size: 12px;
  padding: 3px 5px;
  top: -6px;
  display: block;
  width: 10px;
  text-align: center;
  letter-spacing: -1px;
`;

export default FilterMenu;
