import { jsxs as t, jsx as e } from "react/jsx-runtime";
const o = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e("path", { d: "M12 14.8a.48.48 0 0 1-.35-.15l-2.8-2.8c-.15-.15-.187-.33-.112-.538.075-.208.229-.312.462-.312h5.6c.233 0 .387.104.462.312.075.209.038.388-.112.538l-2.8 2.8a.48.48 0 0 1-.35.15Z" }),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        }
      )
    ]
  }
);
export {
  o as default
};
