import { jsx as c } from "react/jsx-runtime";
const A = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M4 6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 6 4h7c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412 0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 13 8H6c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0 1 4 6Zm0 6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 6 10h12c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.021-.588 1.413A1.925 1.925 0 0 1 18 14H6c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 4 12Zm0 6c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 6 16h3c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.021-.587 1.413A1.928 1.928 0 0 1 9 20H6c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 4 18Z" })
  }
);
export {
  A as default
};
