import { jsx as t } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "m16.306 14.125-3.6 3.6c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063 1.1 1.1 0 0 1-.375-.063.871.871 0 0 1-.325-.212l-3.6-3.6a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7.184-.183.42-.275.712-.275s.53.092.713.275l1.875 1.875V8a.97.97 0 0 1 .287-.713.97.97 0 0 1 .713-.287c.284 0 .52.096.712.287.192.192.288.43.288.713v6.6l1.875-1.875a.948.948 0 0 1 .7-.275c.284 0 .517.092.7.275a.99.99 0 0 1 .287.7.906.906 0 0 1-.262.7Z" })
  }
);
export {
  s as default
};
