/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftSharePoint = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.256 13.28a6.14 6.14 0 1 0 0-12.28 6.14 6.14 0 0 0 0 12.28Z"
      fill="#036C70"
    />
    <path
      d="M17.372 18.395a5.628 5.628 0 1 0 0-11.255 5.628 5.628 0 0 0 0 11.255Z"
      fill="#1A9BA1"
    />
    <path
      d="M13.023 22.488a4.349 4.349 0 1 0 0-8.697 4.349 4.349 0 0 0 0 8.697Z"
      fill="#37C6D0"
    />
    <path
      opacity={0.1}
      d="M13.28 6.541v10.918a.942.942 0 0 1-.937.936H8.68c-.005-.087-.005-.168-.005-.255-.002-.086.001-.171.01-.256a4.335 4.335 0 0 1 2.604-3.73v-.952a6.132 6.132 0 0 1-5.086-7.086c.026-.172.062-.343.108-.511h6.032a.94.94 0 0 1 .936.936Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M11.831 6.116H6.203a6.133 6.133 0 0 0 5.321 7.12c-1.586.752-2.742 2.888-2.84 4.648a2.14 2.14 0 0 0-.01.256 3.415 3.415 0 0 0 .072.767h3.085a.942.942 0 0 0 .936-.936V7.052a.94.94 0 0 0-.936-.936Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M11.831 6.116H6.203a6.133 6.133 0 0 0 5.223 7.107c-1.535.807-2.645 2.937-2.741 4.66h3.146a.944.944 0 0 0 .936-.936V7.052a.94.94 0 0 0-.936-.936Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M11.32 6.116H6.202a6.133 6.133 0 0 0 4.538 6.975 8.453 8.453 0 0 0-2.056 4.793h2.634a.94.94 0 0 0 .937-.937V7.052a.937.937 0 0 0-.937-.936Z"
      fill="#000"
    />
    <path
      d="M1.938 6.116h9.38c.518 0 .938.42.938.938v9.38c0 .518-.42.938-.938.938h-9.38A.938.938 0 0 1 1 16.434v-9.38c0-.518.42-.938.938-.938Z"
      fill="url(#microsoft-share-point_svg__a)"
    />
    <path
      d="M5.189 11.63a1.732 1.732 0 0 1-.537-.568 1.544 1.544 0 0 1-.187-.776 1.493 1.493 0 0 1 .348-1.005c.247-.282.566-.49.924-.603.407-.134.833-.2 1.262-.196a4.805 4.805 0 0 1 1.663.233v1.177a2.595 2.595 0 0 0-.752-.307 3.663 3.663 0 0 0-.88-.105 1.903 1.903 0 0 0-.904.191.593.593 0 0 0-.358.543.55.55 0 0 0 .153.385c.118.122.256.221.41.293a12.902 12.902 0 0 0 .877.382c.335.132.66.291.968.478.233.144.43.341.572.576.146.266.216.567.204.87.017.376-.098.746-.325 1.046a1.82 1.82 0 0 1-.87.586 3.94 3.94 0 0 1-1.24.18 6.561 6.561 0 0 1-1.126-.092 3.854 3.854 0 0 1-.912-.268v-1.241c.28.2.59.348.921.44a3.6 3.6 0 0 0 1.016.163c.32.02.638-.048.921-.196a.618.618 0 0 0 .314-.552.589.589 0 0 0-.174-.422 1.754 1.754 0 0 0-.48-.335 16.373 16.373 0 0 0-.903-.404 5.139 5.139 0 0 1-.905-.473Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="microsoft-share-point_svg__a"
        x1={2.955}
        y1={5.383}
        x2={10.3}
        y2={18.105}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#058F92" />
        <stop offset={0.5} stopColor="#038489" />
        <stop offset={1} stopColor="#026D71" />
      </linearGradient>
    </defs>
  </svg>
);

export default MicrosoftSharePoint;
