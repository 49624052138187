import { jsx as a } from "react/jsx-runtime";
const t = (A) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ a("path", { d: "M9 18h6a.968.968 0 0 0 .713-.288A.967.967 0 0 0 16 17a.967.967 0 0 0-.287-.712A.968.968 0 0 0 15 16H9a.965.965 0 0 0-.712.288A.965.965 0 0 0 8 17c0 .283.096.52.288.712A.965.965 0 0 0 9 18Zm0-4h6a.968.968 0 0 0 .713-.288A.967.967 0 0 0 16 13a.97.97 0 0 0-.287-.713A.97.97 0 0 0 15 12H9a.967.967 0 0 0-.712.287A.968.968 0 0 0 8 13c0 .283.096.52.288.712A.965.965 0 0 0 9 14Zm-3 8c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 2h7.175a1.978 1.978 0 0 1 1.4.575l4.85 4.85a1.978 1.978 0 0 1 .575 1.4V20c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 22H6Zm7-18H6v16h12V9h-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 8V4ZM6 4v5-5 16V4Z" })
  }
);
export {
  t as default
};
