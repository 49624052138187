import * as React from "react";
const SvgIconHangouts = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M12 2c-4.83 0-8.75 3.92-8.75 8.75 0 4.599 4.144 8.334 8.75 8.334V22c5.296-2.582 8.749-6.847 8.749-11.25 0-4.83-3.92-8.75-8.75-8.75Zm-.416 10c0 1.153-.743 2.08-1.665 2.08V12H7.838V8.248h3.752V12h-.006Zm4.997 0c0 1.153-.743 2.08-1.665 2.08V12h-2.081V8.248h3.752V12h-.006Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconHangouts;
