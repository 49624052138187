import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M18 9a.968.968 0 0 1-.712-.287A.967.967 0 0 1 17 8V7h-1a.968.968 0 0 1-.713-.287A.967.967 0 0 1 15 6c0-.283.096-.52.287-.713A.968.968 0 0 1 16 5h1V4c0-.283.096-.52.288-.712A.968.968 0 0 1 18 3c.283 0 .52.096.712.288.192.191.288.429.288.712v1h1c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 20 7h-1v1c0 .283-.096.52-.288.713A.968.968 0 0 1 18 9ZM6.4 20.4a.99.99 0 0 1-.95-.087c-.3-.192-.45-.471-.45-.838V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 7 3h7c-.333.5-.583.98-.75 1.438-.167.458-.25.979-.25 1.562 0 1.2.38 2.258 1.137 3.175.759.917 1.713 1.492 2.863 1.725.383.05.717.075 1 .075a7.87 7.87 0 0 0 1-.075v8.575c0 .367-.15.646-.45.837a.99.99 0 0 1-.95.088L12 18l-5.6 2.4Z" })
  }
);
export {
  t as default
};
