import * as React from "react";
const SvgIconFileDownload = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M19 18v-3c0-.55.45-1 1-1s1 .45 1 1v4c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-4c0-.55.45-1 1-1s1 .45 1 1v3c0 .55.45 1 1 1h12c.55 0 1-.45 1-1Zm-4.12-7.21L13 12.67V4c0-.55-.45-1-1-1s-1 .45-1 1v8.67l-1.89-1.88A.996.996 0 1 0 7.7 12.2l3.59 3.59c.39.39 1.02.39 1.41 0l3.59-3.59a.996.996 0 1 0-1.41-1.41Z" />
  </svg>
);
export default SvgIconFileDownload;
