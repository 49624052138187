import { jsx as e } from "react/jsx-runtime";
const d = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2 6.703A4.703 4.703 0 0 1 6.703 2h10.594A4.703 4.703 0 0 1 22 6.703v10.594A4.703 4.703 0 0 1 17.297 22H6.703A4.703 4.703 0 0 1 2 17.297V6.703Zm15.238 2.44a2.381 2.381 0 1 1-4.762 0 2.381 2.381 0 0 1 4.762 0ZM9.113 6.762a2.351 2.351 0 0 0-2.351 2.351v7.655c0 .26.21.47.47.47h1.94a2.351 2.351 0 0 0 2.352-2.351V7.232a.47.47 0 0 0-.47-.47h-1.94Z",
        fill: "#2C275F"
      }
    )
  }
);
export {
  d as default
};
