import { jsxs as c, jsx as t } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    ...a,
    children: [
      /* @__PURE__ */ t("path", { d: "M17.855 1.122c.029-.163.261-.163.29 0a4.6 4.6 0 0 0 3.733 3.733c.163.029.163.261 0 .29a4.6 4.6 0 0 0-3.733 3.733c-.029.163-.261.163-.29 0a4.6 4.6 0 0 0-3.733-3.733c-.162-.029-.162-.261 0-.29a4.6 4.6 0 0 0 3.733-3.733" }),
      /* @__PURE__ */ t("path", { d: "M18 11q.364 0 .717-.042a6.95 6.95 0 0 1-1.407 2.59c-.9 1.05-1.32 2.42-1.32 3.8v.18c0 .81-.66 1.47-1.47 1.47H9.47c-.81 0-1.47-.66-1.47-1.47 0-1.43-.32-2.85-1.26-3.92-1.37-1.56-2.06-3.73-1.59-6.06.58-2.89 2.83-5.02 5.74-5.46a7.3 7.3 0 0 1 1.887-.043A6 6 0 0 0 18 11M14.75 20c.14 0 .25.11.25.25 0 .97-.78 1.75-1.75 1.75h-2.5C9.78 22 9 21.22 9 20.25c0-.14.11-.25.25-.25z" })
    ]
  }
);
export {
  s as default
};
