import { jsxs as a, jsx as c } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ c(
        "path",
        {
          d: "M6.188 3h11.625C19.575 3 21 4.462 21 6.27v11.46c0 1.808-1.425 3.27-3.188 3.27H6.188C4.425 21 3 19.538 3 17.73V6.27C3 4.461 4.425 3 6.188 3Z",
          fill: "#001E36"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M6.446 7.977v7.915c0 .07.03.1.092.1H8.03a.096.096 0 0 0 .085-.107v-2.47c.146 0 .254 0 .33.008.078.008.2.008.385.008.631 0 1.208-.07 1.731-.262.5-.184.931-.507 1.239-.938.307-.431.461-.977.461-1.639 0-.346-.061-.677-.177-1a2.45 2.45 0 0 0-.561-.877 2.747 2.747 0 0 0-1.031-.63c-.43-.162-.954-.239-1.585-.239-.23 0-.461 0-.7.008-.115.004-.226.006-.334.007-.116.002-.227.004-.335.008-.1.004-.198.006-.292.008-.1.002-.197.004-.293.008-.176.007-.3.007-.43.007-.054 0-.077.031-.077.085Zm1.677 3.985V9.377c.054-.008.161-.015.315-.008.146-.007.323-.007.523-.007.316-.008.623.038.908.153.223.085.408.239.538.439.123.208.185.446.177.684 0 .339-.077.6-.238.793-.17.192-.385.338-.631.408a2.874 2.874 0 0 1-.885.138h-.407c-.086 0-.178-.007-.277-.014l-.023-.001Z",
          fill: "#31A8FF"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          d: "M16.323 11.23c.261.055.507.14.738.262.03.016.07.016.139 0a.095.095 0 0 0 .038-.077v-1.253c0-.031-.008-.062-.015-.093-.016-.03-.046-.061-.077-.069a2.705 2.705 0 0 0-.662-.177 6.63 6.63 0 0 0-.954-.07c-.546 0-1 .086-1.361.27-.33.154-.616.4-.808.708-.17.284-.261.6-.261.93-.008.27.053.54.177.785.146.262.346.485.584.654.339.23.7.415 1.092.554.377.146.631.261.754.361.123.1.185.2.185.308 0 .139-.085.27-.208.323-.138.07-.346.108-.638.108-.308 0-.616-.039-.908-.115a3.293 3.293 0 0 1-.954-.377c-.023-.016-.046-.024-.069-.008-.023.015-.03.046-.03.07v1.338c-.009.061.03.115.084.146.246.115.515.2.784.246.347.07.693.1 1.047.1.553 0 1.015-.085 1.392-.246.346-.139.646-.377.861-.685.2-.3.308-.661.3-1.023a1.66 1.66 0 0 0-.177-.792 1.788 1.788 0 0 0-.607-.639c-.37-.23-.77-.423-1.185-.561a7.065 7.065 0 0 1-.546-.254.869.869 0 0 1-.261-.208.342.342 0 0 1-.077-.2c0-.069.023-.146.061-.207a.395.395 0 0 1 .239-.154c.146-.039.307-.062.461-.054.293 0 .577.038.862.1Z",
          fill: "#31A8FF"
        }
      )
    ]
  }
);
export {
  h as default
};
