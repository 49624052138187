import { jsx as c } from "react/jsx-runtime";
const t = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M12 22c0-2.283.742-4.267 2.225-5.95 1.483-1.683 3.325-2.675 5.525-2.975.333-.05.625.05.875.3s.35.542.3.875c-.3 2.2-1.292 4.042-2.975 5.525C16.267 21.258 14.283 22 12 22Zm0 0c0-2.283-.742-4.267-2.225-5.95-1.483-1.683-3.325-2.675-5.525-2.975-.333-.05-.625.05-.875.3s-.35.542-.3.875c.3 2.2 1.292 4.042 2.975 5.525C7.733 21.258 9.717 22 12 22Zm0-11.5c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775C13.292 5.742 12.7 5.5 12 5.5s-1.292.242-1.775.725C9.742 6.708 9.5 7.3 9.5 8s.242 1.292.725 1.775c.483.483 1.075.725 1.775.725Zm0 4.5c-.65 0-1.237-.18-1.762-.538A3.042 3.042 0 0 1 9.1 13.05c-.083 0-.158.004-.225.012a3.033 3.033 0 0 1-2.45-.912A3.033 3.033 0 0 1 5.5 9.925 2.978 2.978 0 0 1 6.2 8a3.368 3.368 0 0 1-.5-.913 2.97 2.97 0 0 1-.175-1.012c0-.867.304-1.608.913-2.225a2.99 2.99 0 0 1 2.437-.913c.067.009.142.013.225.013a3.048 3.048 0 0 1 1.138-1.413A3.055 3.055 0 0 1 12 1c.65 0 1.238.179 1.763.537.525.359.904.83 1.137 1.413.083 0 .158-.004.225-.013a2.99 2.99 0 0 1 2.437.913c.609.617.913 1.358.913 2.225 0 .35-.054.687-.163 1.012-.108.325-.279.63-.512.913.217.283.383.587.5.912.117.325.175.663.175 1.013 0 .867-.304 1.608-.913 2.225a2.99 2.99 0 0 1-2.437.912 1.903 1.903 0 0 0-.225-.012 3.044 3.044 0 0 1-1.137 1.412A3.056 3.056 0 0 1 12 15Z" })
  }
);
export {
  t as default
};
