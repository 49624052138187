import { jsx as c } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M17.675 11.5c-.55 0-1.02-.196-1.413-.588a1.926 1.926 0 0 1-.587-1.412V7c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 17.675 5H19c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v2.5c0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 19 11.5h-1.325Zm-6.325 0c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 9.35 9.5V7c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 11.35 5h1.325c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412v2.5c0 .55-.196 1.02-.588 1.412a1.926 1.926 0 0 1-1.412.588H11.35Zm-6.325 0c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 3.025 9.5V7c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 5.025 5H6.35c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v2.5c0 .55-.196 1.02-.587 1.412a1.926 1.926 0 0 1-1.413.588H5.025Zm0 7.5c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 3.025 17v-2.5c0-.55.196-1.02.588-1.412a1.926 1.926 0 0 1 1.412-.588H6.35c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412V17c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 6.35 19H5.025Zm6.325 0c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 9.35 17v-2.5c0-.55.196-1.02.588-1.412a1.926 1.926 0 0 1 1.412-.588h1.325c.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412V17c0 .55-.196 1.02-.588 1.413a1.926 1.926 0 0 1-1.412.587H11.35Zm6.325 0c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 15.675 17v-2.5c0-.55.196-1.02.587-1.412a1.926 1.926 0 0 1 1.413-.588H19c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412V17c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 19h-1.325Z" })
  }
);
export {
  h as default
};
