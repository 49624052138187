/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from "react";
import { Button } from "./Button";
import { IconButtonProps } from "./Button.types";

export const IconButton = forwardRef(
  (props: IconButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const { icon, ...rest } = props;
    return (
      <Button iconButton ref={ref} {...rest}>
        {icon}
      </Button>
    );
  }
);
