/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Zoom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.535 5.68C3 6.73 3 8.105 3 10.855v2.29c0 2.75 0 4.125.535 5.175a4.909 4.909 0 0 0 2.145 2.145C6.73 21 8.105 21 10.855 21h2.29c2.75 0 4.125 0 5.175-.535a4.908 4.908 0 0 0 2.145-2.145C21 17.27 21 15.895 21 13.145v-2.29c0-2.75 0-4.124-.535-5.175a4.909 4.909 0 0 0-2.145-2.145C17.27 3 15.895 3 13.145 3h-2.29c-2.75 0-4.124 0-5.175.535A4.91 4.91 0 0 0 3.535 5.68Zm10.668 9.593H7.594c-.73 0-1.321-.55-1.321-1.228v-4.5h6.608c.73 0 1.322.55 1.322 1.228v4.5Zm3.524 0-2.643-1.636v-2.455l2.643-1.637v5.728Z"
    />
  </svg>
);

export default Zoom;
