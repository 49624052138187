import { jsx as c } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ c("path", { d: "M6.5 20c-1.517 0-2.812-.526-3.887-1.576C1.538 17.374 1 16.091 1 14.574c0-1.3.392-2.458 1.175-3.475.783-1.016 1.808-1.666 3.075-1.95a6.97 6.97 0 0 1 2.138-3.4A6.861 6.861 0 0 1 11 4.074v8.075l-.9-.875A.932.932 0 0 0 9.413 11c-.275 0-.513.1-.713.3a.948.948 0 0 0-.275.7c0 .284.092.517.275.7l2.6 2.6c.2.2.433.3.7.3.267 0 .5-.1.7-.3l2.6-2.6a.98.98 0 0 0 .288-.688.932.932 0 0 0-.288-.712.979.979 0 0 0-.687-.288.975.975 0 0 0-.713.263l-.9.875V4.074c1.717.234 3.146 1.004 4.288 2.312C18.429 7.695 19 9.233 19 11c1.15.134 2.104.63 2.863 1.487A4.41 4.41 0 0 1 23 15.5c0 1.25-.437 2.313-1.312 3.188S19.75 20 18.5 20h-12Z" })
  }
);
export {
  t as default
};
