import * as React from "react";
const SvgIconFileGeneric = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9.828a2 2 0 0 0-.586-1.414l-5.828-5.828A2 2 0 0 0 12.172 2H6Zm10.586 8H13a1 1 0 0 1-1-1V5.414c0-.89 1.077-1.337 1.707-.707l3.586 3.586c.63.63.184 1.707-.707 1.707Z"
    />
  </svg>
);
export default SvgIconFileGeneric;
