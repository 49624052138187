import { Theme, ThemeWithOnlySemanticColors } from "./theme";
import { StringVariablesObject } from "../DesignTokens/designTokens";

/** e.g. { "--font-size-body-md": "24px" } */
type CssVariables = { [key: string]: string };

/**
 * converts lower camelCase string into kebab-case, e.g. "boxShadow" -> "box-shadow"
 */
export const slugify = (str: string) =>
  str.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);

const recursivelyConvertToCssVariables = (
  obj: StringVariablesObject,
  prefixBeforeDash: string
): CssVariables => {
  const variables: StringVariablesObject = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const cssKey = `${prefixBeforeDash}-${slugify(key)}`;
    if (typeof value === "string") {
      variables[cssKey] = value;
    } else {
      Object.assign(variables, recursivelyConvertToCssVariables(value, cssKey));
    }
  });
  return variables as CssVariables;
};

export const toCssVariables = (
  theme: Theme | ThemeWithOnlySemanticColors,
  prefix?: string
): CssVariables => {
  const variables = recursivelyConvertToCssVariables(
    theme,
    prefix ? `--${prefix}` : "-"
  );
  Object.keys(variables).forEach((varName) => {
    // remove font variants - they're not really useful as css variables because they're complex
    if (varName.startsWith("--font-variant")) {
      delete variables[varName];
    }
    // remove animation keyframes - they're not really useful as css variables because they're complex
    if (varName.startsWith("--animation-keyframes")) {
      delete variables[varName];
    }
    // remove theme name (obviously irrelevant)
    if (varName.startsWith("--name")) {
      delete variables[varName];
    }
  });
  return variables;
};

export const toCss = (
  theme: Theme | ThemeWithOnlySemanticColors,
  prefix?: string
): string => {
  const cssVariables = toCssVariables(theme, prefix);
  return Object.keys(cssVariables)
    .map((key) => `${key}: ${cssVariables[key]};`)
    .join("\r\n");
};
