/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const DropboxSign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m2.999 9.646 9 6.159V8.193L3 2v7.646Z" fill="#0061FE" />
    <path d="M21.001 2 12 8.195v7.61l9.001-6.158V2.001Z" fill="#1E1919" />
    <path d="M21.001 14.354 12 8.195v7.612L21.001 22v-7.646Z" fill="#0061FE" />
    <path d="m2.999 22 9-6.194v-7.61l-9 6.158V22Z" fill="#1E1919" />
  </svg>
);

export default DropboxSign;
