import { jsxs as a, jsx as e } from "react/jsx-runtime";
const l = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3.587 16.413C3.979 16.804 4.45 17 5 17h14c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413V5c0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 19 3H5c-.55 0-1.021.196-1.413.587A1.928 1.928 0 0 0 3 5v10c0 .55.196 1.021.587 1.413ZM17 13H7c-.2 0-.35-.092-.45-.275a.44.44 0 0 1 .05-.525l2-2.675c.1-.133.233-.2.4-.2s.3.067.4.2L11.25 12l2.6-3.475c.1-.133.233-.2.4-.2s.3.067.4.2L17.4 12.2a.44.44 0 0 1 .05.525A.484.484 0 0 1 17 13Z"
        }
      ),
      /* @__PURE__ */ e("path", { d: "M4 19a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2H4Z" })
    ]
  }
);
export {
  l as default
};
