import { jsx as h } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M14 4v5c0 1.12.37 2.16 1 3H9c.65-.86 1-1.9 1-3V4h4Zm3-2H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.316-.842 2.43-2.018 2.836C5.46 12.016 5 12.448 5 13a1 1 0 0 0 1 1h4.97v7a1 1 0 1 0 2 0v-7H18a1 1 0 0 0 1-1c0-.552-.46-.984-.982-1.164A2.996 2.996 0 0 1 16 9V4h1c.55 0 1-.45 1-1s-.45-1-1-1Z" })
  }
);
export {
  s as default
};
