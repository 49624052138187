import { jsxs as t, jsx as a } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a("path", { d: "M14.075 21H5c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 5 4h1V3a1 1 0 0 1 2 0v1h8V3a1 1 0 1 1 2 0v1h1c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v8.075a3.456 3.456 0 0 0-.487-.063 10.227 10.227 0 0 0-1.025 0c-.159.009-.321.03-.488.063V10H5v9h9.075c-.033.167-.054.33-.063.488a10.165 10.165 0 0 0 0 1.024c.009.159.03.321.063.488Z" }),
      /* @__PURE__ */ a("path", { d: "m18.586 20-1.414 1.414a1 1 0 1 0 1.414 1.414L20 21.414l1.414 1.414a1 1 0 1 0 1.414-1.414L21.414 20l1.414-1.414a1 1 0 1 0-1.414-1.414L20 18.586l-1.414-1.414a1 1 0 1 0-1.414 1.414L18.586 20Z" })
    ]
  }
);
export {
  c as default
};
