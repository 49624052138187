/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Twitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5 6.058c-.7.32-1.453.53-2.234.63a3.925 3.925 0 0 0 1.707-2.175 7.817 7.817 0 0 1-2.47.96c-.718-.786-1.726-1.244-2.87-1.244-2.134 0-3.878 1.756-3.878 3.922 0 .311.036.613.1.896a11.051 11.051 0 0 1-8.029-4.095 3.9 3.9 0 0 0-.527 1.965c0 1.362.681 2.569 1.735 3.255a3.833 3.833 0 0 1-1.771-.458v.028c0 1.901 1.344 3.492 3.124 3.848a3.81 3.81 0 0 1-1.753.065c.49 1.544 1.917 2.696 3.633 2.724a7.708 7.708 0 0 1-4.84 1.682c-.31 0-.618-.019-.927-.055a10.978 10.978 0 0 0 5.976 1.764c7.157 0 11.09-5.978 11.09-11.162 0-.173 0-.338-.01-.512A7.814 7.814 0 0 0 21.5 6.058Z"
      fill="#1DA1F2"
    />
  </svg>
);

export default Twitter;
