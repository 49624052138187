import * as React from "react";
const SvgIconSend = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="m3.671 20.4 17.45-7.48a1 1 0 0 0 0-1.84L3.671 3.6a.993.993 0 0 0-1.39.91l-.01 4.61c0 .5.37.93.87.99L17.271 12l-14.13 1.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91Z" />
  </svg>
);
export default SvgIconSend;
