import { jsx as h } from "react/jsx-runtime";
const Z = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M7 21v-2h2v2H7Zm0-8v-2h2v2H7Zm4 0v-2h2v2h-2Zm0 8v-2h2v2h-2Zm-8-4v-2h2v2H3Zm0 4v-2h2v2H3Zm0-8v-2h2v2H3Zm0-4V7h2v2H3Zm8 8v-2h2v2h-2Zm8-8V7h2v2h-2Zm0 4v-2h2v2h-2ZM4 5a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 4c0-.283.096-.521.288-.713A.967.967 0 0 1 4 3h16c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 5H4Zm15 12v-2h2v2h-2Zm-4 4v-2h2v2h-2ZM11 9V7h2v2h-2Zm8 12v-2h2v2h-2Zm-4-8v-2h2v2h-2Z" })
  }
);
export {
  Z as default
};
