import * as React from "react";
const SvgIconTwitter = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M20.252 6.557s1.604-.128 2.248-.615c0 0-.154.56-2.085 2.335 0 0 .65 9.573-9.253 11.917 0 0-5.023 1.088-8.662-1.687 0 0 3.81.687 5.953-1.716 0 0-2.765.028-3.639-2.832 0 0 1.044.229 1.664-.057 0 0-3.132-.659-3.075-4.12 0 0 .846.63 1.608.515 0 0-2.821-2.432-1.072-5.463 0 0 3.78 4.531 8.465 4.262a4.154 4.154 0 0 1-.126-1.015C12.278 5.828 14.081 4 16.304 4c1.123 0 2.14.467 2.87 1.22a4.566 4.566 0 0 0 2.737-.887s.063.873-1.659 2.224Z" />
  </svg>
);
export default SvgIconTwitter;
