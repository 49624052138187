import { jsx as A } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ A("path", { d: "M19 20c0 .283-.096.52-.288.712A.965.965 0 0 1 18 21a.965.965 0 0 1-.712-.288A.965.965 0 0 1 17 20v-4c0-.283.096-.521.288-.713A.967.967 0 0 1 18 15c.283 0 .52.096.712.287.192.192.288.43.288.713v4ZM7 20c0 .283-.096.52-.287.712A.968.968 0 0 1 6 21a.968.968 0 0 1-.713-.288A.967.967 0 0 1 5 20v-8a.97.97 0 0 1 .287-.713A.97.97 0 0 1 6 11a.97.97 0 0 1 .713.287A.97.97 0 0 1 7 12v8Zm14-8c0 .283-.096.52-.288.712A.965.965 0 0 1 20 13h-4a.968.968 0 0 1-.713-.288A.967.967 0 0 1 15 12a.97.97 0 0 1 .287-.713A.97.97 0 0 1 16 11h1V4c0-.283.096-.52.288-.712A.965.965 0 0 1 18 3c.283 0 .52.096.712.288A.965.965 0 0 1 19 4v7h1c.283 0 .52.096.712.287.192.192.288.43.288.713Zm-6 4a.968.968 0 0 1-.288.713A.967.967 0 0 1 14 17h-1v3c0 .283-.096.52-.288.712A.965.965 0 0 1 12 21a.968.968 0 0 1-.713-.288A.967.967 0 0 1 11 20v-3h-1a.97.97 0 0 1-.713-.287A.97.97 0 0 1 9 16a.97.97 0 0 1 .287-.713A.97.97 0 0 1 10 15h4c.283 0 .52.096.712.287.192.192.288.43.288.713Zm-2-4c0 .283-.096.52-.288.712A.965.965 0 0 1 12 13a.968.968 0 0 1-.713-.288A.967.967 0 0 1 11 12V4c0-.283.096-.52.287-.712A.968.968 0 0 1 12 3c.283 0 .52.096.712.288A.965.965 0 0 1 13 4v8ZM9 8c0 .283-.096.52-.288.712A.965.965 0 0 1 8 9H4a.968.968 0 0 1-.713-.288A.967.967 0 0 1 3 8c0-.283.096-.52.287-.712A.968.968 0 0 1 4 7h1V4c0-.283.096-.52.287-.712A.968.968 0 0 1 6 3c.283 0 .521.096.713.288A.967.967 0 0 1 7 4v3h1c.283 0 .52.096.712.288A.965.965 0 0 1 9 8Z" })
  }
);
export {
  h as default
};
