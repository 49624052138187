import { jsx as t } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "m7.694 9.875 3.6-3.6c.1-.1.208-.17.325-.212A1.1 1.1 0 0 1 11.994 6a1.1 1.1 0 0 1 .375.063.871.871 0 0 1 .325.212l3.6 3.6a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7c-.184.183-.42.275-.712.275s-.53-.092-.713-.275L12.994 9.4V16a.97.97 0 0 1-.287.713.97.97 0 0 1-.713.287.967.967 0 0 1-.712-.287.968.968 0 0 1-.288-.713V9.4l-1.875 1.875a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.99.99 0 0 1-.287-.7.906.906 0 0 1 .262-.7Z" })
  }
);
export {
  s as default
};
