import { jsxs as a, jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ c("path", { d: "M17.94 13.86c.07-.49.48-.86.98-.86.6 0 1.09.53 1 1.12-.62 4.47-4.94 7.75-9.77 6.67-3.09-.69-5.39-3.08-5.99-6.19C3.16 9.48 7.06 5 12 5V2.21a.5.5 0 0 1 .85-.36l3.79 3.79c.2.2.2.51 0 .71l-3.79 3.79c-.31.32-.85.1-.85-.35V7c-3.73 0-6.68 3.42-5.87 7.29.48 2.26 2.14 3.99 4.39 4.53 3.65.88 6.94-1.59 7.42-4.96Z" }),
      /* @__PURE__ */ c("path", { d: "M10.9 11.73V16h-.85v-3.26l-1.01.31v-.69l1.77-.63h.09ZM13.9 15.15c-.05.07-.11.13-.18.17-.07.04-.17.06-.27.06a.6.6 0 0 1-.42-.15.633.633 0 0 1-.19-.41H12c.01.2.05.37.13.53.08.16.19.28.32.39.13.11.29.19.46.24.17.05.35.08.53.08.24 0 .46-.04.64-.12.18-.08.33-.18.45-.31s.21-.28.27-.45c.06-.17.09-.35.09-.54 0-.22-.03-.43-.09-.6a1.31 1.31 0 0 0-.25-.45c-.11-.12-.25-.22-.41-.28-.16-.06-.34-.1-.55-.1a1.231 1.231 0 0 0-.22.023.822.822 0 0 0-.16.037c-.025.01-.05.018-.075.025a.879.879 0 0 0-.137.054c-.018.009-.033.016-.048.021l.11-.92h1.7v-.71h-2.39l-.25 2.17.67.17c.03-.03.06-.06.1-.09.04-.03.07-.05.12-.07.05-.02.1-.04.15-.05.05-.01.13-.02.2-.02.12 0 .22.02.3.05.08.03.16.09.21.15.05.06.1.14.13.24.03.1.04.19.04.31s-.01.22-.03.31c-.02.09-.06.17-.11.24Z" })
    ]
  }
);
export {
  t as default
};
