import { jsx as c } from "react/jsx-runtime";
const i = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M5.3 14.275c-.3-.3-.442-.658-.425-1.075.017-.417.183-.742.5-.975.917-.7 1.938-1.246 3.063-1.638A10.762 10.762 0 0 1 12 10c1.267 0 2.463.2 3.588.6 1.125.4 2.145.958 3.062 1.675.317.233.48.554.488.963A1.41 1.41 0 0 1 18.7 14.3c-.283.283-.629.43-1.037.438a1.94 1.94 0 0 1-1.138-.338 7.899 7.899 0 0 0-2.112-1.037A7.894 7.894 0 0 0 12 13c-.85 0-1.654.125-2.412.375A8.246 8.246 0 0 0 7.5 14.4c-.367.25-.754.363-1.162.338a1.556 1.556 0 0 1-1.038-.463ZM1.05 10.05a1.412 1.412 0 0 1-.425-1.038c0-.408.15-.745.45-1.012a17.303 17.303 0 0 1 5-2.938C7.925 4.354 9.9 4 12 4c2.1 0 4.075.354 5.925 1.062a17.303 17.303 0 0 1 5 2.938c.3.267.454.6.463 1 .008.4-.138.75-.438 1.05a1.503 1.503 0 0 1-1.037.45 1.626 1.626 0 0 1-1.113-.375 14.271 14.271 0 0 0-4.062-2.3A13.588 13.588 0 0 0 12 7a13.58 13.58 0 0 0-4.737.825 14.267 14.267 0 0 0-4.063 2.3 1.625 1.625 0 0 1-1.112.375 1.504 1.504 0 0 1-1.038-.45ZM12 20c-.567 0-1.042-.192-1.425-.575C10.192 19.042 10 18.567 10 18c0-.567.192-1.042.575-1.425.383-.383.858-.575 1.425-.575.567 0 1.042.192 1.425.575.383.383.575.858.575 1.425 0 .567-.192 1.042-.575 1.425-.383.383-.858.575-1.425.575Z" })
  }
);
export {
  i as default
};
