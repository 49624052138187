import { jsx as e } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm12.58 5.079a1.5 1.5 0 0 1 .234 2.108l-5.99 7.489a1.538 1.538 0 0 1-.544.433 1.498 1.498 0 0 1-1.713-.314l-2.985-2.984a1.5 1.5 0 0 1 2.122-2.122l1.815 1.815 4.953-6.191a1.5 1.5 0 0 1 2.108-.234Z"
      }
    )
  }
);
export {
  t as default
};
