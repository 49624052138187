import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "m19.95 17.25-1.5-1.5c.183-.45.321-.887.413-1.312A6.36 6.36 0 0 0 19 13.1c0-1.933-.683-3.6-2.05-5C15.583 6.7 13.933 6 12 6c-.467 0-.917.037-1.35.112a5.947 5.947 0 0 0-1.3.388L7.85 5a8.553 8.553 0 0 1 1.988-.75A9.194 9.194 0 0 1 12 4c1.233 0 2.396.233 3.488.7a8.923 8.923 0 0 1 2.862 1.937 9.318 9.318 0 0 1 1.938 2.888c.475 1.1.712 2.292.712 3.575a8.4 8.4 0 0 1-.275 2.162 9.452 9.452 0 0 1-.775 1.988ZM17.7 3.05a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.875 2.875a.893.893 0 0 1 .263.687.98.98 0 0 1-.288.688.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-2.875-2.875a.891.891 0 0 1-.262-.688.975.975 0 0 1 .287-.687Zm3.5 19.65a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-2.45-2.45a8.643 8.643 0 0 1-2.538 1.312A9.129 9.129 0 0 1 12 22a8.782 8.782 0 0 1-3.488-.7 9.237 9.237 0 0 1-2.862-1.9 8.939 8.939 0 0 1-1.938-2.825A8.563 8.563 0 0 1 3 13.1c0-.983.154-1.933.463-2.85A9.095 9.095 0 0 1 4.8 7.7l-.85-.85-.525.525a.89.89 0 0 1-.687.262.976.976 0 0 1-.688-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l.5-.5-1.175-1.175a.891.891 0 0 1-.262-.688A.975.975 0 0 1 1.4 2.9a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l18.425 18.425a.894.894 0 0 1 .263.688.979.979 0 0 1-.288.687ZM12 20.025c.7 0 1.383-.108 2.05-.325a7.325 7.325 0 0 0 1.85-.9L6.2 9.15a7.398 7.398 0 0 0-.888 1.887A6.97 6.97 0 0 0 5 13.1c0 1.933.683 3.57 2.05 4.912 1.367 1.342 3.017 2.013 4.95 2.013Z" })
  }
);
export {
  t as default
};
