import { jsx as h } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ h(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4 5.116A4.116 4.116 0 0 1 8.116 1h7.063a4.116 4.116 0 0 1 2.258 7.558 4.116 4.116 0 0 1-2.258 7.558h-.09a4.1 4.1 0 0 1-2.767-1.07v3.793c0 2.304-1.891 4.161-4.184 4.161C5.871 23 4 21.163 4 18.884c0-1.44.739-2.706 1.858-3.442A4.112 4.112 0 0 1 4 12c0-1.44.739-2.706 1.858-3.442A4.112 4.112 0 0 1 4 5.116Zm6.973 4.116H8.116a2.768 2.768 0 0 0-.016 5.536h2.873V9.232ZM12.322 12a2.768 2.768 0 0 0 2.767 2.768h.09a2.768 2.768 0 0 0 0-5.536h-.09A2.768 2.768 0 0 0 12.322 12Zm-4.214 4.116H8.1a2.768 2.768 0 0 0-2.752 2.768c0 1.523 1.256 2.768 2.79 2.768 1.56 0 2.835-1.265 2.835-2.813v-2.723H8.108Zm.008-13.768h2.857v5.536H8.116a2.768 2.768 0 1 1 0-5.536Zm4.206 5.536V2.348h2.857a2.768 2.768 0 0 1 0 5.536h-2.857Z"
      }
    )
  }
);
export {
  t as default
};
