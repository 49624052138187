import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/tooltip/package.json";
import { Tooltip } from "../../../../../packages/tooltip/src/index";
import { TextDelta, BodyUI } from "@happeouikit/typography";
import { success, navy, warn } from "@happeouikit/colors";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  Tooltip,
  TextDelta,
  BodyUI,
  success,
  navy,
  warn,
  React
};