/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Aws = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2 10.778c0 .27.03.49.08.651.059.161.132.337.234.527a.318.318 0 0 1 .052.168c0 .073-.044.147-.14.22l-.46.307a.35.35 0 0 1-.19.066c-.074 0-.147-.037-.22-.103a2.26 2.26 0 0 1-.263-.343 5.643 5.643 0 0 1-.227-.432c-.571.673-1.288 1.01-2.151 1.01-.615 0-1.105-.176-1.464-.527-.358-.351-.541-.82-.541-1.405 0-.622.22-1.127.666-1.507.446-.38 1.039-.571 1.792-.571.249 0 .505.022.776.059.27.036.549.095.841.16v-.534c0-.556-.117-.944-.344-1.17-.234-.227-.629-.337-1.192-.337-.256 0-.52.03-.79.095a5.83 5.83 0 0 0-.79.249 2.1 2.1 0 0 1-.257.095.449.449 0 0 1-.117.022c-.102 0-.154-.073-.154-.227v-.358c0-.117.015-.205.052-.256a.548.548 0 0 1 .204-.154c.257-.132.564-.242.922-.33a4.436 4.436 0 0 1 1.142-.138c.87 0 1.507.197 1.917.592.402.395.607.995.607 1.8v2.371H7.2ZM4.23 11.89c.24 0 .49-.044.753-.132a1.63 1.63 0 0 0 .695-.468c.117-.139.205-.292.249-.468.044-.176.073-.388.073-.637v-.307a6.11 6.11 0 0 0-.673-.124 5.513 5.513 0 0 0-.688-.044c-.49 0-.849.095-1.09.292-.242.198-.359.476-.359.842 0 .344.088.6.27.775.176.183.433.271.77.271Z"
    />
    <path d="M10.105 12.68c-.132 0-.22-.022-.278-.073-.059-.044-.11-.146-.154-.285l-1.72-5.656a1.282 1.282 0 0 1-.065-.293c0-.117.058-.183.175-.183h.717c.14 0 .234.022.286.073.058.044.102.147.146.286l1.23 4.844 1.14-4.844c.037-.147.081-.242.14-.286a.504.504 0 0 1 .292-.073h.586c.139 0 .234.022.292.073.059.044.11.147.14.286l1.155 4.902 1.266-4.902c.044-.147.096-.242.147-.286a.478.478 0 0 1 .285-.073h.68c.118 0 .183.059.183.183 0 .031-.005.063-.011.098l-.003.02a1.036 1.036 0 0 1-.051.182l-1.764 5.656c-.044.146-.095.242-.153.285a.468.468 0 0 1-.278.074h-.63c-.139 0-.234-.022-.292-.074-.059-.05-.11-.146-.14-.292l-1.134-4.72-1.126 4.713c-.037.146-.08.241-.14.292-.058.051-.16.073-.292.073h-.63ZM19.507 12.878c-.38 0-.761-.044-1.127-.132-.366-.088-.651-.183-.841-.292-.117-.066-.198-.14-.227-.205a.516.516 0 0 1-.044-.205v-.373c0-.154.058-.227.168-.227.044 0 .088.007.132.022.028.01.066.025.108.043l.075.03c.249.11.52.197.805.256.293.059.578.088.87.088.462 0 .82-.08 1.069-.242a.788.788 0 0 0 .38-.695.713.713 0 0 0-.197-.512c-.132-.139-.38-.263-.74-.38l-1.06-.33c-.534-.168-.93-.417-1.171-.746a1.743 1.743 0 0 1-.366-1.061c0-.307.066-.578.198-.812.131-.234.307-.44.526-.6.22-.168.469-.293.761-.38.293-.088.6-.125.922-.125.161 0 .33.007.49.03.169.021.323.05.476.08.147.036.286.073.417.117.132.044.235.088.308.132a.633.633 0 0 1 .22.182.394.394 0 0 1 .065.242v.344c0 .153-.058.234-.168.234a.761.761 0 0 1-.278-.088 3.348 3.348 0 0 0-1.405-.285c-.417 0-.747.066-.973.205-.227.139-.344.35-.344.65 0 .206.073.381.22.52.146.14.416.278.804.403l1.04.33c.526.167.906.402 1.133.702.227.3.337.643.337 1.024 0 .315-.066.6-.19.849a1.968 1.968 0 0 1-.534.644 2.357 2.357 0 0 1-.813.41 3.48 3.48 0 0 1-1.046.153ZM20.89 16.434c-2.407 1.778-5.905 2.722-8.912 2.722-4.215 0-8.012-1.559-10.88-4.149-.227-.205-.022-.483.248-.322 3.103 1.8 6.93 2.89 10.888 2.89 2.67 0 5.605-.556 8.305-1.697.402-.183.746.263.35.556Z" />
    <path d="M19.075 15.197c.783-.095 2.51-.3 2.817.096.308.387-.344 2.019-.636 2.743-.088.22.102.308.3.14 1.287-1.084 1.624-3.344 1.36-3.674-.263-.322-2.524-.6-3.9.366-.211.154-.175.359.06.33Z" />
  </svg>
);

export default Aws;
