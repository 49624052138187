import { jsx as h } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h5.175a1.978 1.978 0 0 1 1.4.575L12 6h8c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 20H4ZM4 6v12h16V8h-8.825l-2-2H4Zm0 0v12V6Z" })
  }
);
export {
  c as default
};
