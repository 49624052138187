import { jsx as h } from "react/jsx-runtime";
const Z = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M3 21v-2h2v2H3Zm9 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 20v-7H4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 12c0-.283.096-.521.288-.713A.967.967 0 0 1 4 11h7V4c0-.283.096-.521.288-.713A.967.967 0 0 1 12 3a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 4v7h7c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 13h-7v7c0 .283-.096.52-.287.712A.968.968 0 0 1 12 21Zm-9-4v-2h2v2H3Zm0-8V7h2v2H3Zm0-4V3h2v2H3Zm4 16v-2h2v2H7ZM7 5V3h2v2H7Zm8 16v-2h2v2h-2Zm0-16V3h2v2h-2Zm4 16v-2h2v2h-2Zm0-4v-2h2v2h-2Zm0-8V7h2v2h-2Zm0-4V3h2v2h-2Z" })
  }
);
export {
  Z as default
};
