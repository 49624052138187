import { jsx as e } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2ZM6.75 8a.656.656 0 1 0 0 1.313h10.5a.656.656 0 0 0 0-1.313H6.75Zm-.656 3.656c0-.362.294-.656.656-.656h10.5a.656.656 0 0 1 0 1.313H6.75a.656.656 0 0 1-.656-.657ZM6.75 14a.656.656 0 1 0 0 1.313h6a.656.656 0 0 0 0-1.313h-6Z"
      }
    )
  }
);
export {
  t as default
};
