/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleAudio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z"
      fill="#F16C00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.042 6C9.258 6 7 8.333 7 11.21v4.053C7 16.224 7.75 17 8.68 17h1.681v-4.632h-2.24V11.21c0-2.24 1.753-4.052 3.92-4.052 2.169 0 3.922 1.812 3.922 4.052v1.158h-2.24V17h1.68c.93 0 1.68-.776 1.68-1.737V11.21c0-2.877-2.257-5.21-5.041-5.21Z"
      fill="#fff"
    />
  </svg>
);

export default GoogleAudio;
