import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { IconSearch } from "@happeouikit/icons";
import styled from "styled-components";
import { hooks } from "@happeo/react-core";
import { Input } from "../Input";
import TypeaheadList from "./TypeaheadList";
import TypeaheadDropdown from "./TypeaheadDropdown";
import TypeaheadError from "./TypeaheadError";
import TypeaheadNoResults from "./TypeaheadNoResults";
import TypeaheadLoading from "./TypeaheadLoading";

const Typeahead = ({
  onQuery,
  loading,
  results,
  onSelect,
  onClear,
  keepResult,
  placeholder,
  errorMessage,
  queryError,
  dropdownMaxHeight,
}) => {
  // Need to store query as object to support change detection when debouncing from query to the same query
  const [query, setQuery] = useState({ q: "" });
  const debouncedQuery = hooks.useDebounce(query, 300);
  const [focusedResultIndex, setFocusedResultIndex] = useState(null);
  const [allowDropdown, setAllowDropdown] = useState(true);
  const [selectedResult, setSelectedResult] = useState(null);

  const selfRef = useRef(null);
  const selectedRef = useRef(null);

  const userIsTyping = query !== debouncedQuery;
  const showDropDown = query.q.length > 1 && !selectedResult && allowDropdown;

  // The parent may want to keep the result visible until some further user interaction
  // Then keepResult may be toggled OFF to clear the input for next query and set back ON at onQuery
  useEffect(() => {
    if (!keepResult) setQuery({ q: "" });
  }, [keepResult]);

  useEffect(() => {
    // Debouncing timer might still be running after result is selected, so ignore false triggers
    if (selectedResult) return;
    const { q } = debouncedQuery;
    if (q.length < 2) return;
    onQuery(q);
  }, [onQuery, debouncedQuery, selectedResult]);

  useEffect(() => {
    if (results.length === 0) {
      setFocusedResultIndex(null);
    }
    if (results.length > 0) {
      setFocusedResultIndex(0);
    }
  }, [results]);

  const focusNextResult = () => {
    return focusedResultIndex < results.length - 1
      ? focusedResultIndex + 1
      : focusedResultIndex;
  };

  const focusPrevResult = () => {
    return focusedResultIndex > 0 ? focusedResultIndex - 1 : focusedResultIndex;
  };

  const selectResult = (result) => {
    if (keepResult && result.text) {
      setQuery({ q: result.text });
    } else {
      setQuery({ q: "" });
    }
    setSelectedResult(result);
    onSelect(result.data);
    setFocusedResultIndex(null);
  };

  const valueChangeHandler = (event) => {
    const { value } = event.target;
    setQuery({ q: value });
    setAllowDropdown(true);
    setSelectedResult(null);
    setFocusedResultIndex(null);

    // notify parent that selection was erased
    if (keepResult && selectedResult) {
      onClear();
    }
  };

  useEffect(() => {
    if (!selectedRef.current) return;
    selectedRef.current.scrollIntoView({ block: "nearest" });
  }, [focusedResultIndex]);

  const keyDownHandler = (event) => {
    if (!showDropDown) return;
    if (queryError || userIsTyping || loading) return;
    if (event.key === "ArrowUp") {
      setFocusedResultIndex(focusPrevResult());
      event.preventDefault();
    } else if (event.key === "ArrowDown") {
      setFocusedResultIndex(focusNextResult());
      event.preventDefault();
    } else if (event.key === "Enter") {
      if (focusedResultIndex != null) {
        selectResult(results[focusedResultIndex]);
        event.preventDefault();
      }
    }
  };

  const resultClickHandler = (result) => {
    selectResult(result);
  };

  useEffect(() => {
    const handleWindowClick = (event) => {
      if (selfRef.current && !selfRef.current.contains(event.target)) {
        setAllowDropdown(false);
      } else {
        setAllowDropdown(true);
      }
    };
    document.addEventListener("pointerdown", handleWindowClick);
    return function cleanup() {
      document.removeEventListener("pointerdown", handleWindowClick);
    };
  }, [selfRef]);

  const renderDropdownContent = () => {
    if (queryError) {
      return <TypeaheadError />;
    }
    if (userIsTyping || loading) {
      return <TypeaheadLoading query={query.q} />;
    }
    if (results.length > 0) {
      return (
        <TypeaheadList
          results={results}
          focusedResultIndex={focusedResultIndex}
          onResultClick={resultClickHandler}
          ref={selectedRef}
        />
      );
    }
    return <TypeaheadNoResults query={query.q} />;
  };

  return (
    <Wrapper ref={selfRef}>
      <Input
        placeholder={placeholder}
        icon={IconSearch}
        value={query.q}
        name="inputValue"
        onChange={valueChangeHandler}
        onKeyDown={keyDownHandler}
        state={errorMessage ? "error" : "default"}
        errorMessage={errorMessage}
        autoComplete="off"
      />

      {showDropDown && (
        <TypeaheadDropdown maxHeight={dropdownMaxHeight}>
          {renderDropdownContent()}
        </TypeaheadDropdown>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

Typeahead.propTypes = {
  onQuery: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string, // If keepResult, this is showed when selecting a result
      component: PropTypes.element.isRequired,
      data: PropTypes.any.isRequired, // Data will be passed with onSelect
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func,  // If keepResult, this is called if user clears the result from input
  keepResult: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  queryError: PropTypes.bool,
  dropdownMaxHeight: PropTypes.string,
};

Typeahead.defaultProps = {
  keepResult: false,
  placeholder: "",
  errorMessage: null,
  queryError: false,
  dropdownMaxHeight: "350px",
  onClear: () => {}
};

export default Typeahead;
