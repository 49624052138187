import { jsx as h } from "react/jsx-runtime";
const Z = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M9 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 7 16V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 9 2h9c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 18H9Zm0-2h9V4H9v12Zm-6-1h2v-2H3v2Zm0-3.5h2v-2H3v2ZM10 22h2v-2h-2v2Zm-7-3.5h2v-2H3v2ZM5 22v-2H3c0 .55.196 1.021.587 1.413.392.391.863.587 1.413.587Zm1.5 0h2v-2h-2v2Zm7 0c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413h-2v2ZM3 8h2V6c-.55 0-1.021.196-1.413.588A1.925 1.925 0 0 0 3 8Z" })
  }
);
export {
  Z as default
};
