import { jsxs as t, jsx as e } from "react/jsx-runtime";
const i = (d) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...d,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0-4a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        }
      ),
      /* @__PURE__ */ e("path", { d: "M10 20c.462 0 .5-.618.074-.8A10.003 10.003 0 0 1 4.221 7.9c.111-.52-.485-.904-.79-.468A8 8 0 0 0 10 20Z" })
    ]
  }
);
export {
  i as default
};
