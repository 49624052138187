import { jsx as h } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M3 17V7c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 5h14c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 19H5c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 17Zm7.325-10v4H19V7h-8.675ZM19 13h-3.325v4H19v-4Zm-8.675 0v4h3.35v-4h-3.35ZM5 17h3.325V7H5v10Z" })
  }
);
export {
  o as default
};
