import { jsxs as l, jsx as e } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          d: "m14.5 6.5 3.016.57L20 6.5 14.5 1l-.759 2.607L14.5 6.5Z",
          fill: "#2B313B"
        }
      ),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14.5 1v5.5H20v15a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 4 21.5v-19A1.5 1.5 0 0 1 5.5 1h9Zm-4.25 14.75V17h3.5v-1.25h-3.5Zm-1.5-4c0 1.159.606 2.175 1.519 2.75h3.462a3.25 3.25 0 1 0-4.981-2.75Z"
        }
      )
    ]
  }
);
export {
  t as default
};
