import { jsx as t } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M12 21.5c-2.217 0-4.104-.767-5.662-2.3C4.779 17.667 4 15.8 4 13.6c0-1.05.204-2.054.612-3.012A7.784 7.784 0 0 1 6.35 8.05L12 2.5l5.65 5.55c.75.733 1.33 1.58 1.738 2.537.408.959.612 1.963.612 3.013 0 2.2-.78 4.067-2.337 5.6-1.559 1.533-3.446 2.3-5.663 2.3Zm0-2c1.667 0 3.083-.57 4.25-1.712C17.417 16.646 18 15.25 18 13.6c0-.783-.15-1.53-.45-2.237a5.453 5.453 0 0 0-1.3-1.863L12 5.3 7.75 9.5c-.567.533-1 1.154-1.3 1.863-.3.708-.45 1.454-.45 2.237 0 1.65.583 3.046 1.75 4.188C8.917 18.928 10.333 19.5 12 19.5Z" })
  }
);
export {
  h as default
};
