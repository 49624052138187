import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "m9.11 8.293 1.88-1.88v8.67c0 .55.45 1 1 1s1-.45 1-1v-8.67l1.89 1.88a.996.996 0 1 0 1.41-1.41l-3.59-3.59a.996.996 0 0 0-1.41 0L7.7 6.883a.996.996 0 1 0 1.41 1.41ZM19 18v-3c0-.55.45-1 1-1s1 .45 1 1v4c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-4c0-.55.45-1 1-1s1 .45 1 1v3c0 .55.45 1 1 1h12c.55 0 1-.45 1-1Z" })
);
export {
  c as default
};
