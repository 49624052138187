import * as React from "react";
const SvgIconTrendingFlat = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="m21.254 11.65-2.79-2.79a.501.501 0 0 0-.86.35V11h-14c-.55 0-1 .45-1 1s.45 1 1 1h14v1.79c0 .45.54.67.85.35l2.79-2.79c.2-.19.2-.51.01-.7Z" />
  </svg>
);
export default SvgIconTrendingFlat;
