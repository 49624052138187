import * as React from "react";
const SvgIconResizeDrag = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M13 19c0 .55-.45 1-1 1s-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v14Zm6-9.79V11h-3c-.55 0-1 .45-1 1s.45 1 1 1h3v1.79c0 .45.54.67.85.36l2.79-2.79c.2-.2.2-.51 0-.71l-2.79-2.79a.495.495 0 0 0-.85.35ZM5 14.79V13h3c.55 0 1-.45 1-1s-.45-1-1-1H5V9.21c0-.45-.54-.67-.85-.35l-2.79 2.79c-.2.2-.2.51 0 .71l2.79 2.79a.5.5 0 0 0 .85-.36Z" />
  </svg>
);
export default SvgIconResizeDrag;
