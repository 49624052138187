import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M4 16.187V19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2.813a1 1 0 0 0-.641-.934l-5.166-1.986A4 4 0 0 0 12.757 13h-1.514a4 4 0 0 0-1.436.267L4.64 15.254a1 1 0 0 0-.641.933ZM15.5 7.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" })
);
export {
  a as default
};
