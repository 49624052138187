import { jsxs as e, jsx as c } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4.588 21.413C4.979 21.803 5.45 22 6 22h6.1a.66.66 0 0 0 .625-.387c.133-.259.125-.53-.025-.813a5.72 5.72 0 0 1-.525-1.375 6.159 6.159 0 0 1-.175-1.45c0-.833.158-1.613.475-2.338a6.083 6.083 0 0 1 1.275-1.887 5.91 5.91 0 0 1 2.55-1.525 5.801 5.801 0 0 1 2.925-.1c.2.05.38.017.537-.1A.56.56 0 0 0 20 11.55V8.825a1.975 1.975 0 0 0-.575-1.4l-4.85-4.85a1.975 1.975 0 0 0-1.4-.575H6c-.55 0-1.02.196-1.412.587A1.926 1.926 0 0 0 4 4v16c0 .55.196 1.02.588 1.413ZM18 9h-3c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 13 7V4l5 5Z"
        }
      ),
      /* @__PURE__ */ c("path", { d: "m16.994 19.003-1.962-.018a.978.978 0 0 1-.69-.283.931.931 0 0 1-.3-.707c0-.26.1-.49.3-.69.201-.2.43-.3.69-.3h1.98v-1.98c0-.26.1-.489.3-.69.2-.2.43-.3.69-.3.26 0 .489.1.69.3.2.201.3.43.3.69v1.98h1.98c.259 0 .489.094.69.283.2.188.3.424.3.707 0 .26-.1.489-.3.69-.201.2-.431.3-.69.3l-1.963.018-.017 1.962c0 .26-.094.489-.283.69a.93.93 0 0 1-.707.3c-.26 0-.49-.1-.69-.3-.2-.201-.3-.43-.3-.69l-.018-1.962Z" })
    ]
  }
);
export {
  h as default
};
