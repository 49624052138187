import { jsx as c } from "react/jsx-runtime";
const C = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M19.6 21.6 12 14l-2.35 2.35c.133.25.225.516.275.8.05.283.075.566.075.85 0 1.1-.392 2.041-1.175 2.825C8.042 21.608 7.1 22 6 22s-2.042-.392-2.825-1.175C2.392 20.04 2 19.1 2 18s.392-2.042 1.175-2.825C3.958 14.39 4.9 14 6 14c.283 0 .567.025.85.075.283.05.55.141.8.275L10 12 7.65 9.65c-.25.133-.517.225-.8.275-.283.05-.567.075-.85.075-1.1 0-2.042-.392-2.825-1.175C2.392 8.04 2 7.1 2 6s.392-2.042 1.175-2.825C3.958 2.39 4.9 2 6 2s2.042.391 2.825 1.175C9.608 3.958 10 4.9 10 6c0 .283-.025.566-.075.85-.05.283-.142.55-.275.8L21.6 19.6c.283.283.425.616.425 1 0 .383-.142.716-.425 1a1.369 1.369 0 0 1-1 .425c-.383 0-.717-.142-1-.425ZM15 11l-2-2 6.6-6.6a1.368 1.368 0 0 1 1-.425c.383 0 .717.141 1 .425.283.283.425.616.425 1 0 .383-.142.716-.425 1L15 11ZM6 8c.55 0 1.02-.196 1.412-.588C7.804 7.02 8 6.55 8 6c0-.55-.196-1.021-.588-1.412A1.923 1.923 0 0 0 6 4c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 4 6c0 .55.196 1.02.588 1.412C4.979 7.804 5.45 8 6 8Zm6 4.5c.15 0 .271-.046.363-.137A.493.493 0 0 0 12.5 12a.493.493 0 0 0-.137-.363A.493.493 0 0 0 12 11.5a.493.493 0 0 0-.363.137.493.493 0 0 0-.137.363c0 .15.046.27.137.363A.493.493 0 0 0 12 12.5ZM6 20c.55 0 1.02-.196 1.412-.587C7.804 19.02 8 18.55 8 18s-.196-1.021-.588-1.413A1.925 1.925 0 0 0 6 16c-.55 0-1.02.195-1.412.587A1.927 1.927 0 0 0 4 18c0 .55.196 1.02.588 1.413.391.39.862.587 1.412.587Z" })
  }
);
export {
  C as default
};
