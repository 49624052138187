import { jsx as t } from "react/jsx-runtime";
const s = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M11.24 8.224V20H2l9.24-11.776Zm0-4.224c0 2.691-2.066 4.87-4.615 4.87C4.075 8.87 2 6.692 2 4h9.24Zm1.521 16c0-2.691 2.065-4.87 4.615-4.87 2.55 0 4.614 2.179 4.614 4.87h-9.229Zm0-4.224V4H22l-9.239 11.776Z" })
  }
);
export {
  s as default
};
