import * as e from "react";
const t = (l) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M21 5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5Zm-10.918 9.672h3.822L14.711 17H16.5L12.766 7h-1.539L7.5 17h1.782l.8-2.328Zm3.341-1.401h-2.86l1.43-4.149 1.43 4.149Z",
      fill: "#A33639"
    }
  )
);
export {
  t as default
};
