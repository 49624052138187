import { jsx as a } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M20 8a1 1 0 1 0 0-2H4a1 1 0 0 0 0 2h16Zm-7.624 8c.639 0 1.054.694.874 1.306-.115.389-.457.694-.863.694H11a1 1 0 1 1 0-2h1.376ZM17 11a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2h10Zm4.962 5.921-1.875-1.876.9-.9a.436.436 0 0 1 .35-.145.497.497 0 0 1 .35.144l1.175 1.176a.467.467 0 0 1 .138.344.51.51 0 0 1-.137.357l-.9.9ZM15.5 22a.484.484 0 0 1-.357-.144A.484.484 0 0 1 15 21.5v-1.164a.52.52 0 0 1 .15-.362l4.375-4.379 1.875 1.877-4.375 4.378a.5.5 0 0 1-.362.15H15.5Z"
      }
    )
  }
);
export {
  t as default
};
