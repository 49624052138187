import * as e from "react";
const t = (c) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...c
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18.052 5h3.9c.59 0 1.05.51.99 1.1l-1.54 15.43c-.1.82-.79 1.47-1.63 1.47h-1.7l-.02-8c0-4.23-3.1-6.84-6.78-7.69l-.12-1.21c-.05-.59.41-1.1 1-1.1h3.9V2c0-.55.45-1 1-1s1 .45 1 1v3Zm-3 16c.55 0 1 .45 1 1s-.45 1-1 1h-13c-.55 0-1-.45-1-1s.45-1 1-1h13Zm-.1-6c.62 0 1.11-.56.99-1.16-.65-3.23-4.02-4.85-7.39-4.85s-6.73 1.62-7.39 4.85c-.12.6.38 1.16.99 1.16h12.8Zm-13.9 3c0-.55.45-1 1-1h13c.55 0 1 .45 1 1s-.45 1-1 1h-13c-.55 0-1-.45-1-1Z"
    }
  )
);
export {
  t as default
};
