import * as React from "react";
const SvgIconMinusCircle = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Zm0-1.5a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17ZM14.869 9v2H5.131V9h9.738Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconMinusCircle;
