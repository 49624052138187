/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraSalesRequest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2Zm7.412 9.248.078.033c.465.192.945.391 1.383.64.977.558 1.407 1.402 1.243 2.443-.15.953-.778 1.624-1.866 1.995l-.094.032a6.63 6.63 0 0 1-.548.173l.002.93a.493.493 0 0 1-.165.366.59.59 0 0 1-.402.153c-.312 0-.566-.233-.566-.519v-.91l-.234-.024-.01-.002a5.999 5.999 0 0 1-1.637-.342c-.346-.122-.53-.472-.422-.796l.011-.033c.092-.275.366-.46.68-.46.084 0 .167.014.247.041.175.06.335.109.488.152.414.117.783.173 1.127.173.28 0 .553-.038.808-.115l.098-.029c.525-.155.872-.257.98-.763.12-.552-.077-1.023-.585-1.4-.388-.287-.847-.468-1.246-.615-.544-.201-1.175-.461-1.728-.868a2.044 2.044 0 0 1-.855-1.685c.01-.7.37-1.365.963-1.78.39-.274.838-.46 1.344-.556a.4.4 0 0 0 .125-.032c.009-.01.009-.052.008-.126l-.004-.819c0-.131.056-.255.157-.348a.56.56 0 0 1 .38-.144c.296 0 .537.22.537.49v.887l.215.025h.005c.195.023.379.044.565.073.233.037.6.128.93.275a.615.615 0 0 1 .352.399.563.563 0 0 1-.111.504.678.678 0 0 1-.534.24.754.754 0 0 1-.26-.045c-.339-.124-.646-.2-.938-.234a5.32 5.32 0 0 0-.613-.042c-.34 0-.632.053-.891.16-.441.184-.724.51-.775.892-.05.374.132.743.5 1.01.3.22.656.36 1 .497l.258.104Z"
    />
  </svg>
);

export default JiraSalesRequest;
