/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { useRef, forwardRef, useEffect } from "react";
import styled from "styled-components";
import RcModal from "react-modal";
// @ts-ignore types missing
import { hooks } from "@happeo/react-core";
import { IconClose } from "@happeouikit/icons";
import { TextDelta } from "@happeouikit/typography";
// @ts-ignore types missing in build process
import { Button, IconButton } from "@happeouikit/buttons-ds3";
import { media } from "@happeouikit/layout";
import { OverlayGlobalStyle, defaultOverlayStyle } from "./styles";
import { ModalProps } from "./Modal.types";

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      children,
      isOpen,
      headerText,
      close,
      width,
      top,
      footer,
      okCallback,
      okText,
      cancelText,
      iconCloseText,
      iconClose,
      footerComponent,
      disabled,
      overlayClassName = "Overlay",
      overlayStyle,
      closeOnClickOutside,
      reactModalProps,
      closeOnEsc,
      tertiaryButton,
      ...props
    },
    ref
  ) => {
    const reactionsModalCreatedRef = useRef<HTMLDivElement>();
    const reactionsModalRef = ref || reactionsModalCreatedRef;
    const handleClickOutside = () => {
      if (!closeOnClickOutside) {
        return;
      }

      close();
    };

    useEffect(() => {
      if (!closeOnEsc) {
        return;
      }

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          close();
        }
      };
      document.addEventListener("keydown", handleKeyDown, true);

      // eslint-disable-next-line consistent-return
      return () => {
        document.removeEventListener("keydown", handleKeyDown, true);
      };
    }, [close, closeOnEsc]);

    hooks.useOnClickOutside(reactionsModalRef, handleClickOutside);

    return (
      isOpen && (
        <StyledModal
          isOpen
          ariaHideApp={false}
          // @ts-ignore width type error
          width={width as string}
          top={top}
          overlayClassName={overlayClassName}
          style={{
            overlay: {
              ...defaultOverlayStyle,
              ...overlayStyle,
            },
          }}
          {...reactModalProps}
        >
          <OverlayGlobalStyle />
          {/* @ts-ignore ref type error */}
          <ModalWrapper ref={reactionsModalRef} {...props}>
            {(headerText || iconClose) && (
              <ModalHeader headerText={!!headerText}>
                {headerText && typeof headerText === "string" ? (
                  <ModalHeaderText>{headerText}</ModalHeaderText>
                ) : (
                  <>{headerText}</>
                )}
                {iconClose && (
                  <IconButton
                    icon={<IconClose />}
                    onClick={close}
                    aria-label={iconCloseText || "Close"}
                    data-tracker="modal-close-x"
                    variant="secondary"
                    colorScheme="blank"
                  />
                )}
              </ModalHeader>
            )}
            <ModalContent>{children}</ModalContent>
            {footer && (
              <ModalFooter>
                {footerComponent || (
                  <FooterWrapper>
                    {tertiaryButton && (
                      <FooterLeft>{tertiaryButton}</FooterLeft>
                    )}
                    <FooterRight>
                      <Button
                        variant="secondary"
                        onClick={close}
                        data-tracker="modal-cancel"
                      >
                        {cancelText}
                      </Button>
                      {okCallback && okText && (
                        <Button
                          variant="primary"
                          onClick={okCallback}
                          disabled={disabled}
                          data-tracker="modal-ok"
                          autoFocus={!disabled}
                        >
                          {okText}
                        </Button>
                      )}
                    </FooterRight>
                  </FooterWrapper>
                )}
              </ModalFooter>
            )}
          </ModalWrapper>
        </StyledModal>
      )
    );
  }
);

Modal.defaultProps = {
  isOpen: false,
  width: "300px",
  footer: true,
  okText: "OK",
  cancelText: "Cancel",
  iconCloseText: "Close",
  overlayClassName: "Overlay",
  iconClose: false,
  disabled: false,
  overlayStyle: undefined,
  closeOnClickOutside: false,
  closeOnEsc: true,
};

const StyledModal = styled(RcModal)`
  position: relative;
  margin: 0 auto;
  top: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  z-index: 1050;
  max-height: calc(100vh - 120px);
  padding: 0;

  ${media.min.sm`
    max-width: ${({ width }: { width: string }) => width || "300px"};
  `}
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--space-lg, 24px);
  background-color: var(--color-surface, #ffffff);
  border-radius: var(--radii-lg, 6px);
  box-shadow: var(--box-shadow-shadow-float);

  ${media.min.sm`
    height: auto;
  `}
`;

const ModalHeaderText = styled(TextDelta).attrs({
  as: "h2",
})`
  color: var(--color-primary-text-on-light, #ffffff);
`;

const ModalHeader = styled.div<{
  headerText: boolean;
}>`
  display: flex;
  justify-content: ${({ headerText }) =>
    headerText ? "space-between" : "flex-end"};
  align-items: center;
  margin-bottom: var(--space-md, 16px);
`;

const FooterLeft = styled.div`
  display: flex;
  gap: var(--space-md, 16px);
`;

const FooterRight = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: var(--space-md, 16px);
  flex-grow: 1;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  display: flex;
  flex-grow: 1;
`;

const FooterWrapper = styled.div`
  padding-top: var(--space-xl, 32px);
  display: flex;
  width: 100%;
`;

const ModalContent = styled.div`
  flex: 1 1 auto;
`;
export default Modal;
