import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "m21.668 9.624-.028-.071-2.723-7.105a.71.71 0 0 0-.703-.446.729.729 0 0 0-.41.153.729.729 0 0 0-.241.367l-1.838 5.624H8.28L6.443 2.522a.714.714 0 0 0-.241-.368.729.729 0 0 0-.833-.044.716.716 0 0 0-.28.337L2.36 9.548l-.027.072a5.055 5.055 0 0 0 1.677 5.843l.009.007.025.018 4.147 3.105 2.051 1.553 1.25.943a.84.84 0 0 0 1.017 0l1.25-.943 2.05-1.553 4.173-3.124.01-.008a5.057 5.057 0 0 0 1.675-5.837Z" })
  }
);
export {
  h as default
};
