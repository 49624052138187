import { jsxs as l, jsx as a } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3.587 21.413C3.979 21.804 4.45 22 5 22h7v-2H5V10h14v2h2V6c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 19 4h-1V3a.968.968 0 0 0-.288-.713A.967.967 0 0 0 17 2a.967.967 0 0 0-.712.287A.968.968 0 0 0 16 3v1H8V3a.97.97 0 0 0-.287-.713A.97.97 0 0 0 7 2a.97.97 0 0 0-.713.287A.97.97 0 0 0 6 3v1H5c-.55 0-1.021.196-1.413.588A1.925 1.925 0 0 0 3 6v14c0 .55.196 1.021.587 1.413ZM19 6H5v2h14V6Z"
        }
      ),
      /* @__PURE__ */ a("path", { d: "M20 14.875 22.125 17l.725-.725a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7l-.725-.725a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-.725.725ZM14 21.3v1.2c0 .133.05.25.15.35.1.1.217.15.35.15h1.2a1 1 0 0 0 .725-.3l5-5-2.125-2.125-5 5a1.03 1.03 0 0 0-.3.725Z" })
    ]
  }
);
export {
  v as default
};
