import { jsxs as e, jsx as c } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4 3h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.2-.19.45-.29.71-.29.1 0 .21.01.31.05 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1Zm2.99 4.59c-.24-.83-.39-1.7-.45-2.59H5.03c.09 1.32.35 2.59.76 3.79l1.2-1.2ZM19 17.47c-.88-.06-1.75-.21-2.6-.45l-1.2 1.19c1.21.4 2.48.66 3.8.75v-1.49Z"
        }
      ),
      /* @__PURE__ */ c("path", { d: "M19.288 11.712c.208.192.445.288.712.288.283 0 .52-.1.712-.3a.82.82 0 0 0 .238-.7c-.233-2.1-1.092-3.891-2.575-5.375C16.892 4.142 15.1 3.284 13 3.05a.821.821 0 0 0-.7.237c-.2.192-.3.43-.3.713 0 .284.096.525.288.725.191.2.429.317.712.35 1.55.217 2.871.871 3.963 1.962C18.054 8.13 18.708 9.45 18.925 11c.033.284.154.521.363.712ZM15.25 11.775c.167.15.358.225.575.225.333 0 .608-.116.825-.35.217-.233.292-.491.225-.775a4.924 4.924 0 0 0-1.337-2.413 4.924 4.924 0 0 0-2.413-1.337c-.283-.066-.542.004-.775.212a1.04 1.04 0 0 0-.35.813c0 .234.075.434.225.6.15.167.35.292.6.375a2.89 2.89 0 0 1 1.288.762c.375.376.629.805.762 1.288.083.25.208.45.375.6Z" })
    ]
  }
);
export {
  t as default
};
