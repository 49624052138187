import { jsx as v } from "react/jsx-runtime";
const d = (c) => /* @__PURE__ */ v(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ v(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm4.667 12.5c0 .385-.35.7-.778.7-.428 0-.778-.315-.778-.7v-7c0-.385.35-.7.778-.7.428 0 .778.315.778.7v7Zm3.11 1.8c0 .385-.35.7-.777.7-.428 0-.778-.315-.778-.7V6.7c0-.385.35-.7.778-.7.428 0 .778.315.778.7v10.6Zm-6.221-4.6c0 .385-.35.7-.778.7-.428 0-.778-.315-.778-.7v-1.4c0-.385.35-.7.778-.7.428 0 .778.315.778.7v1.4Zm9.333 2.8c0 .385-.35.7-.778.7-.428 0-.778-.315-.778-.7v-7c0-.385.35-.7.778-.7.428 0 .778.315.778.7v7Zm1.555-2.8v-1.4c0-.385.35-.7.778-.7.428 0 .778.315.778.7v1.4c0 .385-.35.7-.778.7-.428 0-.778-.315-.778-.7Z"
      }
    )
  }
);
export {
  d as default
};
