import { jsx as c } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M16 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 21v-3c0-.283.096-.52.288-.712A.965.965 0 0 1 16 17v-1c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 18 14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v1c.283 0 .52.096.712.288A.965.965 0 0 1 21 18v3c0 .283-.096.52-.288.712A.965.965 0 0 1 20 22h-4Zm1-5h2v-1a.968.968 0 0 0-.288-.713A.967.967 0 0 0 18 15a.967.967 0 0 0-.712.287A.968.968 0 0 0 17 16v1Zm1-5c0-.85-.17-1.646-.512-2.387-.342-.742-.838-1.43-1.488-2.063V9c0 .283-.096.52-.287.712A.968.968 0 0 1 15 10a.965.965 0 0 1-.712-.288A.965.965 0 0 1 14 9V5c0-.283.096-.521.288-.713A.967.967 0 0 1 15 4h4c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 19 6h-1.725c.983.883 1.68 1.829 2.088 2.837A8.715 8.715 0 0 1 20 12h-2ZM5 20a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 19c0-.283.096-.52.287-.712A.968.968 0 0 1 5 18h1.725A7.85 7.85 0 0 1 4 12c0-1.583.413-3.012 1.238-4.287a7.94 7.94 0 0 1 3.237-2.888c.383-.183.733-.183 1.05 0 .317.183.475.492.475.925 0 .2-.058.37-.175.512A1.29 1.29 0 0 1 9.4 6.6a6.058 6.058 0 0 0-2.45 2.162C6.317 9.721 6 10.8 6 12c0 .9.179 1.73.537 2.488A6.091 6.091 0 0 0 8 16.45V15c0-.283.096-.521.288-.713A.967.967 0 0 1 9 14a.97.97 0 0 1 .713.287A.97.97 0 0 1 10 15v4c0 .283-.096.52-.287.712A.968.968 0 0 1 9 20H5Z" })
  }
);
export {
  h as default
};
