/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Poland = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 3.75H.75v17.1h22.5V3.75H1Z"
      stroke="#000"
      strokeOpacity={0.2}
      strokeWidth={0.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 20.6H1V4h22v16.6Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 20.6H1v-8.3h22v8.3Z"
      fill="#DC143C"
    />
  </svg>
);

export default Poland;
