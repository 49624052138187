import { jsxs as e, jsx as l } from "react/jsx-runtime";
const d = (c) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: [
      /* @__PURE__ */ l("path", { d: "M14.597 21.253c-3.63 1.58-8.09.66-10.74-2.79-2.4-3.09-2.48-7.5-.2-10.67a8.975 8.975 0 0 1 7.34-3.78v-1.8c0-.45.54-.67.85-.35l2.8 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.32.31-.86.09-.86-.36v-1.76c-2.2 0-4.4 1-5.81 3.07-1.6 2.36-1.63 5.36-.05 7.73 1.99 2.99 5.62 3.86 8.58 2.62.38-.16.81-.1 1.1.19l.05.05c.47.47.34 1.29-.27 1.56Z" }),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m11.915 11.588 3.65-3.66c.78-.79 2.04-.79 2.83-.01l3.67 3.68c.78.78.78 2.05 0 2.83l-3.66 3.65c-.78.78-2.05.78-2.83 0l-3.66-3.66c-.78-.78-.78-2.05 0-2.83Zm4.37-1.55-2.25 2.25c-.39.39-.39 1.03 0 1.42l2.24 2.23c.4.39 1.03.39 1.42 0l2.25-2.25a.996.996 0 0 0 0-1.41l-2.24-2.24c-.4-.39-1.03-.39-1.42 0Z"
        }
      )
    ]
  }
);
export {
  d as default
};
