import { jsx as t } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M12 12.567A5.506 5.506 0 0 1 6.5 18 5.498 5.498 0 0 1 1 12.5C1 9.465 3.468 7 6.5 7c3.01 0 5.464 2.43 5.5 5.433V7h11v11H12v-5.433Z" })
  }
);
export {
  o as default
};
