import { jsx as c } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M13 8V4c0-.283.096-.52.287-.712A.968.968 0 0 1 14 3h6c.283 0 .52.096.712.288.192.191.288.429.288.712v4c0 .283-.096.52-.288.713A.968.968 0 0 1 20 9h-6a.968.968 0 0 1-.713-.287A.967.967 0 0 1 13 8ZM3 12V4c0-.283.096-.52.288-.712A.968.968 0 0 1 4 3h6c.283 0 .52.096.713.288.191.191.287.429.287.712v8c0 .283-.096.52-.287.713A.968.968 0 0 1 10 13H4a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 12Zm10 8v-8c0-.283.096-.52.287-.713A.968.968 0 0 1 14 11h6c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .283-.096.52-.288.712A.968.968 0 0 1 20 21h-6a.968.968 0 0 1-.713-.288A.968.968 0 0 1 13 20ZM3 20v-4c0-.283.096-.52.288-.713A.967.967 0 0 1 4 15h6c.283 0 .52.096.713.287.191.192.287.43.287.713v4c0 .283-.096.52-.287.712A.968.968 0 0 1 10 21H4a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 20Z" })
  }
);
export {
  a as default
};
