import generatedTokens from "./exportedFromFigma/generatedDesignTokens.json";

/** e.g. { font: { size: { bodyMd: "24px" }}} */
type StringVariablesObject = { [key: string]: string | StringVariablesObject };

const basicDesignTokens = { ...generatedTokens } as const;

const applyUnits = (subJson: StringVariablesObject, unitStr: string) => {
  Object.keys(subJson).forEach((key) => {
    const value = subJson[key];
    if (typeof value !== "object") {
      // eslint-disable-next-line no-param-reassign
      subJson[key] = `${value}${unitStr}`;
    } else {
      applyUnits(value, unitStr);
    }
  });
};

applyUnits(basicDesignTokens.blur, "px");
applyUnits(basicDesignTokens.breakpoint, "px");
applyUnits(basicDesignTokens.duration, "ms");
applyUnits(basicDesignTokens.opacity, "%");
applyUnits(basicDesignTokens.radius, "px");
applyUnits(basicDesignTokens.space, "px");
applyUnits(basicDesignTokens.font.size, "px");

/**
 * Get deep value from object with a period-separated path.
 * e.g:
 *     getProperty({ a: { b: "1", c: "2" }, d: "3" }, "a.c") === "2"
 * */
export const getProperty = (
  obj: StringVariablesObject | string,
  path: string
) =>
  path
    .split(".")
    .reduce((o, p) => (o as StringVariablesObject)[p], obj) as string;

/**
 * Replace references to other variables - used for semantic colors, so that
 * e.g. "{palette.blue.300}" is replaced with "#57b5f4".
 */
const applyReferences = (
  subJson: StringVariablesObject,
  primitiveColors: StringVariablesObject
) => {
  Object.keys(subJson).forEach((key) => {
    const value = subJson[key];
    if (typeof value === "string") {
      const keyStr = value;
      if (keyStr.startsWith("{")) {
        const periodJoinedStr = keyStr.slice(1, -1);
        // eslint-disable-next-line no-param-reassign
        subJson[key] = getProperty(primitiveColors, periodJoinedStr);
      }
    } else if (typeof value === "object") {
      applyReferences(value, primitiveColors);
    }
  });
};

// updating exported basicDesignTokens in place, to have nice semantic colors
applyReferences(
  basicDesignTokens.dynamicColorDark,
  basicDesignTokens.primitives
);
applyReferences(
  basicDesignTokens.dynamicColorLight,
  basicDesignTokens.primitives
);

/**
 * note:  the full list of available variables is in Theme/theme.ts
 */
export { basicDesignTokens, type StringVariablesObject };
