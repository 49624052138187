/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const AdobeAnimate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.824 12.572h1.951a8.029 8.029 0 0 0-.18-.554c-.05-.147-.097-.3-.145-.457a33.832 33.832 0 0 0-.175-.559 15.795 15.795 0 0 0-.111-.353c-.08-.237-.145-.46-.21-.684-.028-.095-.054-.187-.078-.276a9.041 9.041 0 0 0-.094-.321h-.014c-.073.331-.152.662-.26.993l-.143.464c-.068.223-.138.45-.21.674-.1.389-.215.749-.33 1.073Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.813 3H6.187C4.425 3 3 4.462 3 6.269V17.73C3 19.538 4.425 21 6.187 21h11.626C19.575 21 21 19.538 21 17.731V6.27C21 4.462 19.575 3 17.813 3Zm-7.584 11.105H7.378l-.583 1.808a.133.133 0 0 1-.144.108H5.18c-.085 0-.107-.044-.085-.137l2.484-7.1c.018-.069.037-.122.066-.202l.013-.035c.029-.158.043-.331.043-.497-.007-.036.021-.079.065-.086h2.001c.065 0 .094.021.101.065l2.8 7.848c.022.086 0 .122-.078.122h-1.606a.115.115 0 0 1-.122-.086l-.634-1.83v.022Zm3.312 1.8v-4.571c0-.137 0-.303-.007-.468-.004-.086-.005-.167-.007-.245a9.178 9.178 0 0 0-.007-.245c-.008-.151-.015-.303-.022-.389a.084.084 0 0 1 .014-.08.13.13 0 0 1 .08-.02h1.238c.043 0 .086.007.122.02.03.015.05.044.065.087a1.771 1.771 0 0 1 .094.425 2.57 2.57 0 0 1 .813-.475c.303-.1.612-.151.93-.151.244 0 .489.036.72.115.546.18.978.598 1.165 1.138.123.316.173.727.173 1.23v3.637c0 .079-.028.108-.1.108l-1.448.014c-.065.007-.115-.029-.122-.094v-3.477c-.007-.23-.05-.46-.144-.67a.916.916 0 0 0-.317-.381.9.9 0 0 0-.525-.137c-.202 0-.404.029-.584.108a1.625 1.625 0 0 0-.46.295v4.248c0 .08-.03.108-.101.108h-1.462a.097.097 0 0 1-.108-.086c-.007-.03-.007-.036 0-.043Z"
    />
  </svg>
);

export default AdobeAnimate;
