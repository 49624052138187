import { jsxs as h, jsx as l } from "react/jsx-runtime";
const o = (e) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M6.53 15.725 7.255 17h9.49l.724-1.275-2.047-3.569a.585.585 0 0 1 .008-.006l-2.524-4.4h-1.812l-2.524 4.4.008.006-2.048 3.569ZM12 10.21 9.255 15h5.49L12 10.21Z"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4.655 21.225c.3.183.625.275.975.275h12.74c.35 0 .674-.092.974-.275.3-.183.542-.425.726-.725l2.35-4.1c.183-.3.274-.633.274-1s-.091-.7-.274-1l-1.883-3.231.018.006L16.081 3.5a1.83 1.83 0 0 0-.737-.75 2.048 2.048 0 0 0-.989-.25h-4.71c-.35 0-.68.083-.988.25a1.83 1.83 0 0 0-.737.75l-4.475 7.675.018-.006L1.58 14.4a1.88 1.88 0 0 0-.275 1c0 .367.092.7.275 1l2.35 4.1c.184.3.425.542.725.725ZM14.345 4.5h-4.69l-6.35 10.9 2.35 4.1h12.69l2.35-4.1-6.35-10.9Z"
        }
      )
    ]
  }
);
export {
  o as default
};
