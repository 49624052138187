import * as e from "react";
const t = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ e.createElement("path", { d: "M21.208 2.795c.39.39.39 1.02 0 1.41L17.413 8h2.59a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v2.59l3.795-3.795a.997.997 0 0 1 1.41 0ZM2.795 19.798a.997.997 0 0 0 1.41 1.41L8 17.413v2.59a1 1 0 1 0 2 0v-5a1 1 0 0 0-1-1H4a1 1 0 1 0 0 2h2.59l-3.795 3.795Z" })
);
export {
  t as default
};
