/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleFonts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.25 19.835 11.918 4.5h6.001v2.134L9.584 19.835" fill="#95A0B1" />
    <path d="M17.919 19.835h-6.002V4.5h6v15.335h.002Z" />
    <path
      d="M22.253 15.501a4.334 4.334 0 0 1-4.335 4.334v-8.668a4.333 4.333 0 0 1 4.335 4.334Z"
      fill="#596578"
    />
    <path d="M17.919 19.835a4.334 4.334 0 1 1 0-8.668v8.668Z" fill="#2B313B" />
    <path
      d="M21.252 7.834a3.334 3.334 0 0 1-3.334 3.334V4.5a3.334 3.334 0 0 1 3.334 3.334Z"
      fill="#596578"
    />
    <path d="M17.918 11.168a3.333 3.333 0 1 1 0-6.668v6.668Z" fill="#2B313B" />
    <path d="M2.584 7.5a3 3 0 1 1 6.001 0 3 3 0 0 1-6.001 0Z" fill="#596578" />
  </svg>
);

export default GoogleFonts;
