import { jsxs as a, jsx as l } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M23 12.512a.491.491 0 0 0-.234-.422h-.003l-.01-.005-7.623-4.513a1.023 1.023 0 0 0-1.143 0l-7.623 4.513-.009.005a.497.497 0 0 0 .012.85l7.623 4.513a1.023 1.023 0 0 0 1.143 0l7.623-4.513a.49.49 0 0 0 .244-.428Z",
          fill: "#0A2767"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M7.253 9.499h5.003v4.585H7.253V9.5Zm14.724-4.662V2.74a.95.95 0 0 0-.928-.972H8.067a.95.95 0 0 0-.928.972v2.097l7.675 2.047 7.163-2.047Z",
          fill: "#0364B8"
        }
      ),
      /* @__PURE__ */ l("path", { d: "M7.14 4.837h5.116v4.605H7.14V4.837Z", fill: "#0078D4" }),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M17.372 4.837h-5.116v4.605l5.116 4.605h4.605V9.442l-4.605-4.605Z",
          fill: "#28A8EA"
        }
      ),
      /* @__PURE__ */ l("path", { d: "M12.256 9.442h5.116v4.604h-5.116V9.442Z", fill: "#0078D4" }),
      /* @__PURE__ */ l("path", { d: "M12.256 14.047h5.116v4.604h-5.116v-4.604Z", fill: "#0364B8" }),
      /* @__PURE__ */ l("path", { d: "M7.253 14.085h5.003v4.168H7.253v-4.168Z", fill: "#14447D" }),
      /* @__PURE__ */ l("path", { d: "M17.372 14.047h4.605v4.604h-4.605v-4.604Z", fill: "#0078D4" }),
      /* @__PURE__ */ l(
        "path",
        {
          d: "m22.766 12.912-.01.005-7.623 4.288c-.034.02-.067.04-.103.057-.13.062-.27.097-.413.102l-.417-.243a1.038 1.038 0 0 1-.102-.059l-7.725-4.41h-.004l-.253-.14v8.679a1.049 1.049 0 0 0 1.056 1.042H21.96c.009 0 .016-.004.025-.004.123-.008.243-.033.358-.075a1.409 1.409 0 0 0 .237-.137c.262-.194.418-.5.419-.826v-8.68a.46.46 0 0 1-.234.401Z",
          fill: "url(#microsoft-outlook_svg__a)"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.5,
          d: "M22.59 12.477v.532l-7.97 5.489-8.253-5.842a.005.005 0 0 0-.005-.005l-.758-.455v-.384l.313-.005.66.379.015.005.056.036 7.777 4.436.297.173c.025-.01.05-.02.082-.03.015-.01 7.7-4.334 7.7-4.334l.086.005Z",
          fill: "#0A2767"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "m22.766 12.912-.01.006-7.623 4.287c-.034.02-.067.04-.103.058a1.069 1.069 0 0 1-1.04-.058l-7.624-4.287-.009-.006a.46.46 0 0 1-.24-.4v8.679a1.048 1.048 0 0 0 1.054 1.042h14.774A1.048 1.048 0 0 0 23 21.19v-8.68a.46.46 0 0 1-.234.401Z",
          fill: "#1490DF"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.1,
          d: "m15.244 17.142-.114.064a1.06 1.06 0 0 1-.505.164l2.9 3.43 5.06 1.22c.139-.105.249-.243.32-.401l-7.661-4.477Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.05,
          d: "m15.76 16.852-.63.354a1.058 1.058 0 0 1-.505.164l1.359 3.747 6.602.901c.26-.195.414-.502.414-.827v-.112l-7.24-4.227Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M7.186 22.233h14.757c.227 0 .449-.07.632-.205L14.2 17.122a1.058 1.058 0 0 1-.102-.059l-7.726-4.41h-.004l-.252-.141v8.65c0 .59.478 1.07 1.07 1.07Z",
          fill: "#28A8EA"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.1,
          d: "M13.279 6.798v10.913a.94.94 0 0 1-.588.87.882.882 0 0 1-.348.072H6.116V6.373H7.14V5.86h5.204a.94.94 0 0 1 .936.937Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.2,
          d: "M12.767 7.31v10.913a.832.832 0 0 1-.076.358.932.932 0 0 1-.86.582H6.116V6.373h5.715a.859.859 0 0 1 .425.107.93.93 0 0 1 .511.83Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.2,
          d: "M12.767 7.31v9.89a.944.944 0 0 1-.936.94H6.116V6.372h5.715a.859.859 0 0 1 .425.108.93.93 0 0 1 .511.83Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.2,
          d: "M12.256 7.31v9.89a.94.94 0 0 1-.937.94H6.116V6.372h5.204c.517 0 .936.42.936.937Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M1.938 6.372h9.38c.518 0 .938.42.938.938v9.38c0 .518-.42.938-.938.938h-9.38A.938.938 0 0 1 1 16.69V7.31c0-.518.42-.938.938-.938Z",
          fill: "url(#microsoft-outlook_svg__b)"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M3.931 10.306a2.728 2.728 0 0 1 1.072-1.184 3.239 3.239 0 0 1 1.702-.426A3.015 3.015 0 0 1 8.28 9.1c.45.268.812.661 1.042 1.131a3.65 3.65 0 0 1 .366 1.663 3.851 3.851 0 0 1-.376 1.74c-.237.486-.61.893-1.075 1.17a3.135 3.135 0 0 1-1.634.415 3.08 3.08 0 0 1-1.61-.41 2.745 2.745 0 0 1-1.056-1.132 3.563 3.563 0 0 1-.37-1.643 3.956 3.956 0 0 1 .364-1.728Zm1.142 2.778c.123.311.332.581.603.779.275.192.605.292.941.283.358.014.71-.089 1.005-.293.267-.196.47-.467.585-.778.129-.348.192-.716.187-1.086a3.306 3.306 0 0 0-.176-1.1 1.739 1.739 0 0 0-.567-.812 1.557 1.557 0 0 0-.996-.307 1.624 1.624 0 0 0-.963.286c-.275.198-.488.47-.614.785a3.121 3.121 0 0 0-.004 2.244v-.001Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ l("path", { d: "M17.372 4.837h4.605v4.605h-4.605V4.837Z", fill: "#50D9FF" }),
      /* @__PURE__ */ a("defs", { children: [
        /* @__PURE__ */ a(
          "linearGradient",
          {
            id: "microsoft-outlook_svg__a",
            x1: 14.558,
            y1: 12.512,
            x2: 14.558,
            y2: 22.233,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ l("stop", { stopColor: "#35B8F1" }),
              /* @__PURE__ */ l("stop", { offset: 1, stopColor: "#28A8EA" })
            ]
          }
        ),
        /* @__PURE__ */ a(
          "linearGradient",
          {
            id: "microsoft-outlook_svg__b",
            x1: 2.955,
            y1: 5.639,
            x2: 10.3,
            y2: 18.361,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ l("stop", { stopColor: "#1784D9" }),
              /* @__PURE__ */ l("stop", { offset: 0.5, stopColor: "#107AD5" }),
              /* @__PURE__ */ l("stop", { offset: 1, stopColor: "#0A63C9" })
            ]
          }
        )
      ] })
    ]
  }
);
export {
  h as default
};
