/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Pinterest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      fill="#fff"
    />
    <path
      d="M12 2C6.477 2 2 6.477 2 12c0 4.236 2.636 7.858 6.356 9.315-.088-.791-.167-2.008.034-2.872.182-.78 1.173-4.97 1.173-4.97s-.3-.6-.3-1.485c0-1.39.807-2.428 1.81-2.428.854 0 1.266.64 1.266 1.408 0 .858-.547 2.141-.829 3.33-.235.995.5 1.807 1.481 1.807 1.778 0 3.144-1.874 3.144-4.58 0-2.394-1.72-4.068-4.177-4.068-2.846 0-4.516 2.134-4.516 4.34 0 .86.331 1.78.745 2.282.081.099.093.186.069.287-.076.316-.245.995-.278 1.134-.043.183-.145.222-.334.133-1.25-.58-2.03-2.407-2.03-3.873 0-3.155 2.292-6.052 6.607-6.052 3.47 0 6.165 2.472 6.165 5.776 0 3.446-2.173 6.22-5.189 6.22-1.013 0-1.966-.527-2.292-1.149 0 0-.502 1.91-.623 2.378-.226.868-.835 1.957-1.243 2.621.936.29 1.93.446 2.961.446 5.523 0 10-4.477 10-10S17.523 2 12 2Z"
      fill="#E60023"
    />
  </svg>
);

export default Pinterest;
