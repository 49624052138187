import * as React from "react";
const SvgIconFileGifColor = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14ZM9.25 9c.41 0 .75.34.75.75s-.34.75-.75.75H6.5v3h2v-.75c0-.41.34-.75.75-.75s.75.34.75.75V14c0 .5-.4 1-1 1H6c-.6 0-1-.5-1-1v-4c0-.5.4-1 1-1h3.25Zm3.75.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75v4.5c0 .41.34.75.75.75s.75-.34.75-.75v-4.5Zm6 0c0-.41-.34-.75-.75-.75H15.5c-.55 0-1 .45-1 1v4.25c0 .41.34.75.75.75s.75-.34.75-.75V13h1.25c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H16v-1h2.25c.41 0 .75-.34.75-.75Z"
      fill="#8997A1"
    />
  </svg>
);
export default SvgIconFileGifColor;
