import { jsx as c } from "react/jsx-runtime";
const A = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M8 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 6 16V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 8 2h12c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 18H8Zm0-2h12V4H8v12Zm-4 6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 20V7c0-.283.096-.521.288-.713A.967.967 0 0 1 3 6a.97.97 0 0 1 .713.287A.97.97 0 0 1 4 7v13h13c.283 0 .52.096.712.288A.965.965 0 0 1 18 21c0 .283-.096.52-.288.712A.965.965 0 0 1 17 22H4Z" })
  }
);
export {
  A as default
};
