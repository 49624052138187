import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextZeta, TextDelta } from "@happeouikit/typography";
// @ts-ignore types missing in build process
import { Button, ButtonColorScheme } from "@happeouikit/buttons-ds3";
import { Modal, ModalIcon } from "./index";
import { ConfirmModalProps, MODAL_STATES } from "./Modal.types";

/**
 *
 * ConfirmModal
 *
 */
const ConfirmModal = ({
  children,
  isOpen,
  close,
  bodyText,
  header,
  okCb,
  okText,
  cancelText,
  state,
  btnDisabled,
  hideCancel,
  width = "350px",
  closeOnClickOutside,
  closeOnEsc,
  ...props
}: ConfirmModalProps) => {
  const buttonType = useMemo<ButtonColorScheme>(() => {
    switch (state) {
      case MODAL_STATES.delete:
      case MODAL_STATES.alert:
        return "alert" as ButtonColorScheme;
      case MODAL_STATES.info:
        return "active" as ButtonColorScheme;
      case MODAL_STATES.success:
        return "success" as ButtonColorScheme;
      case MODAL_STATES.warning:
        return "warning" as ButtonColorScheme;
      default:
        return "active" as ButtonColorScheme;
    }
  }, [state]);

  useEffect(() => {
    if (!closeOnEsc) {
      return;
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        close();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [close, closeOnEsc]);

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      width={width}
      footer={false}
      closeOnClickOutside={closeOnClickOutside}
      {...props}
    >
      <BodyWrapper>
        {state && <ModalIcon state={state} />}
        <HeaderText>{header}</HeaderText>
        <TextZeta color="var(--color-primary-text-on-light, #000000)">
          {bodyText}
        </TextZeta>
      </BodyWrapper>
      {children && <ModalContent>{children}</ModalContent>}
      <ModalFooter>
        {!hideCancel && (
          <Button
            variant="secondary"
            colorScheme={buttonType}
            onClick={close}
            data-tracker="confirm-modal-cancel"
          >
            {cancelText}
          </Button>
        )}
        <Button
          colorScheme={buttonType}
          onClick={okCb}
          disabled={btnDisabled}
          data-tracker="confirm-modal-ok"
        >
          {okText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const HeaderText = styled(TextDelta).attrs({
  as: "h2",
})`
  color: var(--color-primary-text-on-light, #000000);
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--space-md, 16px);
  ul {
    list-style: disc;
    padding-inline-start: var(--space-md, 16px);
  }

  ${TextZeta}, ${HeaderText} {
    text-align: left;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: var(--space-lg, 24px);
`;

const ModalContent = styled.div`
  flex: 1 1 auto;
`;

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  bodyText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  okCb: PropTypes.func,
  hideCancel: PropTypes.bool,
  /* Defines the icon and color scheme of the confirmation modal. */
  state: PropTypes.oneOf(["alert", "delete", "success", "info", "warn"]),
  btnDisabled: PropTypes.bool,
  /* Make sure to use clear text. Sometimes OK and Cancel are really confusing. */
  okText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  closeOnClickOutside: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  isOpen: false,
  close: () => {},
  bodyText: "",
  header: "",
  okCb: () => {},
  hideCancel: false,
  btnDisabled: false,
  state: MODAL_STATES.success,
  okText: "OK",
  cancelText: "Cancel",
  width: "350px",
  closeOnClickOutside: false,
  closeOnEsc: true,
};

export default ConfirmModal;
