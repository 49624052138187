import * as React from "react";
const SvgIconWidgetSpacer = (props) => (
  <svg
    viewBox="0 0 93 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <g filter="url(#icon-widget-spacer_svg__a)">
      <g clipPath="url(#icon-widget-spacer_svg__b)">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
        <rect
          x={-9.025}
          y={-1.447}
          width={110.469}
          height={66.366}
          rx={1.422}
          fill="#fff"
        />
        <rect
          x={6.125}
          y={6}
          width={79.639}
          height={42.664}
          rx={1.422}
          fill="#0075DB"
        />
        <rect
          x={6.125}
          y={6}
          width={79.639}
          height={42.664}
          rx={1.422}
          fill="#fff"
          fillOpacity={0.9}
        />
        <g filter="url(#icon-widget-spacer_svg__c)">
          <rect x={11.125} y={11} width={70} height={10} rx={1} fill="#fff" />
        </g>
        <g filter="url(#icon-widget-spacer_svg__d)">
          <rect x={11.125} y={32} width={70} height={10} rx={1} fill="#fff" />
        </g>
        <path stroke="#0075DB" strokeDasharray="1.42 1.42" d="M6.125 26.5h79" />
      </g>
    </g>
    <defs>
      <filter
        id="icon-widget-spacer_svg__a"
        x={0.593}
        y={0.195}
        width={91.563}
        height={54.588}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44123"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44123"
          result="effect2_dropShadow_213_44123"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44123"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-spacer_svg__c"
        x={8.281}
        y={8.867}
        width={75.689}
        height={15.688}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44123"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44123"
          result="effect2_dropShadow_213_44123"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44123"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-spacer_svg__d"
        x={8.281}
        y={29.867}
        width={75.689}
        height={15.688}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44123"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44123"
          result="effect2_dropShadow_213_44123"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44123"
          result="shape"
        />
      </filter>
      <clipPath id="icon-widget-spacer_svg__b">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconWidgetSpacer;
