/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Miro = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.65 3H4.35A1.35 1.35 0 0 0 3 4.35v15.3c0 .746.604 1.35 1.35 1.35h15.3A1.35 1.35 0 0 0 21 19.65V4.35A1.35 1.35 0 0 0 19.65 3Z"
      fill="#FFD02F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.238 5.251h-1.976l1.647 2.893-3.622-2.893H9.311l1.812 3.536-3.787-3.536H5.36l1.976 4.5-1.976 9h1.976l3.787-9.643-1.812 9.643h1.976L14.91 8.465 13.262 18.75h1.976l3.622-11.25-3.622-2.25Z"
      fill="#050038"
    />
  </svg>
);

export default Miro;
