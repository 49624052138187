import { jsx as a } from "react/jsx-runtime";
const s = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M19 8a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 16 5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 19 2c.833 0 1.542.292 2.125.875S22 4.167 22 5s-.292 1.542-.875 2.125A2.893 2.893 0 0 1 19 8ZM4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h10.1a5.085 5.085 0 0 0 0 2 5.108 5.108 0 0 0 1.55 2.7L12 11 5.3 6.8a.774.774 0 0 0-.862-.025.811.811 0 0 0-.438.75c0 .15.033.288.1.413a.828.828 0 0 0 .3.312l7.075 4.425c.167.1.342.15.525.15s.358-.05.525-.15l4.75-2.975A5.026 5.026 0 0 0 19 10a5.1 5.1 0 0 0 1.575-.25A4.674 4.674 0 0 0 22 9v9c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 20H4Z" })
  }
);
export {
  s as default
};
