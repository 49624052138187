import { jsxs as c, jsx as e } from "react/jsx-runtime";
const v = (l) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4 22c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 2 20V6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 4 4h1V2.975c0-.283.096-.517.287-.7A.993.993 0 0 1 6 2a.97.97 0 0 1 .713.287A.97.97 0 0 1 7 3v1h8V2.975c0-.283.096-.517.288-.7A.99.99 0 0 1 16 2c.283 0 .52.096.712.287.192.192.288.43.288.713v1h1c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v5.674a6.952 6.952 0 0 0-2-.603V10H4v10h6.29c.215.722.543 1.396.965 2H4ZM4 8h14V6H4v2Z"
        }
      ),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M13.463 21.538C14.438 22.513 15.617 23 17 23s2.563-.487 3.538-1.462C21.513 20.563 22 19.383 22 18s-.487-2.563-1.462-3.538C19.563 13.487 18.383 13 17 13s-2.562.487-3.537 1.462C12.488 15.437 12 16.617 12 18s.488 2.563 1.463 3.538ZM17.5 15.5v2.3l1.5 1.5a.48.48 0 0 1 0 .7.48.48 0 0 1-.7 0l-1.575-1.575a.762.762 0 0 1-.175-.25.735.735 0 0 1-.05-.275v-2.4c0-.133.05-.25.15-.35a.48.48 0 0 1 .7 0c.1.1.15.217.15.35Z"
        }
      )
    ]
  }
);
export {
  v as default
};
