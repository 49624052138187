import { jsxs as t, jsx as l } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "m21.64 10.142-.028-.072-2.722-7.105a.71.71 0 0 0-.704-.446.73.73 0 0 0-.41.154.73.73 0 0 0-.24.366l-1.839 5.624H8.254L6.416 3.039a.714.714 0 0 0-.241-.367.729.729 0 0 0-.833-.045.715.715 0 0 0-.28.337l-2.728 7.101-.027.072a5.055 5.055 0 0 0 1.677 5.842l.01.008.024.018 4.147 3.105 2.051 1.553 1.25.943a.84.84 0 0 0 1.016 0l1.25-.943 2.052-1.553 4.171-3.124.01-.009a5.057 5.057 0 0 0 1.675-5.835Z",
          fill: "#E24329"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "m21.64 10.142-.028-.072a9.195 9.195 0 0 0-3.66 1.645l-5.979 4.52 3.808 2.88 4.172-3.125.01-.008a5.057 5.057 0 0 0 1.677-5.84Z",
          fill: "#FC6D26"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "m8.165 19.114 2.051 1.553 1.25.943a.84.84 0 0 0 1.016 0l1.25-.943 2.052-1.553-3.81-2.878c-2.037 1.536-3.81 2.878-3.81 2.878Z",
          fill: "#FCA326"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M5.994 11.715a9.182 9.182 0 0 0-3.66-1.65l-.027.072a5.055 5.055 0 0 0 1.677 5.842l.01.008.024.018 4.147 3.105 3.808-2.878-5.98-4.517Z",
          fill: "#FC6D26"
        }
      )
    ]
  }
);
export {
  h as default
};
