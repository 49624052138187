import { jsx as a } from "react/jsx-runtime";
const c = (v) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ a("path", { d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm-9 7.3a.2.2 0 0 1-.2.2H7.5v3h1v-.75a.75.75 0 0 1 1.5 0v.95c0 .7-.5 1.3-1.3 1.3H7.3c-.8 0-1.3-.7-1.3-1.3v-3.3C6 9.7 6.5 9 7.3 9h1.3c.9 0 1.4.7 1.4 1.3Zm3 3.95a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 1.5 0v4.5Zm4.5-4.5a.75.75 0 0 1-.75.75H16v1h.75a.75.75 0 0 1 0 1.5H16v1.25a.75.75 0 0 1-1.5 0V10a1 1 0 0 1 1-1h1.25a.75.75 0 0 1 .75.75Z" })
  }
);
export {
  c as default
};
