import * as React from "react";
const SvgIconFileMsExcelColor = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="icon-file-ms-excel-color_svg__a"
        x1={5.822}
        y1={11.568}
        x2={20.178}
        y2={36.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#18884f" />
        <stop offset={0.5} stopColor="#117e43" />
        <stop offset={1} stopColor="#0b6631" />
      </linearGradient>
    </defs>
    <path
      d="m29 23-17-3v22.167A1.833 1.833 0 0 0 13.833 44h29.334A1.833 1.833 0 0 0 45 42.167V34z"
      fill="#185c37"
    />
    <path
      d="M29 4H13.833A1.833 1.833 0 0 0 12 5.833V14l17 10 9 3 7-3V14z"
      fill="#21a366"
    />
    <path fill="#107c41" d="M12 14h17v10H12z" />
    <path fill="none" d="M0 0h48v48H0z" />
    <path
      d="M24.167 12H12v25h12.167A1.839 1.839 0 0 0 26 35.167V13.833A1.839 1.839 0 0 0 24.167 12z"
      opacity={0.1}
    />
    <path
      d="M23.167 13H12v25h11.167A1.839 1.839 0 0 0 25 36.167V14.833A1.839 1.839 0 0 0 23.167 13z"
      opacity={0.2}
    />
    <path
      d="M23.167 13H12v23h11.167A1.839 1.839 0 0 0 25 34.167V14.833A1.839 1.839 0 0 0 23.167 13z"
      opacity={0.2}
    />
    <path
      d="M22.167 13H12v23h10.167A1.839 1.839 0 0 0 24 34.167V14.833A1.839 1.839 0 0 0 22.167 13z"
      opacity={0.2}
    />
    <rect
      x={2}
      y={13}
      width={22}
      height={22}
      rx={1.833}
      fill="url(#icon-file-ms-excel-color_svg__a)"
    />
    <path
      d="m7.677 29.958 3.856-5.975L8 18.041h2.842l1.928 3.8c.178.361.3.629.366.806h.025q.19-.432.4-.839l2.061-3.765h2.609l-3.623 5.907 3.715 6.008h-2.776l-2.227-4.171a3.5 3.5 0 0 1-.266-.557h-.033a2.638 2.638 0 0 1-.258.54l-2.293 4.188z"
      fill="#fff"
    />
    <path
      d="M43.167 4H29v10h16V5.833A1.833 1.833 0 0 0 43.167 4z"
      fill="#33c481"
    />
    <path fill="#107c41" d="M29 24h16v10H29z" />
  </svg>
);
export default SvgIconFileMsExcelColor;
