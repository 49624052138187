import { jsxs as h, jsx as e } from "react/jsx-runtime";
const v = (l) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z",
          fill: "#4350B0"
        }
      ),
      /* @__PURE__ */ e("path", { d: "M6 7h12v3H6V7ZM6 12h6v5H6v-5ZM18 12h-4v5h4v-5Z", fill: "#fff" })
    ]
  }
);
export {
  v as default
};
