import { jsx as t } from "react/jsx-runtime";
const w = (o) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ t("path", { d: "M6 15a.968.968 0 0 1-.713-.287A.967.967 0 0 1 5 14v-4a.97.97 0 0 1 .287-.713A.97.97 0 0 1 6 9h3l3.3-3.3c.317-.317.679-.388 1.087-.213.409.175.613.488.613.938v11.15c0 .45-.204.762-.613.937-.408.175-.77.104-1.087-.212L9 15H6Zm10 1V7.95a4.2 4.2 0 0 1 1.812 1.626c.459.733.688 1.541.688 2.425 0 .883-.23 1.683-.688 2.4A4.249 4.249 0 0 1 16 16Z" })
  }
);
export {
  w as default
};
