import { jsxs as l, jsx as a } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          d: "M5.282 12.346c.257.897.613 1.762 1.061 2.58.16.377.419.705.748.949l.196.095c.531.101.818-.323 1.014-.684.255-.62.476-1.251.663-1.894.037-.133.07-.228.101-.361.071-.224.126-.453.165-.685l2.329-6.224a5.168 5.168 0 0 1 1.83-1.533 4.59 4.59 0 0 1 2.288-.59c1.154.01 2.273.4 3.183 1.11 1.534 1.146 2.42 3.433 2.42 3.433.129.3.196.623.196.95-.01.298-.065.593-.164.875-.135.27-.3.522-.494.754a1.91 1.91 0 0 1-.748.488c-.284.105-.583.16-.886.164a1.958 1.958 0 0 1-.945-.228 2.774 2.774 0 0 1-.785-.589.032.032 0 0 0-.032-.032 4.345 4.345 0 0 1-.36-.652 6.627 6.627 0 0 0-.425-.818c-.096-.132-.165-.265-.26-.392a.982.982 0 0 0-.297-.287.647.647 0 0 0-.716-.095l-.032.032a1.798 1.798 0 0 0-.53.817c-.333.78-.598 1.588-.792 2.414l-.095.488-.982 3.136-1.316 2.982a4.892 4.892 0 0 1-1.83 1.47c-.716.34-1.496.52-2.287.53-3.3.038-4.824-2.934-4.824-2.934C.798 14.543.246 10.49.113 9.18a2.022 2.022 0 0 1 .26-1.597c.195-.361.49-.658.85-.855a2.579 2.579 0 0 1 1.21-.26 2.298 2.298 0 0 1 1.756.945c.272.454.418.972.424 1.501.13 1.162.359 2.31.685 3.433h-.016Z",
          fill: "#68778D"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M20.776 6.494a2.961 2.961 0 0 0-.53.063c.414.62.77 1.277 1.06 1.964.13.3.196.623.197.95a2.91 2.91 0 0 1-.165.896c-.135.27-.3.522-.493.754-.2.228-.459.397-.748.488-.284.105-.584.16-.887.165a2.027 2.027 0 0 1-1.146-.345l-.228.886a10.32 10.32 0 0 1-.721 1.958c-.165.387-.362.76-.59 1.114-.15.234-.365.42-.62.53a.898.898 0 0 1-.292.033.532.532 0 0 1-.297-.096 1.783 1.783 0 0 1-.425-.53 6.7 6.7 0 0 1-.323-.685c-.197-.488-.531-1.698-.531-1.698l-.303-1.247-.329-1.279-.228-.849a9.75 9.75 0 0 0-1.438-3.269A5.529 5.529 0 0 0 9.044 4.27a5.274 5.274 0 0 0-2.531-.196A5.55 5.55 0 0 0 2.92 6.557c.22.054.43.144.621.266.25.154.47.354.648.589.272.454.418.972.424 1.501.132 1.136.345 2.26.637 3.365a10.803 10.803 0 0 1 1.539-3.396c.085-.122.195-.223.323-.298a.435.435 0 0 1 .228-.063.722.722 0 0 1 .393.1c.037 0 .037.032.07.032.105.1.202.209.291.324.173.317.326.645.456.982.266.721.494 1.634.494 1.634l.652 2.616c.228.905.522 1.792.881 2.653 1.014 2.319 2.553 2.972 2.553 2.972a5.9 5.9 0 0 0 2.451.658 4.802 4.802 0 0 0 2.35-.494 6.324 6.324 0 0 0 2.06-1.671c.34-.468.646-.96.918-1.47a17.918 17.918 0 0 0 1.4-3.789c.227-.816.405-1.645.531-2.483.069-.425.133-.817.164-1.242.07-.39.059-.791-.031-1.178-.032-.063-.032-.127-.064-.196a2.308 2.308 0 0 0-1.014-1.14 1.968 1.968 0 0 0-1.12-.335Z",
          fill: "#38404C"
        }
      )
    ]
  }
);
export {
  h as default
};
