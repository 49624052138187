/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleChrome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#google-chrome_svg__a)">
      <path
        d="M20.658 7a10 10 0 0 0-17.32 0l.6 10.8L20.658 7Z"
        fill="url(#google-chrome_svg__b)"
      />
      <path
        d="M7.67 14.5 3.34 7A10 10 0 0 0 12 22l9.8-10-14.13 2.5Z"
        fill="url(#google-chrome_svg__c)"
      />
      <path
        d="M16.33 14.5 12 22a10 10 0 0 0 8.658-15H12l4.33 7.5Z"
        fill="url(#google-chrome_svg__d)"
      />
      <path d="M12 17a5 5 0 1 0 0-9.999A5 5 0 0 0 12 17Z" fill="#fff" />
      <path
        d="M12 15.96a3.96 3.96 0 1 0 0-7.92 3.96 3.96 0 0 0 0 7.92Z"
        fill="#1A73E8"
      />
    </g>
    <defs>
      <linearGradient
        id="google-chrome_svg__b"
        x1={3.34}
        y1={8.25}
        x2={20.658}
        y2={8.25}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D93025" />
        <stop offset={1} stopColor="#EA4335" />
      </linearGradient>
      <linearGradient
        id="google-chrome_svg__c"
        x1={13.082}
        y1={21.374}
        x2={4.422}
        y2={6.374}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1E8E3E" />
        <stop offset={1} stopColor="#34A853" />
      </linearGradient>
      <linearGradient
        id="google-chrome_svg__d"
        x1={10.54}
        y1={22}
        x2={19.2}
        y2={7.026}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCC934" />
        <stop offset={1} stopColor="#FBBC04" />
      </linearGradient>
      <clipPath id="google-chrome_svg__a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleChrome;
