import * as React from "react";
const SvgIconViewQuilt = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 18.5h-3c-.55 0-1-.45-1-1v-11c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v11c0 .55-.45 1-1 1Zm6 0h-3c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1Zm3 0h3c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1Zm-7-8v-4c0-.55.45-1 1-1h9c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1h-9c-.55 0-1-.45-1-1Z"
    />
  </svg>
);
export default SvgIconViewQuilt;
