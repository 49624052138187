import { jsx as e } from "react/jsx-runtime";
const c = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M20.56 19.173c-.326.538-.93.827-1.558.827H4.998c-.629 0-1.232-.29-1.558-.827A9.954 9.954 0 0 1 2 14C2 8.477 6.477 4 12 4s10 4.477 10 10a9.954 9.954 0 0 1-1.44 5.173ZM10.45 15.5c.4.4.917.596 1.55.588.633-.009 1.1-.238 1.4-.688l4.225-6.325c.15-.233.13-.446-.063-.637-.191-.192-.404-.213-.637-.063L10.6 12.601c-.45.3-.687.758-.712 1.375-.025.617.162 1.125.562 1.525Z"
      }
    )
  }
);
export {
  c as default
};
