import * as React from "react";
const SvgIconSupervisedUserCircle = (props) => (
  <svg
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M14 .667C6.64.667.667 6.64.667 14S6.64 27.333 14 27.333 27.333 21.36 27.333 14 21.36.667 14 .667Zm4.813 8.453a2.566 2.566 0 0 1 2.574 2.573 2.566 2.566 0 0 1-2.574 2.574 2.566 2.566 0 0 1-2.573-2.574c-.013-1.426 1.147-2.573 2.573-2.573Zm-4.853 1.04a3.153 3.153 0 0 0-3.147-3.147 3.135 3.135 0 0 0-3.146 3.147 3.152 3.152 0 0 0 3.146 3.146 3.153 3.153 0 0 0 3.147-3.146Zm-3.147 9.026v5c-3.2-1-5.733-3.466-6.853-6.613 1.4-1.493 4.893-2.253 6.853-2.253.707 0 1.6.107 2.534.293-2.187 1.16-2.534 2.694-2.534 3.573Zm2.134 5.427c.346.04.693.053 1.053.053 4.52 0 8.373-2.826 9.933-6.786-1.226-1.014-3.693-1.534-5.12-1.534-1.946 0-5.866.947-5.866 2.84v5.427Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconSupervisedUserCircle;
