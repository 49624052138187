/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftAccessAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m12.208 9.518 1.135 3.53H10.61l1.146-3.508a4.51 4.51 0 0 0 .203-.818H12c.046.271.115.538.208.796Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3Zm-6.368 4.124h-2.21l-3.603 9.75h2.074l.768-2.325h3.651l.783 2.326h2.086l-3.55-9.75Z"
    />
  </svg>
);

export default MicrosoftAccessAlt;
