import { jsx as a } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M9.075 21a1.978 1.978 0 0 1-1.4-.575l-4.1-4.1a1.978 1.978 0 0 1-.575-1.4v-5.85a1.978 1.978 0 0 1 .575-1.4l4.1-4.1A1.978 1.978 0 0 1 9.075 3h5.85a1.978 1.978 0 0 1 1.4.575l4.1 4.1a1.978 1.978 0 0 1 .575 1.4v5.85a1.978 1.978 0 0 1-.575 1.4l-4.1 4.1a1.978 1.978 0 0 1-1.4.575h-5.85ZM12 17a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 16a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 15a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 16c0 .283.096.52.288.712A.965.965 0 0 0 12 17Zm0-4a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 12V8a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 7a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 8v4c0 .283.096.52.288.712A.965.965 0 0 0 12 13Zm-2.9 6h5.8l4.1-4.1V9.1L14.9 5H9.1L5 9.1v5.8L9.1 19Z" })
  }
);
export {
  h as default
};
