/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Dribbble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.417 2.524a9.326 9.326 0 0 1 9.328 9.331c0 5.154-4.172 9.34-9.328 9.34a9.326 9.326 0 0 1-9.328-9.332c0-5.155 4.172-9.34 9.328-9.34Z"
      fill="#E74D89"
    />
    <path
      d="M12.418 21.859c-5.516 0-10-4.482-10-9.996 0-5.522 4.484-10.004 10-10.004s10 4.482 10 9.996-4.484 10.004-10 10.004Zm8.43-8.635c-.29-.094-2.641-.79-5.32-.368 1.117 3.066 1.57 5.57 1.656 6.085a8.491 8.491 0 0 0 3.664-5.717Zm-5.094 6.507c-.125-.75-.625-3.363-1.82-6.476a.145.145 0 0 1-.055.016c-4.82 1.681-6.547 5.021-6.703 5.334a8.502 8.502 0 0 0 5.242 1.8 8.336 8.336 0 0 0 3.336-.674Zm-9.68-2.15c.196-.33 2.54-4.209 6.946-5.64.109-.04.226-.07.335-.102-.21-.485-.445-.97-.695-1.447-4.265 1.275-8.406 1.22-8.781 1.213 0 .086-.008.172-.008.258a8.552 8.552 0 0 0 2.203 5.717ZM4.06 10.118c.382.007 3.898.023 7.898-1.04-1.414-2.52-2.945-4.631-3.164-4.936a8.563 8.563 0 0 0-4.734 5.976Zm6.359-6.555a44.997 44.997 0 0 1 3.188 4.998c3.039-1.142 4.32-2.863 4.476-3.082a8.51 8.51 0 0 0-5.664-2.15c-.688 0-1.36.085-2 .234Zm8.61 2.902c-.18.242-1.61 2.08-4.766 3.371.195.407.39.821.57 1.236.063.148.125.297.188.438 2.843-.36 5.664.219 5.945.274a8.512 8.512 0 0 0-1.938-5.32Z"
      fill="#B2215A"
    />
  </svg>
);

export default Dribbble;
