import { jsx as c } from "react/jsx-runtime";
const e = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M10.95 12.925a1.1 1.1 0 0 0 .375-.063.871.871 0 0 0 .325-.212L15.2 9.1a.948.948 0 0 0 .275-.7c0-.283-.092-.525-.275-.725a1.035 1.035 0 0 0-.725-.275.948.948 0 0 0-.7.275L10.95 10.5l-.7-.7a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275c-.2.2-.3.442-.3.725s.1.517.3.7l1.4 1.425c.1.1.208.17.325.212a1.1 1.1 0 0 0 .375.063ZM12 19.35c2.033-1.867 3.542-3.563 4.525-5.088C17.508 12.737 18 11.383 18 10.2c0-1.817-.58-3.304-1.738-4.463C15.104 4.579 13.683 4 12 4c-1.683 0-3.104.579-4.263 1.737C6.579 6.896 6 8.383 6 10.2c0 1.183.492 2.537 1.475 4.062.983 1.525 2.492 3.221 4.525 5.088Zm0 2.275c-.133 0-.267-.025-.4-.075a1.2 1.2 0 0 1-.35-.2C8.817 19.2 7 17.204 5.8 15.363 4.6 13.521 4 11.8 4 10.2c0-2.5.804-4.492 2.413-5.975C8.021 2.742 9.883 2 12 2c2.117 0 3.979.742 5.587 2.225C19.196 5.708 20 7.7 20 10.2c0 1.6-.6 3.321-1.8 5.163-1.2 1.841-3.017 3.837-5.45 5.987a1.2 1.2 0 0 1-.35.2c-.133.05-.267.075-.4.075Z" })
  }
);
export {
  e as default
};
