import { jsx as a } from "react/jsx-runtime";
const c = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M16.1 12.8 8.25 4.9l2.525-1.95c.183-.133.38-.237.588-.313a1.872 1.872 0 0 1 1.275 0c.208.076.404.18.587.313l6.75 5.25c.133.1.233.221.3.362a1.02 1.02 0 0 1 0 .876.961.961 0 0 1-.3.362l-3.875 3Zm2.875 2.85-1.45-1.45 1.225-.925c.183-.133.388-.2.613-.2.225 0 .429.067.612.2.267.2.4.463.4.787a.939.939 0 0 1-.4.788l-1 .8Zm.125 5.75-3.3-3.3-2.575 2a2.36 2.36 0 0 1-.587.313 1.88 1.88 0 0 1-1.275 0 2.358 2.358 0 0 1-.588-.313l-6.75-5.25a.906.906 0 0 1-.387-.788.97.97 0 0 1 .412-.787.999.999 0 0 1 1.2 0L12 18.5l2.35-1.825-1.425-1.425c-.15.084-.296.142-.437.175-.142.034-.305.05-.488.05-.217 0-.425-.033-.625-.1-.2-.066-.4-.175-.6-.325L4 9.775a.874.874 0 0 1-.287-.35A1.044 1.044 0 0 1 3.625 9c0-.15.025-.296.075-.438a.77.77 0 0 1 .275-.362l1.05-.825-2.95-2.95a.96.96 0 0 1-.3-.7c0-.266.1-.5.3-.7.2-.2.438-.3.713-.3.275 0 .512.1.712.3L20.5 20a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.949.949 0 0 1-.7-.275Z" })
  }
);
export {
  c as default
};
