import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M17.305 10.75c0-3.07-1.64-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-.202.05-.397.122-.585.191l-.105.039 8.19 8.19v-2.1Zm-6 11c1.1 0 2-.9 2-2h-4a2 2 0 0 0 2 2ZM4.01 3.805a.997.997 0 0 1 1.41 0l14.57 14.57a.997.997 0 1 1-1.41 1.41l-1.035-1.035H4.715c-.89 0-1.33-1.08-.7-1.71l1.29-1.29v-5c0-1.27.29-2.43.81-3.43L4.01 5.215a.997.997 0 0 1 0-1.41Z"
    }
  )
);
export {
  c as default
};
