import { jsx as e } from "react/jsx-runtime";
const t = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2ZM18 10.232v-.417l-.03-.18c-.02-.13-.041-.26-.07-.388-.278-1.228-1.379-2.237-2.628-2.444-1.094-.181-1.987.192-2.757.925-.126.12-.241.251-.36.386-.054.064-.11.128-.169.192l-.085-.107c-.5-.634-1.103-1.132-1.9-1.343-.925-.245-1.787-.056-2.564.471-.85.577-1.328 1.386-1.422 2.405-.077.841.146 1.621.518 2.367.446.893 1.076 1.653 1.784 2.352 1.09 1.076 2.325 1.968 3.618 2.785.03.018.092.019.121 0 1.52-.967 2.967-2.022 4.17-3.369.639-.714 1.177-1.49 1.502-2.397.132-.368.222-.746.249-1.137.001-.024.008-.046.014-.069l.009-.032Z"
      }
    )
  }
);
export {
  t as default
};
