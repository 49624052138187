import * as React from "react";
const SvgIconFileAdobeIndesign = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 130.746 595.279 580.398"
    width="1em"
    height="1em"
    {...props}
  >
    <radialGradient
      id="icon-file-adobe-indesign_svg__a"
      cx={-183.69}
      cy={328.972}
      r={0.76}
      gradientTransform="matrix(545.6736 0 0 528.3113 100439.305 -173525.125)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#4b2b36" stopOpacity={0.98} />
      <stop offset={1} stopColor="#250012" />
    </radialGradient>
    <path
      d="M24.803 155.549h545.674v530.792H24.803V155.549z"
      fill="url(#icon-file-adobe-indesign_svg__a)"
    />
    <path
      d="M24.803 155.549h545.674v530.792H24.803V155.549zM0 711.145h595.28V130.746H0v580.399zm376.515-339.311c-3.721-1.736-8.682-2.48-14.635-2.48-31.996 0-54.319 24.556-54.319 65.481 0 46.63 22.819 65.48 51.344 65.48 6.2 0 12.649-.744 17.61-2.976V371.834zm-13.147-40.429c5.209 0 8.186 0 13.146.496v-65.977c0-1.736 1.24-2.48 2.479-2.48h40.182c1.984 0 2.48.744 2.48 1.984V497.34c0 6.944 0 15.626 1.24 25.051 0 1.736-.496 1.984-2.232 2.977-21.331 10.169-43.901 14.634-64.984 14.634-54.815 0-94.005-33.98-94.005-103.182-.247-59.281 38.942-105.415 101.694-105.415zM217.774 533.057c0 2.479-.496 3.224-3.225 3.224h-38.197c-2.48 0-3.225-1.24-3.225-3.224V266.668c0-2.48 1.24-3.225 3.225-3.225h38.445c1.984 0 2.977.744 2.977 2.977v266.637z"
      fill="#ff408c"
    />
  </svg>
);
export default SvgIconFileAdobeIndesign;
