import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 32 28",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement("rect", { width: 32, height: 28, fill: "#EB3584", rx: 2 }), /* @__PURE__ */ e.createElement("path", { fill: "#FFF", d: "m23 14-12 8V6z" }))
);
export {
  l as default
};
