import { jsx as c } from "react/jsx-runtime";
const A = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M14 13c.55 0 .983-.158 1.3-.475.317-.317.517-.617.6-.9a.836.836 0 0 1 .338-.437c.175-.125.379-.188.612-.188H20V4H8v7h3.15a.98.98 0 0 1 .612.2c.175.133.288.275.338.425.083.283.283.583.6.9.317.317.75.475 1.3.475Zm-6 5c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 6 16V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 8 2h12c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 18H8Zm-4 4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 20V7c0-.283.096-.521.288-.713A.967.967 0 0 1 3 6a.97.97 0 0 1 .713.287A.97.97 0 0 1 4 7v13h13c.283 0 .52.096.712.288A.965.965 0 0 1 18 21c0 .283-.096.52-.288.712A.965.965 0 0 1 17 22H4Z" })
  }
);
export {
  A as default
};
