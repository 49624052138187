/** @deprecated */
export const border = (type: string) => {
  switch (type) {
    case "gray":
      return `1px solid var(--color-old__gray06__will_become__gray400)`;
    case "disabled":
      return `1px solid var(--color-old__gray08__will_become__gray400)`;
    case "active":
      return `1px solid var(--color-old__active__will_become__active-primary)`;
    default:
      console.error("this shouldn't happen - this deprecated function (border()) is only called with 'active'")
      return `1px solid red`;
  }
};
