import { jsxs as h, jsx as a } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ a("path", { d: "M20.98 5a1 1 0 0 0-1.587-.81l-7.216 5.226L8.4 5.64a1 1 0 0 0-1.305-.094L3.4 8.306A1 1 0 0 0 3 9.107v2.684l2.224-1.839a1 1 0 0 1 1.332.052l4.916 4.755a1 1 0 0 0 1.32.062l3.202-2.568a1 1 0 0 1 .626-.22h4.36V5Z" }),
      /* @__PURE__ */ a("path", { d: "M5.27 11.917a1 1 0 0 1 1.286.087l4.916 4.755a1 1 0 0 0 1.32.062l3.202-2.568a1 1 0 0 1 .626-.22h3.36a1 1 0 0 1 1 1v4.67a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V14.09a1 1 0 0 1 .408-.806l1.861-1.366Z" })
    ]
  }
);
export {
  s as default
};
