import { jsx as t } from "react/jsx-runtime";
const c = (s) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ t("path", { d: "M18.45 7.5 17 10c1.1 0 2.042.392 2.825 1.175C20.608 11.958 21 12.9 21 14s-.392 2.042-1.175 2.825C19.042 17.608 18.1 18 17 18s-2.042-.392-2.825-1.175C13.392 16.042 13 15.1 13 14c0-.383.046-.738.137-1.063A3.74 3.74 0 0 1 13.55 12l3.175-5.5a.973.973 0 0 1 .85-.5c.383 0 .67.167.863.5a.96.96 0 0 1 .012 1Zm-9 0L8 10c1.1 0 2.042.392 2.825 1.175C11.608 11.958 12 12.9 12 14s-.392 2.042-1.175 2.825C10.042 17.608 9.1 18 8 18s-2.042-.392-2.825-1.175C4.392 16.042 4 15.1 4 14c0-.383.046-.738.138-1.063.091-.324.229-.637.412-.937l3.175-5.5a.973.973 0 0 1 .85-.5c.383 0 .67.167.863.5a.96.96 0 0 1 .012 1Z" })
  }
);
export {
  c as default
};
