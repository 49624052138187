import { jsx as t } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M5 19h9v-4c0-.283.096-.521.288-.713A.967.967 0 0 1 15 14h4V5H5v14Zm0 2c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v10l-6 6H5Zm3-7a.968.968 0 0 1-.713-.288A.967.967 0 0 1 7 13a.97.97 0 0 1 .287-.713A.97.97 0 0 1 8 12h3a.97.97 0 0 1 .713.287A.97.97 0 0 1 12 13c0 .283-.096.52-.287.712A.968.968 0 0 1 11 14H8Zm0-4a.968.968 0 0 1-.713-.288A.967.967 0 0 1 7 9a.97.97 0 0 1 .287-.713A.97.97 0 0 1 8 8h8c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 16 10H8Zm-3 9V5v14Z" })
  }
);
export {
  c as default
};
