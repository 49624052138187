import { jsxs as t, jsx as h } from "react/jsx-runtime";
const i = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ h(
        "path",
        {
          d: "M17.884 22.233H7.053a.937.937 0 0 1-.937-.937V2.704c0-.517.42-.937.937-.937h15.01c.518 0 .937.42.937.937v4.18l-5.116 15.349Z",
          fill: "#95A0B1"
        }
      ),
      /* @__PURE__ */ h("path", { d: "m23 12-2.558 1.535L17.884 12V6.884H23V12Z", fill: "#68778D" }),
      /* @__PURE__ */ h("path", { d: "m23 17.116-2.558 1.535-2.558-1.535V12H23v5.116Z", fill: "#596578" }),
      /* @__PURE__ */ h("path", { d: "M17.884 17.116H23v4.157c0 .53-.43.96-.96.96h-4.156v-5.117Z" }),
      /* @__PURE__ */ h(
        "path",
        {
          opacity: 0.2,
          d: "M12.341 5.86H6.116v12.791h6.225a.94.94 0 0 0 .938-.938V6.798a.94.94 0 0 0-.938-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          opacity: 0.2,
          d: "M11.83 6.372H6.116v12.79h5.714a.94.94 0 0 0 .937-.937V7.31a.94.94 0 0 0-.937-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          opacity: 0.5,
          d: "M11.83 6.372H6.116V18.14h5.714a.94.94 0 0 0 .937-.938V7.31a.94.94 0 0 0-.937-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          opacity: 0.5,
          d: "M11.318 6.372H6.116V18.14h5.202a.94.94 0 0 0 .938-.938V7.31a.94.94 0 0 0-.938-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M1.938 6.372h9.38c.518 0 .938.42.938.938v9.38c0 .518-.42.938-.938.938h-9.38A.938.938 0 0 1 1 16.69V7.31c0-.518.42-.938.938-.938Z",
          fill: "#596578"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M4.083 8.952h1.271l2.499 4.041c.113.182.199.327.255.438h.017a6.157 6.157 0 0 1-.037-.829v-3.65h1.085v6.096h-1.19L5.39 10.894a3.228 3.228 0 0 1-.232-.429h-.02c.026.303.037.606.032.91v3.673H4.083V8.952Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  i as default
};
