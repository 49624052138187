import { hsla } from "polished";
import { ColorString } from "./types";

const castShadowLight = hsla(0, 1, 0, 0.12) as ColorString;
const haloShadowLight = hsla(0, 1, 0, 0.05) as ColorString;
const ambientShadowLight = hsla(0, 1, 0, 0.03) as ColorString;
const castShadowDark = hsla(0, 1, 0, 0.16) as ColorString;
const haloShadowDark = hsla(0, 1, 0, 0.09) as ColorString;
const ambientShadowDark = hsla(0, 1, 0, 0.07) as ColorString;

export const boxShadowsLight = {
  shadow0: `0px 0px 0px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 0px 0px ${castShadowLight}`,
  shadow0Reversed: `0px 0px 0px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 0px 0px ${castShadowLight}`,
  shadow100: `0px 1px 4px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 1px 4px ${castShadowLight}`,
  shadow100Reversed: `0px -1px 4px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px -1px 4px ${castShadowLight}`,
  shadow200: `0px 2px 8px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 2px 8px ${castShadowLight}`,
  shadow200Reversed: `0px -2px 8px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px -2px 8px ${castShadowLight}`,
  shadow300: `0px 4px 16px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 4px 16px ${castShadowLight}`,
  shadow300Reversed: `0px -4px 16px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px -4px 16px ${castShadowLight}`,
  shadow400: `0px 12px 32px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 12px 32px ${castShadowLight}`,
  shadow400Reversed: `0px -12px 32px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px -12px 32px ${castShadowLight}`,
  shadow500: `0px 12px 40px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 12px 80px ${castShadowLight}`,
  shadow500Reversed: `0px -12px 40px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 1-2px 80px ${castShadowLight}`,
  shadowSubtle: `0px 0px 0px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 10px 60px ${castShadowLight}`,
  shadowSubtleReversed: `0px 0px 0px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px -10px 60px ${castShadowLight}`,
  shadowSubtleAlt: `0px 4px 12px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 0px 0px ${castShadowLight}`,
  shadowSubtleAltReversed: `0px -4px 12px ${ambientShadowLight}, 0px 0px 0px ${haloShadowLight}, 0px 0px 0px ${castShadowLight}`,
  shadowFloat: `0px 2px 12px ${ambientShadowLight}, 0px 2px 12px ${haloShadowLight}, 0px 0px 0px ${castShadowLight}`,
  shadowFloatReversed: `0px -2px 12px ${ambientShadowLight}, 0px -2px 12px ${haloShadowLight}, 0px 0px 0px ${castShadowLight}`,
} as const;

export const boxShadowsDark = {
  shadow0: `0px 0px 0px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 0px 0px ${castShadowDark}`,
  shadow0Reversed: `0px 0px 0px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 0px 0px ${castShadowDark}`,
  shadow100: `0px 1px 4px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 1px 4px ${castShadowDark}`,
  shadow100Reversed: `0px -1px 4px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px -1px 4px ${castShadowDark}`,
  shadow200: `0px 2px 8px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 2px 8px ${castShadowDark}`,
  shadow200Reversed: `0px -2px 8px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px -2px 8px ${castShadowDark}`,
  shadow300: `0px 4px 16px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 4px 16px ${castShadowDark}`,
  shadow300Reversed: `0px -4px 16px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px -4px 16px ${castShadowDark}`,
  shadow400: `0px 12px 32px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 12px 32px ${castShadowDark}`,
  shadow400Reversed: `0px -12px 32px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px -12px 32px ${castShadowDark}`,
  shadow500: `0px 12px 40px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 12px 80px ${castShadowDark}`,
  shadow500Reversed: `0px -12px 40px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 1-2px 80px ${castShadowDark}`,
  shadowSubtle: `0px 0px 0px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 10px 60px ${castShadowDark}`,
  shadowSubtleReversed: `0px 0px 0px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px -10px 60px ${castShadowDark}`,
  shadowSubtleAlt: `0px 4px 12px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 0px 0px ${castShadowDark}`,
  shadowSubtleAltReversed: `0px -4px 12px ${ambientShadowDark}, 0px 0px 0px ${haloShadowDark}, 0px 0px 0px ${castShadowDark}`,
  shadowFloat: `0px 2px 12px ${ambientShadowDark}, 0px 2px 12px ${haloShadowDark}, 0px 0px 0px ${castShadowDark}`,
  shadowFloatReversed: `0px -2px 12px ${ambientShadowDark}, 0px -2px 12px ${haloShadowDark}, 0px 0px 0px ${castShadowDark}`,
} as const;
