import { jsx as t } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h11l5 5v11c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5ZM16 9h3l-4-4v3c0 .283.096.52.288.712A.965.965 0 0 0 16 9Z" })
  }
);
export {
  c as default
};
