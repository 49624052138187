/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Atlassian = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.344 11.306a.511.511 0 0 0-.87.095l-4.418 8.834a.528.528 0 0 0 .47.765h6.152a.509.509 0 0 0 .473-.3c1.325-2.721.523-6.904-1.807-9.394Z"
      fill="url(#atlassian_svg__a)"
    />
    <path
      d="M11.585 3.283a11.651 11.651 0 0 0-.68 11.504l2.98 5.914a.529.529 0 0 0 .47.293h6.151a.546.546 0 0 0 .455-.759L12.483 3.283a.498.498 0 0 0-.898 0Z"
      fill="#2684FF"
    />
    <defs>
      <linearGradient
        id="atlassian_svg__a"
        x1={10.766}
        y1={12.669}
        x2={6.104}
        y2={20.747}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset={0.92} stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default Atlassian;
