/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const SalesforceContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.426 14.22c.047.472-.307.967-.756.967H7.61c-.449 0-.803-.495-.803-.968.024-.708.756-1.133 1.535-1.487.543-.236.614-.449.614-.685 0-.236-.142-.448-.33-.614a1.6 1.6 0 0 1-.496-1.18c0-.898.543-1.653 1.487-1.653.944 0 1.488.755 1.488 1.653 0 .472-.166.897-.496 1.18-.19.166-.33.378-.33.614s.07.449.613.661c.78.33 1.511.803 1.535 1.511ZM16.722 14.007c.26 0 .472-.213.472-.472v-.709a.474.474 0 0 0-.472-.472h-2.125a.474.474 0 0 0-.473.472v.709c0 .26.213.472.473.472h2.125ZM17.195 10.937c0 .26-.213.473-.473.473h-3.541a.474.474 0 0 1-.473-.473v-.708c0-.26.213-.472.473-.472h3.541c.26 0 .473.212.473.472v.708Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V6Zm15.667 1.16H6.333a1.42 1.42 0 0 0-1.416 1.416v6.848c0 .779.637 1.416 1.416 1.416h11.334a1.42 1.42 0 0 0 1.416-1.416V8.576a1.42 1.42 0 0 0-1.416-1.416Z"
    />
  </svg>
);

export default SalesforceContacts;
