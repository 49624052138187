import { jsx as t } from "react/jsx-runtime";
const e = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M11.7 15.3c-.317.317-.679.388-1.087.212-.409-.174-.613-.487-.613-.937v-5.15c0-.45.204-.762.613-.937.408-.175.77-.104 1.087.212l2.6 2.6c.1.1.175.209.225.325.05.117.075.242.075.375a.942.942 0 0 1-.075.375c-.05.117-.125.225-.225.325l-2.6 2.6Z" })
  }
);
export {
  e as default
};
