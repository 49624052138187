import { jsxs as t, jsx as s } from "react/jsx-runtime";
const a = (e) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ s(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M8.22 3h7.56C18.66 3 21 5.34 21 8.22v7.56A5.22 5.22 0 0 1 15.78 21H8.22C5.34 21 3 18.66 3 15.78V8.22A5.22 5.22 0 0 1 8.22 3Zm-.18 1.8A3.24 3.24 0 0 0 4.8 8.04v7.92a3.238 3.238 0 0 0 3.24 3.24h7.92a3.24 3.24 0 0 0 3.24-3.24V8.04a3.238 3.238 0 0 0-3.24-3.24H8.04Zm9.81 2.475a1.125 1.125 0 1 0-2.25 0 1.125 1.125 0 0 0 2.25 0ZM12 7.5a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9ZM9.3 12a2.7 2.7 0 1 1 5.4 0 2.7 2.7 0 0 1-5.4 0Z",
          fill: "url(#instagram_svg__a)"
        }
      ),
      /* @__PURE__ */ s("defs", { children: /* @__PURE__ */ t(
        "linearGradient",
        {
          id: "instagram_svg__a",
          x1: 4.648,
          y1: 19.352,
          x2: 19.352,
          y2: 4.648,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ s("stop", { stopColor: "#FFC107" }),
            /* @__PURE__ */ s("stop", { offset: 0.507, stopColor: "#F44336" }),
            /* @__PURE__ */ s("stop", { offset: 0.99, stopColor: "#9C27B0" })
          ]
        }
      ) })
    ]
  }
);
export {
  a as default
};
