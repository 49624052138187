/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleVideoAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm10 6.5h1.8L15.6 7H18v8.75c0 .69-.537 1.25-1.2 1.25H7.2c-.663 0-1.2-.56-1.2-1.25v-7.5C6 7.56 6.537 7 7.2 7h.6L9 9.5h1.8L9.6 7h1.2L12 9.5h1.8L12.6 7h1.2L15 9.5Z"
    />
  </svg>
);

export default GoogleVideoAlt;
