import { jsx as t } from "react/jsx-runtime";
const l = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "m6.893 11.842 5.474-4.717 5.423 4.712 2.542-2.924L12.379 2 4.363 8.908l2.53 2.934ZM7.075 15.01 4 17.366C6.324 20.398 9.148 22 12.377 22c3.24 0 6.038-1.612 8.289-4.663l-3.12-2.299c-1.56 2.116-3.246 3.087-5.168 3.087-1.933 0-3.669-.983-5.303-3.115Z" })
  }
);
export {
  l as default
};
