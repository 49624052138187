/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleSlides = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.5 1 20 6.5l-2.75.5-2.75-.5L14 4l.5-3Z" fill="#F29900" />
    <path
      d="M14.5 6.5V1h-9A1.5 1.5 0 0 0 4 2.5v19A1.5 1.5 0 0 0 5.5 23h13a1.5 1.5 0 0 0 1.5-1.5v-15h-5.5Z"
      fill="#FBBC04"
    />
    <path d="M7 9.5v6.75h10V9.5H7Zm8.75 5.5h-7.5v-4.25h7.5V15Z" fill="#fff" />
  </svg>
);

export default GoogleSlides;
