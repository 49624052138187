import * as React from "react";
const SvgIconPage = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8 5h5l5 5v7a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2Zm0 2v10h8v-6h-4V7H8Z"
    />
  </svg>
);
export default SvgIconPage;
