import { jsxs as l, jsx as o } from "react/jsx-runtime";
const s = (e) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ o(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M3.356 2.307C3.13 2.55 3 2.922 3 3.407v17.245c0 .486.13.857.364 1.09l.06.053 9.661-9.661v-.216L3.416 2.255l-.06.052Z",
          fill: "url(#google-play_svg__a)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m16.3 15.364-3.222-3.222v-.226L16.3 8.694l.07.042 3.812 2.167c1.09.614 1.09 1.63 0 2.252L16.37 15.32l-.07.043Z",
          fill: "url(#google-play_svg__b)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m16.37 15.321-3.292-3.292-9.722 9.722c.356.38.953.423 1.62.052l11.394-6.482Z",
          fill: "url(#google-play_svg__c)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M16.37 8.736 4.976 2.264c-.667-.38-1.266-.328-1.62.053l9.722 9.712 3.292-3.293Z",
          fill: "url(#google-play_svg__d)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          opacity: 0.2,
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m16.3 15.251-11.316 6.43c-.632.363-1.196.338-1.56.007l-.06.06.06.053c.364.329.928.356 1.56-.008l11.394-6.472-.078-.07Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          opacity: 0.12,
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m20.182 13.042-3.892 2.21.07.07 3.812-2.167c.546-.311.815-.72.815-1.126-.033.373-.311.727-.805 1.013Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          opacity: 0.25,
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "m4.976 2.377 15.206 8.639c.494.278.772.642.815 1.013 0-.407-.269-.815-.815-1.126L4.976 2.264C3.885 1.64 3 2.161 3 3.408v.113c0-1.249.885-1.76 1.976-1.144Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ l("defs", { children: [
        /* @__PURE__ */ l(
          "linearGradient",
          {
            id: "google-play_svg__a",
            x1: 12.219,
            y1: 3.222,
            x2: -3.257,
            y2: 7.345,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#00A0FF" }),
              /* @__PURE__ */ o("stop", { offset: 7e-3, stopColor: "#00A1FF" }),
              /* @__PURE__ */ o("stop", { offset: 0.26, stopColor: "#00BEFF" }),
              /* @__PURE__ */ o("stop", { offset: 0.512, stopColor: "#00D2FF" }),
              /* @__PURE__ */ o("stop", { offset: 0.76, stopColor: "#00DFFF" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#00E3FF" })
            ]
          }
        ),
        /* @__PURE__ */ l(
          "linearGradient",
          {
            id: "google-play_svg__b",
            x1: 21.605,
            y1: 12.029,
            x2: 2.735,
            y2: 12.029,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#FFE000" }),
              /* @__PURE__ */ o("stop", { offset: 0.409, stopColor: "#FFBD00" }),
              /* @__PURE__ */ o("stop", { offset: 0.775, stopColor: "orange" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#FF9C00" })
            ]
          }
        ),
        /* @__PURE__ */ l(
          "linearGradient",
          {
            id: "google-play_svg__c",
            x1: 14.58,
            y1: 13.82,
            x2: 2.156,
            y2: 34.731,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#FF3A44" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#C31162" })
            ]
          }
        ),
        /* @__PURE__ */ l(
          "linearGradient",
          {
            id: "google-play_svg__d",
            x1: 0.908,
            y1: -3.428,
            x2: 6.451,
            y2: 5.911,
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ o("stop", { stopColor: "#32A071" }),
              /* @__PURE__ */ o("stop", { offset: 0.069, stopColor: "#2DA771" }),
              /* @__PURE__ */ o("stop", { offset: 0.476, stopColor: "#15CF74" }),
              /* @__PURE__ */ o("stop", { offset: 0.801, stopColor: "#06E775" }),
              /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#00F076" })
            ]
          }
        )
      ] })
    ]
  }
);
export {
  s as default
};
