import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 32 32",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M26.668 8H16.001l-1.88-1.88a2.675 2.675 0 0 0-1.893-.787H5.335A2.646 2.646 0 0 0 2.68 8l-.013 16a2.657 2.657 0 0 0 2.667 2.667h21.333c1.467 0 2.667-1.2 2.667-2.667V10.667C29.335 9.2 28.135 8 26.668 8Z" }),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M23.101 13.57a5.307 5.307 0 0 0-4.325-1.541c-2.45.247-4.466 2.236-4.74 4.686a5.33 5.33 0 0 0 10.107 2.924.669.669 0 0 0-.601-.962.65.65 0 0 0-.588.354 4.001 4.001 0 0 1-4.539 2.21 3.97 3.97 0 0 1-2.99-3.017 4.007 4.007 0 0 1 3.904-4.887c1.109 0 2.097.46 2.818 1.188l-1.008 1.008c-.42.42-.127 1.142.467 1.142h2.396a.67.67 0 0 0 .668-.668v-2.396a.67.67 0 0 0-1.142-.474l-.427.434Z",
      fill: "#fff"
    }
  )
);
export {
  a as default
};
