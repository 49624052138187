import { jsx as t } from "react/jsx-runtime";
const a = (o) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ t("path", { d: "M6.4 20.4a.99.99 0 0 1-.95-.088c-.3-.191-.45-.47-.45-.837V5c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 7 3h10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14.475c0 .367-.15.646-.45.837a.99.99 0 0 1-.95.088L12 18l-5.6 2.4Z" })
  }
);
export {
  a as default
};
