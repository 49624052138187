/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Mastercard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.975 6.454h-5.95v10.69h5.95V6.454Z" fill="#FF5F00" />
    <path
      d="M9.403 11.799A6.787 6.787 0 0 1 12 6.454a6.8 6.8 0 1 0 0 10.69 6.787 6.787 0 0 1-2.597-5.345Z"
      fill="#EB001B"
    />
    <path
      d="M23 11.799a6.8 6.8 0 0 1-11 5.345 6.8 6.8 0 0 0 0-10.69 6.799 6.799 0 0 1 11 5.345Z"
      fill="#F79E1B"
    />
  </svg>
);

export default Mastercard;
