import { jsxs as e, jsx as l } from "react/jsx-runtime";
const d = (c) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M6.188 3h11.625C19.575 3 21 4.462 21 6.27v11.46c0 1.808-1.425 3.27-3.188 3.27H6.188C4.425 21 3 19.538 3 17.73V6.27C3 4.461 4.425 3 6.188 3Z",
          fill: "#00005B"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M6.685 8.05v7.915c0 .07.03.1.092.1h1.492a.096.096 0 0 0 .085-.108v-2.47c.146 0 .254 0 .33.009.077.007.2.007.385.007.63 0 1.208-.069 1.731-.261.5-.185.93-.508 1.238-.939.308-.43.462-.977.462-1.638 0-.346-.062-.677-.177-1a2.449 2.449 0 0 0-.562-.877 2.747 2.747 0 0 0-1.03-.63c-.431-.162-.954-.24-1.585-.24-.23 0-.461 0-.7.008-.115.004-.227.006-.335.008-.115.002-.227.004-.334.008-.1.004-.198.005-.292.007-.1.002-.198.004-.293.008-.177.008-.3.008-.43.008-.054 0-.077.03-.077.084Zm1.676 3.992V9.457c.054-.008.162-.015.316-.008.146-.007.323-.007.523-.007.315-.008.623.038.908.154.223.084.407.238.538.438.123.208.185.446.177.685 0 .338-.077.6-.238.792-.17.192-.385.339-.631.408a2.874 2.874 0 0 1-.885.138h-.408c-.085 0-.177-.007-.276-.014l-.024-.001Z",
          fill: "#99F"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M14.954 9.911h-1.346c-.008-.008-.023-.008-.023 0a.07.07 0 0 0-.054.085l.002.02c.014.17.029.333.029.503l.007.3c.002.1.004.2.008.3.008.2.008.392.008.577v4.292c.007.061.061.1.123.092h1.515c.07 0 .1-.03.1-.107v-3.924c.108-.123.246-.215.392-.284.208-.092.423-.162.646-.208.27-.061.554-.084.831-.077.085 0 .123-.023.123-.084V9.865c-.007-.054-.053-.092-.107-.085-.408 0-.816.1-1.17.3-.315.17-.592.393-.823.662a2.566 2.566 0 0 0-.054-.515.781.781 0 0 0-.046-.193.173.173 0 0 0-.161-.123Z",
          fill: "#99F"
        }
      )
    ]
  }
);
export {
  d as default
};
