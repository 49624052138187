import * as React from "react";
const SvgIconImageAlignRight = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 11V9.21a.5.5 0 0 1 .85-.36l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.31.32-.85.1-.85-.35V13H4c-.55 0-1-.45-1-1s.45-1 1-1h9Zm8 8c0 .55-.45 1-1 1s-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v14Z"
    />
  </svg>
);
export default SvgIconImageAlignRight;
