import * as h from "react";
const v = (e) => /* @__PURE__ */ h.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...e
  },
  /* @__PURE__ */ h.createElement("path", { d: "M22 14h-2v-2h2v2Zm0 4h-2v-2h2v2Zm-2 4v-2h2a2 2 0 0 1-2 2ZM8 20h2v2a2 2 0 0 1-2-2Zm14-10h-2V8a2 2 0 0 1 2 2ZM6 4h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2Zm8 18h-2v-2h2v2Zm4 0h-2v-2h2v2Z" })
);
export {
  v as default
};
