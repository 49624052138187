import { jsx as h } from "react/jsx-runtime";
const Z = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M7 5V3h2v2H7Zm0 8v-2h2v2H7Zm4 0v-2h2v2h-2Zm0-8V3h2v2h-2ZM3 9V7h2v2H3Zm0-4V3h2v2H3Zm0 8v-2h2v2H3Zm0 4v-2h2v2H3Zm8-8V7h2v2h-2Zm8 8v-2h2v2h-2Zm0-4v-2h2v2h-2ZM4 21a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 20c0-.283.096-.52.288-.712A.965.965 0 0 1 4 19h16c.283 0 .52.096.712.288A.965.965 0 0 1 21 20c0 .283-.096.52-.288.712A.965.965 0 0 1 20 21H4ZM19 9V7h2v2h-2Zm-4-4V3h2v2h-2Zm-4 12v-2h2v2h-2Zm8-12V3h2v2h-2Zm-4 8v-2h2v2h-2Z" })
  }
);
export {
  Z as default
};
