import * as React from "react";
const SvgIconFileAdobeIllustrator = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 130.746 595.279 580.398"
    width="1em"
    height="1em"
    {...props}
  >
    <radialGradient
      id="icon-file-adobe-illustrator_svg__a"
      cx={-183.69}
      cy={328.972}
      r={0.76}
      gradientTransform="matrix(545.6736 0 0 528.3113 100439.305 -173525.125)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#423325" stopOpacity={0.98} />
      <stop offset={1} stopColor="#1c0a00" />
    </radialGradient>
    <path
      d="M24.803 155.549h545.674v530.792H24.803V155.549z"
      fill="url(#icon-file-adobe-illustrator_svg__a)"
    />
    <path
      d="M24.803 155.549h545.674v530.792H24.803V155.549zM0 711.145h595.28V130.746H0v580.399zm389.908-373.539c0-1.984.744-2.977 2.977-2.977h38.941c1.983 0 2.976.744 2.976 2.977v195.699c0 1.983-.496 2.976-2.976 2.976h-38.445c-2.48 0-3.225-1.24-3.225-3.224V337.606h-.248zm-2.728-56.304c0-15.874 11.161-25.299 25.3-25.299 15.13 0 25.299 10.169 25.299 25.299 0 16.37-10.665 25.299-25.795 25.299-14.387.001-24.804-8.929-24.804-25.299zM275.565 419.209c-6.944-27.532-23.314-87.556-29.516-116.576h-.496c-5.209 29.02-18.354 78.13-28.771 116.576h58.783zm-68.953 40.182-19.595 74.41c-.496 1.983-1.24 2.479-3.72 2.479h-36.461c-2.48 0-2.977-.744-2.48-3.72l70.441-246.546c1.24-4.464 1.984-8.433 2.48-20.586 0-1.736.744-2.48 1.984-2.48h52.087c1.736 0 2.48.496 2.977 2.48l78.874 267.628c.496 1.983 0 3.224-1.984 3.224h-41.174c-1.984 0-3.225-.496-3.72-2.231l-20.339-74.658h-79.37z"
      fill="#ff7f18"
    />
  </svg>
);
export default SvgIconFileAdobeIllustrator;
