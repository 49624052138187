import { jsx as A } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M7 14h10c.283 0 .52-.096.712-.288A.965.965 0 0 0 18 13a.968.968 0 0 0-.288-.713A.967.967 0 0 0 17 12H7a.97.97 0 0 0-.713.287A.97.97 0 0 0 6 13c0 .283.096.52.287.712.192.192.43.288.713.288Zm0-3h10c.283 0 .52-.096.712-.288A.965.965 0 0 0 18 10a.968.968 0 0 0-.288-.713A.967.967 0 0 0 17 9H7a.97.97 0 0 0-.713.287A.97.97 0 0 0 6 10c0 .283.096.52.287.712.192.192.43.288.713.288Zm0-3h10c.283 0 .52-.096.712-.287A.968.968 0 0 0 18 7a.968.968 0 0 0-.288-.713A.967.967 0 0 0 17 6H7a.97.97 0 0 0-.713.287A.97.97 0 0 0 6 7a.97.97 0 0 0 .287.713A.97.97 0 0 0 7 8Zm13.3 12.3L18 18H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 16V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 4 2h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v15.575c0 .45-.204.762-.612.937-.409.175-.771.105-1.088-.212ZM4 4v12h14.825L20 17.175V4H4Zm0 0v13.175V4Z" })
  }
);
export {
  h as default
};
