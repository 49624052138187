import { jsx as c } from "react/jsx-runtime";
const a = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "m12.7 11.2-1.45-1.425a1.003 1.003 0 0 0-.7-.275c-.267 0-.5.1-.7.3a.948.948 0 0 0-.275.7c0 .283.092.517.275.7L12 13.35a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l4.25-4.25c.2-.2.3-.438.3-.713 0-.275-.1-.512-.3-.712-.2-.2-.437-.3-.712-.3-.275 0-.513.1-.713.3L12.7 11.2ZM8 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 6 16V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 8 2h12c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 18H8Zm-4 4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 20V7c0-.283.096-.521.288-.713A.967.967 0 0 1 3 6a.97.97 0 0 1 .713.287A.97.97 0 0 1 4 7v13h13c.283 0 .52.096.712.288A.965.965 0 0 1 18 21c0 .283-.096.52-.288.712A.965.965 0 0 1 17 22H4Z" })
  }
);
export {
  a as default
};
