import { jsx as c } from "react/jsx-runtime";
const h = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M6.5 20c-1.517 0-2.812-.525-3.887-1.575C1.538 17.375 1 16.092 1 14.575c0-1.3.392-2.458 1.175-3.475S3.983 9.433 5.25 9.15c.417-1.533 1.25-2.775 2.5-3.725C9 4.475 10.417 4 12 4c1.95 0 3.604.679 4.962 2.037C18.321 7.396 19 9.05 19 11c1.15.133 2.104.629 2.863 1.487A4.41 4.41 0 0 1 23 15.5c0 1.25-.437 2.313-1.312 3.188S19.75 20 18.5 20h-12Zm3.85-3.425a1.1 1.1 0 0 0 .375-.063.871.871 0 0 0 .325-.212l4.225-4.225c.2-.2.3-.442.3-.725s-.1-.525-.3-.725c-.2-.2-.442-.3-.725-.3s-.525.1-.725.3l-3.5 3.5-1.4-1.4a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275c-.2.2-.3.442-.3.725s.1.517.3.7L9.65 16.3c.1.1.208.17.325.212a1.1 1.1 0 0 0 .375.063Z" })
  }
);
export {
  h as default
};
