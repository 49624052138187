import * as React from "react";
const SvgIconFileVideoFile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 28"
    width="1em"
    height="1em"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <rect width={32} height={28} fill="#EB3584" rx={2} />
      <path fill="#FFF" d="m23 14-12 8V6z" />
    </g>
  </svg>
);
export default SvgIconFileVideoFile;
