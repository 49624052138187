/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftPublisher = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16.232 3.94h5.86c.501 0 .908.406.908.907V19.15a.909.909 0 0 1-.908.908h-5.86a.909.909 0 0 1-.908-.908V4.847a.91.91 0 0 1 .908-.908Z" />
    <path d="M19.037 21.861H7.124a.949.949 0 0 1-.958-.939v-3.991l7.542-1.643 6.286 1.643v3.991c0 .519-.43.94-.957.94Z" />
    <path
      d="M19.006 2.138H7.155a.979.979 0 0 0-.987.972V12l7.123.822L19.995 12V3.11a.981.981 0 0 0-.989-.972Z"
      fill="#95A0B1"
    />
    <path d="M19.995 12H6.168v4.93h13.827V12Z" fill="#68778D" />
    <path
      opacity={0.2}
      d="M12.594 7.378v10.58a.735.735 0 0 1-.015.16.931.931 0 0 1-.92.777H6.167V6.44h5.49a.933.933 0 0 1 .936.932v.005Z"
      fill="#000"
    />
    <path
      opacity={0.1}
      d="M12.996 7.378v9.776c-.007.737-.601 1.33-1.338 1.337h-5.49V6.04h5.49c.737.004 1.334.601 1.338 1.34Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M12.594 7.378v9.776a.933.933 0 0 1-.932.936H6.168V6.44h5.49a.933.933 0 0 1 .936.933v.005Z"
      fill="#000"
    />
    <path
      opacity={0.1}
      d="M12.192 7.378v9.776a.933.933 0 0 1-.932.936H6.167V6.44h5.09a.933.933 0 0 1 .935.933v.005Z"
      fill="#000"
    />
    <path
      d="M1.937 6.39h9.373c.518 0 .937.418.937.937v9.372a.936.936 0 0 1-.937.937H1.937A.936.936 0 0 1 1 16.7V7.327c0-.517.419-.938.937-.938Z"
      fill="#596578"
    />
    <path
      d="M6.712 8.953c.59-.04 1.174.135 1.645.494.396.366.606.891.57 1.43.007.376-.091.745-.282 1.07-.19.316-.47.568-.801.728-.376.18-.789.27-1.206.259h-1.14v2.177H4.33V8.952h2.383Zm-1.215 3.04h1.007c.321.024.64-.074.89-.273.21-.21.32-.5.3-.796 0-.679-.384-1.019-1.154-1.019H5.497v2.088Z"
      fill="#fff"
    />
    <path opacity={0.2} d="M20.404 3.94h-.408v16.116h.408V3.938Z" fill="#000" />
    <path opacity={0.1} d="M20.813 3.94h-.409v16.116h.409V3.938Z" fill="#000" />
  </svg>
);

export default MicrosoftPublisher;
