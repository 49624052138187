import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "m19.95 17.15-1.5-1.5c.183-.45.321-.896.413-1.338.091-.441.137-.879.137-1.312 0-1.933-.683-3.583-2.05-4.95C15.583 6.683 13.933 6 12 6c-.4 0-.825.042-1.275.125-.45.083-.917.217-1.4.4l-1.5-1.5A8.993 8.993 0 0 1 12 4c1 0 1.98.167 2.938.5a10.05 10.05 0 0 1 2.712 1.45l.725-.725a.918.918 0 0 1 .675-.275c.267 0 .5.1.7.3a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-.7.7a10.05 10.05 0 0 1 1.45 2.712c.333.959.5 1.938.5 2.938 0 .7-.087 1.396-.262 2.087a9.803 9.803 0 0 1-.788 2.063ZM13 10.2V9a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 8a.878.878 0 0 0-.475.125 1.347 1.347 0 0 0-.325.275l1.8 1.8ZM12 22a8.654 8.654 0 0 1-3.488-.712A9.2 9.2 0 0 1 5.65 19.35a9.202 9.202 0 0 1-1.938-2.862A8.655 8.655 0 0 1 3 13c0-1 .154-1.962.463-2.887A8.36 8.36 0 0 1 4.8 7.6L2.075 4.875A.918.918 0 0 1 1.8 4.2c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l17.025 17.025a.918.918 0 0 1 .275.675c0 .267-.1.5-.3.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-1.7-1.7a9.441 9.441 0 0 1-2.588 1.338A8.845 8.845 0 0 1 12 22Zm0-2c.7 0 1.383-.108 2.05-.325a7.277 7.277 0 0 0 1.875-.925L6.2 9.025c-.4.583-.7 1.208-.9 1.875A7.27 7.27 0 0 0 5 13c0 1.933.683 3.583 2.05 4.95C8.417 19.317 10.067 20 12 20ZM10 3a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 2c0-.283.096-.521.288-.713A.967.967 0 0 1 10 1h4a.97.97 0 0 1 .713.287A.97.97 0 0 1 15 2c0 .283-.096.52-.287.712A.968.968 0 0 1 14 3h-4Z" })
  }
);
export {
  l as default
};
