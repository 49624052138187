/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Miro = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.35 3h15.3c.746 0 1.35.604 1.35 1.35v15.3A1.35 1.35 0 0 1 19.65 21H4.35A1.35 1.35 0 0 1 3 19.65V4.35C3 3.604 3.604 3 4.35 3Zm8.912 2.251h1.976l3.622 2.25-3.622 11.25h-1.976L14.91 8.465 11.287 18.75H9.311l1.812-9.643-3.787 9.643H5.36l1.976-9-1.976-4.5h1.976l3.787 3.536L9.31 5.251h1.976l3.622 2.893-1.647-2.893Z"
    />
  </svg>
);

export default Miro;
