/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const WebexMeetings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.065 13.148C2.022 12.771 2 12.388 2 12 2 6.477 6.477 2 12 2c4.908 0 8.99 3.536 9.838 8.2.107.584.162 1.185.162 1.8 0 5.523-4.477 10-10 10-5.135 0-9.365-3.87-9.935-8.852ZM5.636 12A6.364 6.364 0 0 1 16.19 7.21a7.91 7.91 0 0 1-8.98 8.98A6.34 6.34 0 0 1 5.637 12Z"
      fill="#CAD0D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.636a6.364 6.364 0 1 0 0 12.728 6.364 6.364 0 0 0 0-12.728ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
      fill="#596578"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.182 10.182c1.004 0 1.818.814 1.818 1.818 0 5.523-4.477 10-10 10a9.976 9.976 0 0 1-7.222-3.083A1.818 1.818 0 0 1 7.404 16.4 6.364 6.364 0 0 0 18.364 12c0-1.004.814-1.818 1.818-1.818Z"
      fill="#2B313B"
    />
  </svg>
);

export default WebexMeetings;
