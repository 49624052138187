import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M7.7 16.3a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l2.9-2.9 2.925 2.925c.183.183.413.27.688.262a.975.975 0 0 0 .687-.287.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7L13.4 12l2.925-2.925a.891.891 0 0 0 .262-.688.975.975 0 0 0-.287-.687.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275L12 10.6 9.075 7.675a.893.893 0 0 0-.687-.263.98.98 0 0 0-.688.288.948.948 0 0 0-.275.7c0 .283.092.517.275.7l2.9 2.9-2.925 2.925a.89.89 0 0 0-.262.687.976.976 0 0 0 .287.688ZM12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22Zm0-2c2.217 0 4.104-.779 5.663-2.337C19.221 16.104 20 14.217 20 12s-.779-4.104-2.337-5.663C16.104 4.779 14.217 4 12 4s-4.104.779-5.662 2.337C4.779 7.896 4 9.783 4 12s.78 4.104 2.338 5.663C7.896 19.221 9.783 20 12 20Z" })
  }
);
export {
  t as default
};
