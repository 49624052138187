/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.603 8.297 12.9 6h8.7a1.2 1.2 0 0 1 1.2 1.2v12a1.2 1.2 0 0 1-1.2 1.2H2.4a1.2 1.2 0 0 1-1.2-1.2V9h7.706a2.4 2.4 0 0 0 1.697-.703Z" />
    <path d="M1.2 4.8v3.6h7.406a2.4 2.4 0 0 0 1.697-.703L12 6l-1.697-1.697A2.4 2.4 0 0 0 8.606 3.6H2.4a1.2 1.2 0 0 0-1.2 1.2Z" />
  </svg>
);

export default MicrosoftFolder;
