import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "m7.3 16.3-4.6-4.6a.877.877 0 0 1-.213-.325A1.107 1.107 0 0 1 2.425 11c0-.133.02-.258.062-.375A.877.877 0 0 1 2.7 10.3l4.6-4.6a.977.977 0 0 1 .687-.287.93.93 0 0 1 .713.287c.2.183.304.413.312.688a.93.93 0 0 1-.287.712l-3.9 3.9 3.9 3.9c.183.183.28.417.287.7a.87.87 0 0 1-.287.7c-.183.2-.417.3-.7.3a.988.988 0 0 1-.725-.3ZM21 19a.968.968 0 0 1-.712-.287A.968.968 0 0 1 20 18v-3c0-.833-.292-1.542-.875-2.125A2.893 2.893 0 0 0 17 12h-6.175l2.9 2.9c.183.183.28.417.287.7a.87.87 0 0 1-.287.7c-.183.2-.417.3-.7.3a.988.988 0 0 1-.725-.3l-4.6-4.6a.877.877 0 0 1-.213-.325A1.107 1.107 0 0 1 7.425 11c0-.133.02-.258.062-.375A.877.877 0 0 1 7.7 10.3l4.6-4.6a.977.977 0 0 1 .688-.287.93.93 0 0 1 .712.287c.2.183.304.413.313.688a.93.93 0 0 1-.288.712l-2.9 2.9H17c1.383 0 2.563.488 3.538 1.463C21.512 12.438 22 13.617 22 15v3c0 .283-.096.52-.288.713A.968.968 0 0 1 21 19Z" })
  }
);
export {
  t as default
};
