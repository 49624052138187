/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const GoogleAudio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm2 8.21C7 8.334 9.258 6 12.042 6s5.041 2.333 5.041 5.21v4.053c0 .961-.75 1.737-1.68 1.737h-1.68v-4.632h2.24V11.21c0-2.24-1.753-4.052-3.921-4.052S8.12 8.97 8.12 11.21v1.158h2.241V17h-1.68C7.75 17 7 16.224 7 15.263V11.21Z"
    />
  </svg>
);

export default GoogleAudio;
