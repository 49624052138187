import { jsx as t } from "react/jsx-runtime";
const c = (e) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ t("circle", { cx: 12, cy: 12, r: 4 })
  }
);
export {
  c as default
};
