import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm-.025-3a.84.84 0 0 0 .613-.262.84.84 0 0 0 .262-.613v-.375c.833-.15 1.55-.475 2.15-.975.6-.5.9-1.242.9-2.225 0-.7-.2-1.342-.6-1.925-.4-.583-1.2-1.092-2.4-1.525-1-.333-1.692-.625-2.075-.875-.383-.25-.575-.592-.575-1.025 0-.433.154-.775.463-1.025.308-.25.754-.375 1.337-.375.333 0 .625.058.875.175.25.117.458.275.625.475.167.2.354.338.563.413.208.075.404.07.587-.013.25-.1.42-.27.513-.512a.69.69 0 0 0-.063-.663 3.79 3.79 0 0 0-.988-.975 2.546 2.546 0 0 0-1.262-.45v-.375a.84.84 0 0 0-.263-.612.84.84 0 0 0-.612-.263.84.84 0 0 0-.613.263.84.84 0 0 0-.262.612v.375c-.833.183-1.483.55-1.95 1.1-.467.55-.7 1.167-.7 1.85 0 .783.23 1.417.688 1.9.458.483 1.179.9 2.162 1.25 1.05.383 1.78.725 2.188 1.025.408.3.612.692.612 1.175 0 .55-.196.954-.588 1.212-.391.259-.862.388-1.412.388-.433 0-.825-.104-1.175-.312-.35-.209-.642-.521-.875-.938a1.03 1.03 0 0 0-.525-.475.877.877 0 0 0-.65 0 .871.871 0 0 0-.513.475.77.77 0 0 0-.012.675c.267.567.625 1.03 1.075 1.387.45.359.992.605 1.625.738v.425a.84.84 0 0 0 .263.613.84.84 0 0 0 .612.262Z" })
  }
);
export {
  t as default
};
