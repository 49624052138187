import * as React from "react";
const SvgIconInsertCaption = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m6.06 17.58 4.57-11.65C10.85 5.37 11.4 5 12 5c.6 0 1.14.37 1.37.93l4.57 11.65c.27.68-.24 1.42-.97 1.42-.44 0-.83-.27-.98-.68L15.12 16H8.87l-.86 2.32c-.16.41-.55.68-.98.68-.74 0-1.24-.74-.97-1.42ZM12 7.67 9.62 14h4.76L12 7.67Z"
    />
  </svg>
);
export default SvgIconInsertCaption;
