import { jsx as t } from "react/jsx-runtime";
const e = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M8 12.14V3a1 1 0 1 0-2 0v9.14c-1.72.45-3 2-3 3.86 0 1.86 1.28 3.41 3 3.86V21a1 1 0 1 0 2 0v-1.14c1.72-.45 3-2 3-3.86 0-1.86-1.28-3.41-3-3.86ZM18 3a1 1 0 1 0-2 0v1.14c-1.72.45-3 2-3 3.86 0 1.86 1.28 3.41 3 3.86V21a1 1 0 1 0 2 0v-9.14c1.72-.45 3-2 3-3.86 0-1.86-1.28-3.41-3-3.86V3Z" })
  }
);
export {
  e as default
};
