import { jsx as a } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M9 3.9a.9.9 0 0 0-.9-.9H6.6A3.6 3.6 0 0 0 3 6.6v1.5a.9.9 0 1 0 1.8 0V6.6a1.8 1.8 0 0 1 1.8-1.8h1.5a.9.9 0 0 0 .9-.9ZM15.9 4.8a.9.9 0 1 1 0-1.8h1.5A3.6 3.6 0 0 1 21 6.6v1.5a.9.9 0 1 1-1.8 0V6.6a1.8 1.8 0 0 0-1.8-1.8h-1.5ZM15 20.1a.9.9 0 0 1 .9-.9h1.5a1.8 1.8 0 0 0 1.8-1.8v-1.5a.9.9 0 1 1 1.8 0v1.5a3.6 3.6 0 0 1-3.6 3.6h-1.5a.9.9 0 0 1-.9-.9ZM3.9 15a.9.9 0 0 1 .9.9v1.5a1.8 1.8 0 0 0 1.8 1.8h1.5a.9.9 0 1 1 0 1.8H6.6A3.6 3.6 0 0 1 3 17.4v-1.5a.9.9 0 0 1 .9-.9Z" })
  }
);
export {
  v as default
};
