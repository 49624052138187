import * as React from "react";
const SvgIconLogoZoomColor = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <circle cx={12} cy={12} r={11} fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm6.597-6.798c-.138.206-.38.342-.658.274l.035-.034c-.229 0-1.036-.664-1.613-1.14-.21-.172-.389-.32-.5-.401-.138-.137-.242-.377-.277-.651-.027-.137-.027-.24-.027-.514v-1.301c0-.24 0-.411.027-.514.035-.582 1.628-1.986 2.355-2.466.277-.102.555 0 .658.274.07.137.07.274.07.651v5.171c0 .377 0 .548-.07.651ZM5.437 8.73c-.104.137-.104.411-.104.925v2.329c0 1.301 0 1.952.277 2.466.212.447.554.687.89.922l.15.105c.519.274 1.177.274 2.493.274h4.467c.555 0 .797 0 .936-.103.032-.032.074-.067.12-.105.1-.083.213-.177.26-.271.104-.137.104-.411.104-.925v-2.329c0-1.301 0-1.952-.277-2.466-.21-.447-.553-.687-.89-.922l-.149-.105c-.52-.274-1.177-.274-2.493-.274H6.753c-.554 0-.796 0-.935.103-.032.032-.075.067-.12.105-.1.083-.213.177-.26.271Z"
      fill="#2D8CFF"
    />
  </svg>
);
export default SvgIconLogoZoomColor;
