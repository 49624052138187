import { jsxs as d, jsx as e } from "react/jsx-runtime";
const i = (l) => /* @__PURE__ */ d(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M12 6a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm2.874 4.166-3 4.5c-.007.01-.015.016-.024.022a.103.103 0 0 0-.021.018.263.263 0 0 0-.019.03.171.171 0 0 1-.03.044.34.34 0 0 1-.082.057c-.014.009-.029.017-.043.027l-.03.021a.318.318 0 0 1-.059.037.758.758 0 0 1-.316.078h-.002V15a.743.743 0 0 1-.364-.11.079.079 0 0 0-.025-.007c-.009-.002-.017-.003-.025-.008-.012-.008-.021-.019-.03-.03l-.022-.024-.027-.016c-.013-.007-.025-.014-.036-.024l-1.5-1.5a.75.75 0 0 1 1.06-1.06l.854.853 2.493-3.74a.751.751 0 0 1 1.248.833Z"
        }
      ),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M4.857 2h14.286A2.857 2.857 0 0 1 22 4.857v14.286A2.857 2.857 0 0 1 19.143 22H4.857A2.857 2.857 0 0 1 2 19.143V4.857A2.857 2.857 0 0 1 4.857 2ZM4.5 12a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Z"
        }
      )
    ]
  }
);
export {
  i as default
};
