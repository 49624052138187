import { jsx as c } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M21.475 22.095c.35-.35.35-.92 0-1.27L3.025 2.365A.901.901 0 1 0 1.75 3.64l2.31 2.31A9.95 9.95 0 0 0 2 12c0 5.5 4.5 10 10 10 2.28 0 4.37-.77 6.05-2.06l2.155 2.155c.35.35.92.35 1.27 0ZM12 20c-4.42 0-8-3.58-8-8 0-1.73.56-3.32 1.5-4.62L16.62 18.5A7.78 7.78 0 0 1 12 20ZM8.17 4.97 6.72 3.5C8.25 2.56 10.06 2 12 2c5.5 0 10 4.5 10 10 0 1.94-.56 3.75-1.5 5.28l-1.47-1.45c.62-1.14.97-2.44.97-3.83 0-4.42-3.58-8-8-8-1.39 0-2.69.35-3.83.97Z" })
  }
);
export {
  e as default
};
