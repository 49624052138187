import { jsxs as e, jsx as h } from "react/jsx-runtime";
const v = (t) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ h("path", { d: "M11.817 11.924h-1.673v-3.38h1.735c1.277 0 1.916.55 1.916 1.649.032.482-.15.955-.5 1.29-.422.323-.948.48-1.478.44Z" }),
      /* @__PURE__ */ h(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3Zm-4.605 4.8a4.123 4.123 0 0 0-2.731-.8H8.203v9.974h1.943v-3.527h1.897a4.52 4.52 0 0 0 2.002-.42 3.133 3.133 0 0 0 1.332-1.177c.317-.521.48-1.122.468-1.732a2.864 2.864 0 0 0-.95-2.318Z"
        }
      )
    ]
  }
);
export {
  v as default
};
