import React, { Fragment } from "react";
import styled from "styled-components";
import { active, toBgLight, lighten40 } from "@happeouikit/colors";
import { radius400 } from "@happeouikit/theme";

const FocusStyle = {
  border: `solid 1px ${lighten40(active)}`,
  borderRadius: radius400,
};

const TypeaheadList = React.forwardRef(
  ({ results, focusedResultIndex, onResultClick }, ref) => (
    <Container>
      {results.map((result, index) => (
        <Fragment key={result.text || index}>
          <Row
            onClick={() => onResultClick(result)}
            style={index === focusedResultIndex ? FocusStyle : {}}
            ref={index === focusedResultIndex ? ref : null}
          >
            {result.component}
          </Row>
        </Fragment>
      ))}
    </Container>
  )
);

const Container = styled.ul`
  padding: 0px;
  margin: 0px;
`;

const Row = styled.li`
  padding: 9px 15px 9px 15px;
  border: solid 1px transparent;
  list-style-type: none;
  cursor: pointer;
  &:hover {
    background: ${toBgLight(active)};
  }
`;

export default TypeaheadList;
