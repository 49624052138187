import { jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M20 5h-1a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 4c0-.283.096-.521.288-.713A.967.967 0 0 1 19 3h1V2c0-.283.096-.521.288-.713A.967.967 0 0 1 21 1c.283 0 .52.096.712.287.192.192.288.43.288.713v1h1c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 23 5h-1v1a.968.968 0 0 1-.288.713A.967.967 0 0 1 21 7a.967.967 0 0 1-.712-.287A.968.968 0 0 1 20 6V5Zm-8 17a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c.75 0 1.48.079 2.188.237a9.574 9.574 0 0 1 2.012.688 2.715 2.715 0 0 0-.15.525c-.033.183-.05.367-.05.55 0 .667.196 1.267.587 1.8a2.84 2.84 0 0 0 1.538 1.075A2.836 2.836 0 0 0 19.2 8.412a2.964 2.964 0 0 0 2.325.538c.15.483.267.98.35 1.488.083.508.125 1.029.125 1.562a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22Zm3.5-11c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C16.292 8.142 15.933 8 15.5 8c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm-7 0c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C9.292 8.142 8.933 8 8.5 8c-.433 0-.792.142-1.075.425C7.142 8.708 7 9.067 7 9.5c0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm3.5 6.5c1.183 0 2.229-.325 3.137-.975A5.473 5.473 0 0 0 17.1 14H6.9a5.473 5.473 0 0 0 1.963 2.525c.908.65 1.954.975 3.137.975Z" })
  }
);
export {
  h as default
};
