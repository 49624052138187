import * as React from "react";
const SvgIconWidgetImage = (props) => (
  <svg
    viewBox="0 0 93 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <g filter="url(#icon-widget-image_svg__a)">
      <g clipPath="url(#icon-widget-image_svg__b)">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
        <rect
          x={-9.025}
          y={-1.447}
          width={110.469}
          height={66.366}
          rx={1.422}
          fill="#fff"
        />
        <rect
          x={6.555}
          y={5.231}
          width={79.639}
          height={42.664}
          rx={1.422}
          fill="#0075DB"
        />
        <rect
          x={6.555}
          y={5.231}
          width={79.639}
          height={42.664}
          rx={1.422}
          fill="#fff"
          fillOpacity={0.9}
        />
        <g filter="url(#icon-widget-image_svg__c)">
          <path
            d="M38.748 20.074 19.286 39.977c-.619.633-.17 1.7.715 1.7H74.22a1 1 0 0 0 .822-1.57L57.78 15.125a1 1 0 0 0-1.501-.167L45 25.368a1 1 0 0 1-1.52-.194l-3.176-4.942a1 1 0 0 0-1.556-.158Z"
            fill="#fff"
          />
        </g>
        <g filter="url(#icon-widget-image_svg__d)">
          <circle cx={25.125} cy={16.176} r={5.5} fill="#fff" />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="icon-widget-image_svg__a"
        x={0.593}
        y={0.195}
        width={91.563}
        height={54.588}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44081"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.602} />
        <feGaussianBlur stdDeviation={1.203} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44081"
          result="effect2_dropShadow_213_44081"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44081"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-image_svg__c"
        x={16.155}
        y={12.559}
        width={61.909}
        height={32.673}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44081"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44081"
          result="effect2_dropShadow_213_44081"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44081"
          result="shape"
        />
      </filter>
      <filter
        id="icon-widget-image_svg__d"
        x={16.781}
        y={8.543}
        width={16.689}
        height={16.689}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.815686 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_213_44081"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.711} />
        <feGaussianBlur stdDeviation={1.422} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.615686 0 0 0 0 1 0 0 0 0.05 0" />
        <feBlend
          in2="effect1_dropShadow_213_44081"
          result="effect2_dropShadow_213_44081"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_213_44081"
          result="shape"
        />
      </filter>
      <clipPath id="icon-widget-image_svg__b">
        <rect
          x={3}
          y={2}
          width={86.75}
          height={49.775}
          rx={3.555}
          fill="#fff"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconWidgetImage;
