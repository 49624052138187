import { jsxs as c, jsx as l } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M7.654 5.243 1.052 16.756a.375.375 0 0 0 .149.523 8.041 8.041 0 0 0 10.692-3.158L18.7 2.27A8.062 8.062 0 0 0 7.654 5.242Z",
          fill: "#0071CD"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "m8.162 2.28-.5.502L9.976 5.11l.5-.503L8.16 2.281Z",
          fill: "#0071CD"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M6.39 2.065c0 .598.483 1.066 1.06 1.066.576 0 1.059-.486 1.059-1.066C8.509 1.467 8.026 1 7.449 1c-.576 0-1.06.486-1.06 1.065Z",
          fill: "#0071CD"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M6.575 9.318v13.307c0 .224.186.392.39.374 4.277-.206 7.699-3.757 7.699-8.112v-13.7c-4.463 0-8.09 3.626-8.09 8.13Z",
          fill: "#0190F7"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M9.066 9.318c0 3.102 2.511 5.625 5.598 5.625V3.691c-3.087 0-5.597 2.505-5.597 5.627Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "m19.647 10.775-4.983-1.457v5.625c2.399 0 4.444-1.514 5.244-3.663.074-.225-.037-.45-.26-.505Zm2.66-4.112c-1.097-3.196-4.11-5.476-7.643-5.476v8.13l7.382-2.168c.205-.075.335-.299.26-.486Z",
          fill: "#FF9B07"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M11.465 6.981c0 .486.39.897.893.897a.895.895 0 0 0 .892-.897.895.895 0 0 0-.892-.897.895.895 0 0 0-.893.897Z",
          fill: "#111D3D"
        }
      )
    ]
  }
);
export {
  h as default
};
