import * as React from "react";
const SvgIconFileZipColor = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"
      fill="#fff"
    />
    <path
      d="M14 17h-2v-2h-2v-2h2v2h2v2Zm0-8h-2v2h2v2h-2v-2h-2V9h2V7h-2V5h2v2h2v2Zm5-6H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"
      fill="#8997A1"
    />
  </svg>
);
export default SvgIconFileZipColor;
