import { jsxs as s, jsx as o } from "react/jsx-runtime";
const f = (t) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ o(
        "path",
        {
          d: "M4.498 3h15.004C20.329 3 21 3.671 21 4.498v15.004c0 .827-.671 1.498-1.498 1.498H4.498A1.486 1.486 0 0 1 3 19.51V4.498C3 3.67 3.671 3 4.498 3Z",
          fill: "url(#microsoft-forms_svg__a)"
        }
      ),
      /* @__PURE__ */ o(
        "path",
        {
          d: "M15.04 8.713h-4.223v2.66h3.937v1.58h-3.937v3.93H8.91V7.125h6.14l-.01 1.588Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ o("defs", { children: /* @__PURE__ */ s(
        "linearGradient",
        {
          id: "microsoft-forms_svg__a",
          x1: 6.128,
          y1: 1.828,
          x2: 17.88,
          y2: 22.181,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ o("stop", { stopColor: "#058F92" }),
            /* @__PURE__ */ o("stop", { offset: 0.5, stopColor: "#038489" }),
            /* @__PURE__ */ o("stop", { offset: 1, stopColor: "#026D71" })
          ]
        }
      ) })
    ]
  }
);
export {
  f as default
};
