import { jsxs as t, jsx as c } from "react/jsx-runtime";
const l = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ c("path", { d: "M16 7.001h-1l-1-1h-4l-1 1H8c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2Zm-4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z" }),
      /* @__PURE__ */ c("path", { d: "M9.45.281c-.4.08-.55.56-.26.84l3.01 3.01c.32.31.85.09.85-.35v-1.74c4.45.44 8.06 3.82 8.84 8.17.08.46.5.78.97.78.62 0 1.09-.57.98-1.18-1.23-6.92-8.05-10.93-14.39-9.53ZM11.8 19.871a.496.496 0 0 0-.85.35v1.74c-4.45-.44-8.06-3.82-8.84-8.17-.08-.46-.5-.78-.97-.78-.62 0-1.09.57-.98 1.18 1.24 6.92 8.06 10.93 14.4 9.53.39-.09.55-.56.26-.85l-3.02-3Z" })
    ]
  }
);
export {
  l as default
};
