import * as a from "react";
const t = (e) => /* @__PURE__ */ a.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...e
  },
  /* @__PURE__ */ a.createElement("path", { d: "M4.997 12A1.993 1.993 0 0 1 3 10c0-1.107.888-2 1.997-2a1.999 1.999 0 1 1 0 4ZM0 18v-1.323a1 1 0 0 1 .629-.928l2.185-.875a.5.5 0 0 1 .686.464V18.5a.5.5 0 0 1-.5.5H1a1 1 0 0 1-1-1Zm19.003-6A1.993 1.993 0 0 0 21 10c0-1.107-.888-2-1.997-2a1.999 1.999 0 1 0 0 4ZM24 18v-1.323a1 1 0 0 0-.629-.928l-2.185-.875a.5.5 0 0 0-.686.464V18.5a.5.5 0 0 0 .5.5h2a1 1 0 0 0 1-1ZM15 8c0 1.66-1.332 3-2.995 3A2.998 2.998 0 0 1 9 8c0-1.66 1.342-3 3.005-3A2.99 2.99 0 0 1 15 8ZM5 18v-2.28a1 1 0 0 1 .684-.948l5.008-1.67c.204-.067.418-.102.633-.102h1.35c.215 0 .429.035.633.103l5.008 1.67a1 1 0 0 1 .684.948V18a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Z" })
);
export {
  t as default
};
