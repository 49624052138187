/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Happeo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23 9.663c0 5.35-2.588 9.549-6.155 11.661A10.282 10.282 0 0 1 11.54 22.8c-.866 0-1.727-.114-2.563-.336h-.012c-4.217-.973-7.52-4.404-7.925-8.715-.502-5.422 3.744-10.19 9.483-10.677 2.334-.2 4.671.355 6.657 1.584.905-1.069 1.683-2.209 2.197-3.456a33.49 33.49 0 0 1 1.206 2.58 9.44 9.44 0 0 0 .905-1.429C22.546 4.948 23 7.407 23 9.663Z"
      fill="#95A0B1"
    />
    <path
      opacity={0.32}
      d="M23 9.663c0 5.35-2.588 9.549-6.155 11.661A10.282 10.282 0 0 1 11.54 22.8c-.866 0-1.727-.114-2.563-.336h-.012c-4.217-.973-7.52-4.404-7.925-8.715-.502-5.422 3.744-10.19 9.483-10.677 2.334-.2 4.671.355 6.657 1.584.905-1.069 1.683-2.209 2.197-3.456a33.49 33.49 0 0 1 1.206 2.58 9.44 9.44 0 0 0 .905-1.429C22.546 4.948 23 7.407 23 9.663Z"
      fill="url(#happeo_svg__a)"
    />
    <path d="M21.503 2.395c5.137 12.766-3.918 22.326-12.528 20.072C2.501 11.69 17.597 9.999 21.503 2.395Z" />
    <path
      opacity={0.24}
      d="M21.503 2.395c5.137 12.766-3.918 22.326-12.528 20.072C2.501 11.69 17.597 9.999 21.503 2.395Z"
      fill="url(#happeo_svg__b)"
    />
    <defs>
      <linearGradient
        id="happeo_svg__a"
        x1={12}
        y1={1.2}
        x2={12}
        y2={22.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="happeo_svg__b"
        x1={15.191}
        y1={2.395}
        x2={15.191}
        y2={22.797}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);

export default Happeo;
