import * as React from "react";
const SvgIconFolderDrive = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.75h7.2c.99 0 1.8.779 1.8 1.75v8.75c0 .966-.806 1.75-1.8 1.75H4.8c-.994 0-1.8-.784-1.8-1.75V6.75C3 5.779 3.801 5 4.8 5h5.4L12 6.75Zm3.726 2.625h-2.151l-.225.402 2.295 3.973H18.3l-2.574-4.375Zm-3.051 7h4.995l1.107-1.785-.234-.402h-4.608l-1.26 2.187Zm-1.125 0-.99-1.873 2.556-4.34 1.35 2.328-2.241 3.885h-.675Z"
    />
  </svg>
);
export default SvgIconFolderDrive;
