import * as React from "react";
const SvgIconPeople = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M19 10c0 1.107-.888 2-1.997 2a1.999 1.999 0 1 1 0-4C18.112 8 19 8.893 19 10Zm3 6.677V18a1 1 0 0 1-1 1h-2a.5.5 0 0 1-.5-.5v-3.162a.5.5 0 0 1 .686-.464l2.185.875a1 1 0 0 1 .629.928ZM13 8c0 1.66-1.332 3-2.995 3A2.998 2.998 0 0 1 7 8c0-1.66 1.342-3 3.005-3A2.99 2.99 0 0 1 13 8ZM3 18v-2.28a1 1 0 0 1 .684-.948l5.008-1.67c.204-.067.418-.102.633-.102h1.35c.215 0 .429.035.633.103l5.008 1.67a1 1 0 0 1 .684.948V18a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Z" />
  </svg>
);
export default SvgIconPeople;
