import { jsx as l } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ l("path", { d: "M18.85 21.975c-.133 0-.258-.02-.375-.062a.883.883 0 0 1-.325-.213l-5.3-5.3v-1l2.525-2.525h1l5.3 5.3c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.883.883 0 0 1-.213.325L19.55 21.7c-.1.1-.208.171-.325.213a1.116 1.116 0 0 1-.375.062ZM5.125 22a1.03 1.03 0 0 1-.725-.3l-2.1-2.1a1.03 1.03 0 0 1-.3-.725c0-.133.025-.258.075-.375.05-.117.125-.225.225-.325l5.3-5.3h2.125l.85-.85L6.45 7.9H5.025L2 4.875 4.825 2.05 7.85 5.075V6.5l4.125 4.125 2.9-2.9L13.8 6.65l1.4-1.4h-2.825l-.7-.7L15.225 1l.7.7v2.825l1.4-1.4 3.55 3.55c.3.283.521.608.663.975a3.104 3.104 0 0 1 0 2.25c-.142.367-.363.7-.663 1L18.75 8.775l-1.4 1.4-1.05-1.05-5.175 5.175v2.1l-5.3 5.3c-.1.1-.208.175-.325.225a.942.942 0 0 1-.375.075Z" })
  }
);
export {
  h as default
};
