import { jsx as c } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M17 17c.417 0 .77-.146 1.062-.438.292-.29.438-.645.438-1.062 0-.416-.146-.77-.438-1.062A1.444 1.444 0 0 0 17 14c-.417 0-.77.146-1.062.438A1.444 1.444 0 0 0 15.5 15.5c0 .417.146.771.438 1.062.291.292.645.438 1.062.438Zm0 3c.517 0 .992-.12 1.425-.363a2.988 2.988 0 0 0 1.05-.962 4.892 4.892 0 0 0-1.175-.5A4.798 4.798 0 0 0 17 18c-.45 0-.883.059-1.3.175a4.892 4.892 0 0 0-1.175.5c.267.4.617.721 1.05.962.433.242.908.363 1.425.363Zm0 2c-1.383 0-2.562-.487-3.537-1.462C12.488 19.563 12 18.384 12 17c0-1.383.488-2.562 1.463-3.538C14.438 12.488 15.617 12 17 12s2.563.488 3.538 1.462C21.513 14.438 22 15.617 22 17c0 1.384-.487 2.563-1.462 3.538C19.563 21.513 18.383 22 17 22Zm-5-.075h-.238a.64.64 0 0 1-.237-.05c-2.183-.666-3.983-2.016-5.4-4.05C4.708 15.792 4 13.55 4 11.1V6.375c0-.416.121-.791.363-1.125.241-.333.554-.575.937-.725l6-2.25a2.07 2.07 0 0 1 .7-.125c.233 0 .467.042.7.125l6 2.25c.383.15.696.392.938.725.241.334.362.709.362 1.125v4.3a12.513 12.513 0 0 0-.975-.363A6.132 6.132 0 0 0 18 10.075V6.4l-6-2.25L6 6.4v4.7c0 .784.104 1.567.312 2.35.209.784.5 1.53.876 2.238A9.944 9.944 0 0 0 8.55 17.65c.533.6 1.125 1.1 1.775 1.5a7.71 7.71 0 0 0 .7 1.5c.283.467.617.892 1 1.275H12Z" })
  }
);
export {
  h as default
};
