import { jsx as a } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M21 12a1 1 0 0 1-1 1h-5.6l4 4a.99.99 0 1 1-1.4 1.4l-4-4V20a1 1 0 1 1-2 0v-5.7l-3.996 3.996a.995.995 0 0 1-1.416-1.4L9.4 13H4a1 1 0 1 1 0-2h5.6l-4-4A.99.99 0 1 1 7 5.6l4 4V4a1 1 0 1 1 2 0v5.4l3.868-3.868a1 1 0 0 1 1.438.025l.035.037a1 1 0 0 1-.024 1.39L14.3 11H20a1 1 0 0 1 1 1Z" })
  }
);
export {
  h as default
};
