import { jsx as l } from "react/jsx-runtime";
const L = (a) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ l("path", { d: "m6.7 19.3-2.75-.6a.942.942 0 0 1-.6-.388.926.926 0 0 1-.175-.687L3.45 14.8l-1.875-2.15a.934.934 0 0 1-.25-.65c0-.25.083-.466.25-.65L3.45 9.2l-.275-2.825a.926.926 0 0 1 .175-.687.943.943 0 0 1 .6-.388l2.75-.6 1.45-2.45a.929.929 0 0 1 .55-.425c.233-.066.467-.058.7.025l2.6 1.1 2.6-1.1c.233-.083.467-.091.7-.025a.929.929 0 0 1 .55.425L17.3 4.7l2.75.6c.25.05.45.18.6.388.15.208.208.437.175.687L20.55 9.2l1.875 2.15c.167.184.25.4.25.65s-.083.467-.25.65L20.55 14.8l.275 2.825a.926.926 0 0 1-.175.687.942.942 0 0 1-.6.388l-2.75.6-1.45 2.45a.93.93 0 0 1-.55.425 1.119 1.119 0 0 1-.7-.025l-2.6-1.1-2.6 1.1a1.119 1.119 0 0 1-.7.025.93.93 0 0 1-.55-.425L6.7 19.3Zm3.55-4.45a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l4.25-4.25c.2-.2.3-.438.3-.713 0-.274-.1-.512-.3-.712-.2-.2-.437-.3-.712-.3-.275 0-.513.1-.713.3L10.95 12.7 9.5 11.275a.974.974 0 0 0-.712-.263.98.98 0 0 0-.688.288.948.948 0 0 0-.275.7c0 .284.092.517.275.7l2.15 2.15Zm-.8 5.1 2.55-1.1 2.6 1.1 1.4-2.4 2.75-.65-.25-2.8 1.85-2.1-1.85-2.15.25-2.8-2.75-.6-1.45-2.4L12 5.15l-2.6-1.1L8 6.45l-2.75.6.25 2.8L3.65 12l1.85 2.1-.25 2.85 2.75.6 1.45 2.4Z" })
  }
);
export {
  L as default
};
