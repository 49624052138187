import { jsxs as s, jsx as t } from "react/jsx-runtime";
const h = (o) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M12 2C5.61 2 2 5.61 2 12c0 6.39 3.61 10 10 10v-5.815c-2.684 0-4.185-1.533-4.185-4.185 0-2.684 1.533-4.185 4.185-4.185 2.684 0 4.185 1.533 4.185 4.185H22c0-6.39-3.61-10-10-10Z",
          fill: "#68778D"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M12 7.815V12H7.816c0 2.684 1.534 4.185 4.186 4.185 2.683 0 4.185-1.533 4.185-4.185 0-2.684-1.502-4.185-4.185-4.185Z",
          fill: "#2B313B"
        }
      )
    ]
  }
);
export {
  h as default
};
