import { jsx as c } from "react/jsx-runtime";
const A = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M2 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 0 18h4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 16V5c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 4 3h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v11c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 18h4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 22 20H2Zm10-1a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 18a.967.967 0 0 0-.287-.712A.968.968 0 0 0 12 17a.965.965 0 0 0-.712.288A.965.965 0 0 0 11 18c0 .283.096.52.288.712A.965.965 0 0 0 12 19Z" })
  }
);
export {
  A as default
};
