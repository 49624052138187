import { jsxs as h, jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ c("path", { d: "M11.99 2.21V5c4.95 0 8.84 4.48 7.85 9.61-.61 3.12-3.12 5.63-6.24 6.24a8.013 8.013 0 0 1-9.53-6.72c-.08-.6.4-1.13 1-1.13.5 0 .92.37.98.85a6.014 6.014 0 0 0 7.23 5.01c2.26-.47 4.1-2.3 4.57-4.57A6.003 6.003 0 0 0 11.99 7v2.8a.5.5 0 0 1-.85.35L7.35 6.36c-.2-.2-.2-.51 0-.71l3.79-3.79c.31-.32.85-.1.85.35Z" }),
      /* @__PURE__ */ c("path", { d: "M10.04 16h.85v-4.27h-.09l-1.77.63v.69l1.01-.31V16ZM12.12 13.9l.25-2.17h2.39v.71h-1.7l-.11.92c.018-.011.038-.02.06-.028a.617.617 0 0 0 .05-.022c.04-.02.09-.04.15-.05.03-.005.06-.012.09-.02l.09-.02a1.35 1.35 0 0 1 .75.08c.16.07.3.16.41.28.11.12.2.27.25.45.05.18.09.38.09.6 0 .19-.03.37-.09.54-.06.17-.15.32-.27.45-.12.13-.27.24-.45.31s-.39.12-.64.12c-.18 0-.36-.03-.53-.08-.17-.05-.32-.14-.46-.24a1.1 1.1 0 0 1-.32-.39c-.08-.15-.13-.33-.13-.53h.84c.02.18.08.32.19.41.11.09.25.15.42.15a.49.49 0 0 0 .45-.23c.04-.07.08-.15.11-.25s.03-.2.03-.31c0-.11-.01-.21-.04-.31a.618.618 0 0 0-.13-.24.51.51 0 0 0-.21-.15.896.896 0 0 0-.3-.05c-.08 0-.15.01-.2.02a.66.66 0 0 0-.15.05c-.02.01-.04.023-.06.035a.745.745 0 0 1-.06.035.29.29 0 0 0-.078.067 1.03 1.03 0 0 1-.022.023l-.67-.16Z" })
    ]
  }
);
export {
  s as default
};
