import * as React from "react";
const SvgIconZoomIn = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M15.97 14.472h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34-4.23-.52-7.78 3.04-7.27 7.27.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.26 4.25c.41.41 1.07.41 1.48 0l.01-.01c.41-.41.41-1.07 0-1.48l-4.25-4.26Zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5Zm-.5-6.5c0-.28.22-.5.5-.5s.5.22.5.5v1.5h1.5c.28 0 .5.22.5.5s-.22.5-.5.5h-1.5v1.5c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-1.5h-1.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1.5v-1.5Z" />
  </svg>
);
export default SvgIconZoomIn;
