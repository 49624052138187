/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Meta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 14.552c0 1.132.196 2.143.593 2.957.057.117.118.229.183.337.637 1.062 1.666 1.767 3.293 1.767 1.372 0 2.414-.616 3.634-2.241.697-.928 1.05-1.49 2.442-3.96l.863-1.529.168.278 1.973 3.296c.663 1.11 1.526 2.343 2.263 3.038.96.905 1.827 1.118 2.806 1.118.69 0 1.256-.16 1.716-.408a3.283 3.283 0 0 0 1.276-1.256c.498-.861.79-1.95.79-3.433 0-2.494-.624-4.911-1.91-6.83C19.915 5.934 18.379 5 16.767 5c-.96 0-1.914.428-2.798 1.2-.598.521-1.154 1.18-1.669 1.875-.634-.803-1.224-1.414-1.795-1.88C9.421 5.308 8.383 5 7.338 5 5.535 5 3.963 6.174 2.874 7.854 1.646 9.747 1 12.199 1 14.552Zm2.376.085c0 .84.185 1.485.426 1.875.316.51.787.727 1.267.727.62 0 1.187-.153 2.28-1.664.616-.854 1.31-1.95 1.91-2.897.25-.396.485-.766.69-1.08l.935-1.437c-.456-.65-.85-1.146-1.132-1.446C9.187 8.115 8.46 7.39 7.3 7.39c-.938 0-1.736.659-2.403 1.667-.943 1.423-1.52 3.544-1.52 5.58Zm11.155-3.255a41.2 41.2 0 0 0-1.151-1.819l.194-.296c1.028-1.527 1.942-2.384 3.078-2.384 1.05 0 2.005.694 2.742 1.831 1.039 1.603 1.51 3.846 1.51 5.868 0 1.24-.258 2.345-1.226 2.601a1.792 1.792 0 0 1-.46.056c-.531 0-.94-.21-1.525-.92-.455-.55-1.231-1.72-2.596-3.994l-.566-.943Z"
    />
  </svg>
);

export default Meta;
