import { jsx as A } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ A("path", { d: "M8 14a.968.968 0 0 1-.713-.288A.967.967 0 0 1 7 13a.97.97 0 0 1 .287-.713A.97.97 0 0 1 8 12a.97.97 0 0 1 .713.287A.97.97 0 0 1 9 13c0 .283-.096.52-.287.712A.968.968 0 0 1 8 14Zm4 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 13c0-.283.096-.521.288-.713A.967.967 0 0 1 12 12a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 13c0 .283-.096.52-.287.712A.968.968 0 0 1 12 14Zm4 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 13c0-.283.096-.521.288-.713A.967.967 0 0 1 16 12c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 16 14ZM5 22c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 20V6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 5 4h1V2.975c0-.283.096-.517.287-.7A.993.993 0 0 1 7 2a.97.97 0 0 1 .713.287A.97.97 0 0 1 8 3v1h8V2.975c0-.283.096-.517.288-.7A.99.99 0 0 1 17 2c.283 0 .52.096.712.287.192.192.288.43.288.713v1h1c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 22H5Zm0-2h14V10H5v10Z" })
  }
);
export {
  h as default
};
