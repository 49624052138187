import { jsx as c } from "react/jsx-runtime";
const t = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M12 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 20h4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 22Zm8-9a.965.965 0 0 1-.712-.288A.965.965 0 0 1 19 12v-2h-2a.965.965 0 0 1-.712-.288A.965.965 0 0 1 16 9c0-.283.096-.521.288-.713A.967.967 0 0 1 17 8h2V6c0-.283.096-.521.288-.713A.967.967 0 0 1 20 5c.283 0 .52.096.712.287.192.192.288.43.288.713v2h2c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 23 10h-2v2c0 .283-.096.52-.288.712A.965.965 0 0 1 20 13ZM5 19a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 18c0-.283.096-.52.287-.712A.968.968 0 0 1 5 17h1v-7c0-1.417.425-2.654 1.275-3.713A5.927 5.927 0 0 1 10.5 4.2v-.7c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 12 2c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v.7c.317.067.621.162.913.287.291.125.554.271.787.438a6.198 6.198 0 0 0-1.087 1.9A6.467 6.467 0 0 0 13.75 9c0 1.417.387 2.663 1.162 3.738.775 1.075 1.805 1.862 3.088 2.362V17h1c.283 0 .52.096.712.288A.965.965 0 0 1 20 18c0 .283-.096.52-.288.712A.965.965 0 0 1 19 19H5Z" })
  }
);
export {
  t as default
};
