import { jsx as c } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M12 22a9.676 9.676 0 0 1-3.875-.788 10.144 10.144 0 0 1-3.188-2.15 10.142 10.142 0 0 1-2.15-3.187A9.676 9.676 0 0 1 2 12c0-1.383.27-2.683.813-3.9a10.13 10.13 0 0 1 2.2-3.175A10.48 10.48 0 0 1 8.25 2.788 9.979 9.979 0 0 1 12.2 2c1.333 0 2.592.23 3.775.688a9.918 9.918 0 0 1 3.112 1.9 9.092 9.092 0 0 1 2.125 2.875A8.273 8.273 0 0 1 22 11.05c0 1.917-.583 3.387-1.75 4.412C19.083 16.489 17.667 17 16 17h-1.85c-.15 0-.254.042-.313.125a.47.47 0 0 0-.087.275c0 .2.125.488.375.863.25.374.375.804.375 1.287 0 .833-.23 1.45-.688 1.85-.458.4-1.062.6-1.812.6Zm-5.5-9c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C7.292 10.142 6.933 10 6.5 10c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm3-4c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C10.292 6.142 9.933 6 9.5 6c-.433 0-.792.142-1.075.425C8.142 6.708 8 7.067 8 7.5c0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm5 0c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C15.292 6.142 14.933 6 14.5 6c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm3 4c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075-.283-.283-.642-.425-1.075-.425-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Z" })
  }
);
export {
  e as default
};
