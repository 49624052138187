import * as React from "react";
const SvgIconArrange = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M5 5V1.131h2V5h3.869v2H7v3.869H5V7H1.131V5H5Zm8.472 8.886-2.736-2.736 1.414-1.414 2.736 2.736 2.736-2.736 1.414 1.414-2.736 2.736 2.736 2.736-1.414 1.414-2.736-2.736-2.736 2.736-1.414-1.414 2.736-2.736ZM6 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm9-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconArrange;
