import * as a from "react";
const t = (e) => /* @__PURE__ */ a.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...e
  },
  /* @__PURE__ */ a.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M18 21a1 1 0 1 0 0-2H3a1 1 0 1 0 0 2h15ZM8 16a1 1 0 0 1-1 1H3a1 1 0 1 1 0-2h4a1 1 0 0 1 1 1Zm0-4a1 1 0 0 0-1-1H3a1 1 0 1 0 0 2h4a1 1 0 0 0 1-1Zm0-4a1 1 0 0 1-1 1H3a1 1 0 1 1 0-2h4a1 1 0 0 1 1 1Zm10-5a1 1 0 1 1 0 2H3a1 1 0 0 1 0-2h15Zm2 14a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h8Z"
    }
  )
);
export {
  t as default
};
