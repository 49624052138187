import { jsxs as f, jsx as a } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ f(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          d: "M18.685 22H5.315A3.315 3.315 0 0 1 2 18.685V5.315A3.315 3.315 0 0 1 5.315 2h13.37A3.315 3.315 0 0 1 22 5.315v13.37A3.315 3.315 0 0 1 18.685 22Z",
          fill: "#0061D5"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "M12.236 10.42c-1.04 0-1.946.586-2.402 1.446a2.718 2.718 0 0 0-4.034-.901V8.674a.543.543 0 0 0-1.087 0v4.51a2.718 2.718 0 0 0 5.121 1.231 2.72 2.72 0 1 0 2.402-3.994m-4.804 4.35a1.631 1.631 0 1 1-.002-3.263 1.631 1.631 0 0 1 .002 3.263Zm4.804 0a1.631 1.631 0 1 1 0-3.263 1.631 1.631 0 0 1 0 3.263Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ a(
        "path",
        {
          d: "m19.174 14.949-1.478-1.813 1.48-1.816a.515.515 0 0 0-.125-.752.627.627 0 0 0-.821.099l-1.274 1.56-1.271-1.56a.626.626 0 0 0-.821-.1.514.514 0 0 0-.124.753l1.476 1.816-1.477 1.813a.515.515 0 0 0 .125.752.626.626 0 0 0 .82-.1l1.272-1.558 1.272 1.559c.2.232.563.278.821.1a.515.515 0 0 0 .125-.753Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  h as default
};
