import { jsx as h } from "react/jsx-runtime";
const m = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M3 9V7h2v2H3Zm8 0V7h2v2h-2Zm0 4v-2h2v2h-2Zm-8 0v-2h2v2H3Zm4-8V3h2v2H7ZM3 5V3h2v2H3Zm8 0V3h2v2h-2Zm4 0V3h2v2h-2Zm-8 8v-2h2v2H7Zm8 8v-2h2v2h-2Zm-4 0v-2h2v2h-2Zm9 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 19 20V4c0-.283.096-.521.288-.713A.967.967 0 0 1 20 3c.283 0 .52.096.712.287.192.192.288.43.288.713v16c0 .283-.096.52-.288.712A.965.965 0 0 1 20 21ZM7 21v-2h2v2H7Zm-4-4v-2h2v2H3Zm12-4v-2h2v2h-2ZM3 21v-2h2v2H3Zm8-4v-2h2v2h-2Z" })
  }
);
export {
  m as default
};
