import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 32 32",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M26.4 13.407 6.867 6.274a1.365 1.365 0 0 0-1.734.8c-.253.707.107 1.48.8 1.733l17.534 6.334h-16.8A2.674 2.674 0 0 0 4 17.807v5.334c0 1.466 1.2 2.666 2.667 2.666h18.666c1.467 0 2.667-1.2 2.667-2.666v-7.334c0-1.066-.667-2.133-1.6-2.4Zm-17.067 8.4H6.667v-2.666h2.666v2.666Zm4 0H24c.733 0 1.333-.6 1.333-1.333s-.6-1.333-1.333-1.333H13.333c-.733 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333Z" })
);
export {
  c as default
};
