import { jsxs as h, jsx as l } from "react/jsx-runtime";
const t = (e) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l("path", { d: "M14.5 1 20 6.5l-2.75.5-2.75-.5L14 4l.5-3Z", fill: "#2B313B" }),
      /* @__PURE__ */ l("path", { d: "M8.25 15h7.5v-4.25h-7.5V15Z" }),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M14.5 1v5.5H20v15a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 4 21.5v-19A1.5 1.5 0 0 1 5.5 1h9ZM7 16.25V9.5h10v6.75H7Z"
        }
      )
    ]
  }
);
export {
  t as default
};
