import { jsx as c } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M6.5 20c-1.533 0-2.833-.533-3.9-1.6C1.533 17.333 1 16.033 1 14.5c0-1.3.404-2.446 1.213-3.438.808-.991 1.82-1.629 3.037-1.912a6.955 6.955 0 0 1 2.475-3.7C8.958 4.483 10.383 4 12 4c1.983 0 3.646.687 4.988 2.062C18.329 7.437 19 9.083 19 11c1.25.133 2.23.65 2.938 1.55.708.9 1.062 1.875 1.062 2.925 0 1.25-.437 2.317-1.312 3.2S19.75 20 18.5 20h-12Zm0-2h12c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775C19.792 13.242 19.2 13 18.5 13H17v-2c0-1.383-.487-2.563-1.462-3.538C14.563 6.487 13.383 6 12 6c-1.05 0-1.983.287-2.8.862a5.115 5.115 0 0 0-1.8 2.213 5.329 5.329 0 0 1 2.925 1.462A5.456 5.456 0 0 1 11.9 13.4c.067.283-.004.537-.212.762-.209.225-.48.338-.813.338a.885.885 0 0 1-.613-.238 1.23 1.23 0 0 1-.362-.612c-.183-.75-.592-1.38-1.225-1.888A3.38 3.38 0 0 0 6.5 11c-.967 0-1.792.342-2.475 1.025A3.372 3.372 0 0 0 3 14.5c0 .967.342 1.792 1.025 2.475A3.372 3.372 0 0 0 6.5 18Z" })
  }
);
export {
  o as default
};
