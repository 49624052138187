import { jsxs as a, jsx as c } from "react/jsx-runtime";
const l = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19V5c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 5 3h8c.283 0 .52.096.713.288.191.191.287.429.287.712v2a2 2 0 0 0 2 2 2 2 0 0 0 2 2h2c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21H5Zm2.588-10.588C7.979 10.804 8.45 11 9 11c.55 0 1.021-.196 1.413-.588C10.804 10.021 11 9.55 11 9c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 9 7c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 7 9c0 .55.196 1.02.588 1.412Zm-.734 5.735 2.795-2.796a.5.5 0 0 1 .705-.002l1.446 1.426 1.81-2.283a.5.5 0 0 1 .782-.002l2.958 3.698a.5.5 0 0 1-.39.812H7.207a.5.5 0 0 1-.353-.853Z"
        }
      ),
      /* @__PURE__ */ c("path", { d: "M18 9a.968.968 0 0 1-.712-.287A.967.967 0 0 1 17 8V7h-1a.968.968 0 0 1-.713-.287A.967.967 0 0 1 15 6c0-.283.096-.52.287-.713A.968.968 0 0 1 16 5h1V4c0-.283.096-.52.288-.712A.968.968 0 0 1 18 3c.283 0 .52.096.712.288.192.191.288.429.288.712v1h1c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 20 7h-1v1c0 .283-.096.52-.288.713A.968.968 0 0 1 18 9Z" })
    ]
  }
);
export {
  l as default
};
