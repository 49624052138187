/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const AdobeAcrobat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16.04 14.3c.533.133.833-.1.866-.333.067-.334-.2-.5-.467-.567-.7-.133-1.666-.033-2.1.033.5.4 1.067.7 1.7.867ZM11.606 6.933c-.1 0-.2.067-.267.167-.166.3-.266 1.233.234 2.567a7.325 7.325 0 0 0 .4-2.067c0-.3-.067-.667-.367-.667ZM12.04 11.7c-.134.4-.5 1.333-.767 1.867.478-.16 1.04-.295 1.414-.386.252-.06.419-.101.419-.114a8.498 8.498 0 0 1-1.067-1.367ZM7.306 16.967c.433 0 1.233-.8 2-2.067-1.333.6-2.2 1.233-2.3 1.7-.033.133 0 .233.1.3a.473.473 0 0 0 .2.067Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.005 3H6.37C4.602 3 3.187 4.467 3.187 6.3v11.4c0 1.833 1.415 3.3 3.182 3.3h11.636c1.768 0 3.182-1.467 3.182-3.3V6.3c0-1.833-1.414-3.3-3.182-3.3Zm-3.936 9.917c.733-.092 2.859-.36 3.67.483.234.267.334.633.167.967-.1.3-.4.633-1.2.633-.6 0-1.733-.167-3.033-1.3-1.1.2-2.2.533-2.9.767C9.939 15.9 8.739 17.6 7.506 17.6c-.3 0-.6-.1-.833-.3-.234-.233-.334-.533-.267-.833.167-1 2.2-1.934 3.167-2.334.876-1.658 1.223-2.64 1.566-3.61l.067-.19c-.633-1.433-.8-2.833-.4-3.533.167-.267.467-.467.8-.467.933 0 1.267.9 1.267 1.734-.034.966-.234 1.866-.534 2.766l.021.035c.217.362.774 1.292 1.58 2.065l.129-.016Z"
    />
  </svg>
);

export default AdobeAcrobat;
