import { jsx as a } from "react/jsx-runtime";
const v = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M11 8.376c0-.43-.238-.818-.606-.987l-5-2.301C4.734 4.784 4 5.304 4 6.075v10.549c0 .43.238.818.606.987l5 2.301c.66.304 1.394-.216 1.394-.987V8.376ZM13 8.376c0-.43.238-.818.606-.987l5-2.301c.66-.304 1.394.216 1.394.987v6.266A5.99 5.99 0 0 0 18 12a5.994 5.994 0 0 0-5 2.682V8.376ZM18 14a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Z" })
  }
);
export {
  v as default
};
