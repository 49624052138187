import { jsx as h } from "react/jsx-runtime";
const Z = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M4 19c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 17V7c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 5h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 19H4Zm7-9h2V8h-2v2Zm0 3h2v-2h-2v2Zm-3-3h2V8H8v2Zm0 3h2v-2H8v2Zm-3 0h2v-2H5v2Zm0-3h2V8H5v2Zm4 6h6a.968.968 0 0 0 .713-.288A.967.967 0 0 0 16 15a.97.97 0 0 0-.287-.713A.97.97 0 0 0 15 14H9a.967.967 0 0 0-.712.287A.968.968 0 0 0 8 15c0 .283.096.52.288.712A.965.965 0 0 0 9 16Zm5-3h2v-2h-2v2Zm0-3h2V8h-2v2Zm3 3h2v-2h-2v2Zm0-3h2V8h-2v2Z" })
  }
);
export {
  Z as default
};
