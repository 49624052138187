import { jsx as a } from "react/jsx-runtime";
const c = (e) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ a(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm3.85 9.093 1.45-1.45c.2-.2.296-.434.287-.7a1.054 1.054 0 0 0-.287-.7 1.02 1.02 0 0 0-.712-.313.93.93 0 0 0-.713.288L6.7 11.393c-.2.2-.3.433-.3.7 0 .266.1.5.3.7l2.175 2.175c.2.2.438.296.713.287a1.02 1.02 0 0 0 .712-.312c.183-.2.28-.434.287-.7a.916.916 0 0 0-.287-.7l-1.45-1.45Zm6.3 0-1.45 1.45c-.2.2-.296.433-.287.7a1.02 1.02 0 0 0 1 1.013.93.93 0 0 0 .712-.288l2.175-2.175c.2-.2.3-.434.3-.7 0-.267-.1-.5-.3-.7l-2.175-2.175a.93.93 0 0 0-.713-.288 1.02 1.02 0 0 0-.712.313c-.183.2-.28.433-.287.7-.009.266.087.5.287.7l1.45 1.45Z"
      }
    )
  }
);
export {
  c as default
};
