import { jsx as t } from "react/jsx-runtime";
const a = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M12.039 17.754c-3.176 0-4.627-1.618-4.627-2.805a1.03 1.03 0 0 1 1.086-1.029c1.36 0 1.004 2.04 3.54 2.04 1.297 0 2.06-.781 2.06-1.514 0-.441-.253-.945-1.109-1.146l-2.833-.715c-2.275-.579-2.672-1.844-2.672-3.018 0-2.436 2.229-3.318 4.35-3.318 1.955 0 4.278 1.08 4.278 2.54 0 .63-.526.967-1.137.967-1.161 0-.966-1.63-3.301-1.63-1.162 0-1.772.544-1.772 1.306 0 .761.903 1.02 1.696 1.192l2.09.473c2.291.516 2.902 1.86 2.902 3.147 0 1.98-1.533 3.51-4.554 3.51h.003Zm8.769-4.19c.09-.52.136-1.046.135-1.573A8.945 8.945 0 0 0 10.45 3.143a5.263 5.263 0 0 0-2.637-.702 5.35 5.35 0 0 0-4.618 7.998 8.952 8.952 0 0 0 10.362 10.418c.801.462 1.71.704 2.634.702a5.35 5.35 0 0 0 4.618-7.995Z" })
  }
);
export {
  a as default
};
