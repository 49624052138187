import * as React from "react";
const SvgIconMsSway = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="Icon-ms-sway_svg__a"
        x1={5.822}
        y1={11.568}
        x2={20.178}
        y2={36.432}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#058f92" />
        <stop offset={0.5} stopColor="#038489" />
        <stop offset={1} stopColor="#026d71" />
      </linearGradient>
    </defs>
    <path
      d="M45 5.83V34l-16 2-.4-.05L12 34V5.83A1.831 1.831 0 0 1 13.83 4h29.34A1.831 1.831 0 0 1 45 5.83Z"
      fill="#37c6d0"
    />
    <path
      d="M45 23.54v5.01l-1.26 1.83H25.17L36 19.55l.87-.87a2.294 2.294 0 0 1 3.26 0Z"
      fill="#1a9ba1"
    />
    <path
      d="M45 28.55V34l-16.4 1.95L12 34v-1.46l16.87-16.87a2.318 2.318 0 0 1 3.26 0L36 19.55Z"
      fill="#038387"
    />
    <circle cx={38.5} cy={10.5} r={2.5} fill="#86f2f2" />
    <path
      d="M13.833 44h29.334A1.833 1.833 0 0 0 45 42.167V34H12v8.167A1.833 1.833 0 0 0 13.833 44Z"
      fill="#036c70"
    />
    <path fill="none" d="M0 0h48v48H0z" />
    <path
      d="M24.167 12H12v25h12.167A1.839 1.839 0 0 0 26 35.167V13.833A1.839 1.839 0 0 0 24.167 12Z"
      opacity={0.1}
    />
    <path
      d="M23.167 13H12v25h11.167A1.839 1.839 0 0 0 25 36.167V14.833A1.839 1.839 0 0 0 23.167 13Z"
      opacity={0.2}
    />
    <path
      d="M23.167 13H12v23h11.167A1.839 1.839 0 0 0 25 34.167V14.833A1.839 1.839 0 0 0 23.167 13Z"
      opacity={0.2}
    />
    <path
      d="M22.167 13H12v23h10.167A1.839 1.839 0 0 0 24 34.167V14.833A1.839 1.839 0 0 0 22.167 13Z"
      opacity={0.2}
    />
    <rect
      x={2}
      y={13}
      width={22}
      height={22}
      rx={1.833}
      fill="url(#Icon-ms-sway_svg__a)"
    />
    <path
      d="M10.029 23.776a3.384 3.384 0 0 1-1.049-1.109 3.018 3.018 0 0 1-.365-1.517 2.919 2.919 0 0 1 .679-1.965 4.011 4.011 0 0 1 1.806-1.178 7.629 7.629 0 0 1 2.467-.382 9.4 9.4 0 0 1 3.251.455v2.3a5.091 5.091 0 0 0-1.471-.6 7.159 7.159 0 0 0-1.719-.206 3.719 3.719 0 0 0-1.767.374A1.161 1.161 0 0 0 11.16 21a1.076 1.076 0 0 0 .3.752 2.708 2.708 0 0 0 .8.572q.5.249 1.5.662a1.612 1.612 0 0 1 .215.086 12.371 12.371 0 0 1 1.891.933 3.387 3.387 0 0 1 1.118 1.126 3.264 3.264 0 0 1 .4 1.7 3.162 3.162 0 0 1-.636 2.046 3.557 3.557 0 0 1-1.7 1.144 7.706 7.706 0 0 1-2.424.352 12.833 12.833 0 0 1-2.2-.18 7.487 7.487 0 0 1-1.784-.516v-2.423a5.746 5.746 0 0 0 1.8.86 7.043 7.043 0 0 0 1.987.318 3.418 3.418 0 0 0 1.8-.382 1.208 1.208 0 0 0 .606-1.079 1.15 1.15 0 0 0-.34-.826 3.409 3.409 0 0 0-.937-.653q-.6-.3-1.767-.791a10.044 10.044 0 0 1-1.76-.925Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIconMsSway;
