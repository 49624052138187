import { jsx as c } from "react/jsx-runtime";
const t = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M12 5a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 4a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 3a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 4c0 .283.096.52.288.712A.965.965 0 0 0 12 5ZM5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h4.175c.183-.583.542-1.063 1.075-1.438A2.98 2.98 0 0 1 12 1c.667 0 1.263.187 1.788.562A2.75 2.75 0 0 1 14.85 3H19c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm0-2h14V5h-2v1c0 .55-.196 1.02-.587 1.412A1.927 1.927 0 0 1 15 8H9c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 7 6V5H5v14Z" })
  }
);
export {
  t as default
};
