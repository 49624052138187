/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const DropboxPaper = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.394 15.872 12 22l9.606-6.128L12 9.745l-9.606 6.127Z"
      fill="#0061FE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.394 8.127 12 14.255l9.606-6.128L12 2 2.394 8.127Z"
      fill="#1E1919"
    />
  </svg>
);

export default DropboxPaper;
