import { jsx as c } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M6 14c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 4 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 10c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.412A1.923 1.923 0 0 1 6 14Zm6 0c-.55 0-1.02-.196-1.412-.588A1.923 1.923 0 0 1 10 12c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 12 10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 12 14Zm6 0c-.55 0-1.021-.196-1.413-.588A1.925 1.925 0 0 1 16 12c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 18 10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.412A1.927 1.927 0 0 1 18 14Z" })
  }
);
export {
  s as default
};
