import { jsx as h } from "react/jsx-runtime";
const A = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M5.5 23c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 4 21.5v-3c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 5.5 17h1v-2h-1c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 4 13.5v-3c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 5.5 9h1V7h-1c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 4 5.5v-3c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 5.5 1h4c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v3c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 9.5 7h-1v2h1c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v.5h3v-.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 15.5 9h4c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v3c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 19.5 15h-4c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 14 13.5V13h-3v.5c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 9.5 15h-1v2h1c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v3c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 9.5 23h-4Z" })
  }
);
export {
  A as default
};
