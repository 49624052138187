import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M6.18 20a2.18 2.18 0 1 1 0-4.36 2.18 2.18 0 0 1 0 4.36ZM4 4.44c8.59 0 15.56 6.97 15.56 15.56h-2.83c0-7.03-5.7-12.73-12.73-12.73V4.44Zm0 5.66c5.47 0 9.9 4.43 9.9 9.9h-2.83c0-3.9-3.17-7.07-7.07-7.07V10.1Z" })
);
export {
  c as default
};
