import { jsxs as l, jsx as h } from "react/jsx-runtime";
const a = (t) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ h(
        "path",
        {
          d: "M5.787 12.867v-7.15H2.663C1.743 5.717 1 6.465 1 7.384v14.78a.833.833 0 0 0 1.421.59l3.437-3.437h9.785c.92 0 1.663-.743 1.663-1.663V14.53H7.455c-.92 0-1.668-.744-1.668-1.663Z",
          fill: "#00AC47"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M20.43 1H7.455c-.92 0-1.663.744-1.663 1.663v3.054h9.851c.92 0 1.663.743 1.663 1.663v7.146h3.124c.92 0 1.663-.744 1.663-1.664V2.663c0-.92-.743-1.663-1.663-1.663Z",
          fill: "#5BB974"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M15.643 5.717H5.787v7.145c0 .92.744 1.664 1.663 1.664h9.852V7.384a1.66 1.66 0 0 0-1.659-1.667Z",
          fill: "#00832D"
        }
      )
    ]
  }
);
export {
  a as default
};
