import * as React from "react";
const SvgIconPeopleBox = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm7.75 6c0 1.245-1 2.25-2.246 2.25a2.249 2.249 0 1 1 0-4.5A2.242 2.242 0 0 1 12.75 9Zm3.002 3c.832 0 1.498-.67 1.498-1.5S16.584 9 15.752 9a1.5 1.5 0 1 0 0 3Zm3.748 4.5v-.992a.75.75 0 0 0-.471-.697l-1.64-.655a.375.375 0 0 0-.514.348v2.371c0 .207.168.375.375.375h1.5a.75.75 0 0 0 .75-.75ZM5.25 14.79v1.71c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-1.71a.75.75 0 0 0-.513-.71l-3.756-1.253a1.5 1.5 0 0 0-.474-.077H9.993a1.5 1.5 0 0 0-.474.077l-3.756 1.252a.75.75 0 0 0-.513.712Z"
    />
  </svg>
);
export default SvgIconPeopleBox;
