import { jsx as e } from "react/jsx-runtime";
const a = (h) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm8 2h4v4h-4V5Zm0 5h4v4h-4v-4Zm3 5a4 4 0 1 1-4-4v4h4Z"
      }
    )
  }
);
export {
  a as default
};
