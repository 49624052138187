import * as React from "react";
const SvgIconPages = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M13 8.376c0-.43.238-.818.606-.987l5-2.301c.66-.304 1.394.216 1.394.987v10.549c0 .43-.238.818-.606.987l-5 2.301c-.66.304-1.394-.216-1.394-.987V8.376Zm-2 0c0-.43-.238-.818-.606-.987l-5-2.301C4.734 4.784 4 5.304 4 6.075v10.549c0 .43.238.818.606.987l5 2.301c.66.304 1.394-.216 1.394-.987V8.376Z" />
  </svg>
);
export default SvgIconPages;
