import { jsx as c } from "react/jsx-runtime";
const l = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M8.625 20h-2.65c-.55 0-1.021-.195-1.413-.587A1.928 1.928 0 0 1 3.975 18v-2.65l-1.9-1.925C1.692 13.042 1.5 12.567 1.5 12c0-.566.192-1.041.575-1.425l1.9-1.925V6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 5.975 4h2.65l1.925-1.9c.383-.383.858-.575 1.425-.575.567 0 1.042.192 1.425.575L15.325 4h2.65c.55 0 1.021.196 1.413.588.391.392.587.862.587 1.412v2.65l1.9 1.925c.383.384.575.859.575 1.425 0 .567-.192 1.042-.575 1.425l-1.9 1.925V18c0 .55-.196 1.021-.587 1.413a1.928 1.928 0 0 1-1.413.587h-2.65L13.4 21.9c-.383.384-.858.575-1.425.575-.567 0-1.042-.191-1.425-.575L8.625 20Zm3.575-3c1.417 0 2.617-.487 3.6-1.463.983-.974 1.475-2.153 1.475-3.537 0-1.383-.492-2.558-1.475-3.525-.983-.966-2.183-1.45-3.6-1.45-.217 0-.412.013-.587.037a6.624 6.624 0 0 0-.488.088.485.485 0 0 0-.237.162.381.381 0 0 0-.088.238c0 .034.075.167.225.4.683.45 1.2 1.038 1.55 1.762A5.2 5.2 0 0 1 13.1 12c0 .85-.183 1.634-.55 2.35A4.423 4.423 0 0 1 11 16.075c-.05.034-.125.159-.225.375 0 .1.033.188.1.262.067.076.15.13.25.163A4.57 4.57 0 0 0 12.2 17Z" })
  }
);
export {
  l as default
};
