import * as React from "react";
const SvgIconBrush = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m19.869 3.291 1.34 1.34c.39.39.39 1.02 0 1.41l-8.96 8.96-2.75-2.75 8.96-8.96a.996.996 0 0 1 1.41 0Zm-15.37 13.71c0-1.66 1.34-3 3-3s3 1.34 3 3c0 2.21-1.79 4-4 4-1.51 0-3.08-.78-4-2 .84 0 2-.69 2-2Z"
    />
  </svg>
);
export default SvgIconBrush;
