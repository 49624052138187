import * as e from "react";
const h = (v) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...v
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      d: "M6 14h2v2H6v-2Zm-3-3h2v2H3v-2Zm0-3h2v2H3V8Zm0-3h2v2H3V5Zm0-3h2v2H3V2Zm2.009 14A2.009 2.009 0 0 1 3 13.994L5 14l.009 2ZM11 8h5l5 5v7a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2Zm0 2v10h8v-6h-4v-4h-4Z"
    }
  )
);
export {
  h as default
};
