import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M10.7 15.3a.948.948 0 0 0 .7.275.949.949 0 0 0 .7-.275l1.9-1.9 1.9 1.9a.948.948 0 0 0 .7.275.949.949 0 0 0 .7-.275.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7L15.4 12l1.9-1.9a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275L14 10.6l-1.9-1.9a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275.948.948 0 0 0-.275.7c0 .283.092.517.275.7l1.9 1.9-1.9 1.9a.948.948 0 0 0-.275.7c0 .283.092.517.275.7ZM9 19c-.333 0-.646-.075-.938-.225a1.912 1.912 0 0 1-.712-.625l-3.525-5a2.03 2.03 0 0 1-.35-1.15c0-.417.117-.8.35-1.15l3.525-5c.183-.267.42-.475.712-.625C8.354 5.075 8.667 5 9 5h10c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 19H9Zm-3.55-7L9 17h10V7H9l-3.55 5ZM19 12V7v10-5Z" })
  }
);
export {
  t as default
};
