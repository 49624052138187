import * as React from "react";
const SvgIconSubdirectoryArrowRight = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="m18.999 15.419-4.58 4.58c-.39.39-1.03.39-1.42 0-.39-.39-.39-1.03 0-1.42l2.88-2.87H5.709c-.55 0-1-.45-1-1v-10c0-.55.45-1 1-1s1 .45 1 1v9h9.17l-2.88-2.87c-.39-.39-.39-1.03 0-1.42.39-.39 1.03-.39 1.42 0l4.58 4.58c.39.39.39 1.03 0 1.42Z" />
  </svg>
);
export default SvgIconSubdirectoryArrowRight;
