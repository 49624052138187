import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M15.95 21.575a1.1 1.1 0 0 1-.375-.063.871.871 0 0 1-.325-.212l-2.85-2.85a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.15 2.15 4.95-4.95a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-5.65 5.65c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063Zm-4.475-8.9c.167.1.342.15.525.15s.358-.05.525-.15l7-4.375a.978.978 0 0 0 .338-.388c.091-.175.137-.345.137-.512 0-.317-.133-.546-.4-.687-.267-.142-.533-.13-.8.037L12 11 5.2 6.75c-.267-.167-.533-.175-.8-.025s-.4.383-.4.7c0 .167.046.333.138.5a.988.988 0 0 0 .337.375l7 4.375ZM4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v5.525a1.98 1.98 0 0 0-.9.062c-.317.092-.6.263-.85.513l-4.275 4.275-1.45-1.45c-.4-.4-.875-.6-1.425-.6s-1.025.2-1.425.6L10.3 16.3c-.4.4-.6.875-.6 1.425s.2 1.025.6 1.425l.85.85H4Z" })
  }
);
export {
  s as default
};
