import * as React from "react";
const SvgIconUpdate = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.998 4.208v5.29c0 .28-.22.5-.5.5h-5.29c-.45 0-.67-.54-.35-.86l2.09-2.09a7.018 7.018 0 0 0-4.95-2.05c-3.89 0-7.06 3.2-7 7.1.06 3.72 3.19 6.85 6.9 6.9 3.61.05 6.62-2.65 7.05-6.14.06-.49.48-.86.98-.86.6 0 1.07.52 1 1.12-.55 4.44-4.34 7.88-8.93 7.88-5.31 0-9.54-4.6-8.94-10.02.46-4.19 3.91-7.56 8.1-7.94 2.82-.25 5.4.79 7.21 2.6l1.78-1.78c.31-.32.85-.1.85.35Zm-10 8.22v-3.68c0-.41.34-.75.75-.75s.75.34.76.74v3.4l2.87 1.71c.36.21.47.67.26 1.03-.21.35-.67.47-1.03.26l-3.12-1.85c-.3-.18-.49-.51-.49-.86Z"
    />
  </svg>
);
export default SvgIconUpdate;
