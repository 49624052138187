import { jsx as e } from "react/jsx-runtime";
const i = (d) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...d,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M13.5 11.932a6.75 6.75 0 1 1-13.5 0 6.75 6.75 0 1 1 13.5 0Zm7.1 0c0 3.534-1.523 6.4-3.4 6.4-1.878 0-3.4-2.866-3.4-6.4 0-3.535 1.522-6.4 3.4-6.4 1.877 0 3.4 2.865 3.4 6.4Zm1.8 5.88c.829 0 1.5-2.602 1.5-5.812 0-3.21-.671-5.813-1.5-5.813S20.9 8.79 20.9 12c0 3.21.671 5.813 1.5 5.813Z"
      }
    )
  }
);
export {
  i as default
};
