import * as React from "react";
const SvgIconBrokenImage = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 11.59V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v4.58l3 3.01L9.3 9.3a.996.996 0 0 1 1.41 0L14 12.59l3.29-3.3c.39-.39 1.03-.39 1.42 0l2.29 2.3Zm0 2.84-3-3.01-3.3 3.28a.996.996 0 0 1-1.41 0L10 11.41l-3.3 3.3a.996.996 0 0 1-1.41 0L3 12.42V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4.57Z"
    />
  </svg>
);
export default SvgIconBrokenImage;
