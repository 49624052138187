import { jsx as c } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M8 22c-1.383 0-2.563-.488-3.537-1.462C3.487 19.562 3 18.383 3 17V8c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 1 6V4c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 3 2h10c.55 0 1.02.196 1.412.587C14.804 2.98 15 3.45 15 4v2c0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 13 8v3.025a6.627 6.627 0 0 0-1.787 1.688A6.286 6.286 0 0 0 10.175 15H9a.967.967 0 0 1-.713-.287A.968.968 0 0 1 8 14c0-.283.096-.52.287-.713A.967.967 0 0 1 9 13h2v-1H9a.967.967 0 0 1-.713-.287A.968.968 0 0 1 8 11c0-.283.096-.52.287-.713A.967.967 0 0 1 9 10h2V8H5v9c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 8 20c.5 0 .958-.108 1.375-.325a3.029 3.029 0 0 0 1.05-.9c.133.333.283.65.45.95.167.3.367.592.6.875-.45.433-.97.775-1.563 1.025C9.322 21.875 8.683 22 8 22Zm2.175-4H9a.967.967 0 0 1-.713-.288A.968.968 0 0 1 8 17c0-.283.096-.52.287-.712A.967.967 0 0 1 9 16h1.025a5.727 5.727 0 0 0 .15 2Zm6.325 1c.7 0 1.292-.242 1.775-.725.483-.483.725-1.075.725-1.775s-.242-1.292-.725-1.775C17.792 14.242 17.2 14 16.5 14s-1.292.242-1.775.725C14.242 15.208 14 15.8 14 16.5s.242 1.292.725 1.775c.483.483 1.075.725 1.775.725Zm0 2c-1.25 0-2.313-.438-3.188-1.313C12.438 18.813 12 17.75 12 16.5c0-1.25.438-2.313 1.313-3.188C14.187 12.438 15.25 12 16.5 12c1.25 0 2.313.438 3.188 1.313C20.563 14.187 21 15.25 21 16.5c0 .433-.058.85-.175 1.25-.117.4-.292.783-.525 1.15l2 2a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-2-2c-.367.233-.75.408-1.15.525A4.44 4.44 0 0 1 16.5 21Z" })
  }
);
export {
  h as default
};
