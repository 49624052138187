import { jsxs as c, jsx as e } from "react/jsx-runtime";
const a = (l) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M6 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 2h7.175a1.978 1.978 0 0 1 1.4.575l4.85 4.85a1.978 1.978 0 0 1 .575 1.4v4.692A8.721 8.721 0 0 0 17 13c-1.642 0-3.18.423-4.567 1.277A8.313 8.313 0 0 0 9.3 17.692c-.16.298-.234.59-.267.826v.001a3.508 3.508 0 0 0 0 .961v.002c.033.235.106.527.267.826.308.617.68 1.182 1.113 1.692H6Zm8-13h4l-5-5v4c0 .283.096.52.288.712A.965.965 0 0 0 14 9Z"
        }
      ),
      /* @__PURE__ */ e("path", { d: "M15.94 20.02c.291.28.644.42 1.06.42.416 0 .77-.14 1.06-.42.291-.28.437-.62.437-1.02s-.146-.74-.437-1.02a1.474 1.474 0 0 0-1.06-.42c-.416 0-.769.14-1.06.42-.29.28-.436.62-.436 1.02s.145.74.436 1.02Z" }),
      /* @__PURE__ */ e(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M13.48 22.02A6.59 6.59 0 0 0 17 23a6.59 6.59 0 0 0 3.52-.98 6.317 6.317 0 0 0 2.41-2.647.422.422 0 0 0 .056-.167 1.49 1.49 0 0 0 0-.413.42.42 0 0 0-.055-.166 6.313 6.313 0 0 0-2.411-2.647A6.586 6.586 0 0 0 17 15a6.586 6.586 0 0 0-3.52.98 6.313 6.313 0 0 0-2.41 2.647.42.42 0 0 0-.056.166 1.5 1.5 0 0 0 0 .413c.01.067.028.123.055.167a6.317 6.317 0 0 0 2.411 2.647Zm5.287-1.32c-.485.467-1.074.7-1.767.7-.693 0-1.282-.233-1.767-.7a2.272 2.272 0 0 1-.727-1.7c0-.667.242-1.233.727-1.7.485-.467 1.074-.7 1.767-.7.693 0 1.282.233 1.767.7.485.467.727 1.033.727 1.7s-.242 1.233-.727 1.7Z"
        }
      )
    ]
  }
);
export {
  a as default
};
