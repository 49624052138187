import { jsxs as h, jsx as l } from "react/jsx-runtime";
const i = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ l("path", { d: "M1 20.867 11.633 4h6.6v2.347l-9.166 14.52", fill: "#FBBC04" }),
      /* @__PURE__ */ l("path", { d: "M18.233 20.867h-6.6V4h6.6v16.867Z", fill: "#1A73E8" }),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M23 16.1a4.767 4.767 0 0 1-4.767 4.767v-9.534A4.767 4.767 0 0 1 23 16.1Z",
          fill: "#34A853"
        }
      ),
      /* @__PURE__ */ l("path", { d: "M18.233 20.867a4.767 4.767 0 1 1 0-9.534v9.534Z", fill: "#0D652D" }),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M21.9 7.667a3.667 3.667 0 0 1-3.667 3.666V4A3.667 3.667 0 0 1 21.9 7.667Z",
          fill: "#1A73E8"
        }
      ),
      /* @__PURE__ */ l("path", { d: "M18.233 11.333a3.667 3.667 0 1 1 0-7.333v7.333Z", fill: "#174EA6" }),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M1.367 7.3a3.3 3.3 0 1 1 6.6 0 3.3 3.3 0 0 1-6.6 0Z",
          fill: "#EA4335"
        }
      )
    ]
  }
);
export {
  i as default
};
