import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 16c.283 0 .53-.104.738-.312.208-.209.312-.455.312-.738s-.104-.53-.312-.738A1.011 1.011 0 0 0 12 13.9c-.283 0-.529.104-.737.312a1.01 1.01 0 0 0-.313.738c0 .283.104.53.313.738.208.208.454.312.737.312Zm0-3.1c.2 0 .371-.07.513-.212a.943.943 0 0 0 .262-.538c.017-.1.033-.183.05-.25a.981.981 0 0 1 .075-.2c.067-.133.163-.271.288-.413.125-.141.329-.354.612-.637.35-.35.613-.7.788-1.05.175-.35.262-.7.262-1.05 0-.783-.258-1.404-.775-1.862C13.558 6.229 12.867 6 12 6c-.533 0-1.02.12-1.462.362a2.885 2.885 0 0 0-1.063.988.69.69 0 0 0-.075.6.676.676 0 0 0 .45.45c.133.067.292.07.475.012a.854.854 0 0 0 .45-.337c.15-.2.329-.354.537-.463.209-.108.438-.162.688-.162.4 0 .725.108.975.325.25.217.375.492.375.825 0 .283-.062.529-.187.737-.125.209-.346.446-.663.713a5.704 5.704 0 0 0-.675.638 2.347 2.347 0 0 0-.375.562c-.05.117-.087.242-.112.375a4.98 4.98 0 0 0-.063.525c-.017.2.046.375.188.525.141.15.32.225.537.225Zm0 8.725c-.133 0-.267-.025-.4-.075a1.2 1.2 0 0 1-.35-.2C8.817 19.2 7 17.204 5.8 15.363 4.6 13.521 4 11.8 4 10.2c0-2.5.804-4.492 2.413-5.975C8.021 2.742 9.883 2 12 2c2.117 0 3.979.742 5.587 2.225C19.196 5.708 20 7.7 20 10.2c0 1.6-.6 3.321-1.8 5.163-1.2 1.841-3.017 3.837-5.45 5.987a1.2 1.2 0 0 1-.35.2c-.133.05-.267.075-.4.075Z" })
  }
);
export {
  s as default
};
