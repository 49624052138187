/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Jabber = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.622 13.406c-.566-3.605 1.02-5.182 1.02-5.182-1.935 4.024 2.192 6.567 5.37 6.363 2.914-.186 5.38-1.11 7.123-3.76 1.656-2.514 1.615-4.967.058-7.126-1.807-2.513-5.963-2.105-5.963-2.105s3.847-.768 7.52 2.04c3.673 2.806 4.763 7.778 2.757 11.683-2.005 3.905-5.573 5.482-9.385 5.152-2.595 2.165-5.754 2.027-5.754 2.027s1.212-1.133 2.518-2.843c-1.685-.72-4.699-2.645-5.264-6.25Z" />
    <path d="M13.25 2.04c4.46 1.451 3.83 5.884 3.83 5.884-2.723-3.21-6.734-3.731-9.264-3.101-1.448.363-2.477 1.059-3.103 1.483-.43.29-.67.453-.727.292-.117-.324.694-2.22 2.408-3.46 1.644-1.194 4.483-1.872 6.855-1.098Z" />
    <path d="M10.86 14.09s.413-1.608.046-3.323c-.735-3.383-3.241-4.715-5.9-3.605 3.766-2.1 8.885 1.14 9.083 5.506-1.009 1.02-3.23 1.421-3.23 1.421Z" />
    <path d="M3.316 9.543c-.263 2.118.868 2.915.868 2.915 1.47-2.8 5.358-2.327 6.623-1.38-.711-5.403-7.043-5.151-7.491-1.535Z" />
  </svg>
);

export default Jabber;
