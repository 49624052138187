import { jsx as a } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M9.075 20.975c-.783.084-1.73.063-2.837-.062-1.109-.125-1.955-.33-2.538-.613-.233-.533-.43-1.366-.587-2.5-.159-1.133-.196-2.1-.113-2.9l6.075 6.075Zm2.375-.4-8.05-8.05c.283-1.25.696-2.387 1.238-3.412.541-1.025 1.17-1.904 1.887-2.638.717-.716 1.588-1.337 2.613-1.862a13.97 13.97 0 0 1 3.312-1.188l8.1 8.1a14.35 14.35 0 0 1-1.175 3.4c-.517 1.034-1.133 1.909-1.85 2.625-.75.734-1.646 1.363-2.687 1.888a12.87 12.87 0 0 1-3.388 1.137ZM8.7 15.3c.2.2.433.3.7.3a.917.917 0 0 0 .675-.275L15.3 10.1a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7.96.96 0 0 0-.7-.3.918.918 0 0 0-.675.275L8.7 13.9a.948.948 0 0 0-.275.7c0 .284.092.517.275.7Zm12.25-6.15-6.075-6.125c.8-.1 1.783-.083 2.95.05 1.167.134 1.992.342 2.475.625.3.667.508 1.53.625 2.588.117 1.058.125 2.012.025 2.862Z" })
  }
);
export {
  o as default
};
