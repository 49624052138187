import { jsx as h } from "react/jsx-runtime";
const a = (m) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...m,
    children: /* @__PURE__ */ h("path", { d: "m12 20 3.46-6h-.01c.34-.6.55-1.27.55-2 0-1.2-.54-2.27-1.38-3h4.79c.38.93.59 1.94.59 3a8 8 0 0 1-8 8Zm-8-8c0-1.46.39-2.82 1.07-4l3.47 6h.01c.69 1.19 1.95 2 3.45 2 .45 0 .88-.09 1.29-.23l-2.4 4.14C7 19.37 4 16.04 4 12Zm11 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-3-8a7.98 7.98 0 0 1 6.92 4H12c-1.94 0-3.55 1.38-3.92 3.21L5.7 7.08A7.981 7.981 0 0 1 12 4Zm0-2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z" })
  }
);
export {
  a as default
};
