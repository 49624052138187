import { jsx as a } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M2 5a1 1 0 0 0 0 2h9a1 1 0 1 0 0-2H2ZM2 9a1 1 0 0 0 0 2h10.667l3.733 2.8a1 1 0 0 0 .6.2h5a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-5a1 1 0 0 0-.6.2L12.667 9H2ZM1 14a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM2 17a1 1 0 1 0 0 2h9a1 1 0 1 0 0-2H2Z" })
  }
);
export {
  o as default
};
