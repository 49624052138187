import { jsx as h } from "react/jsx-runtime";
const e = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M15.675 11H19V7h-3.325v4Zm-5.35 0h3.325V7h-3.325v4ZM5 11h3.325V7H5v4Zm0 6h3.325v-4H5v4Zm5.325 0h3.325v-4h-3.325v4Zm5.35 0H19v-4h-3.325v4ZM3 17V7c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 5h14c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 19H5c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 17Z" })
  }
);
export {
  e as default
};
