import { jsx as c } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M19.875 22.65 1.35 4.125a.918.918 0 0 1-.275-.675c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275L21.3 21.275a.918.918 0 0 1 .275.675c0 .266-.1.5-.3.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Zm-1.85-7.475L6.85 4h9.175c.55 0 1.021.196 1.413.588.391.39.587.862.587 1.412v4.5l3.15-3.15c.15-.15.33-.188.538-.113.208.075.312.23.312.463v8.6c0 .233-.104.387-.312.462-.209.075-.388.038-.538-.112l-3.15-3.15v1.675ZM4.025 4l14 14c-.017.533-.225 1-.625 1.4-.4.4-.867.6-1.4.6H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.534.204-1 .612-1.4.409-.4.88-.6 1.413-.6Z" })
  }
);
export {
  h as default
};
