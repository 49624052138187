/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Germany = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 3.75H.75v17.1h22.5V3.75H1Z"
      stroke="#000"
      strokeOpacity={0.2}
      strokeWidth={0.5}
    />
    <path d="M1 15.067h22V20.6H1v-5.533Z" fill="#FFCE00" />
    <path d="M1 4h22v5.533H1V4Z" fill="#000" />
    <path d="M1 9.533h22v5.534H1V9.533Z" fill="#D00" />
  </svg>
);

export default Germany;
