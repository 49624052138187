import { jsx as t } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v10l-6 6H5Zm6-7a.968.968 0 0 0 .713-.288A.967.967 0 0 0 12 13a.97.97 0 0 0-.287-.713A.97.97 0 0 0 11 12H8a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 13c0 .283.096.52.287.712.192.192.43.288.713.288h3Zm5-4c.283 0 .52-.096.712-.288A.965.965 0 0 0 17 9a.968.968 0 0 0-.288-.713A.967.967 0 0 0 16 8H8a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 9c0 .283.096.52.287.712.192.192.43.288.713.288h8Zm-2 9 5-5h-4a.967.967 0 0 0-.712.287A.968.968 0 0 0 14 15v4Z" })
  }
);
export {
  c as default
};
