import { jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "m21.6 18.75-1.45-1.4c.283-.234.496-.504.638-.812.141-.31.212-.655.212-1.038 0-.7-.242-1.292-.725-1.775C19.792 13.24 19.2 13 18.5 13H17v-2c0-1.384-.487-2.563-1.462-3.538C14.563 6.487 13.383 6 12 6c-.45 0-.883.054-1.3.162-.417.108-.817.28-1.2.513l-1.45-1.45a7.038 7.038 0 0 1 1.863-.913A6.884 6.884 0 0 1 12 4c1.95 0 3.604.679 4.962 2.037C18.321 7.395 19 9.05 19 11c1.15.133 2.104.629 2.863 1.487A4.41 4.41 0 0 1 23 15.5a4.38 4.38 0 0 1-1.4 3.25Zm-2.5 3.15L17.15 20H6.5c-1.533 0-2.833-.534-3.9-1.6C1.533 17.333 1 16.033 1 14.5c0-1.284.396-2.425 1.188-3.425A5.284 5.284 0 0 1 5.25 9.15c.05-.134.1-.263.15-.388.05-.125.1-.262.15-.412L2.1 4.9a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l17 17a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.934.934 0 0 1-.688.275c-.275 0-.512-.092-.712-.275ZM6.5 18h8.65L7.1 9.95a6.625 6.625 0 0 0-.075.525A5.28 5.28 0 0 0 7 11h-.5c-.967 0-1.792.341-2.475 1.025A3.372 3.372 0 0 0 3 14.5c0 .966.342 1.791 1.025 2.475A3.372 3.372 0 0 0 6.5 18Z" })
  }
);
export {
  h as default
};
