/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftLibrary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.2 8.4V4.8a1.2 1.2 0 0 1 1.2-1.2h6.206a2.4 2.4 0 0 1 1.697.703L12 6l-1.697 1.697a2.4 2.4 0 0 1-1.697.703H1.2Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m12.9 6-2.297 2.297A2.4 2.4 0 0 1 8.906 9H1.2v10.2a1.2 1.2 0 0 0 1.2 1.2h1.788v.6a.5.5 0 0 0 .5.5h3.694a.5.5 0 0 0 .5-.5v-.6h6.292v.6a.5.5 0 0 0 .5.5h3.638a.5.5 0 0 0 .5-.5v-.6H21.6a1.2 1.2 0 0 0 1.2-1.2v-12A1.2 1.2 0 0 0 21.6 6h-8.7Zm-7.712 8a1.5 1.5 0 0 1 1.5-1.5h10.624a1.5 1.5 0 0 1 1.5 1.5v6.5h-2.638v-4.953a.5.5 0 0 0-.5-.5H8.382a.5.5 0 0 0-.5.5V20.5H5.188V14Z"
    />
  </svg>
);

export default MicrosoftLibrary;
