import * as React from "react";
const SvgIconGetApp = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 9.5h1.59c.89 0 1.33 1.08.7 1.71L12.7 15.8a.996.996 0 0 1-1.41 0L6.7 11.21c-.63-.63-.18-1.71.71-1.71H9v-5c0-.55.45-1 1-1h4c.55 0 1 .45 1 1v5Zm-9 11c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1H6Z"
    />
  </svg>
);
export default SvgIconGetApp;
