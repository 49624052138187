import * as e from "react";
const a = (l) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13.8 7.4a1 1 0 0 0-1.6 1.2l2.55 3.4-2.55 3.4a1 1 0 0 0 1.6 1.2l3-4a1 1 0 0 0 0-1.2l-3-4Zm-5 0a1 1 0 1 0-1.6 1.2L9.75 12 7.2 15.4a1 1 0 0 0 1.6 1.2l3-4a1 1 0 0 0 0-1.2l-3-4Z"
    }
  )
);
export {
  a as default
};
