import { jsx as a } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ a("path", { d: "M6 17a.968.968 0 0 1-.713-.288A.967.967 0 0 1 5 16V8a.97.97 0 0 1 .287-.713A.97.97 0 0 1 6 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 7 8v3h4V8c0-.283.096-.521.288-.713A.967.967 0 0 1 12 7a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 8v8c0 .283-.096.52-.287.712A.968.968 0 0 1 12 17a.965.965 0 0 1-.712-.288A.965.965 0 0 1 11 16v-3H7v3c0 .283-.096.52-.287.712A.968.968 0 0 1 6 17Zm12 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 17 16V9h-1a.965.965 0 0 1-.712-.288A.965.965 0 0 1 15 8c0-.283.096-.521.288-.713A.967.967 0 0 1 16 7h2c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .283-.096.52-.288.712A.965.965 0 0 1 18 17Z" })
  }
);
export {
  h as default
};
