import * as e from "react";
const t = (l) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M13.015 2.65c-.2-.2-.51-.2-.71 0l-2.01 2.01h4.72l-2-2.01Zm-.7 18.7c.2.2.51.2.71 0l1.98-1.98h-4.66l1.97 1.98Zm-.46-4.78a.847.847 0 0 1-.79 1.16c-.35 0-.67-.22-.8-.56l-.71-1.9h-5.11l-.71 1.9c-.13.33-.45.55-.8.55-.6 0-1.01-.6-.79-1.16l3.74-9.53a1.205 1.205 0 0 1 2.24 0l3.73 9.54Zm-6.8-2.93 1.94-5.18 1.94 5.18h-3.88Zm10.75 2.5h5.33c.44 0 .8.36.78.81 0 .43-.35.79-.79.79h-6.72c-.8 0-1.28-.91-.82-1.57l5.73-8.28h-5.09a.8.8 0 0 1-.79-.8v-.01c0-.44.35-.8.79-.8h6.48c.8 0 1.28.91.82 1.57l-5.72 8.29Z"
    }
  )
);
export {
  t as default
};
