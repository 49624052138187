import { jsx as t } from "react/jsx-runtime";
const l = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ t("path", { d: "M5 19c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 17V7c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 5 5h10c.333 0 .646.075.938.225.291.15.529.358.712.625l3.525 5c.233.35.35.733.35 1.15 0 .417-.117.8-.35 1.15l-3.525 5c-.183.267-.42.475-.712.625-.292.15-.605.225-.938.225H5Zm13.55-7L15 7H5v10h10l3.55-5ZM5 12v5V7v5Z" })
  }
);
export {
  l as default
};
