/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Sweden = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 3.75H.75v17.1h22.5V3.75H1Z"
      stroke="#000"
      strokeOpacity={0.2}
      strokeWidth={0.5}
    />
    <path d="M1 4h22v16.6H1V4Z" fill="#066AA7" />
    <path d="M1 10.64h22v3.32H1v-3.32Z" fill="#FECC00" />
    <path d="M7.05 4h3.3v16.6h-3.3V4Z" fill="#FECC00" />
  </svg>
);

export default Sweden;
