import { jsx as l } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ l("path", { d: "M4 24c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 22c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 4 20h16c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413s-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 24H4Zm10.6-12L12 9.4l-4 4 2.575 2.6 4.025-4Zm-1.175-4L16 10.575 20 6.6 17.4 4l-3.975 4Zm-2.1-.725 5.4 5.4L12 17.425c-.4.4-.87.6-1.412.6-.542 0-1.013-.2-1.413-.6l-.125.1c-.183.15-.383.267-.6.35a1.865 1.865 0 0 1-.675.125h-3.05c-.233 0-.396-.1-.487-.3-.092-.2-.055-.383.112-.55l2.3-2.275c-.4-.4-.608-.88-.625-1.438A1.876 1.876 0 0 1 6.6 12l4.725-4.725Zm0 0L16 2.6c.4-.4.87-.6 1.413-.6.541 0 1.012.2 1.412.6l2.6 2.575c.4.4.6.87.6 1.413 0 .541-.2 1.012-.6 1.412l-4.7 4.675-5.4-5.4Z" })
  }
);
export {
  h as default
};
