import { basicDesignTokens } from "../DesignTokens/designTokens";

const { font } = basicDesignTokens;
export const fontWeight = {
  regular: font.weight.regular,
  medium: font.weight.medium,
  semiBold: font.weight["semi-bold"],
  bold: font.weight.bold,
} as const;

type FontVariantObject = {
  fontSize: string;
  lineHeight: string;
  fontFamily: string;
  fontWeight?: string;
  letterSpacing?: string;
};

export const fontVariant = {
  captionSm: {
    fontSize: font.size["caption-sm"],
    lineHeight: "1.6",
    fontFamily: "IBM Plex Sans",
  },
  captionLg: {
    fontSize: font.size["caption-lg"],
    lineHeight: "1.333",
    fontFamily: "IBM Plex Sans",
  },
  bodySm: {
    fontSize: font.size["body-sm"],
    lineHeight: "1.428",
    fontFamily: "IBM Plex Sans",
  },
  bodyLg: {
    fontSize: font.size["body-lg"],
    lineHeight: "1.5",
    fontFamily: "IBM Plex Sans",
  },
  subtitleSm: {
    fontSize: font.size["subtitle-sm"],
    lineHeight: "1.444",
    fontFamily: "IBM Plex Sans",
  },
  subtitleLg: {
    fontSize: font.size["subtitle-lg"],
    lineHeight: "1.444",
    fontFamily: "IBM Plex Sans",
  },
  titleSm: {
    fontSize: font.size["title-sm"],
    lineHeight: "1.333",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "-0.5px",
  },
  titleMd: {
    fontSize: font.size["title-md"],
    lineHeight: "1.25",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "-1.5px",
  },
  titleLg: {
    fontSize: font.size["title-lg"],
    lineHeight: "1.333",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "-1.5px",
  },
  titleXl: {
    fontSize: font.size["title-xl"],
    lineHeight: "1.25",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "-2px",
  },
  codeSm: {
    fontSize: font.size["code-sm"],
    lineHeight: "1.333",
    fontFamily: "IBM Plex Mono",
    fontWeight: font.weight.regular,
  },
  codeMd: {
    fontSize: font.size["code-md"],
    lineHeight: "1.428",
    fontFamily: "IBM Plex Mono",
    fontWeight: font.weight.regular,
  },
  codeLg: {
    fontSize: font.size["code-lg"],
    lineHeight: "1.5",
    fontFamily: "IBM Plex Mono",
    fontWeight: font.weight.regular,
  },
} as const;

/**
 * this is the "font" shorthand property.
 * example: "400 16px/1.5 IBM Plex Mono"
 *
 * see: https://www.w3schools.com/css/css_font_shorthand.asp
 */
const fontToCssShorthand = (fv: FontVariantObject) => {
  const { fontWeight, fontSize, lineHeight, fontFamily } = fv;
  return `${fontWeight ?? ""} ${fontSize}/${lineHeight} ${fontFamily}`.trim();
};

/**
 * to use fonts as CSS one-liners
 */
export const fontVariantShorthands = Object.fromEntries(
  Object.entries(fontVariant as { [key: string]: FontVariantObject })
    .map(([fvName, fv]) =>
      // letter spacing doesn't appear in the font shorthand, so it can't be a one-liner
      fv.letterSpacing ? undefined : [fvName, fontToCssShorthand(fv)]
    )
    .filter((entry): entry is [string, string] => !!entry)
);

// we add extra variable names that aren't in the export - numbers and basic sizes
export const fontSize = {
  "0": fontVariant.captionSm.fontSize,
  captionSm: fontVariant.captionSm.fontSize,
  "1": fontVariant.captionLg.fontSize,
  captionLg: fontVariant.captionLg.fontSize,
  "2": fontVariant.bodySm.fontSize,
  bodySm: fontVariant.bodySm.fontSize,
  "3": fontVariant.bodyLg.fontSize,
  bodyLg: fontVariant.bodyLg.fontSize,
  "4": fontVariant.subtitleSm.fontSize,
  subtitleSm: fontVariant.subtitleSm.fontSize,
  "5": fontVariant.subtitleLg.fontSize,
  subtitleLg: fontVariant.subtitleLg.fontSize,
  "6": fontVariant.titleSm.fontSize,
  titleSm: fontVariant.titleSm.fontSize,
  "7": fontVariant.titleMd.fontSize,
  titleMd: fontVariant.titleMd.fontSize,
  "8": fontVariant.titleLg.fontSize,
  titleLg: fontVariant.titleLg.fontSize,
  "9": fontVariant.titleXl.fontSize,
  titleXl: fontVariant.titleXl.fontSize,
  codeSm: fontVariant.codeSm.fontSize,
  codeMd: fontVariant.codeMd.fontSize,
  codeLg: fontVariant.codeLg.fontSize,
} as const;
