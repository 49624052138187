import { jsx as t } from "react/jsx-runtime";
const a = (s) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ t("path", { d: "M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14ZM12 2c4.2 0 8 3.22 8 8.2 0 3.183-2.454 6.927-7.354 11.24a.979.979 0 0 1-1.292 0C6.454 17.127 4 13.383 4 10.2 4 5.22 7.8 2 12 2Z" })
  }
);
export {
  a as default
};
