import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "m10.95 13.7-1.4-1.425A1.035 1.035 0 0 0 8.825 12a.948.948 0 0 0-.7.275c-.2.2-.304.438-.313.712a.933.933 0 0 0 .288.713l2.15 2.15a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l4.25-4.25a.948.948 0 0 0 .275-.7c0-.283-.092-.525-.275-.725-.2-.183-.437-.28-.712-.288a.933.933 0 0 0-.713.288L10.95 13.7ZM12 22c-1.25 0-2.42-.237-3.512-.712a9.142 9.142 0 0 1-2.85-1.926 9.143 9.143 0 0 1-1.926-2.85A8.709 8.709 0 0 1 3 13c0-1.25.237-2.42.712-3.513a9.159 9.159 0 0 1 1.926-2.85 9.138 9.138 0 0 1 2.85-1.924A8.698 8.698 0 0 1 12 4c1.25 0 2.421.238 3.513.713a9.152 9.152 0 0 1 2.85 1.924 9.167 9.167 0 0 1 1.925 2.85A8.715 8.715 0 0 1 21 13c0 1.25-.237 2.42-.712 3.512a9.151 9.151 0 0 1-1.925 2.85 9.156 9.156 0 0 1-2.85 1.926A8.715 8.715 0 0 1 12 22ZM3.425 7.325a.89.89 0 0 1-.687.262.976.976 0 0 1-.688-.287.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7l2.875-2.875a.894.894 0 0 1 .688-.263.978.978 0 0 1 .687.288.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L3.425 7.325ZM20.55 7.3l-2.875-2.875a.891.891 0 0 1-.262-.688.975.975 0 0 1 .287-.687.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.875 2.875a.893.893 0 0 1 .263.687.979.979 0 0 1-.288.688.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Z" })
  }
);
export {
  t as default
};
