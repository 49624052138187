import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M16.175 7.35a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l1.4-1.4 1.4 1.4a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7l-1.4-1.4 1.4-1.4a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-1.4 1.4-1.4-1.4a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275.948.948 0 0 0-.275.7c0 .283.092.516.275.7l1.4 1.4-1.4 1.4a.948.948 0 0 0-.275.7c0 .283.092.516.275.7ZM12 12c.55 0 1.021-.196 1.413-.588.391-.392.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413A1.928 1.928 0 0 0 12 8c-.55 0-1.02.195-1.412.587A1.927 1.927 0 0 0 10 10c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Zm0 9.625c-.133 0-.267-.025-.4-.075a1.2 1.2 0 0 1-.35-.2C8.817 19.2 7 17.204 5.8 15.363 4.6 13.52 4 11.8 4 10.2c0-2.5.804-4.492 2.413-5.975C8.021 2.74 9.883 2 12 2c.5 0 .992.045 1.475.137.483.092.967.23 1.45.413.017.2.067.412.15.637.083.225.225.438.425.638l.675.675-.675.675c-.433.433-.625.962-.575 1.587.05.625.325 1.113.825 1.463.383.283.829.395 1.337.337.509-.058.946-.27 1.313-.637l.6-.6.675.675.038.037c.008.008.02.013.037.013.083.35.146.708.188 1.075.041.366.062.725.062 1.075 0 1.6-.6 3.32-1.8 5.163-1.2 1.84-3.017 3.837-5.45 5.987a1.2 1.2 0 0 1-.35.2c-.133.05-.267.075-.4.075Z" })
  }
);
export {
  t as default
};
