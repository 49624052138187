/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Portugal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.8 4H23v16.6H9.8V4Z" fill="red" />
    <path d="M1 4h8.8v16.6H1V4Z" fill="#060" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.67 14.59c-1.11-.035-6.187-3.224-6.222-3.736l.279-.466c.505.736 5.696 3.838 6.208 3.728l-.265.473Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.668 10.322c-.1.27 1.327 1.155 3.039 2.206 1.715 1.048 3.193 1.695 3.3 1.605l.052-.097c-.021.035-.07.045-.148.02-.464-.134-1.67-.69-3.166-1.604-1.499-.913-2.798-1.753-3.001-2.11a.22.22 0 0 1-.02-.107h-.008l-.04.076-.008.01Zm6.026 4.281c-.017.035-.055.035-.12.028-.413-.045-1.67-.66-3.16-1.556-1.732-1.045-3.162-1.992-3.004-2.241l.042-.076.007.003c-.138.422 2.822 2.123 2.997 2.234 1.712 1.065 3.156 1.691 3.283 1.529l-.045.08Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.807 11.166c1.107-.01 2.475-.153 3.266-.47l-.172-.277c-.464.259-1.84.432-3.104.456-1.492-.014-2.547-.155-3.077-.512l-.158.298c.97.415 1.966.501 3.245.505Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.117 10.702c-.027.045-.543.222-1.3.353-.666.102-1.34.151-2.014.149-.646.002-1.291-.04-1.931-.125-.795-.124-1.204-.297-1.358-.36l.038-.075c.436.172.849.276 1.33.352a14.064 14.064 0 0 0 3.922-.02c.774-.128 1.196-.291 1.258-.364l.055.094v-.004Zm-.151-.28c-.083.07-.502.218-1.238.336-.635.092-1.276.138-1.918.138-.756 0-1.378-.055-1.85-.125-.748-.096-1.147-.276-1.292-.325l.045-.076c.114.06.495.215 1.255.322.61.082 1.226.121 1.842.117.638 0 1.274-.045 1.905-.138.739-.104 1.137-.29 1.2-.339l.05.09Zm-6.8 2.086c.681.37 2.197.553 3.63.567 1.307.003 3.005-.2 3.64-.54l-.016-.37c-.2.311-2.022.612-3.637.602-1.616-.014-3.118-.263-3.62-.588v.329"
      fill="#FF0"
    />
    <path
      d="M6.167 12.508c.68.37 2.196.553 3.63.567 1.306.003 3.004-.2 3.64-.54l-.017-.37c-.2.311-2.022.612-3.637.602-1.616-.014-3.118-.263-3.62-.588v.329m6.954-1.806c-.027.045-.543.222-1.3.353-.666.102-1.34.151-2.014.149-.646.002-1.291-.04-1.931-.125-.795-.124-1.204-.297-1.358-.36l.038-.075c.436.172.849.276 1.33.352a14.064 14.064 0 0 0 3.922-.02c.774-.128 1.196-.291 1.258-.364l.055.094v-.004Zm-.151-.28c-.083.07-.502.218-1.238.336-.635.092-1.276.138-1.918.138-.756 0-1.378-.055-1.85-.125-.748-.096-1.147-.276-1.292-.325l.045-.076c.114.06.495.215 1.255.322.61.082 1.226.121 1.842.117.638 0 1.274-.045 1.905-.138.739-.104 1.137-.29 1.2-.339l.05.09Z"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.471 12.456v.086c-.096.118-.694.29-1.443.415-.745.108-1.496.159-2.248.152a13.67 13.67 0 0 1-2.132-.148 5.304 5.304 0 0 1-1.526-.415v-.1c.334.22 1.234.387 1.537.435.543.083 1.24.145 2.12.145.925 0 1.664-.065 2.235-.152.54-.08 1.306-.283 1.457-.418Zm0-.312v.087c-.096.114-.694.287-1.443.411-.745.11-1.496.161-2.248.156a14.153 14.153 0 0 1-2.132-.149 5.304 5.304 0 0 1-1.526-.415v-.103c.334.224 1.238.387 1.537.435a13.94 13.94 0 0 0 2.12.15c.925 0 1.668-.07 2.235-.157.54-.076 1.306-.28 1.457-.415Zm-3.678 2.38c-1.567-.007-2.911-.43-3.197-.498l.207.325a8.541 8.541 0 0 0 3.004.494c1.193-.034 2.234-.128 2.967-.487l.213-.34c-.499.24-2.2.506-3.197.506"
      fill="#FF0"
    />
    <path
      d="M9.793 14.524c-1.567-.007-2.911-.43-3.197-.498l.207.325a8.541 8.541 0 0 0 3.004.494c1.193-.034 2.234-.128 2.967-.487l.213-.34c-.499.24-2.2.506-3.197.506m3.681-2.068v.086c-.096.118-.694.29-1.443.415-.745.108-1.496.159-2.248.152a13.67 13.67 0 0 1-2.132-.148 5.304 5.304 0 0 1-1.526-.415v-.1c.334.22 1.234.387 1.537.435.543.083 1.24.145 2.12.145.925 0 1.664-.065 2.235-.152.54-.08 1.306-.283 1.457-.418Zm0-.312v.087c-.096.114-.694.287-1.443.411-.745.11-1.496.161-2.248.156a14.153 14.153 0 0 1-2.132-.149 5.304 5.304 0 0 1-1.526-.415v-.103c.334.224 1.238.387 1.537.435a13.94 13.94 0 0 0 2.12.15c.925 0 1.668-.07 2.235-.157.54-.076 1.306-.28 1.457-.415Z"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.856 14.282a5.02 5.02 0 0 1-.097.138 7.698 7.698 0 0 1-1.12.29 10.1 10.1 0 0 1-1.846.173c-1.389-.02-2.527-.293-3.06-.529l-.044-.072.007-.014.072.031A9.793 9.793 0 0 0 9.8 14.8c.646 0 1.289-.072 1.808-.166.797-.162 1.12-.283 1.22-.338l.024-.014h.004Zm.182-.305-.069.121c-.185.07-.687.215-1.42.318-.48.066-.78.132-1.739.15a11.875 11.875 0 0 1-3.238-.485l-.038-.086c1.07.297 2.17.46 3.28.484.876-.018 1.25-.083 1.729-.149.852-.131 1.282-.277 1.409-.315a.08.08 0 0 0 0-.007l.09-.034-.004.003Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.059 12.217c.003 1.037-.526 1.971-.949 2.38a3.403 3.403 0 0 1-2.33.975c-1.042.017-2.022-.664-2.287-.965a3.504 3.504 0 0 1-.945-2.331c.062-1.135.505-1.923 1.145-2.466A3.411 3.411 0 0 1 9.9 9.025a3.36 3.36 0 0 1 2.44 1.231c.43.526.619 1.096.719 1.961ZM9.786 8.669c.962 0 1.885.382 2.567 1.064a3.677 3.677 0 0 1 1.077 2.574 3.675 3.675 0 0 1-1.064 2.585 3.633 3.633 0 0 1-2.57 1.07 3.614 3.614 0 0 1-2.569-1.07 3.658 3.658 0 0 1-1.064-2.585 3.633 3.633 0 0 1 3.623-3.638Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.796 8.651c2.001 0 3.63 1.64 3.63 3.652 0 2.013-1.629 3.652-3.626 3.652-1.997 0-3.63-1.639-3.63-3.652 0-2.012 1.63-3.652 3.63-3.652h-.004ZM6.246 12.3c0 1.964 1.605 3.572 3.55 3.572 1.946 0 3.551-1.608 3.551-3.572s-1.605-3.573-3.55-3.573c-1.946 0-3.551 1.609-3.551 3.573Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.8 8.956c.88.003 1.725.356 2.348.982.622.627.973 1.476.976 2.362a3.368 3.368 0 0 1-.976 2.363 3.328 3.328 0 0 1-2.348.985c-1.822 0-3.324-1.508-3.324-3.348a3.368 3.368 0 0 1 .978-2.36A3.328 3.328 0 0 1 9.8 8.955ZM6.555 12.3c0 1.798 1.465 3.265 3.245 3.265s3.245-1.47 3.245-3.265c0-1.798-1.464-3.265-3.245-3.265a3.249 3.249 0 0 0-2.29.96 3.288 3.288 0 0 0-.955 2.305Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.948 8.634h-.313v7.342h.31l.003-7.342Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.913 8.593h.08v7.425h-.076V8.593h-.004Zm-.309 0h.083v7.425h-.08V8.593h-.003Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.43 12.445v-.27l-.22-.207-1.248-.332-1.794-.183-2.166.11-1.54.367-.31.232v.273l.788-.356 1.87-.294h1.798l1.32.145.914.221.588.294Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.8 11.74c.856 0 1.685.08 2.348.207.68.139 1.158.312 1.323.502v.097c-.199-.242-.842-.415-1.34-.52a12.87 12.87 0 0 0-2.334-.207c-.897 0-1.736.09-2.382.215-.516.103-1.207.311-1.293.512v-.1c.045-.14.56-.347 1.282-.495.65-.128 1.489-.211 2.393-.211H9.8Zm0-.315c.787-.002 1.573.068 2.348.208.68.138 1.158.31 1.323.505v.093c-.199-.239-.842-.415-1.34-.515a12.563 12.563 0 0 0-2.334-.208c-.798-.002-1.594.07-2.379.215-.498.093-1.217.307-1.296.508v-.097c.048-.138.57-.356 1.282-.495a12.8 12.8 0 0 1 2.396-.214Zm-.02-1.598c1.35-.007 2.53.19 3.07.467l.195.346c-.467-.256-1.74-.519-3.262-.484-1.24.01-2.568.138-3.231.498l.234-.394c.546-.287 1.832-.433 2.994-.433Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.8 10.11c.705-.011 1.41.039 2.107.15.55.103 1.076.255 1.152.338l.058.104c-.182-.117-.64-.252-1.224-.363-.584-.11-1.33-.149-2.097-.145-.87-.004-1.546.041-2.124.145a3.726 3.726 0 0 0-1.145.356l.059-.107c.206-.104.526-.232 1.069-.332.601-.11 1.285-.142 2.145-.145Zm0-.31c.736-.007 1.464.034 2.031.138.368.055.724.172 1.052.346l.086.138c-.144-.162-.687-.318-1.172-.401-.564-.1-1.261-.138-1.997-.145a12.331 12.331 0 0 0-2.045.152c-.354.05-.698.156-1.018.315l.076-.115c.199-.103.522-.2.928-.28A12.2 12.2 0 0 1 9.8 9.796V9.8Zm1.801 4.022a9.45 9.45 0 0 0-1.805-.139c-2.251.028-2.976.467-3.066.599l-.172-.277c.578-.415 1.802-.65 3.252-.63.753.014 1.403.066 1.946.173l-.155.277"
      fill="#FF0"
    />
    <path
      d="M11.601 13.822a9.45 9.45 0 0 0-1.805-.139c-2.251.028-2.976.467-3.066.599l-.172-.277c.578-.415 1.802-.65 3.252-.63.753.014 1.403.066 1.946.173l-.155.277M9.8 10.111c.705-.012 1.41.038 2.107.149.55.103 1.076.255 1.152.338l.058.104c-.182-.117-.64-.252-1.224-.363-.584-.11-1.33-.149-2.097-.145-.87-.004-1.546.041-2.124.145a3.726 3.726 0 0 0-1.145.356l.059-.107c.206-.104.526-.232 1.069-.332.601-.11 1.285-.142 2.145-.145Zm0-.311c.736-.007 1.464.034 2.031.138.368.055.724.172 1.052.346l.086.138c-.144-.162-.687-.318-1.172-.401-.564-.1-1.261-.138-1.997-.145a12.331 12.331 0 0 0-2.045.152c-.354.05-.698.156-1.018.315l.076-.115c.199-.103.522-.2.928-.28A12.2 12.2 0 0 1 9.8 9.796V9.8Z"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.786 13.645c.626.01 1.238.035 1.832.146l-.041.076c-.55-.104-1.141-.139-1.787-.139-.836-.007-1.675.073-2.407.284-.23.066-.612.214-.653.339l-.041-.07c.014-.075.24-.228.674-.345.838-.242 1.622-.287 2.423-.29Zm.028-.318c.66 0 1.319.057 1.97.173l-.045.08a10.213 10.213 0 0 0-1.925-.17c-.832 0-1.716.062-2.52.298-.258.076-.708.242-.722.37l-.041-.076c.007-.118.395-.274.746-.374.808-.239 1.694-.297 2.537-.3Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m13.01 14.046-.268.426-.78-.695-2.014-1.366-2.276-1.256-1.179-.404.251-.47.086-.046.732.184 2.42 1.255 1.396.885 1.172.848.478.553-.017.086Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.452 10.761c.206-.138 1.726.54 3.32 1.508 1.585.968 3.105 2.061 2.967 2.265l-.045.073-.02.017c.003-.004.027-.035 0-.107-.069-.225-1.148-1.09-2.932-2.176-1.743-1.04-3.194-1.67-3.335-1.49l.045-.09Zm6.614 3.282c.13-.263-1.28-1.332-3.029-2.372-1.787-1.02-3.08-1.622-3.317-1.443l-.048.1c0 .004 0-.006.014-.017.04-.034.113-.034.144-.034.406.007 1.564.543 3.19 1.48.715.415 3.011 1.902 3.001 2.317 0 .035.003.042-.01.062l.058-.09-.003-.003Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.622 12.707a2.176 2.176 0 0 0 .642 1.538 2.162 2.162 0 0 0 1.532.65c.578 0 1.132-.231 1.54-.642a2.178 2.178 0 0 0 .638-1.542V9.789l-4.352-.007v2.925Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M7.703 12.71a2.092 2.092 0 0 0 .616 1.476c.39.395.92.618 1.474.62a2.08 2.08 0 0 0 1.474-.612 2.08 2.08 0 0 0 .612-1.476v-2.84H7.71v2.833m3.337-1.981v1.804l-.004.188a1.232 1.232 0 0 1-.367.885c-.233.235-.55.368-.88.369a1.23 1.23 0 0 1-.876-.376 1.258 1.258 0 0 1-.367-.886v-1.992l2.494.008Z"
      fill="red"
    />
    <path
      d="M7.703 12.71a2.092 2.092 0 0 0 .616 1.476c.39.395.92.618 1.474.62a2.08 2.08 0 0 0 1.474-.612 2.08 2.08 0 0 0 .612-1.476v-2.84H7.71v2.833m3.337-1.981v1.804l-.004.188a1.232 1.232 0 0 1-.367.885c-.233.235-.55.368-.88.369a1.23 1.23 0 0 1-.876-.376 1.258 1.258 0 0 1-.367-.886v-1.992l2.494.008Z"
      stroke="#000"
      strokeWidth={0.05}
    />
    <mask id="portugal_svg__a" fill="#fff">
      <path d="M7.974 10.619c.004-.203.147-.25.147-.25.003 0 .157.05.157.254h-.304" />
    </mask>
    <path
      d="M7.974 10.619c.004-.203.147-.25.147-.25.003 0 .157.05.157.254h-.304"
      fill="#FF0"
    />
    <path
      d="M8.12 10.368v-.05h-.007l-.008.003.016.047Zm.158.255v.05h.05v-.05h-.05Zm-.254-.003a.236.236 0 0 1 .06-.168.172.172 0 0 1 .053-.037l-.016-.047-.016-.047h-.001l-.003.001-.006.003a.276.276 0 0 0-.082.058.335.335 0 0 0-.089.235l.1.002Zm.097-.202h-.01l-.002-.001h-.001.002a.19.19 0 0 1 .056.038c.03.03.062.08.062.168h.1a.323.323 0 0 0-.092-.24.289.289 0 0 0-.098-.062l-.004-.001h-.003a.05.05 0 0 0-.01-.002v.1Zm.157.155h-.304v.1h.304v-.1Z"
      fill="#000"
      mask="url(#portugal_svg__a)"
    />
    <path
      d="m7.85 10.372-.026.232h.154c0-.192.146-.221.146-.221.004 0 .147.04.15.221h.155l-.03-.236h-.55v.004Zm-.037.236h.623c.01 0 .022.011.022.026 0 .018-.011.03-.022.03h-.623c-.011 0-.022-.012-.022-.03 0-.015.01-.026.025-.026h-.003Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M8.04 10.604c0-.121.084-.155.084-.155s.085.037.085.155h-.17Zm-.213-.332h.598c.01 0 .022.015.022.03 0 .01-.011.022-.022.022h-.598c-.01 0-.022-.011-.022-.026 0-.011.011-.022.022-.022v-.004Zm.015.056h.565c.01 0 .022.01.022.025 0 .015-.011.026-.022.026h-.569c-.014 0-.022-.01-.022-.026 0-.014.008-.025.022-.025h.004Zm.183-.391h.044v.03h.033v-.03h.048v.033h.033v-.037h.044v.074c0 .014-.007.022-.018.022h-.162c-.01 0-.022-.008-.022-.019v-.073Zm.169.1.01.235h-.157l.011-.24h.136"
      fill="#FF0"
    />
    <path
      d="m8.194 10.036.01.236h-.157l.011-.24h.136m-.154.572c0-.121.084-.155.084-.155s.085.037.085.155h-.17Zm-.213-.332h.598c.01 0 .022.015.022.03 0 .01-.011.022-.022.022h-.598c-.01 0-.022-.011-.022-.026 0-.011.011-.022.022-.022v-.004Zm.015.056h.565c.01 0 .022.01.022.025 0 .015-.011.026-.022.026h-.569c-.014 0-.022-.01-.022-.026 0-.014.008-.025.022-.025h.004Zm.183-.391h.044v.03h.033v-.03h.048v.033h.033v-.037h.044v.074c0 .014-.007.022-.018.022h-.162c-.01 0-.022-.008-.022-.019v-.073Z"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M8.003 10.147v.125h-.147v-.125h.147ZM8.392 10.147v.125h-.147v-.125h.147ZM7.831 10.051h.044v.037h.033v-.037h.044v.037h.033v-.037h.044v.074c0 .015-.007.022-.018.022h-.158a.022.022 0 0 1-.022-.022v-.074ZM8.22 10.051h.044v.037h.033v-.037h.044v.037h.033v-.037h.044v.074c0 .015-.008.022-.019.022h-.157a.022.022 0 0 1-.022-.022v-.074Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <mask id="portugal_svg__b" fill="#fff">
      <path d="M8.11 10.11c0-.022.033-.022.033 0v.059H8.11v-.059Z" />
    </mask>
    <path d="M8.11 10.11c0-.022.033-.022.033 0v.059H8.11v-.059Z" fill="#000" />
    <path
      d="M8.143 10.169v.05h.05v-.05h-.05Zm-.033 0h-.05v.05h.05v-.05Zm.05-.059a.034.034 0 0 1-.034.033.034.034 0 0 1-.034-.033h.1a.066.066 0 0 0-.066-.067.066.066 0 0 0-.066.067h.1Zm-.067 0v.059h.1v-.059h-.1Zm.05.009H8.11v.1h.033v-.1Zm.017.05v-.059h-.1v.059h.1Z"
      fill="#000"
      mask="url(#portugal_svg__b)"
    />
    <mask id="portugal_svg__c" fill="#fff">
      <path d="M7.916 10.191c0-.022.029-.022.029 0v.044h-.03v-.044Z" />
    </mask>
    <path
      d="M7.916 10.191c0-.022.029-.022.029 0v.044h-.03v-.044Z"
      fill="#000"
    />
    <path
      d="M7.945 10.235v.05h.05v-.05h-.05Zm-.03 0h-.05v.05h.05v-.05Zm.05-.044a.033.033 0 0 1-.01.023.036.036 0 0 1-.05 0 .033.033 0 0 1-.01-.023h.1a.067.067 0 0 0-.02-.048.064.064 0 0 0-.09 0 .067.067 0 0 0-.02.048h.1Zm-.07 0v.044h.1v-.044h-.1Zm.05-.006h-.03v.1h.03v-.1Zm.02.05v-.044h-.1v.044h.1Z"
      fill="#000"
      mask="url(#portugal_svg__c)"
    />
    <mask id="portugal_svg__d" fill="#fff">
      <path d="M8.304 10.191c0-.022.03-.022.03 0v.044h-.03v-.044Z" />
    </mask>
    <path d="M8.304 10.191c0-.022.03-.022.03 0v.044h-.03v-.044Z" fill="#000" />
    <path
      d="M8.334 10.235v.05h.05v-.05h-.05Zm-.03 0h-.05v.05h.05v-.05Zm.05-.044a.033.033 0 0 1-.01.023.036.036 0 0 1-.05 0 .033.033 0 0 1-.01-.023h.1a.067.067 0 0 0-.02-.048.064.064 0 0 0-.09 0 .067.067 0 0 0-.02.048h.1Zm-.07 0v.044h.1v-.044h-.1Zm.05-.006h-.03v.1h.03v-.1Zm.02.05v-.044h-.1v.044h.1Z"
      fill="#000"
      mask="url(#portugal_svg__d)"
    />
    <mask id="portugal_svg__e" fill="#fff">
      <path d="M7.974 12.327c.004-.203.147-.25.147-.25.003 0 .157.05.157.254h-.304" />
    </mask>
    <path
      d="M7.974 12.327c.004-.203.147-.25.147-.25.003 0 .157.05.157.254h-.304"
      fill="#FF0"
    />
    <path
      d="M8.12 12.076v-.05h-.007l-.008.003.016.047Zm.158.255v.05h.05v-.05h-.05Zm-.254-.003a.236.236 0 0 1 .06-.168.172.172 0 0 1 .053-.037l-.016-.047-.016-.047h-.001l-.003.001a.276.276 0 0 0-.088.06.335.335 0 0 0-.089.236l.1.002Zm.097-.202h-.01l-.002-.001h-.001.002a.19.19 0 0 1 .056.038c.03.03.062.08.062.168h.1a.323.323 0 0 0-.092-.24.292.292 0 0 0-.098-.062l-.004-.001h-.003a.05.05 0 0 0-.01-.002v.1Zm.157.155h-.304v.1h.304v-.1Z"
      fill="#000"
      mask="url(#portugal_svg__e)"
    />
    <path
      d="m7.85 12.08-.026.232h.154c0-.192.146-.221.146-.221.004 0 .147.04.15.221h.155l-.03-.236h-.55v.004Zm-.037.236h.623c.01 0 .022.011.022.026 0 .018-.011.03-.022.03h-.623c-.011 0-.022-.012-.022-.03 0-.015.01-.026.025-.026h-.003Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M8.04 12.312c0-.121.084-.155.084-.155s.085.037.085.155h-.17Zm-.213-.332h.598c.01 0 .022.015.022.03 0 .01-.011.022-.022.022h-.598c-.01 0-.022-.011-.022-.026 0-.011.011-.022.022-.022v-.004Zm.015.056h.565c.01 0 .022.01.022.025 0 .015-.011.026-.022.026h-.569c-.014 0-.022-.01-.022-.026 0-.014.008-.025.022-.025h.004Zm.183-.391h.044v.03h.033v-.03h.048v.033h.033v-.037h.044v.074c0 .014-.007.022-.018.022h-.162c-.01 0-.022-.008-.022-.019v-.073Zm.169.1.01.235h-.157l.011-.24h.136"
      fill="#FF0"
    />
    <path
      d="m8.194 11.744.01.236h-.157l.011-.24h.136m-.154.572c0-.121.084-.155.084-.155s.085.037.085.155h-.17Zm-.213-.332h.598c.01 0 .022.015.022.03 0 .01-.011.022-.022.022h-.598c-.01 0-.022-.011-.022-.026 0-.011.011-.022.022-.022v-.004Zm.015.056h.565c.01 0 .022.01.022.025 0 .015-.011.026-.022.026h-.569c-.014 0-.022-.01-.022-.026 0-.014.008-.025.022-.025h.004Zm.183-.391h.044v.03h.033v-.03h.048v.033h.033v-.037h.044v.074c0 .014-.007.022-.018.022h-.162c-.01 0-.022-.008-.022-.019v-.073Z"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M8.003 11.855v.125h-.147v-.125h.147ZM8.392 11.855v.125h-.147v-.125h.147ZM7.831 11.759h.044v.037h.033v-.037h.044v.037h.033v-.037h.044v.074c0 .014-.007.022-.018.022h-.158a.022.022 0 0 1-.022-.022v-.074ZM8.22 11.759h.044v.037h.033v-.037h.044v.037h.033v-.037h.044v.074c0 .014-.008.022-.019.022h-.157a.022.022 0 0 1-.022-.022v-.074Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <mask id="portugal_svg__f" fill="#fff">
      <path d="M8.11 11.818c0-.022.033-.022.033 0v.059H8.11v-.06Z" />
    </mask>
    <path d="M8.11 11.818c0-.022.033-.022.033 0v.059H8.11v-.06Z" fill="#000" />
    <path
      d="M8.143 11.877v.05h.05v-.05h-.05Zm-.033 0h-.05v.05h.05v-.05Zm.05-.06a.034.034 0 0 1-.034.034.034.034 0 0 1-.034-.033h.1a.066.066 0 0 0-.11-.05.066.066 0 0 0-.022.05h.1Zm-.067 0v.06h.1v-.06h-.1Zm.05.01H8.11v.1h.033v-.1Zm.017.05v-.06h-.1v.06h.1Z"
      fill="#000"
      mask="url(#portugal_svg__f)"
    />
    <mask id="portugal_svg__g" fill="#fff">
      <path d="M7.916 11.9c0-.023.029-.023.029 0v.043h-.03V11.9Z" />
    </mask>
    <path d="M7.916 11.9c0-.023.029-.023.029 0v.043h-.03V11.9Z" fill="#000" />
    <path
      d="M7.945 11.943v.05h.05v-.05h-.05Zm-.03 0h-.05v.05h.05v-.05Zm.05-.044a.033.033 0 0 1-.01.023.036.036 0 0 1-.05 0 .033.033 0 0 1-.01-.023h.1a.067.067 0 0 0-.02-.048.064.064 0 0 0-.09 0 .067.067 0 0 0-.02.048h.1Zm-.07 0v.044h.1V11.9h-.1Zm.05-.006h-.03v.1h.03v-.1Zm.02.05V11.9h-.1v.044h.1Z"
      fill="#000"
      mask="url(#portugal_svg__g)"
    />
    <mask id="portugal_svg__h" fill="#fff">
      <path d="M8.304 11.9c0-.023.03-.023.03 0v.043h-.03V11.9Z" />
    </mask>
    <path d="M8.304 11.9c0-.023.03-.023.03 0v.043h-.03V11.9Z" fill="#000" />
    <path
      d="M8.334 11.943v.05h.05v-.05h-.05Zm-.03 0h-.05v.05h.05v-.05Zm.05-.044a.033.033 0 0 1-.01.023.036.036 0 0 1-.05 0 .033.033 0 0 1-.01-.023h.1a.067.067 0 0 0-.02-.048.064.064 0 0 0-.09 0 .067.067 0 0 0-.02.048h.1Zm-.07 0v.044h.1V11.9h-.1Zm.05-.006h-.03v.1h.03v-.1Zm.02.05V11.9h-.1v.044h.1Z"
      fill="#000"
      mask="url(#portugal_svg__h)"
    />
    <mask id="portugal_svg__i" fill="#fff">
      <path d="M8.636 14.107c-.14-.146-.074-.281-.074-.281.003-.003.148-.077.291.066l-.214.218" />
    </mask>
    <path
      d="M8.636 14.107c-.14-.146-.074-.281-.074-.281.003-.003.148-.077.291.066l-.214.218"
      fill="#FF0"
    />
    <path
      d="m8.562 13.826-.035-.036-.006.006-.003.007.044.023Zm.291.066.035.036.035-.036-.035-.035-.035.035Zm-.181.18a.235.235 0 0 1-.076-.161.176.176 0 0 1 .011-.063l-.045-.022-.044-.023-.001.002v.002a.186.186 0 0 0-.011.029.276.276 0 0 0-.01.076.335.335 0 0 0 .104.23l.072-.07Zm-.074-.211-.008.006-.002.001h.002a.194.194 0 0 1 .066-.013c.042-.001.1.012.162.073l.07-.071a.319.319 0 0 0-.234-.103.286.286 0 0 0-.113.026l-.003.002a.076.076 0 0 0-.011.008l.07.07Zm.22-.004-.215.217.07.071.215-.217-.07-.07Z"
      fill="#000"
      mask="url(#portugal_svg__i)"
    />
    <path
      d="m8.374 14.022.145.182.109-.11c-.135-.135-.053-.26-.053-.26.003-.003.132-.077.262.048l.109-.11-.188-.145-.387.392.003.003Zm.14.192.44-.445c.007-.007.023-.008.033.003.013.013.013.029.005.036l-.439.445c-.008.008-.023.008-.036-.005a.025.025 0 0 1 0-.036l-.003.002Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M8.672 14.05c-.086-.086-.05-.17-.05-.17s.086-.034.169.049l-.119.12Zm-.384-.083.421-.426c.008-.008.026-.006.037.005.007.008.007.023 0 .031l-.422.427c-.007.008-.023.008-.033-.003-.008-.008-.008-.023 0-.031l-.003-.003Zm.05.029.397-.403c.008-.008.024-.008.034.002.01.01.01.026.003.034l-.4.406c-.011.01-.024.008-.034-.003-.01-.01-.013-.023-.003-.034l.002-.002Zm-.147-.407.03-.031.022.02.023-.023-.02-.02.033-.035.023.024.023-.024-.026-.026.031-.031.052.052c.01.01.01.02.003.029l-.114.115c-.007.008-.02.01-.028.002l-.052-.052Zm.189-.05.174.159-.11.112-.162-.177.095-.096"
      fill="#FF0"
    />
    <path
      d="m8.38 13.54.174.158-.11.112-.162-.177.095-.096m.295.512c-.086-.085-.05-.169-.05-.169s.086-.034.169.049l-.119.12Zm-.384-.082.421-.426c.008-.008.026-.006.037.005.007.008.007.023 0 .031l-.422.427c-.007.008-.023.008-.033-.003-.008-.008-.008-.023 0-.031l-.003-.003Zm.05.029.397-.403c.008-.008.024-.008.034.002.01.01.01.026.003.034l-.4.406c-.011.01-.024.008-.034-.003-.01-.01-.013-.023-.003-.034l.002-.002Zm-.147-.407.03-.031.022.02.023-.023-.02-.02.033-.035.023.024.023-.024-.026-.026.031-.031.052.052c.01.01.01.02.003.029l-.114.115c-.007.008-.02.01-.028.002l-.052-.052Z"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="m8.324 13.754.088.088-.103.104-.089-.088.104-.104ZM8.597 13.476l.089.088-.103.105-.089-.088.103-.105ZM8.134 13.809l.031-.032.026.026.024-.023-.026-.026.03-.031.027.025.023-.023-.026-.026.03-.031.053.052c.01.01.01.02.003.028l-.112.113a.022.022 0 0 1-.031 0l-.052-.052Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="m8.408 13.531.031-.031.026.026.023-.024-.026-.026.031-.031.026.026.024-.024-.026-.026.03-.031.053.052c.01.01.01.02.002.029l-.11.112a.022.022 0 0 1-.032 0l-.052-.052Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <mask id="portugal_svg__j" fill="#fff">
      <path d="M8.372 13.652c-.016-.016.008-.04.023-.024l.042.042-.023.023-.042-.041Z" />
    </mask>
    <path
      d="M8.372 13.652c-.016-.016.008-.04.023-.024l.042.042-.023.023-.042-.041Z"
      fill="#000"
    />
    <path
      d="m8.437 13.67.035.035.035-.036-.035-.035-.035.036Zm-.023.023-.035.036.035.035.035-.035-.035-.036Zm-.007-.077a.034.034 0 0 1 0 .048.034.034 0 0 1-.047 0l.07-.072a.066.066 0 0 0-.093 0 .066.066 0 0 0 0 .094l.07-.07Zm-.047.048.042.041.07-.071-.042-.042-.07.072Zm.042-.03-.024.024.071.07.023-.023-.07-.07Zm.047.024-.042-.042-.07.071.042.042.07-.071Z"
      fill="#000"
      mask="url(#portugal_svg__j)"
    />
    <mask id="portugal_svg__k" fill="#fff">
      <path d="M8.293 13.847c-.016-.015.005-.036.02-.02l.032.03-.021.022-.031-.032Z" />
    </mask>
    <path
      d="M8.293 13.847c-.016-.015.005-.036.02-.02l.032.03-.021.022-.031-.032Z"
      fill="#000"
    />
    <path
      d="m8.345 13.858.035.035.035-.036-.035-.035-.035.036Zm-.021.02-.035.036.035.036.035-.036-.035-.035Zm.004-.066c.004.004.009.012.009.023a.036.036 0 0 1-.036.036.033.033 0 0 1-.023-.009l.07-.071a.067.067 0 0 0-.047-.02.064.064 0 0 0-.064.065c0 .018.008.035.02.047l.07-.071Zm-.05.05.031.031.07-.07-.03-.032-.07.071Zm.031-.04-.02.021.07.071.02-.021-.07-.07Zm.05.021-.031-.031-.07.071.03.031.07-.07Z"
      fill="#000"
      mask="url(#portugal_svg__k)"
    />
    <mask id="portugal_svg__l" fill="#fff">
      <path d="M8.566 13.57c-.015-.016.005-.037.021-.02l.031.03-.02.021-.032-.03Z" />
    </mask>
    <path
      d="M8.566 13.57c-.015-.016.005-.037.021-.02l.031.03-.02.021-.032-.03Z"
      fill="#000"
    />
    <path
      d="m8.618 13.58.036.036.035-.036-.035-.035-.036.035Zm-.02.021-.035.036.035.035.035-.035-.035-.036Zm.004-.067c.004.005.009.013.009.024a.035.035 0 0 1-.059.027l.07-.072a.064.064 0 0 0-.111.045c0 .019.008.036.02.048l.07-.072Zm-.05.05.031.032.07-.071-.03-.031-.071.07Zm.031-.039-.02.021.07.07.02-.02-.07-.071Zm.05.02-.031-.03-.07.07.03.032.07-.071Z"
      fill="#000"
      mask="url(#portugal_svg__l)"
    />
    <mask id="portugal_svg__m" fill="#fff">
      <path d="M9.65 10.619c.003-.203.146-.25.146-.25.004 0 .158.05.158.254h-.305" />
    </mask>
    <path
      d="M9.65 10.619c.003-.203.146-.25.146-.25.004 0 .158.05.158.254h-.305"
      fill="#FF0"
    />
    <path
      d="M9.796 10.368v-.05h-.008l-.008.003.016.047Zm.158.255v.05h.05v-.05h-.05Zm-.255-.003a.236.236 0 0 1 .061-.168.172.172 0 0 1 .052-.037l-.016-.047-.016-.047h-.001l-.002.001-.007.003a.276.276 0 0 0-.082.058.335.335 0 0 0-.089.235l.1.002Zm.097-.202h-.01l-.002-.001s-.001 0 0 0h.001a.19.19 0 0 1 .056.038c.03.03.063.08.063.168h.1a.323.323 0 0 0-.093-.24.29.29 0 0 0-.097-.062l-.005-.001h-.003a.05.05 0 0 0-.01-.002v.1Zm.158.155h-.305v.1h.305v-.1Z"
      fill="#000"
      mask="url(#portugal_svg__m)"
    />
    <path
      d="m9.525 10.372-.026.232h.154c0-.192.147-.221.147-.221.004 0 .147.04.15.221h.154l-.029-.236h-.55v.004Zm-.037.236h.624c.01 0 .022.011.022.026 0 .018-.011.03-.022.03h-.624c-.01 0-.022-.012-.022-.03 0-.015.011-.026.026-.026h-.004Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M9.716 10.604c0-.121.084-.155.084-.155s.084.037.084.155h-.168Zm-.213-.332h.598c.01 0 .022.015.022.03 0 .01-.011.022-.022.022h-.598c-.011 0-.022-.011-.022-.026 0-.011.011-.022.022-.022v-.004Zm.015.056h.564c.011 0 .022.01.022.025 0 .015-.01.026-.022.026h-.568c-.015 0-.022-.01-.022-.026 0-.014.007-.025.022-.025h.004Zm.183-.391h.044v.03h.033v-.03h.048v.033h.033v-.037h.044v.074c0 .014-.008.022-.019.022h-.161c-.011 0-.022-.008-.022-.019v-.073Zm.169.1.01.235h-.157l.011-.24h.136"
      fill="#FF0"
    />
    <path
      d="m9.87 10.036.01.236h-.157l.011-.24h.136m-.154.572c0-.121.084-.155.084-.155s.084.037.084.155h-.168Zm-.213-.332h.598c.01 0 .022.015.022.03 0 .01-.011.022-.022.022h-.598c-.011 0-.022-.011-.022-.026 0-.011.011-.022.022-.022v-.004Zm.015.056h.564c.011 0 .022.01.022.025 0 .015-.01.026-.022.026h-.568c-.015 0-.022-.01-.022-.026 0-.014.007-.025.022-.025h.004Zm.183-.391h.044v.03h.033v-.03h.048v.033h.033v-.037h.044v.074c0 .014-.008.022-.019.022h-.161c-.011 0-.022-.008-.022-.019v-.073Z"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M9.679 10.147v.125h-.147v-.125h.147ZM10.068 10.147v.125H9.92v-.125h.147ZM9.507 10.051h.044v.037h.033v-.037h.044v.037h.033v-.037h.044v.074c0 .015-.008.022-.018.022h-.158a.022.022 0 0 1-.022-.022v-.074ZM9.896 10.051h.044v.037h.033v-.037h.043v.037h.034v-.037h.043v.074c0 .015-.007.022-.018.022h-.157a.022.022 0 0 1-.022-.022v-.074Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <mask id="portugal_svg__n" fill="#fff">
      <path d="M9.785 10.11c0-.022.033-.022.033 0v.059h-.033v-.059Z" />
    </mask>
    <path
      d="M9.785 10.11c0-.022.033-.022.033 0v.059h-.033v-.059Z"
      fill="#000"
    />
    <path
      d="M9.818 10.169v.05h.05v-.05h-.05Zm-.033 0h-.05v.05h.05v-.05Zm.05-.059a.034.034 0 0 1-.033.033.034.034 0 0 1-.034-.033h.1a.066.066 0 0 0-.066-.067.066.066 0 0 0-.066.067h.1Zm-.067 0v.059h.1v-.059h-.1Zm.05.009h-.033v.1h.033v-.1Zm.017.05v-.059h-.1v.059h.1Z"
      fill="#000"
      mask="url(#portugal_svg__n)"
    />
    <mask id="portugal_svg__o" fill="#fff">
      <path d="M9.59 10.191c0-.022.03-.022.03 0v.044h-.03v-.044Z" />
    </mask>
    <path d="M9.59 10.191c0-.022.03-.022.03 0v.044h-.03v-.044Z" fill="#000" />
    <path
      d="M9.62 10.235v.05h.05v-.05h-.05Zm-.03 0h-.05v.05h.05v-.05Zm.05-.044a.033.033 0 0 1-.01.023.036.036 0 0 1-.05 0 .033.033 0 0 1-.01-.023h.1a.067.067 0 0 0-.019-.048.064.064 0 0 0-.09 0 .067.067 0 0 0-.02.048h.1Zm-.07 0v.044h.1v-.044h-.1Zm.05-.006h-.03v.1h.03v-.1Zm.02.05v-.044h-.1v.044h.1Z"
      fill="#000"
      mask="url(#portugal_svg__o)"
    />
    <mask id="portugal_svg__p" fill="#fff">
      <path d="M9.98 10.191c0-.022.029-.022.029 0v.044h-.03v-.044Z" />
    </mask>
    <path d="M9.98 10.191c0-.022.029-.022.029 0v.044h-.03v-.044Z" fill="#000" />
    <path
      d="M10.009 10.235v.05h.05v-.05h-.05Zm-.03 0h-.05v.05h.05v-.05Zm.05-.044a.033.033 0 0 1-.01.023.036.036 0 0 1-.05 0 .033.033 0 0 1-.01-.023h.1a.067.067 0 0 0-.02-.048.064.064 0 0 0-.09 0 .067.067 0 0 0-.02.048h.1Zm-.07 0v.044h.1v-.044h-.1Zm.05-.006h-.03v.1h.03v-.1Zm.02.05v-.044h-.1v.044h.1Z"
      fill="#000"
      mask="url(#portugal_svg__p)"
    />
    <mask id="portugal_svg__q" fill="#fff">
      <path d="M11.619 10.619c-.004-.203-.147-.25-.147-.25-.004 0-.158.05-.158.254h.305" />
    </mask>
    <path
      d="M11.619 10.619c-.004-.203-.147-.25-.147-.25-.004 0-.158.05-.158.254h.305"
      fill="#FF0"
    />
    <path
      d="M11.472 10.368v-.05h.008l.008.003-.016.047Zm-.158.255v.05h-.05v-.05h.05Zm.255-.003a.235.235 0 0 0-.061-.168.169.169 0 0 0-.052-.037l.016-.047.016-.047h.001l.002.001a.152.152 0 0 1 .028.013c.017.01.039.025.06.048.046.046.088.12.09.235l-.1.002Zm-.097-.202h.01l.002-.001s.001 0 0 0h-.001a.19.19 0 0 0-.056.038.224.224 0 0 0-.063.168h-.1c0-.116.045-.192.093-.24a.29.29 0 0 1 .089-.059c.003 0 .006-.002.008-.003l.005-.001h.003l.01-.002v.1Zm-.158.155h.305v.1h-.305v-.1Z"
      fill="#000"
      mask="url(#portugal_svg__q)"
    />
    <path
      d="m11.743 10.372.026.232h-.154c0-.192-.147-.221-.147-.221-.004 0-.147.04-.15.221h-.154l.03-.236h.55v.004Zm.037.236h-.624c-.01 0-.022.011-.022.026 0 .018.011.03.022.03h.624c.01 0 .022-.012.022-.03a.025.025 0 0 0-.026-.026h.004Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M11.552 10.604c0-.121-.084-.155-.084-.155s-.084.037-.084.155h.168Zm.213-.332h-.598c-.01 0-.022.015-.022.03 0 .01.011.022.022.022h.598c.011 0 .022-.011.022-.026 0-.011-.01-.022-.022-.022v-.004Zm-.015.056h-.564c-.011 0-.022.01-.022.025 0 .015.01.026.022.026h.568c.015 0 .022-.01.022-.026 0-.014-.007-.025-.022-.025h-.004Zm-.183-.391h-.044v.03h-.033v-.03h-.048v.033h-.033v-.037h-.044v.074c0 .014.008.022.019.022h.161c.011 0 .022-.008.022-.019v-.073Zm-.169.1-.01.235h.157l-.01-.24h-.137"
      fill="#FF0"
    />
    <path
      d="m11.398 10.036-.01.236h.157l-.01-.24h-.137m.154.572c0-.121-.084-.155-.084-.155s-.084.037-.084.155h.168Zm.213-.332h-.598c-.01 0-.022.015-.022.03 0 .01.011.022.022.022h.598c.011 0 .022-.011.022-.026 0-.011-.01-.022-.022-.022v-.004Zm-.015.056h-.564c-.011 0-.022.01-.022.025 0 .015.01.026.022.026h.568c.015 0 .022-.01.022-.026 0-.014-.007-.025-.022-.025h-.004Zm-.183-.391h-.044v.03h-.033v-.03h-.048v.033h-.033v-.037h-.044v.074c0 .014.008.022.019.022h.161c.011 0 .022-.008.022-.019v-.073Z"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M11.59 10.147v.125h.146v-.125h-.146ZM11.201 10.147v.125h.147v-.125H11.2ZM11.762 10.051h-.044v.037h-.033v-.037h-.044v.037h-.033v-.037h-.044v.074c0 .015.007.022.018.022h.158a.022.022 0 0 0 .015-.007.022.022 0 0 0 .007-.015v-.074ZM11.373 10.051h-.044v.037h-.033v-.037h-.044v.037h-.033v-.037h-.044v.074c0 .015.007.022.018.022h.158a.022.022 0 0 0 .022-.022v-.074Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <mask id="portugal_svg__r" fill="#fff">
      <path d="M11.483 10.11c0-.022-.033-.022-.033 0v.059h.033v-.059Z" />
    </mask>
    <path
      d="M11.483 10.11c0-.022-.033-.022-.033 0v.059h.033v-.059Z"
      fill="#000"
    />
    <path
      d="M11.45 10.169v.05h-.05v-.05h.05Zm.033 0h.05v.05h-.05v-.05Zm-.05-.059a.034.034 0 0 0 .033.033.033.033 0 0 0 .022-.008.034.034 0 0 0 .012-.025h-.1a.066.066 0 0 1 .066-.067.066.066 0 0 1 .066.067h-.1Zm.067 0v.059h-.1v-.059h.1Zm-.05.009h.033v.1h-.033v-.1Zm-.017.05v-.059h.1v.059h-.1Z"
      fill="#000"
      mask="url(#portugal_svg__r)"
    />
    <mask id="portugal_svg__s" fill="#fff">
      <path d="M11.677 10.191c0-.022-.03-.022-.03 0v.044h.03v-.044Z" />
    </mask>
    <path
      d="M11.677 10.191c0-.022-.03-.022-.03 0v.044h.03v-.044Z"
      fill="#000"
    />
    <path
      d="M11.648 10.235v.05h-.05v-.05h.05Zm.03 0h.05v.05h-.05v-.05Zm-.05-.044c0 .006.002.015.01.023a.036.036 0 0 0 .05 0 .033.033 0 0 0 .01-.023h-.1c0-.017.006-.034.019-.048a.064.064 0 0 1 .091 0c.013.014.02.031.02.048h-.1Zm.07 0v.044h-.1v-.044h.1Zm-.05-.006h.03v.1h-.03v-.1Zm-.02.05v-.044h.1v.044h-.1Z"
      fill="#000"
      mask="url(#portugal_svg__s)"
    />
    <mask id="portugal_svg__t" fill="#fff">
      <path d="M11.289 10.191c0-.022-.03-.022-.03 0v.044h.03v-.044Z" />
    </mask>
    <path
      d="M11.289 10.191c0-.022-.03-.022-.03 0v.044h.03v-.044Z"
      fill="#000"
    />
    <path
      d="M11.26 10.235v.05h-.05v-.05h.05Zm.029 0h.05v.05h-.05v-.05Zm-.05-.044c0 .006.002.015.01.023a.036.036 0 0 0 .05 0 .033.033 0 0 0 .01-.023h-.1c0-.017.006-.034.02-.048a.064.064 0 0 1 .09 0c.013.014.02.031.02.048h-.1Zm.07 0v.044h-.1v-.044h.1Zm-.05-.006h.03v.1h-.03v-.1Zm-.02.05v-.044h.1v.044h-.1Z"
      fill="#000"
      mask="url(#portugal_svg__t)"
    />
    <mask id="portugal_svg__u" fill="#fff">
      <path d="M11.619 12.327c-.004-.203-.147-.25-.147-.25-.004 0-.158.05-.158.254h.305" />
    </mask>
    <path
      d="M11.619 12.327c-.004-.203-.147-.25-.147-.25-.004 0-.158.05-.158.254h.305"
      fill="#FF0"
    />
    <path
      d="M11.472 12.076v-.05h.008l.008.003-.016.047Zm-.158.255v.05h-.05v-.05h.05Zm.255-.003a.235.235 0 0 0-.061-.168.169.169 0 0 0-.052-.037l.016-.047.016-.047h.001l.002.001.007.003a.275.275 0 0 1 .082.058c.045.046.087.12.089.235l-.1.002Zm-.097-.202h.01l.002-.001s.001 0 0 0h-.001a.19.19 0 0 0-.056.038.224.224 0 0 0-.063.168h-.1c0-.116.045-.192.093-.24a.293.293 0 0 1 .089-.059c.003 0 .006-.002.008-.003l.005-.001h.003l.01-.002v.1Zm-.158.155h.305v.1h-.305v-.1Z"
      fill="#000"
      mask="url(#portugal_svg__u)"
    />
    <path
      d="m11.743 12.08.026.232h-.154c0-.192-.147-.221-.147-.221-.004 0-.147.04-.15.221h-.154l.03-.236h.55v.004Zm.037.236h-.624c-.01 0-.022.011-.022.026 0 .018.011.03.022.03h.624c.01 0 .022-.012.022-.03a.025.025 0 0 0-.026-.026h.004Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M11.552 12.312c0-.121-.084-.155-.084-.155s-.084.037-.084.155h.168Zm.213-.332h-.598c-.01 0-.022.015-.022.03 0 .01.011.022.022.022h.598c.011 0 .022-.011.022-.026 0-.011-.01-.022-.022-.022v-.004Zm-.015.056h-.564c-.011 0-.022.01-.022.025 0 .015.01.026.022.026h.568c.015 0 .022-.01.022-.026 0-.014-.007-.025-.022-.025h-.004Zm-.183-.391h-.044v.03h-.033v-.03h-.048v.033h-.033v-.037h-.044v.074c0 .014.008.022.019.022h.161c.011 0 .022-.008.022-.019v-.073Zm-.169.1-.01.235h.157l-.01-.24h-.137"
      fill="#FF0"
    />
    <path
      d="m11.398 11.744-.01.236h.157l-.01-.24h-.137m.154.572c0-.121-.084-.155-.084-.155s-.084.037-.084.155h.168Zm.213-.332h-.598c-.01 0-.022.015-.022.03 0 .01.011.022.022.022h.598c.011 0 .022-.011.022-.026 0-.011-.01-.022-.022-.022v-.004Zm-.015.056h-.564c-.011 0-.022.01-.022.025 0 .015.01.026.022.026h.568c.015 0 .022-.01.022-.026 0-.014-.007-.025-.022-.025h-.004Zm-.183-.391h-.044v.03h-.033v-.03h-.048v.033h-.033v-.037h-.044v.074c0 .014.008.022.019.022h.161c.011 0 .022-.008.022-.019v-.073Z"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M11.59 11.855v.125h.146v-.125h-.146ZM11.201 11.855v.125h.147v-.125H11.2ZM11.762 11.759h-.044v.037h-.033v-.037h-.044v.037h-.033v-.037h-.044v.074c0 .014.007.022.018.022h.158a.022.022 0 0 0 .022-.022v-.074ZM11.373 11.759h-.044v.037h-.033v-.037h-.044v.037h-.033v-.037h-.044v.074c0 .014.007.022.018.022h.158a.022.022 0 0 0 .022-.022v-.074Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <mask id="portugal_svg__v" fill="#fff">
      <path d="M11.483 11.818c0-.022-.033-.022-.033 0v.059h.033v-.06Z" />
    </mask>
    <path
      d="M11.483 11.818c0-.022-.033-.022-.033 0v.059h.033v-.06Z"
      fill="#000"
    />
    <path
      d="M11.45 11.877v.05h-.05v-.05h.05Zm.033 0h.05v.05h-.05v-.05Zm-.05-.06a.034.034 0 0 0 .033.034.033.033 0 0 0 .022-.008.034.034 0 0 0 .012-.025h-.1c0-.02.008-.037.022-.05a.067.067 0 0 1 .09 0c.013.013.02.03.02.05h-.1Zm.067 0v.06h-.1v-.06h.1Zm-.05.01h.033v.1h-.033v-.1Zm-.017.05v-.06h.1v.06h-.1Z"
      fill="#000"
      mask="url(#portugal_svg__v)"
    />
    <mask id="portugal_svg__w" fill="#fff">
      <path d="M11.677 11.9c0-.023-.03-.023-.03 0v.043h.03V11.9Z" />
    </mask>
    <path d="M11.677 11.9c0-.023-.03-.023-.03 0v.043h.03V11.9Z" fill="#000" />
    <path
      d="M11.648 11.943v.05h-.05v-.05h.05Zm.03 0h.05v.05h-.05v-.05Zm-.05-.044c0 .006.002.015.01.023a.036.036 0 0 0 .05 0 .033.033 0 0 0 .01-.023h-.1c0-.017.006-.034.019-.048a.064.064 0 0 1 .091 0c.013.014.02.031.02.048h-.1Zm.07 0v.044h-.1V11.9h.1Zm-.05-.006h.03v.1h-.03v-.1Zm-.02.05V11.9h.1v.044h-.1Z"
      fill="#000"
      mask="url(#portugal_svg__w)"
    />
    <mask id="portugal_svg__x" fill="#fff">
      <path d="M11.289 11.9c0-.023-.03-.023-.03 0v.043h.03V11.9Z" />
    </mask>
    <path d="M11.289 11.9c0-.023-.03-.023-.03 0v.043h.03V11.9Z" fill="#000" />
    <path
      d="M11.26 11.943v.05h-.05v-.05h.05Zm.029 0h.05v.05h-.05v-.05Zm-.05-.044c0 .006.002.015.01.023a.036.036 0 0 0 .05 0 .033.033 0 0 0 .01-.023h-.1c0-.017.006-.034.02-.048a.064.064 0 0 1 .09 0c.013.014.02.031.02.048h-.1Zm.07 0v.044h-.1V11.9h.1Zm-.05-.006h.03v.1h-.03v-.1Zm-.02.05V11.9h.1v.044h-.1Z"
      fill="#000"
      mask="url(#portugal_svg__x)"
    />
    <mask id="portugal_svg__y" fill="#fff">
      <path d="M10.957 14.107c.14-.146.073-.281.073-.281-.002-.003-.147-.077-.29.066l.214.218" />
    </mask>
    <path
      d="M10.957 14.107c.14-.146.073-.281.073-.281-.002-.003-.147-.077-.29.066l.214.218"
      fill="#FF0"
    />
    <path
      d="m11.03 13.826.036-.036.005.006.004.007-.045.023Zm-.29.066-.036.036-.035-.036.036-.035.035.035Zm.181.18a.235.235 0 0 0 .075-.161.176.176 0 0 0-.01-.061v-.002l.044-.022.045-.023v.002l.002.002a.148.148 0 0 1 .01.029c.005.019.01.045.01.076a.335.335 0 0 1-.104.23l-.072-.07Zm.074-.211a.046.046 0 0 0 .007.006l.002.001h.001-.002a.195.195 0 0 0-.066-.013.22.22 0 0 0-.162.073l-.07-.071a.319.319 0 0 1 .234-.103.286.286 0 0 1 .112.026l.004.002.003.002.008.006-.07.07Zm-.22-.004.215.217-.071.071-.215-.217.071-.07Z"
      fill="#000"
      mask="url(#portugal_svg__y)"
    />
    <path
      d="m11.22 14.022-.147.182-.108-.11c.135-.135.053-.26.053-.26-.003-.003-.132-.077-.262.048l-.109-.11.187-.145.388.392-.003.003Zm-.141.192-.44-.444c-.007-.008-.023-.008-.033.002-.013.013-.013.029-.006.037l.44.445c.007.007.023.007.036-.006a.025.025 0 0 0 0-.036l.003.002Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M10.92 14.05c.087-.086.05-.17.05-.17s-.085-.034-.168.049l.119.12Zm.385-.083-.421-.426c-.008-.008-.026-.006-.037.005-.008.008-.008.023 0 .031l.421.427c.008.008.024.008.034-.003a.024.024 0 0 0 0-.031l.003-.003Zm-.05.029-.398-.403c-.007-.008-.023-.008-.033.002-.01.01-.01.026-.003.034l.4.406c.01.01.024.008.034-.003.01-.01.013-.023.003-.034l-.003-.002Zm.147-.407-.031-.031-.02.02-.024-.023.02-.02-.033-.035-.023.024-.024-.024.027-.026-.032-.031-.052.052c-.01.01-.01.02-.002.029l.113.115c.008.008.021.01.029.002l.052-.052Zm-.19-.05-.173.159.11.112.162-.177-.095-.096"
      fill="#FF0"
    />
    <path
      d="m11.213 13.54-.174.158.11.112.162-.177-.095-.096m-.295.512c.086-.085.05-.169.05-.169s-.086-.034-.17.049l.12.12Zm.384-.082-.421-.426c-.008-.008-.026-.006-.037.005-.008.008-.008.023 0 .031l.421.427c.008.008.024.008.034-.003a.024.024 0 0 0 0-.031l.003-.003Zm-.05.029-.398-.403c-.007-.008-.023-.008-.033.002-.01.01-.01.026-.003.034l.4.406c.01.01.024.008.034-.003.01-.01.013-.023.003-.034l-.003-.002Zm.147-.407-.031-.031-.02.02-.024-.023.02-.02-.033-.035-.023.024-.024-.024.027-.026-.032-.031-.052.052c-.01.01-.01.02-.002.029l.113.115c.008.008.021.01.029.002l.052-.052Z"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="m11.27 13.754-.09.088.104.104.089-.088-.104-.104ZM10.995 13.476l-.088.088.103.105.089-.088-.104-.105Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="m11.459 13.809-.031-.032-.027.026-.023-.023.026-.026-.03-.031-.027.025-.023-.023.026-.026-.031-.031-.052.052c-.01.01-.01.02-.003.028l.111.113c.005.004.01.006.016.006a.022.022 0 0 0 .015-.006l.053-.052ZM11.185 13.531l-.031-.031-.027.026-.023-.024.026-.026-.03-.031-.027.026-.023-.024.026-.026-.03-.031-.053.052c-.01.01-.01.02-.003.029l.111.112a.022.022 0 0 0 .031 0l.053-.052Z"
      fill="#FF0"
      stroke="#000"
      strokeWidth={0.05}
    />
    <mask id="portugal_svg__z" fill="#fff">
      <path d="M11.22 13.652c.016-.016-.007-.04-.023-.024l-.041.042.023.023.042-.041Z" />
    </mask>
    <path
      d="M11.22 13.652c.016-.016-.007-.04-.023-.024l-.041.042.023.023.042-.041Z"
      fill="#000"
    />
    <path
      d="m11.156 13.67-.036.035-.035-.036.036-.035.035.036Zm.023.023.035.036-.035.035-.035-.035.035-.036Zm.007-.077a.034.034 0 0 0 0 .048.034.034 0 0 0 .047 0l-.07-.072a.066.066 0 0 1 .093 0c.01.011.019.026.02.044a.066.066 0 0 1-.02.051l-.07-.071Zm.047.048-.042.041-.07-.071.041-.042.07.072Zm-.042-.03.023.024-.07.07-.024-.023.071-.07Zm-.047.024.042-.042.07.071-.042.042-.07-.071Z"
      fill="#000"
      mask="url(#portugal_svg__z)"
    />
    <mask id="portugal_svg__A" fill="#fff">
      <path d="M11.3 13.847c.015-.015-.005-.036-.021-.02l-.031.03.02.022.032-.032Z" />
    </mask>
    <path
      d="M11.3 13.847c.015-.015-.005-.036-.021-.02l-.031.03.02.022.032-.032Z"
      fill="#000"
    />
    <path
      d="m11.248 13.858-.036.035-.035-.036.036-.035.035.036Zm.02.02.036.036-.036.036-.035-.036.035-.035Zm-.003-.066a.033.033 0 0 0-.01.023.036.036 0 0 0 .036.036c.01 0 .019-.005.023-.009l-.07-.071a.067.067 0 0 1 .047-.02c.018 0 .034.007.045.019a.064.064 0 0 1 .02.046.067.067 0 0 1-.021.047l-.07-.071Zm.05.05-.032.031-.07-.07.03-.032.071.071Zm-.032-.04.02.021-.07.071-.02-.021.07-.07Zm-.05.021.032-.031.07.071-.031.031-.07-.07Z"
      fill="#000"
      mask="url(#portugal_svg__A)"
    />
    <mask id="portugal_svg__B" fill="#fff">
      <path d="M11.026 13.57c.016-.016-.005-.037-.02-.02l-.032.03.021.021.031-.03Z" />
    </mask>
    <path
      d="M11.026 13.57c.016-.016-.005-.037-.02-.02l-.032.03.021.021.031-.03Z"
      fill="#000"
    />
    <path
      d="m10.974 13.58-.035.036-.035-.036.035-.035.035.035Zm.021.021.035.036-.035.035-.035-.035.035-.036Zm-.004-.067a.033.033 0 0 0-.009.024c0 .01.004.019.01.025.006.006.015.01.025.01.011 0 .02-.004.024-.008l-.07-.072a.064.064 0 0 1 .111.045.068.068 0 0 1-.02.048l-.07-.072Zm.05.05-.032.032-.07-.071.031-.031.07.07Zm-.031-.039.02.021-.07.07-.021-.02.07-.071Zm-.05.02.031-.03.07.07-.03.032-.071-.071Z"
      fill="#000"
      mask="url(#portugal_svg__B)"
    />
    <path
      d="m9.998 12.6-.018-.017a.243.243 0 0 1-.176.08v.025-.024a.237.237 0 0 1-.176-.08.282.282 0 0 1-.074-.194v-.374h.5v.374c0 .071-.026.14-.074.193l.018.017Zm0 0a.307.307 0 0 0 .08-.21h-.55a.307.307 0 0 0 .17.275c.033.015.07.023.106.024a.268.268 0 0 0 .194-.089Z"
      fill="#039"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M9.687 12.143a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.991 12.143a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.837 12.29a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.687 12.445a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.991 12.445a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="m9.998 11.64-.018-.016a.243.243 0 0 1-.176.08v.025-.025a.237.237 0 0 1-.176-.08.282.282 0 0 1-.074-.193v-.374h.5v.374c0 .071-.026.14-.074.193l.018.017Zm0 0a.307.307 0 0 0 .08-.21h-.55a.307.307 0 0 0 .17.276c.033.015.07.023.106.023a.268.268 0 0 0 .194-.088Z"
      fill="#039"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M9.687 11.184a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.991 11.184a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.837 11.331a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.687 11.486a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.991 11.486a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="m9.235 12.6-.018-.017a.243.243 0 0 1-.176.08v.025-.024a.237.237 0 0 1-.176-.08.282.282 0 0 1-.074-.194v-.374h.5v.374c0 .071-.026.14-.074.193l.018.017Zm0 0a.307.307 0 0 0 .081-.21h-.55c0 .078.028.153.08.21a.264.264 0 0 0 .195.088.268.268 0 0 0 .194-.088Z"
      fill="#039"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M8.924 12.143a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.229 12.143a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.075 12.29a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM8.924 12.445a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.229 12.445a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="m10.76 12.6-.018-.017a.243.243 0 0 1-.176.08v.025-.024a.238.238 0 0 1-.175-.08.282.282 0 0 1-.075-.194v-.374h.5v.374c.001.071-.025.14-.074.193l.019.017Zm0 0a.307.307 0 0 0 .082-.21h-.55c-.001.078.028.153.08.21a.264.264 0 0 0 .194.088.268.268 0 0 0 .195-.088Z"
      fill="#039"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M10.45 12.143a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM10.754 12.143a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM10.6 12.29a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM10.45 12.445a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM10.754 12.445a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="m9.998 13.552-.018-.017a.243.243 0 0 1-.176.08v.025-.025a.237.237 0 0 1-.176-.08.282.282 0 0 1-.074-.193v-.374h.5v.373c0 .072-.026.141-.074.194l.018.017Zm0 0a.307.307 0 0 0 .08-.21h-.55a.307.307 0 0 0 .17.275c.033.015.07.023.106.023a.268.268 0 0 0 .194-.088Z"
      fill="#039"
      stroke="#000"
      strokeWidth={0.05}
    />
    <path
      d="M1 3.75H.75v17.1h22.5V3.75H1Z"
      stroke="#000"
      strokeOpacity={0.2}
      strokeWidth={0.5}
    />
    <path
      d="M9.687 13.094a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.991 13.094a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.837 13.242a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.687 13.397a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03ZM9.991 13.397a.03.03 0 0 1-.03.03.03.03 0 0 1-.03-.03.03.03 0 0 1 .03-.03.03.03 0 0 1 .03.03Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.05}
    />
  </svg>
);

export default Portugal;
