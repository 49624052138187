/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftPowerpointAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 3h15A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3Z"
      fill="url(#microsoft-powerpoint-alt_svg__a)"
    />
    <path
      d="M12.164 7a4.123 4.123 0 0 1 2.73.8 2.864 2.864 0 0 1 .951 2.318c.011.61-.151 1.21-.468 1.732-.321.513-.784.921-1.332 1.177a4.52 4.52 0 0 1-2.002.42h-1.897v3.527H8.203V7h3.96Zm-2.02 4.924h1.673a2.171 2.171 0 0 0 1.478-.441c.35-.335.533-.808.5-1.29 0-1.1-.639-1.649-1.916-1.649h-1.734v3.38Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="microsoft-powerpoint-alt_svg__a"
        x1={6.127}
        y1={1.828}
        x2={17.873}
        y2={22.172}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CA4C28" />
        <stop offset={0.5} stopColor="#C5401E" />
        <stop offset={1} stopColor="#B62F14" />
      </linearGradient>
    </defs>
  </svg>
);

export default MicrosoftPowerpointAlt;
