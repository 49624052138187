import { jsxs as l, jsx as c } from "react/jsx-runtime";
const h = (e) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ c("path", { d: "M5 19a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 18c0-.283.096-.52.287-.712A.968.968 0 0 1 5 17h1v-7c0-1.383.417-2.613 1.25-3.688.833-1.075 1.917-1.779 3.25-2.112v-.7c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 12 2c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v.138A7 7 0 0 0 18 16v1h1c.283 0 .52.096.712.288A.965.965 0 0 1 20 18c0 .283-.096.52-.288.712A.965.965 0 0 1 19 19H5ZM12 22c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 10 20h4c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 12 22Z" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M18 14c-1.383 0-2.563-.488-3.537-1.463C13.488 11.563 13 10.383 13 9s.488-2.563 1.463-3.537C15.438 4.487 16.617 4 18 4s2.563.487 3.538 1.463C22.512 6.437 23 7.617 23 9s-.488 2.563-1.462 3.537C20.562 13.512 19.383 14 18 14Zm2-3a.48.48 0 0 0 0-.7l-1.5-1.5V6.5a.48.48 0 0 0-.15-.35.48.48 0 0 0-.7 0 .48.48 0 0 0-.15.35v2.4c0 .1.017.192.05.275a.762.762 0 0 0 .175.25L19.3 11a.48.48 0 0 0 .7 0Z"
        }
      )
    ]
  }
);
export {
  h as default
};
