import { jsx as e } from "react/jsx-runtime";
const t = (o) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm8.2 6.5c0 .964-.37 1.842-.973 2.5H18v6h-6v-5.773A3.7 3.7 0 1 1 13.2 9.5Z"
      }
    )
  }
);
export {
  t as default
};
