import { jsx as c } from "react/jsx-runtime";
const i = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 21c-.7 0-1.292-.246-1.775-.738A2.424 2.424 0 0 1 9.5 18.5c0-.7.242-1.292.725-1.775C10.708 16.24 11.3 16 12 16s1.292.241 1.775.725c.483.483.725 1.075.725 1.775 0 .683-.242 1.27-.725 1.762A2.392 2.392 0 0 1 12 21Zm8.8-10.9a14.049 14.049 0 0 0-4.062-2.288A13.783 13.783 0 0 0 12 7a13.365 13.365 0 0 0-2 .15L7.45 4.6c.733-.2 1.48-.35 2.238-.45C10.446 4.05 11.217 4 12 4c2.083 0 4.054.35 5.913 1.05 1.858.7 3.52 1.666 4.987 2.9.317.266.48.6.488 1 .008.4-.138.758-.438 1.075a1.503 1.503 0 0 1-1.037.45A1.626 1.626 0 0 1 20.8 10.1Zm-3.225 4.625-.562-.563-.563-.562-3.6-3.6c1.1.1 2.146.35 3.138.75.991.4 1.879.908 2.662 1.525.333.25.5.575.5.975S19 14 18.7 14.3c-.15.15-.325.262-.525.337-.2.075-.4.104-.6.088ZM19.05 21.9l-8.7-8.75a8.037 8.037 0 0 0-1.512.487c-.475.208-.93.454-1.363.738-.367.25-.75.366-1.15.35a1.49 1.49 0 0 1-1.025-.45c-.3-.3-.442-.659-.425-1.075.017-.417.183-.742.5-.975.383-.3.792-.571 1.225-.813.433-.242.883-.462 1.35-.662L5.7 8.5c-.45.233-.883.483-1.3.75-.417.266-.817.55-1.2.85a1.681 1.681 0 0 1-1.112.388 1.417 1.417 0 0 1-1.038-.463A1.511 1.511 0 0 1 .625 8.95c0-.417.158-.75.475-1 .383-.317.771-.609 1.163-.875.391-.267.804-.525 1.237-.775L2.1 4.9a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l16.975 16.975c.183.183.275.42.275.713 0 .29-.092.528-.275.712-.2.2-.442.296-.725.288a.994.994 0 0 1-.7-.288Z" })
  }
);
export {
  i as default
};
