import { jsx as a } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M12.025 11.675a1.52 1.52 0 0 1-.337-.088.81.81 0 0 1-.288-.187L7.625 7.625 4.575 9.85c-.333.233-.679.258-1.037.075-.359-.183-.538-.483-.538-.9 0-.167.033-.317.1-.45a.992.992 0 0 1 .3-.35L7.125 5.5a.744.744 0 0 1 .325-.162.982.982 0 0 1 .35-.013c.117.017.23.046.338.087a.825.825 0 0 1 .287.188L12.2 9.375l7.225-5.225c.333-.233.679-.258 1.037-.075.359.183.538.483.538.9a.905.905 0 0 1-.112.45c-.075.133-.18.25-.313.35L12.7 11.5a.745.745 0 0 1-.325.162.982.982 0 0 1-.35.013Zm0 9a.882.882 0 0 1-.35-.075.946.946 0 0 1-.3-.2L5.8 15l-1.225.875c-.333.233-.679.258-1.037.075-.359-.183-.538-.483-.538-.9a.98.98 0 0 1 .112-.463.876.876 0 0 1 .313-.337l1.9-1.375a.758.758 0 0 1 .313-.163.917.917 0 0 1 .337-.012c.117.017.229.046.337.087a.823.823 0 0 1 .288.188l5.525 5.375 3.9-3.125a.688.688 0 0 1 .287-.175c.109-.033.221-.05.338-.05H20c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 17h-3l-4.325 3.45a.905.905 0 0 1-.312.175c-.109.033-.221.05-.338.05Z" })
  }
);
export {
  h as default
};
