import { jsx as a } from "react/jsx-runtime";
const A = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M11.1 15.5a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7L10 11.6V8a.97.97 0 0 0-.287-.713A.97.97 0 0 0 9 7a.967.967 0 0 0-.712.287A.968.968 0 0 0 8 8v3.575a1.97 1.97 0 0 0 .575 1.4L11.1 15.5Zm6.9 3.425c0-.2.046-.38.138-.538.091-.158.245-.295.462-.412a6.56 6.56 0 0 0 2.475-2.463C21.692 14.454 22 13.283 22 12c0-1.25-.304-2.4-.913-3.45A7.008 7.008 0 0 0 18.6 6.025a1.386 1.386 0 0 1-.425-.4A1.063 1.063 0 0 1 18 5c0-.433.187-.717.562-.85a1.206 1.206 0 0 1 1.088.125 8.704 8.704 0 0 1 3.187 3.25C23.612 8.892 24 10.383 24 12c0 1.617-.383 3.1-1.15 4.45a8.922 8.922 0 0 1-3.15 3.25c-.383.233-.763.275-1.138.125-.375-.15-.562-.45-.562-.9ZM9 21a8.715 8.715 0 0 1-3.513-.712 9.145 9.145 0 0 1-2.849-1.926 9.129 9.129 0 0 1-1.925-2.85A8.698 8.698 0 0 1 0 12c0-1.25.238-2.421.713-3.513a9.145 9.145 0 0 1 1.925-2.85 9.155 9.155 0 0 1 2.849-1.925A8.716 8.716 0 0 1 9 3c1.25 0 2.42.237 3.512.712a9.164 9.164 0 0 1 2.851 1.925 9.167 9.167 0 0 1 1.925 2.85A8.715 8.715 0 0 1 18 12c0 1.25-.237 2.42-.712 3.512a9.151 9.151 0 0 1-1.925 2.85 9.155 9.155 0 0 1-2.851 1.926A8.708 8.708 0 0 1 9 21Z" })
  }
);
export {
  A as default
};
