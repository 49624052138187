import { jsx as c } from "react/jsx-runtime";
const a = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M1 18c.15-1.784.696-3.426 1.638-4.926A10.392 10.392 0 0 1 6.4 9.5L4.55 6.3a.54.54 0 0 1-.075-.475.602.602 0 0 1 .325-.375.527.527 0 0 1 .45-.05c.167.05.3.15.4.3L7.5 8.9C8.933 8.3 10.433 8 12 8c1.567 0 3.067.3 4.5.9l1.85-3.2c.1-.15.233-.25.4-.3a.527.527 0 0 1 .45.05.601.601 0 0 1 .325.375.54.54 0 0 1-.075.475L17.6 9.5a10.4 10.4 0 0 1 3.763 3.575A10.843 10.843 0 0 1 23 18H1Zm6-2.75c.35 0 .646-.122.888-.364.241-.241.362-.537.362-.887s-.12-.645-.362-.887A1.208 1.208 0 0 0 7 12.75c-.35 0-.646.121-.887.363A1.205 1.205 0 0 0 5.75 14c0 .35.121.646.363.887.241.242.537.363.887.363Zm10 0c.35 0 .646-.122.888-.364.241-.241.362-.537.362-.887s-.12-.645-.362-.887A1.208 1.208 0 0 0 17 12.75c-.35 0-.646.121-.887.363a1.205 1.205 0 0 0-.363.887c0 .35.121.646.363.887.241.242.537.363.887.363Z" })
  }
);
export {
  a as default
};
