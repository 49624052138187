import { jsx as e } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ e(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M15.188 16.188C14.313 17.063 13.25 17.5 12 17.5c-1.25 0-2.313-.437-3.188-1.312S7.5 14.25 7.5 13c0-1.25.437-2.313 1.312-3.188S10.75 8.5 12 8.5c1.25 0 2.313.437 3.188 1.312S16.5 11.75 16.5 13c0 1.25-.437 2.313-1.312 3.188Zm-12.6 4.225C2.979 20.804 3.45 21 4 21h16c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413V7c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 20 5h-3.15L15.6 3.65a1.742 1.742 0 0 0-.662-.488A2.09 2.09 0 0 0 14.125 3h-4.25a2.09 2.09 0 0 0-.813.162 1.743 1.743 0 0 0-.662.488L7.15 5H4c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 2 7v12c0 .55.196 1.021.588 1.413ZM20 7v12H4V7h16Zm-8 8.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      }
    )
  }
);
export {
  t as default
};
