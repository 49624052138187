import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Gallery from "react-photo-gallery";
import { Loader } from "@happeouikit/loaders";
import { gray09 } from "@happeouikit/colors";
import { processImage } from "./utils";
import ImageGridPhoto from "./ImageGridPhoto";

const ImageGrid = ({
  images,
  direction,
  columns,
  onClickImage,
  targetRowHeight,
  limitNodeSearch,
  margin,
  maxImages,
  maxImageHeight,
}) => {
  const [loading, setLoading] = useState(false);
  const [processedImages, setProcessedImages] = useState([]);

  useEffect(() => {
    setLoading(true);
    let isSubscribed = true;
    Promise.all(
      images.slice(0, maxImages).map((image) => processImage(image))
    ).then((results) => {
      if (isSubscribed) {
        setProcessedImages(results);
        setLoading(false);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, [images, maxImages]);

  const onClick = (event, { photo, index }) => {
    onClickImage({ image: photo, index, event });
  };

  const renderImage = ({ index, left, top, photo }) => {
    return (
      <ImageGridPhoto
        key={`${photo.src}-${index}`}
        index={index}
        left={left}
        top={top}
        photo={photo}
        margin={margin}
        direction={direction}
        onClick={onClick}
        maxImageHeight={maxImageHeight}
        maxImages={maxImages}
        totalImages={images.length}
      />
    );
  };

  return (
    <>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <Gallery
          photos={processedImages}
          onClick={onClick}
          direction={direction}
          columns={columns}
          targetRowHeight={targetRowHeight}
          limitNodeSearch={limitNodeSearch}
          margin={margin}
          renderImage={renderImage}
        />
      )}
    </>
  );
};

ImageGrid.propTypes = {
  /* Full react-photo-gallery API is supported. See full list here: http://neptunian.github.io/react-photo-gallery/api.html */
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  onClickImage: PropTypes.func,
  direction: PropTypes.oneOf(["column", "row"]),
  columns: PropTypes.number,
  margin: PropTypes.number,
  maxImages: PropTypes.number,
  maxImageHeight: PropTypes.string,
  targetRowHeight: PropTypes.number,
};

ImageGrid.defaultProps = {
  images: [],
  onClickImage: () => {},
  direction: "row",
  columns: 2,
  margin: 2,
  maxImages: 4,
  maxImageHeight: undefined,
  targetRowHeight: undefined,
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${gray09};
`;

export default ImageGrid;
