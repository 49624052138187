import { createGlobalStyle } from "styled-components";

export const OverlayGlobalStyle = createGlobalStyle`
  .ReactModal__Body--open {
    overflow-y: hidden;
  }
`;

export const defaultOverlayStyle: {
  [key: string]: string | number;
} = {
  position: "fixed",
  top: "0px",
  left: "0px",
  right: "0px",
  bottom: "0px",
  backgroundColor: "var(--color-black30)",
  zIndex: "1049",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "100vh",
};
