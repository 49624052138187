/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const DropboxTransfer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m8.12 2 6.448 10-6.447 10h7.753l6.447-10-6.447-10H8.12Z"
      fill="#0061FE"
    />
    <path
      d="m9.432 12 2.57-3.99L8.125 2 1.678 12l6.45 10 3.876-6.012L9.432 12Z"
      fill="#1E1919"
    />
  </svg>
);

export default DropboxTransfer;
