import { jsxs as l, jsx as a } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ a("path", { d: "M7.449 3.13c-.576 0-1.06-.467-1.06-1.065C6.39 1.486 6.873 1 7.45 1a1.062 1.062 0 0 1 .954 1.524l.787.79a8.063 8.063 0 0 1 8.647-1.477 8.133 8.133 0 0 1 4.47 4.826c.074.187-.056.411-.261.486l-7.382 2.169v-.022.001-5.606c-3.087 0-5.597 2.505-5.597 5.627 0 3.102 2.51 5.625 5.596 5.625-.029 4.33-3.44 7.851-7.698 8.056a.367.367 0 0 1-.39-.374v-4.616a8.009 8.009 0 0 1-5.374-.73.375.375 0 0 1-.149-.523L7.654 5.243c.3-.522.648-.999 1.037-1.426l-.788-.792a1.031 1.031 0 0 1-.454.106Z" }),
      /* @__PURE__ */ a("path", { d: "m14.664 9.318 4.983 1.457c.223.056.335.28.26.505-.799 2.15-2.845 3.663-5.244 3.663l.001-5.625ZM12.358 7.878a.894.894 0 0 1-.893-.897c0-.486.39-.897.893-.897.502 0 .892.411.892.897a.895.895 0 0 1-.892.897Z" })
    ]
  }
);
export {
  s as default
};
