import { jsx as l } from "react/jsx-runtime";
const c = (s) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ l("path", { d: "m16.7 7.7 4.6 4.6c.1.1.17.208.212.325.042.117.063.242.063.375s-.02.258-.063.375a.877.877 0 0 1-.212.325l-4.6 4.6a.977.977 0 0 1-.688.287.93.93 0 0 1-.712-.287.935.935 0 0 1-.313-.688.93.93 0 0 1 .288-.712l3.9-3.9-3.9-3.9a.992.992 0 0 1-.287-.7.87.87 0 0 1 .287-.7c.183-.2.417-.3.7-.3s.525.1.725.3ZM3 5c.283 0 .52.096.712.287.192.192.288.43.288.713v3c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 7 12h6.175l-2.9-2.9a.992.992 0 0 1-.287-.7.87.87 0 0 1 .287-.7c.183-.2.417-.3.7-.3s.525.1.725.3l4.6 4.6c.1.1.17.208.212.325.042.117.063.242.063.375s-.02.258-.063.375a.877.877 0 0 1-.212.325l-4.6 4.6a.977.977 0 0 1-.688.287.93.93 0 0 1-.712-.287.935.935 0 0 1-.313-.688.93.93 0 0 1 .288-.712l2.9-2.9H7c-1.383 0-2.563-.488-3.538-1.463C2.487 11.563 2 10.383 2 9V6c0-.283.096-.52.288-.713A.968.968 0 0 1 3 5Z" })
  }
);
export {
  c as default
};
