import { jsxs as l, jsx as t } from "react/jsx-runtime";
const h = (e) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M1 3.75H.75v17.1h22.5V3.75H1Z",
          stroke: "#000",
          strokeOpacity: 0.2,
          strokeWidth: 0.5
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M1 4h22v16.6H1V4Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M12.129 17.46c2.832 0 5.129-2.31 5.129-5.16 0-2.849-2.296-5.16-5.13-5.16C9.297 7.14 7 9.452 7 12.3c0 2.85 2.296 5.16 5.129 5.16Z",
          fill: "#D30000"
        }
      )
    ]
  }
);
export {
  h as default
};
