import { jsx as c } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M5.4 19.6A8.29 8.29 0 0 1 3.638 17C3.213 16.017 3 15 3 13.95c0-1.05.2-2.087.6-3.113C4 9.812 4.65 8.85 5.55 7.95c.583-.583 1.304-1.083 2.163-1.5.858-.417 1.874-.746 3.049-.988 1.175-.241 2.517-.387 4.026-.437 1.508-.05 3.195.008 5.062.175a44.99 44.99 0 0 1 .125 4.875c-.05 1.484-.188 2.82-.413 4.012-.225 1.192-.541 2.234-.949 3.125-.409.892-.913 1.638-1.513 2.238-.883.884-1.82 1.53-2.812 1.937A7.908 7.908 0 0 1 11.25 22a8.28 8.28 0 0 1-3.175-.637A8.09 8.09 0 0 1 5.4 19.6Zm2.8-.4c.483.284.98.488 1.488.612A6.494 6.494 0 0 0 11.25 20c.767 0 1.525-.154 2.275-.462.75-.308 1.467-.805 2.15-1.488.3-.3.604-.72.912-1.262.309-.542.576-1.25.801-2.126.225-.875.395-1.933.512-3.174.117-1.242.133-2.721.05-4.438a57.384 57.384 0 0 0-2.762-.038 22.25 22.25 0 0 0-3.063.238c-1.017.15-1.983.392-2.9.725-.917.333-1.667.792-2.25 1.375-.75.75-1.267 1.492-1.55 2.225C5.142 12.31 5 13.017 5 13.7c0 .984.187 1.846.562 2.587.375.742.705 1.263.988 1.563.7-1.333 1.625-2.613 2.775-3.838A14.561 14.561 0 0 1 13.35 11a18.254 18.254 0 0 0-3.137 3.562C9.321 15.887 8.65 17.433 8.2 19.2Z" })
  }
);
export {
  e as default
};
