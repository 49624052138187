/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Trustpilot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 9.265h-7.64L12.006 2 9.641 9.265 2 9.251l6.18 4.496-2.366 7.265 6.179-4.496 6.18 4.496-2.352-7.265L22 9.265Z"
      fill="#00B67A"
    />
    <path d="m16.35 15.389-.53-1.642-3.813 2.77 4.342-1.128Z" fill="#005128" />
  </svg>
);

export default Trustpilot;
