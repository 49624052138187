import { jsxs as l, jsx as h } from "react/jsx-runtime";
const v = (t) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ h(
        "path",
        {
          d: "M1 3.75H.75v17.1h22.5V3.75H1Z",
          stroke: "#000",
          strokeOpacity: 0.2,
          strokeWidth: 0.5
        }
      ),
      /* @__PURE__ */ h("path", { d: "M1 4h22v16.6H1V4Z", fill: "#012169" }),
      /* @__PURE__ */ h(
        "path",
        {
          d: "m3.578 4 8.388 6.26L20.319 4H23v2.144l-8.25 6.19L23 18.49v2.11h-2.75L12 14.41 3.784 20.6H1v-2.075l8.216-6.156L1 6.213V4h2.578Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M15.575 13.718 23 19.217V20.6l-9.316-6.882h1.891Zm-6.325.692.206 1.21-6.6 4.98H1l8.25-6.19ZM23 4v.104l-8.56 6.501.07-1.521L21.28 4H23ZM1 4l8.216 6.087H7.153L1 5.452V4Z",
          fill: "#C8102E"
        }
      ),
      /* @__PURE__ */ h("path", { d: "M9.284 4v16.6h5.5V4h-5.5ZM1 9.533v5.534h22V9.533H1Z", fill: "#fff" }),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M1 10.675v3.32h22v-3.32H1ZM10.384 4v16.6h3.3V4h-3.3Z",
          fill: "#C8102E"
        }
      )
    ]
  }
);
export {
  v as default
};
