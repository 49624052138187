import { jsxs as s, jsx as t } from "react/jsx-runtime";
const o = (l) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M15.896 4.323v15.125c0 1.692 1.167 2.635 2.406 2.635 1.146 0 2.406-.802 2.406-2.635V4.438c0-1.552-1.146-2.521-2.406-2.521-1.26 0-2.406 1.069-2.406 2.406Z",
          fill: "#F9AB00"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M9.594 12v7.448c0 1.692 1.167 2.635 2.406 2.635 1.146 0 2.406-.802 2.406-2.635v-7.333c0-1.552-1.146-2.521-2.406-2.521-1.26 0-2.406 1.069-2.406 2.406ZM5.698 22.083a2.406 2.406 0 1 0 0-4.812 2.406 2.406 0 0 0 0 4.812Z",
          fill: "#E37400"
        }
      )
    ]
  }
);
export {
  o as default
};
