import { jsxs as t, jsx as a } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a("path", { d: "M10 15h5a.968.968 0 0 0 .713-.288A.967.967 0 0 0 16 14a.97.97 0 0 0-.287-.713A.97.97 0 0 0 15 13h-5a.97.97 0 0 0-.713.287A.97.97 0 0 0 9 14c0 .283.096.52.287.712.192.192.43.288.713.288Zm0-4h8c.283 0 .52-.096.712-.288A.965.965 0 0 0 19 10a.968.968 0 0 0-.288-.713A.967.967 0 0 0 18 9h-8a.97.97 0 0 0-.713.287A.97.97 0 0 0 9 10c0 .283.096.52.287.712.192.192.43.288.713.288Zm0-4h8c.283 0 .52-.096.712-.288A.965.965 0 0 0 19 6a.968.968 0 0 0-.288-.713A.967.967 0 0 0 18 5h-8a.97.97 0 0 0-.713.287A.97.97 0 0 0 9 6c0 .283.096.52.287.712.192.192.43.288.713.288ZM7 19c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 5 17V3c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 7 1h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 21 19H7Z" }),
      /* @__PURE__ */ a("path", { d: "M3 21V6a1 1 0 0 0-2 0v15a2 2 0 0 0 2 2h15a1 1 0 1 0 0-2H3Z" })
    ]
  }
);
export {
  c as default
};
