import { jsxs as a, jsx as l } from "react/jsx-runtime";
const i = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M22.062 1.768H7.054a.938.938 0 0 0-.938.937v4.179l8.698 2.558L23 6.884V2.705a.938.938 0 0 0-.938-.937Z",
          fill: "#95A0B1"
        }
      ),
      /* @__PURE__ */ l("path", { d: "M23 6.884H6.116V12l8.698 1.535L23 12V6.884Z", fill: "#68778D" }),
      /* @__PURE__ */ l("path", { d: "M6.116 12v5.116l8.186 1.024L23 17.116V12H6.116Z" }),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M7.054 22.233h15.008c.518 0 .938-.42.938-.938v-4.179H6.116v4.179c0 .518.42.938.938.938Z",
          fill: "#38404C"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.1,
          d: "M12.341 5.86H6.116v12.791h6.225a.94.94 0 0 0 .938-.938V6.799a.94.94 0 0 0-.938-.937Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.2,
          d: "M11.83 6.372H6.116v12.79h5.714a.941.941 0 0 0 .937-.937V7.31a.94.94 0 0 0-.937-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.2,
          d: "M11.83 6.372H6.116V18.14h5.714a.94.94 0 0 0 .937-.938V7.31a.94.94 0 0 0-.937-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          opacity: 0.2,
          d: "M11.318 6.372H6.116V18.14h5.202a.94.94 0 0 0 .938-.938V7.31a.94.94 0 0 0-.938-.938Z",
          fill: "#000"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M1.938 6.372h9.38c.518 0 .938.42.938.938v9.38c0 .518-.42.938-.938.938h-9.38A.938.938 0 0 1 1 16.69V7.31c0-.518.42-.938.938-.938Z",
          fill: "#596578"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M4.848 13.565c.019.145.031.27.037.378h.021c.008-.102.025-.225.051-.37.026-.144.05-.266.07-.365l.987-4.256h1.275l1.024 4.192c.059.26.102.525.127.79h.017c.019-.257.054-.513.106-.767L9.38 8.95h1.161l-1.433 6.099H7.75l-.972-4.04a9.451 9.451 0 0 1-.161-.863H6.6c-.012.099-.034.246-.066.442-.033.195-.06.34-.08.434l-.913 4.025h-1.38l-1.44-6.095h1.181l.889 4.264c.02.088.039.205.057.349Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  i as default
};
