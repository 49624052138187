import { jsxs as h, jsx as l } from "react/jsx-runtime";
const o = (e) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z",
          fill: "#C84031"
        }
      ),
      /* @__PURE__ */ l("path", { d: "M12.227 12a3.7 3.7 0 1 0-.227.227V18h6v-6h-5.773Z", fill: "#fff" })
    ]
  }
);
export {
  o as default
};
