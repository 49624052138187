import { Playground } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/typography/package.json";
import { TextMega, TextAlpha, TextBeta, TextGamma, TextDelta, TextEpsilon, BodyUI, TinyText, TextZeta } from "../../../../../packages/typography/src/index";
import * as React from 'react';
export default {
  Playground,
  PackageMetadata,
  pkg,
  TextMega,
  TextAlpha,
  TextBeta,
  TextGamma,
  TextDelta,
  TextEpsilon,
  BodyUI,
  TinyText,
  TextZeta,
  React
};