import { jsx as c } from "react/jsx-runtime";
const A = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M19.775 22.575 1.375 4.2a.918.918 0 0 1-.288-.7c.009-.267.113-.5.313-.7.2-.2.438-.3.713-.3.275 0 .512.1.712.3L21.2 21.175c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3Zm1.85-3.825L19.8 16.925 20.8 7h-9.55l-.1-.875a.993.993 0 0 1 .238-.787.923.923 0 0 1 .737-.338H16V2c0-.283.096-.521.288-.713A.967.967 0 0 1 17 1c.283 0 .52.096.712.287.192.192.288.43.288.713v3h3.9c.3 0 .55.108.75.325a.94.94 0 0 1 .25.775l-1.275 12.65ZM2 19a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 18c0-.283.096-.52.288-.712A.965.965 0 0 1 2 17h13c.283 0 .521.096.713.288A.967.967 0 0 1 16 18c0 .283-.096.52-.287.712A.968.968 0 0 1 15 19H2Zm0 4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 22c0-.283.096-.52.288-.712A.965.965 0 0 1 2 21h13c.283 0 .521.096.713.288A.967.967 0 0 1 16 22c0 .283-.096.52-.287.712A.968.968 0 0 1 15 23H2ZM9.05 9.025v2c-.083 0-.175-.004-.275-.013A3.47 3.47 0 0 0 8.5 11a7.76 7.76 0 0 0-2.787.5c-.875.333-1.555.833-2.038 1.5h9.35l2 2H2.1a.989.989 0 0 1-.775-.338.869.869 0 0 1-.225-.762c.317-1.65 1.2-2.88 2.65-3.688C5.2 9.404 6.783 9 8.5 9c.083 0 .175.004.275.012.1.009.192.013.275.013Z" })
  }
);
export {
  A as default
};
