import * as e from "react";
const t = (l) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m11.252 18.199 4.717-.808.044-.01-2.426-2.793a403.566 403.566 0 0 1-2.426-2.808c0-.013 2.505-6.693 2.52-6.717.004-.008 1.709 2.842 4.132 6.908l4.155 6.976L22 19l-7.71-.001H6.58l4.672-.8ZM2 17.347c0-.004 1.143-1.925 2.54-4.27l2.54-4.262 2.96-2.406C11.67 5.086 13.006 4.002 13.01 4a.5.5 0 0 1-.047.116l-3.214 6.676L6.59 17.35l-2.295.002c-1.263.002-2.296 0-2.296-.004Z",
      fill: "#0089D6"
    }
  )
);
export {
  t as default
};
