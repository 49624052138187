import * as React from "react";
const SvgIconGraph = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 83 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.854 27.75 22.739 12.72l18.5 9.636L59.354 4.24l18.885 8.864"
      stroke="#E8ECF2"
    />
    <path
      d="M0 53.097c0-.506.41-.916.915-.916h6.86c.505 0 .915.41.915.916V74H0V53.097ZM18.447 37.534c0-.506.41-.915.915-.915h6.86c.506 0 .915.41.915.915V74h-8.69V37.534ZM36.895 45.316c0-.506.41-.916.914-.916h6.86c.506 0 .916.41.916.916V74h-8.69V45.316ZM55.342 28.227c0-.506.41-.916.915-.916h6.86c.505 0 .915.41.915.916V74h-8.69V28.227ZM73.79 37.534c0-.506.409-.915.914-.915h6.86c.506 0 .915.41.915.915V74h-8.69V37.534ZM8.69 27.235a4.347 4.347 0 0 1-4.345 4.349A4.347 4.347 0 0 1 0 27.235a4.347 4.347 0 0 1 4.345-4.348c2.4 0 4.345 1.947 4.345 4.348ZM27.137 13.045a4.347 4.347 0 0 1-4.345 4.349 4.347 4.347 0 0 1-4.345-4.349 4.347 4.347 0 0 1 4.345-4.348c2.4 0 4.345 1.947 4.345 4.348ZM45.585 21.895a4.347 4.347 0 0 1-4.345 4.348 4.347 4.347 0 0 1-4.345-4.348 4.347 4.347 0 0 1 4.345-4.349c2.4 0 4.345 1.947 4.345 4.349ZM64.032 4.348a4.347 4.347 0 0 1-4.345 4.349 4.347 4.347 0 0 1-4.345-4.349A4.347 4.347 0 0 1 59.687 0c2.4 0 4.345 1.947 4.345 4.348ZM82.48 13.045a4.347 4.347 0 0 1-4.346 4.349 4.347 4.347 0 0 1-4.345-4.349 4.347 4.347 0 0 1 4.345-4.348c2.4 0 4.345 1.947 4.345 4.348Z"
      fill="#E8ECF2"
    />
  </svg>
);
export default SvgIconGraph;
