import * as React from "react";
const SvgIconLogoZoom = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    <path
      d="M8.607 14.803h5.016a.59.59 0 0 0 .59-.59V10.82c0-.897-.726-1.623-1.623-1.623H7.574a.59.59 0 0 0-.59.59v3.393c0 .897.726 1.623 1.623 1.623ZM14.656 11.157c0-.26.115-.508.314-.676l1.318-1.113a.443.443 0 0 1 .728.339v4.586c0 .377-.44.581-.728.339l-1.318-1.113a.885.885 0 0 1-.314-.676v-1.686Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIconLogoZoom;
