import * as React from "react";

const SvgIconAI = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M19.3001 6.42461L18.4501 4.54961L16.5751 3.69961L18.4501 2.84961L19.3001 0.974609L20.1501 2.84961L22.0251 3.69961L20.1501 4.54961L19.3001 6.42461ZM7.25008 6.42461L6.40008 4.54961L4.52508 3.69961L6.40008 2.84961L7.25008 0.974609L8.10008 2.84961L9.97508 3.69961L8.10008 4.54961L7.25008 6.42461ZM19.3001 18.4746L18.4501 16.5996L16.5751 15.7496L18.4501 14.8996L19.3001 13.0246L20.1501 14.8996L22.0251 15.7496L20.1501 16.5996L19.3001 18.4746ZM3.67508 21.6996L1.30008 19.3246C1.11674 19.1413 1.01674 18.9288 1.00008 18.6871C0.98341 18.4454 1.08341 18.2163 1.30008 17.9996L12.5501 6.74961C12.7501 6.54961 12.9917 6.44961 13.2751 6.44961C13.5584 6.44961 13.8001 6.54961 14.0001 6.74961L16.2501 8.99961C16.4501 9.19961 16.5501 9.44128 16.5501 9.72461C16.5501 10.0079 16.4501 10.2496 16.2501 10.4496L5.00008 21.6996C4.80008 21.8996 4.57924 21.9996 4.33758 21.9996C4.09591 21.9996 3.87508 21.8996 3.67508 21.6996ZM13.2501 11.2996L14.8001 9.74961L13.2501 8.19961L11.7001 9.74961L13.2501 11.2996Z" />
  </svg>
);

export default SvgIconAI;
