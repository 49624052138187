import { jsxs as t, jsx as a } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ a("path", { d: "M19.716 14.121A8 8 0 1 0 11 19.938v2.013C5.947 21.449 2 17.185 2 12 2 6.477 6.477 2 12 2s10 4.477 10 10a9.995 9.995 0 0 1-.467 3.03l-1.817-.909Z" }),
      /* @__PURE__ */ a("path", { d: "M14.694 22.95a.48.48 0 0 1-.694-.429v-2.687c0-.22.15-.412.363-.465l3.474-.868-3.474-.869a.48.48 0 0 1-.363-.465V14.48a.48.48 0 0 1 .694-.429l8.04 4.02a.48.48 0 0 1 0 .859l-8.04 4.02ZM11.002 8v3.6l-3 3a.948.948 0 0 0-.275.7c0 .283.092.517.275.7a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l3.3-3.3c.1-.1.175-.212.225-.337.05-.125.075-.255.075-.388V8a.967.967 0 0 0-.287-.713.967.967 0 0 0-.713-.287.968.968 0 0 0-.712.287.968.968 0 0 0-.288.713Z" })
    ]
  }
);
export {
  h as default
};
