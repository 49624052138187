import PropTypes from "prop-types";
import styled from "styled-components";
import {
  IconCheck,
  IconError,
  IconDelete,
  IconInfoRound,
  IconWarning,
} from "@happeouikit/icons";
import { MODAL_STATES as STATES } from "./Modal.types";

/**
 *
 * ModalIcon
 *
 */
const ModalIcon = ({ state }: { state: STATES }) => {
  let color: string = "";
  let iconColor: string = "";
  let Icon: any;

  switch (state) {
    case STATES.success:
      color = "var(--color-success-lighten90)";
      iconColor = "var(--color-success-primary)";
      Icon = IconCheck;
      break;
    case STATES.alert:
      color = "var(--color-alert-lighten90)";
      iconColor = "var(--color-alert-primary)";
      Icon = IconError;
      break;
    case STATES.delete:
      color = "var(--color-alert-lighten90)";
      iconColor = "var(--color-alert-primary)";
      Icon = IconDelete;
      break;
    case STATES.info:
      color = "var(--color-active-lighten90)";
      iconColor = "var(--color-active-primary)";
      Icon = IconInfoRound;
      break;
    case STATES.warning:
      color = "var(--color-warning-lighten90)";
      iconColor = "var(--color-warning-primary)";
      Icon = IconWarning;
      break;
    default:
      color = "var(--color-active-lighten90)";
      iconColor = "var(--color-active-primary)";
      Icon = IconCheck;
  }

  return (
    <IconWrapper color={color} iconColor={iconColor}>
      <Icon fontSize="large" />
    </IconWrapper>
  );
};

ModalIcon.propTypes = {
  state: PropTypes.oneOf(["alert", "delete", "success", "info", "warn"]),
};

ModalIcon.defaultProps = {
  state: STATES.success,
};

const IconWrapper = styled.div<{
  color: string;
  iconColor: string;
}>`
  display: flex;
  background: ${({ color }) => color};
  padding: var(--space-md, 16px);
  border-radius: var(--radius-circle, 50%);

  svg {
    fill: ${({ iconColor }) => iconColor};
    font-size: 32px;
  }
`;

export default ModalIcon;
