import { jsx as c } from "react/jsx-runtime";
const a = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M10.3 19.8c.75-1.517 1.75-2.53 3-3.038S15.617 16 16.5 16c.383 0 .758.033 1.125.1s.725.15 1.075.25a8.84 8.84 0 0 0 .95-2.05c.233-.733.35-1.5.35-2.3 0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 .75.096 1.467.287 2.15.192.683.48 1.317.863 1.9a10.82 10.82 0 0 1 2.125-.775A9.142 9.142 0 0 1 9.5 15c.533 0 1.046.046 1.537.137.492.092.98.213 1.463.363-.383.2-.746.433-1.088.7-.341.267-.662.55-.962.85-.2-.033-.37-.05-.512-.05H9.5c-.533 0-1.063.058-1.588.175A6.924 6.924 0 0 0 6.4 17.7c.533.533 1.13.98 1.787 1.338a7.448 7.448 0 0 0 2.113.762ZM12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm-2.5-8.5a3.372 3.372 0 0 1-2.475-1.025A3.372 3.372 0 0 1 6 10c0-.967.342-1.792 1.025-2.475A3.372 3.372 0 0 1 9.5 6.5c.967 0 1.792.342 2.475 1.025A3.372 3.372 0 0 1 13 10c0 .967-.342 1.792-1.025 2.475A3.372 3.372 0 0 1 9.5 13.5Zm0-2c.417 0 .77-.146 1.063-.438.291-.291.437-.645.437-1.062 0-.417-.146-.77-.438-1.063A1.447 1.447 0 0 0 9.5 8.5c-.417 0-.77.146-1.063.438A1.447 1.447 0 0 0 8 10c0 .417.146.77.438 1.063.291.291.645.437 1.062.437Zm7 3c-.7 0-1.292-.242-1.775-.725C14.242 13.292 14 12.7 14 12s.242-1.292.725-1.775C15.208 9.742 15.8 9.5 16.5 9.5s1.292.242 1.775.725C18.758 10.708 19 11.3 19 12s-.242 1.292-.725 1.775c-.483.483-1.075.725-1.775.725Z" })
  }
);
export {
  a as default
};
