import * as React from "react";
const SvgIconCenterTarget = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M13 5.07V3a1 1 0 1 0-2 0v2.07A7.005 7.005 0 0 0 5.07 11H3a1 1 0 1 0 0 2h2.07A7.004 7.004 0 0 0 11 18.93V21a1 1 0 1 0 2 0v-2.07A7.004 7.004 0 0 0 18.93 13H21a1 1 0 1 0 0-2h-2.07A7.004 7.004 0 0 0 13 5.07ZM11 9a1 1 0 1 0 2 0V7.1a5.006 5.006 0 0 1 3.9 3.9H15a1 1 0 1 0 0 2h1.9a5.006 5.006 0 0 1-3.9 3.9V15a1 1 0 1 0-2 0v1.9A5.006 5.006 0 0 1 7.1 13H9a1 1 0 1 0 0-2H7.1A5.006 5.006 0 0 1 11 7.1V9Z" />
  </svg>
);
export default SvgIconCenterTarget;
