import * as React from "react";
const SvgIconPagegroupOld = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M14.5 3h-9C4.675 3 4 3.675 4 4.5V15h1.5V4.5h9V3Zm-3-3h-9C1.675 0 1 .675 1 1.5V12h1.5V1.5h9V0Zm5.25 6H8.5C7.675 6 7 6.675 7 7.5V18c0 .825.675 1.5 1.5 1.5h8.25c.825 0 1.5-.675 1.5-1.5V7.5c0-.825-.675-1.5-1.5-1.5Zm0 12H8.5V7.5h8.25V18Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconPagegroupOld;
