import { jsx as a } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M21 7a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2h16a1 1 0 0 1 1 1Zm-7.75 10.306c.18-.612-.236-1.306-.874-1.306H11a1 1 0 1 0 0 2h1.387c.405 0 .748-.305.863-.694ZM18 12a1 1 0 0 0-1-1H7a1 1 0 1 0 0 2h10a1 1 0 0 0 1-1Zm3.83 4.17a1.001 1.001 0 0 0-1.417 0L19 17.59l-1.41-1.418a1 1 0 1 0-1.416 1.412L17.59 19l-1.416 1.416a1 1 0 1 0 1.415 1.412L19 20.41l1.413 1.42a1.001 1.001 0 1 0 1.417-1.417L20.41 19l1.42-1.413a1.001 1.001 0 0 0 0-1.417Z" })
  }
);
export {
  h as default
};
