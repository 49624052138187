import { jsxs as c, jsx as l } from "react/jsx-runtime";
const i = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M22.766 12.248c0-.725-.064-1.412-.174-2.081H12.234V14.3h5.93c-.265 1.357-1.044 2.502-2.2 3.281v2.75h3.54c2.07-1.915 3.262-4.739 3.262-8.085Z",
          fill: "#38404C"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M12.234 23c2.97 0 5.454-.99 7.27-2.668l-3.54-2.75c-.99.66-2.245 1.064-3.73 1.064-2.87 0-5.298-1.934-6.17-4.547H2.418v2.833C4.221 20.525 7.934 23 12.233 23Z",
          fill: "#68778D"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M6.065 14.1a6.383 6.383 0 0 1-.349-2.1c0-.733.129-1.44.349-2.1V7.069H2.416a10.872 10.872 0 0 0 0 9.864l3.649-2.833Z",
          fill: "#95A0B1"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M12.234 5.354c1.623 0 3.07.56 4.217 1.65l3.135-3.135C17.688 2.091 15.204 1 12.234 1c-4.3 0-8.012 2.475-9.817 6.068l3.648 2.833c.87-2.613 3.3-4.547 6.169-4.547Z",
          fill: "#596578"
        }
      )
    ]
  }
);
export {
  i as default
};
