/**
 *
 * Default Loader component
 *
 */
import PropTypes from "prop-types";
import styled from "styled-components";
import { gray04 } from "@happeouikit/colors";
import { TextGamma, BodyUI } from "@happeouikit/typography";

import Spinner from "./Spinner";

const LoadingContainer = ({
  title,
  description,
  state,
  progress,
  showPercentage,
}) => (
  <Container>
    <Spinner
      state={state}
      progress={progress}
      showPercentage={showPercentage}
    />

    <div role="alert" aria-live="assertive" key={`${title}${description}`}>
      <TextGamma as="span">{title || "Title is missing"}</TextGamma>
      {description && description.length > 0 && (
        <BodyUI color={gray04} style={{ marginTop: "16px" }}>
          {description}
        </BodyUI>
      )}
    </div>
  </Container>
);

LoadingContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  state: PropTypes.oneOf(["loading", "success", "error", "progress"]),
  progress: PropTypes.number,
  showPercentage: PropTypes.bool,
};

LoadingContainer.defaultProps = {
  description: "",
  state: "loading",
  progress: 0,
  showPercentage: false,
};

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
`;

export default LoadingContainer;
