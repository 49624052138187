import { jsxs as l, jsx as c } from "react/jsx-runtime";
const o = (a) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ c("path", { d: "m20.075 5.513-1.4 1.375a.977.977 0 0 1-.688.288.93.93 0 0 1-.712-.288.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l1.4-1.4-1.4-1.4a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l1.4 1.4 1.4-1.4A.977.977 0 0 1 22.163 1a.93.93 0 0 1 .712.288.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-1.375 1.4 1.375 1.4c.183.183.28.412.288.688a.93.93 0 0 1-.288.712.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-1.4-1.375Z" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M20 9c.513 0 1.009-.077 1.475-.221A9.837 9.837 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c1.13 0 2.203.175 3.221.524A5 5 0 0 0 20 9Zm-4.5 2c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C16.292 8.142 15.933 8 15.5 8c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm-7 0c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C9.292 8.142 8.933 8 8.5 8c-.433 0-.792.142-1.075.425C7.142 8.708 7 9.067 7 9.5c0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm3.5 6.5c1.033 0 1.958-.25 2.775-.75a5.671 5.671 0 0 0 1.95-1.975c.117-.2.125-.379.025-.537-.1-.159-.267-.238-.5-.238h-8.5c-.233 0-.4.08-.5.238-.1.158-.092.337.025.537a5.672 5.672 0 0 0 1.95 1.975c.817.5 1.742.75 2.775.75Z"
        }
      )
    ]
  }
);
export {
  o as default
};
