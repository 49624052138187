import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 32 28",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement("rect", { width: 32, height: 28, fill: "#878A90", rx: 2 }), /* @__PURE__ */ e.createElement("path", { fill: "#FFF", d: "M26 9H6V7h20v2Zm-6 6H6v-2h14v2Zm3 6H6v-2h17v2Z" }))
);
export {
  l as default
};
