import { jsx as h } from "react/jsx-runtime";
const v = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M9 4.414a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5h1.586c.89 0 1.337 1.077.707 1.707l-4.586 4.586a1 1 0 0 1-1.414 0l-4.586-4.586c-.63-.63-.184-1.707.707-1.707H9v-5Zm3 9.17 2.17-2.569H13v-5.6h-2v5.6H9.83l2.17 2.57ZM5 19a1 1 0 0 1 1-1h12a1 1 0 0 1 0 2H6a1 1 0 0 1-1-1Z" })
  }
);
export {
  v as default
};
