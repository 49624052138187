/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const MicrosoftExcel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.814 11.489 6.116 9.954v11.34c0 .519.42.939.938.939h15.008c.518 0 .938-.42.938-.938v-4.179l-8.186-5.627Z"
      fill="#38404C"
    />
    <path
      d="M14.814 1.768h-7.76a.938.938 0 0 0-.938.937v4.179L14.814 12l4.605 1.535L22.998 12V6.884l-8.185-5.116Z"
      fill="#68778D"
    />
    <path d="M6.116 6.884h8.698V12H6.116V6.884Z" />
    <path
      opacity={0.1}
      d="M12.341 5.86H6.116v12.791h6.225a.94.94 0 0 0 .938-.938V6.799a.94.94 0 0 0-.938-.937Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M11.83 6.372H6.116v12.79h5.714a.94.94 0 0 0 .937-.937V7.31a.94.94 0 0 0-.937-.938Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M11.83 6.372H6.116V18.14h5.714a.94.94 0 0 0 .937-.938V7.31a.94.94 0 0 0-.937-.938Z"
      fill="#000"
    />
    <path
      opacity={0.2}
      d="M11.318 6.372H6.116V18.14h5.202a.94.94 0 0 0 .938-.938V7.31a.94.94 0 0 0-.938-.938Z"
      fill="#000"
    />
    <path
      d="M1.938 6.372h9.38c.518 0 .938.42.938.938v9.38c0 .518-.42.938-.938.938h-9.38A.938.938 0 0 1 1 16.69V7.31c0-.518.42-.938.938-.938Z"
      fill="#596578"
    />
    <path
      d="m3.905 15.048 1.972-3.057-1.807-3.04h1.454l.986 1.944c.091.185.154.322.188.413h.012c.065-.148.133-.29.205-.43l1.054-1.926h1.335l-1.853 3.022 1.9 3.074h-1.42l-1.14-2.134c-.053-.09-.099-.186-.135-.285h-.017a1.35 1.35 0 0 1-.132.277l-1.173 2.142h-1.43Z"
      fill="#fff"
    />
    <path
      d="M22.062 1.768h-7.248v5.116H23V2.705a.938.938 0 0 0-.938-.937Z"
      fill="#95A0B1"
    />
    <path d="M14.814 12H23v5.116h-8.186V12Z" />
  </svg>
);

export default MicrosoftExcel;
