import { jsx as c } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M15.3 8.825 14.225 7.75a.438.438 0 0 0-.35-.138.529.529 0 0 0-.35.163.48.48 0 0 0 0 .7L14.6 9.55c.2.2.433.3.7.3.267 0 .5-.1.7-.3l2.475-2.5a.48.48 0 0 0 0-.7.48.48 0 0 0-.7 0L15.3 8.825ZM4 20a.967.967 0 0 1-.712-.288A.968.968 0 0 1 3 19c0-.283.096-.52.288-.712A.967.967 0 0 1 4 18h10c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 14 20H4Zm12-7c-1.383 0-2.563-.488-3.537-1.463C11.488 10.563 11 9.383 11 8s.488-2.563 1.463-3.537C13.438 3.487 14.617 3 16 3s2.563.487 3.538 1.463C20.512 5.437 21 6.617 21 8s-.488 2.563-1.462 3.537C18.562 12.512 17.383 13 16 13ZM4 12a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 11c0-.283.096-.52.288-.713A.967.967 0 0 1 4 10h5.3c.117.367.25.717.4 1.05.15.333.333.65.55.95H4Zm0 4a.967.967 0 0 1-.712-.287A.968.968 0 0 1 3 15c0-.283.096-.52.288-.713A.967.967 0 0 1 4 14h8.4c.383.233.792.43 1.225.588.433.158.892.27 1.375.337V15c0 .283-.096.52-.287.713A.968.968 0 0 1 14 16H4Z" })
  }
);
export {
  t as default
};
