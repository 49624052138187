import * as React from "react";
const SvgIconNotificationsActive = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.004 10.75v5l1.28 1.29c.63.63.18 1.71-.71 1.71H5.404c-.89 0-1.33-1.08-.7-1.71l1.3-1.29v-5c0-3.08 1.63-5.64 4.5-6.32v-.68c0-.83.66-1.5 1.49-1.5s1.51.67 1.51 1.5v.68c2.86.68 4.5 3.25 4.5 6.32Zm-4.01 9a2 2 0 1 1-4 0h4ZM6.774 4.48c.42-.38.43-1.03.03-1.43a1 1 0 0 0-1.39-.02 10.424 10.424 0 0 0-3.27 6.06c-.09.61.38 1.16 1 1.16.48 0 .9-.35.98-.83a8.44 8.44 0 0 1 2.65-4.94Zm10.43-1.43c.38-.38 1-.39 1.4-.02 1.7 1.57 2.87 3.68 3.25 6.05.1.61-.38 1.16-.99 1.16-.49 0-.91-.35-.98-.83a8.495 8.495 0 0 0-2.65-4.94.982.982 0 0 1-.03-1.42Z"
    />
  </svg>
);
export default SvgIconNotificationsActive;
