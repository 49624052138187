import { jsx as t } from "react/jsx-runtime";
const c = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "m19.1 21.9-3.5-3.5-2.725 2.724a1.512 1.512 0 0 1-.45.313 1.214 1.214 0 0 1-.5.112.878.878 0 0 1-.663-.275.973.973 0 0 1-.262-.7V14.4l-3.9 3.9a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7l4.2-4.2-7.8-7.8a.948.948 0 0 1-.275-.7c0-.283.092-.516.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l17 17a.949.949 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM13 18.15 14.15 17 13 15.85v2.3Zm1.1-6.85-1.4-1.4 2.2-2.2L13 5.85v4.35l-2-2V3.423c0-.283.087-.516.262-.7a.878.878 0 0 1 .663-.275c.167 0 .333.038.5.112.167.076.317.18.45.313L17 7c.1.1.171.209.213.325.041.117.062.242.062.375 0 .134-.02.259-.062.375A.883.883 0 0 1 17 8.4l-2.9 2.9Z" })
  }
);
export {
  c as default
};
