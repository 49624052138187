import { useIntl } from "react-intl";

import { IconSearch } from "@happeouikit/icons";
import messages from "./messages";
import TypeaheadMessage from "./TypeaheadMessage";

const TypeaheadNoResults = ({ query }) => {
  const intl = useIntl();
  return (
    <TypeaheadMessage
      icon={IconSearch}
      title={intl.formatMessage(messages.typeaheadNoResults)}
      details={intl.formatMessage(messages.typeaheadNoResultsDetails, {
        query,
      })}
    />
  );
};

export default TypeaheadNoResults;
