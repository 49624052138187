import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M20.525 21.9c-.2.2-.442.3-.725.3a.908.908 0 0 1-.7-.3l-1.575-1.55c-.817.533-1.692.942-2.625 1.225a9.844 9.844 0 0 1-2.875.425 9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.613-1.958-2.138-3.175a9.743 9.743 0 0 1-.787-3.9c0-.983.142-1.942.425-2.875A10.19 10.19 0 0 1 3.675 6.5L2.1 4.925a.933.933 0 0 1-.288-.713c.009-.275.113-.512.313-.712a.948.948 0 0 1 .7-.275c.283 0 .525.092.725.275l16.975 17c.2.2.296.437.287.712a.976.976 0 0 1-.287.688Zm-.15-4.4-5.45-5.45 2.15-4.6c.083-.167.062-.313-.063-.438s-.27-.145-.437-.062l-4.6 2.15-5.45-5.45a10.244 10.244 0 0 1 2.612-1.225A9.809 9.809 0 0 1 12.025 2c1.383 0 2.683.262 3.9.787A10.105 10.105 0 0 1 19.1 4.925c.9.9 1.613 1.958 2.138 3.175a9.742 9.742 0 0 1 .787 3.9 9.8 9.8 0 0 1-.425 2.887 10.24 10.24 0 0 1-1.225 2.613ZM7.45 17.075l4.625-2.175-3-3-2.125 4.675c-.083.167-.062.312.063.437s.27.146.437.063Z" })
  }
);
export {
  t as default
};
