/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Image = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z"
      fill="#C43333"
    />
    <path
      d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM6.56 18h10.88c.218 0 .381-.098.49-.294.109-.196.09-.383-.054-.562l-2.993-3.93a.52.52 0 0 0-.435-.214.52.52 0 0 0-.435.214l-2.83 3.716-2.012-2.647a.52.52 0 0 0-.435-.213.52.52 0 0 0-.435.213l-2.177 2.861c-.145.179-.163.366-.054.562a.527.527 0 0 0 .49.294Z"
      fill="#fff"
    />
  </svg>
);

export default Image;
