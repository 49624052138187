import { ColorString } from "../Colors/types";
import { basicDesignTokens } from "../DesignTokens/designTokens";

// note:  in our design system, color scale is either from 0-100 or 0-1000, a bit inconsistent, where 0 is usually darkest but not always.
type Colors = { [colorName: string]: { [shadeScale: string]: ColorString } };
const { palette, neutral } = basicDesignTokens.primitives as {
  palette: Colors;
  neutral: Colors;
};
const { blue, green, orange, navy, pink, red, violet, yellow } = palette;
const { black, gray, white } = neutral;

export const colorPrimitives = {
  neutral: {
    transparent: "rgba(0, 0, 0, 0)",

    black4: black[4],
    black10: black[10],
    black20: black[20],
    black30: black[30],
    black40: black[40],
    black50: black[50],
    black60: black[60],
    black70: black[70],
    black80: black[80],
    black90: black[90],
    black100: black[100],

    white4: white[4],
    white10: white[10],
    white20: white[20],
    white30: white[30],
    white40: white[40],
    white50: white[50],
    white60: white[60],
    white70: white[70],
    white80: white[80],
    white90: white[90],
    white100: white[100],

    gray50: gray[50],
    gray100: gray[100],
    gray200: gray[200],
    gray300: gray[300],
    gray400: gray[400],
    gray500: gray[500],
    gray600: gray[600],
    gray700: gray[700],
    gray800: gray[800],
    gray900: gray[900],
  },
  palette: {
    red50: red[50],
    red100: red[100],
    red200: red[200],
    red300: red[300],
    red400: red[400],
    red500: red[500],
    red600: red[600],
    red700: red[700],
    red800: red[800],
    red900: red[900],
    pink50: pink[50],
    pink100: pink[100],
    pink200: pink[200],
    pink300: pink[300],
    pink400: pink[400],
    pink500: pink[500],
    pink600: pink[600],
    pink700: pink[700],
    pink800: pink[800],
    pink900: pink[900],
    orange50: orange[50],
    orange100: orange[100],
    orange200: orange[200],
    orange300: orange[300],
    orange400: orange[400],
    orange500: orange[500],
    orange600: orange[600],
    orange700: orange[700],
    orange800: orange[800],
    orange900: orange[900],
    violet50: violet[50],
    violet100: violet[100],
    violet200: violet[200],
    violet300: violet[300],
    violet400: violet[400],
    violet500: violet[500],
    violet600: violet[600],
    violet700: violet[700],
    violet800: violet[800],
    violet900: violet[900],
    blue50: blue[50],
    blue100: blue[100],
    blue200: blue[200],
    blue300: blue[300],
    blue400: blue[400],
    blue500: blue[500],
    blue600: blue[600],
    blue700: blue[700],
    blue800: blue[800],
    blue900: blue[900],
    navy50: navy[50],
    navy100: navy[100],
    navy200: navy[200],
    navy300: navy[300],
    navy400: navy[400],
    navy500: navy[500],
    navy600: navy[600],
    navy700: navy[700],
    navy800: navy[800],
    navy900: navy[900],
    green50: green[50],
    green100: green[100],
    green200: green[200],
    green300: green[300],
    green400: green[400],
    green500: green[500],
    green600: green[600],
    green700: green[700],
    green800: green[800],
    green900: green[900],
    yellow50: yellow[50],
    yellow100: yellow[100],
    yellow200: yellow[200],
    yellow300: yellow[300],
    yellow400: yellow[400],
    yellow500: yellow[500],
    yellow600: yellow[600],
    yellow700: yellow[700],
    yellow800: yellow[800],
    yellow900: yellow[900],
  },
} as const;
