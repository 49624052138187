import { jsx as A } from "react/jsx-runtime";
const v = (c) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ A("path", { d: "M2 6a.967.967 0 0 1-.712-.287A.968.968 0 0 1 1 5V4c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 4 1h1a.97.97 0 0 1 .713.287A.97.97 0 0 1 6 2c0 .283-.096.52-.287.712A.968.968 0 0 1 5 3H4a.967.967 0 0 0-.712.287A.968.968 0 0 0 3 4v1a.97.97 0 0 1-.287.713A.97.97 0 0 1 2 6Zm2 17a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 1 20v-1c0-.283.096-.52.288-.712A.965.965 0 0 1 2 18c.283 0 .521.096.713.288A.967.967 0 0 1 3 19v1c0 .283.096.52.288.712A.965.965 0 0 0 4 21h1c.283 0 .521.096.713.288A.967.967 0 0 1 6 22c0 .283-.096.52-.287.712A.968.968 0 0 1 5 23H4Zm15 0a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 22c0-.283.096-.52.288-.712A.965.965 0 0 1 19 21h1c.283 0 .52-.096.712-.288A.965.965 0 0 0 21 20v-1c0-.283.096-.52.288-.712A.965.965 0 0 1 22 18c.283 0 .52.096.712.288A.965.965 0 0 1 23 19v1c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 20 23h-1Zm3-17a.967.967 0 0 1-.712-.287A.968.968 0 0 1 21 5V4a.968.968 0 0 0-.288-.713A.967.967 0 0 0 20 3h-1a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 2c0-.283.096-.521.288-.713A.967.967 0 0 1 19 1h1c.833 0 1.542.292 2.125.875S23 3.167 23 4v1a.968.968 0 0 1-.288.713A.967.967 0 0 1 22 6ZM11 19.55l-5-2.875a1.955 1.955 0 0 1-.737-.737A2.016 2.016 0 0 1 5 14.925V9.2c0-.367.088-.704.263-1.013.175-.308.42-.554.737-.737l5-2.875c.317-.183.65-.275 1-.275s.683.092 1 .275l5 2.875c.317.183.563.429.738.737.175.309.262.646.262 1.013v5.725c0 .367-.087.704-.262 1.013a1.962 1.962 0 0 1-.738.737l-5 2.875c-.317.183-.65.275-1 .275s-.683-.092-1-.275Zm0-2.3v-4.6l-4-2.3v4.6l4 2.3Zm2 0 4-2.3v-4.6l-4 2.3v4.6Zm-1-6.35 4-2.3-4-2.3-4 2.3 4 2.3Z" })
  }
);
export {
  v as default
};
