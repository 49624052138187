import { jsx as c } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M12 21c-2.517 0-4.646-.87-6.388-2.613C3.871 16.647 3 14.517 3 12c0-2.3.75-4.296 2.25-5.988C6.75 4.322 8.667 3.334 11 3.05c.417-.05.742.1.975.45.233.35.225.717-.025 1.1a5.16 5.16 0 0 0-.85 2.9c0 1.5.525 2.775 1.575 3.825C13.725 12.375 15 12.9 16.5 12.9c.517 0 1.03-.075 1.538-.225a4.98 4.98 0 0 0 1.362-.625c.35-.233.708-.246 1.075-.038.367.21.525.538.475.988-.233 2.3-1.212 4.209-2.937 5.725C16.288 20.242 14.283 21 12 21Zm0-2c1.467 0 2.783-.404 3.95-1.212a7.016 7.016 0 0 0 2.55-3.163c-.333.084-.667.15-1 .2-.333.05-.667.075-1 .075-2.05 0-3.796-.72-5.238-2.162C9.821 11.296 9.1 9.55 9.1 7.5c0-.333.025-.666.075-1 .05-.333.117-.666.2-1a7.01 7.01 0 0 0-3.162 2.55C5.404 9.217 5 10.534 5 12c0 1.934.683 3.584 2.05 4.95C8.417 18.317 10.067 19 12 19Z" })
  }
);
export {
  h as default
};
