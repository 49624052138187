export const safeTagsAndAttributes = {
  a: [
    "class",
    "target",
    "href",
    "title",
    "data-collection-id",
    "data-page-id",
    "rel",
    "data-user-id",
    "data-user-id-old",
  ],
  abbr: ["title"],
  address: [],
  area: ["shape", "coords", "href", "alt"],
  article: [],
  aside: [],
  audio: ["autoplay", "controls", "loop", "preload", "src"],
  b: [],
  bdi: ["dir"],
  bdo: ["dir"],
  big: [],
  blockquote: ["cite"],
  br: [],
  caption: [],
  center: [],
  cite: [],
  code: ["id"],
  col: ["align", "valign", "span", "width"],
  colgroup: ["align", "valign", "span", "width"],
  dd: [],
  del: ["datetime"],
  details: ["open"],
  div: ["style"],
  dl: [],
  dt: [],
  em: [],
  font: ["color", "size", "face", "style"],
  footer: [],
  h1: ["style"],
  h2: ["style"],
  h3: ["style"],
  h4: ["style"],
  h5: ["style"],
  h6: ["style"],
  header: ["style"],
  hr: ["style", "class"],
  i: [],
  img: ["src", "alt", "title", "width", "height", "style", "class", "type"],
  ins: ["datetime"],
  li: ["style"],
  mark: [],
  nav: [],
  ol: ["style"],
  p: ["style"],
  pre: ["id", "style"],
  s: [],
  section: [],
  small: [],
  span: ["style", "class"],
  sub: [],
  sup: [],
  strong: [],
  table: ["width", "border", "align", "valign", "style", "class"],
  tbody: ["align", "valign", "style", "class"],
  td: ["width", "rowspan", "colspan", "align", "valign", "style", "class"],
  tfoot: ["align", "valign", "style", "class"],
  th: ["width", "rowspan", "colspan", "align", "valign", "style", "class"],
  thead: ["align", "valign", "style", "class"],
  tr: ["rowspan", "align", "valign", "style", "class"],
  tt: [],
  u: [],
  ul: ["style", "class"],
  video: [
    "style",
    "autoplay",
    "controls",
    "loop",
    "preload",
    "src",
    "height",
    "width",
  ],
  iframe: [
    "src",
    "frameborder",
    "allow",
    "sandbox",
    "referrerpolicy",
    "allowfullscreen",
    "style",
    "class",
  ],
};

const HIGHLIGHT_JS_THEME = "a11y-light";

export const MONOSPACE_FONT_URL =
  "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap";
export const HIGHLIGHJS_JS = [
  "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/10.6.0/highlight.min.js",
];
export const HIGHLIGHJS_CSS = [
  "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/10.6.0/styles/default.min.css",
  `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/10.6.0/styles/${HIGHLIGHT_JS_THEME}.min.css`,
];
export const ELEMENT_TYPE_PRE = "PRE";
export const ELEMENT_TYPE_CODE = "CODE";
export const CODE_BLOCK_AVAILABLE_LANGUAGES = [
  "javascript",
  "html",
  "css",
  "xml",
  "json",
  "java",
  "php",
  "typescript",
];
