import { jsx as c } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M4 21a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 20c0-.283.096-.52.288-.712A.965.965 0 0 1 4 19h16c.283 0 .52.096.712.288A.965.965 0 0 1 21 20c0 .283-.096.52-.288.712A.965.965 0 0 1 20 21H4ZM4 5a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3 4c0-.283.096-.521.288-.713A.967.967 0 0 1 4 3h16c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 5H4ZM2 17a.965.965 0 0 1-.712-.288A.965.965 0 0 1 1 16V8c0-.283.096-.521.288-.713A.967.967 0 0 1 2 7h20c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .283-.096.52-.288.712A.965.965 0 0 1 22 17H2Z" })
  }
);
export {
  h as default
};
