import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14ZM9.8 13.4h2.5c1.5 0 2.16-.28 2.8-.82.64-.55.9-1.33.9-2.35 0-.97-.25-1.73-.9-2.35-.65-.59-1.27-.88-2.8-.88H8v10h1.8v-3.6Zm0-5V12h2.42c.68 0 1.06-.19 1.38-.5.32-.31.5-.7.5-1.26 0-.52-.17-.89-.5-1.24-.33-.35-.84-.6-1.5-.6H9.8Z"
    }
  )
);
export {
  a as default
};
