import * as e from "react";
const t = (o) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...o
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M3.642 8.724s-1.586 1.577-1.02 5.182c.565 3.604 3.58 5.53 5.264 6.25a25.326 25.326 0 0 1-2.518 2.842s3.16.138 5.754-2.027c3.812.33 7.38-1.247 9.385-5.152 2.006-3.905.916-8.877-2.757-11.684-3.673-2.807-7.52-2.039-7.52-2.039s4.156-.408 5.963 2.105c1.557 2.16 1.598 4.612-.058 7.126-1.743 2.65-4.209 3.574-7.124 3.76-3.177.204-7.304-2.339-5.369-6.363Z",
      fill: "url(#Icon-logo-jabber-color_svg__a)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M17.08 8.424s.63-4.433-3.83-5.884c-2.373-.774-5.212-.096-6.856 1.097C4.68 4.88 3.869 6.774 3.986 7.098c.14.396 1.387-1.163 3.83-1.775 2.53-.63 6.541-.108 9.264 3.1Z",
      fill: "url(#Icon-logo-jabber-color_svg__b)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M10.86 14.59s.413-1.608.046-3.323c-.735-3.383-3.241-4.715-5.9-3.605 3.766-2.1 8.885 1.14 9.083 5.506-1.009 1.02-3.23 1.421-3.23 1.421Z",
      fill: "url(#Icon-logo-jabber-color_svg__c)"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M4.184 12.958s-1.13-.797-.868-2.915c.449-3.616 6.78-3.868 7.49 1.536-1.264-.948-5.153-1.422-6.622 1.38Z",
      fill: "url(#Icon-logo-jabber-color_svg__d)"
    }
  ),
  /* @__PURE__ */ e.createElement("defs", null, /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-logo-jabber-color_svg__a",
      x1: 12.002,
      y1: 22.998,
      x2: 12.002,
      y2: 2.006,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { stopColor: "#0D3095" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.115, stopColor: "#0F3C9D" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.319, stopColor: "#135AB4" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.589, stopColor: "#1A8CD8" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.685, stopColor: "#1D9FE6" })
  ), /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-logo-jabber-color_svg__b",
      x1: 3.97,
      y1: 5.318,
      x2: 17.108,
      y2: 5.318,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { stopColor: "#1D9FE6" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.518, stopColor: "#1B93D4" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#198BC9" })
  ), /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-logo-jabber-color_svg__c",
      x1: 4.594,
      y1: 8.753,
      x2: 14.371,
      y2: 12.401,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { offset: 0.315, stopColor: "#1D9FE6" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.373, stopColor: "#1C9AE2" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.774, stopColor: "#187BCC" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 1, stopColor: "#1670C4" })
  ), /* @__PURE__ */ e.createElement(
    "linearGradient",
    {
      id: "Icon-logo-jabber-color_svg__d",
      x1: 7.036,
      y1: 12.955,
      x2: 7.036,
      y2: 7.408,
      gradientUnits: "userSpaceOnUse"
    },
    /* @__PURE__ */ e.createElement("stop", { stopColor: "#1670C4" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.276, stopColor: "#187BCC" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.767, stopColor: "#1C9AE2" }),
    /* @__PURE__ */ e.createElement("stop", { offset: 0.837, stopColor: "#1D9FE6" })
  ))
);
export {
  t as default
};
