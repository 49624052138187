import { jsx as c } from "react/jsx-runtime";
const l = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M4 5c.283 0 .52.096.713.287.191.192.287.43.287.713v3c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 8 12h9.2l-2.925-2.925A.918.918 0 0 1 14 8.4c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l4.6 4.6c.1.1.17.208.212.325.042.117.063.242.063.375s-.02.258-.063.375a.877.877 0 0 1-.212.325l-4.625 4.625A.918.918 0 0 1 15 18.6a.96.96 0 0 1-.7-.3.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l2.9-2.9H8c-1.383 0-2.563-.488-3.537-1.463C3.487 11.563 3 10.383 3 9V6c0-.283.096-.52.288-.713A.968.968 0 0 1 4 5Z" })
  }
);
export {
  l as default
};
