import * as e from "react";
const h = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z" })
);
export {
  h as default
};
