import { jsx as t } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ t("path", { d: "M3 22a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2 21V3c0-.283.096-.521.288-.713A.967.967 0 0 1 3 2a.97.97 0 0 1 .713.287A.97.97 0 0 1 4 3v18c0 .283-.096.52-.287.712A.968.968 0 0 1 3 22Zm4.5-12c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 6 8.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 7.5 7h13c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 20.5 10h-13Zm0 7c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 6 15.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 7.5 14h7c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 14.5 17h-7Z" })
  }
);
export {
  h as default
};
