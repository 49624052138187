import { jsxs as d, jsx as c } from "react/jsx-runtime";
const l = (e) => /* @__PURE__ */ d(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ c("path", { d: "M14.577 14.912v-3.385c-.07-.015-.139-.03-.208-.038-.084-.008-.169-.015-.254-.015-.3 0-.6.061-.869.2-.261.13-.484.323-.654.569-.169.246-.254.577-.254.977-.007.269.039.538.131.792.077.208.193.392.346.546.147.139.324.246.524.308.207.07.423.1.638.1.115 0 .223-.008.323-.015.1.007.192-.008.277-.039Z" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M17.813 3H6.188C4.425 3 3 4.462 3 6.27v11.46C3 19.539 4.425 21 6.188 21h11.625C19.575 21 21 19.538 21 17.73V6.27C21 4.461 19.575 3 17.812 3Zm-3.99 13.45a3.825 3.825 0 0 1-1.654-.346 2.682 2.682 0 0 1-1.161-1.046c-.285-.469-.423-1.054-.423-1.754a3.16 3.16 0 0 1 1.646-2.815c.538-.3 1.184-.446 1.946-.446.038 0 .092 0 .162.008.037.004.076.006.119.008.037.001.076.003.119.007v-2.43c0-.055.023-.085.077-.085h1.561c.039-.008.07.023.077.053v7.339c0 .126.007.264.014.415l.002.046c.013.137.02.268.027.384l.003.063c0 .053-.023.1-.076.123-.4.169-.824.292-1.254.369-.385.07-.785.108-1.185.108ZM9.377 8.313v7.923c0 .085-.039.123-.108.123H7.762c-.07 0-.1-.038-.1-.123V8.312c0-.07.038-.1.107-.1h1.5a.087.087 0 0 1 .1.077c.008.008.008.015.008.023Z"
        }
      )
    ]
  }
);
export {
  l as default
};
