import * as React from "react";
const SvgIconBookmarkBorder = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3h10c1.1 0 2 .9 2 2v16l-7-3-7 3V5c0-1.1.9-2 2-2Zm5 12.82L17 18V6c0-.55-.45-1-1-1H8c-.55 0-1 .45-1 1v12l5-2.18Z"
    />
  </svg>
);
export default SvgIconBookmarkBorder;
