/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const DropboxAppcenter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.301 8.764c0 1.252-.223 2.333-.657 3.241-.983-2.06-3.043-3.184-5.988-3.184-2.948 0-5.008 1.124-5.992 3.184C2.224 11.097 2 10.012 2 8.764c0-4.093 2.412-6.42 6.649-6.42 4.24 0 6.652 2.327 6.652 6.42Z"
      fill="#68778D"
    />
    <path
      d="M8.649 8.817c1.296 0 2.419.217 3.356.635-2.134.947-3.296 2.937-3.296 5.78 0 2.845 1.162 4.835 3.296 5.785-.937.419-2.06.635-3.356.635C4.412 21.655 2 19.329 2 15.232c0-4.088 2.412-6.415 6.649-6.415Z"
      fill="#2B313B"
    />
    <path
      d="M8.703 15.236c0-1.252.224-2.333.657-3.241.984 2.06 3.044 3.185 5.988 3.185 2.948 0 5.008-1.125 5.992-3.185.433.904.657 1.99.657 3.241 0 4.09-2.411 6.42-6.649 6.42-4.233 0-6.645-2.327-6.645-6.42Z"
      fill="#68778D"
    />
    <path
      d="M15.355 15.183c-1.297 0-2.42-.216-3.357-.635 2.135-.947 3.296-2.936 3.296-5.78 0-2.845-1.161-4.834-3.296-5.785.942-.422 2.064-.638 3.357-.638C19.592 2.345 22 4.67 22 8.764c.004 4.092-2.408 6.419-6.645 6.419Z"
      fill="#2B313B"
    />
  </svg>
);

export default DropboxAppcenter;
