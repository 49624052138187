import { jsxs as e, jsx as a } from "react/jsx-runtime";
const v = (l) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: [
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5.418 5.092A1 1 0 0 0 4 6v10.696a1 1 0 0 0 .58.907l6.995 3.238a.997.997 0 0 0 .85 0l.232-.108A5.975 5.975 0 0 1 12 18c0-1.227.368-2.367 1-3.318V9.865l5-2.303V12c.701 0 1.374.12 2 .341V6a1 1 0 0 0-1.418-.908L12 8.124 5.418 5.092ZM11 9.865 6 7.562v8.495l5 2.314V9.865Z"
        }
      ),
      /* @__PURE__ */ a("path", { d: "M18 14a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Z" })
    ]
  }
);
export {
  v as default
};
