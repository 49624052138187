import { jsxs as i, jsx as l } from "react/jsx-runtime";
const v = (t) => /* @__PURE__ */ i(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ l("path", { d: "m2.999 9.646 9 6.159V8.193L3 2v7.646Z", fill: "#68778D" }),
      /* @__PURE__ */ l("path", { d: "M21.001 2 12 8.194v7.61l9.001-6.158V2Z", fill: "#2B313B" }),
      /* @__PURE__ */ l("path", { d: "M21.001 14.354 12 8.195v7.612L21.001 22v-7.646Z", fill: "#68778D" }),
      /* @__PURE__ */ l("path", { d: "m2.999 22 9-6.194v-7.61l-9 6.158v7.645Z", fill: "#2B313B" })
    ]
  }
);
export {
  v as default
};
