import * as e from "react";
const t = (l) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M16.56 11.94 7.62 3 6.21 4.41l2.38 2.38-5.15 5.15a1.49 1.49 0 0 0 0 2.12l5.5 5.5c.29.29.68.44 1.06.44s.77-.15 1.06-.44l5.5-5.5c.59-.58.59-1.53 0-2.12ZM5.21 13 10 8.21 14.79 13H5.21ZM17 18c0-1.33 2-3.5 2-3.5s2 2.17 2 3.5c0 1.1-.9 2-2 2s-2-.9-2-2Z"
    }
  )
);
export {
  t as default
};
