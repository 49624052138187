import { jsx as t } from "react/jsx-runtime";
const h = (e) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: /* @__PURE__ */ t("path", { d: "m12.3 15.3-2.6-2.6c-.1-.1-.175-.208-.225-.325A.942.942 0 0 1 9.4 12c0-.133.025-.258.075-.375.05-.116.125-.225.225-.325l2.6-2.6c.317-.316.679-.387 1.087-.212.409.175.613.487.613.937v5.15c0 .45-.204.763-.613.937-.408.176-.77.105-1.087-.212Z" })
  }
);
export {
  h as default
};
