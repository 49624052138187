import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M7.792 16.755C5.59 15.427 3.604 13.4 2 11.995c2.561-2.245 6.14-6.113 9.99-6.113.102 0 .201.002.302.007l-.853 2.06a4.084 4.084 0 0 0-2.698 6.513l-.949 2.293Zm8.401-9.53-.952 2.299a4.082 4.082 0 0 1-2.698 6.514l-.854 2.059c.101.004.2.007.302.007 3.851 0 7.301-3.755 9.99-6.113-1.71-1.493-3.648-3.472-5.788-4.767Zm.307-3.43-1.9-.788-7.121 17.189 1.9.787L16.5 3.795Z" })
);
export {
  a as default
};
