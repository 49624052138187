import { jsx as c } from "react/jsx-runtime";
const e = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M12 17c-1.683 0-3.104-.58-4.263-1.738C6.579 14.104 6 12.683 6 11V4.25c0-.35.121-.646.363-.888C6.604 3.121 6.9 3 7.25 3s.646.12.887.362c.242.242.363.538.363.888V11c0 .983.337 1.813 1.012 2.488S11.017 14.5 12 14.5c.983 0 1.813-.337 2.488-1.012S15.5 11.983 15.5 11V4.25c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.888.362c.241.242.362.538.362.888V11c0 1.683-.58 3.104-1.738 4.262C15.104 16.421 13.683 17 12 17Zm-6 4a.968.968 0 0 1-.713-.288A.967.967 0 0 1 5 20c0-.283.096-.52.287-.712A.968.968 0 0 1 6 19h12c.283 0 .52.096.712.288A.965.965 0 0 1 19 20c0 .283-.096.52-.288.712A.965.965 0 0 1 18 21H6Z" })
  }
);
export {
  e as default
};
