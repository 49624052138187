import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M10.5 2.1A11.427 11.427 0 0 1 12 2c2.617 0 4.867.867 6.75 2.6s2.95 3.867 3.2 6.4a.82.82 0 0 1-.238.7c-.191.2-.429.3-.712.3a.979.979 0 0 1-.7-.288 1.139 1.139 0 0 1-.35-.712c-.217-1.717-.908-3.204-2.075-4.463-1.167-1.258-2.608-2.054-4.325-2.387l.75.75a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-2.8-2.8c-.3-.3-.408-.592-.325-.875.083-.283.325-.458.725-.525Zm3 19.8a11.468 11.468 0 0 1-1.5.1c-2.617 0-4.867-.867-6.75-2.6S2.3 15.533 2.05 13a.82.82 0 0 1 .238-.7c.191-.2.429-.3.712-.3.267 0 .5.096.7.287.2.192.317.43.35.713.217 1.717.908 3.204 2.075 4.462 1.167 1.259 2.608 2.055 4.325 2.388l-.75-.75a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.8 2.8c.3.3.408.592.325.875-.083.283-.325.458-.725.525Zm-1-6.9V9h3c.283 0 .52.096.712.287.192.192.288.43.288.713v4c0 .283-.096.52-.288.712A.965.965 0 0 1 15.5 15h-3Zm1.5-1.5h1v-3h-1v3ZM8.25 15a.726.726 0 0 1-.75-.75.728.728 0 0 1 .75-.75H10v-1H9a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7c.1-.1.217-.15.35-.15h1v-1H8.25a.726.726 0 0 1-.75-.75.728.728 0 0 1 .75-.75h2.25a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v4c0 .283-.096.52-.287.712A.968.968 0 0 1 10.5 15H8.25Z" })
  }
);
export {
  t as default
};
