import { defineMessages } from "react-intl";

export default defineMessages({
  uploadFile: {
    id: "dropzone.upload.file",
    defaultMessage: "Upload file",
  },
  browseFiles: {
    id: "dropzone.browse.files",
    defaultMessage: "browse files",
  },
});
