import React, { useState } from "react";
import styled from "styled-components";
import { TinyText, sansFamily, BodyUI } from "@happeouikit/typography";
import { uuidv4 } from "./utils";
import { InputNumberProps, InputProps } from "./Input.types";
import { IconAlertCircleOutline } from "@happeouikit/icons";

/**
 * Map input states to colors
 * @type {{default: string, success: string, error: string}}
 */
const inputStates = {
  success: "var(--color-success-primary, #00a372)",
  error: "var(--color-alert-primary, #da3939)",
  default: "var(--color-secondary-text-on-light, #4a5464)",
};

/**
 * HOC for customizing Icon components for input
 * @param IconComponent
 * @param iconProps
 * @returns {function(): *}
 */
const IconWrapper = (
  IconComponent: React.ComponentType<any>,
  iconProps: {
    [key: string]: any;
  }
) => {
  const iconDefaults = {
    width: "20px",
    height: "20px",
    fill: "var(--color-secondary-text-on-light, #2b313b)",
  };

  const style = {
    position: "absolute",
    top: "10px",
    left: "10px",
    fontSize: "18px",
  };
  const props = { ...iconDefaults, ...iconProps };

  return () => {
    return (
      <InputIconWrapper>
        <IconComponent {...props} style={style} />
      </InputIconWrapper>
    );
  };
};

export const Input = ({
  label,
  description,
  hintText,
  required,
  id,
  icon,
  iconProps,
  state,
  errorMessage,
  disabled,
  readOnly,
  ...rest
}: InputProps) => {
  const [appliedId] = useState(() => id || uuidv4());
  const describedBy = [
    description ? `${appliedId}-description` : "",
    hintText ? `${appliedId}-hint-message` : "",
    state === "error" && errorMessage ? `${appliedId}-error-message` : "",
  ].join(" ");

  return (
    <Container isDisabled={disabled || false}>
      {!!label && (
        <InputLabelContainer>
          <InputLabel bold htmlFor={appliedId} isRequired={required || false}>
            {label}
          </InputLabel>
          {description && (
            <BodyUI
              id={`${appliedId}-description`}
              color="var(--color-secondary-text-on-light, #4a5464)"
            >
              {description}
            </BodyUI>
          )}
        </InputLabelContainer>
      )}

      {icon && IconWrapper(icon, iconProps)()}
      <StyledInput
        title={label || "Styled input"}
        hasIcon={!!(icon || "").toString()}
        state={state || "default"}
        id={appliedId}
        disabled={disabled}
        readOnly={readOnly}
        data-1p-ignore // prevent 1Password from potentially adding a password icon/prompt
        {...(description
          ? {
              "aria-describedby": describedBy,
            }
          : {})}
        {...rest}
      />

      {hintText && (
        <HintContainer id={`${appliedId}-hint-message`}>
          <HintIcon fill="var(--color-hint-text-on-light, #68778d)" />
          <TinyText color="var(--color-secondary-text-on-light, #4a5464)">
            {hintText}
          </TinyText>
        </HintContainer>
      )}

      {state === "error" && errorMessage && (
        <HintContainer id={`${appliedId}-error-message`}>
          <HintIcon fill="var(--color-alert-primary, #da3939)" />
          <TinyText color="var(--color-alert-primary, #da3939)">
            {errorMessage}
          </TinyText>
        </HintContainer>
      )}
    </Container>
  );
};

const HintIcon = styled(IconAlertCircleOutline)`
  width: 13px;
  height: 13px;
`;

const HintContainer = styled.div`
  display: flex;
  gap: var(--space-xs, 4px);
  margin-top: var(--space-sm, 8px);
  align-items: center;
`;

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xxs, 2px);
  margin-bottom: var(--space-sm, 8px);
`;

export const StyledInput = styled.input.attrs(({ type }) => ({
  type: type || "text",
}))<{
  hasIcon: boolean;
  state: "success" | "error" | "default";
}>`
  ::placeholder {
    font-family: ${sansFamily};
    color: var(--color-placeholder-text-on-light, #95a0b1);
    letter-spacing: -0.3px;
    font-size: 14px;
    font-weight: normal;
  }

  font-family: ${sansFamily};
  width: 100%;
  height: 40px;
  border-radius: var(--radius-md, 4px);
  border: solid 1px var(--color-stroke, #95a0b1);
  box-sizing: border-box;
  box-shadow: none;
  background-color: var(--color-surface, #ffffff);
  font-size: var(--font-size-body-sm, 14px);
  padding: ${({ hasIcon }) => (hasIcon ? "10px 12px 10px 38px" : "10px 12px")};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: var(--color-primary-text-on-light, #2b313b);

  :hover {
    border-color: ${({ state = "default" }) =>
      state === "default"
        ? "var(--color-stroke-darken, #596578)"
        : inputStates[state]};
  }

  :active {
    outline: none;
    box-shadow: none;
    border-color: ${({ state = "default" }) =>
      state === "default"
        ? "var(--color-focus-indicator, #1691fe)"
        : inputStates[state]};
  }

  :focus {
    outline: none;
    border-color: ${({ state = "default" }) =>
      state === "default"
        ? "var(--color-focus-indicator, #1691fe)"
        : inputStates[state]} !important;
  }

  :disabled {
    border: solid 1px var(--color-stroke-lighten);
    color: var(--color-gray500, #68778d);

    ::placeholder {
      color: var(--color-gray500, #68778d);
    }
  }

  :read-only {
    background-color: var(--color-surface-darken, #f3f5f7);
    border: solid 1px var(--color-disabled-text-on-light);
    color: var(--color-secondary-text-on-light, #4a5464);
  }

  border-color: ${({ state = "default" }) => inputStates[state]};
`;

const InputIconWrapper = styled.span`
  position: relative;
`;

/**
 * Number Input
 */
export const InputNumber = ({ label, ...props }: InputNumberProps) => {
  return (
    <InputNumberContainer>
      <StyledInputNumber {...props} type="number" />
      {label && <InputNumberLabel>{label}</InputNumberLabel>}
    </InputNumberContainer>
  );
};

const StyledInputNumber = styled(Input)`
  width: 60px;
  text-align: center;
  font-size: var(--font-size-body-sm, 14px);
`;

const InputLabel = styled(BodyUI).attrs({
  as: "label",
})<{ isRequired: boolean }>`
  color: var(--color-primary-text-on-light, #2b313b);
  position: relative;
  ${({ isRequired }) =>
    isRequired &&
    `
    &::after {
      content: '*';
      color: var(--color-alert-primary, #da3939);
      margin-left: var(--space-xxs, 2px);      
    }
  `}
`;

const InputNumberLabel = styled(BodyUI)`
  color: var(--color-primary-text-on-light, #2b313b);
  margin-left: var(--space-sm-plus, 10px);
`;

const Container = styled.div<{
  isDisabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    ${InputLabelContainer},
    ${StyledInput},
    ${HintContainer} {
      opacity: 0.4;
    }
  `}
`;

const InputNumberContainer = styled.div`
  display: flex;
  align-items: center;

  ${Container} {
    width: auto;
  }
`;
