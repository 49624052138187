import { jsx as h } from "react/jsx-runtime";
const c = (t) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ h("path", { d: "M10.5 20h3a.48.48 0 0 0 .35-.15.48.48 0 0 0 0-.7.48.48 0 0 0-.35-.15h-3a.48.48 0 0 0-.35.15.48.48 0 0 0 0 .7c.1.1.217.15.35.15ZM7 23c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 5 21V3c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 7 1h10c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v18c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 17 23H7Zm0-6h10V4H7v13Z" })
  }
);
export {
  c as default
};
