import * as React from "react";
const SvgIconGroupAvatar = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <circle cx={12} cy={12} r={12} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 9c0 1.245-1 2.25-2.246 2.25a2.249 2.249 0 1 1 0-4.5A2.242 2.242 0 0 1 12.75 9Zm-7.5 5.79v1.71c0 .414.336.75.75.75h9a.75.75 0 0 0 .75-.75v-1.71a.75.75 0 0 0-.513-.71l-3.756-1.253a1.5 1.5 0 0 0-.474-.077H9.993a1.5 1.5 0 0 0-.474.077l-3.756 1.252a.75.75 0 0 0-.513.712Z"
      fill="#fff"
    />
    <path
      d="M17.25 10.5c0 .83-.666 1.5-1.498 1.5a1.5 1.5 0 1 1 0-3c.832 0 1.498.67 1.498 1.5Z"
      fill="#fff"
    />
    <path
      d="M17.25 10.5c0 .83-.666 1.5-1.498 1.5a1.5 1.5 0 1 1 0-3c.832 0 1.498.67 1.498 1.5ZM19.5 15.508v.992a.75.75 0 0 1-.75.75h-1.5a.375.375 0 0 1-.375-.375v-2.371c0-.265.268-.447.514-.348l1.64.655a.75.75 0 0 1 .471.697Z"
      fill="#fff"
    />
    <path
      d="M19.5 15.508v.992a.75.75 0 0 1-.75.75h-1.5a.375.375 0 0 1-.375-.375v-2.371c0-.265.268-.447.514-.348l1.64.655a.75.75 0 0 1 .471.697Z"
      fill="#fff"
    />
  </svg>
);
export default SvgIconGroupAvatar;
