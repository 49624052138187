import { jsx as t } from "react/jsx-runtime";
const a = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M12.15 20c-1.083 0-2.058-.271-2.925-.812-.867-.542-1.567-1.305-2.1-2.288a1.06 1.06 0 0 1-.05-.9c.117-.317.342-.542.675-.675a1.04 1.04 0 0 1 .863-.025c.291.116.529.325.712.625.3.5.696.904 1.187 1.213.492.308 1.055.462 1.688.462.7 0 1.333-.167 1.9-.5.567-.334.85-.867.85-1.6 0-.3-.058-.575-.175-.825A2.362 2.362 0 0 0 14.3 14h2.8c.083.233.146.47.188.712.041.242.062.504.062.788 0 1.433-.513 2.541-1.538 3.325C14.787 19.608 13.567 20 12.15 20ZM3 12a.965.965 0 0 1-.712-.288A.965.965 0 0 1 2 11c0-.284.096-.521.288-.713A.967.967 0 0 1 3 10h18c.283 0 .52.095.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 21 12H3Zm4.25-4c.033-1.15.488-2.13 1.363-2.938.875-.808 2.02-1.212 3.437-1.212.9 0 1.708.179 2.425.537.717.358 1.317.913 1.8 1.663a.962.962 0 0 1 .1.9c-.117.316-.342.55-.675.7-.25.116-.52.129-.812.038-.292-.092-.555-.28-.788-.563a2.633 2.633 0 0 0-.825-.625c-.333-.167-.725-.25-1.175-.25-.683 0-1.25.154-1.7.463-.45.308-.7.737-.75 1.287h-2.4Z" })
  }
);
export {
  a as default
};
