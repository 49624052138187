import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M12 22c-1.25 0-2.42-.237-3.512-.712a9.142 9.142 0 0 1-2.85-1.926 9.143 9.143 0 0 1-1.926-2.85A8.709 8.709 0 0 1 3 13c0-1.25.237-2.421.712-3.513a9.159 9.159 0 0 1 1.926-2.85 9.138 9.138 0 0 1 2.85-1.924A8.698 8.698 0 0 1 12 4c1.25 0 2.421.238 3.513.713a9.152 9.152 0 0 1 2.85 1.924 9.167 9.167 0 0 1 1.925 2.85A8.715 8.715 0 0 1 21 13c0 1.25-.237 2.42-.712 3.512a9.151 9.151 0 0 1-1.925 2.85 9.156 9.156 0 0 1-2.85 1.926A8.715 8.715 0 0 1 12 22ZM11 9v3.975a1.03 1.03 0 0 0 .3.725l2.825 2.825a.918.918 0 0 0 .675.275c.267 0 .5-.1.7-.3a.948.948 0 0 0 .275-.7.948.948 0 0 0-.275-.7L13 12.6V8.975a.927.927 0 0 0-.287-.7A.992.992 0 0 0 12 8a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 9ZM3.425 7.325a.918.918 0 0 1-.675.275.96.96 0 0 1-.7-.3.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l2.875-2.875A.918.918 0 0 1 5.6 2.75c.267 0 .5.1.7.3a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L3.425 7.325ZM20.55 7.3l-2.875-2.875a.918.918 0 0 1-.275-.675c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.875 2.875a.917.917 0 0 1 .275.675c0 .267-.1.5-.3.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275Z" })
  }
);
export {
  l as default
};
