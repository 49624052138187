import { jsx as c } from "react/jsx-runtime";
const A = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M5 21c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 3 19V5c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 5 3h4c.55 0 1.02.196 1.412.587C10.804 3.98 11 4.45 11 5v14c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 9 21H5Zm10-11c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 13 8V5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 15 3h4c.55 0 1.02.196 1.413.587C20.803 3.98 21 4.45 21 5v3c0 .55-.196 1.02-.587 1.412A1.926 1.926 0 0 1 19 10h-4Zm0 11c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 13 19v-5c0-.55.196-1.02.588-1.412A1.926 1.926 0 0 1 15 12h4c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v5c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 19 21h-4Z" })
  }
);
export {
  A as default
};
