import { IconEdit, IconArchive } from "@happeouikit/icons";
export const actions = [
  {
    name: "Edit page",
    callback: (val) => console.log("edited", val),
    type: "edit",
  },
  {
    name: "Archive page",
    callback: (val) => console.log("archived", val),
    type: "archive",
  },
];

export const actionsWithIcons = [
  {
    name: "Edit page",
    callback: (val) => console.log("edited", val),
    type: "edit",
    icon: IconEdit,
    dataTracker: "Edit",
  },
  {
    name: "Archive page",
    callback: (val) => console.log("archived", val),
    type: "archive",
    icon: IconArchive,
  },
  {
    name: "New page",
    callback: (val) => console.log("new page", val),
    type: "newpage",
    dataTracker: "New",
  },
];
