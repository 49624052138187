import { jsx as c } from "react/jsx-runtime";
const v = (l) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ c("path", { d: "M21.15 16.65 18 13.5v1.675l-2-2V6H8.825l-2-2H16c.55 0 1.021.196 1.413.588.391.39.587.862.587 1.412v4.5l3.15-3.15c.167-.167.35-.209.55-.125.2.083.3.241.3.475v8.6c0 .233-.1.391-.3.475-.2.083-.383.041-.55-.125Zm-1.3 6L1.325 4.125a.918.918 0 0 1-.275-.675c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l18.525 18.525a.918.918 0 0 1 .275.675c0 .266-.1.5-.3.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM4 4l2 2H4v12h12v-2l2 2c0 .55-.196 1.02-.587 1.413A1.928 1.928 0 0 1 16 20H4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.021.588-1.412A1.923 1.923 0 0 1 4 4Z" })
  }
);
export {
  v as default
};
