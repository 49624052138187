import { jsx as h } from "react/jsx-runtime";
const o = (c) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ h("path", { d: "M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm0-2h14v-3h-3a5.323 5.323 0 0 1-1.787 1.475c-.692.35-1.43.525-2.213.525a4.83 4.83 0 0 1-2.212-.525A5.32 5.32 0 0 1 8 16H5v3Zm7-3c.5 0 .975-.121 1.425-.363.45-.241.833-.62 1.15-1.137.083-.15.2-.271.35-.363A.99.99 0 0 1 15.45 14H19V5H5v9h3.55c.2 0 .375.046.525.137.15.092.267.213.35.363.317.517.7.896 1.15 1.137.45.242.925.363 1.425.363Zm-7 3h14H5Z" })
  }
);
export {
  o as default
};
