import * as React from "react";
const SvgIconRocket = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m16.73 13.79.168 1.703c.11 1.103-.369 2.105-1.298 2.72l-2.794 1.85a1 1 0 0 1-1.548-.758l-.228-2.93c-.985-.032-1.839-.376-2.482-1.015-.64-.634-.986-1.476-1.022-2.445l-2.835-.18a1 1 0 0 1-.78-1.536L5.68 8.428c.612-.96 1.65-1.46 2.789-1.345l1.652.166c.179-.197.367-.394.565-.59 2.843-2.822 7.055-3.715 9.188-3.656.63.017 1.108.492 1.124 1.122.057 2.12-.843 6.295-3.683 9.114-.195.193-.39.377-.584.551Zm-2.5-6.89a1.984 1.984 0 0 0 0 2.82 2.02 2.02 0 0 0 2.842 0 1.984 1.984 0 0 0 0-2.82 2.02 2.02 0 0 0-2.841 0ZM6.28 13.564l-2.82 2.791h1.844l-.922 2.739 2.767-.912v1.825l2.82-2.791a4.293 4.293 0 0 1-2.472-1.204 4.22 4.22 0 0 1-1.217-2.448Z"
    />
  </svg>
);
export default SvgIconRocket;
