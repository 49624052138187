import * as e from "react";
const l = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Zm7.8 3.6a1 1 0 1 0-1.6-1.2l-3 4a1 1 0 0 0 0 1.2l3 4a1 1 0 0 0 1.6-1.2L8.25 12l2.55-3.4Zm4-1.2a1 1 0 0 0-1.6 1.2l2.55 3.4-2.55 3.4a1 1 0 0 0 1.6 1.2l3-4a1 1 0 0 0 0-1.2l-3-4Z"
    }
  )
);
export {
  l as default
};
