import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M12 14a1 1 0 0 1-1-1V6a1 1 0 1 1 2 0v7a1 1 0 0 1-1 1Zm0 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" })
  }
);
export {
  h as default
};
