import { jsxs as e, jsx as l } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Z",
          fill: "#DA3737"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M15.882 12.991a.5.5 0 0 0-.78-.012l-1.704 2.052a.5.5 0 0 1-.78-.013l-2.716-3.5a.5.5 0 0 0-.795.006L5.6 16.2a.5.5 0 0 0 .4.8h11.978a.5.5 0 0 0 .394-.807l-2.49-3.202Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  t as default
};
