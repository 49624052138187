import { jsxs as l, jsx as t } from "react/jsx-runtime";
const c = (e) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ t(
        "path",
        {
          d: "M21.007 10.5c0-5.246-4.261-9.5-9.507-9.5A9.5 9.5 0 0 0 2 10.5c0 4.97 4.53 9 9.5 9V23c5.772-2.927 9.507-7.639 9.507-12.5Z",
          fill: "#038D7F"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.5 1.125c5.246 0 9.5 4.253 9.5 9.5h.004l.003-.125H21a9.5 9.5 0 0 0-19 0l.001.059c.036-5.217 4.274-9.434 9.499-9.434Z",
          fill: "#fff"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          opacity: 0.2,
          d: "M11.5 19.5v-.125c-4.951 0-9.463-3.998-9.499-8.941L2 10.5c0 4.97 4.53 9 9.5 9Zm9.5-9c-.064 4.816-3.778 9.473-9.5 12.374V23c5.772-2.926 9.5-7.638 9.5-12.5Z",
          fill: "#263238"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "m20.99 11.085-4.161-3.74-1.479 2.532-8.392 3.216 7.217 7.462 4.282-3.13c1.499-1.965 2.397-4.13 2.532-6.34Z",
          fill: "url(#google-hangouts-meet_svg__a)",
          fillOpacity: 0.21
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M6.777 10.416v2.266c0 .598.49 1.088 1.088 1.088h5.627c.598 0 1.088-.49 1.088-1.088v-1.431l2.228 2.228v-3.063H6.777Z",
          fill: "#F6F6F6"
        }
      ),
      /* @__PURE__ */ t(
        "path",
        {
          d: "M16.808 10.416V7.344L14.58 9.567V8.15c0-.598-.49-1.087-1.088-1.087H7.865c-.599 0-1.088.49-1.088 1.087v2.267h10.03Z",
          fill: "#E2E2E2"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ l(
        "linearGradient",
        {
          id: "google-hangouts-meet_svg__a",
          x1: 12.002,
          y1: 10.951,
          x2: 18.513,
          y2: 20.092,
          gradientUnits: "userSpaceOnUse",
          children: [
            /* @__PURE__ */ t("stop", {}),
            /* @__PURE__ */ t("stop", { offset: 1, stopColor: "#D8D8D8", stopOpacity: 0 })
          ]
        }
      ) })
    ]
  }
);
export {
  c as default
};
