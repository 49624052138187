import { jsx as a } from "react/jsx-runtime";
const t = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M19.49 11.02c-.16-.01-.32-.02-.49-.02-1.7 0-3.23.71-4.32 1.84L9.866 4.5a1 1 0 0 1 .866-1.5h3.682a1 1 0 0 1 .872.511l4.204 7.509ZM2.265 15.985a1 1 0 0 1 .005-.968l5.02-8.963a1 1 0 0 1 1.74-.011l2.3 3.987-5.44 9.426a1 1 0 0 1-1.743-.021l-1.882-3.45ZM13.2 15.5H9.9l-2.306 4a1 1 0 0 0 .867 1.5h6.079A5.93 5.93 0 0 1 13 17c0-.52.07-1.02.2-1.5ZM19 13a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Z" })
  }
);
export {
  t as default
};
