import { jsx as h } from "react/jsx-runtime";
const Z = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h("path", { d: "M3 21v-2h2v2H3Zm0-4v-2h2v2H3Zm0-4v-2h2v2H3Zm0-4V7h2v2H3Zm0-4V3h2v2H3Zm4 16v-2h2v2H7Zm0-8v-2h2v2H7Zm0-8V3h2v2H7Zm4 16v-2h2v2h-2Zm0-4v-2h2v2h-2Zm0-4v-2h2v2h-2Zm0-4V7h2v2h-2Zm0-4V3h2v2h-2Zm4 16v-2h2v2h-2Zm0-8v-2h2v2h-2Zm0-8V3h2v2h-2Zm4 16v-2h2v2h-2Zm0-4v-2h2v2h-2Zm0-4v-2h2v2h-2Zm0-4V7h2v2h-2Zm0-4V3h2v2h-2Z" })
  }
);
export {
  Z as default
};
