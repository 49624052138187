import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../../../../../packages/buttons-ds3/package.json";
import { Button, IconButton, MediaButton } from "../../../../../packages/buttons-ds3/src/index";
import { DebugHappeoThemeProvider } from "@happeouikit/theme";
import { IconHeartFill, IconChevronRight } from '@happeouikit/icons';
import { DocsButtonWrapper } from "../../../../../packages/buttons-ds3/docs/DocsButtonWrapper";
import * as React from 'react';
export default {
  Playground,
  Props,
  PackageMetadata,
  pkg,
  Button,
  IconButton,
  MediaButton,
  DebugHappeoThemeProvider,
  IconHeartFill,
  IconChevronRight,
  DocsButtonWrapper,
  React
};