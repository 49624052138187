import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 32 28",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement("rect", { width: 32, height: 28, fill: "#F7AC00", rx: 2 }), /* @__PURE__ */ e.createElement("g", { fill: "#FFF", fillRule: "nonzero" }, /* @__PURE__ */ e.createElement("path", { d: "M9.333 5s4 0 5.667 2.235V24c-1.667-2.235-5.667-2.235-5.667-2.235H7.11c-.613 0-1.11-.502-1.11-1.126V6.126C6 5.504 6.492 5 7.11 5h2.223Z" }), /* @__PURE__ */ e.createElement(
    "path",
    {
      fillOpacity: 0.75,
      d: "M22.667 5s-4 0-5.667 2.235V24c1.667-2.235 5.667-2.235 5.667-2.235h2.223c.613 0 1.11-.502 1.11-1.126V6.126C26 5.504 25.508 5 24.89 5h-2.223Z"
    }
  )))
);
export {
  l as default
};
