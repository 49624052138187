import { jsx as a } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ a("path", { d: "M9 21a1 1 0 1 0 0-2H6.41l3.795-3.795a.997.997 0 1 0-1.41-1.41L5 17.59V15a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1h5Zm4.795-10.795c.39.39 1.02.39 1.41 0L19 6.41V9a1 1 0 1 0 2 0V4a1 1 0 0 0-1-1h-5a1 1 0 1 0 0 2h2.59l-3.795 3.795a.997.997 0 0 0 0 1.41Z" })
  }
);
export {
  o as default
};
