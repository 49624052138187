import { jsx as c } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ c("path", { d: "M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 20H4Zm8-7.175c.083 0 .17-.013.262-.038s.18-.062.263-.112L19.6 8.25a.828.828 0 0 0 .3-.312.865.865 0 0 0 .1-.413.815.815 0 0 0-.425-.75c-.283-.167-.575-.158-.875.025L12 11 5.3 6.8c-.3-.183-.592-.188-.875-.013-.283.175-.425.421-.425.738 0 .167.033.312.1.437a.668.668 0 0 0 .3.288l7.075 4.425c.083.05.171.087.263.112a.981.981 0 0 0 .262.038Z" })
  }
);
export {
  h as default
};
