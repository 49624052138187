import * as React from "react";
const SvgIconForum = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4v7c0 1.1-.9 2-2 2H6l-4 4V4c0-1.1.9-2 2-2h11c1.1 0 2 .9 2 2Zm2 2h1c1.1 0 2 .9 2 2v14l-4-4H8c-1.1 0-2-.9-2-2v-1h12c.55 0 1-.45 1-1V6Z"
    />
  </svg>
);
export default SvgIconForum;
