import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 5V2.21c0-.45-.54-.67-.85-.35l-3.8 3.79c-.2.2-.2.51 0 .71l3.79 3.79c.32.31.86.09.86-.36V7c3.73 0 6.68 3.42 5.86 7.29-.47 2.26-2.14 3.99-4.39 4.53a6.02 6.02 0 0 1-7.42-4.96.981.981 0 0 0-.97-.86c-.6 0-1.08.53-1 1.13.63 4.47 4.94 7.75 9.77 6.67 3.09-.69 5.39-3.08 5.99-6.19C20.84 9.48 16.94 5 12 5Zm-1.31 8.9.25-2.17h2.39v.71h-1.7l-.11.92c.03-.02.07-.03.11-.05.04-.02.09-.04.15-.05.06-.01.12-.03.18-.04a1.35 1.35 0 0 1 .75.08c.16.07.3.16.41.28.11.12.2.27.25.45.05.18.09.38.09.6 0 .19-.03.37-.09.54-.06.17-.15.32-.27.45-.12.13-.27.24-.45.31s-.39.12-.64.12c-.18 0-.36-.03-.53-.08-.17-.05-.32-.14-.46-.24a1.1 1.1 0 0 1-.32-.39c-.08-.15-.13-.33-.13-.53h.84c.02.18.08.32.19.41.11.09.25.15.42.15a.49.49 0 0 0 .45-.23c.04-.07.08-.15.11-.25s.03-.2.03-.31c0-.11-.01-.21-.04-.31a.618.618 0 0 0-.13-.24.51.51 0 0 0-.21-.15.896.896 0 0 0-.3-.05c-.08 0-.15.01-.2.02a.66.66 0 0 0-.15.05c-.04.02-.08.05-.12.07-.04.02-.07.06-.1.09l-.67-.16Z" })
  }
);
export {
  t as default
};
