import { jsx as A } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ A(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ A("path", { d: "M8 6H4a.965.965 0 0 0-.712.288A.965.965 0 0 0 3 7c0 .283.096.52.288.712A.965.965 0 0 0 4 8h4a.968.968 0 0 0 .713-.288A.967.967 0 0 0 9 7a.967.967 0 0 0-.287-.712A.968.968 0 0 0 8 6Zm12 10H4a.967.967 0 0 0-.712.287A.968.968 0 0 0 3 17c0 .283.096.521.288.713A.967.967 0 0 0 4 18h16c.283 0 .52-.096.712-.287A.968.968 0 0 0 21 17a.968.968 0 0 0-.288-.713A.967.967 0 0 0 20 16Zm-6-5H4a.965.965 0 0 0-.712.288A.965.965 0 0 0 3 12c0 .283.096.521.288.713A.967.967 0 0 0 4 13h10a.97.97 0 0 0 .713-.287A.97.97 0 0 0 15 12a.967.967 0 0 0-.287-.712A.968.968 0 0 0 14 11Z" })
  }
);
export {
  h as default
};
