import { css, FlattenSimpleInterpolation } from "styled-components";
import { ButtonColorScheme } from "./Button.types";

export const buttonSizes = {
  sm: css`
    height: 32px;
    font: var(--font-body-sm);
    padding: var(--space-xs-plus) var(--space-sm);
    font-weight: var(--font-weight-regular);
  `,
  lg: css`
    height: 40px;
    font: var(--font-body-sm);
    padding: var(--space-sm-plus) var(--space-md-minus);
    font-weight: var(--font-weight-regular);
  `,
};

const createButtonVariants = (colorScheme: ButtonColorScheme) => ({
  [`${colorScheme}Primary`]: css`
    color: var(--color-primary-text-on-dark);
    fill: var(--color-primary-text-on-dark);
    background-color: var(--color-${colorScheme}-primary);
    &:hover {
      background-color: var(--color-${colorScheme}-darken10);
    }
    &:active {
      background-color: var(--color-${colorScheme}-darken20);
    }
    &:focus-visible {
      background-color: var(--color-${colorScheme}-darken10);
      outline-color: var(--color-focus-indicator);
      outline-width: 1px;
      outline-style: solid;
      outline-offset: 1px;
    }
    // the && is to increase specificity, to override button:disabled:active in styleguide.min.css (#FE-131)
    &&:disabled {
      color: var(--color-disabled-text-on-light);
      fill: var(--color-disabled-text-on-light);
      background-color: var(--color-divider);
    }
  `,
  [`${colorScheme}Secondary`]: css`
    color: var(--color-${colorScheme}-darken10);
    background-color: var(--color-${colorScheme}-lighten90);
    &:hover {
      background-color: var(--color-${colorScheme}-lighten80);
    }
    &:active {
      background-color: var(--color-${colorScheme}-lighten50);
    }
    &:focus-visible {
      outline-color: var(--color-focus-indicator);
      outline-width: 1px;
      outline-style: solid;
      outline-offset: 1px;
    }
    &&:disabled {
      color: var(--color-disabled-text-on-light);
      fill: var(--color-disabled-text-on-light);
      background-color: var(--color-transparent);
    }
  `,
  [`${colorScheme}Tertiary`]: css`
    color: var(--color-${colorScheme}-primary);
    background-color: var(--color-transparent);
    &:hover {
      background-color: var(--color-${colorScheme}-lighten90);
    }
    &:active {
      background-color: var(--color-${colorScheme}-lighten80);
    }
    &:focus-visible {
      outline-color: var(--color-focus-indicator);
      outline-width: 1px;
      outline-style: solid;
      outline-offset: 1px;
    }
    &&:disabled {
      color: var(--color-disabled-text-on-light);
      fill: var(--color-disabled-text-on-light);
      background-color: var(--color-transparent);
    }
  `,

  [`${colorScheme}Outline`]: css`
    color: var(--color-${colorScheme}-primary);
    background-color: var(--color-surface);
    border: 1px solid var(--color-${colorScheme}-primary);
    &:hover {
      background-color: var(--color-${colorScheme}-lighten90);
    }
    &:active {
      background-color: var(--color-${colorScheme}-lighten80);
    }
    &:focus-visible {
      outline-color: var(--color-focus-indicator);
      outline-width: 1px;
      outline-style: solid;
      outline-offset: 1px;
    }
    &&:disabled {
      color: var(--color-disabled-text-on-light);
      fill: var(--color-disabled-text-on-light);
      background-color: var(--color-transparent);
      border-color: var(--color-disabled-text-on-light);
    }
  `,
});

type ButtonVariants = {
  [key: string]: FlattenSimpleInterpolation;
};

export const buttonVariants = {
  ...createButtonVariants("active"),
  ...createButtonVariants("alert"),
  ...createButtonVariants("success"),
  ...createButtonVariants("warning"),
  blankPrimary: css`
    color: var(--color-primary-text-on-dark);
    fill: var(--color-primary-text-on-dark);
    background-color: var(--color-hint-text-on-light);
    &:hover {
      background-color: var(--color-stroke-darken);
    }
    &:active {
      background-color: var(--color-secondary-text-on-light);
    }
    &:focus-visible {
      background-color: var(--color-hint-text-on-light);
      outline-color: var(--color-focus-indicator);
      outline-width: 1px;
      outline-style: solid;
      outline-offset: 1px;
    }
    &&:disabled {
      color: var(--color-disabled-text-on-light);
      fill: var(--color-disabled-text-on-light);
      background-color: var(--color-divider);
    }
  `,
  blankSecondary: css`
    color: var(--color-secondary-text-on-light);
    fill: var(--color-secondary-text-on-light);
    background-color: var(--color-surface-over);
    &:hover {
      background-color: var(--color-divider);
    }
    &:active {
      background-color: var(--color-stroke-lighten);
    }
    &:focus-visible {
      outline-color: var(--color-focus-indicator);
      outline-width: 1px;
      outline-style: solid;
      outline-offset: 1px;
    }
    &&:disabled {
      color: var(--color-disabled-text-on-light);
      fill: var(--color-disabled-text-on-light);
      background-color: var(--color-transparent);
    }
  `,
  blankTertiary: css`
    color: var(--color-secondary-text-on-light);
    fill: var(--color-secondary-text-on-light);
    background-color: var(--color-transparent);
    &:hover {
      background-color: var(--color-divider);
    }
    &:active {
      background-color: var(--color-stroke-lighten);
    }
    &:focus-visible {
      outline-color: var(--color-focus-indicator);
      outline-width: 1px;
      outline-style: solid;
      outline-offset: 1px;
    }
    &&:disabled {
      color: var(--color-disabled-text-on-light);
      fill: var(--color-disabled-text-on-light);
      background-color: var(--color-transparent);
    }
  `,
  blankOutline: css`
    color: var(--color-secondary-text-on-light);
    fill: var(--color-secondary-text-on-light);
    background-color: var(--color-surface);
    border: 1px solid var(--color-active-primary);
    &:hover {
      background-color: var(--color-divider);
    }
    &:active {
      background-color: var(--color-stroke);
    }
    &:focus-visible {
      outline-color: var(--color-focus-indicator);
      outline-width: 1px;
      outline-style: solid;
      outline-offset: 1px;
    }
    &&:disabled {
      color: var(--color-disabled-text-on-light);
      fill: var(--color-disabled-text-on-light);
      background-color: var(--color-surface);
      border-color: var(--color-disabled-text-on-light);
    }
  `,
  mediaPrimary: css`
    color: var(--color-white100);
    fill: var(--color-white100);
    background-color: var(--color-black60);
    &:hover {
      background-color: var(--color-black80);
    }
    &:active {
      background-color: var(--color-black80);
    }
    &:focus-visible {
      background-color: var(--color-black80);
      outline-color: var(--color-focus-indicator);
      outline-width: 1px;
      outline-style: solid;
      outline-offset: 1px;
    }
    &&:disabled {
      color: var(--color-white30);
      fill: var(--color-white30);
      background-color: var(--color-black60);
    }
  `,
} as ButtonVariants;
