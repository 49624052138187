import { jsx as c } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ c("path", { d: "M5 17.85c.9-.883 1.946-1.58 3.138-2.088C9.329 15.254 10.617 15 12 15s2.671.254 3.863.762A10.09 10.09 0 0 1 19 17.85V5H5v12.85ZM12 13c.967 0 1.792-.342 2.475-1.025A3.372 3.372 0 0 0 15.5 9.5c0-.967-.342-1.792-1.025-2.475A3.372 3.372 0 0 0 12 6c-.967 0-1.792.342-2.475 1.025A3.372 3.372 0 0 0 8.5 9.5c0 .967.342 1.792 1.025 2.475A3.372 3.372 0 0 0 12 13Zm-7 8c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h14c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm2-2h10v-.25a7.304 7.304 0 0 0-2.325-1.312A8.176 8.176 0 0 0 12 17c-.933 0-1.825.146-2.675.438A7.304 7.304 0 0 0 7 18.75V19Zm5-8c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 10.5 9.5c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 12 8c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062 0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 12 11Z" })
  }
);
export {
  h as default
};
