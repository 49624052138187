import * as e from "react";
const t = (a) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...a
  },
  /* @__PURE__ */ e.createElement("path", { d: "M13.795 10.205a.997.997 0 0 1 0-1.41L17.59 5H15a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0V6.41l-3.795 3.795a.997.997 0 0 1-1.41 0Zm-3.59 3.59c.39.39.39 1.02 0 1.41L6.41 19H9a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1v-5a1 1 0 1 1 2 0v2.59l3.795-3.795a.997.997 0 0 1 1.41 0Z" })
);
export {
  t as default
};
