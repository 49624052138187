const settings = (typeof window !== "undefined" && window.settings) || {};
if (!settings.env) settings.env = "local";

export const ROOT = settings.env === "local" ? "https://staging.unvrs.io" : "";
export const SRCSET_SIZES = [
  "(min-width: 480px) 300px,(min-width: 1024px) 800px, 1400px",
];
export const HAPPEO_IMG_ROOT = "/api/images/image/";
export const HAPPEO_IMG_SIZES = ["thumb", "sm", "md", "lg"];
export const SRCSET_IMG_SIZES = {
  [HAPPEO_IMG_SIZES[0]]: "200w",
  [HAPPEO_IMG_SIZES[1]]: "300w",
  [HAPPEO_IMG_SIZES[2]]: "800w",
  [HAPPEO_IMG_SIZES[3]]: "1400w",
};
export const MISSING_IMAGE_ALT_TEXT = "";
