import { jsx as h } from "react/jsx-runtime";
const o = (v) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ h(
      "path",
      {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm7 6h2V7h-2V5h-2v2h2v2Zm0 2V9h-2v2h2Zm0 2h2v-2h-2v2Zm0 2v-2h-2v2h2Zm0 0h2v2h-2v-2Z"
      }
    )
  }
);
export {
  o as default
};
