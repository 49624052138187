import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { gray07 } from "@happeouikit/colors";
import TabItem from "./TabItem";

const Tabs = ({
  children,
  variant,
  direction,
  active = 0,
  transparent = false,
  scrollToActive,
  customStyles,
}) => {
  const [activeTab, setActiveTab] = useState(active);
  const navigate = useNavigate();

  useEffect(() => setActiveTab(active), [active]);

  const onClickTabItem = (index, routePath) => {
    if (routePath) {
      navigate(routePath);
    }
    setActiveTab(index);
  };
  let content;

  const renderTabs = () =>
    children.map((child, index) => {
      const { onCustomClick, ...rest } = child.props;
      if (activeTab === index) content = child.props.children;

      const handleCustomClick = (index) => {
        setActiveTab(index);
        if (typeof onCustomClick === "function") {
          onCustomClick(index);
        }
      };

      return (
        <TabItem
          key={index}
          child={child}
          index={index}
          variant={variant}
          isActive={activeTab === index}
          onClickTabItem={!onCustomClick ? onClickTabItem : handleCustomClick}
          transparent={transparent}
          scrollToActive={scrollToActive}
          {...rest}
        />
      );
    });

  return (
    <>
      <TabsMenu
        role="tablist"
        onPointerDown={(e) => e.preventDefault()}
        direction={direction}
        variant={variant}
        tabIndex="0"
        style={customStyles}
      >
        {renderTabs()}
      </TabsMenu>
      <div role="tabpanel">{content}</div>
    </>
  );
};

const TabsMenu = styled.ul`
  flex-direction: ${({ direction }) => direction};
  border-bottom: ${({ variant }) =>
    variant === "primary" && `1px solid ${gray07};`};
  list-style-type: none;
  outline: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

Tabs.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]),
  direction: PropTypes.oneOf(["row", "column"]),
  customStyles: PropTypes.objectOf(PropTypes.any),
  active: PropTypes.number,
  transparent: PropTypes.bool,
  scrollToActive: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        props: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
          label: PropTypes.string,
          routePath: PropTypes.string,
          href: PropTypes.string,
          onCustomClick: PropTypes.func,
          icon: PropTypes.node,
          notification: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          count: PropTypes.number,
          "data-tracker": PropTypes.string,
        }),
      })
    ),
    PropTypes.node,
  ]),
};

Tabs.defaultProps = {
  variant: "primary",
  direction: "row",
  customStyles: undefined,
  active: 0,
  transparent: false,
  scrollToActive: false,
  children: undefined,
};

export default Tabs;
