import { jsx as h } from "react/jsx-runtime";
const c = (a) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ h("path", { d: "M2 14c-.55 0-1.02-.195-1.412-.587A1.923 1.923 0 0 1 0 12V6.226a1.978 1.978 0 0 1 .575-1.4L4.65.75a.997.997 0 0 1 .625-.288c.25-.025.475.03.675.163.2.133.35.312.45.537.1.225.125.454.075.688L5.8 5h4.25c.8 0 1.375.308 1.725.925.35.616.392 1.241.125 1.875l-2.125 4.975a2.022 2.022 0 0 1-.737.9A1.92 1.92 0 0 1 7.95 14H2Zm5.95-2L10 7.15V7H3.35l.6-2.7L2 6.2V12h5.95Zm9.9 11.226a.844.844 0 0 1-.312-.5 1.485 1.485 0 0 1-.013-.575L18.2 19H14c-.8 0-1.387-.305-1.762-.913-.375-.608-.421-1.237-.138-1.887l2.125-4.975a2.02 2.02 0 0 1 .738-.9A1.915 1.915 0 0 1 16.05 10H22c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v5.775a1.978 1.978 0 0 1-.575 1.4L19.35 23.25a.91.91 0 0 1-.75.288 1.316 1.316 0 0 1-.75-.313ZM16.05 12 14 16.85V17h6.65l-.6 2.7L22 17.8V12h-5.95ZM2 12V6.2 12Zm20 0v5.8V12Z" })
  }
);
export {
  c as default
};
