import * as React from "react";
const SvgIconLogoSkypeColor = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M12.103 17.713c-3.176 0-4.627-1.618-4.627-2.805a1.03 1.03 0 0 1 1.086-1.029c1.36 0 1.004 2.04 3.541 2.04 1.297 0 2.059-.781 2.059-1.514 0-.441-.252-.945-1.108-1.146l-2.833-.715C7.945 11.965 7.55 10.7 7.55 9.526c0-2.436 2.228-3.318 4.35-3.318 1.954 0 4.277 1.08 4.277 2.54 0 .63-.525.967-1.136.967-1.161 0-.966-1.63-3.302-1.63-1.161 0-1.772.544-1.772 1.306 0 .761.904 1.02 1.697 1.193l2.09.472c2.291.516 2.902 1.86 2.902 3.147 0 1.98-1.533 3.51-4.555 3.51h.003Zm8.77-4.19c.09-.52.135-1.046.135-1.573a8.945 8.945 0 0 0-10.494-8.848A5.262 5.262 0 0 0 7.876 2.4a5.35 5.35 0 0 0-4.617 7.998A8.952 8.952 0 0 0 13.62 20.816a5.26 5.26 0 0 0 2.635.702 5.35 5.35 0 0 0 4.617-7.995Z"
      fill="#0078D7"
    />
  </svg>
);
export default SvgIconLogoSkypeColor;
