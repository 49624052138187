import showdown from "showdown";
import { extraMentioning, mentioningRegex } from "./mentioningUtils";

const mentioningRegexCaseInsensitive = new RegExp(mentioningRegex, "i");
const mentioningRegexGroupCaseInsensitive = new RegExp(mentioningRegex, "gi");

let converter;
const customRules = {
  color: {
    type: "lang",
    regex: /\((.*?)\)\[(rgb\((?:([0-9]{1,2}|1[0-9]{1,2}|2[0-4][0-9]|25[0-5]), ?)(?:([0-9]{1,2}|1[0-9]{1,2}|2[0-4][0-9]|25[0-5]), ?)(?:([0-9]{1,2}|1[0-9]{1,2}|2[0-4][0-9]|25[0-5]))\))\]/gi,
    replace: (s, val) => {
      const color = s
        .match(
          /\[(rgb\((?:([0-9]{1,2}|1[0-9]{1,2}|2[0-4][0-9]|25[0-5]), ?)(?:([0-9]{1,2}|1[0-9]{1,2}|2[0-4][0-9]|25[0-5]), ?)(?:([0-9]{1,2}|1[0-9]{1,2}|2[0-4][0-9]|25[0-5]))\))\]/gi
        )[0]
        .replace("[", "")
        .replace("]", "");
      return `<span style="color:${color}">${val}</span>`;
    },
  },
  mention: {
    type: "lang",
    regex: mentioningRegexGroupCaseInsensitive,
    replace: (s) => {
      const r = s.match(mentioningRegexCaseInsensitive);
      const id = r && r.length > 1 && r[1];
      const name = r && r.length > 2 && r[2];
      if (
        extraMentioning.find(
          (mentioning) => mentioning.toLowerCase() === String(id).toLowerCase()
        )
      ) {
        return `<a class="mention extraMention" data-user-id="${id}">@${name}</a>`;
      }
      return `<a class="mention" data-user-id="${id}">@${name}</a>`;
    },
  },
  userMentioTag: {
    type: "lang",
    regex: /<user-mentio[^>]*>(.+?)<\/user-mentio>/gi,
    replace: (s) => {
      const r = s.match(/<user-mentio[^>]*>(.+?)<\/user-mentio>/);
      const attr = s.match(/data-user-id=["']([^"]+)["']/);
      const name = r && r.length > 1 && r[1];
      const id = attr && attr.length > 1 && attr[1];
      return `<a class="mention" data-user-id="${id}">${name}</a>`;
    },
  },
};

function getConverter() {
  if (converter) return converter;

  showdown.extension("color", customRules.color);
  showdown.extension("mention", customRules.mention);
  showdown.extension("usermentiotag", customRules.userMentioTag);

  converter = new showdown.Converter({
    extensions: ["color", "mention", "usermentiotag"],
  });
  converter.setOption("strikethrough", true); // <del>
  converter.setOption("tables", true); // <table>
  converter.setOption("openLinksInNewWindow", true);
  converter.setOption("disableForced4SpacesIndentedSublists", true);

  return converter;
}

export default getConverter();
