import { jsxs as a, jsx as l } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z",
          fill: "#4BADE8"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M17.671 9.187a1.5 1.5 0 0 0-2.342-1.874l-4.953 6.191L8.56 11.69a1.5 1.5 0 0 0-2.122 2.122l2.985 2.984a1.508 1.508 0 0 0 1.253.445 1.498 1.498 0 0 0 1.004-.564l5.99-7.489Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  h as default
};
