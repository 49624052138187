import { jsx as a } from "react/jsx-runtime";
const h = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M15.95 21.575a1.1 1.1 0 0 1-.375-.063.871.871 0 0 1-.325-.212l-2.85-2.85a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l2.15 2.15 4.95-4.95a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-5.65 5.65c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063ZM4 6l8 5 8-5H4Zm0 14c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v4.35l-1 1-1 1V8l-7.475 4.675c-.083.05-.171.087-.263.112a.981.981 0 0 1-.262.038.981.981 0 0 1-.262-.038 1.026 1.026 0 0 1-.263-.112L4 8v10h5.15l2 2H4Z" })
  }
);
export {
  h as default
};
