import { jsx as a } from "react/jsx-runtime";
const A = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M13.425 21.45c-.2.2-.421.346-.663.437a2.12 2.12 0 0 1-.762.138 2.12 2.12 0 0 1-.762-.138 1.867 1.867 0 0 1-.663-.437L2.55 13.425c-.2-.2-.346-.421-.438-.663A2.135 2.135 0 0 1 1.975 12c0-.267.046-.52.137-.762.092-.242.238-.463.438-.663l8.025-8.025c.2-.2.421-.346.663-.438.241-.091.495-.137.762-.137s.52.046.762.137c.242.092.463.238.663.438l8.025 8.025c.2.2.346.421.438.663.091.241.137.495.137.762s-.046.52-.137.762a1.879 1.879 0 0 1-.438.663l-8.025 8.025ZM12 20.025 20.025 12 12 3.975 3.975 12 12 20.025ZM12 13a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 12V8a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 7a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 8v4c0 .283.096.52.288.712A.965.965 0 0 0 12 13Zm0 3a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 15a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 14a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 15c0 .283.096.52.288.712A.965.965 0 0 0 12 16Z" })
  }
);
export {
  A as default
};
