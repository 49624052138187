import * as React from "react";
const SvgIconBarChart = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 .167a1.17 1.17 0 0 1 1.167 1.166v9.334A1.17 1.17 0 0 1 6 11.833a1.17 1.17 0 0 1-1.167-1.166V1.333A1.17 1.17 0 0 1 6 .167Zm-4.667 3.5H1.5a1.17 1.17 0 0 1 1.167 1.166v5.834A1.17 1.17 0 0 1 1.5 11.833h-.167a1.17 1.17 0 0 1-1.166-1.166V4.833a1.17 1.17 0 0 1 1.166-1.166ZM11.833 8a1.17 1.17 0 0 0-1.166-1.167A1.17 1.17 0 0 0 9.5 8v2.667a1.17 1.17 0 0 0 1.167 1.166 1.17 1.17 0 0 0 1.166-1.166V8Z"
      fill="#434F59"
    />
  </svg>
);
export default SvgIconBarChart;
