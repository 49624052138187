/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Slite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M21.7 9.87a2.702 2.702 0 0 1-2.295 2.67 3.543 3.543 0 0 0-2.894-1.626 2.703 2.703 0 0 1 2.49-3.743c1.486 0 2.699 1.213 2.699 2.7Zm-2.993-6.768c-1.49 0-2.7 1.214-2.7 2.7 0 .575.183 1.11.492 1.55a3.536 3.536 0 0 1 2.502-1.03c.792 0 1.526.262 2.116.702.187-.365.294-.781.294-1.217a2.71 2.71 0 0 0-2.704-2.705ZM12.98 15.295a2.703 2.703 0 0 0-3.314 2.629c0 1.487 1.213 2.7 2.7 2.7 1.486 0 2.7-1.213 2.7-2.7 0-.06-.005-.115-.009-.175a3.554 3.554 0 0 1-2.077-2.454Zm-4.166 2.629c0-.448.083-.876.238-1.273a2.738 2.738 0 0 0-.516-.052c-1.49 0-2.699 1.214-2.699 2.7 0 1.492 1.213 2.701 2.7 2.701.84 0 1.59-.385 2.085-.987a3.544 3.544 0 0 1-1.808-3.09Zm-6.263.087c0 1.491 1.213 2.7 2.7 2.7h.027a3.55 3.55 0 0 1-.293-1.407c0-1.451.872-2.696 2.12-3.248a2.688 2.688 0 0 0-1.855-.745 2.705 2.705 0 0 0-2.7 2.7ZM5 11.056c-1.49 0-2.7 1.214-2.7 2.7 0 .615.206 1.182.555 1.638a3.527 3.527 0 0 1 2.394-.932c.773 0 1.49.25 2.073.67.242-.404.381-.876.381-1.375 0-1.491-1.213-2.7-2.703-2.7Zm3.33-5.051A2.567 2.567 0 0 0 5.764 8.57a2.567 2.567 0 0 0 2.564 2.566 2.567 2.567 0 0 0 2.565-2.566A2.567 2.567 0 0 0 8.33 6.005Zm8.106 5.777a2.696 2.696 0 1 0 .001 5.392 2.696 2.696 0 0 0-.001-5.392Zm-4.139-7.086a2.703 2.703 0 0 0 3.211 2.653 3.55 3.55 0 0 1 1.368-4.584 2.696 2.696 0 0 0-4.579 1.931Z" />
  </svg>
);

export default Slite;
