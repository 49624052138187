import { jsx as c } from "react/jsx-runtime";
const o = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M11 16c0 .283.096.52.288.712A.965.965 0 0 0 12 17a.993.993 0 0 0 .713-.275.928.928 0 0 0 .287-.7V13h1.8c.233 0 .387-.104.462-.312.075-.209.038-.388-.112-.538l-2.8-2.8a.48.48 0 0 0-.7 0l-2.8 2.8c-.15.15-.187.33-.112.538.075.208.229.312.462.312H11v3Zm-7 4c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h5.175a1.978 1.978 0 0 1 1.4.575L12 6h8c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v10c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 20H4Z" })
  }
);
export {
  o as default
};
