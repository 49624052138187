import { jsx as t } from "react/jsx-runtime";
const h = (o) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ t("path", { d: "M3.915 4.635A.902.902 0 1 0 2.64 5.91L5 8.27v11.213a1 1 0 0 0 1.394.92L12 18l4.78 2.05 1.31 1.31a.902.902 0 1 0 1.275-1.275L3.915 4.635ZM7 18v-7.73l6 5.98-1-.43L7 18ZM7 5.16 5.5 3.67C5.88 3.26 6.41 3 7 3h10a2 2 0 0 1 2 2v12.16l-2-2V5H7v.16Z" })
  }
);
export {
  h as default
};
