import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 10.286C5 6.262 8.358 3 12.5 3c4.138 0 7.5 3.257 7.5 7.286 0 3.857-2.77 8.271-7.059 10.285v-3H12.5c-4.142 0-7.5-3.262-7.5-7.285Zm10.296-2.217v1.702l1.166-1.118c.321-.307.871-.088.871.345v3c0 .432-.55.65-.87.344l-1.167-1.113v1.702a.5.5 0 0 1-.509.486H8.676a.5.5 0 0 1-.51-.486V8.069a.5.5 0 0 1 .51-.486h6.111a.5.5 0 0 1 .51.486Z"
    }
  )
);
export {
  a as default
};
