import * as React from "react";
const SvgIconFilePdf = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M6.5 10.5h1v1h-1v-1ZM12.5 10.5h-1v3h1v-3Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5ZM5 9.75a.75.75 0 0 1 .866-.741C5.91 9.003 5.955 9 6 9h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6.5v1.25a.75.75 0 0 1-1.5 0v-4.5ZM10.25 9a.25.25 0 0 0-.25.25v5.5c0 .138.112.25.25.25h2.25a1.5 1.5 0 0 0 1.5-1.5v-3A1.5 1.5 0 0 0 12.5 9h-2.25Zm4.75.75a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5H16.5v1h.75a.75.75 0 0 1 0 1.5h-.75v1.25a.75.75 0 0 1-1.5 0v-4.5Z"
    />
  </svg>
);
export default SvgIconFilePdf;
