import * as React from "react";
const SvgIconOrgChart = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M11 7V5.732a2 2 0 1 1 2 0V7h3a1 1 0 0 1 1 1v2.268a2 2 0 0 1 0 3.464V15h3a1 1 0 0 1 1 1v2.268A2 2 0 0 1 20 22a2 2 0 0 1-1-3.732V17h-3a1 1 0 0 1-1-1v-2.268a2 2 0 0 1 0-3.464V9H9v1.268a2 2 0 0 1 0 3.464V15h3a1 1 0 0 1 1 1v2.268A2 2 0 0 1 12 22a2 2 0 0 1-1-3.732V17H5v1.268A2 2 0 0 1 4 22a2 2 0 0 1-1-3.732V16a1 1 0 0 1 1-1h3v-1.268a2 2 0 0 1 0-3.464V8a1 1 0 0 1 1-1h3Z" />
  </svg>
);
export default SvgIconOrgChart;
