import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M28 27s8.854-6.054 9.045-6.041A13.126 13.126 0 0 0 13.366 17h.134z",
      fill: "#0364b8"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M19.016 18.564A10.447 10.447 0 0 0 13.5 17h-.134a10.5 10.5 0 0 0-8.479 16.5L19 32l8.833-8.155z",
      fill: "#0f78d4"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M37.045 20.959a8.455 8.455 0 0 0-.576-.021 8.506 8.506 0 0 0-3.382.7l-5.254 2.21L34 31l9.98 2.517a8.532 8.532 0 0 0-6.935-12.558z",
      fill: "#1490df"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "m36.529 29.054-2.178-1.3-4.994-2.991-1.524-.913-4.019 1.692-7.7 3.238-3.452 1.45-7.775 3.27A10.484 10.484 0 0 0 13.5 38h22.969a8.531 8.531 0 0 0 7.511-4.483z",
      fill: "#28a8ea"
    }
  ),
  /* @__PURE__ */ e.createElement("path", { fill: "none", d: "M0 0h48v48H0z" })
);
export {
  a as default
};
