import { jsxs as h, jsx as c } from "react/jsx-runtime";
const d = (e) => /* @__PURE__ */ h(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ c("path", { d: "M8.362 9.457v2.585l.023.001c.099.008.19.014.277.014h.407c.3 0 .6-.046.885-.138.246-.07.461-.216.63-.408.162-.192.24-.454.24-.792a1.267 1.267 0 0 0-.178-.685c-.13-.2-.315-.354-.538-.438a2.257 2.257 0 0 0-.908-.154c-.2 0-.377 0-.523.007-.154-.007-.262 0-.315.008Z" }),
      /* @__PURE__ */ c(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M17.813 3H6.188C4.425 3 3 4.462 3 6.27v11.46C3 19.539 4.425 21 6.188 21h11.625C19.575 21 21 19.538 21 17.73V6.27C21 4.461 19.575 3 17.812 3ZM6.684 15.965V8.049c0-.053.023-.084.077-.084.13 0 .253 0 .43-.008.095-.004.192-.006.293-.008.094-.002.191-.003.292-.007.107-.004.219-.006.335-.008.107-.002.22-.004.334-.008.239-.007.47-.007.7-.007.63 0 1.154.077 1.585.238.384.13.738.346 1.03.63.247.247.439.547.562.878.115.323.177.654.177 1 0 .661-.154 1.208-.461 1.638-.308.431-.739.754-1.239.939-.523.192-1.1.261-1.73.261-.185 0-.308 0-.385-.007-.077-.008-.185-.008-.331-.008v2.47c.008.053-.03.1-.085.107H6.777c-.062 0-.092-.03-.092-.1Zm6.923-6.054h1.346c.077 0 .138.054.161.123a.781.781 0 0 1 .046.192 2.566 2.566 0 0 1 .054.516c.231-.27.508-.493.823-.662.354-.2.762-.3 1.17-.3.054-.007.1.031.107.085v1.53c0 .062-.038.085-.123.085a3.347 3.347 0 0 0-.83.077 3.17 3.17 0 0 0-.647.208c-.146.07-.284.161-.392.284v3.924c0 .076-.03.107-.1.107h-1.515c-.062.008-.116-.03-.123-.092v-4.292c0-.185 0-.377-.008-.577-.004-.1-.006-.2-.008-.3-.002-.1-.004-.2-.008-.3 0-.17-.014-.333-.028-.502l-.002-.021a.07.07 0 0 1 .054-.085c0-.008.015-.008.023 0Z"
        }
      )
    ]
  }
);
export {
  d as default
};
