import { jsx as c } from "react/jsx-runtime";
const l = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M10.025 14h7.95c.217 0 .371-.092.463-.275.091-.183.07-.358-.063-.525l-2.425-3.175a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2l-1.9 2.475L12.1 11a.475.475 0 0 0-.4-.2c-.167 0-.3.067-.4.2l-1.675 2.2c-.133.167-.154.342-.063.525.092.183.246.275.463.275ZM8 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 6 16V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 8 2h12c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 18H8Zm0-2h12V4H8v12Zm-4 6c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 20V7c0-.283.096-.521.288-.713A.967.967 0 0 1 3 6a.97.97 0 0 1 .713.287A.97.97 0 0 1 4 7v13h13c.283 0 .52.096.712.288A.965.965 0 0 1 18 21c0 .283-.096.52-.288.712A.965.965 0 0 1 17 22H4ZM8 4v12V4Z" })
  }
);
export {
  l as default
};
