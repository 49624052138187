import { jsxs as i, jsx as h } from "react/jsx-runtime";
const s = (t) => /* @__PURE__ */ i(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: [
      /* @__PURE__ */ h("path", { d: "M2 2h9.524v9.524H2V2Z", fill: "#38404C" }),
      /* @__PURE__ */ h("path", { d: "M12.476 2H22v9.524h-9.524V2Z", fill: "#596578" }),
      /* @__PURE__ */ h("path", { d: "M2 12.476h9.524V22H2v-9.524Z" }),
      /* @__PURE__ */ h("path", { d: "M12.476 12.476H22V22h-9.524v-9.524Z", fill: "#95A0B1" })
    ]
  }
);
export {
  s as default
};
