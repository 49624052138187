import { jsx as a } from "react/jsx-runtime";
const h = (A) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...A,
    children: /* @__PURE__ */ a("path", { d: "M11 20a.965.965 0 0 1-.712-.288A.965.965 0 0 1 10 19v-5.225c0-.033.017-.117.05-.25L11.4 9.65c.083-.183.204-.337.362-.462A.92.92 0 0 1 12.35 9h7.3a.92.92 0 0 1 .588.188c.158.125.279.279.362.462l1.35 3.875.05.25V19c0 .283-.096.52-.288.712A.965.965 0 0 1 21 20a.965.965 0 0 1-.712-.288A.965.965 0 0 1 20 19v-.5h-8v.5c0 .283-.096.52-.287.712A.968.968 0 0 1 11 20Zm1-7.5h8l-.7-2h-6.6l-.7 2Zm-.5 1.5v3-3Zm1.5 2.5a.968.968 0 0 0 .713-.288A.967.967 0 0 0 14 15.5a.97.97 0 0 0-.287-.713A.97.97 0 0 0 13 14.5a.967.967 0 0 0-.712.287.968.968 0 0 0-.288.713c0 .283.096.52.288.712A.965.965 0 0 0 13 16.5Zm6 0c.283 0 .52-.096.712-.288A.965.965 0 0 0 20 15.5a.968.968 0 0 0-.288-.713A.967.967 0 0 0 19 14.5a.967.967 0 0 0-.712.287.968.968 0 0 0-.288.713c0 .283.096.52.288.712A.965.965 0 0 0 19 16.5ZM4 19.4c0-.1.025-.217.075-.35a.897.897 0 0 1 .2-.325L5 18a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 2 15V7c0-1.1.492-1.875 1.475-2.325C4.458 4.225 6.133 4 8.5 4c2.467 0 4.167.217 5.1.65.933.433 1.4 1.217 1.4 2.35v1h-2V7H4v6h5v7H4.6a.578.578 0 0 1-.425-.175A.579.579 0 0 1 4 19.4ZM5 16a.968.968 0 0 0 .713-.288A.967.967 0 0 0 6 15a.97.97 0 0 0-.287-.713A.97.97 0 0 0 5 14a.97.97 0 0 0-.713.287A.97.97 0 0 0 4 15c0 .283.096.52.287.712.192.192.43.288.713.288Zm6.5 1h9v-3h-9v3Z" })
  }
);
export {
  h as default
};
