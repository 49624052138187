import { jsx as a } from "react/jsx-runtime";
const l = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M6 22c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 4 20V4c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 6 2h7.175a1.975 1.975 0 0 1 1.4.575l4.85 4.85a1.975 1.975 0 0 1 .575 1.4v3.525a5.61 5.61 0 0 0-.975-.262A5.939 5.939 0 0 0 18 12V9h-3c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 13 7V4H6v16h6.35c.133.383.3.742.5 1.075.2.333.433.642.7.925H6Zm12-2.575L16.6 20.8a.977.977 0 0 1-.688.287.93.93 0 0 1-.712-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l1.4-1.4-1.4-1.4a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l1.4 1.4 1.4-1.4a.977.977 0 0 1 .688-.287.93.93 0 0 1 .712.287.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L19.425 18l1.375 1.4c.183.183.28.413.287.688a.93.93 0 0 1-.287.712.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275L18 19.425Z" })
  }
);
export {
  l as default
};
