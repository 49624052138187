/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Meta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.376 14.637c0 .84.185 1.485.426 1.875.316.51.787.727 1.267.727.62 0 1.187-.153 2.28-1.664.875-1.211 1.906-2.911 2.6-3.977l1.175-1.806c.816-1.254 1.761-2.648 2.845-3.592.884-.772 1.838-1.2 2.798-1.2 1.612 0 3.148.934 4.323 2.686 1.286 1.919 1.91 4.336 1.91 6.83 0 1.482-.292 2.572-.79 3.433-.48.832-1.416 1.664-2.992 1.664v-2.374c1.35 0 1.686-1.239 1.686-2.657 0-2.022-.471-4.265-1.51-5.868-.736-1.137-1.691-1.831-2.742-1.831-1.136 0-2.05.857-3.078 2.384-.546.812-1.107 1.801-1.736 2.918l-.693 1.228c-1.393 2.468-1.745 3.03-2.442 3.959-1.22 1.625-2.262 2.24-3.634 2.24-1.627 0-2.656-.704-3.293-1.766-.52-.865-.776-2-.776-3.294l2.376.085Z"
      fill="#0081FB"
    />
    <path
      d="M2.874 7.854C3.963 6.174 5.535 5 7.338 5c1.045 0 2.083.31 3.167 1.194 1.186.968 2.45 2.562 4.026 5.188l.566.943c1.365 2.273 2.141 3.443 2.596 3.995.584.708.993.92 1.525.92 1.349 0 1.686-1.24 1.686-2.658L23 14.516c0 1.482-.292 2.572-.79 3.433-.48.832-1.416 1.664-2.992 1.664-.979 0-1.847-.213-2.806-1.118-.737-.695-1.6-1.929-2.263-3.038l-1.973-3.296c-.99-1.654-1.898-2.887-2.424-3.446C9.187 8.115 8.46 7.39 7.3 7.39c-.939 0-1.736.659-2.403 1.667L2.874 7.854Z"
      fill="url(#meta_svg__a)"
    />
    <path
      d="M7.3 7.39c-.938 0-1.736.658-2.403 1.666-.943 1.423-1.52 3.544-1.52 5.58 0 .84.184 1.486.425 1.876l-2.026 1.334c-.52-.865-.776-2-.776-3.294 0-2.353.646-4.805 1.874-6.698C3.964 6.174 5.535 5 7.338 5L7.3 7.39Z"
      fill="url(#meta_svg__b)"
    />
    <defs>
      <linearGradient
        id="meta_svg__a"
        x1={5.667}
        y1={13.951}
        x2={20.815}
        y2={14.716}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0064E1" />
        <stop offset={0.4} stopColor="#0064E1" />
        <stop offset={0.83} stopColor="#0073EE" />
        <stop offset={1} stopColor="#0082FB" />
      </linearGradient>
      <linearGradient
        id="meta_svg__b"
        x1={4.443}
        y1={15.634}
        x2={4.443}
        y2={10.049}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0082FB" />
        <stop offset={1} stopColor="#0064E0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Meta;
