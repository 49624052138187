import { jsx as t } from "react/jsx-runtime";
const e = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M4.975 15.925 7.5 12 4.975 8.075c-.433-.667-.458-1.346-.075-2.038C5.283 5.346 5.875 5 6.675 5H15c.333 0 .646.075.938.225.291.15.529.358.712.625l3.525 5c.25.35.375.733.375 1.15 0 .417-.125.8-.375 1.15l-3.525 5c-.183.267-.42.475-.712.625-.292.15-.605.225-.938.225H6.675c-.8 0-1.392-.346-1.775-1.038-.383-.691-.358-1.37.075-2.037Z" })
  }
);
export {
  e as default
};
