import * as a from "react";
const c = (l) => /* @__PURE__ */ a.createElement(
  "svg",
  {
    viewBox: "0 0 20 20",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ a.createElement(
    "path",
    {
      d: "M16.118 13.765c0 .245-.098.48-.275.657l-1.441 1.43a.976.976 0 0 1-.667.256.941.941 0 0 1-.666-.265l-2.02-2.03a.95.95 0 0 1 .049-1.382c.324.324.598.706 1.098.706.52 0 .941-.421.941-.94 0-.5-.382-.775-.706-1.099a.95.95 0 0 1 1.373-.04l2.04 2.04a.934.934 0 0 1 .274.667ZM9.225 6.853c0 .294-.117.52-.323.716-.324-.324-.598-.706-1.098-.706a.941.941 0 0 0-.941.94c0 .5.382.775.706 1.099a.921.921 0 0 1-.706.304.941.941 0 0 1-.667-.265l-2.04-2.039a.934.934 0 0 1 0-1.324l1.442-1.43a.985.985 0 0 1 .667-.266.95.95 0 0 1 .666.275l2.02 2.03a.95.95 0 0 1 .274.666ZM18 13.765c0-.755-.284-1.461-.824-2l-2.039-2.04a2.835 2.835 0 0 0-2-.823c-.774 0-1.5.314-2.039.863l-.863-.863a2.87 2.87 0 0 0 .863-2.05c0-.744-.284-1.46-.814-1.99l-2.02-2.029a2.787 2.787 0 0 0-2-.833c-.744 0-1.46.284-1.99.814l-1.44 1.431A2.805 2.805 0 0 0 2 6.235c0 .755.284 1.461.824 2l2.039 2.04c.53.529 1.255.823 2 .823.774 0 1.5-.314 2.039-.863l.863.863a2.87 2.87 0 0 0-.863 2.05c0 .744.284 1.46.814 1.99l2.02 2.029c.529.539 1.244.833 2 .833.744 0 1.46-.284 1.99-.814l1.44-1.431c.53-.52.834-1.245.834-1.99Z",
      fillRule: "evenodd"
    }
  )
);
export {
  c as default
};
