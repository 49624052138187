import { jsx as c } from "react/jsx-runtime";
const t = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M11.3 8.3 9.2 6.2a.96.96 0 0 1-.3-.7c0-.266.1-.5.3-.7l2.1-2.1c.2-.2.433-.3.7-.3.267 0 .5.1.7.3l2.1 2.1c.2.2.3.434.3.7 0 .267-.1.5-.3.7l-2.1 2.1c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3ZM2 20a.967.967 0 0 1-.712-.287A.968.968 0 0 1 1 19v-3c0-.566.196-1.041.587-1.425A1.947 1.947 0 0 1 3 14h3.275c.333 0 .65.084.95.25.3.167.542.392.725.675a5.158 5.158 0 0 0 1.788 1.525c.708.367 1.462.55 2.262.55.817 0 1.58-.183 2.287-.55a4.933 4.933 0 0 0 1.763-1.525c.217-.283.47-.508.762-.675.292-.166.596-.25.913-.25H21c.567 0 1.042.192 1.425.575.383.384.575.859.575 1.425v3c0 .284-.096.521-.288.713A.968.968 0 0 1 22 20h-5a.968.968 0 0 1-.712-.287A.968.968 0 0 1 16 19v-1.275A6.81 6.81 0 0 1 12 19a6.673 6.673 0 0 1-2.1-.337A7.035 7.035 0 0 1 8 17.7V19c0 .284-.096.521-.287.713A.967.967 0 0 1 7 20H2Zm2-7a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 1 10c0-.85.292-1.562.875-2.137S3.167 7 4 7c.85 0 1.563.288 2.138.863S7 9.15 7 10c0 .834-.287 1.542-.862 2.125C5.563 12.71 4.85 13 4 13Zm16 0a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 17 10c0-.85.292-1.562.875-2.137S19.167 7 20 7c.85 0 1.563.288 2.137.863C22.712 8.438 23 9.15 23 10c0 .834-.288 1.542-.863 2.125C21.563 12.71 20.85 13 20 13Z" })
  }
);
export {
  t as default
};
