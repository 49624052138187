import * as e from "react";
const t = (c) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...c
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12.855 4.348v.66c3.95.49 7 3.85 7 7.93 0 3.64-2.43 6.71-5.77 7.67-.62.18-1.23-.31-1.23-.96v-.02c0-.43.27-.82.68-.94 2.5-.73 4.32-3.02 4.32-5.75 0-2.97-2.16-5.43-5-5.91v1.53c0 .89-1.07 1.34-1.7.72l-2.13-2.08c-.4-.39-.4-1.03 0-1.43l2.12-2.13c.63-.63 1.71-.18 1.71.71Zm-8 4.76c.31-.57 1.1-.65 1.56-.19.31.31.38.78.16 1.15-.2.38-.36.77-.48 1.17-.12.42-.51.7-.94.7h-.01c-.65 0-1.15-.62-.96-1.25.17-.55.39-1.07.67-1.58Zm-.67 6.08c-.19-.63.31-1.25.96-1.25h.02c.43 0 .82.28.94.72.12.4.28.79.48 1.16.21.38.13.84-.17 1.15-.46.46-1.26.37-1.57-.2-.28-.51-.5-1.04-.66-1.58Zm3.83 4.77c.51.28 1.04.5 1.59.66.62.18 1.24-.32 1.24-.96v-.03c0-.43-.28-.82-.7-.94-.4-.12-.78-.28-1.15-.48a.97.97 0 0 0-1.16.17l-.03.03c-.45.45-.36 1.24.21 1.55Z"
    }
  )
);
export {
  t as default
};
