import * as React from "react";
const SvgIconQuoteInline = (props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M13 13v2h6v-2h-6ZM1 19h18v-2H1v2Zm12-8h6V9h-6v2Zm0-6v2h6V5h-6ZM1 1v2h18V1H1Zm.643 12H3.57l1.286-2.4V7H1v3.6h1.929L1.643 13Zm5 0H8.57l1.286-2.4V7H6v3.6h1.929L6.643 13Z"
      fillRule="evenodd"
    />
  </svg>
);
export default SvgIconQuoteInline;
