import { jsxs as t, jsx as h } from "react/jsx-runtime";
const f = (i) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...i,
    children: [
      /* @__PURE__ */ h("path", { fill: "#010046", d: "M2 2h20v20H2z" }),
      /* @__PURE__ */ h(
        "path",
        {
          d: "M8 9a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1ZM11 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  f as default
};
