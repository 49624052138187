import { jsxs as o, jsx as l } from "react/jsx-runtime";
const t = (e) => /* @__PURE__ */ o(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z",
          fill: "#0099E8"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          d: "M9.74 15.48 6 11.74 9.74 8l1.42 1.41-2.33 2.33 2.33 2.33-1.42 1.41ZM13 9.41 14.42 8l3.74 3.74-3.74 3.74L13 14.07l2.33-2.33L13 9.41Z",
          fill: "#FCFCFC"
        }
      )
    ]
  }
);
export {
  t as default
};
