import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "M15.125 21.1 6.7 12.7a.871.871 0 0 1-.212-.325A1.1 1.1 0 0 1 6.425 12a1.1 1.1 0 0 1 .063-.375.871.871 0 0 1 .212-.325l8.425-8.425c.233-.233.525-.35.875-.35s.65.125.9.375.375.542.375.875a1.2 1.2 0 0 1-.375.875L9.55 12l7.35 7.35c.233.234.35.521.35.862 0 .342-.125.638-.375.888a1.2 1.2 0 0 1-.875.375 1.2 1.2 0 0 1-.875-.375Z" })
  }
);
export {
  h as default
};
