import * as React from "react";
const SvgIconCopyFile = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M15.5 1h-11c-1.1 0-2 .9-2 2v13c0 .55.45 1 1 1s1-.45 1-1V4c0-.55.45-1 1-1h10c.55 0 1-.45 1-1s-.45-1-1-1Zm.59 4.59 4.83 4.83c.37.37.58.88.58 1.41V21c0 1.1-.9 2-2 2H8.49c-1.1 0-1.99-.9-1.99-2l.01-14c0-1.1.89-2 1.99-2h6.17c.53 0 1.04.21 1.42.59ZM20 12h-4.5c-.55 0-1-.45-1-1V6.5L20 12Z"
    />
  </svg>
);
export default SvgIconCopyFile;
