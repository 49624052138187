import { jsx as t } from "react/jsx-runtime";
const s = (o) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...o,
    children: /* @__PURE__ */ t("path", { d: "M15.896 4.323v15.125c0 1.692 1.167 2.635 2.406 2.635 1.146 0 2.406-.802 2.406-2.635V4.438c0-1.552-1.146-2.521-2.406-2.521-1.26 0-2.406 1.069-2.406 2.406ZM9.594 12v7.448c0 1.692 1.167 2.635 2.406 2.635 1.146 0 2.406-.802 2.406-2.635v-7.333c0-1.552-1.146-2.521-2.406-2.521-1.26 0-2.406 1.069-2.406 2.406ZM8.104 19.677a2.406 2.406 0 1 1-4.812 0 2.406 2.406 0 0 1 4.812 0Z" })
  }
);
export {
  s as default
};
