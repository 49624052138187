import * as v from "react";
const e = (h) => /* @__PURE__ */ v.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...h
  },
  /* @__PURE__ */ v.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15 6.121v5.17h4c1.1 0 2 .9 2 2v6c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-10c0-1.1.9-2 2-2h4v-1.17c0-.53.21-1.04.59-1.42l1.7-1.7a.996.996 0 0 1 1.41 0l1.71 1.71c.38.37.59.88.59 1.41ZM5 19.291h2v-2H5v2Zm2-4H5v-2h2v2Zm-2-4h2v-2H5v2Zm8 8h-2v-2h2v2Zm-2-4h2v-2h-2v2Zm2-4h-2v-2h2v2Zm-2-4h2v-2h-2v2Zm8 12h-2v-2h2v2Zm-2-4h2v-2h-2v2Z"
    }
  )
);
export {
  e as default
};
