import * as React from "react";
const SvgIconLogoGDriveColor = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="m8.49 5.263-5.367 9.684L5.929 20l5.36-9.684L8.49 5.263Z"
      fill="#23A566"
    />
    <path
      d="M10.143 14.947 7.336 20h10.735l2.806-5.053H10.143Z"
      fill="#4688F4"
    />
    <path d="M20.41 14.105 14.799 4H9.194l5.612 10.105h5.605Z" fill="#FFD04A" />
  </svg>
);
export default SvgIconLogoGDriveColor;
