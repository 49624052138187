import { jsx as a } from "react/jsx-runtime";
const h = (l) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...l,
    children: /* @__PURE__ */ a("path", { d: "M12 19a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 18v-3.175l.9.9a.934.934 0 0 0 .688.275c.275 0 .512-.1.712-.3.183-.2.28-.433.288-.7a.918.918 0 0 0-.288-.7l-2.6-2.6a.883.883 0 0 0-.325-.213 1.116 1.116 0 0 0-.375-.062c-.133 0-.258.02-.375.062a.883.883 0 0 0-.325.213l-2.6 2.6a.933.933 0 0 0-.275.688c0 .275.1.512.3.712.2.183.433.28.7.288.267.008.5-.088.7-.288l.875-.875v3.2c0 .283.096.517.288.7A.99.99 0 0 0 12 19Zm-6 3c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 20V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 6 2h7.175a1.978 1.978 0 0 1 1.4.575l4.85 4.85a1.978 1.978 0 0 1 .575 1.4V20c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 22H6Zm7-18H6v16h12V9h-4a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 8V4ZM6 4v5-5 16V4Z" })
  }
);
export {
  h as default
};
