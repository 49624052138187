import React from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Banner } from "@happeouikit/banners";
import { ButtonSecondary, IconButton } from "@happeouikit/buttons";
import { IconClose } from "@happeouikit/icons";
import { white } from "@happeouikit/colors";
import { TOAST_POSITION, TOAST_STATE } from "./constants";

const ToastBanner = ({
  show,
  state,
  title,
  description,
  actionLabel,
  actionHandler,
  position,
  hideClose,
  closeHandler,
}) => {
  return (
    <CSSTransition in={show} timeout={100} classNames="toast" unmountOnExit>
      <Container position={position} role="alert" aria-live="assertive">
        <Banner
          state={state}
          title={title}
          description={description}
          action={
            actionLabel && (
              <ActionWrapper>
                <ButtonSecondary text={actionLabel} onClick={actionHandler} />
              </ActionWrapper>
            )
          }
          rightAction={
            !hideClose && <IconButton icon={IconClose} onClick={closeHandler} />
          }
        />
      </Container>
    </CSSTransition>
  );
};

ToastBanner.propTypes = {
  show: PropTypes.bool.isRequired,
  state: PropTypes.oneOf(Object.values(TOAST_STATE)).isRequired,
  closeHandler: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primaryLabel: PropTypes.string,
  primaryHandler: PropTypes.func,
  secondaryLabel: PropTypes.string,
  secondaryHandler: PropTypes.func,
  hideClose: PropTypes.bool,
  position: PropTypes.oneOf(Object.values(TOAST_POSITION)),
};

ToastBanner.defaultProps = {
  show: false,
  state: TOAST_STATE.SUCCESS,
  closeHandler: () => {},
};

const Container = styled.div`
  position: fixed;
  ${({ position }) => {
    switch (position) {
      case TOAST_POSITION.RIGHT:
        return "right: 50px;";
      case TOAST_POSITION.CENTER:
        return `
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;
        `;
      default:
        // TOAST_POSITION.LEFT:
        return "left: 50px;";
    }
  }}
  bottom: -50px;
  width: 440px;
  max-width: 90vw;
  z-index: 2000;

  @media print {
    display: none;
  }

  &.toast-enter {
    transform: translateY(-50px);
  }

  &.toast-enter-active,
  &.toast-enter-done {
    transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translateY(-150px);
  }

  &.toast-exit {
    transform: translateY(-150px);
  }

  &.toast-exit-active {
    transform: translateY(-50px);
    transition: all 100ms ease-out;
  }

  & > div {
    background-color: ${white};
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default ToastBanner;
