import { jsx as a } from "react/jsx-runtime";
const o = (t) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ a("path", { d: "M8.854 10.146a.5.5 0 0 0 .353.854H11v9a1 1 0 1 0 2 0v-9h1.793a.5.5 0 0 0 .353-.854l-2.792-2.792a.5.5 0 0 0-.708 0l-2.792 2.792ZM5 3a1 1 0 0 0 0 2h14a1 1 0 1 0 0-2H5Z" })
  }
);
export {
  o as default
};
