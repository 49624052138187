import * as React from "react";
const SvgIconGif = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M8.583 8.417C8.973 8.806 9.444 9 10 9s1.028-.194 1.417-.583C11.806 8.027 12 7.556 12 7s-.194-1.028-.583-1.417A1.929 1.929 0 0 0 10 5c-.556 0-1.028.194-1.417.583A1.929 1.929 0 0 0 8 7c0 .556.194 1.028.583 1.417Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2h16c1.105 0 2 .852 2 2v16c0 1.148-.895 2-2 2H4c-1.105 0-2-.852-2-2V4c0-1.148.895-2 2-2Zm0 3.006V12l3-3 3 3 6-6 4 4V5.006C20 4.449 19.552 4 19 4H5a1 1 0 0 0-1 1.006ZM13 14h-1.5v6H13v-6Zm-4 0H6c-.6 0-1 .5-1 1v4c0 .5.4 1 1 1h3c.6 0 1-.5 1-1v-2H8.5v1.5h-2v-3H10V15c0-.5-.4-1-1-1Zm10 0v1.5h-3v1h2V18h-2v2h-1.5v-6H19Z"
    />
  </svg>
);
export default SvgIconGif;
