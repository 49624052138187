import { jsx as c } from "react/jsx-runtime";
const t = (v) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...v,
    children: /* @__PURE__ */ c("path", { d: "M3 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 1 16V4c0-.55.196-1.021.588-1.413A1.925 1.925 0 0 1 3 2h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v5h-2V6l-7.475 4.675c-.083.05-.171.087-.263.112a.981.981 0 0 1-.262.038.981.981 0 0 1-.262-.038 1.026 1.026 0 0 1-.263-.112L3 6v10h10v2H3Zm8-9 8-5H3l8 5Zm8 13c-1.1 0-2.042-.392-2.825-1.175C15.392 20.042 15 19.1 15 18v-4.5c0-.7.242-1.292.725-1.775C16.208 11.242 16.8 11 17.5 11s1.292.242 1.775.725c.483.483.725 1.075.725 1.775V17c0 .283-.096.52-.288.712A.965.965 0 0 1 19 18a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 17v-3.5a.48.48 0 0 0-.15-.35.48.48 0 0 0-.7 0 .48.48 0 0 0-.15.35V18c0 .55.196 1.021.587 1.413.392.391.863.587 1.413.587s1.021-.196 1.413-.587c.391-.392.587-.863.587-1.413v-3c0-.283.096-.521.288-.713A.967.967 0 0 1 22 14c.283 0 .52.096.712.287.192.192.288.43.288.713v3c0 1.1-.392 2.042-1.175 2.825C21.042 21.608 20.1 22 19 22ZM3 6v.25V4.8c0-.183 0-.183 0 0V4v.8-.025V6.25 6v10V6Z" })
  }
);
export {
  t as default
};
