import * as React from "react";
const SvgIconPlayArrow = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M6.927 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18a1 1 0 0 0 0-1.69l-8.14-5.17a.998.998 0 0 0-1.54.84Z" />
  </svg>
);
export default SvgIconPlayArrow;
