import { jsx as l } from "react/jsx-runtime";
const h = (t) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...t,
    children: /* @__PURE__ */ l(
      "path",
      {
        d: "M11.178 19.13c2.826-.5 5.16-.912 5.188-.918l.048-.01-2.668-3.174a453.586 453.586 0 0 1-2.669-3.19c0-.015 2.756-7.603 2.771-7.63.005-.009 1.88 3.228 4.546 7.848l4.571 7.924.035.06h-8.48l-8.481-.002 5.139-.908ZM1 18.162c0-.004 1.257-2.187 2.794-4.85L6.588 8.47l3.257-2.733c1.79-1.503 3.26-2.735 3.267-2.737a.57.57 0 0 1-.053.132l-3.536 7.584-3.472 7.448-2.526.003c-1.389.002-2.525 0-2.525-.005Z",
        fill: "#0089D6"
      }
    )
  }
);
export {
  h as default
};
