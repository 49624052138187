import { jsx as t } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ t("path", { d: "m6.3 21.3-2.6-2.6a.871.871 0 0 1-.212-.325A1.1 1.1 0 0 1 3.425 18a1.1 1.1 0 0 1 .063-.375.871.871 0 0 1 .212-.325l2.6-2.6a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-.9.9H20c.283 0 .52.096.712.288A.965.965 0 0 1 21 18c0 .283-.096.52-.288.712A.965.965 0 0 1 20 19H6.8l.9.9a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275ZM10 15a.965.965 0 0 1-.712-.288A.965.965 0 0 1 9 14v-4c-1.1 0-2.042-.392-2.825-1.175C5.392 8.042 5 7.1 5 6s.392-2.042 1.175-2.825C6.958 2.392 7.9 2 9 2h7c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 16 4h-1v10c0 .283-.096.52-.287.712A.968.968 0 0 1 14 15a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 14V4h-2v10c0 .283-.096.52-.287.712A.968.968 0 0 1 10 15Z" })
  }
);
export {
  h as default
};
