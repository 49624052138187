import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M19.075 21.9 7 9.85l-2.175 2.175 3.9 3.9A.918.918 0 0 1 9 16.6c0 .266-.1.5-.3.7a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-4.6-4.6a.96.96 0 0 1-.3-.7c0-.267.1-.5.3-.7l2.875-2.875-3.5-3.5a.933.933 0 0 1-.275-.688c0-.275.1-.512.3-.712.2-.2.437-.3.712-.3.275 0 .513.1.713.3L20.5 20.5a.98.98 0 0 1 .288.688.932.932 0 0 1-.288.712c-.2.2-.437.3-.712.3a.974.974 0 0 1-.713-.3Zm-.65-6.325L17 14.15l2.175-2.175-3.9-3.9A.918.918 0 0 1 15 7.4c0-.267.1-.5.3-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l4.6 4.6c.2.2.3.433.3.7 0 .266-.1.5-.3.7l-2.875 2.875Z" })
  }
);
export {
  t as default
};
