import { jsx as s } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ s("path", { d: "M6 21c-1.1 0-2.042-.392-2.825-1.175C2.392 19.042 2 18.1 2 17s.392-2.042 1.175-2.825C3.958 13.392 4.9 13 6 13s2.042.392 2.825 1.175C9.608 14.958 10 15.9 10 17s-.392 2.042-1.175 2.825C8.042 20.608 7.1 21 6 21Zm12 0c-1.1 0-2.042-.392-2.825-1.175C14.392 19.042 14 18.1 14 17s.392-2.042 1.175-2.825C15.958 13.392 16.9 13 18 13s2.042.392 2.825 1.175C21.608 14.958 22 15.9 22 17s-.392 2.042-1.175 2.825C20.042 20.608 19.1 21 18 21ZM6 19c.55 0 1.02-.196 1.412-.587C7.804 18.02 8 17.55 8 17s-.196-1.02-.588-1.412A1.926 1.926 0 0 0 6 15c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 4 17c0 .55.196 1.02.588 1.413.391.391.862.587 1.412.587Zm12 0c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413s-.196-1.02-.587-1.412A1.926 1.926 0 0 0 18 15c-.55 0-1.02.196-1.413.588A1.926 1.926 0 0 0 16 17c0 .55.196 1.02.587 1.413.392.391.863.587 1.413.587Zm-6-8c-1.1 0-2.042-.392-2.825-1.175C8.392 9.042 8 8.1 8 7s.392-2.042 1.175-2.825C9.958 3.392 10.9 3 12 3s2.042.392 2.825 1.175C15.608 4.958 16 5.9 16 7s-.392 2.042-1.175 2.825C14.042 10.608 13.1 11 12 11Zm0-2c.55 0 1.02-.196 1.412-.588C13.804 8.021 14 7.55 14 7c0-.55-.196-1.02-.588-1.412A1.926 1.926 0 0 0 12 5c-.55 0-1.02.196-1.412.588A1.926 1.926 0 0 0 10 7c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Z" })
  }
);
export {
  t as default
};
