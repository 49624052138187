import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 32 28",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ e.createElement("rect", { width: 32, height: 28, fill: "#EC4B2F", rx: 2 }), /* @__PURE__ */ e.createElement(
    "path",
    {
      fill: "#F1F1F1",
      fillRule: "nonzero",
      d: "M16.238 6.002c-.194-.7-.664-1.048-1.063-.997-.48.062-.991.35-1.196.801-.572 1.295.624 5.107.808 5.744-1.165 3.535-5.152 10.523-7.104 10.995-.041-.462.204-1.798 2.78-3.463.133-.143.286-.318.358-.431-2.177 1.069-4.999 2.785-3.302 4.069.092.072.235.134.399.195 1.298.483 3.117-1.1 4.947-4.685 2.014-.668 3.639-1.172 5.939-1.521 2.514 1.716 4.2 2.065 5.346 1.623.317-.123.817-.524.96-1.048-.93 1.151-3.107.35-4.844-.75 1.604-.175 3.26-.277 3.976-.051.91.287.879.74.869.801.071-.246.173-.647-.02-.966-.768-1.253-4.284-.524-5.561-.39-2.014-1.223-3.404-3.401-3.966-4.973.52-2.025 1.083-3.494.674-4.953m-.869 4.243c-.337-1.202-.807-3.873-.05-4.737 1.481.853.561 2.878.05 4.737m2.77 5.96c-1.942.36-3.26.863-5.151 1.613.562-1.099 1.625-3.904 2.136-5.754.716 1.439 1.584 2.847 3.015 4.141"
    }
  ))
);
export {
  l as default
};
