import { jsxs as t, jsx as l } from "react/jsx-runtime";
const d = (e) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...e,
    children: [
      /* @__PURE__ */ l(
        "path",
        {
          d: "M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z",
          fill: "#97A0AF"
        }
      ),
      /* @__PURE__ */ l(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M12 8.39a3.61 3.61 0 1 0 0 7.219 3.61 3.61 0 0 0 0-7.218ZM6.586 12a5.414 5.414 0 1 1 10.828 0 5.414 5.414 0 0 1-10.828 0Z",
          fill: "#fff"
        }
      )
    ]
  }
);
export {
  d as default
};
