import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M2 2h9.524v9.524H2V2Z", fill: "#F35325" }),
  /* @__PURE__ */ e.createElement("path", { d: "M12.476 2H22v9.524h-9.524V2Z", fill: "#81BC06" }),
  /* @__PURE__ */ e.createElement("path", { d: "M2 12.476h9.524V22H2v-9.524Z", fill: "#05A6F0" }),
  /* @__PURE__ */ e.createElement("path", { d: "M12.476 12.476H22V22h-9.524v-9.524Z", fill: "#FFBA08" })
);
export {
  l as default
};
