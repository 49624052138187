import { jsxs as o, jsx as t } from "react/jsx-runtime";
const l = (a) => /* @__PURE__ */ o(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ t("path", { d: "M10.477 14.978a2.832 2.832 0 0 1-.963-1.245v.002a4.99 4.99 0 0 1 .007-3.588c.201-.503.542-.939.982-1.256a2.598 2.598 0 0 1 1.54-.457 2.49 2.49 0 0 1 1.593.49c.422.336.737.787.907 1.298.193.567.288 1.161.281 1.76a4.834 4.834 0 0 1-.298 1.736c-.184.498-.51.931-.936 1.246-.47.326-1.035.49-1.607.468a2.517 2.517 0 0 1-1.506-.454Z" }),
      /* @__PURE__ */ t(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M19.5 3h-15A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3ZM9.4 7.398A4.362 4.362 0 0 0 7.688 9.29c-.4.866-.598 1.81-.581 2.764-.016.91.186 1.811.59 2.627a4.39 4.39 0 0 0 1.69 1.812c.781.45 1.672.676 2.574.654a5.013 5.013 0 0 0 2.613-.664 4.427 4.427 0 0 0 1.718-1.871 6.16 6.16 0 0 0 .602-2.782 5.836 5.836 0 0 0-.584-2.66 4.323 4.323 0 0 0-1.667-1.807 4.82 4.82 0 0 0-2.52-.647 5.18 5.18 0 0 0-2.721.682Z"
        }
      )
    ]
  }
);
export {
  l as default
};
