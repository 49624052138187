import React, { useCallback, useEffect, useState } from "react";
import styled, { css, ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { type Theme, themeDark, themeLight } from "./theme";
import { toCss } from "./cssUtils";

type Props = {
  children: React.ReactNode;
};

export const HappeoThemeProvider = ({ children }: Props) => {
  const { theme } = useTheme();
  return (
    <StyledComponentsThemeProvider theme={theme}>
      {children}
    </StyledComponentsThemeProvider>
  );
};

export const DebugHappeoThemeProvider = ({children, showThemeColorMarker = true, selectedTheme}: {children: any, showThemeColorMarker: boolean, selectedTheme?: "dark" | "light" }) => {
  
  const { theme, setTheme, themes } = useTheme();

  useEffect(() => {
    if(!selectedTheme) return;
    if(selectedTheme === "dark") {
      setTheme(themeDark);
    } else {
      setTheme(themeLight);
    }
  }, [selectedTheme]);
  

  const toggleThemeColor = useCallback(() => {
    const appThemeIndex = themes.findIndex((t) => t === theme);
    const newTheme = themes[(appThemeIndex + 1) % themes.length];
    setTheme(newTheme);
  }, [theme, themes, setTheme]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key, ctrlKey, metaKey, shiftKey } = event;
      const ctrl = ctrlKey || metaKey;
      if (ctrl && shiftKey && key.toLowerCase() === "s") {
        toggleThemeColor();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [theme]);

  return (
    <StyledComponentsThemeProvider theme={theme}>
      {showThemeColorMarker && <ThemeColorMarker onClick={toggleThemeColor} title={"Toggle theme color (Ctrl+Shift+S)"}>
        <p>{`Theme: ${theme.name}`}</p>
      </ThemeColorMarker>}
      {children}
    </StyledComponentsThemeProvider>
  );
};

const THEME_STYLE_ELEMENT_ID = "happeouikit-theme";
const REGENERATE_CSS_VARS_BY_LOGGING = false;
let lastLoggedTheme = "";

/**
 * Applies light/dark theme, which includes all basic design tokens and some dynamic semantic tokens.
 *
 * - Does NOT apply organization colors
 * - Does NOT apply organization-configured custom fonts
 * - Does NOT maintain consistent theme across refreshes (just defaults to light theme).
 */
export const useTheme = () => {
  // default to light theme at the start, for now
  const [theme, setTheme] = useState<Theme>(themeLight);

  useEffect(() => {
    if (lastLoggedTheme !== theme.name) {
      lastLoggedTheme = theme.name;
      console.log("Applying happeouikit theme:", theme.name);
    }
    const newThemeStyleElement = document.createElement("style");
    newThemeStyleElement.id = THEME_STYLE_ELEMENT_ID;
    const cssString = `
:root {
${toCss(theme)}
}`;
    if (REGENERATE_CSS_VARS_BY_LOGGING) {
      // you can temporarily enable this boolean to regenerate the _generated_css_vars_for_autocompletion.css file.
      console.log(`# generated_css_vars_for_autocompletion (press Copy, paste into file, reformat)`, `\n`, cssString);
    }
    newThemeStyleElement.innerHTML = cssString;
    document.head.appendChild(newThemeStyleElement);
    return () => {
      const oldThemeStyleElement = document.getElementById(THEME_STYLE_ELEMENT_ID);
      oldThemeStyleElement?.parentNode?.removeChild?.(oldThemeStyleElement);
    };
  }, [theme]);

  return {
    theme,
    themes: [themeLight, themeDark],
    setTheme,
  };
};

/*
 * This code is an example of how you can use design tokens in styled components
 */
const ThemeColorMarker = styled.button`
  margin-top: var(--space-md);
  background-color: ${({ theme }) => theme.color.activeLighten90};
  border-radius: var(--radius-xl);
  border: 1px solid var(--color-stroke-darken);

  p {
    font: var(--font-code-lg);
    line-height: 0;
    ${({ theme }) => css`
      color: ${theme.color.primaryTextOnLight};
    `};
  }
`;