import * as React from "react";
const SvgIconImageOverflowCenter = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 21a1 1 0 1 0 0-2H6a1 1 0 1 0 0 2h12Zm1-17a1 1 0 0 0-1-1H6a1 1 0 0 0 0 2h12a1 1 0 0 0 1-1Zm1 13a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h16Z"
    />
  </svg>
);
export default SvgIconImageOverflowCenter;
