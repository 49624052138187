import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M6.7 17.6a.942.942 0 0 1-.375-.075A1.038 1.038 0 0 1 6 17.3l-4.225-4.225c-.183-.183-.275-.421-.275-.713 0-.291.092-.529.275-.712a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l3.55 3.55 1.4 1.4-.725.7c-.1.1-.208.175-.325.225a.942.942 0 0 1-.375.075Zm5.65-.025a1.1 1.1 0 0 1-.375-.063.871.871 0 0 1-.325-.212L7.4 13.05c-.183-.183-.275-.421-.275-.713 0-.291.092-.529.275-.712a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l3.55 3.55 8.5-8.5a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275.183.183.275.42.275.712s-.092.53-.275.713l-9.2 9.2c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063Zm0-5.225-1.425-1.4 4.25-4.25c.183-.183.42-.275.712-.275s.53.092.713.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-4.25 4.25Z" })
  }
);
export {
  t as default
};
