/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Confluence = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.723 17.23c-.206.35-.438.756-.634 1.08a.676.676 0 0 0 .212.899l4.127 2.643c.145.093.32.121.485.08a.638.638 0 0 0 .394-.305c.165-.287.378-.66.61-1.06 1.635-2.808 3.28-2.465 6.244-.991l4.092 2.025a.613.613 0 0 0 .494.022c.16-.062.29-.19.36-.353l1.965-4.625a.672.672 0 0 0-.318-.865c-.863-.423-2.58-1.265-4.126-2.042-5.562-2.811-10.289-2.63-13.905 3.492Z"
      fill="url(#confluence_svg__a)"
    />
    <path
      d="M21.228 6.734c.206-.35.438-.756.635-1.08a.676.676 0 0 0-.213-.898l-4.127-2.643a.615.615 0 0 0-.498-.096.637.637 0 0 0-.406.314c-.166.287-.378.66-.61 1.06-1.635 2.808-3.28 2.465-6.244.991l-4.08-2.015a.612.612 0 0 0-.493-.023.646.646 0 0 0-.36.353L2.867 7.322a.672.672 0 0 0 .317.866c.864.423 2.581 1.265 4.127 2.041 5.574 2.808 10.301 2.62 13.917-3.495Z"
      fill="url(#confluence_svg__b)"
    />
    <defs>
      <linearGradient
        id="confluence_svg__a"
        x1={20.961}
        y1={23.216}
        x2={16.021}
        y2={12.327}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.18} stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="confluence_svg__b"
        x1={2.991}
        y1={0.745}
        x2={7.938}
        y2={11.639}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.18} stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default Confluence;
