import Color from "./color";
import { HEX, ColorString } from "./types";

// Takes two colors and flattens them to one.
// Theory and reference https://www.rweber.net/projects/flattening-color-layers-javascript/

const mix = (upperColor: ColorString, lowerColor: ColorString): HEX => {
  const upperArray = new Color(upperColor).toArray();
  const lowerArray = new Color(lowerColor).toArray();
  const mergedArray: [number, number, number, number?] = [0, 0, 0];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    upperArray[i] *= upperArray[3];
    mergedArray[i] = Math.round(
      (1 - upperArray[3]) * lowerArray[i] + upperArray[i]
    );
  }
  mergedArray[3] = 1;
  return new Color(mergedArray[0], mergedArray[1], mergedArray[2]).toHex();
};

export default mix;
