import { jsx as c } from "react/jsx-runtime";
const h = (s) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...s,
    children: /* @__PURE__ */ c("path", { d: "M12 5V2.21c0-.45-.54-.67-.85-.35l-3.8 3.79c-.2.2-.2.51 0 .71l3.79 3.79c.32.31.86.09.86-.36V7c3.73 0 6.68 3.42 5.86 7.29-.47 2.27-2.31 4.1-4.57 4.57-3.57.75-6.75-1.7-7.23-5.01a1 1 0 0 0-.98-.85c-.6 0-1.08.53-1 1.13.62 4.39 4.8 7.64 9.53 6.72 3.12-.61 5.63-3.12 6.24-6.24C20.84 9.48 16.94 5 12 5Zm-2.44 8.49h.45c.21 0 .37-.05.48-.16.11-.11.16-.25.16-.43a.538.538 0 0 0-.15-.39.418.418 0 0 0-.18-.11.935.935 0 0 0-.25-.04.78.78 0 0 0-.22.03.4.4 0 0 0-.18.1c-.05.05-.09.09-.12.15s-.05.13-.05.2h-.85a1.06 1.06 0 0 1 .41-.85c.13-.1.27-.18.44-.23.17-.05.35-.08.54-.08.21 0 .41.03.59.08s.33.13.46.23.23.23.3.38c.07.15.11.33.11.53a.842.842 0 0 1-.17.52 1.1 1.1 0 0 1-.48.39c.24.09.42.21.54.39.12.18.18.38.18.61 0 .2-.04.38-.12.53a1.1 1.1 0 0 1-.32.39c-.14.1-.29.19-.48.24-.19.05-.38.08-.6.08-.18 0-.36-.02-.53-.07-.17-.05-.33-.12-.46-.23-.13-.11-.25-.23-.33-.38-.08-.15-.12-.34-.12-.55h.85c0 .08.02.15.05.22.03.07.07.12.13.17.06.05.12.09.2.11.08.02.16.04.25.04.1 0 .19-.01.27-.04a.561.561 0 0 0 .33-.3c.03-.07.04-.15.04-.24 0-.11-.02-.21-.05-.29a.508.508 0 0 0-.36-.31c-.09-.02-.18-.04-.29-.04h-.47v-.65Zm5.74.75c0 .32-.03.6-.1.82-.07.22-.17.42-.29.57-.12.15-.28.26-.45.33-.17.07-.37.1-.59.1-.22 0-.41-.03-.59-.1s-.33-.18-.46-.33c-.13-.15-.23-.34-.3-.57-.07-.23-.11-.5-.11-.82v-.74c0-.32.03-.6.1-.82.07-.22.17-.42.29-.57.12-.15.28-.26.45-.33.17-.07.37-.1.59-.1.22 0 .41.03.59.1s.33.18.46.33c.13.15.23.34.3.57.07.23.11.5.11.82v.74Zm-.85-.86c0-.19-.01-.35-.04-.48s-.07-.23-.12-.31a.389.389 0 0 0-.19-.17.655.655 0 0 0-.5 0c-.07.03-.14.09-.19.17-.05.08-.09.18-.12.31s-.04.29-.04.48v.97c0 .19.01.35.04.48s.07.24.12.32c.05.08.11.14.19.17s.16.05.25.05.18-.02.25-.05c.07-.03.14-.09.19-.17.05-.08.09-.19.11-.32.03-.13.04-.29.04-.48v-.97h.01Z" })
  }
);
export {
  h as default
};
