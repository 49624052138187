/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraImprovement = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z"
      fill="#63BA3C"
    />
    <path
      d="M10.94 6.44a1.497 1.497 0 0 1 2.12 0l4.5 4.5a1.5 1.5 0 0 1-2.12 2.12l-1.94-1.939V16.5a1.5 1.5 0 0 1-3 0v-5.379l-1.94 1.94a1.5 1.5 0 0 1-2.12-2.122l4.5-4.5Z"
      fill="#fff"
    />
  </svg>
);

export default JiraImprovement;
