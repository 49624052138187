import * as e from "react";
const c = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 32 32",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M14.001.667C6.641.667.668 6.64.668 14s5.973 13.333 13.333 13.333S27.335 21.36 27.335 14 21.36.667 14 .667Zm-1.333 23.906C7.401 23.92 3.335 19.44 3.335 14c0-.827.106-1.613.28-2.387L10 18v1.333C10 20.8 11.2 22 12.667 22v2.573Zm6.667-5.24c1.2 0 2.186.774 2.533 1.854a10.61 10.61 0 0 0 2.8-7.187c0-4.467-2.76-8.293-6.667-9.88v.547c0 1.466-1.2 2.666-2.666 2.666h-2.667V10c0 .733-.6 1.333-1.333 1.333H8.668V14h8c.733 0 1.333.6 1.333 1.333v4h1.334Z",
      fillRule: "evenodd"
    }
  )
);
export {
  c as default
};
