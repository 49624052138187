import * as React from "react";
const SvgIconLinkOff = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.119 3.63a.996.996 0 1 0-1.41 1.41l2.4 2.4c-1.94.8-3.27 2.77-3.09 5.04.21 2.64 2.57 4.59 5.21 4.59h2.82c.52 0 .95-.43.95-.95s-.43-.95-.95-.95h-2.89c-1.63 0-3.1-1.19-3.25-2.82A3.095 3.095 0 0 1 6.659 9l2.1 2.1c-.43.09-.76.46-.76.92v.1c0 .52.43.95.95.95h1.78l2.27 2.27v1.73h1.73l3.3 3.3a.996.996 0 1 0 1.41-1.41L4.119 3.63Zm17.82 7.67c-.37-2.47-2.62-4.23-5.12-4.23h-2.87c-.52 0-.95.43-.95.95s.43.95.95.95h2.9c1.6 0 3.04 1.14 3.22 2.73.17 1.43-.64 2.69-1.85 3.22l1.4 1.4c1.63-1.02 2.64-2.91 2.32-5.02Zm-6.89-.23c.52 0 .95.43.95.95v.1c0 .16-.05.31-.12.44l-1.49-1.49h.66Z"
    />
  </svg>
);
export default SvgIconLinkOff;
