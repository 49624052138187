import * as e from "react";
const a = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 32 32",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement("path", { d: "M24.001 10.667c0-4.414-3.586-8-8-8-4.413 0-8 3.586-8 8 0 4.653 4.827 10.92 6.974 13.493.533.64 1.506.64 2.04 0C19.175 21.587 24 15.32 24 10.667Zm-10.666 0C13.335 9.2 14.535 8 16 8a2.666 2.666 0 1 1-2.667 2.667ZM8 29.333c-.733 0-1.333-.6-1.333-1.333s.6-1.333 1.333-1.333h16c.734 0 1.334.6 1.334 1.333s-.6 1.333-1.334 1.333h-16Z" })
);
export {
  a as default
};
