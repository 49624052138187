import React, { useCallback, useState } from "react";
import { BodyUI } from "@happeouikit/typography";
import {
  ListStripedContainer,
  ListHeader,
  LiCol,
  LiStriped,
  Li,
  Ul,
} from "./index";

export const StripedListExample = () => {
  const headers = [
    { name: "", width: "5%" },
    { name: "Name", sortable: true, field: "name", width: "30%" },
    { name: "Email", sortable: true, field: "email", width: "25%" },
    {
      name: "Header 1",
      width: "25%",
      mobileHidden: true,
      tooltip: "This is a tooltip",
    },
    {
      name: "Header 2",
      field: "header2",
      width: "15%",
      tooltip: "This is a tooltip",
      sortable: true,
    },
  ];

  const [items, setItems] = useState([
    { name: "Bob the Builder", email: "bob@bob.com" },
    { name: "Joe the Juggler", email: "joe@joe.com" },
    { name: "Sally the Server", email: "sally@sally.com" },
    { name: "Carol the Calculator", email: "carol@carol.com" },
    { name: "Jack the Jumper", email: "jack@jack.com" },
  ]);

  const [sortDir, setSortDir] = useState("desc");
  const [sortField, setSortField] = useState("name");

  const sortFn = useCallback(
    (field) => {
      const sortedItems = items
        .slice()
        .sort((a, b) =>
          sortDir === "desc"
            ? a[field] && b[field] && a[field].localeCompare(b[field])
            : a[field] && b[field] && b[field].localeCompare(a[field])
        );
      setItems(sortedItems);
      setSortField(field);
      setSortDir(sortDir === "desc" ? "asc" : "desc");
    },
    [items, sortDir]
  );

  return (
    <ListStripedContainer>
      <ListHeader
        headers={headers}
        sortDir={sortDir}
        sortField={sortField}
        sortFn={sortFn}
      />
      {items.map((item) => {
        return (
          <LiStriped key={item.name}>
            <LiCol width="5%" mobileHidden>
              <input type="checkbox" />
            </LiCol>
            <LiCol width="30%">
              <BodyUI>{item.name}</BodyUI>
            </LiCol>
            <LiCol width="25%">
              <BodyUI>{item.email}</BodyUI>
            </LiCol>
            <LiCol width="25%">
              <BodyUI>Content 1 </BodyUI>
            </LiCol>
            <LiCol width="15%">
              <BodyUI>Content 2</BodyUI>
            </LiCol>
          </LiStriped>
        );
      })}
    </ListStripedContainer>
  );
};

export const ListWithMarginsExample = () => {
  const headers = [
    { name: "", width: "5%" },
    { name: "Name", sortable: true, field: "name", width: "30%" },
    { name: "Email", sortable: true, field: "email", width: "25%" },
    {
      name: "Header 1",
      width: "25%",
      mobileHidden: true,
      tooltip: "This is a tooltip",
    },
    {
      name: "Header 2",
      field: "header2",
      width: "15%",
      tooltip: "This is a tooltip",
      sortable: true,
    },
  ];

  const [items, setItems] = useState([
    { name: "Bob the Builder", email: "bob@bob.com" },
    { name: "Joe the Juggler", email: "joe@joe.com" },
    { name: "Sally the Server", email: "sally@sally.com" },
    { name: "Carol the Calculator", email: "carol@carol.com" },
    { name: "Jack the Jumper", email: "jack@jack.com" },
  ]);

  const [sortDir, setSortDir] = useState("desc");
  const [sortField, setSortField] = useState("name");

  const sortFn = useCallback(
    (field) => {
      const sortedItems = items
        .slice()
        .sort((a, b) =>
          sortDir === "desc"
            ? a[field] && b[field] && a[field].localeCompare(b[field])
            : a[field] && b[field] && b[field].localeCompare(a[field])
        );
      setItems(sortedItems);
      setSortField(field);
      setSortDir(sortDir === "desc" ? "asc" : "desc");
    },
    [items, sortDir]
  );

  return (
    <Ul>
      <ListHeader
        headers={headers}
        sortDir={sortDir}
        sortField={sortField}
        sortFn={sortFn}
      />
      {items.map((item) => {
        return (
          <Li key={item.name}>
            <LiCol width="5%">
              <input type="checkbox" />
            </LiCol>
            <LiCol width="30%">
              <BodyUI>{item.name}</BodyUI>
              <BodyUI>100</BodyUI>
            </LiCol>
            <LiCol width="25%">
              <BodyUI>{item.email}</BodyUI>
            </LiCol>
            <LiCol width="25%">
              <BodyUI>Content 1 </BodyUI>
            </LiCol>
            <LiCol width="15%">
              <BodyUI>Content 2</BodyUI>
            </LiCol>
          </Li>
        );
      })}
    </Ul>
  );
};

const headers = [
  { name: "", width: "5%" },
  { name: "Name", sortable: true, field: "name", width: "30%" },
  { name: "User", sortable: true, field: "user", width: "25%" },
  { name: "Header 1", sortable: false, width: "25%", mobileHidden: true },
  { name: "Header 2", field: "header2", sortable: true, width: "15%" },
];

export const HeaderWithData = () => {
  return (
    <ListHeader
      headers={headers}
      sortDir="desc"
      sortField="name"
      sortFn={(fn) => {
        console.log("SORTING", fn);
      }}
    />
  );
};

const items = [
  { name: "Test column", owner: "User name" },
  { name: "Test column", owner: "User name" },
  { name: "Test column", owner: "User name" },
  { name: "Test column", owner: "User name" },
  { name: "Test column", owner: "User name" },
];

export const LiStripedWithData = () => {
  return items.map((item, i) => {
    return (
      <LiStriped key={i}>
        <LiCol width="5%" mobileHidden>
          <input type="checkbox" />
        </LiCol>
        <LiCol width="30%">
          <BodyUI>{item.name}</BodyUI>
        </LiCol>
        <LiCol width="25%">
          <BodyUI>{item.owner}</BodyUI>
        </LiCol>
        <LiCol width="25%">
          <BodyUI>Content 1 </BodyUI>
        </LiCol>
        <LiCol width="15%">
          <BodyUI>Content 2</BodyUI>
        </LiCol>
      </LiStriped>
    );
  });
};

export const LiWithData = () => {
  return items.map((item, i) => {
    return (
      <Li key={i}>
        <LiCol width="5%">
          <input type="checkbox" />
        </LiCol>
        <LiCol width="30%">
          <BodyUI>{item.name}</BodyUI>
          <BodyUI>100</BodyUI>
        </LiCol>
        <LiCol width="25%">
          <BodyUI>{item.owner}</BodyUI>
        </LiCol>
        <LiCol width="25%">
          <BodyUI>Content 1 </BodyUI>
        </LiCol>
        <LiCol width="15%">
          <BodyUI>Content 2</BodyUI>
        </LiCol>
      </Li>
    );
  });
};
