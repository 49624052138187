import { jsx as c } from "react/jsx-runtime";
const o = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M6.5 16a9.95 9.95 0 0 1 2.288.262c.741.175 1.479.438 2.212.788V7.2c-.683-.4-1.408-.7-2.175-.9a9.175 9.175 0 0 0-4.112-.125A8.663 8.663 0 0 0 3 6.7v9.9c.583-.2 1.163-.35 1.738-.45C5.313 16.05 5.9 16 6.5 16Zm6.5 1.05a11.01 11.01 0 0 1 2.213-.788 10.276 10.276 0 0 1 4.05-.112c.575.1 1.154.25 1.737.45V6.7a8.668 8.668 0 0 0-1.712-.525 9.226 9.226 0 0 0-4.113.125c-.767.2-1.492.5-2.175.9v9.85Zm-1 2.425c-.233 0-.454-.029-.662-.087a2.814 2.814 0 0 1-.588-.238 8.165 8.165 0 0 0-2.05-.862A8.465 8.465 0 0 0 6.5 18c-.7 0-1.387.092-2.062.275a9.047 9.047 0 0 0-1.938.775c-.35.183-.687.175-1.012-.025A.966.966 0 0 1 1 18.15V6.1c0-.183.046-.358.138-.525A.86.86 0 0 1 1.55 5.2c.767-.4 1.567-.7 2.4-.9.833-.2 1.683-.3 2.55-.3.967 0 1.913.125 2.838.375S11.15 5 12 5.5A10.78 10.78 0 0 1 17.5 4c.867 0 1.717.1 2.55.3.833.2 1.633.5 2.4.9a.866.866 0 0 1 .413.375c.091.167.137.342.137.525v12.05a.966.966 0 0 1-.487.875c-.325.2-.663.208-1.013.025a9.048 9.048 0 0 0-1.938-.775A7.83 7.83 0 0 0 17.5 18c-.75 0-1.483.096-2.2.288-.717.191-1.4.479-2.05.862-.183.1-.379.18-.587.238-.209.058-.43.087-.663.087Z" })
  }
);
export {
  o as default
};
