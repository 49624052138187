/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraDesignTask = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z"
      fill="#4BAEE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9.815v.417c-.008.034-.02.067-.023.1-.027.392-.117.77-.25 1.138-.324.906-.862 1.683-1.5 2.397-1.204 1.347-2.652 2.402-4.17 3.37a.138.138 0 0 1-.122-.001c-1.293-.817-2.528-1.71-3.618-2.785-.708-.699-1.338-1.459-1.784-2.352-.372-.746-.595-1.526-.518-2.367.094-1.019.571-1.828 1.422-2.405.777-.527 1.64-.716 2.563-.471.798.21 1.402.71 1.901 1.343l.085.107c.183-.201.344-.402.529-.578.77-.733 1.663-1.106 2.757-.925 1.25.207 2.35 1.216 2.628 2.444.042.188.067.379.1.568Z"
      fill="#fff"
    />
  </svg>
);

export default JiraDesignTask;
