import { jsxs as s, jsx as t } from "react/jsx-runtime";
const o = (a) => /* @__PURE__ */ s(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ t("path", { d: "M9.358 6.449a8 8 0 0 1 9.3 11.987 1 1 0 1 0 1.664 1.109A10 10 0 0 0 8.698 4.56a1 1 0 1 0 .66 1.888ZM3.954 9.844a1 1 0 0 0-1.292.576 10 10 0 0 0 1.02 9.13 1 1 0 1 0 1.664-1.11 8 8 0 0 1-.816-7.305 1 1 0 0 0-.576-1.291Z" }),
      /* @__PURE__ */ t("path", { d: "M13.555 15.501c-.4.4-.916.596-1.55.588-.633-.009-1.1-.238-1.4-.688L6.38 9.076c-.15-.233-.129-.446.063-.637.191-.192.404-.213.637-.063l6.325 4.225c.45.3.688.758.713 1.375.025.617-.163 1.125-.563 1.525Z" })
    ]
  }
);
export {
  o as default
};
