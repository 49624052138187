/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Jira = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m20.785 11.784-8.01-8.008L12 3l-8.785 8.784a.737.737 0 0 0 0 1.042l5.508 5.507L12 21.611l8.785-8.785a.737.737 0 0 0 0-1.042ZM12 15.056l-2.751-2.75 2.75-2.752 2.752 2.751L12 15.056Z"
      fill="#2684FF"
    />
    <path
      d="M12 9.554a4.633 4.633 0 0 1-.02-6.533l-6.02 6.02 3.277 3.277L12 9.554Z"
      fill="url(#jira_svg__a)"
    />
    <path
      d="m14.759 12.298-2.76 2.758a4.633 4.633 0 0 1 0 6.555l6.036-6.036-3.276-3.277Z"
      fill="url(#jira_svg__b)"
    />
    <defs>
      <linearGradient
        id="jira_svg__a"
        x1={10.817}
        y1={7.462}
        x2={7.667}
        y2={10.612}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="jira_svg__b"
        x1={13.216}
        y1={17.118}
        x2={16.36}
        y2={13.973}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset={1} stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default Jira;
