import { jsx as a } from "react/jsx-runtime";
const t = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M4.05 11H6.9a5.837 5.837 0 0 0-1.65-3.25c-.3.483-.554.996-.763 1.537A7.707 7.707 0 0 0 4.05 11Zm13.05 0h2.85c-.083-.6-.23-1.17-.438-1.713a9.104 9.104 0 0 0-.762-1.537A5.838 5.838 0 0 0 17.1 11ZM5.25 16.25A5.837 5.837 0 0 0 6.9 13H4.05c.083.6.23 1.17.438 1.713.208.541.462 1.054.762 1.537Zm13.5 0c.3-.483.554-.996.762-1.537A7.707 7.707 0 0 0 19.95 13H17.1a5.838 5.838 0 0 0 1.65 3.25ZM8.95 11H11V4.05a8.806 8.806 0 0 0-2.463.737A7.499 7.499 0 0 0 6.5 6.2a8.564 8.564 0 0 1 1.613 2.163c.425.808.704 1.687.837 2.637ZM13 11h2.05c.133-.95.412-1.83.837-2.637A8.564 8.564 0 0 1 17.5 6.2a7.5 7.5 0 0 0-2.037-1.413A8.806 8.806 0 0 0 13 4.05V11Zm-2 8.95V13H8.95a8.014 8.014 0 0 1-.837 2.637A8.564 8.564 0 0 1 6.5 17.8c.6.583 1.28 1.054 2.037 1.412.759.359 1.58.605 2.463.738Zm2 0a8.806 8.806 0 0 0 2.463-.738A7.5 7.5 0 0 0 17.5 17.8a8.564 8.564 0 0 1-1.613-2.163A8.013 8.013 0 0 1 15.05 13H13v6.95ZM12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Z" })
  }
);
export {
  t as default
};
