import { jsxs as t, jsx as a } from "react/jsx-runtime";
const c = (h) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a("path", { d: "M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14Zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14Z" }),
      /* @__PURE__ */ a("path", { d: "M9.5 12.5a.965.965 0 0 1-.712-.287.965.965 0 0 1-.288-.712v-1H7.475a.926.926 0 0 1-.7-.288A.99.99 0 0 1 6.5 9.5a.97.97 0 0 1 .287-.713A.97.97 0 0 1 7.5 8.5h1V7.475c0-.283.096-.516.288-.7A.99.99 0 0 1 9.5 6.5a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v1h1.025c.283 0 .517.096.7.287.183.192.275.43.275.713 0 .284-.096.521-.287.713a.968.968 0 0 1-.713.288h-1v1.024a.928.928 0 0 1-.287.7.993.993 0 0 1-.713.276Z" })
    ]
  }
);
export {
  c as default
};
