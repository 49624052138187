import * as React from "react";
const SvgIconFormatSize = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h10c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3.5V18c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V7.5H10Zm-4.5 5H4c-.83 0-1.5-.67-1.5-1.5S3.17 9.5 4 9.5h6c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H8.5V18c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-5.5Z"
    />
  </svg>
);
export default SvgIconFormatSize;
