import { jsx as t } from "react/jsx-runtime";
const s = (c) => /* @__PURE__ */ t(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ t("path", { d: "M12 7c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 10 5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 12 3c.55 0 1.02.196 1.412.587C13.804 3.98 14 4.45 14 5s-.196 1.02-.588 1.412A1.926 1.926 0 0 1 12 7Zm0 14c-.417 0-.77-.146-1.063-.438A1.446 1.446 0 0 1 10.5 19.5v-9c0-.417.146-.77.438-1.063A1.447 1.447 0 0 1 12 9c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v9c0 .417-.146.77-.438 1.063A1.446 1.446 0 0 1 12 21Z" })
  }
);
export {
  s as default
};
