/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const JiraStory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.143 2H4.857A2.857 2.857 0 0 0 2 4.857v14.286A2.857 2.857 0 0 0 4.857 22h14.286A2.857 2.857 0 0 0 22 19.143V4.857A2.857 2.857 0 0 0 19.143 2Z"
      fill="#63BA3C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6H9a1.5 1.5 0 0 0-1.5 1.5v9.75c0 .414.336.75.75.75a.734.734 0 0 0 .615-.346l.006.001 2.889-3.345a.301.301 0 0 1 .48 0l2.889 3.345.006-.002a.734.734 0 0 0 .615.347.75.75 0 0 0 .75-.75V7.5A1.5 1.5 0 0 0 15 6Z"
      fill="#fff"
    />
  </svg>
);

export default JiraStory;
