import { defineMessages } from "react-intl";

export default defineMessages({
  dateTimePickerLabel: {
    id: "datePicker.label.date.time.picker",
    defaultMessage: "Select date",
  },
  am: {
    id: "datePicker.label.am",
    defaultMessage: "AM",
  },
  pm: {
    id: "datePicker.label.pm",
    defaultMessage: "PM",
  },
  twentyFourHour: {
    id: "datePicker.label.24h",
    defaultMessage: "24h",
  },
  timeInputPlaceholder: {
    id: "datePicker.placeholder.time",
    defaultMessage: "hh:mm",
  },
  timeInputLabel: {
    id: "datePicker.label.time",
    defaultMessage: "Time",
  },
  invalidTime: {
    id: "datePicker.message.invalid.time",
    defaultMessage: "Invalid Time",
  },
  selectDateFormatDescription: {
    id: "datePicker.message.formatDescription",
    defaultMessage: "Provide a date using the format {localeFormat}",
  },
  wrongFormat: {
    id: "datePicker.message.invalid.format",
    defaultMessage: "Invalid Format",
  },
  notNumber: {
    id: "datePicker.message.invalid.not.number",
    defaultMessage: "Given time is not a number",
  },
  pickerPrevMonth: {
    id: "datePicker.button.prevMonth",
    defaultMessage: "Previous month",
  },
  pickerNextMonth: {
    id: "datePicker.button.nextMonth",
    defaultMessage: "Next month",
  },
  pickerSelectYear: {
    id: "datePicker.button.selectYear",
    defaultMessage: "Select year and month",
  },
  selectYearDropdown: {
    id: "datePicker.dropdown.selectYear",
    defaultMessage: "Select year",
  },
  closeYearMonthPicker: {
    id: "datePicker.button.closeYearMonthPicker",
    defaultMessage: "Done",
  },
});
