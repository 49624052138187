import { jsxs as l, jsx as c } from "react/jsx-runtime";
const h = (a) => /* @__PURE__ */ l(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: [
      /* @__PURE__ */ c(
        "path",
        {
          d: "M12.367 22.28c-4.579-.013-8.57-2.905-9.87-7.22-1.088-3.616-.383-6.92 2.062-9.808C6.062 3.477 8 2.389 10.279 1.924c1.275-.26 2.588-.271 3.867-.032.143.026.144.065.088.18-.629 1.31-1.255 2.62-1.877 3.933a.198.198 0 0 1-.207.127 5.874 5.874 0 0 0-5.584 4.598c-.628 2.796.885 5.69 3.554 6.714 2.119.814 4.09.49 5.884-.9.091-.07.129-.083.194.03a459.605 459.605 0 0 0 2.08 3.604c.062.105.04.152-.05.215a10.224 10.224 0 0 1-4.554 1.788c-.433.056-.87.089-1.306.099Z",
          fill: "#EF492D"
        }
      ),
      /* @__PURE__ */ c(
        "path",
        {
          d: "M19.482 19.302c-.043-.022-.062-.063-.081-.103a1204.475 1204.475 0 0 1-2.358-4.1.295.295 0 0 0-.3-.167c-1.035.004-2.07.003-3.105.002l-1.552-.002h-.194a.232.232 0 0 1 .06-.161l2.368-4.11a.239.239 0 0 1 .242-.137c1.589.004 3.178.004 4.766 0a.237.237 0 0 1 .24.138c.793 1.381 1.59 2.76 2.389 4.138a.213.213 0 0 1 .007.247 2045.37 2045.37 0 0 0-2.416 4.185c-.014.03-.029.06-.066.07ZM15.852 4.472l.282.284.043-.075.07-.121c.346-.572.84-.88 1.524-.832.476.031.79.327.77.725-.014.255-.146.443-.337.598-.201.163-.43.275-.661.387l-.061.03c-.457.213-.88.491-1.258.825-.792.722-.979 1.665-.979 2.677 0 .1.065.1.134.1 1.46-.003 2.922-.003 4.384 0 .104 0 .132-.036.132-.138-.007-.32-.012-.641 0-.962.006-.15-.046-.176-.183-.176-.455.002-.91.002-1.365.002-.454 0-.91 0-1.364.003-.138.001-.163-.032-.121-.162.1-.32.319-.541.599-.702.24-.138.484-.266.728-.394.099-.051.197-.103.296-.156.334-.173.64-.394.911-.655.715-.701.825-2.253-.412-2.922a2.768 2.768 0 0 0-2.232-.22 2.475 2.475 0 0 0-1.447 1.162c-.035.054-.07.11.004.181.182.178.36.358.543.541Z",
          fill: "#EF492D"
        }
      )
    ]
  }
);
export {
  h as default
};
