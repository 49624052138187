import * as e from "react";
const t = (l) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...l
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      d: "M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z",
      fill: "#fff"
    }
  ),
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm5.997 9c-1.84 0-3.28 1.64-2.95 3.54.2 1.21 1.19 2.2 2.4 2.41 1.89.34 3.53-1.1 3.55-2.92V8h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-1.1 0-2 .9-2 2v4.18c-.31-.11-.65-.18-1-.18Z",
      fill: "#8997A1"
    }
  )
);
export {
  t as default
};
