/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/newline-after-import */
import { SVGProps } from "react";

const Hibob = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={2} y={2} width={20} height={20} rx={4} fill="#E52C51" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4a1 1 0 0 0-1 1v7.5h.025A5 5 0 1 0 9 9V5a1 1 0 0 0-1-1Zm7.25 9a3.25 3.25 0 1 1-6.5 0 3.25 3.25 0 0 1 6.5 0Z"
      fill="#fff"
    />
  </svg>
);

export default Hibob;
