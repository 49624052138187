import { jsx as c } from "react/jsx-runtime";
const Z = (h) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: /* @__PURE__ */ c("path", { d: "M7.425 20.975a.83.83 0 0 1-.638-.625c-.091-.333-.02-.617.213-.85l.5-.5c-.983 0-1.813-.337-2.487-1.012C4.338 17.313 4 16.483 4 15.5V6c0-.883.23-1.588.688-2.112.458-.526 1.062-.925 1.812-1.2.75-.276 1.604-.459 2.563-.55A30.838 30.838 0 0 1 12 2c1.1 0 2.137.046 3.113.138.974.091 1.825.275 2.55.55.724.274 1.295.674 1.712 1.2C19.792 4.412 20 5.117 20 6v9.5c0 .983-.337 1.813-1.012 2.488S17.483 19 16.5 19l.5.5c.233.233.304.517.212.85a.83.83 0 0 1-.637.625.97.97 0 0 1-.438 0 .805.805 0 0 1-.387-.225L14 19h-4l-1.75 1.75a.805.805 0 0 1-.388.225.97.97 0 0 1-.437 0ZM12 4c-1.767 0-3.058.104-3.875.313C7.308 4.52 6.75 4.75 6.45 5h11.2c-.25-.283-.787-.52-1.612-.713C15.213 4.096 13.867 4 12 4Zm-6 6h5V7H6v3Zm10.5 2H6h12-1.5ZM13 10h5V7h-5v3Zm-4.5 6c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C9.292 13.142 8.933 13 8.5 13c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm7 0c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075-.283-.283-.642-.425-1.075-.425-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm-8 1h9c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075V12H6v3.5c0 .433.142.792.425 1.075.283.283.642.425 1.075.425ZM12 5h5.65-11.2H12Z" })
  }
);
export {
  Z as default
};
