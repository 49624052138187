import * as e from "react";
const l = (t) => /* @__PURE__ */ e.createElement(
  "svg",
  {
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    ...t
  },
  /* @__PURE__ */ e.createElement(
    "path",
    {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "m14.798 4 5.613 10.105h-5.605L9.194 4h5.604ZM8.49 5.263l-5.367 9.684L5.929 20l5.36-9.684L8.49 5.263Zm1.653 9.684L7.336 20h10.735l2.806-5.053H10.143Z"
    }
  )
);
export {
  l as default
};
