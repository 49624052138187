import { jsxs as e, jsx as a } from "react/jsx-runtime";
const d = (h) => /* @__PURE__ */ e(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...h,
    children: [
      /* @__PURE__ */ a("path", { d: "M1 6a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Z" }),
      /* @__PURE__ */ a(
        "path",
        {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M1 10a1 1 0 0 1 1-1h10.667L16.4 6.2A1 1 0 0 1 17 6h5a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-5a1 1 0 0 1-.6-.2L12.667 11H2a1 1 0 0 1-1-1Zm13.667 0 2.666-2H21v4h-3.667l-2.666-2Z"
        }
      ),
      /* @__PURE__ */ a("path", { d: "M1 14a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM2 17a1 1 0 1 0 0 2h9a1 1 0 1 0 0-2H2Z" })
    ]
  }
);
export {
  d as default
};
